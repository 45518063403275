import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import RowGrid from '@src/components/FormRow/RowGrid'
import TextareaField from '@src/components/Form/TextareaField'
import Grid from '@material-ui/core/Grid'
import FieldPair from '@src/components/Form/FieldPair'
import AttachmentField from '@src/components/Form/AttachmentField'
import CommonFormFieldLabel from '@src/components/Form/CommonLabel'
import { Mime } from '@src/types/Mime'
import { RoleId } from '@src/types/common/RoleId'
import { Attachment } from '@src/types/Attachment'
import FormRowItem from '@src/components/FormRow/Item'
import { Sif } from '@src/types/Sif'
import { HseAnswer } from '@src/types/hse/HseAnswer'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { PackFormType } from '@src/types/PackFormType'
import { apiGetFiles } from '@src/apis/files/get-files'
import { useAppStore } from '@src/AppStore'
import RowFlex from '@src/components/FormRow/RowFlex'

interface Props {
  sif: Sif,
}

interface HseAnswerWithAttachments extends HseAnswer {
  attachments: Attachment[],
}

const HSESiteAssessment = (props: Props) => {
  const { sif } = props
  const { hazard, environment } = sif.hseSiteAssessment
  const [ hazardWithAttachments, setHazardWithAttachments ] = useState<HseAnswerWithAttachments[]>([])
  const [ environmentWithAttachments, setEnvironmentWithAttachments ] = useState<HseAnswerWithAttachments[]>([])
  const { serialId } = useAppStore()
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const result = await apiGetFiles({
          formType: PackFormType.HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION_QUESTIONNAIRE,
          formId: sif.hseId,
          serialId,
        })
        // map files to either hazard or env answer
        const mapping = (ans: HseAnswer) => {
          return {
            ...ans,
            attachments: [],
          }
        }
        let resultHazard: HseAnswerWithAttachments[] = hazard.map(mapping)
        let resultEnvironment: HseAnswerWithAttachments[] = environment.map(mapping)
        result.data.documents.forEach((document: Attachment) => {
          const { hseAId } = document
          if (hseAId) {
            const tryFindOneInHazard: HseAnswerWithAttachments | undefined = 
              resultHazard.find((ans: HseAnswerWithAttachments) => ans.id === hseAId)
            
            if (tryFindOneInHazard) {
              tryFindOneInHazard.attachments.push(document)
            } else {
              const tryFindOneInEnvironment: HseAnswerWithAttachments | undefined = 
              resultEnvironment.find((ans: HseAnswerWithAttachments) => ans.id === hseAId)
              if (tryFindOneInEnvironment) {
                tryFindOneInEnvironment.attachments.push(document)
              }
            }
          }
        })
        setHazardWithAttachments(resultHazard)
        setEnvironmentWithAttachments(resultEnvironment)
      } catch (e) {

      }
    }
    fetchFiles()
  }, [])
  return <Wrapper>
    <FormSectionTitle>{`HSE Site Assessment`}</FormSectionTitle>
    <FormPanel
      title={`Hazard`}
    >
      { hazardWithAttachments
        .filter((answer: HseAnswerWithAttachments) => answer.answer === true)
        .map((answer: HseAnswerWithAttachments) => {
          return <Row key={answer.id} answer={answer}/>
        })
      }
    </FormPanel>
    <FormPanel
      title={`Environment`}
    >
      { environmentWithAttachments
        .filter((answer: HseAnswerWithAttachments) => answer.answer === true)
        .map((answer: HseAnswerWithAttachments) => {
          return <Row key={answer.id} answer={answer}/>
        })
      }
    </FormPanel>
  </Wrapper>
}

const Row = (props: {
  answer: HseAnswerWithAttachments,
}) => {
  const { question, answer, comment, answerDate, attachments } = props.answer
  const { text } = question
  return <Grid container>
    <Grid item xs={2}>
      <FormRowItem>
        <FieldPair label={text} value={formatBooleanToYesNo(Boolean(answer))} />
      </FormRowItem>
    </Grid>
    <Grid item xs={4}>
      <FormRowItem>
        <TextareaField label={`Comments on ${text}`} value={comment || ''} readonly />
      </FormRowItem>
    </Grid>
    <Grid item>
      <FormRowItem>
        <CommonFormFieldLabel>{`Photographs of ${text}`}</CommonFormFieldLabel>
        <RowFlex>
          { attachments.map((attachment: Attachment) => {
            return <AttachmentField key={attachment.id} attachment={attachment}/>
          })}
        </RowFlex>
      </FormRowItem>
    </Grid>
  </Grid>
}

const Wrapper = styled.div``

export default HSESiteAssessment