import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { DRSStatus } from '@src/types/drs/DRSStatus'

interface Options { 
  drsId: string | number,
  roleId: string,
  statusId: DRSStatus,
}

export const apiDRSUpdateStatus = ({
  drsId,
  roleId,
  statusId,
}: Options) => {
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/drs/${drsId}/${statusId}`,
    params: {
      role: roleId,
    },
    data: {
      id: drsId,
      status: {
        id: statusId
      },
    }
  }
  return ax(options)
}

export const apiDRSUpdateStatusAndEnterSiteDiaryNumber = ({
  drsId, 
  siteDiaryNumber,
}: {
  drsId: string | number,
  siteDiaryNumber: string,
}) => {
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/drs/${drsId}/${DRSStatus.COMPLETED}`,
    params: {
      role: RoleId.Administrator,
    },
    data: {
      id: drsId,
      status: {
        id: DRSStatus.COMPLETED
      },
      siteDiaryNumber,
    }
  }
  return ax(options)
}