import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


interface Options {
  shiftId: string,
  serialId: number,
}

export const apiShiftGetPack = ({
  shiftId,
  serialId,
}: Options) => {
  const data = {
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/shifts/${shiftId}/pack`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}