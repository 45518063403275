import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import Typography from '@material-ui/core/Typography'
import FieldText from './f-Text'
import FieldEditableText from './f-EditableText'
import FieldEditableNumber from './f-EditableNumber'
import FieldJobType from './f-JobType'
import FieldEditableDate from './f-EditableDate'
import FieldShiftType from './f-ShiftType'
import FieldJobCrew from './f-JobCrew'
import FieldSwitch from './f-Switch'
import FieldAssetDepotRadio from './f-AssetDepotRadio'
import FieldRadio from './f-Radio'
import FieldDate from './f-Date'
import ReworkAssetsTable from './f-AssetsTable'
import FieldEditableCheckbox from './f-EditableCheckbox'
import FieldEditableCheckboxGroup from './f-EditableCheckboxGroup'
import FieldEditableCheckboxGroupFlat from './f-EditableCheckboxGroupFlat'
import FieldLabourMemberTable from './f-LabourMemberTable'
import FieldLabourCrewSummaryTable from './f-LabourCrewSummaryTable'
import FieldLabourCrewExtrasTable from './f-LabourCrewExtrasTable'
import FieldCommentText from './f-CommentText'
import FieldFileUpload from './f-FileUpload'
import FieldSelect from './f-Select'
import FieldEditableTime from './f-EditableTime'
import FieldTransportTable from './f-TransportTable'
import FieldSupplyPlant from './f-SupplyPlant'
import FieldProduct from './f-Product'
import FieldSupplier from './f-Supplier'
import FieldDepot from './f-Depot'
import FieldComments from './f-Comments'
import FieldSelectTrucks from './f-SelectTrucks'
import FieldMemberTimePicker from './f-MemberTimePicker'
import FieldBinderType from './f-BinderType'
import FieldAggregateSize from './f-AggregateSize'
import { RoleId } from '@src/types/common/RoleId'

const Wrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 1  6px;
`

export const FieldName = styled(Typography)`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
` as typeof Typography

const renderField = ({
  field,
  onChange,
}: DRS.Comp) => {
  if (!field.typeMeta) return null
  switch(field.typeMeta.type) {
    case 'text': {
      return (<React.Fragment>
        <FieldName>
          {field.name}
        </FieldName>
        <FieldText field={field} />
      </React.Fragment>)
    }
    case 'editable-text': {
      return <FieldEditableText field={field} onChange={onChange} />
    }
    case 'editable-number': {
      return <FieldEditableNumber field={field} onChange={onChange} />
    }
    case 'job-type': {
      return <FieldJobType field={field} />
    }
    case 'editable-date': {
      return <FieldEditableDate field={field} onChange={onChange}/>
    }
    case 'shift-type': {
      return <FieldShiftType field={field} onChange={onChange}/>
    }
    case 'job-crew': {
      return <FieldJobCrew field={field} onChange={onChange}/>
    }
    case 'switch': {
      return <FieldSwitch field={field} onChange={onChange}/>
    }
    case 'asset-depot-radio': {
      return <FieldAssetDepotRadio field={field} onChange={onChange}/>
    }
    case 'radio': {
      return <FieldRadio field={field} onChange={onChange}/>
    }
    case 'date': {
      return <FieldDate field={field} onChange={onChange}/>
    }
    case 'assets-table': {
      return <ReworkAssetsTable field={field} onChange={onChange}/>
    }
    case 'editable-checkbox': {
      return <FieldEditableCheckbox field={field} onChange={onChange}/>
    }
    case 'labour-member-table': {
      return <FieldLabourMemberTable field={field} onChange={onChange}/>
    }
    case 'labour-crew-summary-table': {
      return <FieldLabourCrewSummaryTable field={field} onChange={onChange}/>
    }
    case 'labour-crew-extras-table': {
      return <FieldLabourCrewExtrasTable field={field} onChange={onChange}/>
    }
    case 'comments': {
      return <FieldComments field={field} onChange={onChange}/>
    }
    case 'comment-text': {
      return <FieldCommentText field={field} onChange={onChange}/>
    }
    case 'editable-checkbox-group': {
      return <FieldEditableCheckboxGroup field={field} onChange={onChange}/>
    }
    case 'editable-checkbox-group-flat': {
      return <FieldEditableCheckboxGroupFlat field={field} onChange={onChange}/>
    }
    case 'file-upload': {
      return <FieldFileUpload field={field} onChange={onChange} roleId={RoleId.ResourceScheduler}/>
    }
    case 'select': {
      return <FieldSelect field={field} onChange={onChange}/>
    }
    case 'editable-time': {
      return <FieldEditableTime field={field} onChange={onChange}/>
    }
    case 'member-time-picker': {
      return <FieldMemberTimePicker field={field} onChange={onChange}/>
    }
    case 'transport-table': {
      return <FieldTransportTable field={field} onChange={onChange}/>
    }
    case 'supply-plant': {
      return <FieldSupplyPlant field={field} onChange={onChange}/>
    }
    case 'product': {
      return <FieldProduct field={field} onChange={onChange}/>
    }
    case 'supplier': {
      return <FieldSupplier field={field} onChange={onChange} label='Supplier' />
    }
    case 'depot': {
      return <FieldDepot field={field} onChange={onChange} label='Depot' />
    }
    case 'select-trucks': {
      return <FieldSelectTrucks field={field} onChange={onChange}/> 
    }
    case 'binder-type': {
      return <FieldBinderType field={field} onChange={onChange}/> 
    }
    case 'aggregate-size': {
      return <FieldAggregateSize field={field} onChange={onChange}/> 
    }
    default:
      console.log(`No such field: ${field.typeMeta.type}. Needed to be supported.`)
      return null
  }
}

const Field = ({
  field,
  onChange,
}: DRS.Comp) => {
  return <Wrapper>
    { renderField({
      field,
      onChange,
    }) }
  </Wrapper>
}

export default observer(Field)