import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { JobType } from '@src/types/JobType'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'

interface Options  {
  srrId: number | string,
  serialId: number,
  jobType: JobType,
  partialProduct: Partial<MaterialAsphaltProduct>,
}

export const apiSrrMaterialProductCreate = (options: Options) => {
  const { 
    srrId, 
    serialId,
    jobType,
    partialProduct,
  } = options

  const data = {
    srrId,
    ...partialProduct,
  }
  const asOrSs = jobType === JobType.Asphalt ? 'asphalt' : 'sprayseal'
  const oneOrTwo = jobType === JobType.Asphalt ? 1 : 2
  const axOptions = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/srr/${srrId}/material/${asOrSs}/details/${oneOrTwo}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}