import { Project } from '@src/types/project/Project'

interface IGroupProjectsObject {
  projects?: Project[],
  depotId?: string,
}

export const groupProjects = ({
  projects = [],
  depotId = '',
}: IGroupProjectsObject) : any[] => {
  if (!projects.length) {
    return []
  }

  if (!depotId || depotId === 'all') {
    // do the real group here. result by search by group number or all depots of the state
    let result: any[] = []
    let depotIndexMapping: { [key: string]: any } = {} // Sydney Metro: 0, Kembla: 1
    projects.forEach(project => {
      let { depot } = project
      if (depotIndexMapping.hasOwnProperty(depot!.id)) {
        result[depotIndexMapping[depot!.id]].projects.push(project)
      } else {
        depotIndexMapping[depot!.id] = result.length
        result.push({
          id: depot!.id,
          name: depot!.name,
          projects: [project],
        })
      }
    })
    return result
  } else {
    // result from some depot, so we have only one group
    return [
      {
        id: projects[0].depot!.id,
        name: projects[0].depot!.name,
        projects,
      }
    ]
  }

}