import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import FormModalTextField from '@src/components/FormModal/Textfield'
import FormModalNumericField from '@src/components/FormModal/Numericfield'
import FormModalTextarea from '@src/components/FormModal/Textarea'
import FormModalAutocomplete from '@src/components/FormModal/Autocomplete'
import FormModalBooleanRadioGroup from '@src/components/FormModal/BooleanRadioGroup'
import FormModalTimePicker from '@src/components/FormModal/TimePicker'
import FormModalSelect from '@src/components/FormModal/Select'
import FormModalCheckboxGroup from '@src/components/FormModal/CheckboxGroup'
import FormModalDatePicker from '@src/components/FormModal/DatePicker'
import FormModalMultipleAutocomplete from '@src/components/FormModal/MultipleAutocomplete'
import { useModalForm } from './useModalForm'
import { apiSupplierAgreementsBySupplier } from '@src/apis/supplier-agreements-by-supplier'
import { Supplier } from '@src/types/Supplier'
import { SupplierAgreement } from '@src/types/SupplierAgreement'
import { Moment } from 'moment'
import _union from 'lodash/union'
import _isNaN from 'lodash/isNaN'
import _includes from 'lodash/includes'
import _toNumber from 'lodash/toNumber'
import _reject from 'lodash/reject'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { getDecimalPlaces } from '@src/utils/getDecimalPlaces'
import { getLength } from '@src/utils/validateLength'

interface Props<T> {
  open: boolean,
  title: string,
  fullWidth?: boolean,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  data: {
    rowData: Partial<T> | null,
    fields: FormTableField<T>[] | any,
    columns: {
      title: string,
      fieldKeys: (keyof T)[],
    }[],
    extra?: {
      depotId?: string,
      stateId?: string,
    }
  },
  onSuccess(rowDataWithOrWithoutId: Partial<T>): void,
  onClose(): void,
  onCancel(): void,
  // extra actions
  extraAction1Label?: string,
  onExtraAction1?(rowDataWithOrWithoutId: Partial<T>): void,
}

function Modal<T>(props: Props<T>) {
  const {
    open,
    title,
    fullWidth = true,
    maxWidth,
    data: {
      rowData,
      fields,
      columns,
      extra,
    },
    onSuccess,
    onClose,
    onCancel,
    // extra actions
    extraAction1Label,
    onExtraAction1,
  } = props
  const [form, setForm, setField] = useModalForm(fields, rowData)
  const [supplierAgreementOptions, setSupplierAgreementOptions] = useState<SupplierAgreement[]>([])
  const [trigger, setTrigger] = useState(1)
  const [isSaving, setIsSaving] = useState<any>(false)
  const [fieldsInError, setFieldsInError] = useState<any[]>([])
  const handleSave = () => {
    console.log(form)
    setIsSaving(true)
    onSuccess(form)
  }
  useEffect(() => {
    const refreshSupplierAgreements = async () => {
      if (!form || !form['supplier']) {
        return
      }
      try {
        const result = await apiSupplierAgreementsBySupplier({
          supplierId: form['supplier'].id
        })
        if (result.data) {
          setSupplierAgreementOptions(result.data.supplierAgreements as SupplierAgreement[])
        }
      } catch (e) {

      }
    }
    refreshSupplierAgreements()
  }, [trigger])
  useEffect(() => {
    let once = false
    if (!once && form && form['supplier']) {
      once = true
      setTrigger(trigger + 1)
    }
  }, [form])
  function getSelectOptions(field: FormTableField<T>) {
    let options: { id: number | string, description: string }[] = []
    if (field.id === 'supplier-agreement') {
      options = supplierAgreementOptions
        .reduce((acc: SupplierAgreement[], curr: SupplierAgreement) => {
          if (acc.some((supplierAgreement: SupplierAgreement) => supplierAgreement.id === curr.id)) {
            return [...acc]
          }
          return [...acc, curr]
        }, [])
        .sort((a, b) => a.itemNumber < b.itemNumber ? 1 : -1)

    } else if (field.id === 'supplier-agreement-item') {
      if (form['supplierAgreement']) {
        options = supplierAgreementOptions
          .filter((supplierAgreement: SupplierAgreement) => {
            return supplierAgreement.id === form['supplierAgreement'].id
          })
          .map((supplierAgreement: SupplierAgreement) => {
            return {
              id: supplierAgreement.itemNumber,
              description: supplierAgreement.itemDescription,
            }
          })
          .sort((a, b) => a.description > b.description ? 1 : -1)
      }
    }
    return options
  }
  function getFinalUpdates(options: {
    changingKey: keyof T,
    changingValue: any,
    alsoUpdates?: (keyof T)[],
    alsoUpdatesFunc?(changingFieldValue: any, form: any): any[]
  }) {
    const {
      changingKey,
      changingValue,
      alsoUpdates,
      alsoUpdatesFunc,
    } = options
    const result: { [key in keyof T]: any } = { [changingKey]: changingValue } as { [key in keyof T]: any }
    if (alsoUpdates && typeof alsoUpdatesFunc === 'function') {
      const computedValues: any[] = alsoUpdatesFunc(changingValue, form)
      if (computedValues.length === alsoUpdates.length) {
        alsoUpdates.forEach((updatingFieldKey: keyof T, index: number) => {
          result[updatingFieldKey] = computedValues[index]
        })
      }
    }
    return result
  }
  const renderField = (field: FormTableField<T>) => {
    const { type, format } = field
    switch (type) {
      case FormTableFieldType.Readonly: {
        let readonlyText = ''
        if (format && typeof format === 'function') {
          readonlyText = format(form[field.key])
        } else {
          readonlyText = form[field.key]
        }
        return <FormModalTextField
          label={field.label || ''}
          defaultValue={readonlyText || ''}
          disabled={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          }}
        />
      }
      case FormTableFieldType.Text: {
        return <FormModalTextField
          label={field.label || ''}
          id={field.id || ''}
          defaultValue={form[field.key]}
          disabled={field.disabled}

          helperText={
            _some(fieldsInError, { key: String(field.key) }) ?
              _find(fieldsInError, { key: String(field.key) }).helperTextError
              : ""
          }
          error={_some(fieldsInError, { key: String(field.key) })}

          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (field.length !== undefined && getLength(e.target.value, field.length)) {
              setFieldsInError(_union(fieldsInError, [{ key: String(field.key), helperTextError: field.helperTextError }]))
            } else {
              setFieldsInError(_reject(fieldsInError, { key: String(field.key) }))
            }
            setField(field.key, e.target.value)
          }}
        />
      }
      case FormTableFieldType.Number: {
        // <Textfield type={"number"} /> didn't validate to limit to numbers or return onChange so used custom field component
        return <FormModalNumericField
          label={field.label || ''}
          value={form[field.key]}
          helperText={
            _some(fieldsInError, { key: String(field.key) }) ?
              _find(fieldsInError, { key: String(field.key) }).helperTextError
              : ""
          }
          error={_some(fieldsInError, { key: String(field.key) })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //console.log(`Modal number onChange() field.key=${field.key} value=${e.target.value} _isNaN=${_isNaN(_toNumber(e.target.value))}`)

            // if not numeric
            if (e.target.value !== "" && _isNaN(_toNumber(e.target.value))) {
              setFieldsInError(_union(fieldsInError, [{ key: String(field.key), helperTextError: "value must be a number" }]))
            } else if (field.maxScale !== undefined && getDecimalPlaces(e.target.value) > field.maxScale) {
              setFieldsInError(_union(fieldsInError, [{ key: String(field.key), helperTextError: field.helperTextError }]))
            } else if (field.length !== undefined && getLength(e.target.value, field.length)) {
              setFieldsInError(_union(fieldsInError, [{ key: String(field.key), helperTextError: field.helperTextError }]))
            } else {
              setFieldsInError(_reject(fieldsInError, { key: String(field.key) }))
            }
            setField(field.key, e.target.value)
          }
          }
        />
      }
      case FormTableFieldType.Textarea: {
        return <FormModalTextarea
          label={field.label || ''}
          value={form[field.key]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setField(field.key, e.target.value)
          }}
        />
      }
      case FormTableFieldType.Autocomplete: {
        return <FormModalAutocomplete
          extra={{
            plantId: (form.plant && form.plant.id) || (form.supplyPlant && form.supplyPlant.id),
            assetDepotTypeId: form.assetDepotType && form.assetDepotType.id,
            depotId: extra && extra.depotId,
            stateId: extra && extra.stateId,
          }}
          type={field.id || (field.key as string)}
          label={field.label || ''}
          value={form[field.key]}
          onChange={async (item: any) => {
            let updatingObj = getFinalUpdates({
              changingKey: field.key,
              changingValue: item,
              alsoUpdates: field.alsoUpdates,
              alsoUpdatesFunc: field.alsoUpdatesFunc,
            })
            if (field.key === 'supplier' && item) {
              setTrigger(trigger + 1)
            }
            console.log(updatingObj)
            setForm({
              ...form,
              ...updatingObj,
            })
          }}
        />
      }
      case FormTableFieldType.MultipleAutocomplete: {
        return <FormModalMultipleAutocomplete
          extra={{}}
          type={field.id || (field.key as string)}
          label={field.label || ''}
          value={form[field.key] || []}
          onChange={(item: any) => {
            setField(field.key, item)
          }}
        />
      }
      case FormTableFieldType.BooleanRadioGroup: {
        return <FormModalBooleanRadioGroup
          label={field.label || ''}
          value={form[field.key]}
          disabled={field.disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let booleanValue = false
            if ((e.target as HTMLInputElement).value === 'true') {
              booleanValue = true
            }
            let updatingObj = getFinalUpdates({
              changingKey: field.key,
              changingValue: booleanValue,
              alsoUpdates: field.alsoUpdates,
              alsoUpdatesFunc: field.alsoUpdatesFunc,
            })
            setForm({
              ...form,
              ...updatingObj,
            })
          }}
        />
      }
      case FormTableFieldType.Time: {
        return <FormModalTimePicker
          label={field.label || ''}
          value={form[field.key]}
          timeFormat={field.timeFormat}
          onChange={(time: Moment) => {
            let updatingObj = getFinalUpdates({
              changingKey: field.key,
              changingValue: time,
              alsoUpdates: field.alsoUpdates,
              alsoUpdatesFunc: field.alsoUpdatesFunc,
            })
            setForm({
              ...form,
              ...updatingObj,
            })
          }}
        />
      }
      case FormTableFieldType.Date: {
        return <FormModalDatePicker
          label={field.label || ''}
          value={form[field.key]}
          onChange={(HHmmSSString: string) => {
            setField(field.key, HHmmSSString)
          }}
        />
      }
      case FormTableFieldType.Select: {
        return <FormModalSelect
          label={field.label}
          type={field.id || field.key as string}
          disabled={field.disabled}
          value={form[field.key]}
          options={getSelectOptions(field)}
          onChange={(item: {
            id: string | number,
            description?: string,
            label?: string,
          }) => {
            setField(field.key, item)
          }}
        />
      }
      case FormTableFieldType.CheckboxGroup: {
        return <FormModalCheckboxGroup
          label={field.label || ''}
          value={form[field.key]}
          options={field.options}
          onChange={(item: any) => {
            setField(field.key, item)
          }}
        />
      }
    }
  }
  if (form === null) {
    return null
  }
  // console.log(form)
  return <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={onClose}
  >
    <DialogTitle>
      <ModalTitle>{title}</ModalTitle>
    </DialogTitle>
    <DialogContent>
      <SectionWrapper>
        {columns.map((column: { title: string, fieldKeys: (keyof T)[] }, index: number) => {
          return <Section key={column.title || index}>
            <Title>{column.title || ''}</Title>
            {fields
              .filter((field: FormTableField<T>) => column.fieldKeys.includes(field.key))
              .map((field: FormTableField<T>) => {
                return <FieldWrapper key={field.key as string}>
                  {renderField(field)}
                </FieldWrapper>
              })
            }
          </Section>
        })}
      </SectionWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      {extraAction1Label && typeof onExtraAction1 === 'function' &&
        <Button
          color='primary'
          variant='outlined'
          onClick={() => {
            onExtraAction1(form)
          }}
        >
          {extraAction1Label}
        </Button>
      }
      <Button
        color='primary'
        variant='contained'
        onClick={handleSave}
        disabled={fieldsInError.length > 0}
      >
        {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
        {!isSaving && 'SAVE '}
      </Button>
    </DialogActions>
  </Dialog>
}

const Wrapper = styled.div`

`
const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  display: flex;
`
const Section = styled.div`
  padding: 0 30px;
  width: 360px;
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin-bottom: 24px;
  min-height: 28px;
`
const FieldWrapper = styled.div`
  margin-bottom: 40px;
`
const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
export default Modal