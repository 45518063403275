import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { Rework } from '@src/types/Rework'
import { Crew } from '@src/types/Crew'
import { Depot } from '@src/types/common/Depot'
import { Region } from '@src/types/common/Region'
import { State } from '@src/types/common/State'

export interface MobileAsset {
  id: string,
  description: string,
  active: boolean,
  crews: Crew[],
  depot: Depot,
  depotPayroll:Depot,
  region: Region,
  state: State,
  // assetType: MobileAssetType,
  costingSystemAssetNumber: string,
  nextServiceDue: string,
  assetReadingKM: string,
  assetReadingHour: string,
  type: {
    id: MobileAssetType,
    description: string,
    unit: number
  },
  error: boolean

}

// active: true
// condition: null
// costingSystemAssetNumber: null
// crews: [{id: 1, name: "BLUE", colourCode: "0000FF", active: false}]
// depot: {id: "10000", name: "Sydney Metro",…}
// description: "Truck - MITSUBISHI FIGHTER"
// error: null
// errorDate: null
// id: "NTW804"
// nextServiceDue: null
// reading: null
// region: {id: "1000", name: "Southern", active: true}
// rego: null
// state: {id: "NSW", name: "New South Wales"}
// type: {id: 1, description: "Heavy Vehicle"}

export enum MobileAssetType {
  HeavyVehicle = 1,
  FMAS = 2,
  MPAVTackCoater = 3,
  Sprayer = 4,
  CrewJobTruck = 5,
  Trailer = 6,
  Paver = 7,
  Roller = 8,
  TowTractorBroom = 9,
  MTV = 10,
  FrontEndLoader = 11,
  SkidSteer = 12,
  Profiler = 13,
  Forklift = 14,
}

const MobileAssetFields: FormTableField<MobileAsset>[] = [
  {
    id: 'mobileAssetType',
    key: 'type',
    type: FormTableFieldType.Select,
    label: 'Asset Type',
  },
  {
    key: 'id',
    type: FormTableFieldType.Text,
    label: 'Asset Number',
  },
  {
    key: 'description',
    type: FormTableFieldType.Text,
    label: 'Asset Description',
  },
  {
    key: 'costingSystemAssetNumber',
    type: FormTableFieldType.Text,
    label: 'Costing System Asset Number',
  },
  {
    key: 'nextServiceDue',
    type: FormTableFieldType.Number,
    label: 'Next Service Due',
  },
  {
    key: 'depot',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot',
  },
  {
    key: 'depotPayroll',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot Payroll',
  },
  {
    key: 'crews',
    type: FormTableFieldType.MultipleAutocomplete,
    label: 'Crews',
  },
  {
    key: 'assetReadingKM',
    type: FormTableFieldType.Number,
    label: 'Current KMs',
  },
  {
    key: 'assetReadingHour',
    type: FormTableFieldType.Number,
    label: 'Current Hours',
  },
]

export default MobileAssetFields