import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import './TableData.css';
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import _get from 'lodash/get'
import FieldJobType, { JobType } from '../DRS/legacy/Create/comps/f-JobType'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import { default as TableSortLabelOrigin } from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import moment, { Moment } from 'moment'
import { useAppStore } from '../../AppStore'
import { ShiftTypeStatic } from '../DRS/legacy/Create/comps/f-ShiftType'
import CrewStatic from '../../components/CrewStatic'
import { formatStatus } from './formatStatus'
import Filter from '@material-ui/icons/Filter';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { ShiftTypeId, formatShiftTypeId } from '@src/types/ShiftTypeId'
import { ProjectTypeId, formatProjectTypeId } from '@src/types/ProjectTypeId'
import { DRSStatus } from '@src/types/drs/DRSStatus'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import cameraIcon from '@src/assets/icon_camera.png'
import pdfIcon from '@src/assets/pdf_file_icon.png'
import filterIcon from '@src/assets/filter_icon.png'
import filterIconInActive from '@src/assets/filter_icon_inactive.png'
import { BASE_API_URL } from '@src/constants'
import { getCookieIdToken } from '@src/utils/cookies'
import Axios from 'axios'
import DRSRedirectConfirmDialog from '../CrewPack/comps/DRSRedirectConfirmDialog'
import DateFilterDialog from './comps/DateFilterDialog';
import OptionFilterDialog from './comps/OptionsFilterDialog';

import CompThemeButton from '@src/components/Buttons/CompThemeButton';


interface Data {
  id: number,

  crewPackSerialId: number,
  shiftDate: string,
  street: string,
  suburb: string
  shiftType: ShiftTypeId,
  jobType: ProjectTypeId,
  crewName: string,
  crewColourCode: string,
  crewPackNumberOfPdfs: string,
  status: DRSStatus,
  crewPackNumberOfPhotos: string,
  SIFStatus: string,
  SRRStatus: string,
  VMPStatus: string,
  HSEStatus: string,
  MAPStatus: string,
  SMIStatus: string,
  totalQas: string,
  srrId: number,
  drsId: number,
  vmpId: number,
  hseId: number

}

export type CrewPackData = Data
// type DataValue = string |  ShiftTypeId | ProjectTypeId | DRSStatus
type Order = 'asc' | 'desc'
type OrderBy = keyof Data

function DRSTable({
  rows = [],
}: {
  rows: Data[],
}) {
  const [order, setOrder] = useState<Order>('desc')
  const [filterType, setFilterType] = useState<string>('')
  const [filterLabel, setFilterLabel] = useState<string>('')
  const [filterDialogOption, setFilterDialogOption] = useState<boolean>(false)
  const [crews, setCrews] = useState<any>([])
  /* temp fiters options*/
  const CREW_OPTIONS: any = ['ALL'];
  const SHIFT_TYPE_OPTIONS: any = ['ALL'];
  const JOB_TYPE_OPTIONS: any = ['ALL'];
  const STATUS_FILTER_OPTIONS: any = ['ALL'];


  const [jobtypeFilterOption, setJobtypeFilterOption] = useState<any>([])
  const [statusFilterOption, setStatusFilterOption] = useState<any>([])
  /* temp fiters options*/



  const [filterOptions, setFilterOptions] = useState<any>([])
  const [filterBackData, setFilterBackData] = useState<any>(['ALL'])
  const [filterCurrentValue, setFilterCurrentValue] = useState<any>(['ALL'])
  const [filterJob, setFilterJob] = useState<any>(['ALL'])
  const [filterStatus, setFilterStatus] = useState<any>(['ALL'])
  const [filterShiftType, setFilterShiftType] = useState<any>([])
  const [filterCrew, setFilterCrew] = useState<any>(['ALL'])
  const [dates, setDates] = useState<Moment[]>([])
  const [orderBy, setOrderBy] = useState<keyof Data>('shiftDate')
  const [loading, setLoading] = useState<boolean>(false)
  const [isMultiDatePicker, setIsMultiDatePicker] = useState<boolean>(false)
  const [sorted, setSorted] = useState<Data[]>([])
  const [drsDialogOpen, setDrsDialogOpen] = useState<boolean>(false)
  const [isBorder, setBorder] = useState<boolean>(false)
  const [drsId, setDrsId] = useState<number>(0)
  const { serialId, defaultRoleId } = useAppStore()
  const { alert, alertDone, alertError } = useSnackbar()
  const history = useHistory()
  const ShiftTypeOptions = [
    {
      value: 'ALL',
      label: 'All',
    },
    {
      value: ShiftTypeId.DAY,
      label: formatShiftTypeId(ShiftTypeId.DAY),
    },
    {
      value: ShiftTypeId.NIGHT,
      label: formatShiftTypeId(ShiftTypeId.NIGHT),
    },
  ]
  const [projectTypeIds, setProjectTypeIds] = useState<(ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal)[]>([])
  const PROJECT_TYPE_OPTIONS = [
    {
      value: 'ALL',
      label: 'All',
    },
    {
      value: ProjectTypeId.Asphalt,
      label: formatProjectTypeId(ProjectTypeId.Asphalt),
    },
    {
      value: ProjectTypeId.SpraySeal,
      label: formatProjectTypeId(ProjectTypeId.SpraySeal),
    },
  ]

  //  CREW_OPTIONS.shift({valie: 'ALL',label:'All'});
  const STATUS_OPTIONS = [
    { value: 'ALL', label: 'All' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'CANCELLED', label: 'Cancelled' },
    { value: 'SUBMITTED-FORMAN', label: 'Submitted Forman' },
    { value: 'INPROGRESS', label: 'In Progress' },
    { value: 'SUBMITTED-PM', label: 'Submitted PM' },
    { value: 'INREVIEW', label: 'Interview' },
    { value: 'APPROVED-PM', label: 'Approved PM' },
    { value: 'COMPLETED', label: 'Completed' }
  ]
  useEffect(() => {
    if (rows.length > 0) {
      setSorted(rows.sort(getSortFunction(order, orderBy)));
      UpdateLastRecentDate();
      const observerData = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
        { threshold: [1] }
      );
      const el: any = document.querySelector('.sticky-label');
      observerData.observe(el);
      setDefaultValueOptions();
    }
  }, [rows])
  const handleClick = (row: Data) => () => {
    history.push({
      pathname: `/crew-pack/${row.id}`
    })
  }
  function UpdateLastRecentDate() {
    setTimeout(function () {
      var elements: any = document.getElementsByClassName('date-divider');
      if (elements.length > 0) {
        elements[elements.length - 1].id = "future-last-date";
      }
    }, 3000);
  }
  const handleHSEClick = async (id: any) => {

    if (!id || loading) {
      return
    }
    try {
      setLoading(true)
      alert({
        type: 'info',
        message: 'Processing pdf...'
      })
      await handlePdfDownloadConfirmed(id, defaultRoleId);
    } catch (e) {
      alertError()
      setLoading(false)
    }
  }
  const handlePdfDownloadConfirmed = async (id: any, roleId: any) => {
    if (!id) {
      return
    }
    setLoading(true)
    try {
      const result = await Axios({

        method: 'GET',
        //responseType: 'blob',
        headers: {
          // Accept: 'application/pdf',
          Accept: 'application/json',
          'Authorization': getCookieIdToken(),
        },
        url: `${BASE_API_URL}/pdf/hse/${id}`,
        params: {
          reqSid: serialId,
          reqRole: roleId,
        },
      })

      if (typeof result != "undefined") {
        if (typeof result.data.url != "undefined") {
          setTimeout(async function () {
            await downloadPDFS3(result.data.url, id);
          }, 5000)
        } else {
          setTimeout(async function () {
            await downloadPDFS3(result.data, id);
          }, 5000)
        }
      } else {
        setLoading(false)
      }



    } catch (e) {
      console.log(e)
      alertError();
      setLoading(false)
    }
  }

  const downloadPDFS3 = async (pdfName: string, id: any) => {
    if (!pdfName) {
      return
    }
    setLoading(true);
    alert({
      type: 'info',
      message: 'Downloading pdf...'
    })
    try {

      const result: any = await Axios({
        url: pdfName,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf'
        },
      })
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank";

      link.setAttribute('download', "HSE-" + id + ".pdf")
      document.body.appendChild(link)
      link.click()
      setLoading(false)
      alertDone();
    } catch (e) {
      console.log(e)

      alertError()
      setLoading(false)
    }

  }
  function filterDateRecords(dateRecords: any) {
    var sortArr: Data[] = [];

    rows.forEach((row) => {
      if (moment(row.shiftDate) >= dateRecords[0] && moment(row.shiftDate) <= dateRecords[1] && FilterShiftTypeTerm(row) && FilterCrewTerm(row) && FilterJobTerm(row) && FilterStatusTerm(row)) {
        sortArr.push(row);
        if (CREW_OPTIONS.indexOf(row.crewName) == -1) {
          CREW_OPTIONS.push(row.crewName);
        }
        if (SHIFT_TYPE_OPTIONS.indexOf(row.shiftType) == -1) {
          SHIFT_TYPE_OPTIONS.push(row.shiftType);
        }
        if (JOB_TYPE_OPTIONS.indexOf(row.jobType) == -1) {
          JOB_TYPE_OPTIONS.push(row.jobType);
        }
        if (STATUS_FILTER_OPTIONS.indexOf(row.status) == -1) {
          STATUS_FILTER_OPTIONS.push(row.status);
        }

      }
    })
    setCrews(CREW_OPTIONS);
    setJobtypeFilterOption(JOB_TYPE_OPTIONS);
    setFilterShiftType(SHIFT_TYPE_OPTIONS)
    setStatusFilterOption(STATUS_FILTER_OPTIONS)
    // console.log(sortArr);
    setSorted(sortArr);
    UpdateLastRecentDate();
  }
  function filterTableData(data: any) {
    var sortArr: Data[] = [];
    let sortArrData: any;
    rows.forEach((row) => {
      if (filterType == 'shift-type') {
         sortArrData = filterShiftTypeData(data, row);
        // if (sortArrData) {
        //   sortArr.push(sortArrData);
          
        // }
      }
      if (filterType == 'crew-type') {
         sortArrData = filterCrewData(data, row);
        // if (sortArrData) {
        //   sortArr.push(sortArrData);
        // }
      }
      if (filterType == 'job-type') {
         sortArrData = filterJobTypeData(data, row);
        // if (sortArrData) {
        //   sortArr.push(sortArrData);
        // }
      }
      else if (filterType == 'status-type') {
         sortArrData = filterStatusData(data, row);
        // if (sortArrData) {
        //   sortArr.push(sortArrData);

        // }
      }
      if (sortArrData) {
        sortArr.push(sortArrData);

        if (CREW_OPTIONS.indexOf(sortArrData.crewName) == -1) {
          CREW_OPTIONS.push(sortArrData.crewName);
        }
        if (SHIFT_TYPE_OPTIONS.indexOf(sortArrData.shiftType) == -1) {
          SHIFT_TYPE_OPTIONS.push(sortArrData.shiftType);
        }
        if (JOB_TYPE_OPTIONS.indexOf(sortArrData.jobType) == -1) {
          JOB_TYPE_OPTIONS.push(sortArrData.jobType);
        }
        if (STATUS_FILTER_OPTIONS.indexOf(sortArrData.status) == -1) {
          STATUS_FILTER_OPTIONS.push(sortArrData.status);
        }
      }

    })
    setCrews(CREW_OPTIONS);
    setJobtypeFilterOption(JOB_TYPE_OPTIONS);
    setFilterShiftType(SHIFT_TYPE_OPTIONS)
    setStatusFilterOption(STATUS_FILTER_OPTIONS)
 // console.log(JSON.stringify(sortArr));
    setSorted(sortArr);
    UpdateLastRecentDate();
  }
  function filterRecords(data: any, row: any, value: any) {
    var sortArr: any = null;
    if (data.length > 0) {
      if (data.length == 1 && data[0] == 'ALL' && validateDefaultFilters())
        sortArr = (row);
      else if (data.indexOf(value) !== -1) {
        sortArr = (row);
      }
    }
    return sortArr;
  }
  function filterShiftTypeData(data: any, row: any) {
    var sortArr: any = null;
    if (data.length > 0) {
      if (data.length == 1 && data[0] == 'ALL' && validateDefaultFilters())
        sortArr = (row);
      if (data.indexOf(row.shiftType) != -1 && ShifteDateTerm(row) && FilterCrewTerm(row) && FilterJobTerm(row) && FilterStatusTerm(row)) {
        sortArr = (row);
      }
    }
    return sortArr;
  }
  function filterCrewData(data: any, row: any) {
    var sortArr: any = null;
   
    if (data.length > 0) {
      if (data.length == 1 && data.indexOf('ALL') !== -1 && validateDefaultFilters())
        sortArr = (row);
      if (data.indexOf(row.crewName) !== -1 && ShifteDateTerm(row) && FilterShiftTypeTerm(row) && FilterJobTerm(row) && FilterStatusTerm(row)) {
        sortArr = (row);
      }
    }
    console.log(`sortArr`);
    console.log(sortArr);
    console.log(`sortArr`);
    return sortArr;
  }
  function filterJobTypeData(data: any, row: any) {
    var sortArr: any = null;
    if (data.length > 0) {
      if (data.length == 1 && data[0] == 'ALL' && validateDefaultFilters())
        sortArr = (row);
      if (data.indexOf(row.jobType) !== -1 && ShifteDateTerm(row) && FilterShiftTypeTerm(row) && FilterCrewTerm(row) && FilterStatusTerm(row)) {
        sortArr = (row);
      }
    }
    return sortArr;
  }
  function filterStatusData(data: any, row: any) {
    var sortArr: any = null;
    if (data.length > 0) {

     
      if (data.length == 1 && data[0] == 'ALL' && validateDefaultFilters())
        sortArr = (row);
      if (data.indexOf((row.status)) != -1 && ShifteDateTerm(row) && FilterShiftTypeTerm(row) && FilterCrewTerm(row) && FilterJobTerm(row)) {
        sortArr = (row);
      }
    }
    return sortArr;
  }

  function ShifteDateTerm(row: any) {
    if (dates.length > 0) {
      if (moment(row.shiftDate) >= dates[0] && moment(row.shiftDate) <= dates[1]) {
          return true;
      } else {
          return false;
      }
    } else {
        return true;
    }
  }
  function FilterShiftTypeTerm(row: any) {
    if ((filterBackData.length == 1 && filterBackData.indexOf('ALL') !== -1) || filterBackData.length == 0) {
      return true;
    } else {
      if (filterBackData.indexOf(row.shiftType) !== -1) {
         return true;
      } else {
         return false;
      }
    }
  }
  function FilterCrewTerm(row: any) {
    if (filterCrew.length == 1 && filterCrew.indexOf('ALL') !== -1) {
      return true;
    } else {
      if (filterCrew.indexOf(row.crewName) !== -1) {
          return true;
      } else {
         return false;
      }
    }
  }
  function FilterJobTerm(row: any) {
    if (filterJob.length == 1 && filterJob.indexOf('ALL') !== -1) {
      return true;
    } else {
      if (filterJob.indexOf(row.jobType) !== -1) {
           return true;
      } else {
           return false;
      }
    }
  }
  function FilterStatusTerm(row: any) {
    if (filterStatus.length == 1 && filterStatus.indexOf('ALL') !== -1) {
      return true;
    } else {
      if (filterStatus.indexOf(row.status) !== -1) {
           return true;
      } else {
           return false;
      }
    }
  }
  function clearOptions() {
      setFilterBackData(['ALL']);
      setFilterCrew(['ALL']);
      setFilterStatus(['ALL']);
      setFilterJob(['ALL']);
      setDates([]);
  }
  function validateDefaultFilters() {
    if ((filterBackData.length == 1 && filterBackData.indexOf('ALL') !== -1)
      && (filterCrew.length == 1 && filterCrew.indexOf('ALL') !== -1)
      && (filterStatus.length == 1 && filterStatus.indexOf('ALL') !== -1)
      && (filterJob.length == 1 && filterJob.indexOf('ALL') !== -1)
      && (dates.length == 0)
    ) {
      return true;
    } else {
      return false;
    }
  }
  function setFilters(data: any) {
    console.log(data);
    switch (filterType) {
      case 'shift-type':
        setFilterBackData(data);
        break;
      case 'crew-type':
        setFilterCrew(data);
        break;
      case 'status-type':
        setFilterStatus(data);
        break;
      case 'job-type':
        setFilterJob(data);
        break;
    }
    setFilterDialogOption(false);
    filterTableData(data);
  }
  function getBorderClass(dateShift: any) {
    if (moment(dateShift) >= moment(new Date()) && isBorder == false) {
      return 'date-divider'
    } else {
      return 'none';
    }
  }
  const handleStatusPoint = (type: string, row: Data) => () => {

    // if(type == 'SI' && row.SIFStatus=='1' && row.id) {
    if (type == 'SI' && row.id) {
      window.open(`#/crew-pack/${row.id}/#documents`, '_blank');
      return;

    }
    // if(type == 'PDF' && row.crewPackNumberOfPdfs !='0' && row.crewPackSerialId) {
    if (type == 'PDF' && row.crewPackSerialId) {
      window.location.href = `/#/crewpack/photos/${row.id}/${row.crewPackSerialId}#pdf`;
      return;
    }
    // if(type == 'CP' && row.crewPackNumberOfPhotos!='0' && row.crewPackSerialId) {
    if (type == 'CP' && row.crewPackSerialId) {
      history.push({
        pathname: `/crewpack/photos/${row.id}/${row.crewPackSerialId}`
      })
      return;
    }
    // if(type == 'SRR' && row.SRRStatus!='1' && row.srrId) {
    if (type == 'SRR' && row.srrId) {
      history.push({
        pathname: `/srr/${row.srrId}#job/`
      })
      return;
    }
    if (type == 'DRS' && row.drsId) {
      // if(type == 'DRS' && formatStatus(row.status)!='Draft' && row.drsId) {
      setDrsId(row.drsId);
      setDrsDialogOpen(true);
      return;
      // history.push({
      //   pathname: `/drs/plan/${row.drsId}`
      // })
    }
    // if(type == 'VMP' &&  row.VMPStatus!='1' && row.vmpId) {
    if (type == 'VMP' && row.vmpId) {
      history.push({
        pathname: `/vmp/${row.vmpId}`
      })
      return;
    }
    // if(type == 'HSE' &&  row.HSEStatus!='1' && row.hseId) {
    if (type == 'HSE' && row.hseId) {
      handleHSEClick(row.hseId);
      return;
    }
    // if(type == 'MAP' &&  row.MAPStatus!='0') {
    if (type == 'MAP') {
      // console.log('hee');

      //console.log(row);
      // window.location.href="https://google.com";
      window.open(`#/map-list/${row.id}`, '_blank');
      //  return;

    }
    // if(type == 'QAS' && row.totalQas!='0' ) {
    if (type == 'QAS') {
      window.open(`#/crew-pack/${row.id}/#shiftForms`, '_blank');
      return;
    }
    // if(type == 'SMI' && row.SMIStatus!='0' ) {
    if (type == 'SMI') {
      window.open(`#/crew-pack/${row.id}/#shiftForms`, '_blank');
      return;
    }

  }

  const handleIconClick = (type: any) => () => {
    if (type == 'date') {
      setIsMultiDatePicker(true);
    } else {
      setFilterType(type);
      setFilterDialogOption(true)
      if (type == 'shift-type') {

        const shiftTypeData = filterShiftType.map((shiftType: any) => {
          return {
            ...shiftType,
            value: shiftType,
            label: shiftType,
          }
        })

        setFilterOptions(shiftTypeData);
        setFilterLabel('Shift Type');
       
        if((filterBackData.length == 1 && filterBackData.indexOf('ALL')!= -1) || filterBackData.length == 0)
           setFilterCurrentValue(filterShiftType);
        else
           setFilterCurrentValue(filterBackData);
      }
      if (type == 'job-type') {
        const jobtypeData = jobtypeFilterOption.map((jobtype: any) => {
          return {
            ...jobtype,
            value: jobtype,
            label: jobtype,
          }
        })
        setFilterOptions(jobtypeData);
        setFilterLabel('Job Type');
      
        if((filterJob.length == 1 && filterJob.indexOf('ALL')!= -1) || filterJob.length == 0)
          setFilterCurrentValue(jobtypeFilterOption);
        else
          setFilterCurrentValue(filterJob);
        
      }
      if (type == 'crew-type') {
        // console.log(crews);
        const crewData = crews.map((crew: any) => {
          return {
            ...crew,
            value: crew,
            label: crew,
          }
        })

        setFilterOptions(crewData);
        setFilterLabel('Crew');
        if((filterCrew.length == 1 && filterCrew.indexOf('ALL')!= -1) || filterCrew.length == 0)
         setFilterCurrentValue(crews);
        else
         setFilterCurrentValue(filterCrew);

      }
      if (type == 'status-type') {

        const statusData = statusFilterOption.map((statuses: any) => {
          return {
            ...statuses,
            value: statuses,
            label: statuses,
          }
        })

        setFilterOptions(statusData);
        setFilterLabel('Status');

        if((filterStatus.length == 1 && filterStatus.indexOf('ALL')!= -1) || filterStatus.length == 0)
        setFilterCurrentValue(statusFilterOption);
       else
        setFilterCurrentValue(filterStatus);
      }
    }
  }
  const handleSort = (targetOrderBy: OrderBy) => () => {
    setOrder(order === 'desc' ? 'asc' : 'desc')
    setOrderBy(targetOrderBy)
    setSorted(sorted.sort(getSortFunction(order, targetOrderBy)));
  }


  const handleRecent = () => {
    const myElement: any = document.getElementById('future-last-date');
    if (myElement) {
      /// console.log(myElement.current);
      window.scrollTo(0, myElement.offsetTop + 150);
      // scrollIfNeeded(myElement, document.getElementById('MainTable'));
    }


  }
  function setDefaultValueOptions() {


    const shiftTypeData = filterShiftType.map((shiftType: any) => {
      return {
        ...shiftType
        
      }
    })
    
    setFilterBackData(shiftTypeData);
  }

  const handleClear = () => () => {
    setSorted(rows.sort(getSortFunction(order, orderBy)));

    UpdateLastRecentDate();
    clearOptions();
  }

  function filterDates(dates: any) {
    // console.log(dates);
    setDates(dates);
    filterDateRecords(dates);
    setIsMultiDatePicker(false);
  }
  const getSortFunction = (order: Order, orderBy: OrderBy) => {
    // console.log('get sort func', order, orderBy)
    if (['shiftDate', 'street', 'suburb', 'crewName', 'shiftType', 'jobType'].includes(orderBy)) {
      return (a: Data, b: Data) => {
        if (CREW_OPTIONS.indexOf(a.crewName) == -1) {
          CREW_OPTIONS.push(a.crewName);
        }
        if (SHIFT_TYPE_OPTIONS.indexOf(a.shiftType) == -1) {
          SHIFT_TYPE_OPTIONS.push(a.shiftType);
        }
        if (JOB_TYPE_OPTIONS.indexOf(a.jobType) == -1 && a.jobType) {
          JOB_TYPE_OPTIONS.push(a.jobType);
        }
        if (STATUS_FILTER_OPTIONS.indexOf(a.status) == -1 && a.status) {
          STATUS_FILTER_OPTIONS.push(a.status);
        }
        setCrews(CREW_OPTIONS);
        setJobtypeFilterOption(JOB_TYPE_OPTIONS);
        setFilterShiftType(SHIFT_TYPE_OPTIONS)
        setStatusFilterOption(STATUS_FILTER_OPTIONS)
        const aValue: string | number = a[orderBy]
        const bValue: string | number = b[orderBy]
        let result: number
        if (aValue > bValue) {
          result = 1
        } else if (aValue === bValue && orderBy !== 'shiftDate') {
          // we want a secondary sort here, which is the shift date
          if (a['shiftDate'] > b['shiftDate']) {
            result = order === 'asc' ? -1 : 1 // flip for order, to always keep the latest date first
          } else {
            result = order === 'asc' ? 1 : -1 // flip for order
          }
        } else {
          result = -1
        }
        return order === 'asc' ? result : -result
      }
    }
    if (orderBy === 'status') {
      return (a: Data, b: Data) => {
        const aValue: DRSStatus = a[orderBy]
        const bValue: DRSStatus = b[orderBy]
        let result: number
        const jumpInThisQueue = ['DRAFT', 'CANCELLED', 'SUBMITTED-FORMAN', 'INPROGRESS', 'SUBMITTED-PM', 'INREVIEW', 'APPROVED-PM', 'COMPLETED']
        if (jumpInThisQueue.indexOf(aValue) < jumpInThisQueue.indexOf(bValue)) {
          result = -1
        } else {
          result = 1
        }
        return order === 'asc' ? result : -result
      }
    }
  }
  //const sorted: Data[] = 
  // setSorted(rows.sort(getSortFunction(order, orderBy)));
  // console.log(sorted.length ? sorted[0].shiftDate : '')
  return <div style={{ position: 'relative' }}>
    {isMultiDatePicker && <DateFilterDialog
      dates={dates}
      open={isMultiDatePicker}
      onSave={(dates: any) => filterDates(dates)}
      onClose={() => setIsMultiDatePicker(false)}
    />
    }

    {
      filterDialogOption && <OptionFilterDialog
        open={filterDialogOption}
        onSave={(data: any) => setFilters(data)}
        onClose={() => setFilterDialogOption(false)}
        type={filterType}
        currentValue={filterCurrentValue}
        options={filterOptions}
        label={filterLabel}
      />
    }



    <Table stickyHeader style={{ borderCollapse: 'collapse' }} id="MainTable">

      <TableHead>
        <TableRow className='sticky-label'>

          <TableHeaderCell colSpan={8} style={{ backgroundColor: 'transparent' }}>
            <FilterButtons className='filter-clear'>
              <CompThemeButton
                onClick={handleRecent}>
                Go to Recent
              </CompThemeButton>
              &nbsp;
              <CompThemeButton 
            //    disable={!validateDefaultFilters()}
                onClick={handleClear()}>
                Clear Filters

              </CompThemeButton>
            </FilterButtons>
          </TableHeaderCell>
        </TableRow>
        <TableRow>
          <TableHeaderCell style={{ width: '150px' }}>
            <TableSortLabel direction={order} active={orderBy === 'shiftDate'} onClick={handleSort('shiftDate')}>
              Shift Date
            </TableSortLabel>

            <FilterElement onClick={handleIconClick('date')}><img style={{ display: 'inline', position: 'relative', top: '5px', cursor: 'pointer' }} src={dates.length > 0 ? filterIcon :filterIconInActive} width='14' /></FilterElement>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableSortLabel direction={order} active={orderBy === 'street'} onClick={handleSort('street')}>
              Street
            </TableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableSortLabel direction={order} active={orderBy === 'suburb'} onClick={handleSort('suburb')}>
              Suburb
            </TableSortLabel>
          </TableHeaderCell>
          <TableHeaderCell style={{ textAlign: 'center' }}>
            <TableSortLabel direction={order} active={orderBy === 'suburb'} onClick={handleSort('suburb')}>
              Shift Documents
            </TableSortLabel>
          </TableHeaderCell>

          <TableHeaderCell style={{ width: '150px' }}>
            <TableSortLabel direction={order} active={orderBy === 'shiftType'} onClick={handleSort('shiftType')}>
              Shift Type
            </TableSortLabel>
            <FilterElement onClick={handleIconClick('shift-type')}><img style={{ display: 'inline', position: 'relative', top: '5px', cursor: 'pointer' }} src={filterBackData.length > 0 ? ((filterBackData.length == 1 && filterBackData.indexOf('ALL') !== -1)  ? filterIconInActive : filterIcon) : filterIconInActive} width='14' /></FilterElement>
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '150px' }}>
            <TableSortLabel direction={order} active={orderBy === 'jobType'} onClick={handleSort('jobType')}>
              Job Type
            </TableSortLabel>
            <FilterElement onClick={handleIconClick('job-type')}><img style={{ display: 'inline', position: 'relative', top: '5px', cursor: 'pointer' }} src={(filterJob.length == 1 && filterJob.indexOf('ALL') !== -1)  ? filterIconInActive : filterIcon} width='14' /></FilterElement>
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '150px' }}>
            <TableSortLabel direction={order} active={orderBy === 'crewName'} onClick={handleSort('crewName')}>
              Crew
            </TableSortLabel>
            <FilterElement onClick={handleIconClick('crew-type')}><img style={{ display: 'inline', position: 'relative', top: '5px', cursor: 'pointer' }} src={(filterCrew.length == 1 && filterCrew.indexOf('ALL') !== -1) ? filterIconInActive : filterIcon} width='14' /></FilterElement>
          </TableHeaderCell>
          <TableHeaderCell style={{ width: '124px' }}>
            <TableSortLabel direction={order} active={orderBy === 'status'} onClick={handleSort('status')}>
              Status
            </TableSortLabel>
            <FilterElement onClick={handleIconClick('status-type')}><img style={{ display: 'inline', position: 'relative', top: '5px', cursor: 'pointer' }} src={(filterStatus.length == 1 && filterStatus.indexOf('ALL') !== -1) ? filterIconInActive : filterIcon} width='14' /></FilterElement>
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sorted.map((row: Data, rowIndex) => {
          return <TableRow
            hover
            key={row.id} className={getBorderClass(row.shiftDate)}>

            <SCell onClick={handleClick(row)} >
              {moment(row.shiftDate).format('DD/MM/YYYY')}
            </SCell>
            <SCell onClick={handleClick(row)} >
              {row.street}
            </SCell>
            <SCell onClick={handleClick(row)} >
              {row.suburb}

            </SCell>
            <SCell style={{ width: '631px' }} >
              <TableEleLabel isGreen={row.crewPackNumberOfPdfs != '0' ? 1 : 0} onClick={handleStatusPoint('PDF', row)}>
                {row.crewPackNumberOfPdfs != '0' && <CamNotification>{row.crewPackNumberOfPdfs}</CamNotification>}
                <img style={{ display: 'inline', position: 'relative', top: '3px' }} src={pdfIcon} width='24' />
              </TableEleLabel>
              <TableEleLabel isGreen={row.crewPackNumberOfPhotos != '0' ? 1 : 0} onClick={handleStatusPoint('CP', row)}>
                {row.crewPackNumberOfPhotos != '0' && <CamNotification>{row.crewPackNumberOfPhotos}</CamNotification>}
                <img style={{ display: 'inline', position: 'relative', top: '5px' }} src={cameraIcon} width='18' />
              </TableEleLabel>
              <TableEleLabel isGreen={row.SIFStatus && row.SIFStatus != '0' ? 1 : 0} onClick={handleStatusPoint('SI', row)}>SI</TableEleLabel>
              <TableEleLabel isGreen={row.SRRStatus && row.SRRStatus != '1' ? 1 : 0} onClick={handleStatusPoint('SRR', row)}>SRR</TableEleLabel>
              <TableEleLabel isGreen={row.VMPStatus && row.VMPStatus != '1' ? 1 : 0} onClick={handleStatusPoint('VMP', row)}>VMP</TableEleLabel>
              <TableEleLabel isGreen={row.status && formatStatus(row.status) != 'Draft' ? 1 : 0} onClick={handleStatusPoint('DRS', row)}>DRS</TableEleLabel>
              <TableEleLabel isGreen={row.HSEStatus && row.HSEStatus != '1' ? 1 : 0} onClick={handleStatusPoint('HSE', row)}>HSE</TableEleLabel>
              <TableEleLabel isGreen={row.MAPStatus != '0' ? 1 : 0} onClick={handleStatusPoint('MAP', row)}>MAP</TableEleLabel>
              <TableEleLabel isGreen={row.totalQas != '0' ? 1 : 0} onClick={handleStatusPoint('QAS', row)}>QAS</TableEleLabel>
              <TableEleLabel isGreen={row.SMIStatus != '0' ? 1 : 0} onClick={handleStatusPoint('SMI', row)}>SMI</TableEleLabel>
            </SCell>
            <SCell onClick={handleClick(row)}>
              <ShiftTypeStatic
                shiftTypeId={row.shiftType}
              />
            </SCell>
            <SCell onClick={handleClick(row)}>
              <JobType jobTypeId={row.jobType} />
            </SCell>
            <SCell onClick={handleClick(row)}>
              <CrewStatic crew={{
                name: row.crewName,
                colourCode: row.crewColourCode,
              }} />
            </SCell>
            <SCell >
              {formatStatus(row.status)}
            </SCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
    {drsDialogOpen && <DRSRedirectConfirmDialog
      data={drsId}
      open={drsDialogOpen}
      onCancel={() => setDrsDialogOpen(false)}
      onClose={() => setDrsDialogOpen(false)}
    />
    }

  </div>
}

function TableSortLabel(props: any) {
  return <TableSortLabelOrigin
    IconComponent={KeyboardArrowDownIcon}
    {...props}
  >

  </TableSortLabelOrigin>
}
const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const TableEleLabel = styled.div<{ isGreen: number }>`
  color: #c6c6c6;
  font-weight: bold;
  font-size: 12px;
  display: inline;
  margin-left: 20px;
  border: 1px solid #c6c6c6;
  padding: 7px;
  border-radius:5px;
  position:relative;
  ${props => {
    if (props.isGreen) {
      return css`
      color: #0A9688;
     
      border: 1px solid #0A9688;
      `
    }
  }}

`
const CamNotification = styled.div`
font-weight: bold;
    font-size: 10px;
    position: absolute;
    background: #0A9688;
    width: 14px;
    height: 14px;
    border-radius: 25px;
    color: white;
    top: -9px;
    right: 0px;
    text-align: center;
`
const SCell = styled(TableCell)`
  padding: 22px 16px;
  cursor: pointer;
`
const FilterElement = styled.a`
font-weight: bold;
    font-size: 10px;
   cursor:pointer
   
    text-align: center;
`
const FilterButtons = styled.div`
font-weight: bold;
font-size: 10px;
cursor: pointer;
float: right;
margin-bottom: 10px;

right:12px;
top:10px;
   
`
export default DRSTable