import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'


export const apiPMInState = (stateId: string) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/profiles`,
    params: {
      role: RoleId.ProjectManager,
      state: stateId,
    }
  }
  return ax(options)
}