import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  crewID: string,
  start: string,
  end: string
}

export const apiCrewtNoteSearch = ({ crewID, start, end }: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/crewNotes`,
    params: {
      crewID: crewID,
      start: start,
      end: end,
      'reqDev': 'web'
    }
  }
  return ax(options)
}