import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import DepotAutocomplete from '../../../../../components/autocompletes/DepotAutocomplete'

interface Options extends DRS.Comp {
  label?: string,
}

function FieldDepot ({
  label = '',
  field,
  onChange,
}: Options) {
  const { id, name } = field.data
  const selectedItem = {
    value: id,
    label: name,
  }
  const handleChange = (item: AIMS.ValueLabel) => {
    onChange({
      id: item.value,
      name: item.label,
    })
  }
  return <Wrapper>
    <FormControl fullWidth>
      <DepotAutocomplete 
        label={label}
        selectedItem={selectedItem}
        onChange={handleChange}
        fullWidth={true}
      />
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
`

const Content = styled.div``

export default FieldDepot