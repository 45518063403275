import React from 'react'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { VMPSiteRule } from '@src/types/VMPSiteMap'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import useModal from '@src/components/FormModal/useModal'
import { useVMP } from '@src/modules/VehicleMovementPlan/useVMP'
import { useBundle } from '@src/utils/hooks/useBundle'
import { observer } from 'mobx-react-lite'
import { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import Modal from '@src/components/FormModal/Modal'

interface Props {
  index: number,
  onSave(rules: VMPSiteRule[]): Promise<any>,
}

const CompVMPMapRules = (props: Props) => {
  const {
    index,
    onSave,
  } = props
  const { alertDone, alertError, alert } = useSnackbar()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<VMPSiteRule>({})
  const [ vmp, loading, refresh ] = useVMP()
  const [ bundle ] = useBundle()
  const { vmpDefaultSiteRules } = bundle
  if (vmp === null || !bundle || index < 0 || index >= vmp.siteMaps.length) {
    return null
  }
  const isEditable = (rule: VMPSiteRule) => ![1, 2, 3, 4].includes(rule.id)
  const vmpSiteMapRules = vmp.siteMaps[index].rules
  const handleEdit = (row: VMPSiteRule) => {
    openAndSetData(row)
  }
  const handleSave = async (form: VMPSiteRule) => {
    if (form.text.length > 120) {
      alert({
        type: 'warning',
        message: `Please, keep rule text to maximum 120 characters`,
      })
      return 
    }
    if (!modalData) {
      try {
        await onSave([
          ...vmpSiteMapRules,
          form,
        ])
        alertDone()
        refresh()
        closeModal()
      } catch (e) {
        alertError()
      }
    } else {
      const retainedIndex = vmpSiteMapRules.findIndex(rule => rule.id === form.id)
      if (retainedIndex === -1) {
        return
      }
      const retained = vmpSiteMapRules[retainedIndex]
      try {
        await onSave([
          ...vmpSiteMapRules.slice(0, retainedIndex),
          form,
          ...vmpSiteMapRules.slice(retainedIndex + 1),
        ])
        alertDone()
        refresh()
        closeModal()
      } catch (e) {
        alertError()
      }
    }
  }
  return <Wrapper>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              Edit
            </TableHeaderCell>
            <TableHeaderCell>
              Product
            </TableHeaderCell>
            <TableHeaderCell>
              Required
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { [...vmpDefaultSiteRules, ...vmpSiteMapRules].map((rule: VMPSiteRule, index: number) => {
            return <TableRow 
              hover
              key={rule.id} 
            >
              <SCell>
                { isEditable(rule) && 
                  <IconButton size='small' onClick={() => handleEdit(rule)}><EditIcon /></IconButton>
                }
              </SCell>
              <SCell>
                { `Rule ${index + 1}` }
              </SCell>
              <SCell>
                { rule.text }
              </SCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <MarginTop>
      <CompCreateButton onClick={() => {
        openAndSetData(null)
      }}>
        Add Rule 
      </CompCreateButton>
    </MarginTop>
    { open && <Modal<VMPSiteRule>
      maxWidth='sm'
      open={open}
      title='Edit Site Rule'
      data={{
        rowData: modalData,
        fields: RuleFields,
        columns: [
          {
            title: 'Info',
            fieldKeys: [
              'text',
            ],
          },
        ]
      }}
      onSuccess={handleSave}
      onClose={closeModal}
      onCancel={closeModal}
    /> 
    }
  </Wrapper>
}

const Wrapper = styled.div`

`
const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const SCell = styled(TableCell)``
export const RuleFields: FormTableField<VMPSiteRule>[] = [
  {
    key: 'text',
    type: FormTableFieldType.Textarea,
    label: 'Rule Text',
  },
]
export default observer(CompVMPMapRules)