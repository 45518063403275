import React from 'react'
import styled, { css } from 'styled-components'
import Link from '@src/components/Link'
import Typography from '@material-ui/core/Typography'
import aimsLogo from '@src/assets/pdf_file_icon.png'
import aimsLogoWhite from '@src/assets/pdf_file_icon_white.png'
interface Props {
  link: string,
  left: string,
  isNight:boolean
}
const PDFIcon = ({
  link,
  left,
  isNight
}:Props) => {
  const handleClick = () => {
    window.open('#'+link,'_blank')
  }
 
  return <StyledLogoWrapper  style={{left:left}}  >
    <WhiteLink onClick={handleClick} >
      <img src={isNight ? aimsLogoWhite : aimsLogo} width='24' />
    </WhiteLink>
  </StyledLogoWrapper>
}

const StyledLogoWrapper = styled(Typography)<{ left: string }>`
position: absolute;
z-index:100;
top: 4px;

` as typeof Typography
const WhiteLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

export default PDFIcon