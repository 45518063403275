import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import _get from 'lodash/get'

const renderContent = (field: DRS.Field) => {
  if (!field.typeMeta!.textContentPath) {
    // use the raw one
    return field.data
  } else {
    // debugger
    // console.log(field.data, field.textContentPath)
    return _get(field.data, field.typeMeta!.textContentPath.split('-').join('.'))
  }
}

const FieldText = ({
  field,
  // onChange,
}: {
  field: DRS.Field,
}) => {
  return <Wrapper>
    <Content>
      <Typography>
        {renderContent(field)}
      </Typography>
    </Content>
  </Wrapper>
}

const Wrapper = styled.div``
const Content = styled.div``

export default FieldText