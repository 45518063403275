import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'

interface Options {
  crewPackId: string,
  serialId:number,
}

export const apiGetFilesByCrewPackId = ({
  crewPackId,
  serialId
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/documents/${PackFormType.CREW_PACK_PHOTO}/${crewPackId}`,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}