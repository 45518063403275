import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import usePermission from '@src/utils/hooks/usePermission'
import PDFIcon from '@material-ui/icons/PictureAsPdf'

interface Props {
  activeIndex: number,
  onSubmitATP(): void,
  
  onDownloadPDFMap(): void,
}

const CompATPActionMenu = (props: Props) => {
  const {
    activeIndex,
    onSubmitATP,
   
    onDownloadPDFMap,
  } = props
  const hideCreateAction = activeIndex !== 0
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const beforeSubmit = async () => {
    try {
     
      onSubmitATP()
      setAnchorEl(null)
    } catch(e) {

    }
  }
  const beforeUpdate = async () => {
    try {
      await confirm({
        title: `Confirmation on Update ATP`,
        message: `Sure?`,
        confirmButtonText: 'Update',
      })
      onSubmitATP()
      setAnchorEl(null)
    } catch(e) {

    }
  }


 
  
  return <Wrapper>
    { true &&
      <IconButton
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
    }
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={handleClose}
    >
      { !hideCreateAction &&  <MenuItem onClick={beforeSubmit} disabled={false}>
        <SListItemIcon>
          <SendIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Add Form</Typography>
      </MenuItem>
}

    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
` 

export default CompATPActionMenu