import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _orderBy from 'lodash/orderBy'
import _clone from 'lodash/clone'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import _reject from 'lodash/reject'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAppStore } from '@src/AppStore'
import { Checkbox } from '@material-ui/core'
import FormModalTextareaLarge from '@src/components/FormModal/TextareaLarge'
import { getLength } from '@src/utils/validateLength'

import { Attachment } from '@src/types/Attachment'
import ATPUploadAttachmentTrigger from '../parts/UploadTrigger'
export interface ATPDocumentDialogProps {

  open: boolean,
  onClose(): void,
  onCancel(): void,
  onSuccess(): void,
  editMode: boolean,
  isLoader: boolean,
  attachment?: Attachment,
  currentSelectedFileName?: [],
  onSave(obj: any): void
  onFileSlect(files: FileList, imageFiles: FileList, nonImageFiles: FileList): void,
  normalAttachments: any
}

const ATPDocumentDialog = (props: ATPDocumentDialogProps) => {
  const { open, onClose, onCancel, onSuccess, onFileSlect, normalAttachments, editMode, onSave, isLoader, attachment, currentSelectedFileName } = props
  const { serialId, defaultRoleId } = useAppStore()

  const [checkFilter, setCheckFilter] = useState(true);
  const [isSystemAdmin, setSystemAdmin] = useState(true);
  const [isAdmin, setAdmin] = useState(true);
  const [isRsc, setRsc] = useState(true);
  const [isPM, setPM] = useState(true);
  const [isSup, setSup] = useState(true);
  const [isFor, setFor] = useState(true);
  const [isCommentSection, setCommentSection] = useState(editMode);

  const [noteDesc, setNoteDesc] = useState<string>('');
  const [permissionRole, setPermissionRole] = useState([true, true, true, true, true])
  const [isLoading, setIsLoading] = useState<boolean>(isLoader)
  const [fieldsInError, setFieldsInError] = useState<any[]>([])
  let validateNotes: any = null;


  useEffect(() => {
    setIsLoading(isLoader);
  }, [isLoader])
  useEffect(() => {
    if (attachment) {
      let currentComment: any = attachment?.cpDocComment?.toString();
      setNoteDesc(currentComment.toString());
    }else{
      setNoteDesc('');
    }

  }, [attachment])
  const handleOk = async () => {
    //   setIsLoading(true);
    onSave([isAdmin, isRsc, isPM, isSup, isFor, noteDesc]);
  }
  const setAll = async () => {
    setCheckFilter(!checkFilter);
    if (defaultRoleId.indexOf('ADMINISTRATOR') == -1)
      setAdmin(!checkFilter);
    if (defaultRoleId != 'RESOURCE_SCHEDULER')
      setRsc(!checkFilter);
    if (defaultRoleId != 'PROJECT_MANAGER')
      setPM(!checkFilter);
    if (defaultRoleId != 'SUPERVISOR')
      setSup(!checkFilter);
    if (defaultRoleId != 'FOREMAN')
      setFor(!checkFilter);

      console.log(checkFilter+'--->'+isAdmin+'--admnin',isSup+'--isSup',isRsc+'--isRsc',isFor+'--isFor',isPM+'--isPM');
  }
  const updateAll = async () => {
    
   /// setTimeout(function(){
      console.log(isAdmin,isRsc,isPM,isFor,isSup);
      if(isAdmin) {
        setCheckFilter(false);
        return;
      }
      if(isRsc) {
        setCheckFilter(false);
        return;
      }
      if(isPM) {
        setCheckFilter(false);
        return;
      }
      if(isFor) {
        setCheckFilter(false);
        return;
      }
      if(isSup) {
        setCheckFilter(false);
        return;
      }
      setCheckFilter(true);
      
   // },1000)
    
   
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var currentValue: string = ((event.target as HTMLInputElement).value);

    switch (currentValue) {
      case 'ALL':
        setAll()
        break;
      case 'SYSTEM_ADMINISTRATOR':
        setSystemAdmin(!isSystemAdmin);
        updateAll();
        break;
      case 'ADMINISTRATOR':
        setAdmin(!isAdmin);
        updateAll();
        break;
      case 'RESOURCE_SCHEDULER':
        setRsc(!isRsc);
        updateAll();
        break;
      case 'PROJECT_MANAGER':
        setPM(!isPM);
        updateAll();
        break;
      case 'SUPERVISOR':
        setSup(!isSup);
        updateAll();
        break;
      case 'FOREMAN':
        setFor(!isFor);
        updateAll();
        break;
    }


  }


  const enableComment = async () => {
    setCommentSection(true)
  }
  return <Container maxWidth='sm'>
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={onClose}
    >

      <DialogTitle style={{ textAlign: 'center' }} >
        {'Upload PDF'}

      </DialogTitle>
      <DialogContent>
        <SectionWrapper>
          <Section>
            <DialogContentText>

            </DialogContentText>
          </Section>


        <Section>

            {isLoading && <SpinnerWrapper><Spinner size={24} /></SpinnerWrapper>}
            {!isLoading && !editMode && <TriggerWrapper>
              <ATPUploadAttachmentTrigger accept='application/pdf' id='drs-attach-actual' onSelect={onFileSlect} attachments={normalAttachments} />
            
            
            </TriggerWrapper>}
            

          </Section>
          {
            currentSelectedFileName?.map((item: any) => { 
           return <Section>
            {item}
            </Section>
            })  
          }
        </SectionWrapper>
      </DialogContent>
      <DialogActions>
        {

          <React.Fragment>
            <Button onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              color='primary'
              variant='contained'
              onClick={handleOk}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </React.Fragment>
        }



      </DialogActions>
    </Dialog>
  </Container>
}

const SectionWrapper = styled.div`
`

const Section = styled.div`
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`
const TriggerWrapper = styled.div`
  margin-right: 16px;
`
const SortControlsWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  width: 75%;
`

const ButtonSpinner = styled(CircularProgress)`
  margin-right: 10px;
`
const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width:400px;
`
export default ATPDocumentDialog