import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage' 
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import SummaryInfoFields, { SummaryInfoRowDisplay } from './SummaryInfoFields'

interface SummaryInfoRow extends SummaryInfoRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const CompDRSSummaryInfo = () => {
  const { id } = useParams<{id: string}>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const { alertError } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<SummaryInfoRow>({})
  if (drs === null) {
    return null
  }
  const hanldeEditRow = (row: SummaryInfoRow) => {
    openAndSetData(row)
  }
  const hanldeRemoveRow = async (row: SummaryInfoRow) => {

  }
  const handleSave = async (form: SummaryInfoRow) => {
    const retainedDetails = drs.summary.detail
    const retainedSummary = drs.summary.summary
    const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
    try {
      if (form._no_ === undefined || form._type_ === undefined) {
        return
      }
      const result = await updateSectionViaPath({
        sectionName: DRSSectionName.Summary,
        path: `summary`,
        data:{
          ...retainedSummary,
          [updatingColumn]: {
            ...form
          }
        },
      })
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  }
  function getTableData() {
    if (drs === null) {
      return []
    }
    const rows = [ drs.summary.summary ]
    if (!rows || !rows.length) {
      return []
    }
    
    return doubleUp(rows, drsPageType)
  }
  return <Wrapper>
    <FormPanel title={`DRS Summary`}>
      <FormPAETable<SummaryInfoRow>
        fields={SummaryInfoFields}
        data={getTableData()}
        editable={true}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
        showMoreActions={false}
      />
    </FormPanel>

    { open && 
      <Modal<SummaryInfoRow>
        maxWidth={'sm'}
        open={open}
        title='DRS Summary'
        data={{
          rowData: modalData,
          fields: SummaryInfoFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'submittedBy',
                'qualityOfWorkmanship',
                'comments',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'satisfiedCustomerNeeds',
                'cleanUpAtEndOfShift',
                'submittedByDeclaration',
              ],
            },
          ],
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSSummaryInfo