import { BASE_API_URL } from '../constants'
import { ax } from '@src/utils/axios'
import axios, { Canceler } from 'axios'
import { Depot } from '@src/types/common/Depot'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import { ProjectsResult } from '@src/types/api/Result'
let cancel: Canceler

interface Options {
  userSerialId?: number,
  depot: Depot,
  status: ProjectStatus,
}

interface QueryOptions {
  query: string,
  isLinked?: boolean,
  status?: ProjectStatus | ProjectStatus[],
}

export const apiProjects = (options: Options) => {
  const { userSerialId, depot, status } = options
  if (cancel) {
    cancel()
  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/projects`,
    params: {
      userSerialId,
      depot: depot.id,
      status,
    },
    cancelToken: new axios.CancelToken((c: Canceler) => {
      cancel = c
    }),
  }
  return ax(axOptions)
}

export const apiQueryProjects = (options: QueryOptions): Promise<ProjectsResult> => {
  const {
    query,
    isLinked,
    status,
  } = options

  if (cancel) {
    cancel()
  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/projects`,
    params: {
      search: query,
      status,
      isLinked,
    },
    cancelToken: new axios.CancelToken((c: Canceler) => {
      cancel = c
    }),
  }
  return ax(axOptions)
}