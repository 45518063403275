import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { getLength } from '@src/utils/validateLength'
import { useAppStore } from '@src/AppStore'
import FormModalTextareaLarge from '@src/components/FormModal/TextareaLarge'
import usePermission from '@src/utils/hooks/usePermission'
import { apiCrewtNoteCreate } from '@src/apis/crew-note-create'
import { CrewNote } from '@src/types/Shift'
import { Crew } from '@src/types/Crew'
import { apiCrewtNoteUpdate } from '@src/apis/crew-note-update'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import moment, { Moment } from "moment-timezone";

interface Props {
  open: boolean,
  onClose(): void,
  onSave(): void,
  data: null | CrewNote
  originCrew: Crew,
  originDate: string,
}

const CompCrewNoteDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
    data,
    originCrew,
    originDate,
  } = props
  const isAdd = true;
  const [noteColour, setNoteColour] = useState<string>('')
  const [noteDesc, setNoteDesc] = useState<string>('')
  const { username, depotId } = useAppStore()
  const { alertDone, alertError, alert } = useSnackbar()
  const { canEditNote } = usePermission()
  const [isSaving, setIsSaving] = useState<any>(false)
  const [fieldsInError, setFieldsInError] = useState<any[]>([])

  const removeStyle = () => {
    document.getElementById('#C1E1E2')?.removeAttribute('style');
    document.getElementById('#E2F0CB')?.removeAttribute('style');
    document.getElementById('#D6CFCB')?.removeAttribute('style');
    document.getElementById('#FFFFBF')?.removeAttribute('style');
    document.getElementById('#FF9AA2')?.removeAttribute('style');
    document.getElementById('#FFDAC1')?.removeAttribute('style');
    document.getElementById('#FFB7B2')?.removeAttribute('style');
    document.getElementById('#D9BBE6')?.removeAttribute('style');
    document.getElementById('#FF9AA2')?.removeAttribute('style');
    document.getElementById('#C1E1E2')!.style.border! = 'none';
    document.getElementById('#E2F0CB')!.style.border! = 'none';
    document.getElementById('#D6CFCB')!.style.border! = 'none';
    document.getElementById('#FFFFBF')!.style.border! = 'none';
    document.getElementById('#FF9AA2')!.style.border! = 'none';
    document.getElementById('#FFDAC1')!.style.border! = 'none';
    document.getElementById('#FFB7B2')!.style.border! = 'none';
    document.getElementById('#D9BBE6')!.style.border! = 'none';
    document.getElementById('#FF9AA2')!.style.border! = 'none';
  }

  const dateModified = moment(data?.DateModified).format('YYYY-MM-DD hh:mm:ss').toString();
  const dateCreated = moment(data?.DateCreated).format('YYYY-MM-DD hh:mm:ss').toString();
  const isEditedNote = dateCreated !== dateModified

  const handleClickColor = (event: React.MouseEvent<HTMLElement>) => {
    var trget: any = event.currentTarget;
    if (canEditNote) {
      setNoteColour(event.currentTarget.id.toLowerCase());
      removeStyle();
      trget.style = 'border: 3px solid grey';
    }
  }
  useEffect(() => {
    const fetchPack = async () => {
      try {
        if (data != null) {
          setNoteColour(data.RGBColorCode);
          setNoteDesc(data.Comment);
        }

      } catch (e) {

      }
    }
    fetchPack()
  }, [data])
  let validateNotes: any = null;

  const handleSave = async () => {

    if (!noteColour) {

      alert({
        type: 'error',
        message: 'Note colour required',
      })
      return
    }

    if (!noteDesc) {

      alert({
        type: 'error',
        message: 'Note description required',
      })
      return
    }
    if (noteDesc.length > 200) {
      alert({
        type: 'error',
        message: 'Max 200 characters allowed in Note description.',
      })
      return
    }

    setIsSaving(true)
    if (isAdd && data == null) {
      try {
        apiCrewtNoteCreate({
          Comment: noteDesc,
          RGBColorCode: noteColour,
          Date: moment(originDate).format('YYYY-MM-DD hh:mm:ss'),
          CreatedBy: username,
          CrewID: originCrew.id,
          ModifiedBy: username,
          Active: true,
        }).then((response: any) => {
          alertDone()
          setIsSaving(false)
          // window.location.reload();
          onSave()
        })
      } catch (e) {
        alertError()
      }
    } else {
      try {
        apiCrewtNoteUpdate({
          CrewCalendarNotesID: data == null ? '' : data.CrewCalendarNotesID,
          Comment: noteDesc,
          RGBColorCode: noteColour,
          CreatedBy: username,
          Active: true,
          Date: originDate,
          ModifiedBy: username
        }).then((response: any) => {

          alertDone()
          setIsSaving(false)
          // window.location.reload();
          onSave()
        })
      } catch (e) {
        alertError()
      }
    }
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>Crew Note</ModalTitle>
    </DialogTitle>
    <DialogContent>
      <SectionWrapper>
        <Section>
          <FieldsWrapper >
            <ColorWrapper >
              <FieldsWrapperBlue id="#C1E1E2" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor}   ></FieldsWrapperBlue>
              <FieldsWrapperGreen id="#E2F0CB" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperGreen>
              <FieldsWrapperGrey id="#D6CFCB" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} > </FieldsWrapperGrey>
              <FieldsWrapperYellow id="#FFFFBF" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperYellow>
              <FieldsWrapperOrange id="#FF9AA2" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperOrange>
              <FieldsWrapperMaroon id="#FFDAC1" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperMaroon>
              <FieldsWrapperPink id="#FFB7B2" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperPink>
              <FieldsWrapperPurple id="#D9BBE6" colorCode={data == null ? '' : data.RGBColorCode} onClick={handleClickColor} ></FieldsWrapperPurple>
            </ColorWrapper>
          </FieldsWrapper>

          <FieldWrapper>
            <FormModalTextareaLarge label={'Note description'} defaultValue={noteDesc.replace('blank', '')} rows={6} disabled={!canEditNote} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

              var val = e.target.value;
              if (validateNotes != null) {
                clearTimeout(validateNotes)
              }
              validateNotes = setTimeout(() => {
                if (getLength(val, 200)) {
                  setFieldsInError(_union(fieldsInError, [{ key: 'Note description', helperTextError: "Max 200 characters allowed" }]))
                } else {
                  setFieldsInError(_reject(fieldsInError, { key: 'Note description' }))
                }
              }, 500)
            }}
              onBlur={(e) => {
                setNoteDesc(e.target.value)
              }}
              helperText={
                _some(fieldsInError, { key: 'Note description' }) ?
                  _find(fieldsInError, { key: 'Note description' }).helperTextError
                  : ""
              }
              error={_some(fieldsInError, { key: 'Note description' })}
            ></FormModalTextareaLarge>

          </FieldWrapper>

        </Section>
      </SectionWrapper>

      {
        data !== null && (!isEditedNote ? (
          <>
            <DetailsyText >
              Created by: {noteDesc === 'blank' ? '' : data?.CreatedBy}
            </DetailsyText>
            <DetailsyText>
              Created at: {noteDesc === 'blank' ? '' : dateCreated}
            </DetailsyText>
          </>
        ) : (<>
          <>
            <DetailsyText >
              Created by: {noteDesc === 'blank' ? '' : data?.CreatedBy}
            </DetailsyText>
            <DetailsyText>
              Created at: {noteDesc === 'blank' ? '' : dateCreated}
            </DetailsyText>
            <DetailsyText>
              Last edited: {noteDesc === 'blank' ? '' : dateModified}
            </DetailsyText>
            {/*TODO: check api for ModifiedBy*/}
            {data?.ModifiedBy && <DetailsyText>
              Modified by: {data?.ModifiedBy}
            </DetailsyText>}
          </>
        </>)
        )}

    </DialogContent>
    <DialogActions>
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
        onClose()
      }}>
        Cancel
      </Button>

      <Button color='primary' variant='contained' onClick={handleSave} disabled={isSaving || fieldsInError.length > 0 ? true : false || !canEditNote}>
        {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
        {!isSaving && 'SAVE '}
      </Button>

    </DialogActions>
  </Dialog>
}

const ColorWrapper = styled.div`

display:list;
`
const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const FieldsWrapperBlue = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #C1E1E2;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#C1E1E2" ? "3px solid grey" : "none"}
 
`
const FieldsWrapperGreen = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #E2F0CB;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#E2F0CB" ? "3px solid grey" : "none"}
`
interface colorProps {
  colorCode: string
}
const FieldsWrapperGrey = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #D6CFCB;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#D6CFCB" ? "3px solid grey" : "none"}
`
const FieldsWrapperYellow = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #FFFFBF;
    text-align: center;
    line-height: 48px;
    color: #ffff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#FFFFBF" ? "3px solid grey" : "none"}
`
const FieldsWrapperOrange = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #FF9AA2;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#FF9AA2" ? "3px solid grey" : "none"}
`
const FieldsWrapperMaroon = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #FFDAC1;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#FFDAC1" ? "3px solid grey" : "none"}
`

const FieldsWrapperPink = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #FFB7B2;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#FFB7B2" ? "3px solid grey" : "none"}
`

const FieldsWrapperPurple = styled.div<colorProps>`
width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #D9BBE6;
    text-align: center;
    line-height: 48px;
    color: #fff;
    display:inline-block;
    cursor:pointer;margin-left:5px;
    border: ${props => props.colorCode.toUpperCase() === "#D9BBE6" ? "3px solid grey" : "none"}
`

const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width:400px;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`

const DetailsyText = styled.div`
  font-size: 16px;
  margin: 10px;
`

export default CompCrewNoteDialog