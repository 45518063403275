import React, { SyntheticEvent, ChangeEvent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function FieldSelect ({
  field,
  onChange,
}: DRS.Comp) {
  const { options } = field.typeMeta!
  const handleChange = (e: ChangeEvent<{ value: any }>) => {
    const incomingValue = (e.target as HTMLSelectElement).value
    // const incomingOption = options.find(option => option.value === incomingValue)
    // just need to change the id
    const selectResult = {
      id: (e.target as HTMLSelectElement).value
    }
    onChange(selectResult)
  }
  let selectedId = ''
  if (field.data && field.data.id) {
    selectedId = field.data.id
  }
  return <Wrapper>
    <FormControl fullWidth>
      <InputLabel htmlFor={`${field.path}${field.name}`}>{field.name}</InputLabel>
      <Select
        placeholder={field.name}
        value={selectedId}
        onChange={handleChange}
        inputProps={{
          name: field.name,
          id: `${field.path}${field.name}`,
        }}
      >
        { options!.map((option: AIMS.ValueLabel) => {
          const { value, label } = option
          return <MenuItem key={value} value={value}>{label}</MenuItem>
        })}
      </Select>
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  padding-top: 8px;
`
const Content = styled.div``

export default observer(FieldSelect)

