import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

/**
 * GET /ref/products

search: Product ID
state: State ID
region: Region ID
plant: Plant ID
 */

interface Options {
  query: string,
  plantId?: string,
}

export const apiProductSearch = ({
  query,
  plantId,
}: Options) => {
  if (!query) return
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/products`,
    params: {
      search: query,
      plant: plantId,
    }
  }
  return ax(options)
}