import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  label: string,
  value: string,
  halfWidth?: boolean,
  flat?: boolean,
}

export const PlaceholderPreviewFieldPair = {
  label: '',
  value: '',
}
const CompPreviewFieldPair = (props: Props) => {
  const { label, value, halfWidth = false, flat = false } = props
  return <Wrapper halfWidth={halfWidth} flat={flat}>
    <Label flat={flat}>{ label }</Label>
    <Value flat={flat}>{ value }</Value>
  </Wrapper>
}

const Wrapper = styled.div<{ halfWidth: boolean, flat: boolean }>`
  ${props => props.halfWidth && `width: 120px;`}
  margin: 8px 0;
  ${props => {
    return props.flat && `display: flex;`
  }}
`
const Value = styled.div<{ flat: boolean }>`
  ${props => {
    if (props.flat) {
      return 'width: 130px;'
    }
  }}
  font-size: 12px;
  line-height: 15px;
  min-height: 15px;
  color: #9A9898;
  word-break: break-word;
  padding-top: 2px;
`
const Label = styled.div<{ flat: boolean }>`
  ${props => {
    if (props.flat) {
      return css`
        width: 124px;
        margin-right: 6px;
      `
    }
  }}
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9A9898;
  margin-bottom: 6px;
  word-break: break-word;
`

export default CompPreviewFieldPair