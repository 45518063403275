import React from 'react'
import styled from 'styled-components'
import { FieldName } from './Field'
import { ProjectTypeCircle } from '../../../../ProjectManager/comps/ProjectDialog/ProjectTypeRadio'
import { getProjectTypeLabel } from '../../../../../constants/project-types-enum'

const renderContent = (typeId: string) => {
  return <ContentWrapper>
    <SmallerCircle type={typeId} />
    <ContentText>{getProjectTypeLabel(typeId)}</ContentText>
  </ContentWrapper>
} 

const FieldJobType = ({
  field,
}: {
  field: DRS.Field
}) => {
  return <React.Fragment>
    <FieldName>
      {field.name}
    </FieldName>
    <JobType jobTypeId={field.data.id} />
  </React.Fragment>
  
 }


 export function JobType ({
   jobTypeId,
 }: {
  jobTypeId: string,
 }) {
   return renderContent(jobTypeId)
 }

 const ContentWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
`
const ContentText = styled.div`

`
const SmallerCircle = styled(ProjectTypeCircle)`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 0px;
` as typeof ProjectTypeCircle

 export default FieldJobType