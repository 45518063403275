import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'


interface Props {
  label: string,
  defaultValue: string,
  rows:number,
  type?: 'text' | 'number',
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
  onBlur(e: any): void,
  error?: boolean,
  helperText: string
  disabled:boolean
  
}

const FormModalTextareaLarge = (props: Props) => {
  const {
    label,
    type="text",
    defaultValue,
    onChange,
    rows,
    onBlur, 
     error,
    helperText,
    disabled,
    
  } = props
  // console.log(typeof value, value)
  return <Wrapper
    multiline
    variant='outlined'
    rows={rows}
  
    label={label}
    defaultValue={defaultValue}
    onChange={onChange}
    onBlur={onBlur}
    error={error}
    helperText={helperText}
    disabled={disabled}
    

    
  >

  </Wrapper>
}
FormModalTextareaLarge.defaultProps = {
  error: false,
  disabled: false,
  helperText: "",

  onBlur: () => {}
}
const Wrapper = styled(Textfield)`
width:400px;
`

export default FormModalTextareaLarge