import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'


function CompDRSSiteDiaryNumberDialog ({
  open,
  handleClose,
  handleOk,
}: {
  open: boolean,
  handleClose(): void,
  handleOk(siteDiaryNumber: string): void,
}) {
  const [ inputValue, setInputValue ] = useState<string>('')
  return <Dialog 
    fullWidth
    maxWidth={'sm'}
    open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
    <DialogTitle id='form-dialog-title'>Enter Site Diary Number</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin='dense'
        id='Site Diary Number'
        label='Site Diary Number'
        type='text'
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        fullWidth
      />
      {
        Boolean(inputValue) && <DialogContentText>
          DRS is ready to be marked complete. A completed DRS can not be edited
        </DialogContentText>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Cancel
      </Button>
      <Button onClick={e => {
        if (inputValue) {
          handleOk(inputValue)
        }
      }} color='primary'>
        Mark DRS as Complete
      </Button>
    </DialogActions>
  </Dialog>
}

export default CompDRSSiteDiaryNumberDialog