import { ax } from '../../utils/axios/axios'
import { BASE_API_URL } from '../../constants'

export interface apiCrewListOptions {
  depotId?: string,
  regionId?: string,
  active?: boolean,
}

export const apiCrewList = ({
  depotId,
  regionId,
  active,
} : apiCrewListOptions) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/crews`,
    params: {
      depot: depotId,
      region: regionId,
      active,
    }
  }
  return ax(options)
}