import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompVMPMapRules from './parts/Rules'
import CompVMPMapNotes from './parts/SiteDiagramNotes'
import CompVMPSiteDiagram from './parts/SiteDiagram'
import BoralSwitch from '@src/components/BoralSwitch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { VMPSiteDiagram, VMPSiteRule } from '@src/types/VMPSiteMap'
import { apiVMPUpdateSiteMap } from '@src/apis/vmp-update-site-map'
import { useVMP } from '../../useVMP'
import { useAppStore } from '@src/AppStore'

interface Props {
  activeIndex: number,
  primaryMapIndex?: number,
}

const CompVMPMap = (props: Props) => {
  const {
    activeIndex,
    primaryMapIndex,
  } = props
  const [vmp] = useVMP()
  const { serialId } = useAppStore()
  if (vmp === null) {
    return null
  }
  const siteMapIndex = activeIndex - 1
  const handleSaveSiteDiagram = (siteDiagram: VMPSiteDiagram) => {
    return new Promise(async (resolve, reject) => {
      try {
        await apiVMPUpdateSiteMap({
          vmpId: vmp.id,
          siteMapId: vmp.siteMaps[siteMapIndex].siteDiagram.id,
          serialId,
          siteMap: {
            ...vmp.siteMaps[siteMapIndex],
            siteDiagram,
          },
        })
        resolve({})
      } catch (e) {

      }
    })
  }
  const handleSaveSiteRules = (rules: VMPSiteRule[]) => {
    return new Promise(async (resolve, reject) => {
      try {
        await apiVMPUpdateSiteMap({
          vmpId: vmp.id,
          siteMapId: vmp.siteMaps[siteMapIndex].siteDiagram.id,
          serialId,
          siteMap: {
            ...vmp.siteMaps[siteMapIndex],
            rules,
          },
        })
        resolve({})
      } catch (e) {

      }
    })
  }
  return <Wrapper>
    <FormSectionTitle>{`SITE MAP ${activeIndex}`}</FormSectionTitle>
    <FormPanel title={`Site Diagram`}>
      <CompVMPSiteDiagram index={siteMapIndex} />
    </FormPanel>
    <FormPanel title={`Site Information`}>
      <CompVMPMapNotes
        index={siteMapIndex}
        onSave={handleSaveSiteDiagram}
      />
    </FormPanel>
    <FormPanel title={`Rules`}>
      <CompVMPMapRules
        index={siteMapIndex}
        onSave={handleSaveSiteRules}
      />
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`
  position: relative;
`
const PrimarySetter = styled.div`
  position: absolute;
  left: 232px;
  top: 3px;
  background: #F2F8F7;
  border-radius: 8px;
  padding-left: 14px;
`

export default CompVMPMap