import { default as ENV_DEV } from './env-dev'
import { default as ENV_UAT } from './env-uat'
import { default as ENV_PROD } from './env-prod'

export const getEnvVariables = () => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return ENV_DEV
  } else if (process.env.REACT_APP_ENV === 'uat') {
    return ENV_UAT
  } else if (process.env.REACT_APP_ENV === 'prod') {
    return ENV_PROD
  }
  return ENV_DEV
}