import { useEffect, useState } from 'react'
import { useAppStore } from '@src/AppStore'
import { Shift } from '@src/types/Shift'
import { apiMobileAssetPrestartsByShiftId } from '@src/apis/mobile-asset-prestarts-by-shift'
import { MobileAssetPrestartBrief } from '@src/types/MobileAssetPrestart'

export interface MobileAssetPrestartBriefGroup {
  type: {
    id: number,
    description: string,
  },
  maps: MobileAssetPrestartBrief[]
}

export function useMobileAssetPrestartsByShiftId(shiftId: string): [ MobileAssetPrestartBriefGroup[] ] {
  const [ prestartsGroups, setPrestartsGroups ] = useState<MobileAssetPrestartBriefGroup[]>([])
  const { serialId } = useAppStore()
  let active = false
  useEffect(() => {
    async function fetchShift() {
      try {
        const result = await apiMobileAssetPrestartsByShiftId({
          shiftId: shiftId,
          serialId,
        })
        if (active) {
          setPrestartsGroups(result.data.maps as MobileAssetPrestartBriefGroup[])
        }
      } catch(e) {
        
      }
    }
    if (!active) {
      active = true
      fetchShift()
    }
    return () => {
      active = false
    }
  }, [])
  return [ prestartsGroups ]
}