import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'

interface Props {
  tabs: Tab[],
  activeIndex: number,
  onChange(tab: Tab, index: number): void,
}

export interface Tab {
  key: string | number,
  label: string,
}

const CompPillTabs = (props: Props) => {
  const {
    tabs,
    activeIndex,
    onChange,
  } = props
  if (activeIndex < 0 || activeIndex >= tabs.length) {
    return null
  }
  return <Wrapper>
    { tabs.map((tab, index) => {
      return <SButton 
        key={tab.label}
        activated={index === activeIndex ? 1 : 0} 
        onClick={() => {
          onChange(tabs[index], index)
        }}
      >
        { tab.label }
      </SButton>
    })}
  </Wrapper>
}

const Wrapper = styled.div`

`
const SButton = styled(Button)<{ activated: 1 | 0 }>`
  background-color: #fff;
  border: 1px solid #189688;
  border-radius: 16px;
  font-size: 12px;
  color: #189688;
  text-transform: none;
  padding: 4px 12px 2px;
  &:not(:first-child) {
    margin-left: 8px;
  }
  ${props => {
    if (props.activated) {
      return css`
        cursor: default;
        color: #fff;
        background-color: #189688;
      `
    }
  }}
  &:hover {
    ${props => {
      if (props.activated) {
        return css`
          background-color: #189688;
        `
      }
    }}
  }
`

export default CompPillTabs