import React from 'react'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

export interface FormTableProps<T> {
  fields: FormTableField<T>[],
  data: T[],
  editable?: boolean,
  onEditRow?(row: T): void,
  onRemoveRow?(row: T): void,
  removable?: boolean,
}
interface K {
  id: number | string,
  [key: string]: any,
}

export enum FormTableFieldType {
  Text,
  Number,
  Textarea,
  Autocomplete,
  Select,
  CheckboxGroup,
  BooleanRadioGroup,
  Time,
  Date,
  MultipleAutocomplete,
  Readonly,
}

export interface FormTableField<T> {
  id?: string,
  key: (keyof T),
  type: FormTableFieldType,
  label?: string,
  format?: Function,
  render?: Function,
  options?: any[],
  disabled?: boolean,
  alsoUpdates?: ((keyof T)[]),
  alsoUpdatesFunc?(changingFieldValue: any, form: any): any[],
  timeFormat?: string,
  maxScale?: number,
  length?: number,
  helperTextError?: string,
}

function FormTable<T extends K>(props: FormTableProps<T>) {
  const {
    fields,
    data,
    editable = false,
    removable = true,
    onEditRow,
    onRemoveRow,
  } = props
  function renderTableHeaderLabel(field: FormTableField<T>) {
    const { key, type, label, format } = field
    if (label) {
      return label
    }
    // resort to key displayed as label
    return key
  }
  function renderCellString(field: FormTableField<T>, row: T) {
    const { key, type, label, format } = field
    const cellData: any = row[field.key]
    if (format && typeof format === 'function') {
      return format(cellData)
    }
    return cellData
  }
  return <TableContainer>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {editable &&
            <TableHeaderCell>
              Edit
            </TableHeaderCell>
          }
          {fields.map((field: FormTableField<T>, index: number) => {
            return (
              <TableHeaderCell key={index}>
                {renderTableHeaderLabel(field)}
              </TableHeaderCell>
            )
          })}
          {editable && removable &&
            <TableHeaderCell>
              Remove
            </TableHeaderCell>
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: T, rowIndex: number) => {
          return <TableRow
            hover
            key={row.id || rowIndex}
          // onClick={handleClick(row)}
          >
            {editable &&
              <SCell>
                <IconButton size='small' onClick={() => {
                  if (onEditRow && typeof onEditRow === 'function') {
                    onEditRow(row)
                  }
                }}><EditIcon /></IconButton>
              </SCell>
            }
            {fields.map((field: FormTableField<T>, index: number) => {
              return <SCell key={index}>
                {renderCellString(field, row)}
              </SCell>
            })}
            {editable && removable &&
              <SCell>
                <IconButton size='small' onClick={() => {
                  if (onRemoveRow && typeof onRemoveRow === 'function') {
                    onRemoveRow(row)
                  }
                }}><DeleteIcon /></IconButton>
              </SCell>
            }
          </TableRow>
        })}

      </TableBody>
    </Table>
  </TableContainer>
}

const Wrapper = styled.div`
  /* overflow-x: auto; */
`
const STable = styled(Table)`
  /* display: block;
  white-space: nowrap; */
  /* max-width: calc(100%); */
`
const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const SCell = styled(TableCell)``
export default FormTable