import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import CompDrawingToolDialog from '../comps/DrawingToolDialog'
import Button from '@material-ui/core/Button'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { PackFormType } from '@src/types/PackFormType'
import { RoleId } from '@src/types/common/RoleId'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useAppStore } from '@src/AppStore'
import { BASE_API_URL } from '@src/constants'
import { useVMP } from '@src/modules/VehicleMovementPlan/useVMP'
import { apiGetFiles } from '@src/apis/files/get-files'
import { Attachment } from '@src/types/Attachment'
import { apiRemoveFile } from '@src/apis/files/remove-file'
import { Modal, Box, Dialog, DialogActions, CircularProgress } from '@material-ui/core'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import CompDrawingTool from '../../drawing-tools/DrawingTool'


interface Props {
  index: number,

}
let imageMapData: string, cropImageRef: any;
const CompVMPSiteDiagram = (props: Props) => {
  const {
    index,
  } = props
  const canvasRef = useRef<any>()
  const canvasPreviewRef = useRef<any>()
  const fileRef = useRef<any>()
  const [hasDiagram, setHasDiagram] = useState<boolean>(false)
  const [attachment, setAttachment] = useState<null | Attachment>(null)
  const [open, setOpen] = useState<boolean>(false)

  const [isFill, setIsFill] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [CropModal, setCropModal] = React.useState(false);
  const handleCropModalClose = () => setCropModal(false);
  const [crop, setCrop] = useState({ aspect: 71 / 40 })
  const [cropImage, setcropImage] = useState<any>()
  const [cropedImage, setcropedImage] = useState('')
  const [status, setStatus] = useState<'heremap' | 'captured'>('heremap')
  const [bgDataURLString, setBgDataURLString] = useState<string>('')
  const { alertDone, alertError } = useSnackbar()
  const { serialId, } = useAppStore()
  const [vmp] = useVMP()
  const siteMap = vmp ? vmp.siteMaps[index] : null
  const siteMapId = siteMap ? siteMap.siteDiagram.id : null
  const fileInput = React.createRef();
  const mapCaptured = status === 'captured'
  
  const [exportTrigger, setExportTrigger] = useState(0)
  const compDrawingToolDialogRef: any = useRef();
  async function fetch() {
    const result = await apiGetFiles({
      formType: PackFormType.VEHICLE_MOVEMENT_PLAN_SITE_MAP_DIAGRAM,
      formId: siteMapId!,
      serialId,
    })
    if (result && result.data && result.data.documents && result.data.documents.length) {
      setHasDiagram(true)
      const attachments = result.data.documents as Attachment[]
      setAttachment(attachments[0])
      setIsSaving(false)
    } else {
      setHasDiagram(false)
      setAttachment(null)
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (siteMapId) {
      fetch()
    }
    return () => {
      cropImageRef = null;
    }
  }, [siteMapId])

  if (vmp === null || index < 0 || siteMap === null || siteMap === null) {
    return null
  }

  const handleClick = () => {
    setOpen(true)
  }
  const handleClickUpload = () => {
   
    document.getElementById('fileChange')?.click();
  }
  const handleEvent = (e: any) => {
    setCropModal(true);
    if (e.target.files && e.target.files.length > 0) {
      setcropImage(e.target.files[0]);
      let previewImage = new Image()
      previewImage.onload = function(){
        setTimeout(() => {
          setIsFill(false);
          fileRef.current.value = "";
          const canvas = canvasPreviewRef.current as HTMLCanvasElement
          const context: any = canvas.getContext('2d')
          canvas.width = 710;
          canvas.height = 400;
          context.clearRect(0, 0, canvas.width, canvas.height)
          var scale = Math.min(canvas.width / previewImage.width, canvas.height / previewImage.height);
          var x = (canvas.width / 2) - (previewImage.width / 2) * scale;
          var y = (canvas.height / 2) - (previewImage.height / 2) * scale;
          context.drawImage(previewImage, x, y, previewImage.width * scale, previewImage.height * scale);
        }, 500);
      }
      previewImage.src = URL.createObjectURL(e.target.files[0]);
      // const reader: any = new FileReader();
      // reader.addEventListener('load', () => {
      //   setCropModal(true)
      //   setcropImage(reader.result)
      // });
      // reader.readAsDataURL(e.target.files[0]);
    }
    // const reader = new FileReader();

    // if (e.target.files[0]) {
    //  var readerData = reader.readAsDataURL();
    //  console.log(readerData);
    // }
    // reader.addEventListener("load", function () {
    //   let readData = reader.result ? reader.result : '';
    //   setOpen(true);
    //   console.log(CompDrawingToolDialog);
    //   imageMapData = readData ? readData.toString() : '';
    //  // handleExportPngData(readData ? readData.toString() : '');
    // }, false);
  }
  const handleUploadMap = (str: any) => {
    setOpen(true);
    setBgDataURLString(str);
    setStatus('captured');
    setIsSaving(false);
  //  console.log('On HandleUploadMap' + str)
  }
  const handleEdit = () => {
    setOpen(true);
    setStatus('heremap');
  }
  const handleExportPngData = async (pngData: string) => {
    try {
      setIsSaving(true)
      if (hasDiagram && attachment) {
        await apiRemoveFile({
          id: attachment.id,
          formType: PackFormType.VEHICLE_MOVEMENT_PLAN_SITE_MAP_DIAGRAM,
          formId: siteMapId!,
          roleId: RoleId.ProjectManager,
          serialId,
        })
      }
      await apiUploadFile({
        formType: PackFormType.VEHICLE_MOVEMENT_PLAN_SITE_MAP_DIAGRAM,
        formId: siteMapId!,
        roleId: RoleId.ProjectManager,
        serialId,
        file: dataURLtoFile(pngData, 'map.png'),
        projectInternalId: vmp.job.projectDetails.projectInternalId!,
      })
      fetch()
      setOpen(false)
      setIsSaving(false)
      alertDone();
      window.location.reload();
    } catch (e) {
      setIsSaving(false)
      alertError()
    }
  }
  const onCropChange = (info: any) => {
    setCrop(info);
  }
  const onClose = () => {
    setOpen(false);
  }
  const onImageLoaded = (image: any) => {
    cropImageRef = image;
  }
  const saveCropedImage = () => {
    handleCropModalClose();
    const canvas = canvasPreviewRef.current as HTMLCanvasElement
    handleUploadMap(canvas.toDataURL())
    // var reader: any = new FileReader();
    // reader.readAsDataURL(cropedImage);
    // reader.onloadend = function () {
    //   // console.log("cropedImage ----------->>>>>>> ",cropedImage);
      
    //   handleUploadMap(reader.result)
    //   //handleExportPngData(reader.result)
    // }
  }

  const CropedImage = async (crop: any) => {
    const croppedImageUrl: any = await getCroppedImg(cropImageRef, crop);
    setcropedImage(croppedImageUrl);
  }
  const getCroppedImg = (image: any, crop: any) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx: any = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY, 0, 0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          resolve(blob);
        }, 'image/jpeg', 1
      );
    });
  }

  const mystyle = {

    display: 'none'
  }
  const setCropRatio = (type: any)=> {
    const canvas = canvasPreviewRef.current as HTMLCanvasElement
    const context: any = canvas.getContext('2d')
    canvas.width = 710;
    canvas.height = 400;
    context.clearRect(0, 0, canvas.width, canvas.height)
    let previewImage = new Image()
    previewImage.onload = function(){
      if(type == "fill") {
        var scale = Math.max(canvas.width / previewImage.width, canvas.height / previewImage.height);
        var x = (canvas.width / 2) - (previewImage.width / 2) * scale;
        var y = (canvas.height / 2) - (previewImage.height / 2) * scale;
        context.drawImage(previewImage, x, y, previewImage.width * scale, previewImage.height * scale);
        setIsFill(true);
      } else {
        var scale = Math.min(canvas.width / previewImage.width, canvas.height / previewImage.height);
        var x = (canvas.width / 2) - (previewImage.width / 2) * scale;
        var y = (canvas.height / 2) - (previewImage.height / 2) * scale;
        context.drawImage(previewImage, x, y, previewImage.width * scale, previewImage.height * scale);
        setIsFill(false);
      }
    }
    previewImage.src = URL.createObjectURL(cropImage);
  //  switch (ratio) {
 
    
  // case 4: 
  //   setCrop({ aspect: 3 / 2});
  //   break;
  // case 3:
  //     setCrop({ aspect: 1 / 1});
  //   break;
   
   
   
  //  }
  }
  const handleExport = async (svgData: string) => {
    if (canvasRef && canvasRef.current) {
      console.log(svgData, bgDataURLString)
      const canvas = canvasRef.current as HTMLCanvasElement
      const context = canvas.getContext('2d')
      if (context === null) {
        return
      }
      const width = 710
      const height = 400
      canvas.width = width
      canvas.height = height
      let mapImage = new Image()
      let svgImage = new Image()
      mapImage.onload = () => {
        proceed()
      }
      mapImage.src = bgDataURLString
      svgImage.onload = function () {
        proceed()
      }
      svgImage.src = svgData

      let readyCount = 0
      const proceed = async () => {
        readyCount++
        if (readyCount === 2) {
          context.clearRect(0, 0, width, height)
          context.drawImage(mapImage, 0, 0, width, height)
          context.drawImage(svgImage, 0, 0, width, height)
          const pngData = canvas.toDataURL('image/' + 'png')
          handleExportPngData(pngData)
        }
      }
    }
  }
  return <Wrapper>
    {
      hasDiagram && attachment ?
        <React.Fragment>
          <ImageWrapper>
            <img src={`${BASE_API_URL}/v2/documents/document/${attachment.name}`} alt='site-diagram' />
          </ImageWrapper>
          <Button variant='outlined' onClick={handleEdit}>
            Update Map
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='outlined' onClick={handleClickUpload}>
            Update Image
          </Button>
          <input type="file"  ref={fileRef} style={mystyle} id="fileChange" onChange={handleEvent} />
        </React.Fragment>
        :
        <Wrapper>
          <CompCreateButton
            onClick={handleClick}>
            Add Map
          </CompCreateButton>
          &nbsp;
          <CompCreateButton
            onClick={handleClickUpload}>
            Upload Image
          </CompCreateButton>
          <input type="file" ref={fileRef} style={mystyle} id="fileChange" onChange={handleEvent} />
        </Wrapper>

    }
    {
      open && <CompDrawingToolDialog
        open={open}
        onClose={() => setOpen(false)}
        isSaving={isSaving}
        onExportPngData={handleExportPngData}

      />
    }
    {mapCaptured &&
      <Dialog
        // fullWidth
        maxWidth='xl'
        open={open}
        onClose={() => {

        }}
      >
        <CompDrawingTool
          bgDataURLString={bgDataURLString}
          exportTrigger={exportTrigger}
          onExport={handleExport}
        />
        {<DialogActions>
          {mapCaptured &&
            <React.Fragment>
              <Button onClick={onClose}>
                Cancel
              </Button>
          
              <Button
                color='primary'
                variant='contained'
                disabled={isSaving}
                onClick={() => setExportTrigger(exportTrigger + 1)}
              >
                {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
                {!isSaving && 'SAVE DIAGRAM'}
              </Button>
            </React.Fragment>
          }
        </DialogActions>
        }
      </Dialog>
    }
    <Modal open={CropModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <MolalBox>
        <ImageBox>
          <CanvasPreview ref={canvasPreviewRef as any}></CanvasPreview>
          {/* <ReactCrop src={cropImage} crop={crop} onImageLoaded={onImageLoaded} onChange={onCropChange} onComplete={CropedImage} minWidth={710} minHeight={400} maxWidth={710} maxHeight={400}/> */}
        </ImageBox>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          
          <CropsButton onClick={()=>setCropRatio("fit")} style={{background: isFill ?  "#292e2e" : "#00999b"}}>Fit</CropsButton>
          <CropsButton onClick={()=>setCropRatio("fill")} style={{background: !isFill ?  "#292e2e" : "#00999b"}}>Fill</CropsButton>
         
          
        </div>
        <div style={{ display: 'flex', justifyContent: 'end',marginTop:'25px' }}>
          <CropCancelButton onClick={handleCropModalClose}>Cancel</CropCancelButton>
          <CropSaveButton onClick={saveCropedImage}>Next</CropSaveButton>
        </div>
      </MolalBox>
    </Modal>
    <CanvasPanel ref={canvasRef as any}>

    </CanvasPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
const ImageBox = styled.div`
  width: 710px;
  height: 400px;
  border: 1px solid #111;
  box-sizing: content-box;
  pointerEvents: none;
`
const ImageWrapper = styled.div`
  margin-bottom: 10px;
`
const CanvasPanel = styled.canvas`
  display: none;
`
const CanvasPreview = styled.canvas`
  width: 710px;
  height: 400px;
`
const CropsButton = styled(Button)({
  color: "#ffffff",
  fontSize: "13px",
  marginRight: '10px',
  width: '150px !important',
  padding: '2px 0 !important',
  textTransform: "capitalize",
  '&:hover': {
    background: "#292e2e",
    color: "#ffffff !important"
  }
});
const CropCancelButton = styled(Button)({
  background: "#292e2e",
  color: "#ffffff",
  fontSize: "13px",
  marginRight: '10px',
  width: '60px !important',
  padding: '2px 0 !important',
  textTransform: "capitalize",
  '&:hover': {
    background: "#292e2e !important",
    color: "#ffffff !important"
  }
});
const CropSaveButton = styled(Button)({
  background: "#00999b",
  color: "#ffffff",
  fontSize: "13px",
  width: '60px !important',
  padding: '2px 0 !important',
  textTransform: "capitalize",
  '&:hover': {
    background: "#00999b !important",
    color: "#ffffff !important"
  }
})

const MolalBox = styled(Box)({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  width: 740,
  boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
  padding: 15,
  outline: 0
})
const Spinner = styled(CircularProgress)`
  margin-right: 10px;
`
function dataURLtoFile(dataurl: any, filename: any) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export default CompVMPSiteDiagram