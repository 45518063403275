import React, { useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { useTabs } from '@src/components/Tabs/useTabs'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import CompDRSCrewMembers from './parts/CrewMembers'
import TabsWrapper from '@src/modules/SiteResoureRequirements/comps/TabsWrapper'
import CompDRSCrewSummary from './parts/CrewSummary'
import CompDRSCrewExtras from './parts/CrewExtras'
import CompDRSCrewContactDetails from './parts/CrewContactDetails'
import CompDRSLostTime from './parts/LostTime'
import { useDRS } from '../../useDRS'
import { DRSPageType } from '../../DRSPage'
import { compareWorkedLostTime } from '@src/utils/busrules/LabourRules'
import { useSnackbar } from '@src/components/GlobalSnackbar'

const DRSSectionLabour = () => {
  const crewDetailsTab = {
    key: 'Crew Details',
    label: 'Crew Details',
  }
  const crewSummaryTab = {
    key: 'Crew Summary',
    label: 'Crew Summary',
  }
  const lostTimeTab = {
    key: 'Lost Time',
    label: 'Lost Time',
  }
  const allTabs = [
    crewDetailsTab,
    crewSummaryTab,
    lostTimeTab,
  ]
  const { alert } = useSnackbar()
  const [tabIndex, tabs, handleTabChange, setTabsFunc] = useTabs(allTabs)
  const { drs, drsPageType } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  useEffect(() => {
    if (drs !== null) {
      const busRule: any = compareWorkedLostTime(drs)
      console.log(`DRSSectionLabour useEffect() busRule=${JSON.stringify(busRule, null, 2)}`)

      if (busRule.result) {
        alert({
          type: 'warning',
          message: busRule.message,
        })
      }

      if (!isPlan) {
        setTabsFunc(allTabs)
      } else {
        setTabsFunc([
          crewDetailsTab,
          crewSummaryTab,
        ])
      }
    }
  }, [drs, drsPageType])
  const renderParts = () => {
    const currentTab: Tab = tabs[tabIndex]
    switch (currentTab.key) {
      case crewDetailsTab.key: {
        return <React.Fragment>
          <CompDRSCrewMembers />
        </React.Fragment>
      }
      case crewSummaryTab.key: {
        return <React.Fragment>
          <CompDRSCrewSummary />
          <CompDRSCrewExtras />
          <CompDRSCrewContactDetails />
        </React.Fragment>
      }
      case lostTimeTab.key: {
        return <React.Fragment>
          <CompDRSLostTime />
        </React.Fragment>
      }
    }
  }
  return <Wrapper>
    <FormSectionTitle>LABOUR</FormSectionTitle>
    <TabsWrapper>
      <TabsComp
        tabs={tabs}
        activeIndex={tabIndex}
        onChange={handleTabChange}
      />
    </TabsWrapper>
    {renderParts()}
  </Wrapper>
}

const Wrapper = styled.div`

`

export default DRSSectionLabour