import { RoleId } from '@src/types/common/RoleId'
import { DRSCrewMemberPAE } from '@src/types/drs/labour/Labour'
import { PAE } from '@src/types/drs/PAE'
import moment, { Moment } from 'moment'

const LUNCH_ALLOWANCE = .5
export function getHoursByLunchTaken(hours: number | null, lunchTaken: boolean) {
  if (hours) {
    const hoursAllowanceForLunch = lunchTaken ? LUNCH_ALLOWANCE : 0
    hours = hours - hoursAllowanceForLunch
  }
  return hours
}

export function getStartEndDiff ({
  start,
  end,
}: {
  start: Moment | string | null,
  end: Moment | string | null,
}) {
  if (!start || !end) {
    return null
  }
  let minutes = moment.duration(moment(end).diff(moment(start))).asMinutes()
  const MINUTES_IN_ONE_DAY = 24 * 60
  minutes = minutes % ( MINUTES_IN_ONE_DAY )
  if (minutes < 0) {
    minutes = minutes + MINUTES_IN_ONE_DAY
  }
  let hours = Number((minutes / 60).toFixed(2))
  return hours
}

export const getMemberTotalHours = (members: DRSCrewMemberPAE[]) => {
  if (!members || !members.length) {
    return 0
  }
  return members
  .filter(member => member.plan.startTime && member.plan.endTime)
  .reduce((acc, curr) => {
    let diff = getStartEndDiff({
      start: curr.plan.startTime,
      end: curr.plan.endTime,
    })
    return acc + ( diff! ? 0 : diff! )
  }, 0)
}

function calcDuration({
  member,
  pae = PAE.Plan,
}: {
  member: DRSCrewMemberPAE,
  pae: PAE,
}) {
  if (member[pae] && member[pae].startTime && member[pae].endTime) {
    let diff = getStartEndDiff({
      start: member[pae].startTime,
      end: member[pae].endTime,
    })
    diff = getHoursByLunchTaken(diff, member[pae].lunchTaken)
    return diff
  } else {
    return 0
  }
}

function getHours({
  roleId,
  members,
  pae,
}: {
  roleId: RoleId,
  members: DRSCrewMemberPAE[],
  pae: PAE,
}) {
  // count into default role and present
  const filtered = members.filter(member => {
    const { roles } = member.profile
    if (!roles || !roles.length) {
      return false
    }
    return roles.some(role => role.default && role.id === roleId && member[pae].present)
  })
  return filtered.reduce((acc, curr) => {
    return acc + calcDuration({ 
      member: curr, 
      pae,
    })!
  }, 0) 
}

export const getGeneralCrewHours = (members: DRSCrewMemberPAE[], pae: PAE) => {
  return getHours({
    roleId: RoleId.GeneralCrew,
    members,
    pae,
  })
}
export const getForemanHours = (members: DRSCrewMemberPAE[], pae: PAE) => {
  return getHours({
    roleId: RoleId.Foreman,
    members,
    pae,
  })
}
export const getSupervisorHours = (members: DRSCrewMemberPAE[], pae: PAE) => {
  return getHours({
    roleId: RoleId.Supervisor,
    members,
    pae,
  })
}
export const getTotalHours = (members: DRSCrewMemberPAE[], pae: PAE, pmHours: number) => {
  const generalCrewHours = getGeneralCrewHours(members, pae)
  const foremanHours = getForemanHours(members, pae)
  const supervisorHours = getSupervisorHours(members, pae)
  return generalCrewHours + foremanHours + supervisorHours + pmHours
}