import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { XYCoord, useDragLayer } from 'react-dnd'
import moment from 'moment'
import { DragItemTypes } from '@src/types/DragItemTypes'
import DRSItem from './comps/DRSItem'
import { useCrewCalender } from './StoreCrewCalender'
import { useSelectedShifts } from '@src/utils/hooks/StoreSelectedShifts'

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(
  gridWidth: number,
  daysOffset: number,
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  let { x, y } = currentOffset
  //console.log(`CustomDraglayer getItemStyles() daysOffset=${daysOffset}}`)
  //console.log(`CustomDraglayer getItemStyles() gridWidth=${gridWidth} currentOffset=${JSON.stringify(currentOffset, null, 2)}`)
  //console.log(`CustomDraglayer getItemStyles() currentOffset=${JSON.stringify(currentOffset, null, 2)}`)

  const transform = `translate(${daysOffset > 1 ? x - ((daysOffset - 1) * gridWidth) : x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export interface CustomDragLayerProps {
  snapToGrid: boolean
}

export const CustomDragLayer: React.FC<CustomDragLayerProps> = (props) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  const { selectedShifts } = useSelectedShifts()
  const { gridWidth, dragStartDate } = useCrewCalender()
  const [daysOffset, setDaysOffset] = useState<number>(0)

  function getItems() {
    let items: any = [], total: number = 1, emptyDays: number, shiftOnSameDay: number, offset: number = 0
    let thisShiftDate: moment.Moment, nextShiftDate: moment.Moment, prevShiftDate: moment.Moment
    let selectedShift: number, daysToNextShift: number = -1, daysFromPrevShift: number = 0;

    if (selectedShifts.length > 0) {
      const draggingDate = moment(dragStartDate).startOf('day');
      const startDate = moment(selectedShifts[0].date).startOf('day');
      //const offset = draggingDate.diff(startDate, 'days') + 1
      offset = draggingDate.diff(startDate, 'days') + 1

      if (daysOffset != offset) {
        setDaysOffset(offset)
      }

      //console.log(`CustomDragLayer renderItems() offset=${offset}`)
      //console.log(`CustomDragLayer renderItems() dragStartDate=${dragStartDate}`)
      //console.log(`CustomDragLayer renderItems() numShifts=${selectedShifts.length}`)
      //console.log(`CustomDragLayer renderItems() numShifts=${selectedShifts.length} numDays=${numDays} daysOffset=${daysOffset}`)
      //console.log(`CustomDragLayer renderItems() numShifts=${selectedShifts.length} numDays=${numDays} daysOffset=${daysOffset}`)
      //console.log(`CustomDragLayer renderItems() startDate=${startDate} endDate=${endDate} numDays=${numDays}`)
      //console.log(`CustomDragLayer renderItems() selectedShifts.length=${selectedShifts.length} numDays=${numDays}`)

      //console.log(`\nstart`)
      for (selectedShift = 0; selectedShift < selectedShifts.length; selectedShift++) {
        total = 1;
        thisShiftDate = moment(selectedShifts[selectedShift].date).startOf('day')

        if (selectedShift < selectedShifts.length - 1) {
          nextShiftDate = moment(selectedShifts[selectedShift + 1].date).startOf('day')
          daysToNextShift = nextShiftDate.diff(thisShiftDate, 'days')
          //console.log(`${selectedShift} CustomDragLayer renderItems() daysToNextShift=${daysToNextShift}`)
        }

        if (selectedShift > 0) {
          prevShiftDate = moment(selectedShifts[selectedShift - 1].date).startOf('day')
          daysFromPrevShift = thisShiftDate.diff(prevShiftDate, 'days')
          // if on same day as previous shift subtract from offset
          //if (daysFromPrevShift === 0)
          //offset2--
          //console.log(`${selectedShift} CustomDragLayer renderItems() daysFromPrevShift=${daysFromPrevShift}`)
        }

        //calculate total selected shifts on the day
        if (daysToNextShift === 0) {
          for (shiftOnSameDay = selectedShift + 1; shiftOnSameDay < selectedShifts.length; shiftOnSameDay++) {
            nextShiftDate = moment(selectedShifts[shiftOnSameDay].date).startOf('day')
            daysToNextShift = nextShiftDate.diff(thisShiftDate, 'days')
            //console.log(`${shiftOnSameDay} CustomDragLayer renderItems() daysToNextShift=${daysToNextShift}`)
            if (daysToNextShift === 0) {
              total++;
            } else {
              break;
            }
          }
        }

        if (selectedShift === 0 || daysFromPrevShift > 0) {
          items.push(
            <Wrapper
              gridWidth={gridWidth}
            >
              <DRSItem
                drsOpt={false}
                isDuplicateOperation={``}
                moveCard={() => console.log(``)}
                id={''}
                isMultiShift={false}
                onDuplicateDrs={(data:any) => console.log(``)}
                data={selectedShifts[selectedShift]}
                currentIndex={0}
                batchIndex={1}
                crewIndex={1}
                total={total}
                inDragLayer={true}
               className={''}
                onClick={() => console.log(``)}
                onPrevious={() => console.log(``)}
                onNext={() => console.log(``)}
                onEdit={() => console.log(``)}
                onCardOrder={() => console.log(``)}
                onToggleShift={() => console.log(``)}
              />
            </Wrapper>
          );
        }

        // insert empty days in the drag image
        //if (daysToNextShift > 1 && selectedShift < selectedShifts.length - 1) {
        //if (daysToNextShift > 1 && daysFromPrevShift > 0 && selectedShift < selectedShifts.length - 1) {

        if (daysToNextShift > 1 && total === 1 && selectedShift < selectedShifts.length - 1) {
          //console.log(`${selectedShift} ${selectedShifts[selectedShift].projectId} CustomDragLayer renderItems() daysToNextShift=${daysToNextShift} daysFromPrevShift=${daysFromPrevShift} total=${total}`)
          for (emptyDays = 1; emptyDays < daysToNextShift; emptyDays++) {
            items.push(
              <Wrapper gridWidth={gridWidth} />
            );
          }
        }
      }
      return items
    } else {
      return items
    }
  }

  function renderItems() {
    switch (itemType) {
      case DragItemTypes.DRSItem:
        return getItems()
      default:
        return null
    }
  }

  if (!isDragging) {
    return null
  }
  //console.log(`CustomDraglayer initialOffset=${JSON.stringify(initialOffset, null, 2)} currentOffset=${JSON.stringify(currentOffset, null, 2)}`)
  //console.log(`CustomDraglayer getItemStyles=${JSON.stringify(getItemStyles(initialOffset, currentOffset), null, 2)}`)
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(gridWidth, daysOffset, initialOffset, currentOffset)}
      >
        {renderItems()}
      </div>
    </div>
  )
}

const Box = styled.div<{ gridWidth: number }>`
flex - grow: 1;
width: ${props => `${props.gridWidth}px`};
overflow - y: hidden;
`

const Wrapper = styled(Box) <{}>`
position: relative;
padding: 10px;
border: 0px;
cursor: pointer;
float: left;
`