import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'

interface Props {
  label: string,
  value: string,
  error?: boolean,
  helperText: string,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
  disabled?: boolean,
}

const FormModalNumericField = (props: Props) => {
  const {
    label,
    value,
    error,
    helperText,
    onChange,
    disabled = false,
  } = props


  return <Wrapper
    fullWidth
    type={"text"}
    label={label}
    value={value}
    onChange={onChange}
    disabled={disabled}
    error={error}
    helperText={helperText}
  ></Wrapper>
}

FormModalNumericField.defaultProps = {
  error: false,
  helperText: "",
}

const Wrapper = styled(Textfield)`

`

export default FormModalNumericField