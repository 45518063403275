import axios from 'axios'
import { DEVICE_INFO } from '../constants'

export const apiDeviceInfo = (token: any, id: string) => {
  const options = {
    method: 'GET',
    headers: { 
      'Authorization': `Bearer ${token}`
    },
    url: DEVICE_INFO+id,
  }
  return axios(options as any)
}