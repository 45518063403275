import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useAppStore } from '@src/AppStore'
import CompSiteDetail from '@src/modules/SiteInspectionForm/parts/comps/SiteDetail'
import useModal from '../../../../components/FormModal/useModal'
import Modal from '../../../../components/FormModal/Modal'
import { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import { useSRR } from '../../useSRR'
import { apiSrrUpdateSiteInformation } from '@src/apis/srr-update-site-information'
import { useParams } from 'react-router'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { SiteInformation, SRR } from '@src/types/SRR'
import usePermission from '@src/utils/hooks/usePermission'
import { Nullable } from '@src/types/utils/Nullable'
import moment from 'moment'
import { toValidTimeString } from '@src/utils/time/toValidTime'

interface Props {

}

type SiteInfoFlat = {
  street: string,
  nearestCrossStreet: string,
  suburb: string,
  mapReference: string,
  timeOnSite: string,
  timeOffSite: string,
  siteSpecificInduction: boolean,
  plantRiskAssessmentsRequired: boolean,
  uhfChannel: number,
  emergencyAssemblyPoint: string,
  nearestHospital: string,
  baseCondition: string,
  generalSiteObservations: string,
  machineAccess: string,
  machineParking: string,
}
type NullableSiteInfoFlat = Nullable<SiteInfoFlat>

const fields: FormTableField<NullableSiteInfoFlat>[] = [
  {
    key: 'street',
    type: FormTableFieldType.Text,
    label: 'Street',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'nearestCrossStreet',
    type: FormTableFieldType.Text,
    label: 'Nearest Cross Street',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'suburb',
    type: FormTableFieldType.Text,
    label: 'Suburb',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'mapReference',
    type: FormTableFieldType.Text,
    label: 'Map Reference',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'emergencyAssemblyPoint',
    type: FormTableFieldType.Text,
    label: 'Emergency Assembly Point',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'nearestHospital',
    type: FormTableFieldType.Text,
    label: 'Nearest Hospital',
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'timeOnSite',
    type: FormTableFieldType.Time,
    label: 'Time On Site',
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'timeOffSite',
    type: FormTableFieldType.Time,
    label: 'Time Off Site',
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'siteSpecificInduction',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Site Specific Induction',
  },
  {
    key: 'plantRiskAssessmentsRequired',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Plant Risk Assessments Required?',
  },
  {
    key: 'uhfChannel',
    type: FormTableFieldType.Number,
    label: 'UHF Channel',
  },
  {
    key: 'baseCondition',
    type: FormTableFieldType.Textarea,
    label: 'Base Condition - Description',
  },
  {
    key: 'generalSiteObservations',
    type: FormTableFieldType.Textarea,
    label: 'General Site Observations',
  },
  {
    key: 'machineAccess',
    type: FormTableFieldType.Textarea,
    label: 'Comments on Machine Access',
  },
  {
    key: 'machineParking',
    type: FormTableFieldType.Textarea,
    label: 'Comments on Machine Parking',
  },
]
function covert(srr: SRR | null): NullableSiteInfoFlat | null {
  if (srr === null) {
    return null
  }
  const siteInfo: SiteInformation = srr.siteInformation
  const { siteDetails } = siteInfo
  return (({ 
    street,
    nearestCrossStreet,
    suburb,
    mapReference,
    timeOnSite,
    timeOffSite,
    siteSpecificInduction,
    plantRiskAssessmentsRequired,
    uhfChannel,
    emergencyAssemblyPoint,
    nearestHospital,
    baseCondition,
    generalSiteObservations,
    machineAccess,
    machineParking,
  }) => ({
    street,
    nearestCrossStreet,
    suburb,
    mapReference,
    timeOnSite,
    timeOffSite,
    siteSpecificInduction,
    plantRiskAssessmentsRequired,
    uhfChannel,
    emergencyAssemblyPoint,
    nearestHospital,
    baseCondition,
    generalSiteObservations,
    machineAccess,
    machineParking,
  }))(siteDetails)
}
const SRRSiteInfo = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const { serialId } = useAppStore()
  const { id } = useParams<{id: string}>()
  const { alert } = useSnackbar()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<NullableSiteInfoFlat>({})
  const { canEditSubmitSRR } = usePermission()

  const handleEditShiftDetail = () => {
    openAndSetData(covert(srr))
  }
  const handleSave = async (form: any) => {
    console.log(form)
    const finalForm = {
      ...form,
      timeOnSite: toValidTimeString(form.timeOnSite, 'HH:mm'),
      timeOffSite: toValidTimeString(form.timeOffSite, 'HH:mm'),
    }
    try {
      const result = await apiSrrUpdateSiteInformation({
        serialId,
        siteId: 1,
        srrId: Number(id),
        ...finalForm,
      })
      refresh()
      alert({
        message: 'Updated.'
      })
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: 'Error.'
      })
    }
  }
  if (srr === null) {
    return null
  }
  return <Wrapper>
    <FormSectionTitle>SITE DETAILS</FormSectionTitle>
    <FormPanel
      title={`Site Information`}
      actionEdit={canEditSubmitSRR}
      onEdit={handleEditShiftDetail}
    >
      <CompSiteDetail siteInformation={srr.siteInformation} />
    </FormPanel>
    { open && 
        <Modal<NullableSiteInfoFlat>
          maxWidth={'lg'}
          open={open}
          title='Site Details'
          data={{
            rowData: modalData,
            fields: fields,
            columns: [
              {
                title: 'Geography',
                fieldKeys: [
                  'street',
                  'nearestCrossStreet',
                  'suburb',
                  'mapReference',
                  'emergencyAssemblyPoint',
                  'nearestHospital',
                ],
              },
              {
                title: 'Time',
                fieldKeys: [
                  'timeOnSite',
                  'timeOffSite',
                ],
              },
              {
                title: 'Site Details',
                fieldKeys: [
                  'siteSpecificInduction',
                  'plantRiskAssessmentsRequired',
                  'uhfChannel',
                ],
              },
              {
                title: 'Comments',
                fieldKeys: [
                  'baseCondition',
                  'generalSiteObservations',
                  'machineAccess',
                  'machineParking',
                ],
              },
            ]
          }}
          onSuccess={handleSave}
          onClose={closeModal}
          onCancel={closeModal}
        />
      }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default SRRSiteInfo