import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import moment, { Moment } from 'moment'
import { useHover } from '../../../../../utils/hooks'
import FieldEditableTime from './f-EditableTime'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

interface Options extends DRS.Comp {
  onApplyToAll?(): void,
}

function FieldMemberTimePicker ({
  field,
  onChange,
  onApplyToAll = () => {},
}: Options) {
  const ref = useRef<any>()
  const [ open, setOpen ] = useState<any>(false)
  
  const handleChange = (date: Moment) => {
    setOpen(true)
    onChange(date)
  }

  const handleApplyToAll = () => {
    setOpen(false)
    onApplyToAll()
  }
  return <Wrapper ref={ref}>
    <FieldEditableTime
      field={field}
      onChange={handleChange}
    />
    <Popover 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      anchorEl={ref.current}
      onClose={() => setOpen(false)}
    >
      <Button onClick={handleApplyToAll} color='primary'>Apply to all</Button>
    </Popover>
  </Wrapper>
}

const Wrapper = styled.div`
  /* padding-top: 8px; */
`

export default FieldMemberTimePicker