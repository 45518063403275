
import React, { useEffect, useRef, useState, useLayoutEffect, ReactNode, SyntheticEvent } from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Dropdown from './Dropdown'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import CircularProgress from '@material-ui/core/CircularProgress'

function renderInput(inputOptions: any) {
  const { 
    InputProps,
    inputProps, // id
    InputLabelProps,
    classes,
    ref,
    label,
    fullWidth,
    ...other 
  } = inputOptions
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel htmlFor={InputLabelProps.htmlFor}>{label}</InputLabel>
      <Input
        ref={ref}
        {...inputProps}
        startAdornment={InputProps.startAdornment}
      />
    </FormControl>
  )
}

interface Props {
  id?: string,
  label?: string,
  isOpen: boolean,
  onInternalOpen?: any,
  onInternalClose?: any,
  onEsc?(): void,

  disabled?: boolean,
  isLoading?: boolean,
  onSelect?: any,
  onInput?: any,
  options?: any[],
  selectedItem?: any,
  clearAfterSelection?: boolean,
  dropdownAutoWidth?: boolean,
  fullWidth?: boolean,
  initialSelectedItem?: AIMS.ValueLabel,
  startAdornment?: ReactNode,
  onChange?: any,
  hasTopOption?: boolean,
  topOptionLabel?: string,
  onTopOption?: any,
  placeholder?: string,
}
const BaseAutocomplete = ({
  isOpen,
  onInternalOpen,
  onInternalClose,
  onEsc,

  disabled,
  isLoading = false,
  onSelect,
  onInput,
  options,
  selectedItem,
  clearAfterSelection,
  dropdownAutoWidth,
  fullWidth = true,
  ...props 
}: Props) => {
  const inputRef = useRef(null)
  const classes = useStyles()
  const handleSelect = (item: any) => {
    onSelect(item)
    if (clearAfterSelection) {
      if (inputRef !== null) {
        // @ts-ignore
        (inputRef.current).value = ''
      }
    }
  }
  const handleStateChange = (changes: any) => {
    // console.log(changes)
    if (changes.type === Downshift.stateChangeTypes.changeInput) {
      onInternalOpen()
    }

    // 
    if (changes.type === Downshift.stateChangeTypes.mouseUp && changes.isOpen === false) {
      onInternalClose(changes.inputValue)
    }

    if (typeof changes.isOpen === 'boolean') {
      if (changes.isOpen) {
        // onInternalOpen()
      } else {
        onInternalClose()
      }
    } else {

    }
  }
  return (
    <div className={classes.root}>
      <Downshift 
        id={props.id} 
        initialSelectedItem={props.initialSelectedItem}
        onChange={props.onChange}
        itemToString={item => (item ? item.label : '')}
        onSelect={handleSelect}
        onStateChange={handleStateChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          // isOpen,
          selectedItem,
          closeMenu,
          clearSelection,
        }) => {
          const handleKeyUp = (e: KeyboardEvent) => {
            if (e.keyCode === 27) {
            } else {
              onInput(inputValue)
              // console.log('keyup', inputValue, e.target.value)
            }
          }
          const handleKeyDown = (e: KeyboardEvent) => {
            if (e.keyCode === 27) {
              e.stopPropagation()// esc
              e.preventDefault()
              // @ts-ignore
              e.nativeEvent.preventDownshiftDefault = true
            }
          }
          // console.log(props.inputValue)
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: props.placeholder,
            onKeyUp: handleKeyUp,
            onKeyDown: handleKeyDown,
            disabled,
            // value: props.inputValue,
          })

          const inputOptions = {
            fullWidth: fullWidth,
            classes,
            InputProps: { 
              onBlur, 
              onFocus, 
              startAdornment: props.startAdornment,
            },
            // InputLabelProps: getLabelProps({ shrink: true }),
            InputLabelProps: {
              ...getLabelProps({ }),
              shrink: inputValue ? true : false,
            },
            inputProps,
            label: props.label,
            ref: inputRef,

          }
          const handleTopOptionClick = () => {
            // closeMenu()
            props.onTopOption()
          }
          return (
            <div className={classes.container}>

              { renderInput(inputOptions) }
              { isLoading && <LoadingIndicator size={20} /> }
              <Dropdown 
                isLoading={isLoading}
                isOpen={isOpen}
                options={options}
                dropdownAutoWidth={dropdownAutoWidth}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}

                inputRef={inputRef}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}

                hasTopOption={props.hasTopOption}
                topOptionLabel={props.topOptionLabel}
                onTopOptionClick={handleTopOptionClick}
              />
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}))



const StyledArrowDropDown = styled(ArrowDropDown)`
  top: calc(50% - 8px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
`

const LoadingIndicator = styled(CircularProgress)`
  position: absolute;
  right: 0;
  bottom: 8px;
`


export default BaseAutocomplete