/**
 * Notification Center
 */

import React from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'

const Notification = ({ ...props }) => {
  return <Typography>
    {props.message}
  </Typography>
}

const Notifications = () => {

  return <Container maxWidth='xl'>
    <Paper>
  
      {/* <Notification message='DRS#43 from project `excalibur`' /> */}
      
    </Paper>
  </Container>

}

export default Notifications