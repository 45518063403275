import axios from 'axios'
import { AUTH_USER_URL } from '../constants'

export const apiOktaUser = (token: string) => {
  const options = {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}` },
    url: AUTH_USER_URL,
  }
  return axios(options as any)
}