import React, { useState } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormTable, { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { Depot, NullableDepot } from '@src/types/common/Depot'
import { NullableIdDescription } from '@src/types/utils/IdDescription'
import { Nullable } from '@src/types/utils/Nullable'
import Button from '@material-ui/core/Button'
import Modal from '../../../../../../components/FormModal/Modal'
import { MaterialSpraySealProduct } from '@src/types/MaterialSpraySealProduct'
import { MaterialSpraySealProductFields } from './fields'
import useModal from '@src/components/FormModal/useModal' 
import CompCreateButton from '@src/components/Buttons/CreateButton'
import MarginTop from '@src/components/Layout/MarginTop'
import { useParams } from 'react-router'
import { useAppStore } from '@src/AppStore'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrMaterialProductCreate } from '@src/apis/srr-material-product-create'
import { JobType } from '@src/types/JobType'
import { apiSrrMaterialProductUpdate } from '@src/apis/srr-material-product-update'
import { apiSrrMaterialProductRemove } from '@src/apis/srr-material-product-remove'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

type MSSP = MaterialSpraySealProduct

const CompSsProduct = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const { serialId } = useAppStore()
  const fields = MaterialSpraySealProductFields
  const [ srr, srrLoading, refresh ] = useSRR()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<MSSP>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.material.spraySeal.products

  const hanldeRemoveRow = async (row: MaterialSpraySealProduct) => {
    try {
      await confirm({
        title: `Confirm deletion of Spray Seal Product`,
        message: `This will delete the Spray Seal Product from this Shift Resource requirement AND Pre-DRS`,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: MaterialSpraySealProduct) => {
    try {
      const result = await apiSrrMaterialProductRemove({
        srrId: id,
        serialId,
        jobType: JobType.SpraySeal,
        productId: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  const hanldeEditRow = (row: MSSP) => {
    console.log(row)
    openAndSetData(row)
  }
  const handleSave = async (form: any) => {
    console.log(form)
    if (modalData === null) {
      try {
        const result = await apiSrrMaterialProductCreate({
          srrId: id, 
          serialId,
          jobType: JobType.SpraySeal,
          partialProduct: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrMaterialProductUpdate({
          srrId: id, 
          serialId,
          jobType: JobType.SpraySeal,
          partialProduct: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  // hide fields
  const hiddenFieldKeys: string[] = ['comments', 'aggregateRequired_Tonnes', 'aggregateRequired_M3', 'stockpileSite', 'preCoatRate', 'stockpileInstructions']
  const finalFields = fields.filter((field: FormTableField<MSSP>) => {
    return !hiddenFieldKeys.includes(field.key)
  })
  return <Wrapper>
    <FormPanel title={`Spray Seal Product Details`}>
      <FormTable<MSSP>
        fields={finalFields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      { canEditSubmitSRR &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Spray Seal Material Details
          </CompCreateButton>
        </MarginTop>
      }
      { open && <Modal<MSSP>
        maxWidth='lg'
        open={open}
        title='Spray Seal Product'
        data={{
          rowData: modalData,
          fields: fields,
          columns: [
            {
              title: '',
              fieldKeys: ['spraySealType', 'sprayBinderType', 'area', 'binderMixture', 'orderedApplicationRate', 'residualBinder', ],
            },
            {
              title: '',
              fieldKeys: ['cutter', 'adhesionAgent', 'depot', 'aggregateSize', 'aggregateRequired_Tonnes', 'aggregateRequired_M3'],
            },
            {
              title: '',
              fieldKeys: ['aggregateStockpile', 'stockpileSite', 'orderedRate', 'preCoatRate', 'otherSealType'],
            },
            {
              title: 'Comments',
              fieldKeys: ['existingSurfaceConditions', 'comment', 'stockpileInstructions'],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompSsProduct