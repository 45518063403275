import React, { useState, SyntheticEvent, useEffect } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Project } from '@src/types/project/Project'
import DialogSectionTitle from '@src/components/Dialog/DialogSectionTitle'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import DialogFieldInlineDatePicker from '@src/components/Dialog/DatePicker'
import moment, { Moment } from 'moment'
import LabourAutocomplete from '@src/components/autocompletes/LabourAutocomplete'
import ProfileChip from '@src/components/Profile/Chip'
import { Profile } from '@src/types/common/Profile'
import FileBox from '@src/modules/DRS/legacy/Create/comps/FileBox'
import UploadTrigger from './UploadTrigger'
import FileChip, { FileChipFile, FileChipStatus } from '@src/modules/DRS/legacy/Create/comps/FileChip'
import { apiSifCreateOne } from '@src/apis/sif-create-one'
import { useAppStore } from '@src/AppStore'
import { Sif, SifBrief } from '@src/types/Sif'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { PackFormType } from '@src/types/PackFormType'
import { RoleId } from '@src/types/common/RoleId'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import ProfileAutocomplete from '@src/components/autocompletes/ProfileAutocomplete'
import { apiSifUpdate } from '@src/apis/sif-update'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'

export interface CreateSifDialogProps {
  open: boolean,
  onSuccess(): void,
  onClose(): void,
  onCancel(): void,
  data: {
    project: Project,
    sif?: SifBrief,
  }
}

let lastFileId: number

const CreateSifDialog = (props: CreateSifDialogProps) => {
  const { open, onClose, onCancel, data, onSuccess } = props
  const { id, name, projectInternalId } = data.project
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ street, setStreet ] = useState<string>('')
  const [ suburb, setSuburb ] = useState<string>('')
  const [ dueDate, setDueDate ] = useState<Moment | undefined>()
  const [ supervisor, setSupervisor ] = useState<Profile| undefined>(undefined)
  const [ files, setFiles ] = useState<{ file: File, fileChip: FileChipFile }[] >([])
  const [ editing, setEditing ] = useState<boolean>(false)
  const { serialId, } = useAppStore()
  const { alert } = useSnackbar()

  useEffect(() => {
    if (data.sif) {
      setEditing(true)
      setStreet(data.sif.street || '')
      setSuburb(data.sif.suburb || '')
      setDueDate(moment(data.sif.date || undefined))
      setSupervisor(data.sif.supervisor || undefined)
    } else {

    }
  }, [])

  const handleOk = async () => {
    if (!street) {
      alert({ message: 'Street required', type: 'error'})
      return 
    }
    if (!suburb) {
      alert({ message: 'Suburb required', type: 'error'})
      return 
    }
    if (!dueDate) {
      alert({ message: 'Due Date required', type: 'error'})
      return 
    }
    if (!supervisor) {
      alert({ message: 'Supervisor required', type: 'error'})
      return 
    }
    // all good to go
    setLoading(true)
    if (editing) {
      try {
        const updateResult = await apiSifUpdate({
          id: data.sif!.id,
          serialId,
          projectInternalId,
          street,
          suburb,
          dueDate: dueDate!.toISOString(),
          supervisor: supervisor,
        })
        setLoading(false)
        alert({ message: `Site Inspection form has been assigned to ${formatProfileFirstLastName(supervisor)}.`})
        onSuccess()
      } catch (e) {
        
      }
    } else {
      try {
        const createResult = await apiSifCreateOne({
          serialId,
          projectInternalId,
          street,
          suburb,
          dueDate: dueDate!.toISOString(),
          supervisor: supervisor,
        })
        const createdSif: Sif = createResult.data.sif
        // startUpload all images sequetially
        const uploadResult = await Promise.all(files.map((item: { file: File, fileChip: FileChipFile }) => {
          return apiUploadFile({
            formType: PackFormType.SITE_INSPECTION_FORM,
            formId: createdSif.id,
            roleId: RoleId.ProjectManager,
            serialId,
            file: item.file,
            projectInternalId,
          })
        }))
        setLoading(false)
        alert({ message: `Site Inspection form has been assigned to ${formatProfileFirstLastName(supervisor)}.`})
        onSuccess()
      } catch(e) {
        setLoading(false)
      }
    }
  }
  const handleFiles = (fileList: FileList) => {
    setFiles([ ...files, ...generateNewFiles(fileList) ])
  }
  const handleFileRemove = (index: number) => async (file: FileChipFile) => {
    setFiles([
      ...files.slice(0, index),
      ...files.slice(index + 1),
    ])
  }
  /** generate new file id */
  function generateUploadingFileId() {
    if (lastFileId) {
      lastFileId = lastFileId + 100
      return lastFileId + 100
    }
    if (files.length) {
      lastFileId = files[files.length - 1].fileChip.id
    } else {
      lastFileId = 100
    }
    let newId = lastFileId + 100
    lastFileId = newId
    return newId
  }
  function generateNewFiles(files: FileList): { file: File, fileChip: FileChipFile }[] {
    return Array.from(files).map((file: File) => {
      const newFileId = generateUploadingFileId()
      const newFile: FileChipFile = {
        id: newFileId,
        name: file.name,
        status: FileChipStatus.NORMAL,
        physicalName: '',
      }
      return {
        file,
        fileChip: newFile,
      }
    })
  }
  return <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle id='labour-dialog-title'>
      {`New Site Inspection - `}
      <ProjectInfo>{`${id || projectInternalId} | ${name}`}</ProjectInfo>
    </DialogTitle>
    <DialogContent>
      <Section>
        <DialogSectionTitle>{`Location and Time Details`}</DialogSectionTitle>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled={editing}
                label={`Street`}
                value={street}
                onChange={(e: SyntheticEvent) => setStreet((e.target as HTMLInputElement).value)}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled={editing}
                label={`Suburb`}
                value={suburb}
                onChange={(e: SyntheticEvent) => setSuburb((e.target as HTMLInputElement).value)}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <DialogFieldInlineDatePicker 
                disabled={editing}
                label={`Due Date`}
                value={dueDate}
                onChange={(dueDate: Moment) => setDueDate(dueDate)}
              />
            </GridInner>
          </Grid>
        </Grid>
      </Section>
      {/* Section II */}
      <Section>
        <DialogSectionTitle>{`Assignees`}</DialogSectionTitle>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              <FormControl>
                <FormLabel>{`Assign Supervisor`}</FormLabel>
                { !supervisor ?
                  <ProfileAutocomplete
                    roleId={RoleId.Supervisor}
                    selectedItem={{ value: '', label: '' }}
                    onChange={(value: {
                      label: string,
                      value: string,
                      raw: Profile,
                    }) => {
                      setSupervisor(value.raw)
                    }}
                  /> :
                  <ProfileChipWrapper>
                    <ProfileChip 
                      profile={supervisor}
                      removable
                      onRemove={() => setSupervisor(undefined)}
                    />
                  </ProfileChipWrapper>
                }
              </FormControl>
            </GridInner>
          </Grid>
        </Grid>
      </Section>

      { !editing &&
      <Section>
        <DialogSectionTitle>{`Attachments`}</DialogSectionTitle>
        {/* <FileBox onDropOrSelect={handleFiles} /> */}
        <UploadTrigger onSelect={handleFiles}/>
        { [...files].map((item: { file: File, fileChip: FileChipFile }, index: number) => {
          const { fileChip } =  item
          return <FileChip
            key={fileChip.id}
            file={fileChip}
            removable={true}
            onRemove={handleFileRemove(index)}
            // onClick={handleFileClick}
            onRemoveTitle={`Confirm removal of ${fileChip.name}`}
            onRemoveMessage={`This action will remove the file attached.`}
          />
        })}
      </Section>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button 
        color='primary'
        variant='contained'
        disabled={loading} 
        onClick={handleOk} 
        >
        { `Assign Site Inspection` }
      </Button>
    </DialogActions>
  </Dialog>
}
const Wrapper = styled.div``
const ProjectInfo = styled.span`
  color: #0A9688;
`
const Section = styled.div`
  margin-bottom: 40px;
`
const GridInner =styled.div`
  padding-right: 16px;
`
const ProfileChipWrapper = styled.div`
  margin-top: 6px;
`
export default CreateSifDialog