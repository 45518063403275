import React from 'react'
import styled from 'styled-components'
import ErrorIcon from '@material-ui/icons/Error'
import Divider from '@material-ui/core/Divider'
import CrewPackStatus from '@src/components/Status/CrewPackStatus'
import ColorSquare from '@src/components/Crew/ColorSquare'
import { MobileAsset } from '@src/modules/MgmtMobileAsset/fields'
import { formatNullStringNumberToString } from '@src/types/format/formatNullStringNumberToString'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'

interface Props {
  mobileAsset: MobileAsset,
  issueData: any
}

const CompMobileAssetDetailInfo = (props: Props) => {
  const {
    mobileAsset,
    issueData
  } = props
  const { id, description, depot, assetReadingKM, assetReadingHour, nextServiceDue, costingSystemAssetNumber, crews, active, error, type } = mobileAsset
  const renderTitle = () => {
    if (error) {
      return <ErrorItem>
        <ErrorIcon />
        { `${id} - ${description}` }
      </ErrorItem>
    } else {
      return <NormalItem>{ `${id} - ${description}` }</NormalItem>
    }
  }
  return <Wrapper>
    { renderTitle() }
    <DetailInfoWrapper>
      <InfoBlock>{`Depot - ${depot.name}`}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>{`Current Kms: ${type.unit == 2 ? "N/A" : formatNullStringNumberToString(assetReadingKM)}`}</InfoBlock>
	    <SDivider orientation='vertical' />
      <InfoBlock>{`Current Hours: ${type.unit == 1 ? "N/A" : formatNullStringNumberToString(assetReadingHour)}`}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>{`Next Service Due: ${formatNullStringNumberToString(nextServiceDue)}`}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>{`Costing Asset Number: ${formatNullStringNumberToString(costingSystemAssetNumber)}`}</InfoBlock>
      {
        !crews.length ? null :
        <React.Fragment>
          <SDivider orientation='vertical' />
          <InfoBlock>
            { crews.map((crew) => {
              return <FlexBox key={crew.id}>
              <ColorSquare color={`#${crew.colourCode}`} />
              <CrewName>{`${crew.name}`}</CrewName>
            </FlexBox>
            })}
          </InfoBlock>
        </React.Fragment>
      }
      <SDivider orientation='vertical' />
      <InfoBlock>
        <Status>{active ? 'Active' : 'Inactive' }</Status>
      </InfoBlock>
    </DetailInfoWrapper>
  </Wrapper>
}

const Wrapper = styled.div`

`
const ErrorItem = styled.div`
  color: #D32929;
  display: flex;
  line-height: 24px;
  font-size: 20px;
  svg {
    margin-right: 10px;
  }
`
const NormalItem = styled.div`
  color: #0A9688;
  font-size: 20px;
`
const DetailInfoWrapper = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: 16px;
`
const SDivider = styled(Divider)`
  background-color: #129688;
`
const InfoBlock = styled.div`
  padding: 0 48px;
  max-height: 60px;
  overflow-y: auto;
`
const Status = styled.div`
  padding: 2px 47px;
  background-color: #129688;
  border-radius: 16px;
  color: #fff;
`
const CrewName = styled.div`
  margin-left: 6px;
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`
export default CompMobileAssetDetailInfo