import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const BoralTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#009688',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#009688',
    },
  },
})(TextField) as any


export default BoralTextField