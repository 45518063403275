import Cookies from 'js-cookie'
import { CookieOptions, REFRESH_TOKEN_EXPIRY_DAYS } from '../../constants'

let LocalIdToken: any
let LocalAccessToken: any

export const getCookieIdToken = () => {
  //console.log(`getCookieIdToken() LocalIdToken=${LocalIdToken}`)
  if (process.env.NODE_ENV === 'development') {
    return LocalIdToken
  }
  return Cookies.get('IdToken')
}

export const setCookieIdToken = (IdToken: any) => {
  if (process.env.NODE_ENV === 'development') {
    LocalIdToken = IdToken
  } else {
    Cookies.set('IdToken', IdToken, CookieOptions)
  }
}

export const getCookieAccessToken = () => {
  if (process.env.NODE_ENV === 'development') {
    return LocalAccessToken
  }
  return Cookies.get('AccessToken')
}

export const setCookieAccessToken = (AccessToken: any) => {
  if (process.env.NODE_ENV === 'development') {
    LocalAccessToken = AccessToken
  } else {
    Cookies.set('AccessToken', AccessToken, CookieOptions)
  }
}

export const getCookieRefreshToken = () => {
  if (process.env.NODE_ENV === 'development') {
    const currentUser = require('../../test-accounts').default
    return currentUser.RefreshToken
  }
  return Cookies.get('RefreshToken')
}

export const setCookieRefreshToken = (RefreshToken: any) => {
  Cookies.set('RefreshToken', RefreshToken, Object.assign({}, CookieOptions, { expires: REFRESH_TOKEN_EXPIRY_DAYS }))
}

export const getCookieEmail = () => {
  if (process.env.NODE_ENV === 'development') {
    const currentUser = require('../../test-accounts').default
    return currentUser.email
  }
  return Cookies.get('Email')
}

export const setCookieEmail = (email: string) => {
  Cookies.set('Email', email, CookieOptions)
}

export function getCookieCurrentRole() {
  if (process.env.NODE_ENV === 'development') {
    const currentUser = require('../../test-accounts').default
    return currentUser.currentRole
  }
  return Cookies.get('CurrentRole')
}

export function setCookieCurrentRole(roleId: string) {
  Cookies.set('CurrentRole', roleId, CookieOptions)
}