import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  sifId: number,
  shiftId: number,
  serialId: number,
}

export const apiSifLinkToCrewPack = (options: Options) => {
  const { sifId, shiftId, serialId } = options
  const data = {

  }
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/shifts/${shiftId}`,
    data,
    params: {
      sif: sifId,
      reqSid: serialId,
      // reqRole: 'RESOURCE_SCHEDULER',
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}