import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  supplierId: number,
}

export const apiSupplierAgreementsBySupplier = (options: Options) => {
  const {
    supplierId,
  } = options
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/supplieragreements`,
    params: {
      supplier: supplierId,
    }
  }
  return ax(axOptions)
}