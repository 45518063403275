import axiosInstance, { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { getCookieIdToken } from '@src/utils/cookies';

interface Options {
  shiftId: number,
  serialId: number,
}

export const apiShiftRemove = (options: Options) => {
	const { 
	  shiftId,
	  serialId,
	} = options
	const axOptions: any = {
	  method: 'DELETE',
	  url: `${BASE_API_URL}/v2/shifts/${shiftId}`,
	  params: {
		reqSid: serialId,
		reqRole: RoleId.ResourceScheduler,
		reqDev: 'web',
	  },
	  headers:  {'Authorization': getCookieIdToken()},
	}
	return axiosInstance.delete(`${BASE_API_URL}/v2/shifts/${shiftId}`,axOptions).catch((error) => {
	  throw error; // Rethrow the error to be caught in the calling code
	});
  };
  