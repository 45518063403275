import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { SRRQAAsphalt } from '@src/types/SRR'
import { JobType } from '@src/types/JobType'

interface Options  {
  srrId: number | string,
  serialId: number,
  jobType: JobType,
  partial: Partial<SRRQAAsphalt>,
}

export const apiSrrQaUpdate = (options: Options) => {
  const { 
    srrId, 
    serialId,
    jobType,
    partial,
  } = options

  const data = {
    srrId,
    type: {
      id: jobType,
    },
    ...partial,
  }
  const asOrSs = jobType === JobType.Asphalt ? 'asphalt' : 'sprayseal'
  const oneOrTwo = jobType === JobType.Asphalt ? 1 : 2
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/quality/${asOrSs}/details/${oneOrTwo}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}