import { SRRResourceTypeId } from '@src/types/SRRResourceTypeId'
import { apiSrrResouceCreate } from '@src/apis/srr-resource-create'
import { apiSrrResouceUpdate } from '@src/apis/srr-resource-update'
import { toValidTimeString } from '@src/utils/time/toValidTime'

interface Options {
  srrId: number,
  serialId: number,
  resouceTypeId: SRRResourceTypeId,
  modalData: null | {},
  form: any,
  alertDone(): void,
  alertError(): void,
  refresh(): void,
  closeModal(): void,
}

export async function handleSaveSRRResource(options: Options) {
  const {
    srrId,
    serialId,
    resouceTypeId,
    modalData,
    form,
    alertDone,
    alertError,
    refresh,
    closeModal,
  } = options
  const finalForm = {
    ...form,
    startTime: toValidTimeString(form.startTime, 'HH:mm'),
  }
  const requestOptions = {
    srrId,
    serialId,
    resouceTypeId,
    partial: {
      ...finalForm
    },
  }
  if (modalData === null) {
    try {
      const result = await apiSrrResouceCreate(requestOptions)
      alertDone()
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  } else {
    try {
      const result = await apiSrrResouceUpdate(requestOptions)
      alertDone()
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  }
}