import React from 'react'
import styled from 'styled-components'
import { Sif, SifBrief } from '@src/types/Sif'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import PersonIcon from '@material-ui/icons/Person'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { Attachment } from '@src/types/Attachment'
import { formatSifStatus, SifStatus } from '@src/types/SifStatus'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import usePermission from '@src/utils/hooks/usePermission'

export interface SifCardProps {
  sifBrief: SifBrief,
  onClick?(): void,
  onEdit?(): void,
}

const SifCard = (props: SifCardProps) => {
  const { sifBrief, onClick, onEdit } = props
  const { status, street, nearestCrossStreet, suburb, supervisor, date, numberOfAttachments } = sifBrief
  const { canReassignSif } = usePermission()
  const handleOnMore = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (onEdit && typeof onEdit === 'function') {
      onEdit()
    }
  }
  return <Wrapper onClick={onClick}>
    <FlexGrowWrapper>
      <TopPart>
        <StreetSuburbWrapper>
          <Street>
            <LocationOnIcon />
            <span>{ street }</span>
          </Street>
          <Suburb>
            {suburb}
          </Suburb>
        </StreetSuburbWrapper>
        { canReassignSif && status.id === SifStatus.Assigned && <SMoreVertIcon onClick={handleOnMore}/> }
      </TopPart>
      <DetailWrapper>
        <Date>
          <CalendarTodayIcon />
          {formatDateStringDDMMYYYY(date || '')}
        </Date>
        <Supervisor>
          <PersonIcon />
          {supervisor ? formatProfileFirstLastName(supervisor) : ''}
        </Supervisor>
        {/* <Attachments>
          <AttachmentIcon />
          { numberOfAttachments }
        </Attachments> */}
      </DetailWrapper>
    </FlexGrowWrapper>
    <Status>{formatSifStatus(status.id)}</Status>
  </Wrapper>
}

const Wrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #F9F9F9;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const FlexGrowWrapper = styled.div`
  flex-grow: 1;
`
const DetailWrapper = styled.div`
  margin-top: 16px;
`
const Street = styled.div`
  color: #179687;
  font-size: 16px;
  height: 21px;
  line-height: 21px;
  display: flex;
  > svg {
    font-size: 21px;
    margin-right: 8px;
  }
`

const Suburb = styled.div`
  display: flex;
  font-size: 12px;
  margin-left: 30px;
  margin-top: 4px;
`
const Date = styled.div`
  display: flex;
  font-size: 12px;
  > svg {
    font-size: 14px;
    color: #0A9688;
    margin-right: 16px;
  }
`
const Supervisor = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 12px;
  > svg {
    font-size: 16px;
    color: #0A9688;
    margin-right: 14px;
  }
`
const Attachments = styled.div`
  margin-top: 8px;
  display: flex;
  font-size: 12px;
  > svg {
    font-size: 16px;
    color: #0A9688;
    margin-right: 14px;
  }
`
const Status = styled.div`
  border-radius: 8px;
  line-height: 16px;
  color: #F9F9F9;
  font-size: 12px;
  background-color: #0A9688;
  padding: 2px 6px;
  display: inline-block;
  width: fit-content;
`
const SMoreVertIcon = styled(MoreVertIcon)`
  color: #0A9688;
  font-size: 18px;
  cursor: pointer;
`
const TopPart = styled.div`
  display: flex;
`
const StreetSuburbWrapper = styled.div`
  flex-grow: 1;
`

export default SifCard