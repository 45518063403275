import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


export const apiProjectGetOne = (id: string) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/projects/${id}`,
  }
  return ax(options)
}