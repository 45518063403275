import axios from 'axios';

declare global {
    interface Window {
      jwt_decode:any;
    }
}
const jwt_decode = window.jwt_decode;
export const checkToken = async() => {
    if(localStorage.getItem("apiToken") && jwt_decode(localStorage.getItem("apiToken")).exp > Date.now() / 1000) {
      return true;
    } else {
      const data:any = "grant_type=client_credentials";
      const options: any = {
        method: 'POST',
        headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          'Authorization' : `Basic ${btoa("2bh641jer1h9la5v5mtlr14i1i:1jmc6d94be4n8q18jdk1f28puvs58d266la3uohhr3otj4nc8l7i")}`
        },
        data,
        url: 'https://device-api-auth.senquip.com/oauth2/token',
      };
      const response: any = await axios(options);
      if(response && response.status == 200) {
        localStorage.setItem("apiToken", response.data.access_token);
        return true;
      }else{
        return false;
      }
    }
  }