import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { Project } from '@src/types/project/Project'

interface Options {
  project: Project,
  shiftTypeId: string,
  dates: string[],
  crewId: number,
  drsProjectTypeId: string,
  serialId: number,
  street: string,
  nearestCrossStreet: string,
  suburb: string,
  planKMPlantToDepot?:number;
  ShiftSealingRequirment: string;
  ShiftSalesOrder: string;
  isCostOnlyShift: boolean;
  
}
export const apiShiftCreate = ({
  project,
  shiftTypeId,
  dates,
  crewId,
  drsProjectTypeId,
  serialId,
  street,
  nearestCrossStreet,
  suburb,
  planKMPlantToDepot,
  ShiftSealingRequirment,
  ShiftSalesOrder,
  isCostOnlyShift
}: Options) => {
  const data = {
    projectInternalId: project.projectInternalId,
    dates,
    plan: {
      shiftType: {
        id: shiftTypeId
      },
      crew: {
        id: crewId,
      },
      street,
      nearestCrossStreet,
      suburb,
      planKMPlantToDepot
    },
    drsProjectType: {
      id: drsProjectTypeId
    },
    ShiftSealingRequirment,
    ShiftSalesOrder,
	isCostOnlyShift
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/shifts`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}