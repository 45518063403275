import React from 'react'
import styled from 'styled-components'

export interface FormCardRowProps {
  children: React.ReactNode | React.ReactNode[],
}

const FormCardRow = (props: FormCardRowProps) => {
  const { children } = props
  if (!children) { 
    return null
  }
  return <Wrapper>
    { React.Children.toArray(children).map((child: React.ReactNode, index: number) => {
      return <ItemWrapper key={index}>{child}</ItemWrapper>
    })}
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
`
const ItemWrapper = styled.div`
  padding-right: 40px;
`

export default FormCardRow