import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormTableField, FormTableProps } from './Table'
import PAEAttachmentsCell from './PAEAttachmentsCell'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PAETableMoreActionsMenu, { PAETableMoreActionType } from './comps/ActionMenu'
import { PackFormType } from '@src/types/PackFormType'
import Checkbox from '@material-ui/core/Checkbox'
import { alpha, FormControlLabel, FormGroup, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'


export enum PAETableRowType {
  First, // plan
  Second, // actual or edit
  Third, // other
}
export interface FormPAETableInteralData {
  _key_: string,
  _no_?: number,
  _type_: PAETableRowType,
  _editable_: boolean,
  _more_actions_?: PAETableMoreActionType[],
  [key: string]: any,
}

interface FormPAETable<T> extends FormTableProps<T> {
  showNo?: boolean,
  showCheckBox?: boolean,
  showPAEType?: boolean,
  showMoreActions?: boolean,
  onRevert?(row: T): void,
  onMarkZero?(row: T): void,
  onDelete?(row: any): void,
  onDeleteSelected?(rows: T[]): void,
  onDuplicate?(row: T): void,
  isSelectable?: boolean
  isSpecialDelete?: boolean
  isPlan?: boolean,
  PAEType?: String

}

/* Table for Plan Actual Edit(PAE) */
function FormPAETable<T extends FormPAETableInteralData>(props: FormPAETable<T>) {
  const {
    fields,
    data,
    editable = false,
    onEditRow,
    onRemoveRow,
    showNo = false,
    showPAEType = true,
    showMoreActions = true,
    onRevert,
    onMarkZero,
    onDelete,
    onDeleteSelected,
    onDuplicate,
    isSelectable,
    isSpecialDelete,
    isPlan = false,
    PAEType = 'null'
  } = props
  // anchor for more action menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [rowOnAction, setRowOnAction] = useState<null | T>(null)
  const [rowActions, setRowActions] = useState<PAETableMoreActionType[]>([])
  const [selected, setSelected] = useState<any[]>([]);
  const { confirm } = useConfirm()


  const isSelected = (id: number) => {
    const index = selected.findIndex(item => item._no_ === id)
    return index !== -1;
  }


  const handleOnChange = (row) => {
    const selectedIndex = selected.findIndex(item => item._no_ === row._no_);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(data);
      return;
    }
    setSelected([]);
  };

  const beforeDeleteSelected = async () => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure?',
      })
      selected.sort((a,b) => b._no_ - a._no_)
      if (typeof onDeleteSelected === 'function') {
        onDeleteSelected(selected)
        console.log("hi")
      } else {
        if(isSpecialDelete) {
          onDelete(selected);
        }else {
          selected.forEach(item => {
            onDelete(item);
            })
        }
        
       
      }
      setSelected([])
    } catch (e) { }
  }



  function renderTableHeaderLabel(field: FormTableField<T>) {
    const { key, type, label, format } = field
    if (label) {
      return label
    }
    // resort to key displayed as label
    return key
  }

  function renderCellString(field: FormTableField<T>, row: T) {
    const { key, type, label, format, render } = field
    const cellData: any = row[field.key]
    if (render && typeof render === 'function') {
      return render(cellData, row)
    }
    if (format && typeof format === 'function') {
      return format(cellData, row)
    }
    return cellData
  }
  function renderRowType(row: T) {
    switch (row._type_) {
      case PAETableRowType.First: {
        return 'Planned'
      }
      case PAETableRowType.Second: {
        return 'Actual'
      }
    }
    return null
  }
  const renderMoreActions = (row: T) => {
    const actions = row._more_actions_ || []
    if (!actions.length) {
      return null
    }
    return <React.Fragment>
      <SmallIconButton
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
          setRowActions(actions)
          setRowOnAction(row)
        }}
      >
        <MoreHorizIcon />
      </SmallIconButton>
    </React.Fragment>
  }


  const renderActionTollbars = () => {
    if (selected.length > 0) {
      return <React.Fragment>
        <TableActionToolbar style={{ backgroundColor: '#E4F3F2' }}>
          <TypographySelected>
            {selected.length} selected
          </TypographySelected>
          <Tooltip title="Delete">
            <IconButton
              style={{ padding: '0' }}
              onClick={beforeDeleteSelected}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableActionToolbar>
      </React.Fragment >
    }
  }
  return <Wrapper>
    <Typography>
      {renderActionTollbars()}
    </Typography>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {showPAEType &&
              <TableHeaderCell></TableHeaderCell>
            }
            {editable &&
              <TableHeaderCell>Edit</TableHeaderCell>
            }
            {showNo &&
              <TableHeaderCell>No.</TableHeaderCell>
            }
            {fields.map((field: FormTableField<T>, index: number) => {
              return (
                <TableHeaderCell key={index}>
                  {renderTableHeaderLabel(field)}
                </TableHeaderCell>
              )
            })}
            {data.length > 0 && isSelectable &&
              <TableHeaderCell>
                 <Checkbox
                  color="primary"
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all',
            }}
          />
              </TableHeaderCell>
            }
            {showMoreActions &&
              <TableHeaderCell>
                Actions
              </TableHeaderCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: T, rowIndex: number) => {
            if (row._type_ === PAETableRowType.Third) {
              const colSpan = [editable, editable, showNo].reduce((acc, curr) => {
                return acc + (curr ? 1 : 0)
              }, 0) + fields.length
              return <TableDataRow
                last={1}
                key={row._key_}
              >
                <SCell colSpan={1}></SCell>
                <SCell colSpan={colSpan}>
                  <PAEAttachmentsCell
                    packFormType={row._pack_form_type_}
                    packFormId={row._pack_form_id_}
                  />
                </SCell>
              </TableDataRow>
            }
            return <TableDataRow
              hover
              key={row._key_ || rowIndex}
              // onClick={handleClick(row)}
              last={row._type_ === PAETableRowType.Second && !row._pack_form_id_ ? 1 : 0}
            >
              {showPAEType &&
                <SCell>{renderRowType(row)}</SCell>
              }
              {editable &&
                <SCell>
                  <IconButton
                    size='small'
                    disabled={!row._editable_}
                    onClick={() => {
                      if (onEditRow && typeof onEditRow === 'function') {
                        onEditRow(row)
                      }
                    }}>
                    <EditIcon />
                  </IconButton>
                </SCell>
              }
              {showNo &&
                <SCell>
                  {row._no_ !== undefined ? `${row._no_ + 1}.` : ''}
                </SCell>
              }
              {fields.map((field: FormTableField<T>, index: number) => {
                return <SCell key={index}>
                  {
                   // renderCellString(field, row)
                   (row._type_ === PAETableRowType.Second && !isPlan) ? renderCellString(field, row) : (field.key == 'notUsed' ? '' : renderCellString(field, row))
                  }
                  
                </SCell>
              })}
              {isSelectable &&   
                <SCell>
                  {(row._type_ === PAETableRowType.Second && !isPlan) || (row._type_ === PAETableRowType.First && isPlan) || (PAEType !=='mobileassets')  ?
                  <FormGroup style={{paddingLeft:'20%'}}>
                    <FormControlLabel
                      control={
                        <Checkbox id={`custom-checkbox-${rowIndex}`}
                          checked={isSelected(row._no_)}
                          onChange={() => handleOnChange(row)}
                        />}
                      label=" " />
                  </FormGroup> : ''}
                </SCell>
              }
              {showMoreActions && 
                <SCell>
                  {renderMoreActions(row)}
                </SCell>
              }
            </TableDataRow>
          })}

        </TableBody>
      </Table>
    </TableContainer>
    <PAETableMoreActionsMenu
      actions={rowActions}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      onRevert={() => {
        if (onRevert && typeof onRevert === 'function' && rowOnAction) {
          onRevert(rowOnAction)
        }
        setAnchorEl(null)
      }}
      onMarkZero={() => {
        if (onMarkZero && typeof onMarkZero === 'function' && rowOnAction) {
          onMarkZero(rowOnAction)
        }
        setAnchorEl(null)
      }}
      onDelete={() => {
        if (onDelete && typeof onDelete === 'function' && rowOnAction) {
          onDelete(rowOnAction)
          setSelected([])
        }
        setAnchorEl(null)
      }}
      onDuplicate={() => {
        if (onDuplicate && typeof onDuplicate === 'function' && rowOnAction) {
          onDuplicate(rowOnAction)
        }
        setAnchorEl(null)
      }}
    />
  </Wrapper>
}

const Wrapper = styled.div`
  /* overflow-x: auto; */
`

const TableActionToolbar = styled(Toolbar)`
  justify-content: space-between;
  background-color: primary;
`

const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const SCell = styled(TableCell)`

`
const TableDataRow = styled(TableRow) <{ last: number }>`
  td {
    ${props => {
    if (props.last) {
      return css`
          border-bottom-color: #0A9688;
        `
    }
  }}
  }
`
const SmallIconButton = styled(IconButton)`
  padding: 3px;
`

const TypographySelected = styled(Typography)`
  flex: '1 1 100%'
  color = "inherit"
  variant = "subtitle1"
  component = "div"
`
export default FormPAETable