import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import Panel from '../comps/Panel'
import { useDRS, getEmptyMaterialAsProduct } from '../StoreDRS'
import _get from 'lodash/get'
import { getStartEndDiff, formatHoursToDecimal } from '../comps/f-LabourMemberTable'

export const getMemberTotalHours = (members: DRS.DRSLabourRow[]) => {
  if (!members || !members.length) {
    return 0
  }
  return members
  .filter(member => member.plan.startTime && member.plan.endTime)
  .reduce((acc, curr) => {

    // let diff = Math.floor(moment.duration(moment(curr.plan.endTime).diff(moment(curr.plan.startTime))).asHours())
    // if (diff < 0) {
    //   diff = 0
    // }
    let diff = getStartEndDiff({
      start: curr.plan.startTime,
      end: curr.plan.endTime,
    })
    return acc + ( diff! ? 0 : diff! )
  }, 0)
}

function calcDuration({
  member,
  colName = 'plan',
}: {
  member: DRS.DRSLabourRow,
  colName: 'plan' | 'edit' | 'actual',
}) {
  if (member[colName] && member[colName].startTime && member[colName].endTime) {
    // let diff = Math.floor(moment.duration(moment(member[colName].endTime).diff(moment(member[colName].startTime))).asHours())
    // if (diff < 0) {
    //   diff = diff + 24
    // }
    // return diff
    let diff = getStartEndDiff({
      start: member[colName].startTime,
      end: member[colName].endTime,
    })
    return diff
  } else {
    return 0
  }
}

function getHours({
  roleId,
  members,
  colName,
}: {
  roleId: string,
  members: DRS.DRSLabourRow[],
  colName: 'plan' | 'edit' | 'actual',
}) {
  if (!roleId) {
    return 0
  }
  const filtered = members.filter(member => {
    const { roles } = member.profile
    if (!roles || !roles.length) {
      return false
    }
    return roles.some(role => role.default && role.id === roleId && member[colName].present)
  })
  return filtered.reduce((acc, curr) => {
    return acc + calcDuration({ 
      member: curr, 
      colName,
    })!
  }, 0) 
}

export const getGeneralCrewHours = (members: DRS.DRSLabourRow[], colName: DRS.PlanActualEdit = 'plan') => {
  return getHours({
    roleId: 'GENERAL_CREW',
    members,
    colName,
  })
}
export const getForemanHours = (members: DRS.DRSLabourRow[], colName: DRS.PlanActualEdit = 'plan') => {
  return getHours({
    roleId: 'FOREMAN',
    members,
    colName,
  })
}
export const getSupervisorHours = (members: DRS.DRSLabourRow[], colName: DRS.PlanActualEdit = 'plan') => {
  return getHours({
    roleId: 'SUPERVISOR',
    members,
    colName,
  })
}
export const getTotalHours = (rootData: DRS.RootData, colName: DRS.PlanActualEdit = 'plan', editMembers: any[] = []) => {
  let members = _get(rootData, `labour.detail`, [])
  if (colName === 'edit') {
    members = editMembers
  }
  const generalCrewHours = getGeneralCrewHours(members, colName)
  const foremanHours = getForemanHours(members, colName)
  const supervisorHours = getSupervisorHours(members, colName)
  const projectManagerHours = Number(_get(rootData, `labour.summary.${colName}.pmHours`, '0'))
  return generalCrewHours + foremanHours + supervisorHours + projectManagerHours
}
export const mergeActualToEdit = ({
  members = [],
}) => {
  let result: any[] = []
  members.forEach((member: DRS.DRSLabourRow, index: number) => {
    // use edit or actual
    if (/* member.edit.present &&  */member.edit.startTime && member.edit.endTime) {
      result.push(member)
    } else {
      result.push({
        ...member,
        edit: {
          ...member.actual,
        }
      })
    }
  })
  return result
}
const getPanel = (data: DRS.RootData) => {
  const members: any = _get(data, `labour.detail`)
  const crewCount = members.length
  const crewHours = getGeneralCrewHours(members)
  const foremanHours = getForemanHours(members)
  const supervisorHours = getSupervisorHours(members)
  const projectManagerHours = Number(_get(data, `labour.summary.plan.pmHours`, '0'))
  const totalHours = getTotalHours(data)

  let panel = {
    name: `Crew Details`,
    rows: [
      {
        id: `Row - Crew Details`,
        name: `Crew Details`,
        fields: [
          {
            name: `Crew Count`,
            path: `labour.members.length`,
            data: crewCount,
            typeMeta: {
              type: 'text',
            },
          },
          {
            name: `Crew Hours`,
            path: `labour.members.crewHours`,
            data: formatHoursToDecimal(crewHours),
            typeMeta: {
              type: 'text',
            },
          },
          {
            name: `Foreman Hours`,
            path: `labour.members.foremanHours`,
            data: formatHoursToDecimal(foremanHours),
            typeMeta: {
              type: 'text',
            },
          },
          {
            name: `Supervisor Hours`,
            path: `labour.members.supervisorHours`,
            data: formatHoursToDecimal(supervisorHours),
            typeMeta: {
              type: 'text',
            },
          },
          {
            name: `Project Manager Hours`,
            path: `labour.summary.plan.pmHours`,
            data: projectManagerHours,
            typeMeta: {
              type: 'editable-number',
            },
          },
          {
            name: `Total Hours`,
            path: `labour.members.totalHours`,
            data: formatHoursToDecimal(totalHours),
            typeMeta: {
              type: 'text',
            },
          },
        ]
      }
    ]
  }
  return panel
}

const LabourDetailPanel = ({
  onFieldChange,
}: {
  onFieldChange(field: any, value: any): void,
}) => {
  const { data } = useDRS()

  let panel = getPanel(data)

  return <Panel 
    panel={panel}
    onFieldChange={onFieldChange}
  />
}


export default observer(LabourDetailPanel, {
  forwardRef: true,
})