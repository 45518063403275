import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { PAETableRowType } from '@src/components/Form/PAETable'
import { DRSMaterialSpraySealItemRowDisplay } from './MaterialSpraySealItemsFields'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import { useAppStore } from '@src/AppStore'
import CommentDialog from './CommentDialog'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useCrewCalender } from '@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import moment from 'moment'
import { apiSearchShiftNote } from '@src/apis/shift-note/shift-note-create'
import { apiShiftLogNotes } from '@src/apis/shift-note/shift-log-note'
import ShitLogNotesDialog from './LogNotesDialog'

interface DRSMaterialSpraySealItemRow extends DRSMaterialSpraySealItemRowDisplay {
    _key_: string,
    _type_: PAETableRowType,
    _no_?: number,
    _editable_: boolean,
}

interface Props {
    onUpdated?(): void;
    shiftID?: number;
}
const Format = 'DD/MM/YYYY'
const DRSSectionComment = (props: Props) => {
    const { shiftID } = props
    const { username } = useAppStore()
    const [open, modalData, openAndSetData, closeModal] = useModal<DRSMaterialSpraySealItemRow>({})
    const { goRefresh } = useCrewCalender()
    const { alertDone, alertError, alert } = useSnackbar()
    const [shiftCommentData, setShiftCommentData] = useState<[]>([]);
    const [shiftLogNoteData, setShiftLogNoteData] = useState<[]>([]);
    const [isShiftLogNoteDialogOpen, setShiftLogNoteDialogOpen] = useState<boolean>(false)
    const [commentData, setCommentData] = useState<any>(null)
    useEffect(() => {
        fetchComments()
    }, [])

    const fetchComments = () => {
        apiSearchShiftNote(shiftID)
            .then((data) => {
                setShiftCommentData(data.data.shiftNote);
            })
    }
    const fetchShiftLogNotes = (ShiftCalendarNotesID: string) => {
        apiShiftLogNotes({ShiftCalendarNotesID})
            .then((response: any) => {
                let dataResponse: any = response.data;
               if (dataResponse) {
                 
                    
                   if (typeof dataResponse.shiftLogNote != "undefined") {
                   
                        if (dataResponse.shiftLogNote.length > 0) {
                            setShiftLogNoteDialogOpen(true);
                            setShiftLogNoteData(dataResponse.shiftLogNote);
                        } else {
							// TODO: show proper error message
                           // alertDone()

                           alert({
                            type: 'error',
                            message: 'No Log History available for this note',
                        })
                        }
                   } else {
				    // TODO: show proper error message
                    alertDone()
                    alert({
                        type: 'error',
                        message: 'No Log History available for this note',
                    })
                   }
               } else{
				// TODO: show proper error message
                alertDone()
               }
            })
    }
    return <Wrapper>
        
            <TableContainer style={{ marginBottom: '15px' }}>
                <Table aria-labelledby="commentlist" style={{ border: '2px solid' }}>
                    <TableHeaderCell>
                        <TableRow>
                            <TableCellCusHead align="center">Notes</TableCellCusHead>
                            <TableCellCusHead align="center">Creator Name</TableCellCusHead>
                            <TableCellCusHead align="center">Date</TableCellCusHead>
                            <TableCellCusHead align="center">Action</TableCellCusHead>
							{/* <TableCellCusHead align="center"></TableCellCusHead> */}
                        </TableRow>
                    </TableHeaderCell>
                    <TableBody>
                        {shiftCommentData.length > 0 && shiftCommentData.map((item: { ShiftId: string, Comment: string,IsAcknowledge:boolean, CreatedBy: string, Date: string,ShiftCalendarNotesID:string }) => (
                            <TableRow>
                                <TableCellCus key={item.ShiftId} align="center">{item.Comment}</TableCellCus>
                                <TableCellCus key={item.ShiftId} align="center">{item.CreatedBy}</TableCellCus>
                                <TableCellCus key={item.ShiftId} align="center">{moment(item.Date).format(Format)}</TableCellCus>
                                <TableCellCus key={item.ShiftId} align="center">
									{item.IsAcknowledge && <CompThemeButton onClick={() => {
										fetchShiftLogNotes(item.ShiftCalendarNotesID)
										}}>
											Log History
									</CompThemeButton>
                                    }
                                    	{!item.IsAcknowledge && <CompThemeButton onClick={() => {
                                            setCommentData(item);
										openAndSetData(null);
										}}>
											Edit
									</CompThemeButton>
}
								</TableCellCus>
								
                            </TableRow>)
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <MarginTop>
                <CompCreateButton onClick={() => {
                     setCommentData(null);
                    openAndSetData(null)
                }}>
                    Add Notes
                </CompCreateButton>
            </MarginTop>

        

        {open &&
            <CommentDialog
                open={open}
                commentData={commentData}
                createdBy={username}
                ShiftID={shiftID}
                onClose={closeModal}
                fetchComments={() => {
                    fetchComments()
                }}
                onSave={() => {
                    goRefresh()
                }}
            />
        }
             {isShiftLogNoteDialogOpen &&
            <ShitLogNotesDialog
                open={isShiftLogNoteDialogOpen}
                shiftCommentData={shiftLogNoteData}
                onClose={() => {
                  setShiftLogNoteDialogOpen(false);
                }}
                
               
            />
        }
    </Wrapper>
}
const TableCellCus = styled(TableCell)({
    border: '2px solid #f7f8f9',
    wordBreak:'break-all',
})
const Wrapper = styled.div`
        `
const TableHeaderCell = styled(TableHead)`
        background-color: #F5F5F5;
        `
const TableCellCusHead = styled(TableCell)({
    border: '2px solid #f7f8f9',
    color: '#707070',
    fontWeight: 'bold',
    fontSize: '16px'
})




export default DRSSectionComment