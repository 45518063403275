import React, { SyntheticEvent, ReactElement } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { observer } from 'mobx-react-lite'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link';
import { BoralSwitch } from '../../components'
import CrewStatic from '../../components/CrewStatic'
import { apiLabourUpdate } from '../../apis/labour-update'
import { useSnackbar } from '../../components/GlobalSnackbar'
import DefaultRoleIcon from '@material-ui/icons/VerifiedUserRounded'
import usePermission from '@src/utils/hooks/usePermission'
import { DepotType, formatDepotType } from '@src/types/common/DepotType'
import { useRSMgmtStore } from './Store'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useButtonStyles from '@src/utils/hooks/useButtonStyles'

interface Column {
  id: string,
  fieldname: string,
  label: string,
  type?: string,
  format?(value: any): ReactElement | null,
  element?: any,
  width?: number,
  minWidth?: number,
  sortable: boolean,
}

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: #9a9898;
    font-size: 18px;
    margin-left: 4px;
  }
`
const columns: Column[] = [
  {
    id: 'actions',
    fieldname: 'actions',
    label: 'Edit',
    type: 'custom',
    element: <IconButton size='small'><EditIcon /></IconButton>,
    width: 40,
    sortable: false,
  },
  { id: 'firstName', fieldname: 'FirstName', label: 'First Name', minWidth: 170, sortable: true },
  { id: 'lastName', fieldname: 'LastName', label: 'Last Name', minWidth: 100, sortable: true },
  { id: 'email', fieldname: 'EmailAddress', label: 'Email', minWidth: 200, sortable: true },
  {
    id: 'roles', fieldname: '', label: 'Role', minWidth: 100, sortable: false,
    format: (value: any) => {
      if (!value) return null
      return <React.Fragment>
        {value.map((role: any) => {
          return <div key={role.id}>
            <RoleWrapper>
              {role.name} {role.default && <DefaultRoleIcon />}
            </RoleWrapper>
          </div>
        })}
      </React.Fragment>
    }
  },
  { id: 'mobileNumber', fieldname: '', label: 'Mobile Number', minWidth: 100, sortable: false },
  {
    id: 'depot', fieldname: 'DepotID', label: 'Depot', minWidth: 100, sortable: false,
    format: (value: any) => {
      if (!value) return null
      const { id, name } = value
      return <React.Fragment>
        <Typography>
          {name}
        </Typography>
      </React.Fragment>
    }
  },
  {
    id: 'depotType', fieldname: '', label: 'Labour Pool', minWidth: 100, sortable: false,
    format: (value: null | {
      id: DepotType,
    }) => {
      return <React.Fragment>
        <Typography>
          {value ? formatDepotType(value.id) : ''}
        </Typography>
      </React.Fragment>
    }
  },
  {
    id: 'crews', fieldname: '', label: 'Crews', minWidth: 100, sortable: false,
    format: (value: any) => {
      if (!value) return null
      return <React.Fragment>
        {value.map((crew: any) => {
          return <div key={crew.id}>
            <CrewStatic crew={crew} />
          </div>
        })}
      </React.Fragment>
    }
  },
  {
    id: 'active', fieldname: 'Active', label: 'Status', minWidth: 100, sortable: false,
    type: 'custom',
    element: <BoralSwitch />,
  },
]

function MemberTable({
  members = [],
  onEditLabour,
  onUpdateSuccess,
}: {
  members: AIMS.Profile[],
  onEditLabour(profile: AIMS.Profile): void,
  onUpdateSuccess(): void,
}) {
  const { alert } = useSnackbar()
  const { canCreateEditLabour } = usePermission()
  const { labourSortOrder, setLabourSortOrder, labourAscDesc, setLabourAscDesc, query } = useRSMgmtStore()
  const classes: any = useButtonStyles()
  const handleEditClicked = (row: AIMS.Profile) => () => {
    //console.log(`MemberTable handleEditClicked() ${row} clicked edit`)
    onEditLabour(row)
  }
  const handleStatusChange = (row: AIMS.Profile) => async (e: SyntheticEvent) => {
    const upcoming = (e.target as HTMLInputElement).checked
    const result = await apiLabourUpdate({
      ...row,
      depotId: row.depot.id,
      active: upcoming,
      serialId: row.serialId,
    } as any)
    if (result.data) {
      alert({
        message: 'Updated.'
      })
      onUpdateSuccess()
    }
  }
  const renderHeading = (column: Column) => {
    //console.log(`MemberTable column.id=${JSON.stringify(column.id, null, 2)}`)
    return <HeadingTitle>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setLabourSortOrder(column.fieldname)
        }}
      >
        {column.fieldname === labourSortOrder ? <BoldTypography>{column.label}</BoldTypography> : `${column.label}`}
      </Link>
      <IconButton className={column.fieldname === labourSortOrder && labourAscDesc === 'ASC' ? classes.mediumButton : classes.smallButton} onClick={() => { setLabourAscDesc(column.fieldname, 'ASC') }}>
        <ArrowDropUpIcon className={column.fieldname === labourSortOrder && labourAscDesc === 'ASC' ? classes.mediumIcon : classes.smallIcon} />
      </IconButton>
      <IconButton className={column.fieldname === labourSortOrder && labourAscDesc === 'DESC' ? classes.mediumButton : classes.smallButton} onClick={() => { setLabourAscDesc(column.fieldname, 'DESC') }}>
        <ArrowDropDownIcon className={column.fieldname === labourSortOrder && labourAscDesc === 'DESC' ? classes.mediumIcon : classes.smallIcon} />
      </IconButton>
    </HeadingTitle>
  }
  return <Wrapper>
    <Table stickyHeader aria-label='sticky table'>
      <TableHead>
        <TableRow>
          {columns.map((column: any) => (
            <HeaderCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width,
              }}
            >
              { column.sortable ? renderHeading(column) : column.label}
            </HeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {members.length === 0 ?
          <TableRow hover role='checkbox' tabIndex={-1} key={1}>
            <TableCell align={'center'} colSpan={columns.length}>
              <Typography>No matches found.</Typography>
            </TableCell>
          </TableRow>
          : members.map((row: AIMS.Profile) => {
            //console.log(`MemberTable row=${JSON.stringify(row, null, 2)}`)
            return (
              <TableRow hover role='checkbox' tabIndex={-1} key={row.serialId}>
                {columns.map((column: Column) => {
                  const { id, format, type, element } = column
                  const value = _get(row, `${id}`)
                  let finalElement

                  if (type === 'custom') {
                    let additionalProps: { [key: string]: any } = {}
                    if (id === 'actions') {
                      additionalProps.disabled = !canCreateEditLabour
                      additionalProps.onClick = handleEditClicked(row)
                    } else if (id === 'active') {
                      additionalProps.checked = value
                      additionalProps.disabled = !canCreateEditLabour
                      additionalProps.onChange = handleStatusChange(row)
                    }
                    finalElement = React.cloneElement(element, { ...element.props, ...additionalProps })
                  } else {
                    finalElement = format && typeof format === 'function' ? format(value) : value
                  }

                  return (
                    <TableCell
                      key={id}
                    >
                      { finalElement}
                    </TableCell>
                  );
                })}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
const HeaderCell = styled(TableCell)`
  border-bottom: none;
  font-size: 16px;
  color: #645E5E;
  background-color: #F5F5F5;
` as typeof TableCell

const HeadingTitle = styled.div`
  white-space: nowrap;
`

const BoldTypography = styled(Typography)`
  font-weight: bold;
` as typeof Typography

export default observer(MemberTable)