import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTabs, ASSSTabs, getTabsByType } from '@src/components/Tabs/useTabs'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import TabsWrapper from '../../comps/TabsWrapper'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import CompASQuality from './parts/ASQuality'
import CompSSQuality from './parts/SSQuality'
import { useSRR } from '../../useSRR'
import { ProjectTypeId } from '@src/types/ProjectTypeId'

interface Props {

}

const SRRQuality = (props: Props) => {
  const {

  } = props
  const [ tabIndex, tabs, handleTabChange, setTabs ] = useTabs(ASSSTabs)
  const [ srr, srrLoading, refresh ] = useSRR()
  useEffect(() => {
    if (srr !== null) {
      setTabs(getTabsByType(srr.job.shiftDetails.shiftType.id))
    }
  }, [srr])

  if (srr === null) {
    return null
  }
  const renderAsOrSS = () => {
    const currentTab: Tab = tabs[tabIndex]
    if (currentTab.key === ProjectTypeId.Asphalt) {
      return <React.Fragment>
        <CompASQuality />
      </React.Fragment>
    } else {
      return <React.Fragment>
        <CompSSQuality />
      </React.Fragment>
    }
  }
  return <Wrapper>
    <FormSectionTitle>QUALITY</FormSectionTitle>
    <TabsWrapper>
      <TabsComp 
        tabs={tabs} 
        activeIndex={tabIndex}
        onChange={handleTabChange}
      />
    </TabsWrapper>
    { renderAsOrSS() }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default SRRQuality