import React, { useEffect } from 'react'
import styled from 'styled-components'
import DRSUploadAttachmentTrigger from '@src/modules/DRS/parts/9-Summary/parts/attachments/UploadTrigger'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { PackFormType } from '@src/types/PackFormType'
import { mapDRSPageTypeToRoleId, DRSPageType } from '@src/modules/DRS/DRSPage'
import { useDRS } from '@src/modules/DRS/useDRS'
import { useSnackbar } from '../GlobalSnackbar'
import { useAppStore } from '@src/AppStore'
import { useAttachmentsInPackForm } from '@src/modules/DRS/utils/useAttachmentsInPackForm'
import { Attachment } from '@src/types/Attachment'
import AttachmentField from './AttachmentField'
import { RoleId } from '@src/types/common/RoleId'
import { useConfirm } from '../GlobalConfirmDialog'
import { apiRemoveFile } from '@src/apis/files/remove-file'

interface Props {
  packFormType: PackFormType,
  packFormId: string | number,
}

const PAEAttachmentsCell = (props: Props) => {
  const {
    packFormType,
    packFormId,
  } = props
  const { drs, drsId, drsPageType, } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const roleId = getRole()
  const { alertDone, alertError } = useSnackbar()
  const { serialId } = useAppStore()
  const { confirm } = useConfirm()
  const { attachments, refresh } = useAttachmentsInPackForm(packFormType, packFormId)
  function getRole() {
    if (isPlan) {
      return RoleId.ResourceScheduler
    } else if (isEdit) {
      return RoleId.ProjectManager
    } else {
      return RoleId.Administrator
    }
  }
  const handleFileSelect = async (files: FileList) => {
    if (!drs) {
      return
    }
    try {
      const uploadResult = await Promise.all(
        Array.from(files)
          .map((file: File) => {
            return apiUploadFile({
              formType: packFormType,
              formId: packFormId,
              roleId: mapDRSPageTypeToRoleId(drsPageType),
              serialId,
              file: file,
              projectInternalId: drs.jobDetails.project.projectInternalId,
            })
          })
      )
      alertDone()
      refresh()
    } catch (e) {
      alertError()
    }
  }
  const onRemove = async (attachment: Attachment) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Sure to delete this file?',
      })
      remove(attachment)
    } catch (e) { }
  }
  const remove = async (attachment: Attachment) => {
    try {
      await apiRemoveFile({
        id: attachment.id,
        formType: packFormType,
        formId: packFormId,
        roleId,
        serialId,
      })
      alertDone()
      refresh()
    } catch (e) {
      alertError()
    }
  }
  return <Wrapper>
    {(isPlan || isEdit) &&
      <DRSUploadAttachmentTrigger id={`packFormId-${packFormId}`} onSelect={handleFileSelect} />
    }
    {attachments.map((attachment: Attachment) => {
      return <AttachmentFieldWrapper key={attachment.id}>
        <AttachmentField
          attachment={attachment}
          removable={roleId === attachment.roleId}
          onRemove={onRemove}
        />
      </AttachmentFieldWrapper>
    })}
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
`
const AttachmentFieldWrapper = styled.div`
  margin: 0 16px;
`

export default PAEAttachmentsCell
