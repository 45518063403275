import React from 'react'
import styled from 'styled-components'
import { FieldName } from './Field'
import BoralSwitch from '../../../../../components/BoralSwitch'

const FieldSwitch = ({
  field,
  onChange,
}: DRS.Comp) => {
  const { checkedPath } = field.typeMeta!
  let finalLabel = field.name
  // if (optional) {
  //   finalLabel = `${field.name} (Opt)`
  // }
  let findalChecked = field.data
  if (checkedPath) {
    if (typeof checkedPath === 'function') {
      findalChecked = checkedPath(field.data)
    }
  }
  return <Wrapper>
    <FieldName>
      {finalLabel}
    </FieldName>
    <BoralSwitch
      checked={findalChecked}
      onChange={e => onChange(e.target.checked)}
    />
  </Wrapper>
}

const Wrapper = styled.div``
const Content = styled.div``

export default FieldSwitch