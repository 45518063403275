import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import CalendarToolbar from '../Toolbar/Toolbar'
import { useCrewCalender } from '../StoreCrewCalender'
import CrewRow from '../comps/CrewRow'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
interface Props {
    shiftNoteStyle:string,
    noteClickEvent():void,
    isDrsDuplicate(opt: boolean,obj: any):void
    opt: boolean,
    duplicateData: any
}
const WeekView = ({
  shiftNoteStyle,
  noteClickEvent,
  isDrsDuplicate,
  opt,duplicateData
}:Props) => {
  const { shiftsData } = useCrewCalender()
  const shiftCollapsedLocal = localStorage.getItem('shiftCollapsed') ? localStorage.getItem('shiftCollapsed') : 'yes';
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const [shiftCollapsed, setShiftCollapsed] = useState<boolean>(shiftCollapsedLocal == 'yes' ? true : false);
  const [shiftNewStyle, setShiftNewStyle] = useState<string>('shiftNoteCollapseIn')
  const [rotation, setRotation] = useState<string>('0deg')
  const [topDiv, setTopDiv] = useState<string>('96px')
  const [topDivShift, setTopDivShift] = useState<string>('96px')
  const handleToggle = () => {
    if (shiftNewStyle == 'shiftNoteCollapseIn') {
     setShiftNewStyle('shiftNoteCollapseOut');
     setRotation('270deg');
     setTopDiv('98px');  
     return; 
    } else {
      setShiftNewStyle('shiftNoteCollapseIn');
      setRotation('0deg'); 
      setTopDiv('96px');
      return;
    }
  }
  const handleShiftToggle = () => {
    if(shiftCollapsed) {
      setShiftCollapsed(false);
      localStorage.setItem('shiftCollapsed','no');
      return;
    }else{
      setShiftCollapsed(true);
      localStorage.setItem('shiftCollapsed','yes');
      return;
    }
  }
  useEffect(() => {
    if(shiftNoteStyle == 'shfitNoteClass') {
      setTopDivShift('57px');
      setCollapsed(true);
    }else{
      setTopDivShift('96px');
      setCollapsed(false);
    }
    // console.log(shiftNoteStyle);
  }, [shiftNoteStyle])

  return <Wrapper>
      {/* <CollapsibleDiv id="CollapsibleDivNote"  onClick={handleToggle} style={{fontSize:'14px',borderRadius:'0px',cursor:'pointer',display:'inline-flex',top:topDiv}}>
        <ArrowDropDownCircleIcon style={{transform:"rotate("+rotation+")"}} className={shiftNoteStyle} />
        <CollapsibleDivTxt id="CollapsibleDivTxt"> </CollapsibleDivTxt>
        </CollapsibleDiv> */}

        
    <CalendarToolbar hidden={collapsed} shiftNoteStyle={shiftNoteStyle} handleShiftToggle={handleShiftToggle} shiftCollapsed={shiftCollapsed} />
  
   
    {/* should be CrewRowInCrewView */}
    {shiftsData.map((crew: any, index: number) => {
      return <CrewRow
          drsOpt={opt}
        key={crew.name+'_'+index}
        data={{
          ...crew,
          crewIndex: index,
        }}
        duplicateData={duplicateData}
        shiftCollapsed={shiftCollapsed}
        onDuplicateDrsOpt={(copyData,obj) => isDrsDuplicate(copyData,obj)}
      />
    })
    }
  </Wrapper>
}

const Wrapper = styled.div`
  position: relative;
  z-index:399;
`

const CollapsibleDiv = styled.div`
height: 32px;
width:40px;
z-index: 555;
position:relative;
left:0;
display:inline-flex;
font-size:12px;
border-radius:0;
`
const CollapsibleDivTxt = styled.div`
  margin-top: 5px;`
  




export default observer(WeekView)