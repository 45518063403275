import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import EditableCheckboxGroup from './f-EditableCheckboxGroup'


function FieldSelectTrucks ({
  field,
  onChange,
}: DRS.Comp) {
  const { name, data, typeMeta } = field
  const { options } = typeMeta!
  const checkboxGroupSelectedArray = data.filter((item: any) => item.plan).map((item: any) => ({ id: item.truck.id }))
  const handleChange = (idArray: any[]) => {
    onChange(data.map((item: any) => {
      return {
        ...item,
        plan: idArray.some(id => id.id === item.truck.id),
      }
    }))
  }
  // console.log(checkboxGroupSelectedArray, options)
  return (
    <EditableCheckboxGroup
      field={{
        path: '', // we don't have a path here?
        name,
        data: checkboxGroupSelectedArray,
        typeMeta: {
          type: 'editable-checkBoxGroup',
          options,
          direction: 'horizontal',
        }
      }}
      onChange={handleChange}
      />

  )
}

export default observer(FieldSelectTrucks)