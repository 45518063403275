import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import SRRMaterialSpraySealOtherFields from '@src/modules/SiteResoureRequirements/parts/4-Material/parts/SsOther/fields'

interface Props {

}

const CompPreviewMaterialSpraySealOther = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  if (srr.material.spraySeal.otherProducts === null) {
    return null
  }
  return <Wrapper>
    <CompPreviewSection 
      name={`Other Material (Spray Seal)`}
    >
      <CompFlatFields
        value={srr.material.spraySeal.otherProducts}
        fields={SRRMaterialSpraySealOtherFields}
      />
    </CompPreviewSection>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompPreviewMaterialSpraySealOther