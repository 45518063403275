import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSMaterialSpraySealOther } from '@src/types/drs/material/MaterialSpraySealOther'

export interface DRSMaterialSpraySealOtherRowDisplay extends DRSMaterialSpraySealOther {

}

const DRSMaterialSpraySealOtherFields: FormTableField<DRSMaterialSpraySealOtherRowDisplay>[] = [
  {
    key: 'catEyeCovers',
    type: FormTableFieldType.Number,
    label: 'Cat eye covers',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'grit',
    type: FormTableFieldType.Number,
    label: 'Grit (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'depot',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot',
    format: (item: any) => {
      if (item) {
        return item.name
      }
    }
  },
  {
    key: 'hateliteBitac',
    type: FormTableFieldType.Number,
    label: 'Hatelite/Bitac (meters)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'sticksNStomps',
    type: FormTableFieldType.Number,
    label: 'Sticks & Stomps',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'geoFabric',
    type: FormTableFieldType.Number,
    label: 'Geo fabric (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'emulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
]

export default DRSMaterialSpraySealOtherFields