import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import { SupplierAgreementActivity } from '@src/types/SupplierAgreement'
import { PAETableRowType } from '@src/components/Form/PAETable'
import { SubcontractorTransportProfiling } from '@src/types/drs/subcontractor/SubcontractorTransportProfiling'

interface DRSSubcontractorTransportProfilingDisplay extends SubcontractorTransportProfiling {
  activity: SupplierAgreementActivity,
}

export interface DRSSubcontractorTransportProfilingRow extends DRSSubcontractorTransportProfilingDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const DRSSubcontractorTransportProfilingsFields: FormTableField<DRSSubcontractorTransportProfilingDisplay>[] = [
  {
    id: 'supplier',
    key: 'supplier',
    type: FormTableFieldType.Autocomplete,
    label: 'Supplier',
    format: (item: any) => {
      if (item) {
        return item.name
      }
    },
  },
  {
    id: 'supplier-agreement',
    key: 'supplierAgreement',
    type: FormTableFieldType.Select,
    label: 'Supplier Agreement',
    format: (item: any, row: SubcontractorTransportProfiling) => {
      if (item) {
        return item.description
      }
    }
  },
  {
    id: 'supplier-agreement-item',
    key: 'activity',
    type: FormTableFieldType.Select,
    label: 'Activity',
    format: (item: any, row: SubcontractorTransportProfiling) => {
      if (row.supplierAgreement) {
        return row.supplierAgreement.itemDescription
      }
    }
  },
  {
    key: 'weight',
    type: FormTableFieldType.Number,
    label: 'Weight',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time',
    format: formatDateStringHHmm,
  },
  {
    key: 'endTime',
    type: FormTableFieldType.Time,
    label: 'End Time',
    format: formatDateStringHHmm,
  },
  {
    key: 'quantity',
    type: FormTableFieldType.Number,
    label: 'Quantity/Hours',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'docketNumber',
    type: FormTableFieldType.Text,
    label: 'Docket Number',
  },
  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comment',
  },
]

export default DRSSubcontractorTransportProfilingsFields