export enum DRSStatus {
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
  SUBMITTED_FORMAN = 'SUBMITTED-FORMAN',
  INPROGRESS = 'INPROGRESS',
  SUBMITTED_PM = 'SUBMITTED-PM',
  INREVIEW = 'INREVIEW',
  APPROVED_PM = 'APPROVED-PM',
  COMPLETED = 'COMPLETED',
}

const Mapping: any = {
  'DRAFT': 'Draft',
  'CANCELLED': 'Shift Cancelled',
  'SUBMITTED-FORMAN': 'Sent to Crew',
  'INPROGRESS': 'Shift in Progress',
  'SUBMITTED-PM': 'Shift Completed',
  'INREVIEW': 'Shift Review in Progress',
  'APPROVED-PM': 'Review Completed',
  'COMPLETED': 'DRS Completed',
}
export function formatDRSStatus (status: DRSStatus) {
  if (status) {
    return Mapping[status]
  }
}