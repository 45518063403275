export enum HSEStatus {
  New = 1,
  Completed = 2,
  Updated = 3,
}

export function formatHSEStatus(status: HSEStatus) {
  switch (status) {
    case HSEStatus.New:
      return 'New'
    case HSEStatus.Completed:
      return 'Completed'
    case HSEStatus.Updated:
      return 'Updated'
  }
}


// [
//   { "id": 1, "description": "New" },
//   { "id": 2, "description": "Completed" },
//   { "id": 3, "description": "Updated" }
// ]
