import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useVMP } from '../../useVMP'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import RowGrid from '@src/components/FormRow/RowGrid'
import FieldPair from '@src/components/Form/FieldPair'
import { formatProjectTypeId } from '@src/types/ProjectTypeId'

interface Props {

}

const CompVMPJob = (props: Props) => {
  const {

  } = props
  const [ vmp ] = useVMP()
  if (vmp === null) {
    return null
  }
  const { createdBy, dateCreated, updatedBy, dateUpdated } = vmp.job.vmpInformation
  const basicInfo = [
    {
      label: `Created By`,
      value: formatProfileOrNullFirstLastName(createdBy),
    },
    {
      label: `Creation date`,
      value: formatDateStringOrNullDDMMYYYY(dateCreated),
    },
    {
      label: `Updated by`,
      value: formatProfileOrNullFirstLastName(updatedBy),
    },
    {
      label: `Last updated on`,
      value: formatDateStringOrNullDDMMYYYY(dateUpdated),
    },
  ]
 
  const { date, projectType, foremen, supervisors,planKmPlantToDepot } = vmp.job.shiftDetails
  const shiftInfo = [
    {
      label: `Shift Date`,
      value: formatDateStringOrNullDDMMYYYY(date),
    },
    {
      label: `Shift Type`,
      value: formatProjectTypeId(projectType.id),
    },
    {
      label: `Supervisor Name`,
      value: supervisors.map(profile => formatProfileOrNullFirstLastName(profile)).join(', '),
    },
    {
      label: `Supervisor Contact`,
      value: supervisors.map(profile => profile.mobileNumber).join(', '),
    },
    {
      label: `Foreman Name`,
      value: foremen.map(profile => formatProfileOrNullFirstLastName(profile)).join(', '),
    },
    {
      label: `Foreman Contact`,
      value: foremen.map(profile => profile.mobileNumber).join(', '),
    },
  ]
  const { id: projectId, projectInternalId, projectName, customer, customerRepresentativeName, customerRepresentativeContactNumber} = vmp.job.projectDetails
  const projectInfo = [
    {
      label: `Project Number`,
      value: projectId || '',
    },
    {
      label: `Project Name`,
      value: projectName || '',
    },
    {
      label: `Customer`,
      value: customer ? customer.name : '',
    },
    {
      label: `Customer Representative`,
      value: customerRepresentativeName || '',
    },
    {
      label: `Contact Number`,
      value: customerRepresentativeContactNumber || '',
    },
    {
      label: `KMs`,
      value: planKmPlantToDepot ? planKmPlantToDepot : '',
    },
  ]

  
  return <Wrapper>
    <FormSectionTitle>JOB DETAILS</FormSectionTitle>
    <FormPanel title={`Vehicle Movement Plan Information`}>
      <RowGrid>
        { basicInfo.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel title={`Project Details`}>
      <RowGrid>
        { projectInfo.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel title={`Shift Details`}>
      <RowGrid>
        { shiftInfo.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompVMPJob
