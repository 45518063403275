import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import TabSectionLabour from './1-TabSectionLabour'
import TabSectionMaterial from './2-TabSectionMaterial'
import TabSectionTransport from './3-TabSectionTransport'
import TabSectionMobileAssets from './4-TabSectionMobileAssets'
import _get from 'lodash/get'
import { useDRS } from '@src/modules/DRS/useDRS'
import { DRS } from '@src/types/drs/DRS'
import TabSectionSubcontractor from './5-TabSectionSubcontractor'
import { ProjectTypeId } from '@src/types/ProjectTypeId'

const SpraySealTab = () => {
  const { drs } = useDRS()
  if (drs === null) {
    return null
  }
  return <Wrapper>
    <TabSectionLabour
      isLast={false}
    />

    <TabSectionMaterial
      type='SPRAYSEAL'
      isLast={false}
      data={getMaterialSsProducts(drs)}
    />

    {/* <TabSectionTransport
      type='SPRAYSEAL'
      isLast={false}
      data={drs}
    /> */}

    <TabSectionMobileAssets
      type='SPRAYSEAL'
      isLast={false}
      data={drs}
    />
    
     <TabSectionSubcontractor drs={drs} projectTypeId={ProjectTypeId.SpraySeal} />
  </Wrapper>
}
const Wrapper = styled.div``
const getMaterialSsProducts = (drs: DRS) => {
  return  _get(drs, 'material.spraySeal.detail')
}

export default observer(SpraySealTab)