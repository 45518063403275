import React from 'react'
import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

interface RadioGroupOption {
  value: string,
  label: string,
}

interface Props {
  label: string,
  value: string,
  disabled?: boolean,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
  options: RadioGroupOption[],
}

const FormModalRadioGroup = (props: Props) => {
  const {
    label,
    value,
    disabled = false,
    onChange,
    options,
  } = props
  return <FormControl component='fieldset'>
    <FormLabel component='legend' disabled={disabled}>{label}</FormLabel>
    <RadioGroup value={value} onChange={onChange}>
      {options.map((option) => {
        return <FormControlLabel key={option.value} value={option.value} disabled={disabled} control={<Radio />} label={option.label} />
      })}
    </RadioGroup>
  </FormControl>
}

const Wrapper = styled.div`

`

export default FormModalRadioGroup