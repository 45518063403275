

import { ADIDNameToValueLabel } from '@src/api-adaptors/id-name-to-value-label'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { getAsphProcess, getExistingSurface } from '@src/modules/DRS/legacy/Edit/Contants'
import { SectionSprayProduct } from '@src/types/SectionSprayProduct'
import { SectionSprayItem } from '@src/types/drs/material/SectionSprayItem'
export interface SectionSprayItemRowDisplay extends SectionSprayItem{

}

const SectionSprayItemFields: FormTableField<SectionSprayItemRowDisplay>[] = [
  
  {
    key: 'sprayProcess',
    type: FormTableFieldType.Select,
    label: 'Process',
    options: getAsphProcess().map(item => ADIDNameToValueLabel(item)),
    format: (cellData: SectionSprayProduct['sprayProcess']) => {
      if (cellData) {
        console.log(`cellData`);
        console.log(JSON.stringify(cellData));
        console.log(`cellData`);
        return cellData
      }
    },
  },
  

  {
    key: 'existingSurface',
    type: FormTableFieldType.Select,

    label: 'Surface',
    options: getExistingSurface().map(item => ADIDNameToValueLabel(item)),
    format: (cellData: SectionSprayProduct['existingSurface']) => {
      if (cellData) {
        console.log(`cellData`);
        console.log(JSON.stringify(cellData));
        console.log(`cellData`);
        return cellData
      }
    },
   
  },
  {
    key: 'lotNoClauseNo',
    type: FormTableFieldType.Text,
    label: 'Lot No/Clause No',
  },
 
 
]

export default SectionSprayItemFields