import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import HereMapSnapshot from './HereMapSnapshot'
import CompDrawingTool from '@src/modules/VehicleMovementPlan/parts/drawing-tools/DrawingTool'

interface Props {
  open: boolean,
  onClose(): void,
  isSaving: boolean,
  onExportPngData(pngData: string): void,

}

const CompDrawingToolDialog = (props: Props) => {
  const {
    open,
    onClose,
    isSaving,
    onExportPngData,

  } = props
  const canvasRef = useRef<any>()
  const [status, setStatus] = useState<'heremap' | 'captured'>('heremap')
  const [bgDataURLString, setBgDataURLString] = useState<string>('')
  const showingHeremap = status === 'heremap'
  const mapCaptured = status === 'captured'
  const [exportTrigger, setExportTrigger] = useState(0)
  const [mapType, setMapType] = useState<string>('map')
  const [zoom, setZoom] = useState<number>(15)
  const [center, setCenter] = useState<any>({ lng: 133.28, lat: -26.439, alt: 0 })
  //const center: any = { lng: 133.28, lat: -26.439 }
  const handleCaptured = (dataURLString: string) => {
    setBgDataURLString(dataURLString)
    setStatus('captured')
  }

  const handleToggle = () => {
    if (mapType === 'map')
      setMapType('satellite')
    else
      setMapType('map')
  }
  const handleZoom = (newZoom: number) => {
    if (zoom !== newZoom) {
      setZoom(newZoom)
    }
  }
  const handleCenterChange = (newCenter: any) => {
    if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
      setCenter(newCenter)
    }
  }
  const handleExport = async (svgData: string) => {
    if (canvasRef && canvasRef.current) {
      // console.log(svgData, bgDataURLString)
      const canvas = canvasRef.current as HTMLCanvasElement
      const context = canvas.getContext('2d')
      if (context === null) {
        return
      }
      const width = 700
      const height = 410
      canvas.width = width
      canvas.height = height
      let mapImage = new Image()
      let svgImage = new Image()
      mapImage.onload = () => {
        proceed()
      }
      mapImage.src = bgDataURLString
      svgImage.onload = function () {
        proceed()
      }
      svgImage.src = svgData

      let readyCount = 0
      const proceed = async () => {
        readyCount++
        if (readyCount === 2) {
          context.clearRect(0, 0, width, height)
          context.drawImage(mapImage, 0, 0, width, height)
          context.drawImage(svgImage, 0, 0, width, height)
          const pngData = canvas.toDataURL('image/' + 'png')
          onExportPngData(pngData)
        }
      }
    }
  }
  return <Wrapper>
    <Dialog
      // fullWidth
      maxWidth='xl'
      open={open}
      onClose={() => {
        if (showingHeremap) {
          onClose()
        }
      }}
    >
      {!mapCaptured &&
        <HereMapSnapshot
          mapType={mapType}
          zoom={zoom}
          center={center}
          onZoom={handleZoom}
          onCenterChange={handleCenterChange}
          onToggle={handleToggle}
          onCaptured={handleCaptured}
        />
      }
      {mapCaptured &&
        <CompDrawingTool
          bgDataURLString={bgDataURLString}
          exportTrigger={exportTrigger}
          onExport={handleExport}
        />
      }
      {!showingHeremap && <DialogActions>
        {mapCaptured &&
          <React.Fragment>
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button
              color='default'
              variant='contained'
              onClick={() => {
                setStatus('heremap')
              }}>
              Back to Map
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={isSaving}
              onClick={() => setExportTrigger(exportTrigger + 1)}
            >
              {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
              {!isSaving && 'SAVE SITEMAP'}
            </Button>
          </React.Fragment>
        }
      </DialogActions>
      }
    </Dialog>
    <CanvasPanel ref={canvasRef as any}>

    </CanvasPanel>
  </Wrapper>
}

const Wrapper = styled.div`
`
const CanvasPanel = styled.canvas`
  display: none;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
`

export default CompDrawingToolDialog