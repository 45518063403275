import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormTable from '@src/components/Form/Table'
import Modal from '../../../../../components/FormModal/Modal'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { Rework } from '@src/types/Rework'
import SRRReworkFields from '../fields'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { apiSrrReworkCreate } from '@src/apis/srr-rework-create'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { JobType } from '@src/types/JobType'
import { apiSrrReworkRemove } from '@src/apis/srr-rework-remove'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrReworkUpdate } from '@src/apis/srr-rework-update'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const CompASRework = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const [ srr, srrLoading, refresh ] = useSRR()
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<Rework>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.rework.asphalt
  const hanldeEditRow = (row: Rework) => {
    openAndSetData(row)
  }
  const hanldeRemoveRow = async (row: Rework) => {
    try {
      await confirm({
        title: `Confirm deletion of Rework`,
        message: `This will delete the Rework from this Shift Resource requirement AND Pre-DRS`,
      })
      confrimRemoveRow()
    } catch (e) {

    }
  }
  const confrimRemoveRow = async () => {
    try {
      const result = await apiSrrReworkRemove({
        srrId: id,
        serialId,
        jobType: JobType.Asphalt,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  const handleSave = async (form: Partial<Rework>) => {
    console.log(form)
    // no data, that's creating
    if (data === null) { 
      try {
        const result = await apiSrrReworkCreate({
          srrId: id,
          serialId,
          jobType: JobType.Asphalt,
          ...form,
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
        try {
          const result = await apiSrrReworkUpdate({
            srrId: id,
            serialId,
            jobType: JobType.Asphalt,
            ...form,
          })
          alert({
            message: `Updated.`
          })
          refresh()
          closeModal()
        } catch (e) {
          alert({
            type: 'error',
            message: `Error.`
          })
        }
    }
  }
  return <Wrapper>
    <FormPanel title={`Rework Details`}>
      <FormTable<Rework>
        fields={SRRReworkFields}
        data={data === null ? [] : [data]}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      { canEditSubmitSRR && data === null &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Rework Details
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>


    { open && 
      <Modal<Rework>
        maxWidth={'xs'}
        open={open}
        title='Rework'
        data={{
          rowData: modalData,
          fields: SRRReworkFields,
          columns: [
            {
              title: 'Rework Information',
              fieldKeys: [
                'reworkPercentage', 
                'reworkTonnes', 
                'hours', 
                'reworkEmulsion',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompASRework
