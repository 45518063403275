import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { observer } from "mobx-react-lite";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import _merge from "lodash/merge";
import { WeekView } from "./WeekView";
import { useCrewCalender } from "./StoreCrewCalender";
import usePermission from "@src/utils/hooks/usePermission";
import moment from "moment";
import _assign from "lodash/assign";
import _map from "lodash/map";
import _flattenDeep from "lodash/flattenDeep";
import { useConfirm } from "@src/components/GlobalConfirmDialog";
import { useAppStore } from "@src/AppStore";
import { useCrewFilter } from "./FilterInHeader/CrewFilterStore";
import { apiCrewList, apiCrewListOptions } from "@src/apis/crew/crew-list";
import { apiShiftList } from "@src/apis/shift-list";
import Typography from "@material-ui/core/Typography";
import { CustomDragLayer } from "./CustomDragLayer";
import { useSocketClient } from "@src/utils/hooks/StoreSocketClient";
import { getCookieIdToken } from "@src/utils/cookies";
import { apiCrewtNoteSearch } from "@src/apis/crew-note-search";
import { apiLeavetNoteSearch } from "@src/apis/leave-note-search";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import CompThemeButton from "@src/components/Buttons/CompThemeButton";
import CompDeleteButton from "@src/components/Buttons/CompDeleteButton";
import { apiShiftDRSDuplicate } from "@src/apis/shift-drs-duplicate";
import { apiUserViewsLists, addDefaultView } from "@src/apis/user-view-list";
import { ProjectTypeId } from "@src/types/ProjectTypeId";
interface Props {
  shiftNoteStyle: string;
  noteClickEvent(): void;
}
const CrewCalendarView = ({ shiftNoteStyle, noteClickEvent }: Props) => {
  const {
    setToday,
    batchStartDay,
    data,
    setAllUserShiftCardViews,
    setCurrentShiftCardViewFields,
    setCurrentShiftCardViewId,
    crews,
    storeCrewData,
    setCrews,
    setDrss,
    setCrewNotes,
    setLeaveNotes,
    gridNumber,
    refreshHook,
    isCalendarLoading,
    setIsCalendarLoading,
    setCalendarControlsDisabled,
    goRefresh,
    setStoreDepot,
    drss,
    webHookTime,
    setWebHookTime,
    setWebhookAnimationInterval,
    webhookAnimationInterval
  } = useCrewCalender();
  const { selectedDepot } = useCrewFilter();
  const {
    socketRefreshHook,
    setShiftIds,
    setWatch,
    setListenBy,
    doConnectWebSocket,
  } = useSocketClient();
  var intervalId: any;
  let counter = -1;
  const { canConnecttoWebSocket } = usePermission();
  const {
    depotName,
    depotId,
    regionId,
    stateId,
    appStoreRefreshHook,
    serialId,
    isUserSettingsOpen
  } = useAppStore();
  const { confirm } = useConfirm();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [opt, setOpt] = useState<boolean>(false);
  const [interValAnimate, setIntervalAnimate] = useState<any>(null);
  const [duplicateObj, setDuplicateObj] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNeedsToReload, setIsNeedsToReload] = useState<boolean>(false);
  let cookieIdToken = getCookieIdToken();
  const { alert, alertDone } = useSnackbar();
  /*
  DO NOT DELETE used for WebHook */

  useEffect(() => {
    const drss = _map(data, "drss");
    const shifts = _flattenDeep(drss);
    const shiftIds = _map(shifts, "id");
    setShiftIds(shiftIds);
    fetchUserViews();
    const newCrewBoral = localStorage.getItem('newCrewBoral');
    if (newCrewBoral) {
      const newCrewBoralData = JSON.parse(newCrewBoral);
      if (newCrewBoralData) {
        callNewCrewDialog(newCrewBoralData);
      }
    }
  }, [data]);
  useEffect(() => {
    if (isUserSettingsOpen == true) {
      const newCrewBoral = localStorage.getItem('newCrewBoral');
      if (newCrewBoral) {
        const newCrewBoralData = JSON.parse(newCrewBoral);
        if (newCrewBoralData) {
          localStorage.removeItem('newCrewBoral');
          document.getElementById('manage-crew-order').click();
        }
      }
    }
  }, [isUserSettingsOpen])
  useEffect(() => {
    // if not the first time refresh hook has changed
    if (socketRefreshHook) {
      if (webHookTime != -1) {
        console.log(webhookAnimationInterval,'dd');
        // var intData = webHookTime * 30 * 1000;
        // if not already loading
       // if (!isLoading) {

          setIsNeedsToReload(true);
          // var intVar = setInterval(function () {
          //   console.log(intVar);
          //goRefresh();
          //   clearInterval(intVar);
          // }, (webHookTime))
      //  }
      }

    } else {
      // console.log(`CrewCalendarView useEffect FIRST LOAD socketRefreshHook=${socketRefreshHook}`)
      //  if (canConnecttoWebSocket) {
      doConnectWebSocket();
      //}
    }
  }, [socketRefreshHook]);

  function TimerWebhook(isClear) {
    if(isClear){
      console.log(isClear);
     
      //clearInterval(intervalId);
      setIntervalAnimate(clearInterval(interValAnimate));
      isClear = null;
    }
    //console.log('1111');
    //intervalId = setInterval(updateCounter, 1000); // 1000 milliseconds = 1 second
    setIntervalAnimate(setInterval(updateCounter, 1000));
}
function updateCounter() {
 counter--;
  
 //console.log('counter--',isNeedsToReload,counter);
  setWebhookAnimationInterval(counter);
  if(isNeedsToReload && counter == webhookAnimationInterval -1) {
    goRefresh();
    setIsNeedsToReload(false);
  }
  if (counter <= 0) {
    console.log('counter--');
    
    setWebhookAnimationInterval(counter);
    setWebHookTime(webHookTime - 1);
    setWebHookTime(webHookTime + 1);
    
    // setIntervalAnimate(clearInterval(interValAnimate));
    //   clearInterval(interValAnimate); // Clear the previous interval
    //   counter  = webHookTime / 1000 // Reset the counter
       
    //   TimerWebhook(true); // Start a new interval
  }
}
  useEffect(() => {
    if (webHookTime > 0) {
      setIntervalAnimate(clearInterval(interValAnimate));
    //   setInterval(function() {
    //     clearInterval(intervalId);
    //     console.log('333',counter);
    //     setWebHookTime(webHookTime);
    // }, webHookTime);
      // counter = 0;
      // clearInterval(intervalId);
      // updateCounter();
      counter  = Math.round(webHookTime / 1000);
      setWebhookAnimationInterval(counter);
     
   
   
      // intVarAnimation = setInterval(function () {
        TimerWebhook(true);
      //   setWebhookAnimationInterval(intDesc);
      //   console.log(intInc, intervalAnimationBreak);
      //   if (intInc === intervalAnimationBreak) {
      //     intInc = 1;
      //     var intervalAnimationBreak = webHookTime / 1000;
      //     intDesc = intervalAnimationBreak - 1;
      //     setWebhookAnimationInterval(intDesc);
      //     clearInterval(intVarAnimation);
      //     setWebhookAnimationInterval(-1);
      //     intVarAnimation = setInterval(this, 1000);

       
      //   }
      //   // console.log(intInc);
      //   // clearInterval(intVar);
      //   intInc++;
      //   intDesc--;
      // }, 1000);
    }

  }, [webHookTime]);
  useEffect(() => {
    if (appStoreRefreshHook) {
      //console.log(`CrewCalendarView useEffect regionId=${regionId} depotId=${depotId} depotName=${depotName}`)
      //setSelectedDepot({ value: depotId, label: depotName })
      //setSelectedItem({ value: depotId, label: depotName })
    }
  }, [appStoreRefreshHook]);

  const [prevGridNumber, setPrevGridNumber] = useState<number>(gridNumber);
  const [prevBatchStartDay, setPrevBatchStartDay] =
    useState<any>(batchStartDay);

  const fetchDrss = async () => {
    try {
      setIsLoading(true);
      setIsCalendarLoading(true);
      setCalendarControlsDisabled(true);
      const batchStartDayCloned = batchStartDay.clone();
      const batchEndDayCloned = batchStartDay.clone();

      // re-enable controls if taking too long
      setTimeout(function () {
        setIsCalendarLoading(false);
        setCalendarControlsDisabled(false);
      }, 15000);
      let dayStart = gridNumber;
      let dayEnd = gridNumber;
      if (gridNumber == 8) {
        dayStart = gridNumber - 8;
        dayEnd = gridNumber - 1;
      }
      if (gridNumber == 15) {
        dayStart = gridNumber - 12;
      }
      if (gridNumber == 22) {
        dayStart = gridNumber - 20;
      }
      if (gridNumber == 29) {
        dayStart = gridNumber - 26;
      }
      // console.log(gridNumber);
      // MODERATE SHIFT CACHEING
      var startCalendarDate = batchStartDayCloned
        .subtract(dayStart, "days")
        .startOf("day")
        .toISOString();
      var endCalendarDate = batchEndDayCloned.add(dayEnd, "day").endOf("day").toISOString();
      var startCalendarDateArr = startCalendarDate.split('T');
      startCalendarDate = startCalendarDateArr[0] + 'T' + '00:00:00.000Z';
      var endCalendarDateArr = endCalendarDate.split('T');
      endCalendarDate = endCalendarDateArr[0] + 'T' + '23:59:00.000Z';
      let options = {
        // start: batchStartDayCloned.subtract(gridNumber + 7, 'days').startOf('day').toISOString(),
        // end: batchEndDayCloned.add(gridNumber * 2, 'day').endOf('day').toISOString(),
        start: startCalendarDate,
        end: endCalendarDate,
        region: regionId,
      };

      if (selectedDepot.value !== 'all') {
        options['depot'] = selectedDepot.value;
      }
      console.log(`options`, dayEnd);
      // console.log(startCalendarDateArr);
      console.log(options.start);
      console.log(options.end);
      // console.log(`options`);
      //  console.log(`CrewCalendarView fetchDrss() START options=${JSON.stringify(options, null, 2)}`)
      const result = await apiShiftList(options);
      if (result.data && result.data.shifts && result.data.shifts.length) {
        setIsCalendarLoading(false);
        setCalendarControlsDisabled(false);
        let newShifts: any[] = _map(result.data.shifts, function (shift) {
          if (!shift.products.asphalt && !shift.products.spraySeal) {
            return _merge({}, shift, {
              products: { asphalt: [], spraySeal: [] },
            });
          } else if (!shift.products.asphalt) {
            return _merge({}, shift, { products: { asphalt: [] } });
          } else if (!shift.products.spraySeal) {
            return _merge({}, shift, { products: { spraySeal: [] } });
          } else {
            return shift;
          }
        });

        setDrss(newShifts);
        //setDrss(result.data.shifts)
        if (isFirstLoad) {
          setIsFirstLoad(false);
        }
        setIsLoading(false);
      } else {
        console.log(`CrewCalendarView fetchDrss() apiShiftList() empty`);
        setIsCalendarLoading(false);
        setCalendarControlsDisabled(false);
        setDrss([]);
        alert({ type: "error", message: "No records found" });
      }
    } catch (e) {
      console.log(`e--->FetchDRSS`);
      console.log(e);
      console.log(`e--->FetchDRSS`);
    }

  };
  async function getCrewNotes(crews: any, batchEndDayCloned: any) {
    // console.log('getCrewNotes');
    // console.log(batchEndDayCloned);
    // console.log(`getCrewNotes`);
    let crewIDs: any[] = [];
    crews.map(async (crewData: any) => {
      crewIDs.push(crewData.id);
    });
    const crewIDsForNote: any = crewIDs.sort().filter(function (el, i, a) {
      return i === a.indexOf(el);
    });
    let crewNotesAll: any = [];
    // crewIDsForNote.map(async (crewID: number) => {
    //   var crewNotesData = await apiCrewtNoteSearch({ crewID: crewID, start: batchStartDay.format('YYYY-MM-DD'), end: batchEndDayCloned.add(gridNumber * 2, 'day').endOf('day').toISOString() });
    //   if (crewNotesData.data.crewNote.length > 0)
    //     crewNotesAll[crewID] = crewNotesData.data.crewNote;
    // });
    const crewIDsForNoteStr = crewIDs.toString();
    var crewNotesData = await apiCrewtNoteSearch({
      crewID: crewIDsForNoteStr,
      start: batchStartDay.format("YYYY-MM-DD"),
      end: batchEndDayCloned
        .add(gridNumber * 2, "day")
        .endOf("day")
        .toISOString(),
    });
    if (crewNotesData.data.crewNote.length > 0) {
      // crewNotesAll = assignNotesToCrew(
      //   crewIDsForNote,
      //   crewNotesData.data.crewNote
      // );
      return crewNotesData.data.crewNote;
    } else {
      return crewNotesAll;
    }
    return crewNotesAll;
  }
  async function duplicateDRS() {
    const selectedShiftElements: any =
      document.getElementsByClassName("activeShift");
    if (selectedShiftElements.length == 0) {
      alert({ type: "error", message: "Please select target shift" });
      return false;
    }
    const TargetShiftIDs: any = [];
    var isNotValidShiftIDs: any = [];

    var isNotValidShiftIDsTypes: any = [];


    for (var i = 0; i < selectedShiftElements.length; i++)
      TargetShiftIDs.push(selectedShiftElements[i].id.replace("shift_", ""));


    for (var i = 0; i < drss.length; i++) {
      for (var j = 0; j < TargetShiftIDs.length; j++) {
        console.log(TargetShiftIDs[j] + '---' + drss[i].id);
        if ((drss[i].status.id == 'SUBMITTED-PM' || drss[i].status.id == 'COMPLETED') && drss[i].id == TargetShiftIDs[j]) {
          isNotValidShiftIDs.push(drss[i].id);
        }
        console.log(drss[i].drsProjectType.id);

        if ((duplicateObj.shift.drsProjectType.id == ProjectTypeId.Asphalt && drss[i].id == TargetShiftIDs[j] && drss[i].drsProjectType.id == ProjectTypeId.SpraySeal)) {
          isNotValidShiftIDsTypes.push(drss[i].id);
        }
        if ((duplicateObj.shift.drsProjectType.id == ProjectTypeId.SpraySeal && drss[i].id == TargetShiftIDs[j] && drss[i].drsProjectType.id == ProjectTypeId.Asphalt)) {
          isNotValidShiftIDsTypes.push(drss[i].id);
        }
		/*
        if ((duplicateObj.shift.drsProjectType.id == ProjectTypeId.Asphalt && drss[i].id == TargetShiftIDs[j] && drss[i].drsProjectType.id == ProjectTypeId.AsphaltAndSpraySeal)) {
          isNotValidShiftIDsTypes.push(drss[i].id);
        }
        if ((duplicateObj.shift.drsProjectType.id == ProjectTypeId.SpraySeal && drss[i].id == TargetShiftIDs[j] && drss[i].drsProjectType.id == ProjectTypeId.AsphaltAndSpraySeal)) {
          isNotValidShiftIDsTypes.push(drss[i].id);
        }
		*/
        if ((duplicateObj.shift.drsProjectType.id == ProjectTypeId.AsphaltAndSpraySeal && drss[i].id == TargetShiftIDs[j] && (drss[i].drsProjectType.id == ProjectTypeId.Asphalt || drss[i].drsProjectType.id == ProjectTypeId.SpraySeal))) {
          isNotValidShiftIDsTypes.push(drss[i].id);
        }
      }
    }

    if (isNotValidShiftIDs.length > 0) {
      alert({ type: "error", message: "Unable to copy data to target shift as it has been completed" });
      return false;
    }
    if (isNotValidShiftIDsTypes.length > 0) {
      alert({ type: "error", message: "Unable to copy data to target shift as it is a different shift type from the source shift" });
      return false;
    }


    const duplicateOptions = {
      copyItems: duplicateObj.drsSection,
      TargetShiftIDs: TargetShiftIDs,
      serialId,
      drsProjectTypeId: duplicateObj.shift.drsProjectType.id,
      drsID: duplicateObj.shift.drsId,
      targetDate: duplicateObj.shift.date,
      SourceShiftID: duplicateObj.shift.id,
    };
    setIsLoading(true);
    apiShiftDRSDuplicate(duplicateOptions).then((response: any) => {
      setIsLoading(false);
      setOpt(false);
      goRefresh();
      setDuplicateObj({});
      removeSelectedShifts();
      alertDone();
    });
  }
  async function getLeaveNotes(crews: any, batchEndDayCloned: any) {
    let crewIDs: any[] = [];
    crews.map(async (crewData: any) => {
      crewIDs.push(crewData.id);
    });
    const crewIDsForNote: any = crewIDs.sort().filter(function (el, i, a) {
      return i === a.indexOf(el);
    });
    let leaveNotesAll: any = [];
    const crewIDsForNoteStr = crewIDs.toString();
    var leaveNotesData = await apiLeavetNoteSearch({
      crewID: crewIDsForNoteStr,
      start: batchStartDay.format("YYYY-MM-DD"),
      end: batchEndDayCloned
        .add(gridNumber * 2, "day")
        .endOf("day")
        .toISOString(),
    });
    if (leaveNotesData.data.leaveNote.length > 0) {
      leaveNotesAll = assignNotesToCrew(
        crewIDsForNote,
        leaveNotesData.data.leaveNote
      );
      return leaveNotesData.data.leaveNote;
    } else {
      return leaveNotesAll;
    }
  }
  async function fetchUserViews() {
    let userViewsData: any = await apiUserViewsLists({
      userViewSerialId: serialId,
    });

    let shiftcardviews = addDefaultView(serialId, userViewsData);
    if (shiftcardviews) {
      setAllUserShiftCardViews(shiftcardviews);
      setCurrentSelectedView(shiftcardviews);
    }
  }
  async function callNewCrewDialog(newCrewBoralData: any) {
    try {
      const confirmDiag = await confirm({
        title: 'New Crewd Added',
        message: 'There is new Crew Added ' + newCrewBoralData.crew.name + ' ,   do you want to Manage new Crew Order?',
      })
      if (confirmDiag) {
        document.getElementById('profile-main-btn-data').click();
        document.getElementById('user-settings-btn').click();

      } else {
        localStorage.removeItem('newCrewBoral');
      }

    } catch (e) {
      console.log(e);
      localStorage.removeItem('newCrewBoral');
      console.log('Cancel-------------------->>>>');
    }
  }
  function setCurrentSelectedView(viewData: any) {
    const finalView = [];
    for (var i = 0; i < viewData.length; i++) {
      if (viewData[i].isDefault === true) {
        setCurrentShiftCardViewFields(viewData[i].selectedItems);
        setCurrentShiftCardViewId(viewData[i].viewId);
        break;
      }
    }
    return finalView.toString();
  }
  /* fuction to add Crew note/Leave Note to their specific crew */
  function assignNotesToCrew(crewIDs: any, data: any) {
    const noteAll: any = {};
    crewIDs.map(async (crewID: number) => {
      noteAll[crewID] = [];
      for (var i = 0; i < data.length; i++) {
        // console.log(crewID+' == '+data[i].CrewID)
        if (crewID == data[i].CrewID) noteAll[crewID].push(data[i]);
      }
    });
    return noteAll;
  }
  const clickIntReset = async () => {
    console.log('CliclOm');

    clearInterval(intervalId);
    TimerWebhook(false);
    
  }
  const fetchCrews = async () => {
    try {
      let options: apiCrewListOptions = { active: true };
      if (selectedDepot.value === "all") {
        options.regionId = regionId;
        setListenBy("region");
      } else {
        options.depotId = selectedDepot.value;
        setListenBy("depot");
      }
      setIsCalendarLoading(true);
      setCalendarControlsDisabled(true);
      const result = await apiCrewList(options);
      setCrews(result.data.crews);

      if (batchStartDay) {
        const batchEndDayCloned = batchStartDay.clone();
        const crewNotesAll: any = await getCrewNotes(
          result.data.crews,
          batchEndDayCloned
        );
        const leaveNotesAll: any = await getLeaveNotes(
          result.data.crews,
          batchEndDayCloned
        );
        setTimeout(function () {
          setCrewNotes(crewNotesAll);
          setLeaveNotes(leaveNotesAll);
          // console.log(`leaveNotesAll---`);
          // console.log(leaveNotesAll);
          // console.log(`leaveNotesAll----`);
        }, 2000);
      }
    } catch (e) {
      setCrews([]);
      alert({ type: "error", message: "No Crew Found" });
    }
    if (batchStartDay) {
      fetchDrss();
    }
  };
  // determine crews to display
  useEffect(() => {
    // if this is the first load today & implicitly batchStartDay will need to be set
    if (isFirstLoad) {
      setToday(moment());
    }
    if (selectedDepot && selectedDepot.value) {
      setStoreDepot(selectedDepot.value);
      fetchCrews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepot]);

  useEffect(() => {
    if (
      batchStartDay &&
      !isFirstLoad &&
      (!moment(prevBatchStartDay).isSame(batchStartDay, "day") ||
        gridNumber > prevGridNumber)
    ) {
      // console.log(`CrewCalendarView useEffect() prevBatchStartDay=${prevBatchStartDay} batchStartDay=${batchStartDay}`)
      // console.log(`CrewCalendarView useEffect() prevGridNumber=${prevGridNumber} gridNumber=${gridNumber}`)
      //console.log(`CrewCalendarView useEffect() batchStartDay=${batchStartDay} gridNumber=${gridNumber}`)
      setPrevGridNumber(gridNumber);
      setPrevBatchStartDay(batchStartDay);
      fetchCrews();
      fetchDrss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchStartDay, gridNumber]);
  function removeSelectedShifts() {
    const selectedShiftElements: any =
      document.getElementsByClassName("activeShift");
    const TargetShiftIDs: any = [];
    // console.log(`selectedShiftElements----->>`);
    // console.log(selectedShiftElements);
    // console.log(`selectedShiftElements----->>`);
    const selectedShiftElementsMain: any =
      document.getElementsByClassName("sourceShiftClass");
    if (selectedShiftElements.length > 0) {
      for (var i = 0; i < selectedShiftElements.length; i++) {
        TargetShiftIDs.push(selectedShiftElements[i].id);
        // selectedShiftElements[i].classList.remove('activeShift');
      }
      for (var jData in TargetShiftIDs) {
        let validID: any = document.getElementById(TargetShiftIDs[jData]);
        if (validID) validID.classList.remove("activeShift");
      }
    }
    if (selectedShiftElementsMain.length > 0) {
      for (var j = 0; j < selectedShiftElementsMain.length; j++) {
        selectedShiftElementsMain[j].classList.remove("sourceShiftClass");
      }
    }
  }
  function handleDrsOpt(opt: any, obj: any) {
    setOpt(opt);
    setDuplicateObj(obj);
  }
  useEffect(() => {
    if (refreshHook && batchStartDay) {
      console.log(`CrewCalendarView useEffect() refreshHook=${refreshHook}`);
      fetchCrews();
      fetchDrss();
    }
  }, [refreshHook]);
  const showWaitAnimation = () => {
    return (
      <React.Fragment>
        <SpinnerWrapper>
          <StyledFab
            color="inherit"
            aria-label="loading"
            size="small"
            isVisible={isCalendarLoading}
          >
            <Spinner size={18} />
          </StyledFab>
        </SpinnerWrapper>
      </React.Fragment>
    );
  };
  if (!gridNumber) {
    return null;
  }
  return (
    <Wrapper>
      
      {opt && (
        <DuplicateDRSAction>
          <CompThemeButton
            onClick={() => {
              //  setOpt(false);
              duplicateDRS();
            }}
          >
            OK
          </CompThemeButton>
          <CompDeleteButton
            onClick={() => {
              setOpt(false);
              setDuplicateObj({});
              removeSelectedShifts();
              removeSelectedShifts();
            }}
          >
            CANCEL
          </CompDeleteButton>
        </DuplicateDRSAction>
      )}
      {!isFirstLoad && (!storeCrewData || !storeCrewData.length) ? (
        <Typography>No crew found.</Typography>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <CustomDragLayer snapToGrid={true} />
          <WeekView
            shiftNoteStyle={shiftNoteStyle}
            noteClickEvent={noteClickEvent}
            opt={opt}
            duplicateData={duplicateObj}
            isDrsDuplicate={(copyData, obj) => handleDrsOpt(copyData, obj)}
          />
        </DndProvider>
      )}
      {showWaitAnimation()}
     
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  /*  margin-right: 30px;
 make no sense to be responsive under 960px */
  min-width: 1216px;
  /* 
  overflow-x: scroll; */
  /* @media (max-width: 1216px) {
    width: 100%;
    overflow-x: scroll;
  } */
`;

const Spinner = styled(CircularProgress)`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 1px;
  padding-bottom: 10px;
`;

const StyledFab = styled(Fab) <{ isVisible: boolean }>`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;
const DuplicateDRSAction = styled.div`
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  float: left;
  margin-bottom: 10px;
  top: 60px;
  position: absolute;
  z-index: 456;
`;
export default observer(CrewCalendarView);
