import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import FormTable, { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { SRRParkingPatrolInfo } from '@src/types/SRR'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrParkingPatrolRemove } from '@src/apis/srr-parking-patrol-remove'
import { apiSrrParkingPatrolUpdate } from '@src/apis/srr-parking-patrol-update'
import { apiSrrParkingPatrolCreate } from '@src/apis/srr-parking-patrol-create'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatTimeStringOrNullHHmm } from '@src/types/format/timeStringOrNullHHmm'
import usePermission from '@src/utils/hooks/usePermission'
import { toValidTimeString } from '@src/utils/time/toValidTime'

interface Props {

}

const ParkingPatrolInfo = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.machine.trafficControl.parkingPatrolInformation
  const handleSave = async (form: any) => {
    const finalForm = {
      ...form,
      parkingPatrolStartTime: toValidTimeString(form.parkingPatrolStartTime, 'HH:mm'),
    }
    console.log(form)
    if (modalData === null) {
      try {
        const result = await apiSrrParkingPatrolCreate({
          srrId: srr.id, 
          serialId,
          partial: {
            ...finalForm,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrParkingPatrolUpdate({
          srrId: srr.id, 
          serialId,
          partial: {
            ...finalForm,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  const hanldeEditRow = (row: any) => {
    openAndSetData(row)
  }
  const hanldeRemoveRow = async (row: SRRParkingPatrolInfo) => {
    try {
      await confirm({
        title: `Confirm deletion`,
        message: ``,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: SRRParkingPatrolInfo) => {
    try {
      const result = await apiSrrParkingPatrolRemove({
        srrId: srr.id,
        serialId,
        id: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel title={`Parking Patrol Information`}>
      <FormTable<SRRParkingPatrolInfo>
        fields={SRRParkingPatrolInfoFields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      { canEditSubmitSRR && 
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Parking Patrol
          </CompCreateButton>
        </MarginTop>
      }
      { open && <Modal<SRRParkingPatrolInfo>
        maxWidth='xs'
        open={open}
        title='Traffic Control'
        data={{
          rowData: modalData,
          fields: SRRParkingPatrolInfoFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'parkingPatrolDate',
                'parkingPatrolStartTime',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
export const SRRParkingPatrolInfoFields: FormTableField<SRRParkingPatrolInfo>[] = [
  {
    key: 'parkingPatrolDate',
    type: FormTableFieldType.Date,
    label: 'Date for Parking Patrol',
    format: formatDateStringOrNullDDMMYYYY,
  },
  {
    key: 'parkingPatrolStartTime',
    type: FormTableFieldType.Time,
    label: 'Start Time for Parking Patrol',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
]

export default ParkingPatrolInfo