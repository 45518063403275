import React, { useState, useRef, useEffect, useContext, SyntheticEvent } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useAppStore } from '../../../AppStore'
import DepotAutocomplete from '../../../components/autocompletes/DepotAutocomplete'
import { ADIDNameToValueLabel } from '../../../api-adaptors/id-name-to-value-label'
import ForemanAutocomplete from '../../../components/autocompletes/ForemanAutocomplete'
import { apiCrewCreate } from '../../../apis/crew/crew-create'
import { apiCrewUpdate } from '../../../apis/crew/crew-update'
import { useSnackbar } from '../../../components/GlobalSnackbar'
import CrewColor from './CrewColor'

function CrewDialog ({ 
  crew, // only exist in edition
  open, 
  onClose = () => {}, 
  onCancel = () => {}, 
  onOk = () => {},
  onUpdateSuccess= () => {},
  onCreateSuccess= () => {},
}: {
  crew: AIMS.Crew | null,
  open: boolean,
  onClose?(): void,
  onCancel?(): void,
  onOk?(): void,
  onUpdateSuccess?(crew: AIMS.Crew): void,
  onCreateSuccess?(): void,
}) {
  const [ isLoading, setIsLoading ] = useState<any>(false)
  const [ isEdit, setIsEdit ] = useState<any>(false)
  const [ crewName, setCrewName ] = useState<any>('')
  const [ depot, setDepot ] = useState<AIMS.ValueLabel>({ value: '', label: '' })
  const [ colourCode, setColourCode ] = useState<any>('')
  const [ foremen, setForemen ] = useState<AIMS.Profile[]>([])
  const [ isFormValidated, setIsFormValidated ] = useState<any>(false)
  const { stateId } = useAppStore()
  const { alert } = useSnackbar()

  useEffect(() => {
    if (crew !== null && crew.id) {
      setIsEdit(true)
      const { name, active, colourCode, depot } = crew
      setCrewName(name || '')
      setDepot(ADIDNameToValueLabel(depot))
      setColourCode(colourCode || '')
      // setStatus(active)
    } else {
      setIsEdit(false)
      setIsFormValidated(false)
      setCrewName('')
      setDepot({ value: '', label: '' })
      setColourCode('')
      setForemen([])
    }
  }, [open, crew])
 
  function updateStoreCrewData(crewData: any) {
    let crewStoreData = localStorage.getItem('storeCrews');
    if (crewStoreData) {
      let crewStoreDataObj = JSON.parse(crewStoreData);
      let cardCrews = crewStoreDataObj.cardCrews;
      const activeIndex = cardCrews.findIndex((data: any) => data.id === crewData.id);
      console.log('activeIndex------',activeIndex);
      if(activeIndex!=  -1) {
        cardCrews[activeIndex] = crewData;
        crewStoreDataObj.cardCrews = cardCrews;
        localStorage.setItem('storeCrews',JSON.stringify(crewStoreDataObj));
      }
    }
   
  }
  const handleOk = async () => {
    if (!crewName) {
      alert({
        type: 'error',
        message: `Crew Name is required`
      })
      return 
    }
    if (!depot.value) {
      alert({
        type: 'error',
        message: `Depot is required`
      })
      return 
    }
    if (!colourCode) {
      alert({
        type: 'error',
        message: `Crew Colour is required`
      })
      return 
    }
    if (isEdit) {
   
      // update existing asset
      const updatedCrew = {
        ...crew,
        name: crewName,
        colourCode,
        depot: {
          id: depot.value,
        },
      };
      const result = await apiCrewUpdate(updatedCrew)
      if (result.data) {
        console.log('crew---><<',JSON.stringify(crew));
        updateStoreCrewData(updatedCrew);
        alert({
          message: 'Updated.'
        })
        onUpdateSuccess(result.data.crew)
      }
    } else {
      if (!foremen.length) {
        alert({
          type: 'error',
          message: `Foreman is required`
        })
        return 
      }
      setIsLoading(true)
      // create new asset
      const result = await apiCrewCreate({
        name: crewName,
        colourCode,
        depot: {
          id: depot.value
        },
        members: foremen,
      })
      setIsLoading(false)
      if (result.data) {
        localStorage.setItem('newCrewBoral',JSON.stringify(result.data));
        alert({
          message: 'Created.'
        })
        onCreateSuccess()
      }
    }
  }

  const [ validationData, setValidationData ] = useState<any>({
    'name': {
      rules: ['required'],
      pristine: true
    }
  }) 

  if (!open) {
    return null
  }
  const onTextChange = (fieldName: string) => (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value
    if (fieldName === 'crewName') {
      setCrewName(value)
    }
  }
  return <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
    aria-labelledby='labour-dialog-title'>
    <DialogTitle id='labour-dialog-title'>
      {isEdit ? `Edit Crew` : `Add Crew`}</DialogTitle>
    <DialogContent>
      <Section>
        <SectionTitle>Crew Details</SectionTitle>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              {/* <ValidatorField
                  validation={[
                    {
                      type: 'required',
                      errorMessage: 'Asset Number required.'
                    }
                  ]}
                  value={crewName}
                > */}
                <TextField
                  label={`${ isEdit ? 'Crew Name' : 'Add Crew Name'}`}
                  value={crewName}
                  onChange={onTextChange('crewName')}
                  inputProps={{ maxLength: 15 }}
                />
              {/* </ValidatorField> */}
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <DepotAutocomplete
              label={`${ isEdit ? 'Depot' : 'Add Depot'}`}
              selectedItem={depot}
              onChange={(value: AIMS.ValueLabel) => {
                setDepot(value)
              }}
            />
          </Grid>
          {
            // show only in creation
            !isEdit && <Grid item xs={4}>
              <ForemanAutocomplete 
                stateId={stateId}
                selectedItem={{label: '', value: ''}}
                onChange={(value: {
                  raw: AIMS.Profile,
                }) => setForemen([...foremen, value.raw])}
              />
              { foremen.map(foreman => {
                return <div key={foreman.email}>{foreman.email}</div>
              })}
            </Grid>
          }
          
        </Grid>
      </Section>

      <Section>
        <SectionTitle>Crew Colour</SectionTitle>
        
        <Grid container>
          <Grid item xs={4}>
            <CrewColor 
              colorHex={ colourCode ? `#${colourCode}` : '' }
              onChange={(colorHex: string) => setColourCode(colorHex.substring(1))}
              />
            {/* <TextField
                label={`${ isEdit ? 'Colour Code' : 'Add Colour Code'}`}
                value={colourCode}
                onChange={(e: SyntheticEvent) => setColourCode((e.target as HTMLInputElement).value)}
              /> */}
          </Grid>
        </Grid>
      </Section> 
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button 
        color='primary'
        variant='contained'
        disabled={isLoading} 
        onClick={handleOk} 
        >
        { isEdit ? `Update Crew` : `Add Crew` }
      </Button>
    </DialogActions>
  </Dialog>
}

const Section = styled.div`
  margin-bottom: 40px;
`
const SectionTitle = styled.div`
  color: #009688;
  font-size: 20px;
  font-weight: bold;
`
const SDialogContent = styled(DialogContent)`
  margin: 0 50px;
`
const GridInner = styled.div`
  padding-right: 40px;
`

export default CrewDialog

