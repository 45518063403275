import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FileBox from '@src/modules/DRS/legacy/Create/comps/FileBox'
import { FileUpload } from '@src/modules/DRS/legacy/Create/comps/f-FileUpload'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { PackFormType } from '@src/types/PackFormType'
import { RoleId } from '@src/types/common/RoleId'
import { useAppStore } from '@src/AppStore'
import { useParams } from 'react-router'
import { useSRR } from '../../useSRR'
import { apiGetFiles } from '@src/apis/files/get-files'
import FileChip, { FileChipFile, FileChipStatus, FileMime } from '@src/modules/DRS/legacy/Create/comps/FileChip'
import { Attachment } from '@src/types/Attachment'
import { apiRemoveFile } from '@src/apis/files/remove-file'
import { BASE_API_URL } from '@src/constants'
import qs from 'qs'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const SRRSiteDiagram = (props: Props) => {
  const {

  } = props
  const { serialId } = useAppStore()
  const [ srr ] = useSRR()
  const [ refreshToken, setRefreshToken ] = useState<number>(0)
  const [ list, setList ] = useState<Attachment[]>([])
  const { canEditSubmitSRR } = usePermission()

  useEffect(() => {
    async function fetchUploaded() { 
      try {
        const result = await apiGetFiles({
          formType: PackFormType.SRR_SITE_ACCESS,
          formId: srr!.id,
          serialId,
        })
        const resultList = result.data.documents.map((item: Attachment) => ({
          id: item.id,
          uuid: item.name,
          name: item.originalName,
          physicalName: item.name,
          status: FileChipStatus.NORMAL,
          mime: item.fileType.description,
        }))
        setList(resultList as Attachment[])
      } catch (e) {

      }
    }
    if (srr !== null) {
      fetchUploaded()
    }
  }, [ refreshToken, srr ])

  
  if (srr === null) {
    return null
  }
  const handleFileDropOrSelect = (files: FileList) => {
    Promise.all(
      Array.from(files).map((file) => {
        return apiUploadFile({
          formType: PackFormType.SRR_SITE_ACCESS,
          formId: srr!.id,
          roleId: RoleId.ProjectManager,
          serialId,
          file: file,
          projectInternalId: srr!.job.projectDetails.projectInternalId!,
        })
      })
    ).then(() => setRefreshToken(refreshToken + 1))
  }

  const handleFileRemove = (index: number) => async (file: FileChipFile) => {
    try {
      await apiRemoveFile({ 
        id: file.id,
        formType: PackFormType.SRR_SITE_ACCESS,
        formId: srr.id,
        roleId: RoleId.ProjectManager,
        serialId,
      })
      setRefreshToken(refreshToken + 1)
    } catch(e) {
      alert({
        message: 'Cannot remove file at the moment.'
      })
    }
  }
  const handleFileClick = (file: FileChipFile) => {
    const fileUrl: string = `${BASE_API_URL}/v2/documents/document/${file.physicalName}?${qs.stringify({
      reqRole: RoleId.ProjectManager,
      reqSid: serialId,
    })}`
    if (!file.physicalName) {
      return
    }
    window.open(fileUrl, '_blank')
  }
  
  return <Wrapper>
    <FormSectionTitle>SITE DIAGRAM</FormSectionTitle>
    <FormPanel title={`Site Diagram`}>
      { canEditSubmitSRR && 
        <FileBox onDropOrSelect={handleFileDropOrSelect} /> 
      }
      <FileChipsWrapper>
        { list.map((file: any, index: number) => {
          return <FileChip
            key={file.id}
            file={file}
            removable={canEditSubmitSRR}
            onRemove={handleFileRemove(index)}
            onClick={handleFileClick}
            onRemoveTitle={`Confirm removal of ${file.name}`}
            onRemoveMessage={`This action will remove the file attached to this SRR.`}
          />
        })}
      </FileChipsWrapper>
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
const FileChipsWrapper = styled.div`
  margin-top: 8px;
`

export default SRRSiteDiagram