import React from 'react'
import styled from 'styled-components'
import FormTable, { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import useModal from '@src/components/FormModal/useModal'
import Modal from '@src/components/FormModal/Modal'
import { useVMP } from '@src/modules/VehicleMovementPlan/useVMP'
import { useBundle } from '@src/utils/hooks/useBundle'
import { VMPSiteDiagram } from '@src/types/VMPSiteMap'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from '@src/components/GlobalSnackbar'

interface Props {
  index: number,
  onSave(siteDiagram: VMPSiteDiagram): Promise<any>,
}

const CompVMPMapNotes = (props: Props) => {
  const {
    index,
    onSave,
  } = props
  const { alertDone, alertError, alert } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<VMPSiteDiagram>({})
  const [vmp, loading, refresh] = useVMP()
  const [bundle] = useBundle()
  const { vmpDefaultSiteDiagram } = bundle
  if (vmp === null || !bundle || index < 0 || index >= vmp.siteMaps.length || !vmpDefaultSiteDiagram.cleanOutArea) {
    return null
  }
  const data = Object.assign({}, vmpDefaultSiteDiagram, vmp.siteMaps[index].siteDiagram)

  const hanldeEditRow = (row: VMPSiteDiagram) => {
    openAndSetData(row)
  }
  const handleSave = async (form: VMPSiteDiagram) => {
    if (form.entryDescription && form.entryDescription.length > 100) {
      alert({
        type: 'warning',
        message: 'Entry text maximum 100 characters.'
      })
      return
    }
    if (form.exitDescription && form.exitDescription.length > 100) {
      alert({
        type: 'warning',
        message: 'Exit text maximum 100 characters.'
      })
      return
    }
    if (form.marshallingArea && form.marshallingArea.length > 100) {
      alert({
        type: 'warning',
        message: 'Marshalling Area text maximum 100 characters.'
      })
      return
    }
    if (form.cleanOutArea && form.cleanOutArea.length > 100) {
      alert({
        type: 'warning',
        message: 'Clean Out Area text maximum 100 characters.'
      })
      return
    }
    try {
      await onSave(form)
      alertDone()
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  }
  return <Wrapper>
    <FormTable<VMPSiteDiagram>
      fields={NoteFields}
      data={[data]}
      editable={true}
      onEditRow={hanldeEditRow}
      removable={false}
    // onRemoveRow={hanldeRemoveRow}
    />
    {open && <Modal<VMPSiteDiagram>
      maxWidth='sm'
      open={open}
      title='Edit Site Diagram'
      data={{
        rowData: modalData,
        fields: NoteFields,
        columns: [
          {
            title: 'Info',
            fieldKeys: [
              'entryDescription',
              'exitDescription',
              'uhfChannel',
            ],
          },
          {
            title: '',
            fieldKeys: [
              'marshallingArea',
              'cleanOutArea',
              'comments',
            ],
          },
        ]
      }}
      onSuccess={handleSave}
      onClose={closeModal}
      onCancel={closeModal}
    />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`
export const NoteFields: FormTableField<VMPSiteDiagram>[] = [
  {
    key: 'entryDescription',
    type: FormTableFieldType.Textarea,
    label: 'Entry',
  },
  {
    key: 'exitDescription',
    type: FormTableFieldType.Textarea,
    label: 'Exit',
  },
  {
    key: 'uhfChannel',
    type: FormTableFieldType.Number,
    label: 'UHF Channel',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'marshallingArea',
    type: FormTableFieldType.Textarea,
    label: 'Marshalling Area',
  },
  {
    key: 'cleanOutArea',
    type: FormTableFieldType.Textarea,
    label: 'Clean Out Area',
  },
  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comments',
  },
]
export default observer(CompVMPMapNotes)