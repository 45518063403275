import React, { useContext } from 'react'
import { observable, action } from 'mobx'

let _resolve: Function
let _reject: Function

export interface ConfirmModal {
  isOpen: boolean,
  title: string,
  message: string,
  type: string,
  confirmButtonText: string,
  close(): void,
  onConfirm(): void,
  confirm(options: ConfrimOptions): Promise<any>,
}
interface ConfrimOptions {
  title: string,
  message: string,
  type?: string,
  confirmButtonText?: string,
}

const store: ConfirmModal = observable<ConfirmModal>({
  isOpen: false,
  title: '',
  message: '',
  type: 'info',
  confirmButtonText: 'Confirm',
  close: action(() => {
    store.isOpen = false
    // store.message = ''
    _reject()
  }),
  onConfirm: action(() => {
    store.isOpen = false
    _resolve('confirmed')
  }),
  confirm: action(({
    title,
    message,
    type = 'info',
    confirmButtonText = 'Confirm',
  }: ConfrimOptions) => {
    store.isOpen = true
    store.title = title
    store.message = message
    store.confirmButtonText = confirmButtonText
    store.type = type
    return new Promise((resolve, reject) => {
      _resolve = resolve
      _reject = reject
    })
  }),
})


const context = React.createContext(store)

export const useConfirm = () => {
  return useContext<ConfirmModal>(context)
}
