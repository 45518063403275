import React, { useState } from 'react'
import styled from 'styled-components'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker as DatePickerOrigin,
  TimePicker as TimePickerOrigin,
} from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

const Styled = styled.div`

`

const TimePicker = ({ 
  ...props 
}) => {
  return <Styled>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker 
        value={props.value}
        onChange={props.onChange}
        mask='__:__'
        keyboardIcon={<AccessTimeIcon />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  </Styled>
}

TimePicker.defaultProps = {

}


export default TimePicker