import React from 'react'
import moment, { Moment } from 'moment'
import { DatePicker } from '@src/components'
import IconButton from '@material-ui/core/IconButton'
import styled, { css } from 'styled-components'
import { styledWithOut } from '@src/utils/styledWithout'

const Format = 'DD/MM/YYYY'

function FilterDatePicker({
  variant = 'inline',
  value = {},
  
  onChange,
}: {
  variant?: "dialog" | "inline" | "static" | undefined,
  value?: any,
  
  onChange(date: Moment): void,
}) {
  
  
  return (
    <DatePicker
      isKeyboardDatePicker={false}
      onChange={onChange}
      value={value ? moment(value, 'YYYY/MM/DD') : null}
      disableToolbar
      variant={variant}
    />
  )
}

const DayWrapper = styled.div<{ dayInCurrentMonth: boolean }>`
  ${props => {
    if (!props.dayInCurrentMonth) {
      return css`
        opacity: 0;
        pointer-events: none;
      `
    }
  }}
`
const Day = styled(styledWithOut('isHighlighted')(IconButton))`
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: inherit;
  ${props => {
    if (props.isHighlighted) {
      return css`
        background: #009688;
        color: #fff;
      `
    }
  }}
`

export default FilterDatePicker