import React from 'react'
import styled from 'styled-components'

type MarginTopSize = 'lg' | 'md' | 'sm'

interface Props {
  size?: MarginTopSize,
  children: React.ReactNode,
}

const MarginTop = (props: Props) => {
  const {
    size = 'md',
    children,
  } = props

  return <Wrapper marginTop={calc(size)}>
    { children }
  </Wrapper>
}

const Wrapper = styled.div<{ marginTop: number }>`
  margin-top: ${props => props.marginTop}px;
`

function calc(size: MarginTopSize) {
  switch(size) {
    case 'lg': {
      return 24
    }
    case 'md': {
      return 16
    }
    case 'sm': {
      return 8
    }
  }
  return 16
}
export default MarginTop