import React from 'react'
import styled from 'styled-components'
import { Point, CircleHandle } from './Arrow'

interface Props {
  from: Point,
  to: Point,
  stroke?: string,
  rotation?: number,
  onMouseOver(): void,
  hovered?: boolean,
  onMouseDown(e: React.MouseEvent<SVGSVGElement, MouseEvent>): void,
  onTopLeftMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
  onTopRightMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
  onBottomLeftMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
  onBottomRightMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
  onBottomRightRotateMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
}

const CompRectangleElememt = (props: Props) => {
  const {
    from,
    to,
    stroke = '#D32929',
    rotation = 0,
    onMouseOver,
    hovered = false,
    onMouseDown,
    onTopLeftMouseDown,
    onTopRightMouseDown,
    onBottomLeftMouseDown,
    onBottomRightMouseDown,
    onBottomRightRotateMouseDown,
  } = props
  let pTopLeftX = Math.min(from.x, to.x)
  let pTopLeftY = Math.min(from.y, to.y)
  let pBottomRightX = Math.max(from.x, to.x)
  let pBottomRightY = Math.max(from.y, to.y)
  const width = pBottomRightX - pTopLeftX
  const height = pBottomRightY - pTopLeftY

  function buildPath() {
    const dM = `M 0, 0`
    const dRect = `H ${width} V ${height} H 0 Z`
    return `${dM} ${dRect}`
  }
  return <Wrapper
    transform={`translate(${pTopLeftX}, ${pTopLeftY}) rotate(${rotation}, ${width / 2}, ${height / 2})`} 
    onMouseOver={onMouseOver}
    onMouseDown={onMouseDown}
    // onClick={onClick}
  >
    { hovered && 
      <RectPath 
        d={buildPath()} 
        stroke='#56c3e4' 
        strokeWidth={5}
        fill='none'
      />
    }
    <RectPath 
      d={buildPath()} 
      stroke={stroke} 
      strokeWidth={3}
      strokeDasharray='4'
      fill='transparent'
    />

  { hovered && 
      <React.Fragment>
        <CircleHandle r='5' cx={0} cy={0} 
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onTopLeftMouseDown(e)
            }
          } 
        />
        <CircleHandle r='5' cx={width} cy={0} 
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onTopRightMouseDown(e)
            }
          } 
        />
        <CircleHandle r='5' cx={0} cy={height}  
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onBottomLeftMouseDown(e)
            }
          } 
        />

        <RotateHandle r='10' cx={width} cy={height}
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onBottomRightRotateMouseDown(e)
            }
          }
        />
        <CircleHandle r='5' cx={width} cy={height}  
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onBottomRightMouseDown(e)
            }
          } 
        />
        
      </React.Fragment>
    }

  </Wrapper>
}

const Wrapper = styled.g`
  
`
const RectPath = styled.path`
  &:hover {
    cursor: move;
  }
`
const RotateHandle = styled.circle`
  stroke: transparent;
  stroke-width: 2;
  fill: transparent;
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAABA0lEQVQ4T4XSry+FYRTA8c/dBIEuCorGJrCpd1MEI7AxSaLI/gnB7QwjECRBIAiiYBPYVE21sbFzd567Z3eu993e8D7P+Z7v+fG2/P/84AJr+K5DW33cEDaxigmM5/15wr3wGhzDNabwikespCkSXQ4y3mIGOzjKoK+0Fygq6pZcjNs4SEOdebkyBXSGE1wV8AYjmGsY1lu2sFTADxxitwE8znYmCxi9RKlNYJQaw+uBz/jEdIPxBQ/YKMa93NlWBc7jvvqO/UY76zgt4HAaS1xAsdPRPIjpBnSHxXoddYUFiinvYzbfJ7TxPggM00KVKYCwdeqq+v/ViA9TwGH+676b8xetzDIP7MQUnwAAAABJRU5ErkJggg==') 5 5, auto;
`

export default CompRectangleElememt