import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import axios, { Canceler } from 'axios'
let cancel: Canceler

export const apiSupplierSearch = ({ query }: { query: string }) => {
  if (cancel) {
    cancel()
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/suppliers`,
    params: {
      search: query,
    },
    cancelToken: new axios.CancelToken((c: Canceler) => {
      cancel = c
    }),
  }
  return ax(options)
}