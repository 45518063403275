import React, { useState } from 'react'
import styled from 'styled-components'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { DelayReason } from '@src/types/drs/summary/DelayReason'
import Textfield from '@material-ui/core/TextField'
import { useBundle } from '@src/utils/hooks/useBundle'
import { IdDescription } from '@src/types/utils/IdDescription'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from '@src/components/GlobalSnackbar'

interface Props {
  existingDelayReasons: DelayReason[],
  open: boolean,
  onClose(): void,
  onSave(delayReason: DelayReason, duration: number): void,
}

const CompDRSAddDelayReasonsDialog = (props: Props) => {
  const {
    existingDelayReasons = [],
    open,
    onClose,
    onSave,
  } = props
  const [ delayReason, setDelayReason ] = useState<null | DelayReason>(null)
  const [ duration, setDuration ] = useState<number | null>(null)
  const [ bundle ] = useBundle()
  const { alert } = useSnackbar()
  if (!bundle || !bundle.delayReasons) {
    return null
  }
  const leftDelayReasons = bundle.delayReasons.filter((reason: DelayReason) => {
    return !existingDelayReasons.some((existingReason: DelayReason) => reason.id === existingReason.id)
  })
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const found = bundle.delayReasons.find((reason: DelayReason) => reason.id === event.target.value)
    if (found) {
      setDelayReason(found)
    }
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(Number(event.target.value))
  }
  return <Dialog 
    open={open}
    onClose={onClose}
  >
    <DialogTitle>
      Add New Delay Reason
    </DialogTitle>
    <DialogContent>
      <FieldWrapper>
        <FormControl fullWidth>
          <InputLabel id='delay reason type'>Delay Reason</InputLabel>
          <Select
            labelId='delay reason type'
            id='delay reason type select'
            value={delayReason ? delayReason.id : ''}
            onChange={handleChange}
          >
            { leftDelayReasons.map((reason: IdDescription) => {
              return <MenuItem key={reason.id} value={reason.id}>{reason.description}</MenuItem>
            })}
          </Select>
        </FormControl>
      </FieldWrapper>
      <FieldWrapper>
        <FormControl fullWidth>
          <Textfield 
            label='Delay Duration'
            value={duration ? duration : ''}
            type='number'
            onChange={handleInputChange}
            inputProps={{
              id: 'duration',
            }}
          />
        </FormControl>
      </FieldWrapper>

    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      <Button 
        color='primary'
        variant='contained'
        onClick={() => {
          if (!delayReason) {
            alert({
              type: 'warning',
              message: 'Delay Reason required',
            })
            return
          }
          if (!duration) {
            alert({
              type: 'warning',
              message: 'Duration required',
            })
            return
          }
          onSave(delayReason, duration)
        }} 
        >
        { `Save` }
      </Button>
    </DialogActions>
  </Dialog>
}

const Wrapper = styled.div`

`
const FieldWrapper = styled.div`
  margin-bottom: 40px;
`

export default observer(CompDRSAddDelayReasonsDialog)