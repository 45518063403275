import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { WordAutocomplete } from '../../../../components/WordAutocomplete'
import { apiDepots } from '@src/apis/depots'
import { ADIDNameToValueLabel } from '../../../../api-adaptors/id-name-to-value-label'
import { ADDepot, APIDepot } from '@src/api-adaptors/depot'
import { useAppStore } from '@src/AppStore'
import { ValueLabel } from '@src/types/common/ValueLabel'

const FixedOptionOnTop = {
  label: 'All',
  value: 'all',
}

const CrewDepotAutocomplete = ({
  id,
  selectedItem,
  onInput = () => { },
  onChange = () => { },
  onSelect = () => { },
  onEsc = () => { },
}: {
  id: string,
  selectedItem: ValueLabel,
  onInput?(): void,
  onChange?(): void,
  onSelect(item: ValueLabel): void,
  onEsc?(): void,
}) => {
  const { regionId, stateId, appStoreRefreshHook } = useAppStore()
  const [inputValue, setInputValue] = useState<any>('')
  const [options, setOptions] = useState<any>([])

  const finalOptions = [FixedOptionOnTop, ...options.filter((option: ValueLabel) => {
    return (option.label || '').toLowerCase().includes(inputValue.toLowerCase())
  })]

  useEffect(() => {
    const fetchDepotOptions = async () => {
      try {
        const result: any = await apiDepots({
          region: regionId,
          state: stateId,
        })
        setOptions(result.data.depots.map((depot: APIDepot) => ADDepot(depot)).map((depot: ValueLabel) => {
          let splitted = (depot.label || '').split('-')
          return { label: splitted[splitted.length - 1], value: depot.value }
        }))
      } catch (err) {
        console.log('can not get depots')
      }
    }
    fetchDepotOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStoreRefreshHook])

  const handleInput = (value: string) => {
    setInputValue(value)
  }
  const handleSelect = (item: ValueLabel) => {
    setInputValue('')
    onSelect(item)
  }
  return <StyledDepotAutocomplete
    id='crew depot autocomplete'
    selectedItem={selectedItem}
    color='boral-light'
    onInput={handleInput}
    options={finalOptions}
    onSelect={handleSelect}
  />
}

const StyledDepotAutocomplete = styled(WordAutocomplete)`
  display: inline-block;
  color: ${props => props.theme.app.colors['boral-light']};
` as typeof WordAutocomplete



export default CrewDepotAutocomplete