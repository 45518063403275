import { BASE_API_URL, IDENTITY_PROVIDER, REFRESH_TOKEN_URL, CLIENT_ID, REDIRECT_AUTH_URL } from '../constants'
import axios from 'axios'
import { getCookieIdToken, getCookieRefreshToken, setCookieAccessToken, setCookieIdToken } from '../utils/cookies'

let MAX_RETRY_NUMBER = 3
if (process.env.NODE_ENV === 'development') {
  MAX_RETRY_NUMBER = 1
}
let retryCount = 0

export const apiProfileForOktaUsername = (email: string) => {
  return new Promise((resolve, reject) => {
    axios.get(`${BASE_API_URL}/ref/profiles/${email}`, {
      headers: {
        'Authorization': getCookieIdToken()
      }
    })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject()
          clearTokenAndRefresh(email)
        }
        // reset retry count
        // console.log('reset')
        retryCount = 0
      })
      .catch(error => {
        // // if it's token expiry
        // if (error.config && error.config.headers['Authorization'] === getCookieIdToken()) {
        axios({
          url: REFRESH_TOKEN_URL,
          headers: {
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            'Content-Type': 'application/x-amz-json-1.1',
          },
          mode: 'cors',
          cache: 'no-cache',
          method: 'POST',
          data: {
            ClientId: CLIENT_ID,
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
              REFRESH_TOKEN: getCookieRefreshToken(),
            }
          },
        } as any)
          .then(response => {
            if (response.data
              && response.data.AuthenticationResult
              && response.data.AuthenticationResult.IdToken
            ) {
              const { AuthenticationResult } = response.data
              setCookieIdToken(AuthenticationResult.IdToken)
              setCookieAccessToken(AuthenticationResult.AccessToken)
              if (retryCount < MAX_RETRY_NUMBER) {
                retryCount++
                console.log('retryCount', retryCount)
                //do the profile request again using the new IdToken
                resolve(apiProfileForOktaUsername(email))
              } else {
                console.log(`Retried ${MAX_RETRY_NUMBER} times, rejecting`, retryCount)
                reject(error)
              }

            } else {
              clearTokenAndRefresh(email)
              console.log('failed to refresh token')
            }
          })
        // }
      })
  })

}

const clearTokenAndRefresh = (email: string) => {
  setCookieIdToken('')
  setCookieAccessToken('')
  // may redirect to login again
  if (process.env.NODE_ENV === 'development') {
    console.log(`Refresh Token not set for ${email}, get a new one! And set it in test-account`)
  } else {
    window.location.replace(REDIRECT_AUTH_URL)
  }
}