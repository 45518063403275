import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSQaAsphalt } from '@src/types/drs/qa/QaAsphalt'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { JobSpecification } from '@src/types/JobSpecification'

export interface DRSQaAsphaltRowDisplay extends DRSQaAsphalt {

}

const comments: FormTableField<DRSQaAsphaltRowDisplay | DRSQaAsphaltModalField> = {
  key: 'comments',
  type: FormTableFieldType.Textarea,
  label: 'Comment',
}
const jobSpecField: FormTableField<DRSQaAsphaltRowDisplay | DRSQaAsphaltModalField> = {
  id: 'jobSpecification',
  key: 'jobSpecification',
  type: FormTableFieldType.Select,
  label: 'Job Spec',
  format: (item: any) => item ? item.description : '',
}
export const DRSQaAsphaltRequirementFields: FormTableField<DRSQaAsphaltRowDisplay>[] = [
  {
    key: 'asphaltLayingRecord',
    type: FormTableFieldType.Text,
    label: 'Asphalt Laying Record',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'asphaltTesting',
    type: FormTableFieldType.Text,
    label: 'Asphalt Testing',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'compactionTesting',
    type: FormTableFieldType.Text,
    label: 'Compaction Testing',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'holdPointReleaseForm',
    type: FormTableFieldType.Text,
    label: 'Hold Point Release Form',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'labTechOnsite',
    type: FormTableFieldType.Text,
    label: 'Lab Tech Onsite',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'measureDipReport',
    type: FormTableFieldType.Text,
    label: 'Measure Dip Report',
    format: formatBooleanOrNullToYesNo,
  },
]
const DRSQaAsphaltFields: FormTableField<DRSQaAsphaltRowDisplay>[] = [
  jobSpecField,
  ...DRSQaAsphaltRequirementFields,
  comments,
]

export type DRSQaAsphaltModalField = {
  jobSpecification: JobSpecification,
  requirements: any[],
  comments: string,
}

export const DRSQaAsphaltModalFields: FormTableField<DRSQaAsphaltModalField>[] = [
  jobSpecField,
  {
    key: 'requirements',
    label: 'Select QA Requirements',
    type: FormTableFieldType.CheckboxGroup,
    options: DRSQaAsphaltRequirementFields.map((requirement) => ({ value: requirement.key, label: requirement.label })),
  },
  comments,
]

export default DRSQaAsphaltFields