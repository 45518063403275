import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import _set from 'lodash/set'
import _get from 'lodash/get'
import shortid from 'shortid'
import { CONST_PROJECT_TYPES_ENUM } from '../../../../constants/project-types-enum'
import { getNull } from '../Edit/sections/2-Rework-config'

const store: any = observable({
  get type() {
    return _get(store.data, `jobDetails.drsProjectType`).id || 'ASPHALT-SPRAY-SEAL'
  },
  get isAS() { 
    return store.type === CONST_PROJECT_TYPES_ENUM.ASPHALT 
      || store.type === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL'] 
  },
  get isSS() { 
    return store.type === CONST_PROJECT_TYPES_ENUM['SPRAY-SEAL'] 
      || store.type === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL'] 
  },
  get isASSS() { return store.type === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL']  },

  highlightedSection: '',
  setHighlightedSection: action((val: string) => {
    store.highlightedSection = val
  }),
  get highlightedSectionData() {
    if (!store.data) {
      return
    }
    const Mapping: { [key: string]: string } = {
      'JOB_DETAIL': 'jobDetails',
      'REWORK': 'rework',
      'MATERIAL': 'materials',
      'TRANSPORT': 'transport',
      'MOBILE_ASSETS': 'mobileAssets',
      'LABOUR': 'labour',
      'QA_REQUIREMENTS': 'qaRequirements',
      'SUMMARY': 'summary',
    }
    return store.data[Mapping[store.highlightedSection]]
  },
  data: undefined,
  setData: action((data: any) => store.data = data),
  update: action(({
    fieldPath,
    value,
  }: {
    fieldPath: string,
    value: any,
  }) => {
    try {
      _set(store.data, fieldPath, value)
    } catch (err) {
      console.log('can not set DRS data', fieldPath, value)
    }
  }),
})


const context = React.createContext(store)

export const useDRS = () => {
  return useContext(context)
}


/* 
  Utils below
*/
export const getEmptyAsset = () => {
  const ASSETS_EMPTY_ROW_DATA = {
    'mobileAssetDepotId': {
      id: 'My Depot'
    },
    'mobileAsset': {
      'id': '',
      'description': '',
    },
    'hours': '',
  }
  return {
    ...ASSETS_EMPTY_ROW_DATA,
  }
}
export const getEmptyRework = () => {
  const emptyRework = {
    'reworkPercentage': '',
    'reworkTonnes': '',
    'reworkLabourHours': '',
    'reworkEmulsion': '',
  }
  let result: any = { ...emptyRework }
  result._react_key_ = shortid.generate()
  return result
}


export const emptyTransport = {
  asphalt: {
    boral: undefined,
    subcontractor: undefined,
  },
  spraySeal: {
    boral: undefined,
    subcontractor: undefined,
  }
}

export const getEmptyTransportType = (type = 'delivery') => {
  return {
    type,
    trucks: [],
    orderedTonnes: ''
  }
}

export const emptyMaterial = {
  'asphaltLayer': {
    id: '',
    name: '',
  },
  'supplyPlant': {
    id: '',
    name: ''
  },
  'product': {
    id: '',
    description: '',
  },
  'maxRap': '',
  'customerSpecifiedMix': false,
  'depth': '',
  'area': '',
  'tonnes': '',
  'lotNumber': '',
  'controlLine': '',
  'wasteReturn': '',
  'wasteReturnReason': {
    id: '',
    description: '',
  },
}

export const getEmptyMaterialAsProduct = (colName = 'plan') => {
  let result = {
    _react_key_: shortid.generate(),
    [colName]: {
      ...getNull(emptyMaterial),
    }
  }
  return result
}

const EmptyMaterialAsSummary = {
  catEyeCovers: '',
  dryIce: false,
  emulsion: '',
  geoFabric: '',
  grit: '',
  hateliteBitac: '',
  roadBase: '',
  sticksNStomps: '',
}

export const getEmptyMaterialAsSummary = (colName = 'plan') => {
  return {
    _react_key_: shortid.generate(),
    [colName]: {
      ...getNull(EmptyMaterialAsSummary),
    }
  }
}

const EmptyMaterialSsSummary = {
  grit: '',
  catEyeCovers: '',
  hateliteBitac: '',
  sticksNStomps: '',
  emulsion: '',
  geoFabric: '',
}

export const getEmptyMaterialSsSummary = (colName = 'plan') => {
  return {
    _react_key_: shortid.generate(),
    [colName]: {
      ...getNull(EmptyMaterialSsSummary),
    }
  }
}

export const EmptyMaterialSsProduct = {
  'spraySealType': {
    id: '',
    description: '',
  },
  'binderType': {
    id: '',
    description: ''
  },
  'depot': {
    id: '',
    name: ''
  },
  'adhesionAgent': '',
  'aggregateInStockpile': '',
  'aggregateRequired_M3': '',
  'aggregateRequired_Tonnes': '',
  'aggregateSize': {
    id: '',
    description: ''
  },
  'area': '',
  'comments': '',
  'cutter': '',
  'existingSurfaceCondition': '',
  'orderedApplicationRate': '',
  'orderedRate': '',
  'otherSealType': '',
  'residualBinder': '',
  'spraySheetNumber': '',
  'aggregateUsed': '',
}

export const getEmptyMaterialSsProduct = (colName = 'plan') => {
  let result = {
    _react_key_: shortid.generate(),
    [colName]: {
      ...getNull(EmptyMaterialSsProduct),
    }
  }
  return result
}

export const getEmptyLabourMember = () => {
  let result = {
    id: shortid.generate(),
    plan: {
      'email': '',
      'drsSummaryId': 2,
      'assigned': false,
      'profile': {},
      'crew': {},
      'startTime': null,
      'endTime': null,
      'hours': '',
      'comments': '',
    }
  }
  return result
}

const emptySubcontractorAsset = {
  assetType: '',
  supplier: '',
  float: false,
  tipSite: false,

  // 2
  millType: '',
  millSize: '',
  area: '',
  depth: '',
  rapSuitable: false,

  // 3
  docketNumber: '',
  startTime: null,
  endTime: null,
  hours: '',
  waitingTimeHours: '',

  // 4
  tipNumber: '',
  blockNumber: '',
  comment: '',

  // general 1
  equipmentType: '',
  quantity: '',
}
export const getEmptySubcontractorAsset = () => {
  let result: any = emptySubcontractorAsset
  result.id = shortid.generate()
  return result
}

const emptyTransportTableRow = {
  'supplierName': '',
  'orderedTonnesPerRound': '',
  'startTime': null,
  'endTime': null,
  'docketNumber': '',
  'comments': '',
}
export const getEmptyTransportTable = () => {
  return {
    ...emptyTransportTableRow
  }
}

export const getTransportPlan = ({
  deliveryOrProfiling = 'delivery',
}) => {
  const transportTypeId = deliveryOrProfiling === 'delivery' ? 'Delivery' : 'Profiling'
  return {
    ...emptyTransportTableRow,
    transportType: {
      id: transportTypeId,
    }
  }
}

export const getTransportRow = ({
  asOrSs = 'as',
  deliveryOrProfiling = 'delivery',
}) => {
  const workTypeId = asOrSs === 'as' ? 'ASPHALT' : 'SPRAYSEAL'
  return {
    _react_key_: shortid.generate(),
    plan: {
      ...getTransportPlan({ deliveryOrProfiling })
    },
    workType: {
      id: workTypeId,
    }
  }
}
export const emptyMobileAssetsContractorSs = {
  supplierName: '',
  equipmentTypeDesc: '',
  quantity: '',
  docketNumber: '',
  startTime: null,
  endTime: null,
  comments: '',
  hours: '',
}
export const emptyMobileAssetsContractorProfiling = {
  supplierName: '',
  float: false,
  tipSite: false,
  area: '',
  depth: '',
  rapSuitable: false,
  docketNumber: '',
  startTime: null,
  endTime: null,
  hours: '',
  waitingTimeHours: '',
  noOfTips: '',
  noOfBlocks: '',
  comments: '',
  millType: '',
  millSize: '',
}

