import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'


export const SectionNameUrlMapping: { [key: string]: string } = {
  jobDetails: 'jobdetails',
  rework: 'rework',
  materials: 'material',
  transport: 'transport',
  mobileAssets: 'mobileassets',
  labour: 'labour',
  qaRequirements: 'qa',
  summary: 'summary',
}

interface Options {
  sectionName: string,
  drsId: string,
  roleId: string,
}

export const apiDRSGetSection = ({
  sectionName = 'rework',
  drsId,
  roleId,
}: Options) => {
  
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/${SectionNameUrlMapping[sectionName]}`,
    params: {
      drs: drsId,
      role: roleId,
    }
  }
  return ax(options)
}