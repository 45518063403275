import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'
import axios, { Canceler } from 'axios'
let cancel: Canceler

export const apiProfileSearch = ({
  query,
  depotId,
  stateId,
  roleId,
  active,
  roleType,
}: {
  query: string,
  depotId?: string,
  stateId?: string,
  roleId?: string,
  active?: boolean,
  roleType?: string,
}) => {
  // if (!query) return
  if (cancel) {
    cancel()
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/profiles`,
    params: {
      search: query,
      depot: depotId,
      state: stateId,
      role: roleType || roleId,
      active,
    },
    cancelToken: new axios.CancelToken((c: Canceler) => {
      cancel = c
    }),
  }
  return ax(options)
}