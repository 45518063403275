import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import { SRRExtraResourcesFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/11-ExtraResources'
import { SRRProfilerFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/3-Profiling/Profiling'
import { SRRFloatFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/4-Float/MachineFloat'
import { SRRParkingPatrolInfoFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/5-TrafficControl/ParkingPatrolInfo'
import { SRRTrafficControlFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/5-TrafficControl/TrafficControl'
import { SRRPaverFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/1-Paver'
import { SRRMachineResources } from '@src/types/SRR'
import { SRRSteelRollerFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/2-SteelRoller'
import { SRRMultiRollerFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/3-MultiRoller'
import { SRRSkidSteerComboFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/4-SkidSteerCombo'
import { SRRSkidSteerBroomFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/5-SkidSteerBroom'
import { SRRSuctionBroomFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/6-SuctionBroom'
import { SRRTractorBroomFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/7-TractorBroom'
import { SRRWaterCartFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/8-WaterCart'
import { SRRLineMarkingFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/9-LineMarking'
import { SRRVMSBoardsFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/2-Resource/10-VMSBoards'

interface Props {

}

const CompPreviewMobileAssets = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  const renderResources = () => {
    const mapping: { key: keyof Omit<SRRMachineResources, 'extraResources'>, label: string, fields: any[] }[] = [
      {
        key: 'paver',
        label: 'Paver',
        fields: SRRPaverFields,
      },
      {
        key: 'steelRoller',
        label: 'Steel Roller',
        fields: SRRSteelRollerFields,
      },
      {
        key: 'multiRoller',
        label: 'Multi Roller',
        fields: SRRMultiRollerFields,
      },
      {
        key: 'skidSteerCombo',
        label: 'Skid Steer / Combo',
        fields: SRRSkidSteerComboFields,
      },
      {
        key: 'skidSteerBroom',
        label: 'Skid Steer Broom',
        fields: SRRSkidSteerBroomFields,
      },
      {
        key: 'suctionBroom',
        label: 'Suction Broom',
        fields: SRRSuctionBroomFields,
      },
      {
        key: 'tractorBroom',
        label: 'Tractor Broom',
        fields: SRRTractorBroomFields,
      },
      {
        key: 'waterCart',
        label: 'Water Cart',
        fields: SRRWaterCartFields,
      },
      {
        key: 'lineMarking',
        label: 'Line Marking',
        fields: SRRLineMarkingFields,
      },
      {
        key: 'vmsBoards',
        label: 'VMS Boards',
        fields: SRRVMSBoardsFields,
      },
    ]
    return mapping.map((resource) => {
      const data = srr.machine.resources[resource.key]
      if (data === null) {
        return null
      }
      if (Array.isArray(data)) {
        return data.map((item, index) => {
          return <CompPreviewSection 
            key={item.id}
            name={`${resource.label} ${index + 1}`}
          >
            <CompFlatFields
              value={item}
              fields={resource.fields}
            />
          </CompPreviewSection>
        })
      } else {
        return <CompPreviewSection 
          name={`${resource.label}`}
        >
          <CompFlatFields<any>
            value={data}
            fields={resource.fields}
          />
        </CompPreviewSection> 
      }
    })
  }
  const renderExtraResources = () => {
    const data = srr.machine.resources.extraResources
    if (data !== null) {
      return <CompPreviewSection 
        name={`Extra Resources`}
      >
        <CompFlatFields
          value={data}
          fields={SRRExtraResourcesFields}
        />
      </CompPreviewSection>
    }
  }
  const renderProfiling = () => {
    const data = srr.machine.profiling.profilers
    return <React.Fragment>
      {
        data.map((profiler, index) => {
          return <CompPreviewSection 
            key={profiler.id}
            name={`Profiling ${index + 1}`}
          >
            <CompFlatFields
              value={profiler}
              fields={SRRProfilerFields}
            />
          </CompPreviewSection>
        })
      }
    </React.Fragment>
  }
  const renderFloat = () => {
    const data = srr.machine.float.float
    if (data !== null) {
      return <CompPreviewSection 
        name={`Float`}
      >
        <CompFlatFields
          value={data}
          fields={SRRFloatFields}
        />
      </CompPreviewSection>
    }
  }
  const renderTrafficControl = () => {
    const data = srr.machine.trafficControl.trafficControl
    return <React.Fragment>
      {
        data.map((item, index) => {
          return <CompPreviewSection 
            key={item.id}
            name={`Traffic Control ${index + 1}`}
          >
            <CompFlatFields
              value={item}
              fields={SRRTrafficControlFields}
            />
          </CompPreviewSection>
        })
      }
    </React.Fragment>
  }
  const renderParkingPatrol = () => {
    const data = srr.machine.trafficControl.parkingPatrolInformation
    return <React.Fragment>
      {
        data.map((item, index) => {
          return <CompPreviewSection 
            key={item.id}
            name={`Parking Patrol ${index + 1}`}
          >
            <CompFlatFields
              value={item}
              fields={SRRParkingPatrolInfoFields}
            />
          </CompPreviewSection>
        })
      }
    </React.Fragment>
  }
  return <Wrapper>
    { renderResources() }
    { renderExtraResources () }
    { renderProfiling() }
    { renderFloat() }
    { renderTrafficControl() }
    { renderParkingPatrol() }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompPreviewMobileAssets