import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

export interface APILabourDepotOptions {
  depotId: string,
  serialId: number,
}

export const apiLabourDepotUpdate = ({
  depotId,
  serialId,
}: APILabourDepotOptions) => {
  const data = {
    updateType: "depot",
    serialId,
    depotId,
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/profiles`,
    data,
  }
  return ax(options)
}