import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useAppStore } from "@src/AppStore";
import _find from "lodash/find";
import _some from "lodash/some";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Grid, IconButton } from "@material-ui/core";
import {
  Check as CheckIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Share as FolderShared,
} from "@material-ui/icons";
import { useCrewCalender } from "@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";

import FormModalTextField from "@src/components/FormModal/Textfield";
import CompThemeButton from "@src/components/Buttons/CompThemeButton";

import { DndProvider } from "react-dnd";

import ShiftCardViewSection from "./ShiftCardViewSection";
import { apiUserShiftCardViewCreate } from "@src/apis/user-view-create";
import { apiUserShiftCardViewUpdate } from "@src/apis/user-view-update";
import { apiUserViewsLists } from "@src/apis/user-view-list";
import { apiUserShiftCardViewRemove } from "@src/apis/user-view-delete";

import ShiftCardCrewItem from "./ShiftCardCrewItem";
import { useCrewFilter } from "@src/modules/DashboardCalendar/CrewCalendarView/FilterInHeader/CrewFilterStore";
import ShareViewDialogue from "./ShiftCardViewShare";

interface DragItem {
  type: string;
}
interface Props {
  open: boolean;
  onClose(): void;
  onSave(): void;
  myViews: any;
}
const UserViewDialog = (props: Props) => {
  const { open, onClose, onSave, myViews } = props;
  const { serialId } = useAppStore();
  const { alertDone, alertError, alert } = useSnackbar();
  const {
    setAllShiftViewsOptions,
    allCardsOptions,
    allShiftViewsOptions,
    currentShiftCardViewLocalId,
    setCurrentShiftCardViewId,
    setCurrentShiftCardViewFields,
    currentShiftCardView,
    setUserShiftCardView,
    defaultViewOptions,
    crews,
    setCrews,
    setStoreCardCrews,
    storeCrewData,
    goRefresh,
  } = useCrewCalender();
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editID, setEditID] = useState<number>(0);
  const [canAddNewView, setCanAddNewView] = useState<boolean>(false);
  const [viewName, setViewName] = useState<string>("");
  const [fieldsInError, setFieldsInError] = useState<any[]>([]);
  const [currentView, setCurrentViewData] = useState<any>([]);
  const [myTotalViews, setMyTotalViews] = useState<any>([]);
  const [canHandleCrew, setCanHandleCrew] = useState<boolean>(false);
  const [cardCrews, setCardCrews] = useState<any>([]);
  const { selectedDepot } = useCrewFilter();
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [shareSelectedView, setShareSelectedView] = useState<any>(null);
  useEffect(() => {
    if (myViews) {
      setMyTotalViews(myViews);
      setCurrentSelectedView(myViews);
    }
  }, [myViews]);
  function filterArr(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  function handleMoveAgain(type: string, item: any) {
    const itemData = item.shift;

    if (type === "back") {
      const foundAll = allShiftViewsOptions.find((fs: any) => fs === itemData);
      if (!foundAll) {
        allShiftViewsOptions.unshift(itemData);
        currentView.splice(
          currentView.findIndex((o: any) => o === itemData),
          1
        );
      }
    }
    if (type === "move") {
      const found = currentView.find((fs: any) => fs === itemData);
      if (!found) {
        // console.log('itemData-Shifting');
        currentView.unshift(itemData);
        allShiftViewsOptions.splice(
          allShiftViewsOptions.findIndex((o: any) => o === itemData),
          1
        );
      }
    }
    setCurrentViewData(currentView);
    setAllShiftViewsOptions(allShiftViewsOptions);
  }
  function moveCard(dragIndex: number, hoverIndex: number, type: string) {
    const dragItem = currentView[dragIndex];

    if (dragItem && type !== "all") {
      setCurrentViewData((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  }
  function moveCardCrew(dragIndex: number, hoverIndex: number) {
    const dragItem = cardCrews[dragIndex];
    setCardCrews((prevState: any) => {
      const coppiedStateArray = [...prevState];
      // remove item by "hoverIndex" and put "dragItem" instead
      const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
      // remove item by "dragIndex" and put "prevItem" instead
      coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
      return coppiedStateArray;
    });
  }

  function setCurrentSelectedView(viewData: any) {
    const finalView = [];
    for (var i = 0; i < viewData.length; i++) {
      if (viewData[i].isDefault === true) {
        setCurrentShiftCardViewFields(viewData[i].selectedItems);
        setCurrentShiftCardViewId(viewData[i].viewId);
        break;
      }
    }
    return finalView.toString();
  }
  const handleShiftCardViewShare = async (viewData: any) => {
    try {
      setShareDialogOpen(true);
      setShareSelectedView(viewData);
    } catch (e) {
      console.log(e);
      alertError();
    }
  };
  const handleEdit = (viewData: any) => {
    setEdit(true);
    setCanHandleCrew(false);
    setEditID(viewData.viewId);
    setCanAddNewView(true);
    setViewName(viewData.viewName);
    setCurrentViewData(viewData.selectedItems.split(","));
    handleAllOptions(viewData.selectedItems.split(","));
    const allShiftCardOptionsData = allShiftViewsOptions.filter(filterArr);
    setAllShiftViewsOptions(allShiftCardOptionsData);
  };

  const handleCancel = async () => {
    setEdit(false);
    setEditID(0);
    setCanAddNewView(false);
    setViewName("");
    setCanHandleCrew(false);
  };

  function handleAllOptions(viewData: any) {
    for (var i = 0; i < viewData.length; i++) {
      if (allShiftViewsOptions.findIndex((o: any) => o === viewData[i]) != -1)
        allShiftViewsOptions.splice(
          allShiftViewsOptions.findIndex((o: any) => o === viewData[i]),
          1
        );
    }
    setAllShiftViewsOptions(allShiftViewsOptions);
  }
  const handleDefaultShiftCard = async (viewData: any) => {
    try {
      apiUserShiftCardViewUpdate({
        viewName: viewData.viewName,
        isDefault: true,
        viewId: viewData.viewId,
        sharedUserSearialId: "",
        selectedItems: viewData.selectedItems,
        userSearialId: viewData.userSearialId,
      }).then((response: any) => {
        resetViewData();
        alertDone();
      });
    } catch (e) {
      console.log(e);
      alertError();
    }
  };
  const handleShiftCardDelete = async (id: number) => {
    try {
      apiUserShiftCardViewRemove({
        viewId: id,
      }).then((response: any) => {
        resetViewData();
        alertDone();
      });
    } catch (e) {
      console.log(e);
      alertError();
    }
  };
  const handleSaveCrew = async () => {
    setCrews(cardCrews);
    setStoreCardCrews({ depotID: selectedDepot.value, cardCrews });
    setEdit(false);
    setEditID(0);
    setCanAddNewView(false);
    setViewName("");
    setCanHandleCrew(false);
    onClose();
    goRefresh();
  };
  const handleSave = async () => {
    if (canHandleCrew) {
      handleSaveCrew();
      return;
    }
    if (editID !== 0) {
      try {
        apiUserShiftCardViewUpdate({
          viewName: viewName,
          isDefault: currentShiftCardViewLocalId === editID ? true : false,
          viewId: editID,
          sharedUserSearialId: "",
          selectedItems: currentView.join(","),
          userSearialId: serialId,
        }).then((response: any) => {
          resetViewData();
          alertDone();
        });
      } catch (e) {
        console.log(e);
        alertError();
      }
    } else {
      apiUserShiftCardViewCreate({
        viewName: viewName,
        isDefault: currentShiftCardViewLocalId === editID ? true : false,
        userSearialId: serialId,
        sharedUserSearialId: "",
        selectedItems: currentView.join(","),
      }).then((response: any) => {
        resetViewData();
        alertDone();
      });
    }
  };
  const resetViewData = async () => {
    setUserShiftCardView(currentView, viewName, editID);
    setEdit(false);
    setEditID(0);
    setCanAddNewView(false);
    setViewName("");
    if (currentShiftCardViewLocalId == editID) {
      setCurrentShiftCardViewId(editID);
      setCurrentShiftCardViewFields(currentShiftCardView);
    }
    const userViewsData: any = await apiUserViewsLists({
      userViewSerialId: serialId,
    });
	console.log("server response" + JSON.stringify(userViewsData));
    if (userViewsData) {
      setMyTotalViews(userViewsData.data.shiftcardviews);
      setCurrentSelectedView(userViewsData.data.shiftcardviews);
    }
  };
  const handleAdd = async () => {
    setEdit(false);
    setEditID(0);
    setCanAddNewView(true);
    setViewName("");
    setCanHandleCrew(false);
    setCurrentViewData(defaultViewOptions);
    setAllShiftViewsOptions(allCardsOptions);
  };

  const handleCrews = async () => {
    setCardCrews(storeCrewData);
    setCanHandleCrew(true);
  };
  const onCloseShare = async () => {
    setShareDialogOpen(false);
  };
  const onSaveShare = async () => {
    setShareDialogOpen(false);
    alertDone();
    setShareSelectedView(null);
  };

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={onClose}>
      <DialogTitle>
        <ModalTitle>User View Settings</ModalTitle>
      </DialogTitle>
      <DialogContent>
        <SectionWrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {}
              {!canAddNewView && !canHandleCrew && (
                <TableContainer
                  style={{ marginBottom: "15px", position: "relative" }}
                >
                  <MainActionFormBtn>
                    <CompThemeButton
                      onClick={() => {
                        handleAdd();
                      }}
                    >
                      Add New View
                    </CompThemeButton>
                    &nbsp;
                    <CompThemeButton id={'manage-crew-order'}
                      onClick={() => {
                        handleCrews();
                      }}
                    >
                      Manage Crew Order
                    </CompThemeButton>
                  </MainActionFormBtn>
                  <Table
                    aria-labelledby="commentlist"
                    style={{ border: "2px solid" }}
                  >
                    <TableHeaderCell>
                      <TableRow>
                        <TableCellCusHead align="center">Name</TableCellCusHead>
                        <TableCellCusHead align="center">
                          Selected Items
                        </TableCellCusHead>

                        <TableCellCusHead align="center">
                          Action
                        </TableCellCusHead>
                      </TableRow>
                    </TableHeaderCell>
                    <TableBody>
                      {myTotalViews.map((viewData: any) => {
                        return (
                          <TableRow>
                            <TableCellCus align="center">
                              {viewData.isDefault && (
                                <DefaultCheck>
                                  <CheckIcon style={{ color: "green" }} />
                                </DefaultCheck>
                              )}{" "}
                              {viewData.viewName}{" "}
                            </TableCellCus>
                            <TableCellCus align="center">
                              {viewData.selectedItems
                                .split(",")
                                .map((dataFields: any) => {
                                  return (
                                    <FieldSection>{dataFields}</FieldSection>
                                  );
                                })}
                            </TableCellCus>
                            <TableCellCus align="center">
                              <ButtonGroup
                                variant="contained"
                                color="primary"
                                aria-label="split button"
                              >
                                <IconButton
                                  onClick={() => {
                                    handleEdit(viewData);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    handleShiftCardDelete(viewData.viewId);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    handleDefaultShiftCard(viewData);
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>

                                <IconButton
                                  onClick={() => {
                                    handleShiftCardViewShare(viewData);
                                  }}
                                >
                                  <FolderShared />
                                </IconButton>
                              </ButtonGroup>
                            </TableCellCus>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>

          {canAddNewView && !canHandleCrew && (
            <SectionViewForm>
              <SectionViewField>
                <FieldWrapper>
                  {
                    <FormModalTextField
                      label="View Name"
                      defaultValue={viewName}
                      helperText={
                        _some(fieldsInError, { key: "View Name" })
                          ? _find(fieldsInError, { key: "View Name" })
                              .helperTextError
                          : ""
                      }
                      error={_some(fieldsInError, { key: "View Name" })}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        var val = e.target.value;
                      }}
                      onBlur={(e) => {
                        setViewName(e.target.value);
                      }}
                    />
                  }
                </FieldWrapper>
              </SectionViewField>

              <DndProvider backend={HTML5Backend}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <ShiftCardViewSection
                      id="allCard"
                      moveCard={moveCard}
                      onMove={handleMoveAgain}
                      type="all"
                      shiftCards={allShiftViewsOptions}
                      title={"View Options"}
                    ></ShiftCardViewSection>
                  </Grid>
                  <Grid item xs={2}>
                    <SectionAction></SectionAction>
                  </Grid>
                  <Grid item xs={5}>
                    <ShiftCardViewSection
                      id="userCard"
                      type="mine"
                      moveCard={moveCard}
                      onMove={handleMoveAgain}
                      shiftCards={currentView}
                      title={"My View"}
                    ></ShiftCardViewSection>
                  </Grid>
                </Grid>
              </DndProvider>
            </SectionViewForm>
          )}
          {canHandleCrew && !canAddNewView && (
            <SectionViewForm>
              <DndProvider backend={HTML5Backend}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SectionWrapper key={"sectionCrewMain"}>
                      <Section>
                        {cardCrews.map((card: any, index: number) => {
                          return (
                            <ShiftCardCrewItem
                              moveCard={moveCardCrew}
                              index={index}
                              inDragLayer={false}
                              data={card}
                              id={"card_crew" + index}
                            ></ShiftCardCrewItem>
                          );
                        })}
                      </Section>
                    </SectionWrapper>
                  </Grid>
                </Grid>
              </DndProvider>
            </SectionViewForm>
          )}
        </SectionWrapper>
      </DialogContent>
      <DialogActions>
        {(canAddNewView || canHandleCrew) && (
          <Button onClick={handleSave}>Save</Button>
        )}
        {canAddNewView && <Button onClick={handleCancel}>Cancel</Button>}
        <Button
          onClick={(e: React.SyntheticEvent<any>) => {
            e.stopPropagation();
            window.location.reload();
          }}
        >
          Close
        </Button>
      </DialogActions>
      {shareDialogOpen && (
        <ShareViewDialogue
          viewData={shareSelectedView}
          onClose={onCloseShare}
          open={open}
          onSuccess={onSaveShare}
        ></ShareViewDialogue>
      )}
    </Dialog>
  );
};

const FieldSection = styled.label`
    font-size: 12px;
    margin-right: 10px;
    background: #009688;
    padding: 5px;
    line-height: 46px;
    color: #fff;
}
`;
const ShiftCardView = styled.div`
  padding: 10px;
  background-color: #0a9688;
  color: #fff;
  border: 1px solid #ececec;
  margin: 5px 0px;
  cursor: pointer;
`;
const ModalTitle = styled.div`
  font-size: 24px;
`;
const DefaultCheck = styled.div`
  color: green;
`;

const SectionWrapper = styled.div`
  /* display: flex; */
`;
const MainActionFormBtn = styled.div`
  float: right;
  margin-bottom: 10px;
`;
const SectionViewForm = styled.div`
  margin-top: 10px;
  text-align: center;
`;
const SectionViewField = styled.div`
  padding: 0 10px;
  vertical-align: middle;

  text-align: center;
`;
const SectionAction = styled.div`
  padding: 0 10px;
  vertical-align: middle;
  margin-top: 100%;
  text-align: center;
`;
const Section = styled.div`
  padding: 0 30px;
  border: 1px solid #cecece;
`;

const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`;
const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
`;
const FormModalSelectWrapper = styled.div`
  min-width: 200px;
`;

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;
const TableHeaderCell = styled(TableHead)`
  background-color: #f5f5f5;
`;
const TableCellCusHead = styled(TableCell)({
  border: "2px solid #f7f8f9",
  color: "#707070",
  fontWeight: "bold",
  fontSize: "16px",
});
const TableCellCus = styled(TableCell)({
  border: "2px solid #f7f8f9",
  wordBreak: "break-word",
});

export default UserViewDialog;
