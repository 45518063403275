import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiAggregateSizeSearch } from '../../apis/aggregate-size-search'

const AggregateSizeAutocomplete = ({
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
}: AIMS.Autocomplete) => {
  const fetch = async (query: string) => {
    const result: any = await apiAggregateSizeSearch({ query })
    return result.data.aggregates
      .slice(0, 5)
      .map((aggregateSize: any) => {
        return {
          value: aggregateSize.id,
          label: aggregateSize.description,
          raw: aggregateSize,
        }
      })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label || 'Aggregate Size'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}

export default AggregateSizeAutocomplete