import React from 'react'
import styled from 'styled-components'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE, mapDRSPageTypeToRoleId } from '@src/modules/DRS/DRSPage'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import _get from 'lodash/get'
import { PAE } from '@src/types/drs/PAE'
import { tripleUp } from '@src/modules/DRS/utils/tripleUp'
import { getSupplierAgreementActivityRowData } from '../utils/getSupplierAgreementActivityRowData'
import { transformFromFormData } from '../utils/transformFromFormData'
import DRSSubcontractorProfilingAssetsFields, { DRSSubcontractorProfilingAssetRow } from './ProfilingAssetsFields'
import { SubcontractorProfilingAssetPAE } from '@src/types/drs/subcontractor/SubcontractorProfiling'
import { apiDRSSubcontractorProfilingAssetsUpdate } from '@src/apis/drs/drs-subcontractor-profiling-assets-update'
import { PackFormType } from '@src/types/PackFormType'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { apiDRSSubcontractorProfilingAssetsRemove } from '@src/apis/drs/drs-subcontractor-profiling-assets-remove'

const NULL_VALUES = {
  area: null,
  comments: null,
  depth: null,
  docketNumber: null,
  hours: null,
  rapSuitable: null,
  startTime: null,
  supplier: null,
  supplierAgreement: null,
  tipSite: null,
  tipSiteLocation: null,
}
const ZERO_VALUES = {
  area: '0',
  comments: null,
  depth: '0',
  docketNumber: null,
  hours: '0',
  rapSuitable: null,
  startTime: null,
  supplier: null,
  supplierAgreement: null,
  tipSite: null,
  tipSiteLocation: null,
}

interface Props { }
const CompDRSSubcontractorProfilingAssets = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const { drs, drsId, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone, } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSSubcontractorProfilingAssetRow>({})
  const { depotId, stateId, serialId } = useAppStore()
  if (drs === null || drs.subcontractor.asphalt === null) {
    return null
  }
  const handleSave = async (form: DRSSubcontractorProfilingAssetRow) => {
    const finalFormData = transformFromFormData(form, drs.jobDetails.date)
    if (modalData === null) {
      const retained = getRetained()
      update([
        ...retained,
        {
          [updatingColumn]: {
            ...finalFormData,
          }
        }
      ])
    } else {
      updateItemByValues(form, finalFormData)
    }
  }
  const handleRevert = async (form: DRSSubcontractorProfilingAssetRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleMarkZero = async (form: DRSSubcontractorProfilingAssetRow) => {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = async (form: any[]) => {
    var recordsAPI = [];
    var formData =typeof form == 'object' && form.length > 0 ?  form : [form];
    const retained = getRetained();
    if (formData.length > 0) {
      if (retained.length == formData.length) {
        recordsAPI = retained;
      } else {
        for (var i = 0; i < formData.length; i++) {
          recordsAPI.push(retained[formData[i]['_no_']]);
        }
      }
    }
  
    if (recordsAPI.length > 0)
      deleteRecords(recordsAPI);
  }
  const deleteRecords = async (data: any[]) => {
    try {
      await apiDRSSubcontractorProfilingAssetsRemove({
        drsId: drsId,
        assets: data,
        reqSid: serialId,
        reqRole: mapDRSPageTypeToRoleId(drsPageType),
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const handleDuplicate = async (form: DRSSubcontractorProfilingAssetRow) => {
    const retained = getRetained()
    update([
      ...retained,
      {
        [updatingColumn]: {
          ...NULL_VALUES,
          supplier: form.supplier,
          supplierAgreement: form.supplierAgreement,
        }
      }
    ])
  }
  const getRetained = () => {
    const retainedAssets = drs.subcontractor.asphalt.profilingAssets
    return retainedAssets
  }
  const updateItemByValues = (form: DRSSubcontractorProfilingAssetRow, values: any) => {
    const retained = getRetained()
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const data = [
      ...retained.slice(0, updatingIndex),
      {
        ...retained[updatingIndex],
        [updatingColumn]: {
          ...values,
        }
      },
      ...retained.slice(updatingIndex + 1),
    ]
    update(data)
  }
  const update = async (data: any[]) => {
    try {
      await apiDRSSubcontractorProfilingAssetsUpdate({
        drsId: drsId,
        assets: data,
        reqSid: serialId,
        reqRole: mapDRSPageTypeToRoleId(drsPageType),
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const getTableData = () => {
    const assets: SubcontractorProfilingAssetPAE[] = drs.subcontractor.asphalt.profilingAssets
    const packFormType = PackFormType.DRS_SUBCONTRACTOR_ASPHALT_MOBILE_ASSET
    // const tripled = tripleUp<SubcontractorProfilingAssetPAE>(assets, drsPageType, packFormType)
    const tripled = doubleUp<SubcontractorProfilingAssetPAE>(assets, drsPageType)
    const tripledWithActions = tripled.map((data: any) => mapMoreActions(data, drsPageType, undefined, [PAETableMoreActionType.Delete, PAETableMoreActionType.Duplicate]))
    return tripledWithActions
  }

  let finalFields = DRSSubcontractorProfilingAssetsFields
  return <Wrapper>
    <FormPanel title={`Subcontractor Profiling`}>
      <FormPAETable<DRSSubcontractorProfilingAssetRow>
        fields={finalFields}
        data={getTableData()}
        isSpecialDelete={true}
        showNo={true}
        editable={true}
        isSelectable={true}
        onEditRow={(row: DRSSubcontractorProfilingAssetRow) => {
          openAndSetData(getSupplierAgreementActivityRowData(row))
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add New Asset
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSSubcontractorProfilingAssetRow>
        maxWidth={'md'}
        open={open}
        title='Subcontractor Profiling Asset'
        data={{
          rowData: modalData,
          fields: finalFields,
          columns: [
            {
              title: 'Asset Information',
              fieldKeys: [
                'supplier',
                'supplierAgreement',
                'activity',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'startTime',
                'hours',
                'docketNumber',
                'area',
                'depth',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'rapSuitable',
                'tipSite',
                'tipSiteLocation',
                'comments',
              ],
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSSubcontractorProfilingAssets