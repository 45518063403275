import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


interface Options {
 
  Comment: string,
  DepotID: string,
  Date: string,
  RGBColorCode: string,
  CreatedBy: string,
  Active: boolean

}
export const apiDepotNoteCreate = ({
  Comment,
  DepotID,
  Date,
  RGBColorCode,
  CreatedBy,
  Active
  
}: Options) => {
  const data = {
    Comment,
  DepotID,
  Date,
  RGBColorCode,
  CreatedBy,
  Active
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/depotNotes`,
    data
  }
  return ax(options)
}