import React from 'react'
import styled from 'styled-components'
import { Profile } from '@src/types/common/Profile'
import CancelIcon from '@material-ui/icons/Cancel'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useHover } from '@src/utils/hooks'
import { styledWithOut } from '@src/utils/styledWithout'
import IconButton from '@material-ui/core/IconButton'
import { capWord } from '@src/utils/capWord'

export interface ProfileChipProps {
  profile: Profile,
  onRemove?(): void,
  removable?: boolean,
}

const ProfileChip = (props: ProfileChipProps) => {
  const { profile, removable = false, onRemove } = props
  const [ hoverRef, isHovered ] = useHover()
  const { firstName, lastName } = profile
  return <Wrapper ref={hoverRef as any}>
    <RemoveButton onClick={onRemove} visible={removable && isHovered}>
      <CancelIcon />
    </RemoveButton>
    <AccountCircleIcon />
    <Name>
      { `${capWord(firstName)} ${capWord(lastName).substring(0, 1)}`}
    </Name>
  </Wrapper>
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-left: 6px;
  &:first-child {
    margin-left: 0;
  }
  text-align: center;
`
const RemoveButton = styled(styledWithOut('visible')(IconButton))`
  visibility: ${props => props.visible ? 'visible' : 'hidden' };  
  padding: 0;
  position: absolute;
  top: -6px;
  right: -2px;
  color: #000;
  svg {
    width: 16px;
    height: 16px;
  }
`
const Name = styled.div``

export default ProfileChip