import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'


interface Options {
  id: number,
  shiftId?: number,
  submittedBy?: string,
  agreedByCustomer?:string,
  customerContact?:string,
  section?: string,
  contractNo?: string,
  type?: string,
  holdPointNumber?: string,
  aspect?: [],
  detailsComments?: string,
  comments?: string,
  status?: string,

  asphalt?:[],
  serialId?: number,
  
 spray?:[],
 idempToken?:string
}
export const apiATPSubmitPut = (options: Options) => {
  const { shiftId, serialId,submittedBy,agreedByCustomer,id,
    customerContact,section,contractNo,type,holdPointNumber
	,aspect,detailsComments, comments,status,asphalt,spray,idempToken
   } = options
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/atp`,
    data: options,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}