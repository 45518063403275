import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { groupProjects } from '../groupProjects'
import ProjectGroupComp from './ProjectGroup'
import { Project } from '@src/types/project/Project'
import { ProjectGroup } from '@src/types/project/ProjectGroup'

export const ShouldDisableStatusContext = React.createContext(false)
const ShouldDisableStatusProvider = ({ value, children } : { value: boolean, children: ReactNode }) => (
  <ShouldDisableStatusContext.Provider value={value}>
    {children}
  </ShouldDisableStatusContext.Provider>
)


const ProjectsGroupByDepot = ({
  projects = [],
  shouldDisableStatus = false,
  afterStatusChanged = () => {},
}: {
  projects: Project[],
  shouldDisableStatus?: boolean,
  afterStatusChanged?(project: Project): void,
}) => {
  const projectGroups = groupProjects({
    projects,
  })
  return <Wrapper>
    <ShouldDisableStatusProvider value={shouldDisableStatus}>
    { 
      projectGroups.map((projectGroup: ProjectGroup) => (
        <ProjectGroupComp
          key={projectGroup.id}
          projectGroup={projectGroup}
          afterStatusChanged={afterStatusChanged}
        />
      ))
    }
    </ShouldDisableStatusProvider>
  </Wrapper>
}

const Wrapper = styled.div``
export default ProjectsGroupByDepot