import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable from '@src/components/Form/PAETable'
import DRSReworkSummaryFields from '../../2-Rework/parts/ReworkSummaryFields'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { useDRS } from '@src/modules/DRS/useDRS'
import { formatProjectTypeId } from '@src/types/ProjectTypeId'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatShiftTypeId } from '@src/types/ShiftTypeId'
import { ShiftTypeStatic } from '@src/modules/DRS/legacy/Create/comps/f-ShiftType'

const Fields: FormTableField<any>[] = [
  {
    key: 'date',
    label: 'Date',
    type: FormTableFieldType.Text,
  },
  {
    key: 'shift',
    label: 'Shift',
    type: FormTableFieldType.Text,
    render: (cellData: any, row: any) => {
      if (row && row.shift) {
        return <ShiftTypeStatic
          shiftTypeId={cellData.id as string}
        />
      } 
    },
  },
  {
    key: 'weather',
    label: 'Weather',
    type: FormTableFieldType.Text,
  },
]

const PartDRSTimeDetails = () => {
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  if (drs === null) {
    return null
  }
  const { project, drsProjectType, date } = drs.jobDetails
  const { id: projectId, projectInternalId, name: projectName, customer, } = project
  const { suburb, streetName, kmPlantToDepot, nearestCrossStreet, siteAllowance, weather } = drs.jobDetails.plan
  const { shiftType, crew } = drs.jobDetails.shift.plan
  function getTableData() {
    // only one row of data
    return [{
      id: 1,
      date: formatDateStringOrNullDDMMYYYY(date),
      shift: shiftType,
      weather: weather.description,
    }]
  }
  return <Wrapper>
    <FormPanel title={`Time Details`}>
      <FormPAETable<any>
        fields={Fields}
        data={getTableData()}
        showPAEType={false}
        showMoreActions={false}
      />
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default PartDRSTimeDetails