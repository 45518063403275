import React from 'react'
import styled from 'styled-components'
import CrewAutocomplete from '../autocompletes/CrewAutocomplete'
import SelectedCrews from '@src/modules/MgmtLabour/SelectedCrews'
import { useSnackbar } from '../GlobalSnackbar'

interface Props<T> {
  type: string,
  label: string,
  value: any[],
  onChange(items: T[]): void,
  extra?: {
    plantId?: string, // only for product auto complete
  },
}

function FormModalMultipleAutocomplete<T>(props: Props<T>) {
  const {
    type,
    label,
    value = [],
    onChange,
    extra,
  } = props
  switch (type) {
    case 'crews': {
      return <MultipleCrewAutocomplete values={value} onChange={onChange}/>
    }
  }
  return null
}

function MultipleCrewAutocomplete({ values, onChange }: { values: any[], onChange(items: any[]): void }) {
  return <MultipleCrewAutocompleteWrapper>
    <CrewAutocomplete
      selectedItem={{ value: '', label: '' }}
      onChange={(selected) => {
        const upcoming = selected.raw
        if (values.some((item) => item.id === upcoming.id)) { 
          // do nothing, which means not allowing duplicates
        } else {
          onChange([ ...values, upcoming ])
        }
      }}
    />
    <SelectedCrewBox>
      <SelectedCrews crews={values} onRemove={(crew, index) => {
        onChange([
          ...values.slice(0, index),
          ...values.slice(index + 1),
        ])
      }}/>
    </SelectedCrewBox>
  </MultipleCrewAutocompleteWrapper>
}
const MultipleCrewAutocompleteWrapper = styled.div``
const SelectedCrewBox = styled.div`
  margin-top: 20px;
`


export default FormModalMultipleAutocomplete