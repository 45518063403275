import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import MemberTable from './MemberTable'
import { FloatActionButton } from '../../components'
import MemberDialog from './MemberDialog'
import { useRSMgmtStore } from './Store'
import { useAppStore } from '../../AppStore'
import { apiProfileSearchAdvanced } from '@src/apis/profile-search-advanced'
import { observer } from 'mobx-react-lite'
import usePermission from '@src/utils/hooks/usePermission'
import Breadcrumbs from '@src/components/Breadcrumbs'
import { useBundle } from '@src/utils/hooks/useBundle'
import { CONST_ROLES_SYSTEM_ACCESS, CONST_ROLES_LABOUR, CONST_ROLES_WO_ADMIN, CONST_CREW_MEMBER_ROLE_OPTIONS, CONST_SYSTEM_ACCESS_ROLE_OPTIONS } from '@src/constants/roles-enum'

function MgmtLabourPage({

}) {
  const [isOpen, setIsOpen] = useState<any>(false)
  const [page, setPage] = useState<number>(1)
  const [paginPageCount, setPaginPageCount] = useState<number>(10)
  const [userType, setUserType,] = useState<string>('Crew Member')
  const [roleOptions, setRoleOptions,] = useState<{ value: string, label: string }[]>(CONST_CREW_MEMBER_ROLE_OPTIONS)
  const [isLoading, setIsLoading] = useState<any>(true)
  const [dialogLabour, setDialogLabour] = useState<AIMS.Profile>({} as AIMS.Profile)
  const [members, setMembers] = useState<any>([])
  const { depot, active, setActive, roleIds, setRoleIds, labourSortOrder, labourAscDesc, search, setSearch } = useRSMgmtStore()
  const { regionId } = useAppStore()
  const [refreshToken, setRefreshToken] = useState<any>(0)
  const { canCreateEditLabour, canViewEditSystemAccessUsers } = usePermission()
  const limit: number = 1000
  const [ bundle ] = useBundle()
  useEffect(() => {
    setIsLoading(true)
    setRefreshToken(refreshToken + 1)
    setPage(1)
  }, [depot, labourSortOrder, labourAscDesc, roleIds, active, search])

  useEffect(() => {
    let offset: number;
    { page === 1 ? offset = 0 : offset = limit * (page - 1) }

    setIsLoading(true)
    const fetch = async () => {
      const searchNames: boolean = true
      const handleProfileDepotType: boolean = true
      let depotId

      if (depot.value !== 'all') {
        depotId = depot.value
      }

      let role: string = roleIds.join(',')

      const result = await apiProfileSearchAdvanced({
        search,
        depotId,
        regionId,
        role,
        roleIds,
        active,
        searchNames,
        handleProfileDepotType,
        orderField: labourSortOrder,
        orderDirection: labourAscDesc,
        limit,
        offset,
      })
      if (result.data) {
        setMembers(
          result.data.profiles
          // .slice(0, 20)
        )
        //console.log(`result.data.profiles.length=${JSON.stringify(result.data.profiles.length, null, 2)}`)
        //console.log(`result.data.pagination=${JSON.stringify(result.data.pagination, null, 2)}`)
        if (result.data.pagination && result.data.pagination.totalRows) {
          const pageCount: number = Math.ceil(result.data.pagination.totalRows / limit)
          setPaginPageCount(pageCount)
        }
        //console.log(`MgmtLabourPage result.data.profiles=${JSON.stringify(result.data.profiles, null, 2)}`)
        setMembers(result.data.profiles)
        setIsLoading(false)
      }
    }

    fetch()
  }, [refreshToken, page])

  const handleUpdateSuccess = () => {
    setRefreshToken(refreshToken + 1)
  }
  const handleSuccess = () => {
    setRefreshToken(refreshToken + 1)
  }

  const handleOpenProfile = (profile: any) => {
    //console.log(`MgmtLabourPage handleOpenProfile() profile=${JSON.stringify(profile, null, 2)}`)
    setIsOpen(false)
    setDialogLabour(profile)
    setTimeout(() => {
      setIsOpen(true)
    }, 500)
  }
  const handleAddNewLabour = () => {
    setIsOpen(true)
    const emptyProfile = {} as AIMS.Profile;
    setDialogLabour(emptyProfile)
  }
  const handleEditLabour = (labour: AIMS.Profile) => {
    setIsOpen(true)
    setDialogLabour(labour)
  }
  const handlePageChange = (event: object, page: number) => {
    //console.log(`MgmtLabourPage handlePageChange() event=${JSON.stringify(event, null, 2)}`)
    //console.log(`MgmtLabourPage handlePageChange() page=${JSON.stringify(page, null, 2)}`)
    setPage(page)
  }
  /*
  const handlePageChange = (event: React.ChangeEvent<{ value: any }>, page: number) => {
    //console.log(`MgmtLabourPage handlePageChange() event=${JSON.stringify(event, null, 2)}`)
    //console.log(`MgmtLabourPage handlePageChange() page=${JSON.stringify(page, null, 2)}`)
    setPage(page)
  } */
  const handleSearchTextChange = (event: React.ChangeEvent<{ value: string }>) => {
    //console.log(`handleChange() value=${event.target.value}`)
    setSearch(event.target.value)
  }
  const handleActiveChange = (event: React.ChangeEvent<{ value: string | null }>) => {
    if (event.target.value == "") {
      setActive(null)
    } else {
      const isTrueSet = (event.target.value === 'true')
      setActive(isTrueSet)
    }
  }
  const handleRoleIdsChange = (event: React.ChangeEvent<{ value: string | null }>) => {
    //console.log(`MgmtLabourPage handleRoleIdsChange() event.target.value=${event.target.value}`)
    //console.log(`MgmtLabourPage handleRoleIdsChange() event=${JSON.stringify(event, null, 2)}`)
    const roleIds: string | null = event.target.value
    if (roleIds) {
      const roleIdsArray: string[] = roleIds.split(',')
      setRoleIds(roleIdsArray)
      if (roleIds === CONST_ROLES_WO_ADMIN.join(",")) {
        //console.log(`MgmtLabourPage handleRoleIdsChange() ROLES_LABOUR`)
        setUserType('Crew Member')
        setRoleOptions(CONST_CREW_MEMBER_ROLE_OPTIONS)
      } else {
        //console.log(`MgmtLabourPage handleRoleIdsChange() ROLES_SYSTEM_ACCESS`)
        setUserType('Systems Access')
        setRoleOptions(CONST_SYSTEM_ACCESS_ROLE_OPTIONS)
      }
    }
  }
  const renderControls = () => {
    return <ControlsWrapper>
      <SearchWrapper>
        <TextField id="test_search" label="Search" variant="outlined" fullWidth={true} onChange={handleSearchTextChange} />
      </SearchWrapper>
      {canViewEditSystemAccessUsers &&
        <React.Fragment>
          <StatusWrapper>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="gender" name="active" value={active} onChange={handleActiveChange}>
                <FormControlLabel value={true} control={<Radio />} label="Enabled" />
                <FormControlLabel value={false} control={<Radio />} label="Disabled" />
                <FormControlLabel value={null} control={<Radio />} label="All" />
              </RadioGroup>
            </FormControl>
          </StatusWrapper>
          <UserTypeWrapper>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="userType" name="userType" value={roleIds.join(",")} onChange={handleRoleIdsChange}>
                <FormControlLabel value={CONST_ROLES_WO_ADMIN.join(",")} control={<Radio />} label="Labour" />
                <FormControlLabel value={CONST_ROLES_SYSTEM_ACCESS.join(",")} control={<Radio />} label="System Access" />
              </RadioGroup>
            </FormControl>
          </UserTypeWrapper>
        </React.Fragment>
      }
    </ControlsWrapper>
  }
  const renderParts = () => {
    // console.log(`MgmtLabourPage renderParts() canCreateEditLabour=${canCreateEditLabour}`)
    return <React.Fragment>
      <StyledBox>
        <Pagination
          count={paginPageCount}
          page={page}
          siblingCount={3}
          boundaryCount={1}
          onChange={handlePageChange}
        />
      </StyledBox>
      <MemberTable
        members={members}
        onEditLabour={handleEditLabour}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <StyledBox>
        <Pagination
          count={paginPageCount}
          page={page}
          siblingCount={3}
          boundaryCount={1}
          onChange={handlePageChange}
        />
      </StyledBox>
    </React.Fragment >
  }
  return <Container maxWidth='xl'>
    <Breadcrumbs items={[{ type: 'text', text: `Labour Management` }]} />
    <AssetMgmtHeader>ASSET MANAGEMENT: LABOUR</AssetMgmtHeader>
    <MainWrapper>
      {renderControls()}
      {isLoading ? <SpinnerWrapper><Spinner size={80} /></SpinnerWrapper> : renderParts()}
    </MainWrapper>
    <MemberDialog
      // @ts-ignore
      labour={dialogLabour}
      hireCompanies={bundle.hirecompanies}
      open={isOpen}
      userType={userType}
      roleOptions={roleOptions}
      onClose={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      onUpdateSuccess={handleSuccess}
      onCreateSuccess={handleSuccess}
      onOpenProfile={handleOpenProfile}
    />
    {canCreateEditLabour &&
      <FloatActionButton
        tooltipText={`Add ${userType}`}
        onClick={handleAddNewLabour}
      />
    }
  </Container>
}

const SearchWrapper = styled.div`
  width: 20%;
`

const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`

const UserTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`

const ControlsWrapper = styled.div`
  display: flex;
`

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
`

export const AssetMgmtHeader = styled(Typography)`
  flex-grow: 1;
  font-size: 36px;
  font-weight: bold;
  color: #009688;
`

const MainWrapper = styled.div`
  margin: 40px 50px;
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Spinner = styled(CircularProgress)`
  position: center;
  margin-top: 80px;
`

export default observer(MgmtLabourPage)