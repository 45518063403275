import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useSRR } from '../useSRR'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiSrrSubmit } from '@src/apis/srr-submit'
import { SRRStatus } from '@src/types/SRRStatus'
import CompImportSifToSrrModal from './ImportSifToSrrModal'
import useModal from '../../../components/FormModal/useModal'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const CompSRRActionMenu = (props: Props) => {
  const {

  } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()
  const [srr, srrLoading, refresh] = useSRR()
  const { serialId } = useAppStore()
  const { alertError, alertDone } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const beforeSubmit = async () => {
    if (srr === null) {
      return
    }
    try {
      const shiftDateString = srr.job.shiftDetails.shiftDate !== null ? formatDateStringDDMMYYYY(srr.job.shiftDetails.shiftDate) : ''
      await confirm({
        title: `Are you sure you want to submit Shift Resource Requirement for ${shiftDateString}`,
        message: `Submitting the Shift Resource Requirement will populate the Daily reporting shift for this shift`,
        confirmButtonText: `Submit`,
      })
      submitSRR()
    } catch (e) {

    }
  }
  const submitSRR = async () => {
    setAnchorEl(null)
    console.log('submitting')
    try {
      const result = await apiSrrSubmit({
        srrId: srr!.id,
        serialId,
      })
      alertDone()
      refresh()
    } catch (e) {
      alertError()
    }
  }

  const beforeImport = async () => {
    if (srr === null) {
      return
    }
    try {
      await confirm({
        title: `Are you sure you want to continue?`,
        message: `Importing another SIF will override all information in the sections Job, Site information & Site Diagram`,
        confirmButtonText: `Yes, Link another SIF`,
      })
      importSIF()
    } catch (e) {

    }
  }
  const importSIF = () => {
    openAndSetData({
      srrId: srr.id,
      projectId: srr.job.projectDetails.projectId,
      projectInternalId: srr.job.projectDetails.projectInternalId,
      projectName: srr.job.projectDetails.projectName,
    })
  }
  const onImportSuccess = () => {
    alertDone()
    setAnchorEl(null)
    closeModal()
    refresh()
  }
  return <Wrapper>
    {canEditSubmitSRR &&
      <IconButton
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
    }
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={handleClose}
    >
      <MenuItem onClick={beforeSubmit} disabled={srr.status.id !== SRRStatus.New}>
        <SListItemIcon>
          <SendIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Submit Shift Resource Requirement</Typography>
      </MenuItem>
      <MenuItem onClick={beforeImport}>
        <SListItemIcon>
          <SaveAltIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Import Site Inspection Form</Typography>
      </MenuItem>
    </Menu>
    {open &&
      <CompImportSifToSrrModal
        open={open}
        data={modalData}
        onClose={closeModal}
        onCancel={closeModal}
        onSuccess={onImportSuccess}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`

export default CompSRRActionMenu