import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const BoralSwitch = withStyles({
  switchBase: {
    color: '#e2e2e2',
    '&$checked': {
      color: '#009688',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#7fcbc2',
      },
    }
  },
  checked: {},
  track: {
    opacity: 1,
    backgroundColor: '#c1c0c0',
  },
  thumb: {
    
  },
})(Switch)


export default BoralSwitch