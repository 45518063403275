import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import useModal from '../../../components/FormModal/useModal'
import usePermission from '@src/utils/hooks/usePermission'
import UndoIcon from '@material-ui/icons/Undo'
import DeleteIcon from '@material-ui/icons/Delete'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import FileCopyIcon from '@material-ui/icons/FileCopy'

export enum PAETableMoreActionType {
  Revert,
  MarkZero,
  Duplicate,
  Delete,
}

interface Props {
  actions: PAETableMoreActionType[],
  anchorEl: null | HTMLElement,
  onClose(): void,
  onRevert?(): void,
  onMarkZero?(): void,
  onDelete?(): void,
  onDuplicate?(): void,
}

const PAETableMoreActionsMenu = (props: Props) => {
  const {
    actions,
    anchorEl = null,
    onClose,
    onRevert = () => {},
    onMarkZero = () => {},
    onDelete = () => {},
    onDuplicate = () => {},
  } = props
  const menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()
  
  const beforeRevert = async() => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure?',
      })
      onRevert()
    } catch (e) {}
  }
  const beforeMarkZero = async() => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure?',
      })
      onMarkZero()
    } catch (e) {}
  }
  const beforeDelete = async() => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure?',
      })
      onDelete()
    } catch (e) {}
  }
  const beforeDuplicate = async() => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure?',
      })
      onDuplicate()
    } catch (e) {}
  }
  return <Wrapper>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={onClose}
    >
      { actions.includes(PAETableMoreActionType.Revert) &&
        <MenuItem onClick={beforeRevert}>
          <SListItemIcon>
            <UndoIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Revert to original values</Typography>
        </MenuItem>
      }
      { actions.includes(PAETableMoreActionType.MarkZero) &&
        <MenuItem onClick={beforeMarkZero}>
          <SListItemIcon>
            <ClearAllIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Mark all values as “0”</Typography>
        </MenuItem>
      }
      { actions.includes(PAETableMoreActionType.Duplicate) &&
        <MenuItem onClick={beforeDuplicate}>
          <SListItemIcon>
            <FileCopyIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Duplicate</Typography>
        </MenuItem>
      }
      { actions.includes(PAETableMoreActionType.Delete) &&
        <MenuItem onClick={beforeDelete}>
          <SListItemIcon>
            <DeleteIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Delete Row</Typography>
        </MenuItem>
      }
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`

export default PAETableMoreActionsMenu