import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Breadcrumbs from '@src/components/Breadcrumbs'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import LeftNavStickyWrapper from '@src/components/LeftNav/StickyWrapper'
import { NavDeepLink } from '@src/types/NavDeepLink'
import CompATPActionMenu from './parts/drawing-tools/comps/ActionMenu'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import Dialog from '@material-ui/core/Dialog'
import { useApproval } from './useApproval'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/AppStore'
import { ATP } from '@src/types/ATP'
import CompATPJob from './parts/1-Job/Job'
import CompATPSection from './parts/2-9-Maps/ATPSection'
import { apiATPSectionPDF } from '@src/apis/atp-section-pdf'
import { apiATPSubmit } from '@src/apis/atp-submit'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import useModal from '@src/components/FormModal/useModal'
import Button from '@material-ui/core/Button'
import FormModalRadioGroup from '@src/components/FormModal/RadioGroup'
import FormModalTextField from '@src/components/FormModal/Textfield'
import CircularProgress from '@material-ui/core/CircularProgress'
import LeftNavATP from '@src/components/LeftNav/LeftNavATP'

const ApprovalDetailPage = () => {
  const { serialId,username } = useAppStore()
  const { alert, alertError, alertDone } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  const { hash } = useLocation()
  const history = useHistory()
  const [drs, loading, refresh, refreshagain, sections,fetchCommentHeaders,allCommentsHeaders,commentsHeaders,atpAspetcts] = useApproval()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const [isSaving, setIsSaving] = useState<any>(false)
  const mapsData = sections === null ? [] : sections
  const primaryMapIndex: number = 1
  const [sectionType, setSectionTypeData] = useState<any>('Approval to Proceed');
  const [section, setSection] = useState<any>('');
  const [contractNo, setContractNo] = useState<any>('');
  const [holdPoint, setHoldPoint] = useState<any>('');
  const [customerRes, setCustomerRes] = useState<any>('');
  const [comment, setComment] = useState<any>('');

  const deepLinks: NavDeepLink<any>[] = [
    {
      anchor: 'Job',
      urlHash: 'job',
      key: 'job',
    },

    ...mapsData.map((atp: ATP, index: number) => {
      return {
        anchor: `ATP - ${(atp.id)} - `,
        urlHash: `atp${atp.id}`,
        key: `atp${atp.id }`,
        status: atp.status
      }
    })
  ]
  const [activeSection, setActiveSection] = useState<any>('job')
  const activeIndex = deepLinks.findIndex((link: NavDeepLink<any>) => link.key === activeSection)
  // deep linking
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1)
      if (deepLinks.some((link: NavDeepLink<any>) => link.urlHash === trimmedHash)) {
        setActiveSection(
          deepLinks.find((link: NavDeepLink<any>) => link.urlHash === trimmedHash)!
            .key
        )
        return
      }
    }
    setActiveSection('job')
  }, [id])
  // deep linking
  useEffect(() => {

    const found = deepLinks.find((link: NavDeepLink<any>) => link.key === activeSection)
    if (!found) {
      return
    }
    history.replace({
      hash: `#${found.urlHash}`
    })
  }, [activeSection])
  if (drs === null) {
    return null
  }
  const handleOnAnchorClick = async (index: number) => {
    
    setActiveSection(deepLinks[index].key)
  }
  function getCurrentAsph() {

    let asph: any = [];
    let currentAsph = drs.material.asphalt.detail;
    if(currentAsph.length > 0) {
      for(var i = 0;i < currentAsph.length;i++) {
        const data = currentAsph[i].plan;

		console.log(data.depth);

        asph.push({
          asphaltProcess: '',
          layer: data.asphaltLayer.id,
          layerThickness: parseFloat(data.depth),
          asphaltLotNoClauseNo: data.lotNumber,
          productId: data.product.description,
          plantId: data.product.plant.name
  
        });
      }
     return asph;
    }else{
      return asph;
    }
  
  }
  function getCurrentSpray() {
  
    let spray: any = [];
    let currentSpray = drs.material.spraySeal.detail;
    if(currentSpray.length > 0) {
      for(var i = 0;i < currentSpray.length;i++) {
        const data = currentSpray[i].plan;
		console.log("data.spraySealType.description ->> ", data.spraySealType.description)
		console.log("data.spraySealType.description ->> ", data.spraySealType.description)
        spray.push({
         	  existingSurface: data.existingSurfaceCondition,
              lotNoClauseNo: '',
              sprayProcess: data.spraySealType.description
        });
      }
      return spray;
    } else {
      return spray;
    }
  }
 
  const handleSubmitATP = async () => {
    try {
      setIsSaving(true)
      const postData: any = {
        serialId: serialId,
        agreedByCustomer: '',
        shiftId: parseInt(id),
        submittedBy: username,
        customerContact: customerRes,
        section: section,
        contractNo: contractNo,
        type:sectionType,
        holdPointNumber: holdPoint,
        aspectPreparationSurface: '',
        detailsComments: '',
        comments: comment,
        status: 'Pending',
        aspect:[],
        asphalt: getCurrentAsph(),
        spray: getCurrentSpray(),
        idempToken: new Date().getTime().toString()
      };
      // console.log(postData);
      // return;
      await apiATPSubmit(postData)
      await refresh()
       await refreshagain(id);
       setIsSaving(false)
      closeModal();
      alertDone();
    } catch (e) {
      await refresh()
      await refreshagain(id);
      alertError()
      setIsSaving(false)
    }
  }
  const handleAddATP = async () => {
    setCustomerRes('');
    setSection('');
    setHoldPoint('');
    setSectionTypeData('Approval to Proceed');
    setContractNo('');
    openAndSetData(null);
  }
 

 
  const handleDownloadPDFATP = async () => {
    const currentMapIndex = activeIndex - 1
    const atpID = sections[currentMapIndex].id
    try {
      const result = await apiATPSectionPDF({
        id: atpID,
        serialId,
      })
      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      const pdfName = `ATP-Section-${atpID}.pdf`
      link.setAttribute('download', pdfName)
      document.body.appendChild(link)
      link.click()
    } catch (e) {

    }
  }
  const renderPart = () => {
    switch (activeSection) {
      case 'job': {
        return <CompATPJob />
      }

      default: {
        return <CompATPSection
          activeIndex={activeIndex}
          primaryMapIndex={primaryMapIndex}
          shiftId={parseInt(id)}
          allCommentsHeaders={allCommentsHeaders}
          commentsHeaders={commentsHeaders}
          atpAspetcts={atpAspetcts}
          drsProjectType={drs.jobDetails.shift.drsProjectType.id}
        />
      }
    }
  }
  const { id: projectId, projectInternalId, projectName, } = drs.jobDetails.project
  const { id: shiftId, street, suburb } = drs.jobDetails.shift
  return <Wrapper maxWidth='xl'>
    <Breadcrumbs items={[
      { type: 'link', text: `Project ${projectId || projectInternalId}`, url: `/project/${projectInternalId}` },
      { type: 'text', text: `Approval to Proceed` },
    ]} />
    <MainWrapper>
      <LeftNavStickyWrapper top={16}>
        <LeftNavATP
          title={`Approval to Proceed`}
          statusName={''}
          sections={[
            {
              label: 'Project',
              value: `${projectId || projectInternalId} | ${projectName}`,
            },
            {
              label: 'Site',
              value: (suburb && street) ? `${suburb}, ${street}` : '',
            },
            {
              label: 'Shift',
              value: `${shiftId}`,
            }
          ]}
          anchors={deepLinks.map((link: NavDeepLink<any>) => link)}
          activeIndex={activeIndex}
          onAnchorClick={handleOnAnchorClick}
        // chipIndex={primaryMapIndex}
        // chipText={'Primary'}
        />
      </LeftNavStickyWrapper>
      <PartWrapper>
        {renderPart()}
        {activeIndex == 0 && <ActionWrapper>
          <CompATPActionMenu
            activeIndex={activeIndex}
            onSubmitATP={handleAddATP}

           
            onDownloadPDFMap={handleDownloadPDFATP}
          />
        </ActionWrapper>}
      </PartWrapper>
    </MainWrapper>
    {open && 
      <Dialog
        fullWidth={true}

        open={open}
        onClose={closeModal}
      >
        <DialogTitle>
          <ModalTitle>{"Add Section"}</ModalTitle>
        </DialogTitle>
        <DialogContent>
          <SectionWrapper2>
            <FormModalTextField
              label={'Section/Chainage'}
              id={'section'}
              defaultValue={section}
              

              helperText={"Enter Section/Chainage"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSection(e.target.value)
               }}

            />
            <FormModalTextField
              label={'Contract No'}
              id={'contractno'}
              defaultValue={contractNo}


              helperText={"Enter Contract No"
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setContractNo(e.target.value)
               }}

            />
            <FormModalTextField 
              label={'Customer Representative Name'}
              id={'custRName'}
              defaultValue={customerRes}

			  helperText={"Enter Customer Representative Name"
              }

              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCustomerRes(e.target.value)
               }}

            />
            
             
          </SectionWrapper2>
          <SectionWrapper style={{ marginTop: '15px', marginBottom: '15px' }}>
            <FormModalRadioGroup label='Type' value={sectionType} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              //console.log(e.target.value);
              setSectionTypeData(e.target.value)
            }} options={[{ value: 'Approval to Proceed', label: 'Approval to Proceed' }, { value: 'Hold Point Release', label: 'Hold Point Release' }, { value: 'Witness Point', label: 'Witness Point' }]} key={'a'} />

          </SectionWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>
            Cancel
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmitATP}

          >
               {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
          {!isSaving && 'SAVE '}
          </Button>
        </DialogActions>
      </Dialog>
    }
  </Wrapper>
}

const Wrapper = styled(Container)`

`
const MainWrapper = styled.div`
  display: flex;
`
const PartWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  flex-grow: 1;
`
const ActionWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: -6px;
`
const SectionWrapper2 = styled.div`
  display: column;
  width:400px;
`
const PrimarySetter = styled.div`
  position: absolute;
  left: 232px;
  top: 3px;
  background: #F2F8F7;
  border-radius: 8px;
  padding-left: 14px;
`
const SectionWrapper = styled.div`
  display: flex;
`
const ModalTitle = styled.div`
  font-size: 24px;
`
const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`

export default observer(ApprovalDetailPage)
