import React, { useState } from 'react'
import styled from 'styled-components'
import { Profile } from '@src/types/common/Profile'
import LabourAutocomplete from '@src/components/autocompletes/LabourAutocomplete'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

interface Props {
  open: boolean,
  onClose(): void,
  onSave(profile: Profile): void,
}

const CompDRSAddCrewMemberDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
  } = props
  const [selectedItem, setSelectedItem] = useState<{
    value: string,
    label: string,
    raw: Profile | null,
  }>({
    value: '',
    label: '',
    raw: null,
  })
  return <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>
      Add New Crew Member
    </DialogTitle>
    <DialogContent>
      <LabourAutocomplete
        id='New Crew Member'
        label='Crew Member'
        onChange={(item: any) => {
          setSelectedItem(item)
        }}
        selectedItem={selectedItem}
        active={true}
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      <Button
        color='primary'
        variant='contained'
        onClick={() => {
          if (selectedItem.raw) {
            onSave(selectedItem.raw)
          }
        }}
      >
        {`Save`}
      </Button>
    </DialogActions>
  </Dialog>
}

const Wrapper = styled.div`

`

export default CompDRSAddCrewMemberDialog