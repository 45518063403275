import React, { useRef } from 'react'
import styled from 'styled-components'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useSnackbar } from '@src/components/GlobalSnackbar'

const AcceptingFileMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
const HTMLInputAccept = AcceptingFileMimeTypes.join(',') // 'image/jpeg, image/png, application/pdf'

interface Props {
  onSelect(files: FileList): void,
}

const UploadTrigger = (props: Props) => {
  const { onSelect } = props
  const fileInputRef = useRef<any>(null)
  const { alert } = useSnackbar()
  const handleFilesSelected = () => {
    if (fileInputRef) {
      checkFileTypesBeforeHandOver(fileInputRef.current.files)
    }
  }
  const checkFileTypesBeforeHandOver = (files: any) => {
    files = Array.from(files)
    const validFiles = files.filter((file: File) => AcceptingFileMimeTypes.includes(file.type))
    const inValidFiles = files.filter((file: File) => !AcceptingFileMimeTypes.includes(file.type))
    if (inValidFiles.length) {
      const inValidFileNames = inValidFiles.map((file: File) => `'${file.name}'`).join(', ')
      alert({
        type: 'warning',
        message: `${inValidFileNames} in the wrong file format. Please upload only .JPEG, .PNG or .PDF file formats`,
      })
    }
    // console.log(inValidFiles)
    if (validFiles.length) {
      onSelect(validFiles)
    }
  }
  return <Wrapper>
    <Label htmlFor='input-sif-upload'>
      <AttachmentIcon />
      Attachments
    </Label>
    <FileInput
      ref={fileInputRef}
      accept={HTMLInputAccept}
      id='input-sif-upload'
      multiple
      type='file'
      onChange={handleFilesSelected}
    />
  </Wrapper>
}

const Wrapper = styled.div`
  height: 40px;
  background: #F8F8F8;
  color: #0A9688;
  margin-bottom: 10px;
`
const Label = styled.label`
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  svg {
    position: relative;
    top: 6px;
  }
`
const FileInput = styled.input`
  display: none;
`

export default UploadTrigger