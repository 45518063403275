import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import TabSection from '../../comps/TabSectionWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import _get from 'lodash/get'
import TableCell from '../../comps/TableCell'
import TableHeadCell from '../../comps/TableHeadCell'
import { useDRS } from '@src/modules/DRS/useDRS'
import { getTotalHours } from '@src/modules/DRS/legacy/Create/sections/6-LabourMembers'
import { formatHoursToDecimal } from '@src/modules/DRS/legacy/Create/comps/f-LabourMemberTable'

const TabSectionLabour = ({
  isLast,
}: {
  isLast: boolean,
}) => {
  const { drs } = useDRS()
  const crewName = _get(drs, `jobDetails.plan.crew.name`)
  // count if he/she has email
  const labourMembers = _get(drs, `labour.detail`, [])
  const labourCount = labourMembers.filter((item: DRS.DRSLabourRow) => {
    return item.plan && item.email
  }).length
  const totalHours = getTotalHours(drs as DRS.RootData)

  return <TabSection
    name='Labour Preview'
    isLast={isLast}
  >
    <InnerWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Count</TableHeadCell>
            <TableHeadCell>Hours</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{crewName}</TableCell>
            <TableCell>{labourCount}</TableCell>
            <TableCell>{formatHoursToDecimal(totalHours)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </InnerWrapper>
  </TabSection>
}

const InnerWrapper = styled.div`
  min-height: 44px;
`

export default observer(TabSectionLabour)