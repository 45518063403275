import React, { useState } from 'react'
import styled from 'styled-components'
import FormTable, { FormTableField } from '@src/components/Form/Table'
import FormPanel from '@src/components/FormPanel/FormPanel'
import Modal from '../../../../../../components/FormModal/Modal'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { MaterialAsphaltProductFields } from './fields'
import useModal from '@src/components/FormModal/useModal'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import MarginTop from '@src/components/Layout/MarginTop'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { apiSrrMaterialProductCreate } from '@src/apis/srr-material-product-create'
import { JobType } from '@src/types/JobType'
import { useAppStore } from '@src/AppStore'
import { useParams } from 'react-router'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrMaterialProductUpdate } from '@src/apis/srr-material-product-update' 
import { apiSrrMaterialProductRemove } from '@src/apis/srr-material-product-remove'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

type MAP = MaterialAsphaltProduct

const CompAsProduct = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const { serialId } = useAppStore()
  const fields = MaterialAsphaltProductFields
  const [ srr, srrLoading, refresh ] = useSRR()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<MAP>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.material.asphalt.products

  const hanldeRemoveRow = async (row: MaterialAsphaltProduct) => {
    try {
      await confirm({
        title: `Confirm deletion of Asphalt Product`,
        message: `This will delete the Asphalt Product from this Shift Resource requirement AND Pre-DRS`,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: MaterialAsphaltProduct) => {
    try {
      const result = await apiSrrMaterialProductRemove({
        srrId: id,
        serialId,
        jobType: JobType.Asphalt,
        productId: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  const hanldeEditRow = (row: MAP) => {
    console.log(row)
    openAndSetData(row)
  }
  const handleSave = async (form: any) => {
    console.log(form)
    if (modalData === null) {
      try {
        const result = await apiSrrMaterialProductCreate({
          srrId: id, 
          serialId,
          jobType: JobType.Asphalt,
          partialProduct: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrMaterialProductUpdate({
          srrId: id, 
          serialId,
          jobType: JobType.Asphalt,
          partialProduct: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  // hide fields
  const hiddenFieldKeys: string[] = ['comments']
  const finalFields = fields.filter((field: FormTableField<MAP>) => {
    return !hiddenFieldKeys.includes(field.key)
  })
  return <Wrapper>
    <FormPanel title={`Asphalt Product Details`}>
      <FormTable<MAP>
        fields={finalFields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      { canEditSubmitSRR && 
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Asphalt Material Details
          </CompCreateButton>
        </MarginTop>
      }
      { open && <Modal<MAP>
        maxWidth='md'
        open={open}
        title='Asphalt Product'
        data={{
          rowData: modalData,
          fields: fields,
          columns: [
            {
              title: 'Product Details',
              fieldKeys: [
                'asphaltLayer',
                'plant',
                'product',
                'customerSpecificMix', 
              ],
            },
            {
              title: 'Specifications',
              fieldKeys: [
                'depth',
                'area',
                'tonnes',
                'lotNumber',
                'controlLine',
              ],
            },
            {
              title: 'Quote Details',
              fieldKeys: [
                'quoteItem',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}


const Wrapper = styled.div`

`

export default CompAsProduct