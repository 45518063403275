import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  siteMapId: number,
  vmpId: number,
  roleId: RoleId,
  serialId: number,
}

export const apiVmpCopyToVmp = (options: Options) => {
  const { siteMapId, vmpId, roleId, serialId } = options
  const data = {

  }
  // `POST /v2/vmp/123/sites?reqRole=RESOURCE_SCHEDULER&reqSid=812&reqDev=web&siteMap={Site Map ID}`
  const axOptions = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/vmp/${vmpId}/sites`,
    data,
    params: {
      reqSid: serialId,
      reqRole: roleId,
      reqDev: 'web',
      siteMap: siteMapId 
    }
  }
  return ax(axOptions)
}