const ASPHALT = 'ASPHALT'
const SPRAY_SEAL = 'SPRAY-SEAL'
const ASPHALT_SPRAY_SEAL = 'ASPHALT-SPRAY-SEAL'

const VALUES = [ 'ASPHALT', 'SPRAY-SEAL', 'ASPHALT-SPRAY-SEAL' ]

export const CONST_PROJECT_TYPES_ENUM_VALUES = VALUES

const TYPES = {
  'ASPHALT': ASPHALT,
  'SPRAY-SEAL': SPRAY_SEAL,
  'ASPHALT-SPRAY-SEAL': ASPHALT_SPRAY_SEAL,
}

export const CONST_PROJECT_TYPES_ENUM = TYPES

/**
 * Asphalt & Spray Seal
Asphalt
Spray Seal
 */

const LABELS: { [key: string]: string } = {
  'ASPHALT': 'Asphalt',
  'SPRAY-SEAL': 'Spray Seal',
  'ASPHALT-SPRAY-SEAL': 'Asphalt & Spray Seal',
}
export const getProjectTypeLabel = (id: string) => {
  if (!VALUES.includes(id)) {
    return ''
  }
  return LABELS[id]
}