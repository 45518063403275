import React from 'react'
import styled from 'styled-components'

export interface TextareaProps {
  value: string,
  readonly?: boolean,
}

const Textarea = (props: TextareaProps) => {
  const { value, readonly = false } = props
  if (readonly) {
    return <Readonly>
      {value}
    </Readonly>
  }
  return <textarea value={value}></textarea>
}

// min-hegith for at least 3 lines
const Readonly = styled.div`
  background-color: #F8F8F8;
  font-size: 16px;
  border-radius: 8px;
  line-height: 24px;
  padding: 20px;
  min-height: 112px; 
  overflow-wrap: break-word;
`

export default Textarea