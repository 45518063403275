import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

interface Props {
  onClick(): void,
  children?: React.ReactNode,
  className?:string
  disable?:boolean
  id?:string
}
const CompThemeButton = (props: Props) => {
  const {
    onClick,
    children,
    className,
    disable,
    id

  } = props
  return <Wrapper
      className={className}
    onClick={onClick}
    disabled={disable ? disable : false}
    id={id}
  >
    { children }
  </Wrapper>
}

const Wrapper = styled(Button)`
  color: #009688;
  background-color: #E7F4F2;
` as typeof Button

export default CompThemeButton