import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { Region } from '@src/types/common/Region'
import { State } from '@src/types/common/State'
import { MobileAsset } from '@src/modules/MgmtMobileAsset/fields'


export const apiMobileAssetsUpdate = (asset: MobileAsset) => {
  const data = {
    ...asset,
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/mobileassets`,
    data,
  }
  return ax(options)
}