import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  id: number,
  serialId: number,
}

export const apiSifGetOne = (options: Options) => {
  const { id, serialId } = options
  const data = {

  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/sif/${id}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ResourceScheduler,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}