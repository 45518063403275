import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'

interface Options {
  drsId: string,
  serialId: number,
}

export const apiGetFilesByDRSId = ({
  drsId,
  serialId,
}: Options) => {
  // return Promise.resolve(Mocked)
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/documents/${PackFormType.DAILY_REPORTY_SHEET}/${drsId}`,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}