import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import SearchIcon from '@material-ui/icons/Search'
import { useDebounce } from '../../../utils/hooks'
import { useProjectFilter, FilterBy } from './ProjectFilterStore'

const ProjectSearchBar = () => {
  const inputRef = useRef<any>()
  const { filterBy, setFilterBy ,projectNumber, setProjectNumber } = useProjectFilter()
  const open: boolean = filterBy === FilterBy.TEXT
  const [ inputValue, setInputValue ] = useState<any>(filterBy === FilterBy.CONDITIONS ? '' : projectNumber)
  const handleClick = () => {
    if (!open) {
      setProjectNumber('')
      setInputValue('')
    } else {
      // toggleCondition()
    }
    setFilterBy(open ? FilterBy.CONDITIONS : FilterBy.TEXT)
  }
  const debouncedInputValue = useDebounce(inputValue, 200)

  useEffect(() => {
    setProjectNumber(debouncedInputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue])

  useEffect(() => {
    if (open && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [open]) 
  return (
    <React.Fragment>
      { open &&
        <Zoom in={open}>
          <TextField 
            id='ProductNumber' 
            type='text'
            inputRef={inputRef}
            value={inputValue} 
            onChange={e => {
              // handleChange(e.target.value)
              setInputValue(e.target.value)
            }} 
            placeholder='Project Number'
          />
        </Zoom>
      }
      
      <StyledIconWithLabelBox onClick={handleClick}>
        <IconButton
          aria-label='search bar for project number'
          color='inherit'
          size='small'
        >
          <SearchIcon />
        </IconButton>
        <Typography variant='body2'>Search</Typography>
      </StyledIconWithLabelBox>
    </React.Fragment>
  )
}

const StyledIconWithLabelBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  button {
    display: block;
  }
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`

export default observer(ProjectSearchBar)