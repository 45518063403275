import moment from 'moment'
import { FormModalTimePickerFormat } from '@src/components/FormModal/TimePicker'

const FORMAT = 'HH:mm'

export function formatTimeStringOrNullHHmm(timeString: string | null) {
  if (timeString === null) {
    return ''
  }
  return moment(timeString, FormModalTimePickerFormat).format(FORMAT)
}