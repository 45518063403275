import React, { useRef, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useCrewCalender } from "../StoreCrewCalender";
import WeekDays from "./WeekDays";
import useHover from "../../../../utils/hooks/useHover";
import Tooltip from "@material-ui/core/Tooltip";

interface Props {
  hidden: boolean;
  shiftNoteStyle: string;
  shiftNewStyle: string;
}
const WeekToolbar = ({ hidden, shiftNoteStyle, shiftNewStyle }: Props) => {
  const ref = useRef(null);
  const [hoverRef, isHovered] = useHover();
  const {
    isWeekView,
    batchStartDay,
    setBatchStartDay,
    isCalendarControlsDisabled,
    currentBatch,
  } = useCrewCalender();

  const handleNextOne = () => {
    setBatchStartDay(batchStartDay.clone().add(1, "days"));
  };
  const handleBackOne = () => {
    setBatchStartDay(batchStartDay.clone().subtract(1, "days"));
  };
  if (!isWeekView) return null;
  return (
    <Wrapper ref={ref}>
      <WeekDaysWrapper ref={hoverRef as any}>
        <React.Fragment>
          <BackOneStep isHovered={isHovered as boolean}>
            <Tooltip title={`Previous`} placement="top">
              <IconButton
                id="button-previous-day"
                size="small"
                disabled={isCalendarControlsDisabled}
                onClick={handleBackOne}
              >
                <KeyboardArrowLeft />
              </IconButton>
            </Tooltip>
          </BackOneStep>
          <NextOneStep isHovered={isHovered as boolean}>
            <Tooltip title={`Next`} placement="top">
              <IconButton
                id="button-next-day"
                size="small"
                disabled={isCalendarControlsDisabled}
                onClick={handleNextOne}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Tooltip>
          </NextOneStep>
        </React.Fragment>
        <WeekDays
          hidden={hidden}
          currentBatch={currentBatch}
          shiftNoteStyle={shiftNoteStyle}
          shiftNewStyle={shiftNewStyle}
        />
      </WeekDaysWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* display: flex; */
`;

const WeekDaysWrapper = styled.div`
  position: relative;
  background-color: #f5f5f5;
  padding-top: 12px;
`;
const BackOneStep = styled.div<{ isHovered: boolean }>`
  position: absolute;
  left: 0;
  top: 16px;
  visibility: ${(props) => (props.isHovered ? "visible" : "hidden")};
`;

const NextOneStep = styled.div<{ isHovered: boolean }>`
  position: absolute;
  right: 0;
  top: 16px;
  visibility: ${(props) => (props.isHovered ? "visible" : "hidden")};
`;

export default observer(WeekToolbar);
