import React, { useContext } from 'react'
import { observable, action } from 'mobx'

import { ATP } from '@src/types/ATP'

interface StoreATP {
  id: number | null,
  setId(id: number): void,
  drs: any | null,
  setDRS(drs: any): void,
  sections: ATP[] | null,
  setSections(atps: ATP[]): void,
  allCommentsHeaders:[],
  setAllCommentsHeaders(arr:[]): void,
  commentsHeaders:[],
  setCommentsHeaders(arr:[]): void
  atpAspetcts:[],
  setAtpAspects(arr:[]): void
}

interface fetchOptions {
  serialId: number, 
  srrId: number | string,
}

export const store: StoreATP = observable<StoreATP>({
  id: null,
  setId: action((id: number) => store.id = id),
  drs: null,
  setDRS: action((drs: any) => {
    
    store.drs = drs
  }),
  sections: null,
  setSections: action((atps: ATP[]) => {
    
    store.sections = atps
  }),
  allCommentsHeaders: null,
  setAllCommentsHeaders: action((arr: []) => {
    
    store.allCommentsHeaders = arr
  }),
  commentsHeaders: null,
  setCommentsHeaders: action((arr: []) => {
    
    store.commentsHeaders = arr
  }),
  atpAspetcts: null,
  setAtpAspects: action((arr: []) => {
    
    store.atpAspetcts = arr
  }),
  
})

const context: React.Context<StoreATP> = React.createContext<StoreATP>(store)

export const useStoreATP = () => {
  return useContext<StoreATP>(context)
}