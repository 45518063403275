import React from 'react'
import styled from 'styled-components'
import CompFlatTable from '@src/modules/SiteResoureRequirements/comps/FlatTable'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { SRRTransportAdditionalInfo } from '@src/types/SRR'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import useModal from '@src/components/FormModal/useModal'
import Modal from '@src/components/FormModal/Modal'
import { apiSrrTransportAdditionalInfoUpdate } from '@src/apis/srr-transport-additional-info-update'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { formatTimeStringOrNullHHmm } from '@src/types/format/timeStringOrNullHHmm'
import usePermission from '@src/utils/hooks/usePermission'
import { toValidTimeString } from '@src/utils/time/toValidTime'

interface Props {

}


const CompAdditionalTransportInfo = (props: Props) => {
  const {

  } = props
  const [srr, srrLoading, refresh] = useSRR()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data: SRRTransportAdditionalInfo = srr.machine.transport.additionalInformation

  const handleSave = async (form: any) => {
    console.log(form)
    const finalForm = {
      ...form,
      startTime: toValidTimeString(form.startTime, 'HH:mm'),
      lunchTime: toValidTimeString(form.lunchTime, 'HH:mm'),
      lastTruckTime: toValidTimeString(form.lastTruckTime, 'HH:mm'),
    }
    try {
      const result = await apiSrrTransportAdditionalInfoUpdate({
        srrId: srr.id,
        serialId,
        partial: {
          ...finalForm,
        }
      })
      alert({
        message: `Done.`
      })
      refresh()
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }

  return <Wrapper>
    <FormPanel
      title={`Additional Transport Information`}
    >
      <CompFlatTable<any>
        data={data}
        fields={SRRTransportAdditionalInfoFields}
        editable={canEditSubmitSRR}
        onEdit={() => {
          openAndSetData(data)
        }}
      />
      {open && <Modal<SRRTransportAdditionalInfo>
        maxWidth='xs'
        open={open}
        title='Additional Transport Information'
        data={{
          rowData: modalData,
          fields: SRRTransportAdditionalInfoFields,
          columns: [
            {
              title: 'Information',
              fieldKeys: [
                'startTime',
                'lunchTime',
                'lastTruckTime',
                'preLunchRate',
                'postLunchRate',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export const SRRTransportAdditionalInfoFields: FormTableField<SRRTransportAdditionalInfo>[] = [
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time (Mix on site)',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'lunchTime',
    type: FormTableFieldType.Time,
    label: 'Lunch Time',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'lastTruckTime',
    type: FormTableFieldType.Time,
    label: 'Last Truck on Site',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'preLunchRate',
    type: FormTableFieldType.Number,
    label: 'Delivery Rate before Lunch (tonnes / hr)',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'postLunchRate',
    type: FormTableFieldType.Number,
    label: 'Delivery Rate after Lunch (tonnes / hr)',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
]

export default CompAdditionalTransportInfo