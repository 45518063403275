import React from 'react'
import styled from 'styled-components'
import Link from '@src/components/Link'
import Typography from '@material-ui/core/Typography'
import aimsLogo from '@src/assets/aims-logo-with-text.png'

const Logo = () => {
  return <StyledLogoWrapper>
    <WhiteLink to='/'>
      <img src={aimsLogo} width='100' />
    </WhiteLink>
  </StyledLogoWrapper>
}

const StyledLogoWrapper = styled(Typography)`
  margin-right: 24px;
` as typeof Typography
const WhiteLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

export default Logo