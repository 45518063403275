import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Box from '@material-ui/core/Box'
import { Route } from 'react-router-dom'
import { CrewFilter } from '@src/modules/DashboardCalendar/CrewCalendarView/FilterInHeader'
import { MgmtLabourFilter } from '@src/modules/MgmtLabour'
import ProjectFilter from '@src/modules/HeaderFilters/ProjectFilter/ProjectFilter'
import { FilterPageType } from '@src/modules/MgmtLabour/Filter'
import { useSelectedShifts } from '@src/utils/hooks/StoreSelectedShifts'

const Center = () => {
  const { clearSelectedShifts } = useSelectedShifts()

  const handleClick = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    //console.log(`Center onClick()`)
    clearSelectedShifts()
  }

  return <StyledBox onClick={handleClick}>
    <Route exact path='/dashboard/projects' component={() => <ProjectFilter />} />
    <Route exact path='/dashboard/calendar' component={() => <CrewFilter />} />
    <Route exact path='/mgmt/labour' component={() => <MgmtLabourFilter filterPage={FilterPageType.Labour} />} />
    <Route exact path='/mgmt/crew' component={() => <MgmtLabourFilter filterPage={FilterPageType.Crew} />} />
    <Route exact path='/mgmt/mobile-asset' component={() => <MgmtLabourFilter filterPage={FilterPageType.MobileAssets} />} />
  </StyledBox>
}

const StyledBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

export default observer(Center)