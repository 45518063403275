import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

export const apiATPGetCommentHeaders = () => {

  const data = {

  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/atpheader`,
    data,
    params: {
      
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}