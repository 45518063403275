import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import BoralTextField from '../../../../../components/BoralTextField'
import Tooltip from '@material-ui/core/Tooltip'
import FormControl from '@material-ui/core/FormControl'
import _get from 'lodash/get'

const FieldEditableNumber = ({
  field,
  onChange,
}: DRS.Comp) => {
  const pulp = <FormControl fullWidth>
    <BoralTextField
      id={field.path}
      type='number' // only diff with text
      label={field.name}
      value={field.data || ''}
      onChange={(e: SyntheticEvent) => onChange((e.target as HTMLInputElement).value)}
    />
  </FormControl>

  let content
  const tooltip = _get(field, 'typeMeta.tooltip')
  if (tooltip) {
    // wrap with tooltip
    content = <Tooltip title={tooltip} placement='bottom-start'>
      {pulp}
    </Tooltip>
  } else {
    content = pulp
  }
  return <Wrapper>
    { content }
  </Wrapper>
}

const Wrapper = styled.div`
  padding-top: 8px;
`

const Content = styled.div``


export default observer(FieldEditableNumber)