import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Anchors from './Anchors'
import AnchorsATP from './AnchorsATP'

interface LeftNavProps {
  title: string,
  statusName: string,
  sections: LeftNavSectionProp[],
  anchors: any[],
  activeIndex: number,
  onAnchorClick?(index: number): void,
  chipIndex?: number,
  chipText?: string,
}

interface LeftNavSectionProp {
  label: string,
  value: string,
}

const LeftNavATP = (props: LeftNavProps) => {
  const {
    title,
    statusName,
    sections,
    anchors,
    activeIndex,
    onAnchorClick = () => { },
    chipIndex,
    chipText,
  } = props
  return <Wrapper>
    <Title>{title}</Title>
    {statusName &&
      <Status>{statusName}</Status>
    }
    {sections.map((section: LeftNavSectionProp) => {
      return <SectionWrapper key={section.label}>
        <Label>{section.label}</Label>
        <Value>{section.value}</Value>
      </SectionWrapper>
    })}
    <Divider />
    <AnchorsATP
      anchors={anchors}
      activeIndex={activeIndex}
      onClick={(anchor: any, index: number) => onAnchorClick(index)}
      chipIndex={chipIndex}
      chipText={chipText}
    />
  </Wrapper>
}

const Wrapper = styled.div`
  position: relative;
  width: 208px;
  background-color: #F8F8F8;
  padding: 14px 16px;
`
const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #000;
`
const Status = styled.div`
  position: relative;
  left: -2px;
  border-radius: 8px;
  line-height: 16px;
  color: #F9F9F9;
  font-size: 12px;
  background-color: #0A9688;
  display: inline-block;
  padding: 2px 6px;
  margin-bottom: 12px;
`
const SectionWrapper = styled.div`
  margin-bottom: 14px;
  :nth-last-child() {
    margin-bottom: 0px;
  }
`
const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  color: #000;
`

const Value = styled(Typography)`
  font-size: 14px;
  color: #000;
  overflow-wrap: break-word;
`
const Divider = styled.div`
  height: 1px;
  background-color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
`

export default LeftNavATP