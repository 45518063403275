import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import Axios from 'axios'


/**
 * 
 * Available request parameters:

- `state`: state id

 */

export const apiDepots = ({
  state,
  region,
}: {
  state?: string,
  region?: string
}) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/depots`,
    params: {
      state,
      region,
    }
  }
  return ax(options)
}