import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'

interface Options {
  id: number,
  formType: PackFormType,
  formId: number | string,
  roleId: string,
  serialId: number,
}

export const apiRemoveFile = ({
  id,
  formType,
  formId,
  roleId,
  serialId,
}: Options) => {
  const options = {
    method: 'DELETE',
    url: `${BASE_API_URL}/v2/documents/${formType}/${formId}/document/${id}`,
    params: {
      roleId,
      serialId,
    },
  }
  return ax(options)
}