import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  srrId: number,
  sifId: number,
  serialId: number,
}

export const apiSrrImportSif = (options: Options) => {
  const { 
    sifId, 
    srrId, 
    serialId,
  } = options
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}`,
    params: {
      sif: sifId,
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}