import React from 'react'
import styled from 'styled-components'
import CompFlatTable from '@src/modules/SiteResoureRequirements/comps/FlatTable'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import Modal from '@src/components/FormModal/Modal'
import { SRRExtraResources } from '@src/types/SRR'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import useModal from '@src/components/FormModal/useModal'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiSrrExtraResourcesUpdate } from '@src/apis/srr-extra-resources-update'
import MarginTop from '@src/components/Layout/MarginTop'
import Textfield from '@material-ui/core/TextField'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const ResourceExtraResources = (props: Props) => {
  const {

  } = props
  const [srr, srrLoading, refresh] = useSRR()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert, alertDone, alertError } = useSnackbar()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data: SRRExtraResources = srr.machine.resources.extraResources
  const handleSave = async (form: any) => {
    console.log(form)
    try {
      const result = await apiSrrExtraResourcesUpdate({
        srrId: srr.id,
        serialId,
        partial: {
          ...form,
        }
      })
      alertDone()
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  }
  return <Wrapper>
    <FormPanel
      title={`Extra Resources`}
    >
      <CompFlatTable<any>
        data={data}
        fields={
          SRRExtraResourcesFields.filter((field) => !['comment', 'accessComment', 'parkingComment'].includes(field.key))
        }
        editable={canEditSubmitSRR}
        onEdit={() => {
          openAndSetData(data)
        }}
      />
      <MarginTop>
        <FlexWrapper>
          <STextfield
            InputLabelProps={{ shrink: true }}
            multiline
            disabled
            variant='outlined'
            rows={3}
            label={`Comments`}
            value={data !== null && data.comment ? data.comment : ''}
          />
          <STextfield
            InputLabelProps={{ shrink: true }}
            multiline
            disabled
            variant='outlined'
            rows={3}
            label={`Comments on Machine Access`}
            value={data !== null && data.accessComment ? data.accessComment : ''}
          />
          <STextfield
            InputLabelProps={{ shrink: true }}
            multiline
            disabled
            variant='outlined'
            rows={3}
            label={`Comments on Machine Parking`}
            value={data !== null && data.parkingComment ? data.parkingComment : ''}
          />
        </FlexWrapper>
      </MarginTop>
      {open && <Modal<SRRExtraResources>
        maxWidth='md'
        open={open}
        title='Extra Resources'
        data={{
          rowData: modalData,
          fields: SRRExtraResourcesFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'lightingTower',
                'sprayer',
                'floatTrailer',
                'dogTrailer',
                'comboRoller',
                'loader',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'shuttleBuggy',
                'spreaderTruck',
                'backups',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'comment',
                'accessComment',
                'parkingComment',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div``
const FlexWrapper = styled.div`
  display: flex;
`
const STextfield = styled(Textfield)`
  &:not(:first-child) {
    margin-left: 20px;
  }
`

export const SRRExtraResourcesFields: FormTableField<SRRExtraResources>[] = [
  {
    key: 'lightingTower',
    type: FormTableFieldType.Number,
    label: 'Lighting Towers',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'sprayer',
    type: FormTableFieldType.Number,
    label: 'Sprayer',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'floatTrailer',
    type: FormTableFieldType.Number,
    label: 'Float/Tag Trailer',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'dogTrailer',
    type: FormTableFieldType.Number,
    label: 'Dog',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'comboRoller',
    type: FormTableFieldType.Number,
    label: 'Combo Roller',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'loader',
    type: FormTableFieldType.Number,
    label: 'Loader',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'shuttleBuggy',
    type: FormTableFieldType.Number,
    label: 'Shuttle Buggy',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'spreaderTruck',
    type: FormTableFieldType.Number,
    label: 'Spreader Trucks',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'backups',
    type: FormTableFieldType.Text,
    label: 'Backup Machine',
  },
  {
    key: 'comment',
    type: FormTableFieldType.Textarea,
    label: 'Comments',
  },
  {
    key: 'accessComment',
    type: FormTableFieldType.Textarea,
    label: 'Comments on Machine Access',
  },
  {
    key: 'parkingComment',
    type: FormTableFieldType.Textarea,
    label: 'Comments on Machine Parking',
  },
]


export default ResourceExtraResources