import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import Modal from '@src/components/FormModal/Modal'
import { SRRProfiler } from '@src/types/SRR'
import FormTable, { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { apiSrrProfilerCreate } from '@src/apis/srr-profiler-create'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { apiSrrProfilerUpdate } from '@src/apis/srr-profiler-update'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrProfilerRemove } from '@src/apis/srr-profiler-remove'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const MachineProfiling = (props: Props) => {
  const {

  } = props
  const [srr, srrLoading, refresh] = useSRR()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.machine.profiling.profilers
  const handleSave = async (form: any) => {
    console.log(form)
    if (modalData === null) {
      try {
        const result = await apiSrrProfilerCreate({
          srrId: srr.id,
          serialId,
          partial: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrProfilerUpdate({
          srrId: srr.id,
          serialId,
          partial: {
            ...form,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  const hanldeEditRow = (row: any) => {
    openAndSetData(row)
  }
  const hanldeRemoveRow = async (row: SRRProfiler) => {
    try {
      await confirm({
        title: `Confirm deletion`,
        message: ``,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: SRRProfiler) => {
    try {
      const result = await apiSrrProfilerRemove({
        srrId: srr.id,
        serialId,
        id: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel title={`Profiler`}>
      <FormTable<SRRProfiler>
        fields={SRRProfilerFields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      {canEditSubmitSRR &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Profiler
          </CompCreateButton>
        </MarginTop>
      }
      {open && <Modal<SRRProfiler>
        maxWidth='md'
        open={open}
        title='Profiler'
        data={{
          rowData: modalData,
          fields: SRRProfilerFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'provider',
                'workType',
                'machineSize',
                'area',
                'quantity',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'rapSuitable',
                'depth',
                'float',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'tipSite',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
export const SRRProfilerFields: FormTableField<SRRProfiler>[] = [
  {
    id: 'MachineProvider',
    key: 'provider',
    type: FormTableFieldType.Select,
    label: 'Traffic Control Provider',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    id: 'MachineProfilingWorkType',
    key: 'workType',
    type: FormTableFieldType.Select,
    label: 'Type of work (Circle)',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    id: 'ProfilingMachineSize',
    key: 'machineSize',
    type: FormTableFieldType.Select,
    label: 'Machine Size',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    key: 'area',
    type: FormTableFieldType.Number,
    label: 'Area (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'quantity',
    type: FormTableFieldType.Number,
    label: 'Quantity',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'depth',
    type: FormTableFieldType.Number,
    label: 'Depth (mm)',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    id: 'Answers',
    key: 'rapSuitable',
    type: FormTableFieldType.Select,
    label: 'Rap Suitable',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    key: 'tipSite',
    type: FormTableFieldType.Textarea,
    label: 'Tip Site (Address)',
  },
  {
    key: 'float',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Float within shift',
    format: formatBooleanToYesNo,
  },
]

export default MachineProfiling