import _set from 'lodash/set'
import _get from 'lodash/get'
import moment from 'moment'
import { getStartEndDiff, formatHoursToDecimal } from '../../Create/comps/f-LabourMemberTable'
import { isAS, isSS } from '../EditDRSStore'

export default function getTransportSectionData(data: DRS.RootData) {
  let panels: any[] = []
  if (isAS(data)) {
    panels = [
      getBoralTransport(true, data), 
      getBoralTransport(false, data), 
      ...getSubcontractor({
        isAs: true,
        isDelivery: true,
        data,
      }),
      ...getSubcontractor({
        isAs: true,
        isDelivery: false,
        data,
      }),  
    ]
  }
  if (isSS(data)) {
    panels = [ 
      ...panels, 
      ...getSubcontractor({
        isAs: false,
        data,
      }),
    ]
  }
  const section = {
    label: 'TRANSPORT',
    panels,
  }
  return section
}

function getBoralTransport(isDelivery: boolean, data: DRS.RootData) {
  const label = isDelivery ? 'Boral Transport - Asphalt (Delivery)' : 'Boral Transport - Asphalt (Profiling)'
  const path = isDelivery ?  `transport.boral.asphalt.delivery` : `transport.boral.asphalt.profiling`
  const panel = {
    label,
    repeatable: false,
    path,
    fields: getBoralTransportFields(path),
  }
  return panel
}

function getBoralTransportFields(path: string) {
  return [
    {
      label: 'Trucks',
      type: 'select',
      showOnlyPlan: true,
      absolutePath: `${path}.trucks`,
      format: (value: any) => value
        .filter((item: any) => item.plan)
        .map((item: any) => item.truck.description)
        .join(', '),
      editable: false,
    },
    {
      label: 'Ordered Tonnes per round',
      type: 'number',
      showOnlyPlan: true,
      path,
      relativePath: 'orderedTonnesPerRound',
      editable: false,
    },
  ]
}

function getSubcontractor({
  isAs,
  isDelivery = false,
  data,
}: {
  isAs: boolean,
  isDelivery?: boolean,
  data: DRS.RootData
}) {
  let label: string
  let path: string
  if (isAs) {
    if (isDelivery) {
      label = 'Subcontractor Transport - Asphalt (Delivery)'
      path = 'transport.contractor.asphalt.delivery'
    } else {
      label = 'Subcontractor Transport - Asphalt (Profiling)'
      path = 'transport.contractor.asphalt.profiling'
    }
  } else {
    label = 'Subcontractor Transport - Spray Seal'
    path = 'transport.contractor.spraySeal.delivery'
  }
  const detailData = _get(data, path)
  if (!detailData) return []
  const assets = detailData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'supplierName': '',
        'orderedTonnesPerRound': '',
        'startTime': '',
        'endTime': '',
        'docketNumber': '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getAssetFields(`${path}.${index}`),
    }
  })
  return assets
}

function getAssetFields(path: string) {
  return [
    {
      label: 'Supplier',
      type: 'text',
      path,
      relativePath: 'supplierName',
      editable: true,
    },
    {
      label: 'Weight (T)',
      type: 'number',
      path,
      relativePath: 'orderedTonnesPerRound',
      editable: true,
    },
    {
      label: 'Start Time',
      type: 'time',
      path,
      relativePath: 'startTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'End Time',
      type: 'time',
      path,
      relativePath: 'endTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'Hours',
      type: 'hours',
      path,
      relativePath: 'hours',
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const timeData: any = _get(rootData, `${panelPath}.${planActualOrEdit}`);
          const { startTime, endTime } = timeData;
          return formatHoursToDecimal(getStartEndDiff({
            start: startTime,
            end: endTime,
          }))
        } catch (e) {
          return 0
        }
      },
      editable: true,
      readonly: true,
      editReadonly: true,
      showTrending: true,
    },
    {
      label: 'Docket Number',
      type: 'text',
      path,
      relativePath: 'docketNumber',
      editable: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}