import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'


interface Options {
  assetId: string,
  serialId: number,
}
export const apiMobileAssetPaperPrestartsByAsset = ({
  assetId,
  serialId,
}: Options) => {
  const data = {
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/map`,
    data,
    params: {
      asset: assetId,
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
      paperPrestart:'y'
    }
  }
  return ax(options)
}