import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function FieldEditableCheckboxGroup ({
  field,
  onChange,
}: DRS.Comp) {
  // direction oneOf(['vertical', 'horizontal']),
  const { name, data, typeMeta } = field
  if (!typeMeta || !typeMeta.options || !typeMeta.options.length) {
    return null
  }
  const handleChange = (option: AIMS.ValueLabel) => () => {
    const { value, label } = option
    let final = data.slice()
    if (final.some((item: { id: string }) => item.id === value)) {
      final.splice(final.findIndex((item: { id: string }) => item.id === value), 1)
    } else {
      final.push({
        id: value
      })
    }
    onChange(final)
  }
  return <Wrapper>
    <FormControl>
      <FormLabel>{name}</FormLabel>
      <FormGroup row={typeMeta.direction === 'horizontal'}>
        { typeMeta.options.map(option => {
          const { value, label } = option
          return <FormControlLabel
            key={value || ''}
            control={<Checkbox checked={data.some((item: { id: string }) => item.id === value)} onChange={handleChange(option)} value={value} />}
            label={label}
        />
        })}
        
      </FormGroup>
    </FormControl>
    
  </Wrapper>
}

const Wrapper = styled.div`
  /* padding-top: 14px; */
`

interface Options {
  direction?: string, //PropTypes.oneOf(['vertical', 'horizontal']),
  options: AIMS.ValueLabel[],
  selectedItems: any[],
  onChange(items: any[]): void,
  disabled?: boolean,
}

export function CheckboxGroup ({
  direction = 'vertical',
  options = [],
  selectedItems = [],
  onChange = (items) => {},
  disabled = false,
}: Options) {
  const handleChange = (option: AIMS.ValueLabel) => () => {
    const { value, label } = option
    let final = selectedItems.slice()
    if (final.some(item => item.id === value)) {
      final.splice(final.findIndex(item => item.id === value), 1)
    } else {
      final.push({
        id: value
      })
    }
    onChange(final)
  } 
  return <FormGroup row={direction === 'horizontal'}>
    { options.map(option => {
      const { value, label } = option
      return <FormControlLabel
        key={value || ''}
        control={<Checkbox 
          disabled={disabled}
          checked={selectedItems.some(item => item.id === value)} onChange={handleChange(option)} value={value} />}
        label={label}
    />
    })}
    
  </FormGroup>
}

export default observer(FieldEditableCheckboxGroup)