import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { CrewNote, Shift } from '@src/types/Shift'
import { Crew } from '@src/types/Crew'
import { useCrewCalender } from '../StoreCrewCalender'
import ShiftDialog from './ShiftDialog'
import { DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import NoteDialog from './NoteDialog'
import CompCrewNoteDialog from './CrewNoteDialog'
import CompLeaveNoteDialog from './LeaveNoteDialog'

interface Props {
  open: boolean,
  onClose(): void,
  onSave(): void,
  data: null | Shift,
  crewOptions: Crew[],
  originCrew: Crew,
  originDate: string,
  setShiftOptionsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const ShiftOptionsDialog = (props: Props) => {
  const {
    open,
    onClose,
    data,
    originCrew,
    originDate,
    setShiftOptionsDialogOpen,
  } = props
  const isAdd = data === null
  const isEdit = !isAdd

  const { crews, goRefresh } = useCrewCalender()
  const [isShifDialogOpen, setShiftDialogOpen] = useState<boolean>(false)

  const [isCrewDialogOpen, setCrewDialogOpen] = useState<boolean>(false)
  const [isLeaveNoteDialogOpen, setLeaveNoteDialogOpen] = useState<boolean>(false)

  const [crewNoteData, setCrewNoteData] = useState<null | CrewNote>(null);
  const handleClick = () => {
    setShiftDialogOpen(true)

  }
  const handleNoteClick = () => {
    setCrewDialogOpen(true);
    // setShiftOptionsDialogOpen(false)

  }
  const handleLeaveNoteClick = () => {
    console.log("LeaveNote Clicked")
    setLeaveNoteDialogOpen(true);
    // setShiftOptionsDialogOpen(false)

  }
  return (
    <>
      <ShiftDialog
        open={isShifDialogOpen}
        data={data}
        crewOptions={crews}
        originCrew={originCrew}
        originDate={originDate}
        onClose={() => {
          setShiftDialogOpen(false)
          setShiftOptionsDialogOpen(false)
          goRefresh();
        }}
        onSave={() => {
          setShiftOptionsDialogOpen(false)
          setShiftDialogOpen(false)
          goRefresh();
        }}
      />
      {isCrewDialogOpen &&
        <CompCrewNoteDialog
          open={isCrewDialogOpen}
          data={crewNoteData}
          originCrew={originCrew}
          originDate={originDate}
          onClose={() => {
            setCrewDialogOpen(false)
            goRefresh();
            onClose();
          }}
          onSave={() => {
            onClose();
            setCrewDialogOpen(false)
            goRefresh();
          }}
        />
      }
        {isLeaveNoteDialogOpen &&
        <CompLeaveNoteDialog
          open={isLeaveNoteDialogOpen}
          data={null}
        crewOptions={crews}
        originCrew={originCrew}
        originDate={originDate}
        onClose={() => {
          setShiftDialogOpen(false)
          setShiftOptionsDialogOpen(false)
          goRefresh();
        }}
        onSave={() => {
          setShiftOptionsDialogOpen(false)
          setShiftDialogOpen(false)
          goRefresh();
        }}
        />
      }
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={onClose}
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <ButtonOptions onClick={handleClick}
            color='primary'
            variant="contained">
            Add Shift
          </ButtonOptions>
          <ButtonOptions onClick={handleNoteClick}
            color='primary'
            variant="contained">
            Create Note
          </ButtonOptions>
          <ButtonOptions onClick={handleLeaveNoteClick}
            color='primary'
            variant="contained">
            Leave Shift
          </ButtonOptions>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={(e: React.SyntheticEvent<any>) => {
            e.stopPropagation()
            // resetDRS()
            onClose()
          }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* <NoteDialog/> */}
    </>)
}

const ButtonOptions = styled(Button)`
  margin: 15px;

`

export default ShiftOptionsDialog