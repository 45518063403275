import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import CrewCalendarView from '@src/modules/DashboardCalendar/CrewCalendarView/CrewCalendarView'
import AssetMgmt from '../Dashboard/parts/AssetMgmt'

interface Props {
  shiftNoteStyle:string,
  noteClickEvent():void
}

const DashboardCalendar = ({
  shiftNoteStyle,
  noteClickEvent
}:Props) => {
  return (
    <Wrapper>
      <CrewCalendarView shiftNoteStyle={shiftNoteStyle} noteClickEvent={noteClickEvent} />
      {/* <AssetMgmt /> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`
export default DashboardCalendar