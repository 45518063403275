import React from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import STab from '@src/components/Tab'

interface STabsProps {
  labels: string[],
  value: number,
  onClick(label: string, index: number): void,
}

const STabs = (props: STabsProps ) => {
  const { labels, value, onClick } = props
  
  const handleClick = (e: any, index: number) => {
    onClick(labels[index], index)
  }
  
  return <Tabs 
    value={value} 
    onChange={handleClick}
  >
    { 
      labels.map((label: string) => {
        return  <STab key={label} label={label}></STab>
      })
    }
  </Tabs>
}

export default STabs