import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PersonIcon from '@material-ui/icons/Person'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ListIcon from '@material-ui/icons/List'
import WarningIcon from '@material-ui/icons/Warning'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import CollectionsIcon from '@material-ui/icons/Collections'
import DirectionsIcon from '@material-ui/icons/Directions'
import DescriptionIcon from '@material-ui/icons/Description'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { PackFormType, formatPackFormType } from '@src/types/PackFormType'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import cameraIcon from '@src/assets/icon_camera.png'
import pdfIcon from '@src/assets/pdf_file_icon.png'
export interface FormCardProps {
  person: string,
  attachment: string,
  camCounter?: string,
  pdfCounter?: string,
  images: string,
  status: string,
  currentIndex: number,
  total?: number,
  formType: PackFormType,
  onClick?(): void,
  action?: string,
  onAction?(action: string): void
  onPrevious?(): void,
  onNext?(): void,
}

const FormCard = (props: FormCardProps) => {
  const {
    person,
    attachment,
    camCounter,
    pdfCounter,
    images,
    status,
    currentIndex,
    total,
    formType,
    onClick,
    action,
    onAction,
    onPrevious,
    onNext,
    
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleOnMore = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    setAnchorEl(e.target as HTMLElement)
  }
  const handleOnAction = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (onAction) {
      onAction(action || '')
      setAnchorEl(null)
    }
  }
  const handlePrevious = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    if (onPrevious)
      onPrevious()
  }
  const handleNext = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    if (onNext)
      onNext()
  }
  function renderIcon() {
    switch (formType) {
      case PackFormType.DAILY_REPORTY_SHEET:
        return <SAssignmentIcon />
      case PackFormType.SITE_INSPECTION_FORM: {
        return <SSearchIcon />
      }
      case PackFormType.SHIFT_RESOURCE_REQUIREMENT: {
        return <ListIcon />
      }
      case PackFormType.MOBILITY_ASSET_PRESTARTS: {
        return <LocalShippingIcon />
      }
      case PackFormType.HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION: {
        return <WarningIcon />
      }
      case PackFormType.VEHICLE_MOVEMENT_PLAN: {
        return <DirectionsIcon />
      }
      case PackFormType.QUANTITY_AGREEMENT_SHEET: {
        return <CheckCircleIcon />
      }
      case PackFormType.SITE_MEMO_INSTRUCTION: {
        return <DescriptionIcon />
      }
	  case PackFormType.CREW_PACK_PHOTO: {
		  return <CollectionsIcon />
	  }
    }
    return null
  }
  return <Wrapper onClick={onClick}>
    <Header>
      {renderIcon()}
      <Title>{formatPackFormType(formType)}</Title>
      {total &&
        <NavIcons>
          <ShiftNo>
            {`${currentIndex + 1}/${total}`}
            {total !== 1 &&
              <React.Fragment>
                <NavIconButton
                  disabled={total === 1}
                  size='small'
                  onClick={handlePrevious} >
                  <KeyboardArrowLeft fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
                </NavIconButton>
                <NavIconButton
                  disabled={total === 1}
                  size='small'
                  onClick={handleNext} >
                  <KeyboardArrowRight fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
                </NavIconButton>
              </React.Fragment>
            }
          </ShiftNo>
        </NavIcons>
      }
      {action &&
        <SMoreVertIcon onClick={handleOnMore} />
      }
      <Menu
        id='form-card-action-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnAction}>{action}</MenuItem>
      </Menu>
    </Header>
    <BottomWrapper>
      <InfoWrapper>
        {person &&
          <InfoRow>
            <PersonIcon />
            {person}
          </InfoRow>
        }
      
      </InfoWrapper>
      {status && <Status>{status}</Status>}
     
        
      
        {(pdfCounter || camCounter ) && <InfoRow >
          {pdfCounter && <CounterElePDF>
            <img style={{display:'inline-block'}} src={pdfIcon} width='35' />
             <PDFCountLabel >{pdfCounter}</PDFCountLabel> 
          </CounterElePDF>
          
          }
          { camCounter &&  <CounterEleCam  pdfIcon={pdfCounter ? 1 : 0}>
              <img style={{display:'inline-block'}} src={cameraIcon} width='24' />
              <CountLabel >{camCounter}</CountLabel> 
            </CounterEleCam>
             }
            
          </InfoRow>
          }
    </BottomWrapper>
  </Wrapper>
}

FormCard.defaultProps = {
  currentIndex: -1,
}

const Wrapper = styled.div`
  width: 200px;
  height: 240px;
  background-color: #F9F9F9;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  color: #fff;
  background-color: #129688;
  padding: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 90px;
`
const Title = styled.div`
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
  margin-left: 6px;
  margin-right: 6px;
`
const CounterElePDF = styled.div`
 display:inline-block;
 position:relative;
 width:45%;
 margin-left:7px;
`
const CounterEleCam = styled.div<{ pdfIcon: number }>`
 display:inline-block;
 position:relative;
 width:45%;
 text-align:right;
 margin-right:7px;
 ${props => {
  if (!props.pdfIcon) {
    return css`
    width:97%;;
    `
  }
}}
`
const CountLabel = styled.div`
display: inline-block;
font-size: 16px;
font-weight: bold;
margin-top: -12px;

vertical-align: middle;
margin-left: 5px;
`
const PDFCountLabel = styled.div`
display: inline-block;
font-size: 16px;
font-weight: bold;

left: 46px;

vertical-align: top;
margin-left: 5px;
`
const SSearchIcon = styled(SearchIcon)`
  font-size: 18px;
  margin: 2px;
`
const SAssignmentIcon = styled(AssignmentIcon)`

`
const SMoreVertIcon = styled(MoreVertIcon)`
  font-size: 18px;
  cursor: pointer;
`
const InfoWrapper = styled.div`
  padding: 12px;
`
const Status = styled.div`
  border-radius: 8px;
  line-height: 16px;
  color: #F9F9F9;
  font-size: 12px;
  background-color: #0A9688;
  padding: 2px 6px;
  display: inline-block;
  width: fit-content;
  margin-left: 12px;
`
const InfoRow = styled.div`
  svg {
    font-size: 12px;
    margin-right: 16px;
  }
`
const BottomWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ShiftNo = styled.div`
  font-size: 12px;
  height: 30px;
  line-height: 30px;
`

const NavIcons = styled.div`
  white-space: nowrap;
`

const NavIconButton = styled(IconButton)`
  color: #F5F5F5;
`
export default FormCard