import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { useTabs, ASSSTabs, getTabsByType } from '@src/components/Tabs/useTabs'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import { ProjectTypeId } from '@src/types/ProjectTypeId'
import TabsWrapper from '@src/modules/SiteResoureRequirements/comps/TabsWrapper'
import { useDRS } from '../../useDRS'
import DRSSectionMaterialAspahltItems from './parts/MaterialAsphaltItems'
import DRSSectionMaterialAspahltOthers from './parts/MaterialAsphaltOther'
import DRSSectionMaterialSpraySealItems from './parts/MaterialSpraySealItems'
import DRSSectionMaterialSpraySealOthers from './parts/MaterialSpraySealOther'

interface Props {

}

const PartDRSMaterial = (props: Props) => {
  const {

  } = props
  const [ tabIndex, tabs, handleTabChange, setTabs ] = useTabs(ASSSTabs)
  const { drs } = useDRS()
  useEffect(() => {
    if (drs !== null) {
      setTabs(getTabsByType(drs.jobDetails.drsProjectType.id))
    }
  }, [drs])
  
  const renderAsOrSS = () => {
    const currentTab: Tab = tabs[tabIndex]
    if (currentTab.key === ProjectTypeId.Asphalt) {
      return <React.Fragment>
        <DRSSectionMaterialAspahltItems />
        <DRSSectionMaterialAspahltOthers />
      </React.Fragment>
    } else {
      return <React.Fragment>
        <DRSSectionMaterialSpraySealItems />
        <DRSSectionMaterialSpraySealOthers />
      </React.Fragment>
    }
  }

  return <Wrapper>
    <FormSectionTitle>Material</FormSectionTitle>
    <TabsWrapper>
      <TabsComp 
        tabs={tabs} 
        activeIndex={tabIndex}
        onChange={handleTabChange}
      />
    </TabsWrapper>
    { renderAsOrSS() }
  </Wrapper>
}

const Wrapper = styled.div`

`
export default PartDRSMaterial