import React from 'react'
import styled from 'styled-components'
import CompSsProduct from './SsProduct/SsProduct'
import CompSsOther from './SsOther/SsOther'

interface Props {

}

const CompSsMaterial = (props: Props) => {
  const {

  } = props
  return <Wrapper>
    <React.Fragment>
      <CompSsProduct />
      <CompSsOther />
    </React.Fragment>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompSsMaterial