import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import _set from 'lodash/set'
import _get from 'lodash/get'
import getJobDetailSectionData from './sections/1-JobDetail-config'
import getReworkSectionData from './sections/2-Rework-config'
import getMaterialSectionData from './sections/3-Material-config'
import getTransportSectionData from './sections/4-Transport-config'
import getMobileAssetsSectionData from './sections/5-MobileAssets-config'
import getLabourSectionData from './sections/6-Labour-config'
import getQARequirementsSectionData from './sections/7-QA-config'
import getSummarySectionData from './sections/8-Summary-config'
import { CONST_PROJECT_TYPES_ENUM } from '../../../../constants/project-types-enum'

function getCurrentSectionData(data: DRS.RootData, sectionName: string) {
  if (sectionName === 'JOB_DETAIL') {
    return getJobDetailSectionData(data)
  }
  if (sectionName === 'REWORK') {
    return getReworkSectionData(data)
  }
  if (sectionName === 'MATERIAL') {
    return getMaterialSectionData(data)
  }
  if (sectionName === 'TRANSPORT') {
    return getTransportSectionData(data)
  }
  if (sectionName === 'MOBILE_ASSETS') {
    return getMobileAssetsSectionData(data)
  }
  if (sectionName === 'LABOUR') {
    return getLabourSectionData(data)
  }
  if (sectionName === 'QA_REQUIREMENTS') {
    return getQARequirementsSectionData(data)
  }
  if (sectionName === 'SUMMARY') {
    return getSummarySectionData(data)
  }
}

const store: any = observable({
  highlightedSection: 'JOB_DETAIL',
  setHighlightedSection: action((val: string) => store.highlightedSection = val),
  get currentSectionData() {
    return getCurrentSectionData(store.data, store.highlightedSection)
  },
  get highlightedSectionData() {
    if (!store.data) {
      return
    }
    const Mapping: { [key: string]: string } = {
      'JOB_DETAIL': 'jobDetails',
      'REWORK': 'rework',
      'MATERIAL': 'materials',
      'TRANSPORT': 'transport',
      'MOBILE_ASSETS': 'mobileAssets',
      'LABOUR': 'labour',
      'QA_REQUIREMENTS': 'qaRequirements',
      'SUMMARY': 'summary',
    }
    return store.data[Mapping[store.highlightedSection]]
  },
  data: undefined,
  setData: action((data: any) => store.data = data),
  update: action(({
    fieldPath,
    value,
  }: {
    fieldPath: string,
    value: any,
  }) => {
    try {
      _set(store.data, fieldPath, value)
    } catch (err) {
      console.log('can not set DRS data', fieldPath, value)
    }
  }),

})


export function isAS(data: DRS.RootData) { 
  const typeData: any = _get(data, `jobDetails.drsProjectType`);
  const typeId: any = typeData.id || 'ASPHALT-SPRAY-SEAL'
  return typeId === CONST_PROJECT_TYPES_ENUM.ASPHALT 
    || typeId === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL'] 
}
export function isSS(data: DRS.RootData) { 
  const typeData:any = _get(data, `jobDetails.drsProjectType`);
  const typeId: any = typeData.id || 'ASPHALT-SPRAY-SEAL'
  return typeId === CONST_PROJECT_TYPES_ENUM['SPRAY-SEAL'] 
    || typeId === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL'] 
}

const context = React.createContext(store)

export const useEditDRSStore = () => {
  return useContext(context)
}

