import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useApproval } from '../../useApproval'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatProjectTypeId } from '@src/types/ProjectTypeId'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { CrewFlat } from '@src/modules/DRS/legacy/Create/comps/f-JobCrew'
import { Crew } from '@src/types/Crew'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { DRSPageType } from '@src/modules/DRS/DRSPage'
import { ShiftTypeStatic } from '@src/modules/DRS/legacy/Create/comps/f-ShiftType'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'

interface Props {

}

const CompATPJob = (props: Props) => {
  const {

  } = props
  const [ drs ] = useApproval()
  if (drs === null) {
    return null
  }
  interface ShiftLocationDetail {
    streetName: string,
    nearestCrossStreet: string,
    suburb: string,
    kmPlantToDepot: string,
  }

  const FieldsLocation: FormTableField<ShiftLocationDetail>[] = [
    {
      key: 'streetName',
      label: 'Street',
      type: FormTableFieldType.Text,
    },
    {
      key: 'nearestCrossStreet',
      label: 'Nearest Cross Street',
      type: FormTableFieldType.Text,
      length: 200,
      helperTextError: 'Max 200 character allow'
    },
    {
      key: 'suburb',
      label: 'Suburb',
      type: FormTableFieldType.Text,
    },
    {
      key: 'kmPlantToDepot',
      label: 'Kms from Plant',
      type: FormTableFieldType.Number,
      maxScale: 2,
      helperTextError: `only 2 decimal places allowed`,
    },
  ]
  const FieldsShift: FormTableField<any>[] = [
    {
      key: 'projectName',
      label: 'Project Name',
      type: FormTableFieldType.Text,
    },
    {
      key: 'projectNumber',
      label: 'Project Number',
      type: FormTableFieldType.Text,
    },
    {
      key: 'customerName',
      label: 'Customer',
      type: FormTableFieldType.Text,
    },
    {
      key: 'jobType',
      label: 'Job Type',
      type: FormTableFieldType.Text,
    },
    {
      key: 'crew',
      label: 'Crew',
      type: FormTableFieldType.Text,
      render: (cellData: any, row: any) => {
        if (cellData) {
          return <CrewFlat crew={cellData as Crew} /> 
        }
      }
    },
  ]
  const FieldsTime: FormTableField<any>[] = [
    {
      key: 'date',
      label: 'Date',
      type: FormTableFieldType.Text,
    },
    {
      key: 'shift',
      label: 'Shift',
      type: FormTableFieldType.Text,
      render: (cellData: any, row: any) => {
        if (row && row.shift) {
          return <ShiftTypeStatic
            shiftTypeId={cellData.id as string}
          />
        } 
      },
    },
    {
      key: 'weather',
      label: 'Weather',
      type: FormTableFieldType.Text,
    },
  ]
  const { project, drsProjectType, date } = drs.jobDetails
  const { id: projectId, projectInternalId, name: projectName, customer, } = project
  const { shiftType, crew } = drs.jobDetails.shift.plan
  const { suburb, streetName, kmPlantToDepot, nearestCrossStreet, siteAllowance, weather } = drs.jobDetails.plan

  function getTableDataShift() {
   
    return [{
      id: 1,
      projectName: projectName || '',
      projectNumber: (`${projectId || projectInternalId}`) || '',
      customerName: customer ? customer.name : '',
      jobType: formatProjectTypeId(drsProjectType.id),
      crew: crew,
    }]
  }
  function getTableDataLocation() {
    if (drs === null) {
      return []
    }
    const mappedPAEData = {
      id: drs.jobDetails.id,
      plan: {
        streetName: drs.jobDetails.plan.streetName,
        nearestCrossStreet: drs.jobDetails.plan.nearestCrossStreet,
        suburb: drs.jobDetails.plan.suburb,
        kmPlantToDepot: drs.jobDetails.plan.kmPlantToDepot,
      },
      actual: {
        streetName: drs.jobDetails.actual.streetName,
        nearestCrossStreet: drs.jobDetails.actual.nearestCrossStreet,
        suburb: drs.jobDetails.actual.suburb,
        kmPlantToDepot: drs.jobDetails.actual.kmPlantToDepot,
      },
      edit: {
        streetName: drs.jobDetails.edit.streetName,
        nearestCrossStreet: drs.jobDetails.edit.nearestCrossStreet,
        suburb: drs.jobDetails.edit.suburb,
        kmPlantToDepot: drs.jobDetails.edit.kmPlantToDepot,
      },
    }
    function isEditAvailableFunc(actual: any, edit: any) {
      if (edit.streetName !== null
        || edit.nearestCrossStreet !== null
        || edit.suburb !== null
        || edit.kmPlantToDepot !== null
      ) {
        return true
      }
      return false
    }
    const doubled = doubleUp(
      [mappedPAEData],
      DRSPageType.Plan,
      isEditAvailableFunc,
    )
    return doubled.map((data: any) => {
      if (data._type_ === PAETableRowType.Second) {
        return {
          ...data,
          _more_actions_: [
            PAETableMoreActionType.Revert,
            // PAETableMoreActionType.MarkZero,
          ],
        }
      }
      return data
    })
    
  }
  function getTableDataTime() {
    // only one row of data
    return [{
      id: 1,
      date: formatDateStringOrNullDDMMYYYY(date),
      shift: shiftType,
      weather: weather.description,
    }]
  }
  return <Wrapper>
    <FormSectionTitle>JOB</FormSectionTitle>
    <Wrapper>
    <FormPanel title={`Shift Details`}>
      <FormPAETable<any>
        fields={FieldsShift}
        data={getTableDataShift()}
        showPAEType={false}
        showMoreActions={false}
      />
    </FormPanel>
    </Wrapper>
    <Wrapper>
    <FormPanel title={`Shift Location Details`}>
      <FormPAETable<any>
        fields={FieldsLocation}
        data={getTableDataLocation()}
        editable={false}
        showPAEType={false}
        isSelectable={false}
      />
    </FormPanel>
    </Wrapper>
    <Wrapper>
    <FormPanel title={`Time Details`}>
      <FormPAETable<any>
        fields={FieldsTime}
        data={getTableDataTime()}
        showPAEType={false}
        showMoreActions={false}
      />
    </FormPanel>
  </Wrapper>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompATPJob
