import { ax } from '@src/utils/axios/axios'
import { BASE_API_URL } from '@src/constants'
import { SubcontractorGeneralAssetPAE } from '@src/types/drs/subcontractor/SubcontractorGeneral'
import { GenericApiOptions } from '../generics/GenericApiOptions'
import { NormalizedProjectType } from '@src/types/ProjectTypeId'

interface Options extends GenericApiOptions {
  drsId: number,
  normalizedType: NormalizedProjectType,
  assets: any[],
}

export function apiDRSSubcontractorGeneralAssetsRemove(options: Options) {
  const {
    drsId,
    normalizedType,
    assets,
    reqSid,
    reqRole,
  } = options

  const data = [
    ...assets,
  ]

  const params = {
    reqSid,
    reqRole,
    isDelete:'Y'
  }

  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/subcontractors/${drsId}/${normalizedType}/assets/delivery`, // delivery === general, backend naming convention
    data,
    params,
  }
  return ax(axOptions)
}