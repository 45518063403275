import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import usePermission from '@src/utils/hooks/usePermission'
import PDFIcon from '@material-ui/icons/PictureAsPdf'

interface Props {
  activeIndex: number,
  onSubmitVMP(): void,
  onAddMap(): void,
  onRemoveMap(): void,
  onDuplicateMap(): void,
  onDownloadPDFMap(): void,
}

const CompVMPActionMenu = (props: Props) => {
  const {
    activeIndex,
    onSubmitVMP,
    onAddMap,
    onRemoveMap,
    onDuplicateMap,
    onDownloadPDFMap,
  } = props
  const hideDuplicateAction = activeIndex === 0
  const hideRemoveAction = activeIndex === 0
  const hidePDFAction = activeIndex === 0
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()
  const { serialId } = useAppStore()
  const { alertError, alertDone } = useSnackbar()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const beforeSubmit = async () => {
    try {
      await confirm({
        title: `Confirmation on Submit VMP`,
        message: `Sure?`,
        confirmButtonText: 'Submit',
      })
      onSubmitVMP()
      setAnchorEl(null)
    } catch(e) {

    }
  }

  const beforeRemove = async () => {
    try {
      await confirm({
        title: `Remove Site Map ${activeIndex}`,
        message: `Removing a site map will delete map, markings, rules & all information.`,
        confirmButtonText: 'Remove Site Map',
      })
      onRemoveMap()
      setAnchorEl(null)
    } catch(e) {

    }
  }
  const beforeDuplicate = async () => {
    try {
      await confirm({
        title: `Duplicate Site Map ${activeIndex}`,
        message: `Duplicating site map will replicate the map, markings, rules and any relevant information.`,
        confirmButtonText: 'Duplicate Site Map',
      })
      onDuplicateMap()
      setAnchorEl(null)
    } catch(e) {

    }
  }
  const handleAddMap = () => {
    onAddMap()
    setAnchorEl(null)
  }
  const handlePDF = () => {
    onDownloadPDFMap()
    setAnchorEl(null)
  }
  return <Wrapper>
    { true &&
      <IconButton
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
    }
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={handleClose}
    >
      <MenuItem onClick={beforeSubmit} disabled={false}>
        <SListItemIcon>
          <SendIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Submit VMP</Typography>
      </MenuItem>
      <MenuItem onClick={handleAddMap} disabled={false}>
        <SListItemIcon>
          <AddCircleIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Add Map</Typography>
      </MenuItem>
      { !hidePDFAction && <MenuItem onClick={handlePDF} disabled={false}>
        <SListItemIcon>
          <PDFIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>{`Download PDF`}</Typography>
      </MenuItem>
      }
      { !hideRemoveAction && <MenuItem onClick={beforeRemove}>
        <SListItemIcon>
          <SDeleteIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>{`Remove Site Map ${activeIndex}`}</Typography>
      </MenuItem>
      }
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
` 

export default CompVMPActionMenu