import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType, FormPAETableInteralData } from '@src/components/Form/PAETable'
import { DRSQaSpraySealModalFields, DRSQaSpraySealRequirementFields, DRSQaSpraySealTableFields, DRSQaSpraySealTableRow, DRSQaSpraySealModalForm } from './QaSpraySealFields'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage' 
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'
import { DRSQaSpraySealPAE } from '@src/types/drs/qa/QaSpraySeal'

const NULL_VALUES = {
  ballPenTesting: null,
  comments: null,
  compactionTesting: null,
  holdPointReleaseForm: null,
  jobSpecification: null,
  labTechOnsite: null,
  measureDipReport: null,
  sealDesign: null,
}

const DRSSectionQaSpraySeal = () => {
  const { id } = useParams<{id: string}>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone, } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<DRSQaSpraySealModalForm>({})
  if (drs === null || drs.qa.spraySeal === null) {
    return null
  }
  
  const onEditRow = (row: DRSQaSpraySealTableRow) => {
    openAndSetData({
      ...row,
      requirements: DRSQaSpraySealRequirementFields
          .filter((field) => row[field.key] === true)
          .map((field) => field.key)
    })
  }
  const handleRevert = (row: DRSQaSpraySealTableRow) => {
    // updatingColumn is edit here
    const retained = drs.qa.spraySeal
    const data = {
      ...retained,
      [updatingColumn]: {
        ...NULL_VALUES,
      }
    }
    update(data)
  }
  const handleSave = async (form: DRSQaSpraySealModalForm) => {
    const retained = drs.qa.spraySeal
    const data = {
      ...retained,
      [updatingColumn]: {
        ...form,
        ...DRSQaSpraySealRequirementFields
          .map((field) => {
            return {
              key: field.key,
              value: form.requirements.includes(field.key),
            }
          })
          .reduce((acc, curr) => {
            return {
              ...acc,
              [curr.key]: curr.value
            }
          }, {})
      },
    }
    update(data)
  }
  const update = (data: DRSQaSpraySealPAE) => {
    genericUpdateDRSSection<DRSQaSpraySealPAE>({
      sectionName: DRSSectionName.Quality,
      path: `spraySeal`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const getTableData = () => {
    const rows = [ drs.qa.spraySeal ]
    if (!rows || !rows.length) {
      return []
    }
    const doubled = doubleUp(rows, drsPageType) as DRSQaSpraySealTableRow[]
    const doubledWithActions = doubled.map((data: DRSQaSpraySealTableRow) => mapMoreActions(data, drsPageType, [PAETableMoreActionType.Revert]))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`QA Spray Seal`}>
      <FormPAETable<DRSQaSpraySealTableRow>
        fields={DRSQaSpraySealTableFields}
        data={getTableData()}
        editable={true}
        onEditRow={onEditRow}
        showMoreActions={isEdit}
        onRevert={handleRevert}
      />
    </FormPanel>

    { open && 
      <Modal<DRSQaSpraySealModalForm>
        maxWidth={'xs'}
        open={open}
        title='QA Spray Seal'
        data={{
          rowData: modalData,
          fields: DRSQaSpraySealModalFields,
          columns: [
            {
              title: 'QA',
              fieldKeys: [
                'jobSpecification',
                'requirements',
                'comments',
              ],
            },
          ],
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default DRSSectionQaSpraySeal