// 'paver', 'steelroller', 'multiroller', 'skidsteercombo', 'skidsteerbroom', 'suctionbroom', 'tractorbroom', 'watercart', 'linemarking', 'vmsboards'

export enum SRRResourceTypeId {
  Paver = 1,
  SteelRoller = 2,
  MultiRoller = 3,
  SkidSteerCombo = 4,
  SkidSteerBroom = 5,
  SuctionBroom = 6,
  TractorBroom = 7,
  WaterCart = 8,
  LineMarking = 9,
  VMSBoards = 10,
}