import React, { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

const NOT_FOUND_TEXT = 'Not found.'

function renderSuggestion(suggestionProps: {
  highlightedIndex?: number,
  index?: number,
  itemProps?: object,
  selectedItem?: any,
  suggestion: {
    label: string
  },
}) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  const isHighlighted = highlightedIndex === index
  let isSelected = false
  if (selectedItem && (selectedItem.label || '').indexOf(suggestion.label) > -1) {
    isSelected = true
  }

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component='div'
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}

// renderSuggestion.propTypes = {
//   highlightedIndex: PropTypes.number,
//   index: PropTypes.number,
//   itemProps: PropTypes.object,
//   selectedItem: PropTypes.any,
//   suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
// }

const Dropdown = ({
  isLoading,
  isOpen,
  options,
  dropdownAutoWidth,
  highlightedIndex,
  selectedItem,
  
  inputRef,
  getItemProps,
  getMenuProps,

  hasTopOption,
  topOptionLabel,
  onTopOptionClick,
}: any) => {
  if (isLoading) return null
  const length = options.length
  let content: ReactElement
  if (!length) {
    content = <MenuItem
      // {...getItemProps({ item: {} })}
      component='div'
    >
      { NOT_FOUND_TEXT }
    </MenuItem>
  } else {
    content = <React.Fragment>
      { hasTopOption && 
        <MenuItem 
        {...getItemProps({ 
          item: { 
            label: topOptionLabel,
            value: topOptionLabel,
          },
          onClick: onTopOptionClick,
        })}
        component='div'
        
        >
          {topOptionLabel}
        </MenuItem> 
      }
      <Divider component='div' />
      {options.map((suggestion: any, index: number) =>
        renderSuggestion({
          suggestion,
          index: hasTopOption ? index + 1 : index, // if we have top option, all the following options should have +1 index
          itemProps: getItemProps({ item: suggestion }),
          highlightedIndex,
          selectedItem,
        }),
      )}
    </React.Fragment>
    
  }

  let paperStyle: {
    width: any,
    minWidth?: any,
  } = {
    // marginTop: 8, 
    width: !dropdownAutoWidth && inputRef && inputRef.current ? inputRef.current.clientWidth : undefined 
  }
  if (dropdownAutoWidth) {
    paperStyle.minWidth = inputRef && inputRef.current ? inputRef.current.clientWidth : undefined
  }
  
  return <Popper
    open={isOpen}
    anchorEl={inputRef.current}
    style={{zIndex: 1300}}
    placement='bottom-start'
    transition
  >
  {({ TransitionProps }) => (
    <Fade {...TransitionProps} timeout={{appear: 200}}>
      <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
        <Paper
          square
          style={paperStyle}
        >
          { content }
        </Paper>
      </div>
    </Fade>
  )}
  
</Popper>
}


export default observer(Dropdown)