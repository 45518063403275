import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'

interface Options {
  id: number,
}

export const apiCrewDeactivate = ({
  id,
}: Options) => {
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/crews/${id}/deactivate`,
  }
  return ax(options)
}