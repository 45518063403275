import { Profile } from './common/Profile'
import { Region } from './common/Region'
import { State } from './common/State'
import { MobileAssetPrestartStatus } from './MobileAssetPrestartStatus'
import { Depot } from './common/Depot'
import { Shift, ShiftRaw } from './Shift'
import { Project } from './project/Project'

export interface MobileAssetPrestart {
  id: number,
  assetId: string,
  shift: ShiftRaw,
  preWorkSubmissionDate?:string,
  suburb:string,
  street:string,
  project: {
    projectInternalId: number,
    projectId: string,
    projectName: string,
  },
  status: {
    id: MobileAssetPrestartStatus,
    description: string,
  },
  date: string,
  preWorkOperator: Profile | null,
  postWorkOperator: Profile | null,
  cpFileName: string | null,
  fileLocation: string | null,
  assetKM: number,
  assetHours: null,
  condition: {
    id: 1,
    description: string,
  },
  issues: MobileAssetPrestartIssue[],
}

export enum MobileAssetPrestartIssue {
  PriorityList1 = 1,
  PriorityList2 = 2,
}
export function formatMobileAssetPrestartIssue(issue: MobileAssetPrestartIssue) {
  switch (issue) {
    case MobileAssetPrestartIssue.PriorityList1: 
      return 'Priority 1'
    case MobileAssetPrestartIssue.PriorityList2: 
      return 'Priority 2'
  }
}

export interface MobileAssetPrestartBrief {
  mapId: 6,
  asset: {
    id: string,
    description: string,
    active: true,
    type: {
      id: 1,
      description: string,
    },
    costingSystemAssetNumber: null,
    assetReadingKM: number,
	assetReadingHour: number,
    nextServiceDue: null,
    rego: null,
    error: null,
    errorDate: null,
    condition: null,
    depot: Depot,
    region: Region,
    state: State,
  },
  operator: Profile,
  status: {
    id: MobileAssetPrestartStatus,
    description: string,
  }
}