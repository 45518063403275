import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  id: number, // vmp id
  serialId: number,
}

export const apiATPGet = (options: Options) => {
  const { id, serialId } = options
  const data = {

  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/atp/byShift/${id}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}