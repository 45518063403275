import React from 'react'
import styled from 'styled-components'
import { DRS } from '@src/types/drs/DRS'
import { ProjectTypeId } from '@src/types/ProjectTypeId'
import TabSection from '@src/modules/DRS/legacy/Create/Right/comps/TabSectionWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '../../comps/TableCell'
import TableHeadCell from '../../comps/TableHeadCell'
import { SubcontractorItemPAE } from '@src/types/drs/subcontractor/Subcontractor'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import _get from 'lodash/get'
import { observer } from 'mobx-react-lite'

enum SubcontractorSubSection {
  AsphaltGeneralAssets,
  AsphaltProfilingAssets,
  AsphaltDeliveryTransport,
  AsphaltProfilingTransport,
  SpraySealGeneralAssets,
  SpraySealDeliveryTransport,
}

interface Props {
  drs: DRS,
  projectTypeId: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal,
}

const TabSectionSubcontractor = (props: Props) => {
  const {
    drs,
    projectTypeId,
  } = props
  const renderSub = (subSection: SubcontractorSubSection) => {
    let data = getData(subSection, drs)
    function getData(subSection: SubcontractorSubSection, drs: DRS): SubcontractorItemPAE[] {
      switch(subSection) {
        case SubcontractorSubSection.AsphaltGeneralAssets: return drs.subcontractor.asphalt ? drs.subcontractor.asphalt.generalAssets : []
        case SubcontractorSubSection.AsphaltProfilingAssets: return drs.subcontractor.asphalt ? drs.subcontractor.asphalt.profilingAssets : []
        case SubcontractorSubSection.AsphaltDeliveryTransport: return drs.subcontractor.asphalt ? drs.subcontractor.asphalt.deliveryTransport : []
        case SubcontractorSubSection.AsphaltProfilingTransport: return drs.subcontractor.asphalt ? drs.subcontractor.asphalt.profilingTransport : []
        case SubcontractorSubSection.SpraySealGeneralAssets: return drs.subcontractor.spraySeal ? drs.subcontractor.spraySeal.generalAssets : []
        case SubcontractorSubSection.SpraySealDeliveryTransport: return drs.subcontractor.spraySeal ? drs.subcontractor.spraySeal.deliveryTransport : []
      }
      return []
    }
    if (!data.length) {
      return null
    }
    return <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell colSpan={4}>{`${SubcontractorSubSection[subSection]}`}</TableHeadCell>
      </TableRow>
      <TableRow>
        <TableHeadCell>Sup</TableHeadCell>
        <TableHeadCell>Act</TableHeadCell>
        <TableHeadCell>Start</TableHeadCell>
        <TableHeadCell>Q/H</TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      { data.map((item: SubcontractorItemPAE) => {
        return (
          <TableRow key={item.id}>
            <TableCell>{item.plan.supplier ? item.plan.supplier.name : ''}</TableCell>
            <TableCell>{item.plan.supplierAgreement ? item.plan.supplierAgreement.itemDescription : ''}</TableCell>
            <TableCell>{item.plan.startTime ? formatDateStringHHmm(item.plan.startTime) : ''}</TableCell>
            <TableCell>{_get(item, 'plan.hours', _get(item, 'plan.quantity', ''))}</TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  </Table>
  }
  const render = () => {
    if (projectTypeId === ProjectTypeId.Asphalt) {
      return <React.Fragment>
        { renderSub(SubcontractorSubSection.AsphaltGeneralAssets) }
        { renderSub(SubcontractorSubSection.AsphaltProfilingAssets) }
        { renderSub(SubcontractorSubSection.AsphaltDeliveryTransport) }
        { renderSub(SubcontractorSubSection.AsphaltProfilingTransport) }
      </React.Fragment>
    } else {
      return <React.Fragment>
        { renderSub(SubcontractorSubSection.SpraySealGeneralAssets) }
        { renderSub(SubcontractorSubSection.SpraySealDeliveryTransport) }
      </React.Fragment>
    }
  }
  return (
    <TabSection
      name='Subcontractor Preview'
    >
    <InnerWrapper>
      { render() }
    </InnerWrapper>
    
  </TabSection>
  )
}

const Wrapper = styled.div`

`
const InnerWrapper = styled.div`
  min-height: 110px;
`

export default observer(TabSectionSubcontractor)
