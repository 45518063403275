import React from 'react'
import { ProjectDrsTable } from '..'
import { Shift } from '@src/types/Shift'

const ProjectCrewPacks = ({
  data,
}: {
  data: Shift[],
}) => {
  function mapToTableRow (shift: Shift) {
    return {
      id: shift.id,
      shiftDate: shift.date,
      street: shift.plan.street,
      suburb: shift.plan.suburb,
      shiftType: shift.plan.shiftType.id,
      jobType: shift.drsProjectType.id,
      crewName: shift.plan.crew.name || '',
      crewColourCode: shift.plan.crew.colourCode || '',
      status: shift.status.id,
      crewPackNumberOfPhotos:shift.crewPackNumberOfPhotos,
      crewPackNumberOfPdfs:shift.crewPackNumberOfPdfs,
      SIFStatus:shift.SIFStatus,
      SRRStatus:shift.SRRStatus,
      VMPStatus: shift.VMPStatus,
      HSEStatus: shift.HSEStatus,
      MAPStatus: shift.MAPStatus,
      SMIStatus: shift.SMIStatus,
      totalQas: shift.totalQas,
      crewPackSerialId: shift.crewPackSerialId,
      srrId: shift.srrId,
      drsId:shift.drsId,
      vmpId: shift.vmpId,
      hseId:shift.hseId
    }
  }
  return <ProjectDrsTable 
    rows={data.map(mapToTableRow)}
  />
}

export default ProjectCrewPacks