import { FormTableField, FormTableFieldType } from '../Form/Table'
import { useState, useEffect } from 'react'

interface K {
  // id: number | string,
  [key: string]: any,
}

export function useModalForm<T extends K>(fields: FormTableField<T>[], initValues: Partial<T> | null) {
  const [ form, setForm ] = useState<any>(null)
  function setField(fieldKey: string, value: any) {
    setForm({
      ...form,
      [fieldKey]: value,
    })
  }
  // initialize form field with value according to type of field
  useEffect(() => {
    if (initValues === null) {
      const emptyValues: { [key in keyof T ]: any } = {} as T
      fields.forEach((field: FormTableField<T>) => {
        if (field.type === FormTableFieldType.Select) {
          emptyValues[field.key] = null
        } else if (field.type === FormTableFieldType.CheckboxGroup) {
          emptyValues[field.key] = []
        } else if (field.type === FormTableFieldType.MultipleAutocomplete) {
          emptyValues[field.key] = []
        } else {
          emptyValues[field.key] = ''
        }
      })
      setForm(emptyValues)
    } else {
      console.log(initValues)
      const values: { [key in keyof T ]: any } = {} as T
      Object.keys(initValues).forEach((key: keyof T) => {
        if (initValues[key] === null) {
          const foundField = fields.find((field: FormTableField<T>) => field.key === key)
          if (foundField) {
            if (foundField.type === FormTableFieldType.Select) {
              values[key] = null
            } else if (foundField.type === FormTableFieldType.CheckboxGroup) {
              values[key] = []
            } else if (foundField.type === FormTableFieldType.MultipleAutocomplete) {
              values[key] = []
            } else {
              values[key] = ''
            }
          } else {
            values[key] = ''
          }
        } else {
          values[key] = initValues[key]
        }
      })
      setForm(values)
      // setForm(initValues)
    }
  }, [])
  return [ form, setForm, setField ]
}