import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Box from '@material-ui/core/Box'
import { useCrewFilter } from './CrewFilterStore'
import { useAppStore } from '@src/AppStore'
import CrewAutocomplete from './CrewAutocomplete'
import { ValueLabel } from '@src/types/common/ValueLabel'

const CrewFilter = ({
}) => {
  const { depotName, depotId, appStoreRefreshHook } = useAppStore()
  const { isShow, setSelectedDepot } = useCrewFilter()
  const [selectedItem, setSelectedItem] = useState<ValueLabel>({
	label: depotName,
	value: depotId,
  })

  useEffect(() => {
    setSelectedDepot({
		label: depotName,
		value: depotId,
    })
  }, [])

  useEffect(() => {
    if (appStoreRefreshHook) {
      setSelectedDepot({ value: depotId, label: depotName })
      setSelectedItem({ value: depotId, label: depotName })
    }
  }, [appStoreRefreshHook])

  if (!isShow) {
    return null
  }
  if (!selectedItem.value) {
    return null
  }

  return <StyledBox>

    <PaddingSpan>View Crews in</PaddingSpan>

    <CrewAutocomplete
      id='crew autocomplete for crew view'
      selectedItem={selectedItem}
      onSelect={(item: ValueLabel) => {
        setSelectedDepot(item)
        setSelectedItem(item)
      }}
    />

    <PaddingSpan>Depot</PaddingSpan>
  </StyledBox>
}

CrewFilter.propTypes = {
  open: PropTypes.bool,
}

const StyledBox = styled(Box)`
  font-size: 24px;
`
const PaddingSpan = styled.span`
  padding: 0 2px;
`

export default observer(CrewFilter)