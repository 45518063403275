import React from 'react'
import styled from 'styled-components'
import FormRowItem from './Item'

export interface FormRowFlexProps {
  children: React.ReactNode | React.ReactNode[],
}

const RowFlex = (props: FormRowFlexProps) => {
  const { children } = props
  if (!children) {
    return null
  }
  return <Wrapper>
    {React.Children.toArray(children).map((child: React.ReactNode, index: number) => {
      return <FormRowItem key={index}>{child}</FormRowItem>
    })}
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default RowFlex