import { SRRResourceTypeId } from '@src/types/SRRResourceTypeId'
import { apiSrrResouceRemove } from '@src/apis/srr-resource-remove'

interface Options {
  srrId: number,
  serialId: number,
  resouceTypeId: SRRResourceTypeId,
  row: any,
  confirm: Function,
  alertDone(): void,
  alertError(): void,
  refresh(): void,
}

export async function handleRemoveSRRResource(options: Options) {
  const {
    srrId,
    serialId,
    resouceTypeId,
    row,
    confirm,
    alertDone,
    alertError,
    refresh,
  } = options
  const requestOptions = {
    srrId,
    serialId,
    resouceTypeId,
    id: row.id,
  }
  try {
    await confirm({
      title: `Confirm deletion`,
      message: ``,
    })
    confrimRemoveRow(row)
  } catch (e) {

  }

  async function confrimRemoveRow(row: any) {
    try {
      const result = await apiSrrResouceRemove({
        srrId,
        serialId,
        resouceTypeId,
        id: row.id,
      })
      alertDone()
      refresh()
    } catch (e) {
      alertError()
    }
  }
  
}