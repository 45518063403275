import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ViewListIcon from '@material-ui/icons/ViewList'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

const AssetMgmt = () => {
  return (
    <MgmtWrapper>
      <MainWrapper>
        <STypography> Asset Management </STypography>
        <Circles>
          <Circle type='crew' />
          <Circle type='labour' />
          <Circle type='mobileAssets' />
        </Circles>
      </MainWrapper>
    </MgmtWrapper>
  )
}

const Circle = ({
  type,
}: {
  type: 'crew' | 'labour' | 'mobileAssets',
}) => {
  const history = useHistory()
  const handleClick = () => {
    const UrlMapping = {
      crew: '/mgmt/crew',
      labour: '/mgmt/labour',
      mobileAssets: '/mgmt/mobile-asset',
    }
    history.push({
      pathname: `${UrlMapping[type]}`,
    })
  }
  const renderIcon = () => {
    const IconMapping = {
      crew: <SupervisorAccountIcon />,
      labour: <ViewListIcon />,
      mobileAssets: <LocalShippingIcon />,
    }
    return (
      <Icon>
        { IconMapping[type] }
      </Icon>
    )
  }
  const renderText = () => {
    const TextMapping = {
      crew: 'Crew',
      labour: 'Labour',
      mobileAssets: 'Mobile Assets',
    }
    return <Text>{TextMapping[type]}</Text>
  }
  return (
    <CircleWrapper onClick={handleClick}>
      { renderIcon() }
      { renderText() }
    </CircleWrapper>
  )
}

const MgmtWrapper = styled.div`
  width: 480px;
  margin-left: 40px;
  border-left: 1px solid #0A9688;
`
const STypography = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  color: #0A9688;
`
const MainWrapper = styled.div`
  margin-left: 40px;
`
const Circles = styled.div`
  margin-top: 20px;
  display: flex;
  
`
const CircleWrapper = styled.div`
  width: 120px;
  height: 120px;
  border: 1px solid #0A9688;
  border-radius: 120px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Icon = styled.div`
  color: #0A9688;
  svg {
    font-size: 35px;
  }
`
const Text = styled(Typography)`
  color: #0A9688;
  font-size: 16px;
`

export default AssetMgmt