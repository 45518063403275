export enum TransportTypeId {
  Delivery = 'Delivery',
  Profiling = 'Profiling',
}

export function formatTransportTypeId(id: TransportTypeId): string {
  return {
    'Delivery': 'Delivery',
    'Profiling': 'Profiling',
  }[id]
}