import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string,
  children: React.ReactNode,
}

const CompPreviewSection = (props: Props) => {
  const {
    name,
    children,
  } = props
  return <Wrapper>
    <Name>{name}</Name>
    <ChildrenWrapper>
      { children }
    </ChildrenWrapper>
  </Wrapper>
}

const Wrapper = styled.div`

`
const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #009688;
`
const ChildrenWrapper = styled.div`
  border-bottom: 1px solid #009688;
  margin-bottom: 8px;
`

export default CompPreviewSection