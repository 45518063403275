import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { FormTableField } from '@src/components/Form/Table'

interface Props<T> {
  data: T | null,
  fields: FormTableField<T>[],
  editable?: boolean,
  onEdit(): void,
}

function CompFlatTable<T>(props: Props<T>) {
  const {
    data,
    fields,
    editable = false,
    onEdit,
  } = props
  const handleEdit = () => {
    onEdit()
  }
  function renderDataCell(field: FormTableField<T>) {
    if (data === null) {
      return null
    }
    const { key, type, label, format } = field
    const cellData: any = data ? data[field.key] : null;
    if (format && typeof format === 'function') {
      return format(cellData)
    }
    return cellData
  }
  return <Wrapper>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            { editable && 
              <TableHeaderCell>
                Edit
              </TableHeaderCell>
            }
            <TableHeaderCell>
              Product
            </TableHeaderCell>
            <TableHeaderCell>
              Required
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { fields.map((field: FormTableField<T>, rowIndex: number) => {
            return <TableRow 
              hover
              key={field.key as string} 
              // onClick={handleClick(row)}
            >
              { editable && 
                <SCell>
                  <IconButton size='small' onClick={handleEdit}><EditIcon /></IconButton>
                </SCell>
              }
              <SCell>
                { field.label }
              </SCell>
              <SCell>
                { renderDataCell(field) }
              </SCell>
            </TableRow>
          })}
          
        </TableBody>
      </Table>
    </TableContainer>
  </Wrapper>
}

const Wrapper = styled.div`

`

const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const SCell = styled(TableCell)``

export default CompFlatTable
