import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  LeaveCalendarNotesID:string,
  LeaveType: number,

  
  Active: boolean

  ModifiedBy:string

}

export const apiLeavetNoteUpdate = ({
  LeaveCalendarNotesID,
  LeaveType,
 
  Active,
  ModifiedBy
}: Options) => {
  const data = {
    LeaveCalendarNotesID,
    LeaveType,
   
    Active,ModifiedBy
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/leaveNote`,
    data,
  }
  return ax(options)
}