import React from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import DeleteIcon from '@material-ui/icons/Delete'

import FolderIcon from '@material-ui/icons/Folder'
import EditIcon from '@material-ui/icons/Edit'
import usePermission from '@src/utils/hooks/usePermission'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useCrewCalender } from '../StoreCrewCalender'
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface Props {
  anchorEl: null | HTMLElement,
  onClose(): void,
  onEdit?(): void,
  onAdd?(): void, 
  onDuplicateNote?(): void,
  onDelete?(): void,
  isEdit:boolean

}

const NoteMoreActionsMenu = (props: Props) => {
  const {
    anchorEl = null,
    onClose,
    onEdit = () => { },
    onAdd = () => { },
    onDuplicateNote = () => { },
    onDelete = () => { },
    isEdit
  
  } = props
  let menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()


  const { alert } = useSnackbar()

  const beforeEdit = async (e: React.SyntheticEvent<any>) => {
    onEdit()
  }
  const beforeOpen = async (e: React.SyntheticEvent<any>) => {
    onAdd();
   // e.stopPropagation()
  //  onOpenCrewpack()
  }

  const beforeDuplicateNote = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onDuplicateNote()
  }
  const beforeDelete = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    try {
     

      await confirm({
        title: 'Confirm Deletion of Crew Note',
        message: 'This will permanently delete any saved note information from the system and cannot be undone.',
      })
      onDelete()
    } catch (e) { }
  }

  return <Wrapper>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={onClose}
    >
      <MenuItem onClick={beforeOpen}>
        <SListItemIcon>
          <FolderIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Add Note</Typography>
      </MenuItem>
      {isEdit && 
        <MenuItem onClick={beforeDuplicateNote}>
          <SListItemIcon>
            <FileCopyIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Note Duplicate</Typography>
        </MenuItem>
      }
      {isEdit && 
      <MenuItem onClick={beforeEdit} >
        <SListItemIcon>
          <EditIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Edit Note</Typography>
      </MenuItem>
    }
    {isEdit && 
      <MenuItem onClick={beforeDelete} >
        <SListItemIcon>
          <SDeleteIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Delete Note</Typography>
      </MenuItem>
}
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
`

export default NoteMoreActionsMenu