import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {
  data: AIMS.MobileAsset[],
  onRemove?(row: AIMS.MobileAsset, rowIndex: number): void,
}

const CrewMgmtAssetTable = (props: Props) => {
  const {
    data = [],
    onRemove = () => {},
  } = props
  const { canEditCrewInMgmt } = usePermission()
  const { confirm } = useConfirm()
  if (!data) {
    return null
  }
  const handleRemove = (row: AIMS.MobileAsset, rowIndex: number) => {
    onRemove(row, rowIndex)
  }
  const beforeRemove = (row: AIMS.MobileAsset, rowIndex: number) => async () => {
    try {
      await confirm({
        title: 'Confirm removal of this item',
        message: 'This will erase any changes you have made to the fields and cannot be undone',
        confirmButtonText: 'Remove',
      })
      handleRemove(row, rowIndex)
    } catch (error) {

    }
  }
  return <STable>
  <TableHead>
    <TableRow>
      <TableHeaderCell>Asset Number</TableHeaderCell>
      <TableHeaderCell>Description</TableHeaderCell>
      { canEditCrewInMgmt && <TableHeaderCell>Remove</TableHeaderCell> }
    </TableRow>
  </TableHead>
  <TableBody>
    { data.map((row, rowIndex) => {
      return <TableRow 
        hover
        key={row.id} 
        >
        <SCell>
          {row.id}
        </SCell>
        <SCell>
          {row.description}
        </SCell>
        { canEditCrewInMgmt &&
          <SCell>
            <IconButton 
              data-testid='asset-table-delete-button'
              onClick={beforeRemove(row, rowIndex)}
            >
              <DeleteIcon />
            </IconButton>
          </SCell>
        }
      </TableRow>
    })}
  </TableBody>
</STable>
}

const STable = styled(Table)`
  margin: 10px 0 40px;
`
const TableHeaderCell = styled(TableCell)`
  border-bottom: none;
  background-color: #F5F5F5;
`
const SCell = styled(TableCell)`
  padding: 22px 16px;
`

export default CrewMgmtAssetTable