import React, { useEffect, useState } from 'react'
import MobileAssetTable from '../MgmtMobileAsset/parts/MobileAssetTable';
import Device from '../Devices/Device';
import { useMobileAssetsFilteredByDepot } from '../MgmtMobileAsset/useMobileAssetsFilteredByDepot'
import { useRSMgmtStore } from '../MgmtLabour/Store'
import { HEREMAP_KEY } from '@src/constants'
declare global {
  interface Window {
      H:any;
  }
}
interface Props {}
const DashboardTasks = (props:Props) => {
  const { depot, active } = useRSMgmtStore()
const [ assets, refreshAssets ] = useMobileAssetsFilteredByDepot(depot, active)

  const mapRef = React.createRef();
  const H = window.H;
  const platform = new H.service.Platform({
      apikey: HEREMAP_KEY
  });
  const latlng =  { lat: 50, lng: 5 };
  let map: any = null;
  const defaultLayers = platform.createDefaultLayers();
    // Create an instance of the map

  // setTimeout(function() {
    useEffect(() => {
      map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
          // This map is centered over Europe
          center: latlng,
          zoom: 4,
          pixelRatio: window.devicePixelRatio || 1
        }
      );
      var location = new H.map.Marker(latlng);
      map.addObject(location);
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(res) {
          map.setCenter({"lat": res.coords.latitude,"lng": res.coords.longitude});
          location.setGeometry({lat: res.coords.latitude, lng: res.coords.longitude});
        });
      }
    }, [])
  // }, 1000);

  return <div>
      <Device />    
     <div ref={mapRef as React.RefObject<HTMLDivElement> } style={{ height: "500px" }} />
    
     {/* <MobileAssetTable
        assets={assets}
        onEditAsset={handleEditAsset}
        onSuccess={refreshAssets}
      /> */}
     
      
      </div>

  
}

export default DashboardTasks

function handleEditAsset() {
 return true;
}

