import React from 'react'
import styled from 'styled-components'

import { default as TableCellOrigin } from '@material-ui/core/TableCell'


const TableCell = styled(TableCellOrigin)`
  border-bottom: none;
  padding-left: 16px;
  padding-right: 16px;
` as any

export default TableCell 