import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSRR } from './useSRR'
import Breadcrumbs from '@src/components/Breadcrumbs'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import LeftNavStickyWrapper from '@src/components/LeftNav/StickyWrapper'
import { LeftNav } from '@src/components/LeftNav'
import { formatSRRStatus } from '@src/types/SRRStatus'
import { NavDeepLink } from '@src/types/NavDeepLink'
import SRRJob from './parts/1-Job/Job'
import SRRRework from './parts/3-Rework/Rework'
import SRRMaterial from './parts/4-Material/Material'
import SRRMachine from './parts/5-Machine/Machine'
import SRRQuality from './parts/6-Quality/Quality'
import SRRSiteInfo from './parts/2-SiteInfo/SiteInfo'
import { observer } from 'mobx-react-lite'
import { useSRRDetailStore } from './SRRDetailStore'
import { useAppStore } from '@src/AppStore'
import CompSRRActionMenu from './comps/ActionMenu'
import SRRSiteDiagram from './parts/7-SiteDiagram/SiteDiagram'

interface Props {

}

enum SRRSectionName {
  Job,
  SiteInformation,
  Rework,
  Material,
  Machine,
  Quality,
  SiteDiagram,
}

const SRRPage = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const { hash } = useLocation()
  const history = useHistory()
  const srrId = Number(id)
  const { serialId } = useAppStore()
  const [ srr, srrLoading, refresh ] = useSRR()
  useEffect(() => {
    refresh()
  }, [ id ])
  const deepLinks: NavDeepLink<SRRSectionName>[] = [
    {
      anchor: 'Job',
      urlHash: 'job',
      key: SRRSectionName.Job,
    },
    {
      anchor: 'Site Information',
      urlHash: 'site-information',
      key: SRRSectionName.SiteInformation,
    },
    {
      anchor: 'Rework',
      urlHash: 'rework',
      key: SRRSectionName.Rework,
    },
    {
      anchor: 'Material',
      urlHash: 'Material',
      key: SRRSectionName.Material,
    },
    {
      anchor: 'Machine',
      urlHash: 'Machine',
      key: SRRSectionName.Machine,
    },
    {
      anchor: 'Quality',
      urlHash: 'Quality',
      key: SRRSectionName.Quality,
    },
    {
      anchor: 'Site Diagram',
      urlHash: 'site-diagram',
      key: SRRSectionName.SiteDiagram,
    },
  ]
  const [ activeSection, setActiveSection ] = useState<SRRSectionName>(SRRSectionName.Job)
  const activeIndex = deepLinks.findIndex((link: NavDeepLink<SRRSectionName>) => link.key === activeSection)
  // deep linking
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1)
      if (deepLinks.some((link: NavDeepLink<SRRSectionName>) => link.urlHash === trimmedHash)) {
        setActiveSection(
          deepLinks.find((link: NavDeepLink<SRRSectionName>) => link.urlHash === trimmedHash)!
            .key
        )
        return
      }
    }
    setActiveSection(SRRSectionName.Job)
  }, [id])
  // deep linking
  useEffect(() => {
    history.replace({
      hash: `#${deepLinks.find((link: NavDeepLink<SRRSectionName>) => link.key === activeSection)!
        .urlHash}`
    })
  }, [activeSection])
  const handleOnAnchorClick = (index: number) => {
    setActiveSection(deepLinks[index].key)
  }
  function renderPart () {
    if (!srr) {
      return null
    }
    switch(activeSection) {
      case SRRSectionName.Job: {
        return <SRRJob />
      }
      case SRRSectionName.SiteInformation: {
        return <SRRSiteInfo />
      }
      case SRRSectionName.Rework: {
        return <SRRRework />
      }
      case SRRSectionName.Material: {
        return <SRRMaterial />
      }
      case SRRSectionName.Machine: {
        return <SRRMachine />
      }
      case SRRSectionName.Quality: {
        return <SRRQuality />
      }
      case SRRSectionName.SiteDiagram: {
        return <SRRSiteDiagram />
      }
    }
    return null
  }
  if (!srr) {
    return null
  }
  const { projectId, projectInternalId, projectName } = srr.job.projectDetails
  const { street, suburb } = srr.siteInformation.siteDetails
  return <Wrapper maxWidth='xl'>
    <Breadcrumbs items={[
      { type: 'link', text: `Project ${projectId || projectInternalId}`, url: `/project/${projectInternalId}`},
      { type: 'text', text: `Shift Resource Requirement ${srrId}`},
    ]}/>
    {/* { SRRLoading ? 'loading' : 
    
      srr ? srr.id : 'not found'
      
    } */}
    <MainWrapper>
      <LeftNavStickyWrapper top={16}>
        <LeftNav 
          title={`Shift Resource Requirement`}
          statusName={formatSRRStatus(srr.status.id)}
          sections={[
            {
              label: 'Project',
              value: `${projectId || projectInternalId} | ${projectName}`,
            },
            {
              label: 'Site',
              value: (suburb && street) ? `${suburb}, ${street}` : '',
            },
          ]}
          anchors={deepLinks.map((link: NavDeepLink<SRRSectionName>) => link.anchor)}
          activeIndex={activeIndex}
          onAnchorClick={handleOnAnchorClick}
        />
      </LeftNavStickyWrapper>
      <PartWrapper>
        { renderPart() }
        <ActionWrapper>
          <CompSRRActionMenu />
        </ActionWrapper>
      </PartWrapper>
    </MainWrapper>
  </Wrapper>
}

const Wrapper = styled(Container)`

`
const MainWrapper = styled.div`
  display: flex;
`
const PartWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  flex-grow: 1;
`
const ActionWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: -6px;
`

export default observer(SRRPage)