import React from 'react'
import styled from 'styled-components'

interface CrewColorSelectedDotProps {
  colorHex: string,
}
const CrewColorSelectedDot = ({
  colorHex,
}: CrewColorSelectedDotProps) => {
  return <Wrapper colorHex={colorHex}>

  </Wrapper>
}

const Wrapper = styled.div<{ colorHex: string }>`
  border: 2px solid #000000;
  background-color: ${props => props.colorHex};
  width: 30px;
  height: 30px;
  border-radius: 30px;
`
export default CrewColorSelectedDot