import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import { FieldName } from './Field'
import SupplyPlantAutocomplete from '../../../../../components/autocompletes/SupplyPlantAutocomplete'

const FieldSupplyPlant = ({
  field,
  onChange,
}: DRS.Comp) => {
  const { id, name } = field.data
  const selectedItem = {
    value: id,
    label: name,
  }
  const handleChange = (item: AIMS.ValueLabel) => {
    onChange({
      id: item.value,
      name: item.label,
    })
  }
  return <Wrapper>
    <FormControl fullWidth>
      <SupplyPlantAutocomplete 
        selectedItem={selectedItem}
        onChange={handleChange}
        fullWidth={true}
      />
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
`
const Content = styled.div``

export default FieldSupplyPlant