import { makeStyles } from '@material-ui/core/styles'

const useButtonStyles = makeStyles(theme => ({
    smallButton: {
        padding: 2
    },
    mediumButton: {
        padding: 1
    },
    largeButton: {
        padding: 2
    },
    smallIcon: {
        fontSize: ".9em"
    },
    mediumIcon: {
        fontSize: "1.3em"
    },
    largeIcon: {
        fontSize: "1.3em"
    },
    input: {
        display: "none"
    }
}))

export default useButtonStyles