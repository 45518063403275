import React from 'react'
import styled from 'styled-components'

import TableCell from './base-TableCell'

const TableHeaderCell = styled(TableCell)`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
` as any

export default TableHeaderCell 