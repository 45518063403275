import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import PhotoIcon from '@material-ui/icons/InsertPhoto'
import PDFIcon from '@material-ui/icons/PictureAsPdf'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import CircularProgress from '@material-ui/core/CircularProgress'

const ON_REMOVE_TITLE = 'Confirm removal'
const ON_REMOVE_MESSAGE = 'This action will remove the file'

export interface FileChipFile {
  id: number,
  uuid?: string,
  name: string,
  physicalName: string,
  status: FileChipStatus,
  mime?: FileMime,
}

export enum FileChipStatus {
  NORMAL,
  PENDING,
  COMPLETED,
} 
export enum FileMime {
  UNKNOWN_FILE,
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

/**
 * Main comp
 * @description showing file icon, name and possibly actions on the right
 */
function FileChip ({
  file,
  onClick = (file: FileChipFile) => {},
  removable = false,
  onRemove = () => {},
  onRemoveTitle = ON_REMOVE_TITLE,
  onRemoveMessage = ON_REMOVE_MESSAGE,
}: {
  file: FileChipFile,
  onClick?(file: FileChipFile):void,
  removable?: boolean,
  onRemove?(file: FileChipFile): void,
  onRemoveTitle?: string,
  onRemoveMessage?: string,
}) {
  const { name, status, mime } = file
  const { confirm } = useConfirm()
  // always ask before removing
  const beforeRemoving = async () => {
    try {
      await confirm({
        title: onRemoveTitle,
        message: onRemoveMessage,
      })
      onRemove(file)
    } catch(e) {}
  }
  const handleClick = () => {
    onClick(file)
  }
  return (
    <Wrapper>

      <FileIconWrapper>
        <Icon mime={mime}/>
      </FileIconWrapper>

      <FileName 
        data-testid='file-name'
        onClick={handleClick}
      > 
        { name } 
      </FileName>

      <ActionsOnTheRight 
        status={status}
        removable={Boolean(removable)}
        onRemove={beforeRemoving}
      />

    </Wrapper>
  )
}

/**
 * sub comp
 * @description pdf, jpeg or png
 */
function Icon({
  mime = FileMime.UNKNOWN_FILE,
}: {
  mime?: FileMime,
}) {
  switch(mime) {
    case FileMime.UNKNOWN_FILE: {
      return (
        <FileIcon data-testid='icon-file'/>
      )
    }
    case FileMime.PDF: {
      return (
        <PDFIcon data-testid='icon-pdf'/>
      )
    }
    case FileMime.JPEG:
    case FileMime.PNG:
       {
      return (
        <PhotoIcon data-testid='icon-image'/>
      )
    }
    default:
      return null
  }
}

/**
 * sub comp
 * @description showing loading, check icon or bin icon depending on file status
 */
function ActionsOnTheRight ({
  status,
  removable,
  onRemove,
}: {
  removable: boolean,
  status: FileChipStatus,
  onRemove(): void,
}) {
  switch (status) {
    case FileChipStatus.NORMAL: {
      if (!removable) {
        return null
      }
      return (
        <IconButton 
          data-testid='remove-btn'
          size='small' 
          onClick={onRemove}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
    case FileChipStatus.PENDING: {
      return (
        <CircularProgress 
          data-testid='progress-indicator'
          size={24} 
        />
      )
    }
    case FileChipStatus.COMPLETED: {
      return (
        <CheckIcon 
          data-testid='completed-indicator'
        />
      )
    }
    default:
      return null
  }
}

const Wrapper = styled.div`
  display: flex;
  min-height: 60px;
  max-width: 520px;
  background-color: #F5F5F5;
  padding: 0 20px;
  align-items: center;
  margin-bottom: 8px;
  color: #9A9898;
`
const FileIconWrapper = styled.div`
  margin-right: 10px;
`
const FileName = styled.div`
  color: #009688;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  &:hover {
    text-decoration: underline;
  }
`

export default FileChip