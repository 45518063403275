import React from 'react'
import styled from 'styled-components'
import { MobileAssetPrestartBrief } from '@src/types/MobileAssetPrestart'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'

interface Props {
  mobileAssetPrestart: MobileAssetPrestartBrief,
  onClick(): void,
}

const CompPrestartItem = (props: Props) => {
  const {
    mobileAssetPrestart,
    onClick,
  } = props
  const { id, description } = mobileAssetPrestart.asset
  return <Wrapper onClick={onClick}>
    <Left>
      <NumberAndName>{`${id} ${description}`}</NumberAndName>
      <OperatorNames>{`Operator Name: ${formatProfileOrNullFirstLastName(mobileAssetPrestart.operator)}`}</OperatorNames>
    </Left>
    <Right>
    <Status>{mobileAssetPrestart.status.description}</Status>
    </Right>
  </Wrapper>
}

const Wrapper = styled.div`
  background-color: #F8F8F8;
  display: flex;
  margin: 16px 0;
  padding: 12px 20px;
  cursor: pointer;
`
const Left = styled.div`
  flex-grow: 1;
`
const Right = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const NumberAndName = styled.div`
  font-size: 16px;
  color: #707070;
  font-weight: bold;
`
const OperatorNames = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #707070;
`
const Status = styled.div`
  padding: 0 4px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: #009688;
  border-radius: 8px;
  text-align: center;
  color: #fff;
`

export default CompPrestartItem