import React from 'react'
import styled from 'styled-components'
import { Sif } from '@src/types/Sif'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import RowGrid from '@src/components/FormRow/RowGrid'
import TextareaField from '@src/components/Form/TextareaField'
import FormRowItem from '@src/components/FormRow/Item'
import FieldPair from '@src/components/Form/FieldPair'
import { SiteInformation } from '@src/types/SRR'
import { formatTimeStringHHmm } from '@src/types/format/timeStringHHmm'

interface Props {
  siteInformation: SiteInformation,
}

const CompSiteDetail = (props: Props) => {
  const {
    siteInformation,
  } = props
  const { suburb, street, nearestCrossStreet, mapReference, timeOnSite, timeOffSite,
    siteSpecificInduction, plantRiskAssessmentsRequired, uhfChannel, emergencyAssemblyPoint, nearestHospital,
    baseCondition, generalSiteObservations,
    machineAccess, machineParking,
  } = siteInformation.siteDetails
  const siteDetailData: { label: string, value: string }[] = [
    {
      label: 'Street',
      value: street || '',
    },
    {
      label: 'Nearest Cross Street',
      value: nearestCrossStreet || '',
    },
    {
      label: 'Suburb',
      value: suburb || '',
    },
    {
      label: 'Map Reference',
      value: mapReference || '',
    },
    {
      label: 'Time on site',
      value: formatTimeStringHHmm(timeOnSite || ''),
    },
    {
      label: 'Time off site',
      value: formatTimeStringHHmm(timeOffSite || ''),
    },
  ]
  const siteDetailDataRow2: { label: string, value: string }[] = [
    {
      label: 'Site Specific Induction',
      value: formatBooleanToYesNo(Boolean(siteSpecificInduction)),
    },
    {
      label: 'Plant Risk Assessments Required?',
      value: formatBooleanToYesNo(Boolean(plantRiskAssessmentsRequired)),
    },
    {
      label: 'UHF Channel',
      value: uhfChannel !== null ? `${uhfChannel}` : '',
    },
    {
      label: 'Emergency Assembly Point',
      value: emergencyAssemblyPoint || '',
    },
    {
      label: 'Nearest Hospital',
      value: nearestHospital || '',
    },
  ]
  return (
    <React.Fragment>
      <RowWrapper>
        <RowGrid>
          { siteDetailData.map((item: { label: string, value: string } ) => {
            return <FieldPair key={item.label} label={item.label} value={item.value} />
          })}
        </RowGrid>
      </RowWrapper>
      <RowWrapper>
        <RowGrid>
          { siteDetailDataRow2.map((item: { label: string, value: string } ) => {
            return <FieldPair key={item.label} label={item.label} value={item.value} />
          })}
        </RowGrid>
      </RowWrapper>
      <RowWrapper>
        <RowGrid size={4}>
          <FormRowItem>
            <TextareaField 
              label={'Comments on Base Condition'}
              value={baseCondition || ''}
              readonly
              />
          </FormRowItem>
          <FormRowItem>
            <TextareaField 
              label={'General Site Observations'}
              value={generalSiteObservations || ''}
              readonly
              />
          </FormRowItem> 
        </RowGrid>
      </RowWrapper>
      <RowWrapper>
        <RowGrid size={4}>
          <FormRowItem>
            <TextareaField 
              label={'Comments on Machine Access'}
              value={machineAccess || ''}
              readonly
              />
          </FormRowItem>
          <FormRowItem>
            <TextareaField 
              label={'Comments on Machine Parking'}
              value={machineParking || ''}
              readonly
              />
          </FormRowItem> 
        </RowGrid>
      </RowWrapper>
    </React.Fragment>
  )
}

const Wrapper = styled.div`

`
const RowWrapper = styled.div`
  margin-bottom: 48px;
`

export default CompSiteDetail
