import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'

import Select from '@material-ui/core/Select'

import CrewAutocomplete from '../DashboardCalendar/CrewCalendarView/FilterInHeader/CrewAutocomplete'
import { useAppStore } from '@src/AppStore'
import MgmtLinkSelect from './MgmtLinkSelect'
import { useRSMgmtStore } from './Store'
import DownshiftSelect from '@src/components/DownshiftSelect'

export enum FilterPageType {
  MobileAssets,
  Labour,
  Crew,
}
const StatusOptions = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
]

interface Props {
  filterPage: FilterPageType,
}

function MgmtLabourFilter(props: Props) {
  const { filterPage } = props
  const isCrewPage = filterPage === FilterPageType.Crew
  const { depotId, depotName, regionId, stateId, appStoreRefreshHook } = useAppStore()
  const { depot, setDepot, active, setActive } = useRSMgmtStore()
  const [selectedItem, setSelectedItem] = useState<AIMS.ValueLabel>({
    label: depotName,
    value: depotId,
  })

  useEffect(() => {
    setDepot({
      label: depotName,
      value: depotId,
    })
  }, [])

  useEffect(() => {
    if (appStoreRefreshHook) {
      console.log(`Filter appStoreRefreshHook=${appStoreRefreshHook}`)
      setDepot({
        label: depotName,
        value: depotId,
      })
      setSelectedItem({
        label: depotName,
        value: depotId,
      })
    }
  }, [appStoreRefreshHook])

  if (!selectedItem.value) {
    return null
  }
  const handleChange = (value: string) => {
    setActive(value === StatusOptions[0].value ? true : false)
  }

  return <StyledBox>

    {/* `View mgmt in Sydney Metro depot` */}

    <PaddingSpan>View My </PaddingSpan>

    {!isCrewPage &&
      <React.Fragment>
        <DownshiftSelect
          color='boral'
          options={StatusOptions}
          value={active ? StatusOptions[0] : StatusOptions[1]}
          onChange={handleChange}
          displayAsText={true} >
        </DownshiftSelect>

        <PaddingSpan></PaddingSpan>
      </React.Fragment>
    }
    <MgmtLinkSelect />

    <PaddingSpan> in </PaddingSpan>

    <CrewAutocomplete
      id=''
      // color='boral'
      selectedItem={selectedItem}
      onSelect={(item: AIMS.ValueLabel) => {
        setDepot(item)
        setSelectedItem(item)
      }}
    />

    <PaddingSpan>Depot</PaddingSpan>

  </StyledBox>
}

const StyledBox = styled(Box)`
  font-size: 24px;
`
const PaddingSpan = styled.span`
  padding: 0 2px;
`
const SecondHalf = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: inline-block;
  }
`



export default observer(MgmtLabourFilter)