import React from 'react'
import styled from 'styled-components'
import { CrewColoured } from '../MgmtCrew/parts/CrewCard'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import useHover from '../../utils/hooks/useHover'
import ClearIcon from '@material-ui/icons/Clear'
import { styledWithOut } from '../../utils/styledWithout'

function SelectedCrews({
  crews = [],
  onRemove = () => { },
}: {
  crews: AIMS.Crew[],
  onRemove?(crew: AIMS.Crew, index: number): void,
}) {
  const handleRemove = (crew: AIMS.Crew, index: number) => () => {
    onRemove(crew, index)
  }
  return <Wrapper>
    {crews.map((crew, index) => {
      return <Crew key={crew.id} crew={crew} onRemove={handleRemove(crew, index)} />
    })}
  </Wrapper>
}

function Crew({
  crew,
  onRemove = () => { }
}: {
  crew: AIMS.Crew,
  onRemove?(): void,
}) {
  const { colourCode, name } = crew
  const [hoverRef, isHovered] = useHover()
  return <CrewWrapper ref={hoverRef}>
    <CrewColoured colourCode={colourCode || ''} />
    <CrewName>{name}</CrewName>
    <Clear size='small' isHovered={isHovered} onClick={onRemove}>
      <ClearIcon />
    </Clear>
  </CrewWrapper>
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CrewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  margin-right: 20px;
  margin-bottom: 8px;
`
const CrewName = styled(Typography)`
  text-align: center;
`

const Clear = styled(styledWithOut('isHovered')(IconButton))`
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 0.875rem;
  visibility: ${props => props.isHovered ? 'visible' : 'hidden'};
`

export default SelectedCrews