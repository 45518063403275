import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useSelect } from 'downshift'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

const DownshiftSelect = ({ 
  value,
  options,
  onChange,
  displayAsText,
  color,
}: {
  value?: AIMS.ValueLabel,
  options: AIMS.ValueLabel[],
  onChange: any,
  displayAsText: any,
  color: any,
}) => {
  const itemToString = (item: AIMS.ValueLabel): string => item.label ? item.label : ''
  const {
    isOpen,
    // selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    toggleMenu,
  } = useSelect<AIMS.ValueLabel>({
    items: options,
    itemToString,
  })
  const ref = useRef(null)
  
  if (!value) {
    return null
  }
  return <Wrapper>
    <ToggleButton 
      ref={ref}
      variant='body2' 
      {...getToggleButtonProps({refKey: 'ref'})}
      innerRef={ref}
    >
      {itemToString(value)}
    </ToggleButton>
    { isOpen && 
      <Popper
        open={isOpen}
        anchorEl={ref.current}
        style={{zIndex: 1300}}
        placement='bottom-start'
      >
        <SPaper
          style={{zIndex: 1300}}
          {...getMenuProps()}
        >
          { options.map((option: any, index: number) => {
            const isHighlighted = index === highlightedIndex
            const itemProps = getItemProps({
              item: option,
              index,
              onMouseDown: () => {
                // console.log('clicked', option)
                onChange(option.value, option)
                toggleMenu()
              },
            })
            return <MenuItem
                key={option.label}
                selected={isHighlighted}
                component='div'
                {...itemProps}
              >
                {option.label}
              </MenuItem>
          })}
        </SPaper>
      </Popper>
    }
    
  </Wrapper>
}

const Wrapper = styled.div`
  display: inline-block;
`
const SPaper = styled(Paper)`
  position: absolute;
  z-index: 10;
` as typeof Paper

const ToggleButton = styled(Typography)`
  color: #5E843D;
  border-bottom: 1px solid #5E843D;
  font-size: inherit;
  font-weight: 700;
  cursor: pointer;
`

export default observer(DownshiftSelect)