import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  depot: string,
  start: string,
  end: string
  region: string
  state: string
}

export const apiDepotNoteSearch = ({ depot, start, end,region,state }: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/depotNotes`,
    params: {
      depot: depot,
      start: start,
      region: region,
      state: state,
      end: end,
      'reqDev': 'web'
    }
  }
  return ax(options)
}