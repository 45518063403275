import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import { Depot } from '@src/types/common/Depot'
import { Region } from '@src/types/common/Region'
import { Profile } from '@src/types/common/Profile'

export enum FilterBy {
  CONDITIONS,
  TEXT,
}

interface ProjectFilterStore {
  filterBy: FilterBy,
  setFilterBy(filterBy: FilterBy): void,
  // by conditions
  PM?: Profile,
  setPM(email: Profile): void,
  status: ProjectStatus,
  setStatus(status: ProjectStatus): void,
  depot?: Depot,
  region?: Region,
  setDepot(depot: Depot, region?: Region): void,

  
  // by text
  projectNumber: string,
  setProjectNumber(projectNumber: string): void,
}

const store: ProjectFilterStore = observable<ProjectFilterStore>({
  filterBy: FilterBy.CONDITIONS, 
  setFilterBy: action((filterBy: FilterBy) => {
    store.filterBy = filterBy
    if (filterBy === FilterBy.TEXT) {
      store.projectNumber = ''
    } else {

    }
  }),

  PM: undefined,
  setPM: action((PM: Profile) => store.PM = PM),

  status: ProjectStatus.Active,
  setStatus: action((status: ProjectStatus) => store.status = status),

  depot: undefined,
  region: undefined,
  setDepot: action((depot: Depot, region?: Region) => {
    if (region) {
      store.region = region
      store.depot = undefined
    } else {
      store.depot = depot
      store.region = undefined
    }
  }),

  projectNumber: '',
  setProjectNumber: action((projectNumber: string) => store.projectNumber = projectNumber),

})

interface ProjectFilterOptions {
  role?: string,
  depot?: string,
  state?: string,
  status?: boolean,
  email?: string,
  search?: string,
}

export function getFilterOptions({
  roleId,
  stateId,
  userEmail,
  filterBy,
  projectNumber,
  status,
  depotId,
  email,
}: {
  roleId: string,
  stateId: string,
  userEmail: string,
  filterBy: FilterBy,
  projectNumber: string,
  status: string,
  depotId: string,
  email: string,
}): ProjectFilterOptions {
  let options: ProjectFilterOptions = {}
  if (filterBy !== FilterBy.CONDITIONS) {
    // console.log('filter by', filterBy)
    options.search = projectNumber
  } else {
    
    options.role = roleId //'PROJECT_MANAGER'
  
    if (depotId !== 'all') {
      options.depot = depotId
    } else {
      options.state = stateId
    }
    if (status !== 'all') {
      options.status = status !== 'INACTIVE'
    }
    if (email === 'my') {
      options.email = userEmail
    } else if (email === 'all') {
      // leave it empty
    } else {
      options.email = email
    }
  }
  
  return options
}


const context = React.createContext(store)

export const useProjectFilter = () => {
  return useContext<ProjectFilterStore>(context)
}