import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
interface Options {
  data: any,
  serialId: number
}
export const apiShiftSortOrder = ({
  data,
  serialId
}: Options) => {
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/shifts`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}