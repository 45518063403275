import React from 'react'
import styled from 'styled-components'

const CommonFormFieldLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  /* height: 21px; */
  line-height: 21px;
  color: #000;
  margin-bottom: 8px;
  word-break: break-word;
`

export default CommonFormFieldLabel