import { ax } from '../../utils/axios/axios'
import { BASE_API_URL } from '../../constants'

interface Options {
  crewId: number,
}

export const apiCrewGetInfo = ({
  crewId,
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/crews/${crewId}`,
  }
  return ax(options)
}