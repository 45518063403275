import React from 'react'
import styled from 'styled-components'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import BinderTypeAutocomplete from '../autocompletes/BinderTypeAutocomplete'
import SupplyPlantAutocomplete from '../autocompletes/SupplyPlantAutocomplete'
import ProductAutocomplete from '../autocompletes/ProductAutocomplete'
import DepotAutocomplete from '../autocompletes/DepotAutocomplete'
import AggregateSizeAutocomplete from '../autocompletes/AggregateSizeAutocomplete'
import AssetAutocomplete from '../autocompletes/AssetAutocomplete'
import { DRSAssetDepotType } from '@src/modules/DRS/parts/2-Rework/parts/ReworkAssetsFields'
import SupplierAutocomplete from '../autocompletes/SupplierAutocomplete'


interface Props<T> {
  type: string,
  label: string,
  value: any,
  onChange(item: T): void,
  extra?: {
    plantId?: string, // only for product auto complete,
    depotId?: string,
    stateId?: string,
    assetDepotTypeId?: any,
  },
}

function FormModalAutocomplete<T>(props: Props<T>) {
  const {
    type,
    label,
    value,
    onChange,
    extra,
  } = props
  switch (type) {
    case 'sprayBinderType': {
      return <BinderTypeAutocomplete
        label={label}
        fullWidth
        selectedItem={value ? {
          label: value.description,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'supplyPlant': {
      return <SupplyPlantAutocomplete
        label={label}
        fullWidth
        selectedItem={value ? {
          label: value.name,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'product': {
      let disabled = true
      let finalPlantId = ''
      if (extra) {
        if (extra.plantId) {
          finalPlantId = extra.plantId
          disabled = false
        }
      }
      return <ProductAutocomplete
        label={label}
        plantId={finalPlantId}
        disabled={disabled}
        fullWidth
        selectedItem={value ? {
          label: value.description,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'depot': {
      return <DepotAutocomplete
        label={label}
        fullWidth
        selectedItem={value ? {
          label: value.name,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'aggregateSize': {
      return <AggregateSizeAutocomplete
        label={label}
        fullWidth
        selectedItem={value ? {
          label: value.description,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'drs-rework-asset': {
      // let disabled = true
      let depotId
      let stateId
      // console.log(extra, DRSAssetDepotType.MyDepot)
      if (extra) {
        if (extra.assetDepotTypeId === DRSAssetDepotType.MyDepot) {
          depotId = extra.depotId
        } else {
          stateId = extra.stateId
        }
      }
      return <AssetAutocomplete
        label={label}
        depotId={depotId}
        stateId={stateId}
        fullWidth
        selectedItem={value ? {
          label: value.id,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }
    case 'supplier': {
      return <SupplierAutocomplete
        label={label}
        fullWidth
        selectedItem={value ? {
          label: value.name,
          value: value.id,
        } : {
          label: '',
          value: '',
        }}
        onChange={(item: any) => {
          onChange(item.raw)
        }}
      />
    }

  }
  return null
}


export default FormModalAutocomplete