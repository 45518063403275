import { DRSSectionName } from '../DRSPage'
import { DRSQaSpraySealPAE } from '@src/types/drs/qa/QaSpraySeal'

export interface UpdateDRSSectionViaPathOptions {
  sectionName: DRSSectionName,
  path: string, 
  data: any,
}

type DataType<T> = 
  T extends DRSQaSpraySealPAE ? DRSQaSpraySealPAE :
  any

interface Options<T> {
  sectionName: DRSSectionName, 
  path: string, 
  data: DataType<T>,
  refresh(): void,
  closeModal(): void,
  alertDone(): void,
  alertError(): void,
  updateSectionViaPath(options: UpdateDRSSectionViaPathOptions): void,
}

export async function genericUpdateDRSSection<T>(options: Options<T>) {
  const {
    sectionName,
    path,
    data,
    refresh,
    closeModal,
    alertDone,
    alertError,
    updateSectionViaPath,
  } = options
  try {
    await updateSectionViaPath({
      sectionName,
      path,
      data,
    })
    refresh()
    closeModal()
    alertDone()
  } catch (e) {
    alertError()
  }
}