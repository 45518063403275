import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { Mime } from '@src/types/Mime'
import { RoleId } from '@src/types/common/RoleId'
interface Options {

  serialId: number,

  id: number,

  
  roleId: string
  

}

export const apiDeleteFile = ({
  roleId,
  serialId,
  id
}: Options) => {

  const data = {  
      id,
     roleId,
    serialId
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/documents/crew_pack_photo/${id}`,
    data,
    
    params: {
      reqRole: roleId,
      reqSid: serialId,
      reqDev: 'web',
      active: false,
      id
    }
  }
  return ax(options)
}