const _numDecimals: any = (v: any) => {
  const [i, p, d, e, n] = v.toString().split(/(\.|e[\-+])/g);
  const f = e === 'e-';
  return ((p === '.' && (!e || f) && d.length) + (f && parseInt(n)))
    || (p === 'e-' && parseInt(d))
    || 0;
}

export const getDecimalPlaces = (num: any) => (
  (!isNaN(num) && num % 1 !== 0 && _numDecimals(num)) || 0
);