import React from 'react'
import styled, { css } from 'styled-components'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'

const TableHeadCell = styled(TableCellOrigin)`
  color: rgb(154, 152, 152);
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
  border-bottom: 0px;
` as typeof TableCellOrigin

export default TableHeadCell
