import { useAppStore } from '../../AppStore'
import { RoleId } from '@src/types/common/RoleId'
import { Role } from '@src/types/common/Role'

const usePermission = () => {
  const { roles } = useAppStore()
  let isPM: boolean = false
  let isRS: boolean = false
  let isAdmin: boolean = false
  let isSystemAdmin: boolean = false
  let isSupervisor: boolean = false
  //console.log(`usePermission roles=${JSON.stringify(roles, null, 2)}`)
  roles.forEach((role: Role) => {
    switch (role.id) {
      case RoleId.ProjectManager:
        isPM = true
        break
      case RoleId.ResourceScheduler:
        isRS = true
        break
      case RoleId.SystemAdministrator:
        isSystemAdmin = true
        break
      case RoleId.Administrator:
        isAdmin = true
        break
      case RoleId.Supervisor:
        isSupervisor = true
        break
      default:
    }
  })
  // const canSwitchRole: boolean = false
  // https://docs.google.com/spreadsheets/d/1HsbtkadfhQbDOYqwvMs2merxEw3_afV6w1HJfCZw9kg/edit?ts=5e30f7d9#gid=0&range=B6
  const canCreateDraftProject: boolean = isPM
  const canLinkDraftProject: boolean = isPM
  const canEditProject: boolean = isPM
  const canAddCollaborator: boolean = isPM
  const canRemoveCollaborator: boolean = isPM

  const canAddLabourInMgmt: boolean = isRS
  const canEditLabourInMgmt: boolean = isRS
  const canChangeStatusForLabourInMgmt: boolean = isRS

  const canAddMobileAssetsInMgmt: boolean = isRS
  const canEditMobileAssetsInMgmt: boolean = isRS
  const canChangeStatusForMobileAssetsInMgmt: boolean = isRS

  const canAddCrewInMgmt: boolean = isRS
  const canEditCrewInMgmt: boolean = isRS

  const canCreateShift: boolean = isRS
  const canEditShift: boolean = isRS

  const canEditNote: boolean = isRS
  
  const canEditShiftNote: boolean = isRS || isPM || isAdmin || isSystemAdmin || isSupervisor
  

  const canCreateSif: boolean = isPM
  const canReassignSif: boolean = isPM
  const canLinkSif: boolean = isRS || isPM
  const canUnlinkSif: boolean = isRS || isPM

  const canPlanDRS: boolean = isRS
  const canEditDRS: boolean = isPM
  const canReviewDRS: boolean = isAdmin

  //QAS
  const canDownloadQASPdf: boolean = isRS || isPM || isAdmin

  //SMI
  const canDownloadSMIPdf: boolean = isRS || isPM || isAdmin

  // SRR
  const canEditSubmitSRR: boolean = isPM
  const canImportSIFToSRR: boolean = isPM

  // Labour
  const canCreateEditLabour: boolean = isAdmin || isRS
  const canCreateEditAdmin: boolean = isAdmin
  const canViewEditSystemAccessUsers: boolean = isSystemAdmin

  // WebHook
  const canConnecttoWebSocket: boolean = isSystemAdmin

  return {
    canCreateDraftProject,
    canLinkDraftProject,
    canEditProject,
    canAddCollaborator,
    canRemoveCollaborator,

    canAddLabourInMgmt,
    canEditLabourInMgmt,
    canChangeStatusForLabourInMgmt,

    canAddMobileAssetsInMgmt,
    canEditMobileAssetsInMgmt,
    canChangeStatusForMobileAssetsInMgmt,

    canAddCrewInMgmt,
    canEditCrewInMgmt,

    canCreateShift,
    canEditShift,

    canCreateSif,
    canReassignSif,
    canLinkSif,
    canUnlinkSif,

    canPlanDRS,
    canEditDRS,
    canReviewDRS,

    canDownloadQASPdf,
    canDownloadSMIPdf,

    canEditSubmitSRR,
    canImportSIFToSRR,

    canCreateEditLabour,
    canCreateEditAdmin,

    canViewEditSystemAccessUsers,

    canConnecttoWebSocket,
	canEditNote,
	canEditShiftNote,
  }
}

export default usePermission