import { DRSMobileAssetRow } from '../../5-MobileAssets/parts/MobileAssets'
import { DRSReworkAssetRow } from '../parts/ReworkAssets'

export function transformAssetData(form: DRSReworkAssetRow) {
  let hours = form.hours
  if (typeof form.hours === 'number') {
    hours = form.hours
  } else {
    // @ts-ignore
    hours = form.hours ? form.hours.id : ''
  }
  return {
    ...form,
    hours,
  }
}
export function transformMobileAssetData(form: DRSMobileAssetRow) {
  let hours = form.hours
  if (typeof form.hours === 'number') {
    hours = form.hours
  } else {
    // @ts-ignore
    hours = form.hours ? form.hours.id : ''
  }
  return {
    ...form,
    hours,
  }
}