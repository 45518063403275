import { useEffect, useState } from 'react'
import { useAppStore } from '@src/AppStore'
import { apiMobileAssetById } from '@src/apis/mobile-asset-by-id'
import { MobileAsset } from '@src/modules/MgmtMobileAsset/fields'

export function useMobileAsset(id: string): [ MobileAsset | null ] {
  const [ mobileAsset, setMobileAsset ] = useState<MobileAsset | null>(null)
  const { serialId } = useAppStore()
  let active = false
  useEffect(() => {
    async function fetch() {
      try {
        const result = await apiMobileAssetById({
          id,
        })
        if (active) {
          setMobileAsset(result.data.mobileAsset as MobileAsset)
        }
      } catch(e) {
        
      }
    }
    if (!active) {
      active = true
      fetch()
    }
    return () => {
      active = false
    }
  }, [])
  return [ mobileAsset ]
}