export enum ProjectTypeId {
  Asphalt = 'ASPHALT',
  SpraySeal = 'SPRAY-SEAL',
  AsphaltAndSpraySeal = 'ASPHALT-SPRAY-SEAL',
}

export type NormalizedProjectType = 'asphalt' | 'spraySeal'
export function normalizeProjectTypeId(id: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal): NormalizedProjectType {
  switch(id) {
    case ProjectTypeId.Asphalt: return 'asphalt'
    case ProjectTypeId.SpraySeal: return 'spraySeal'
  }
}

export function formatProjectTypeId(id: ProjectTypeId): string {
  return {
    'ASPHALT': 'Asphalt',
    'SPRAY-SEAL': 'Spray Seal',
    'ASPHALT-SPRAY-SEAL': 'Asphalt & Spray Seal',
  }[id]
}