import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { Depot } from '@src/types/common/Depot'
import { Profile } from '@src/types/common/Profile'
import { Customer } from '@src/types/Customer'

interface Options {
  projectInternalId?: number,
  depot: Depot,
  location: string,
  collaborators: Profile[],
  customer: Customer,
  customerRepresentativeName: string,
  customerRepresentativeContactNumber: string,
  defaultProjectManager: string,
  projectColorCode: string
}


export const apiProjectCreate = (options: Options) => {
  const { depot, location, collaborators, customer, customerRepresentativeName ,customerRepresentativeContactNumber, defaultProjectManager,projectColorCode } = options
  const method = `POST` 
  const url = `${BASE_API_URL}/v2/projects`
  const axOptions = {
    method,
    url,
    data: {
      'depot': {
        'id': depot.id
      },
      'location': location,
      'customer': {
        id: customer.id,
      },
      'projectTeam': collaborators.map((profile: Profile) => {
        return {
          serialId: profile.serialId
        }
      }),
      customerRepresentativeName,
      customerRepresentativeContactNumber,
	  defaultProjectManager,
    projectColorCode
    }
  }
  return ax(axOptions)
}

export const apiProjectUpdate = (options: Options) => {
  const { projectInternalId, depot, location, collaborators, customer, customerRepresentativeName ,customerRepresentativeContactNumber, defaultProjectManager,projectColorCode } = options
  const method = `PUT` 
  const url = `${BASE_API_URL}/v2/projects/${projectInternalId}`
  const axOptions = {
    method,
    url,
    data: {
      projectInternalId,
      'depot': {
        'id': depot.id
      },
      'location': location,
      'customer': {
        id: customer.id,
      },
      'projectTeam': collaborators.map((profile: Profile) => {
        return {
          serialId: profile.serialId
        }
      }),
      customerRepresentativeName,
      customerRepresentativeContactNumber,
	  defaultProjectManager,
    projectColorCode
    }
  }
  return ax(axOptions)
}