import moment, { Moment } from 'moment'

export function adjustDateTimeToDate(time: Moment | string | undefined, date: string) {
  if (!time) {
    return null
  }
  time = moment(time)
  const drsYYYYMMDD = moment(date).format('YYYY-MM-DD')
  const selectedTimeHHmm = time.format('HH:mm')
  return moment(`${drsYYYYMMDD} ${selectedTimeHHmm}`).toISOString()
}