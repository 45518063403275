import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import _reject from "lodash/reject";
import _merge from "lodash/merge";
import _isEmpty from "lodash/isEmpty";
import _unionBy from "lodash/unionBy";
import styled, { css } from "styled-components";
import _union from "lodash/union";
import _find from "lodash/find";
import _some from "lodash/some";
import { useCrewCalender } from "@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";
import { DragItemTypes } from "@src/types/DragItemTypes";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";

interface Props {
  data: any;
  inDragLayer: boolean;

  id: string;
  index: number;

  moveCard(dragIndex: number, hoverIndex: number): void;
}

const ShiftCardCrewItem = ({
  data,
  inDragLayer,

  id,

  index,
  moveCard,
}: Props) => {
  const ref: any = useRef();

  const {
    selectedShiftCardViewsCount,
    selectedShiftCardViews,

    isShiftCardViewDragHappening,
  } = useCrewCalender();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [canDragShift, setCanDragShift] = useState<boolean>(true);

  useEffect(() => {
    let found = selectedShiftCardViews.find((o: any) => o.id === data.id);
    if (found) setIsSelected(true);
    else setIsSelected(false);
  }, [selectedShiftCardViewsCount]);

  const style = {
    border: "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",

    cursor: "move",
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.ShiftCardCrewItem,
      shift: data,
      index: index,
    },

    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop({
    accept: DragItemTypes.ShiftCardCrewItem,

    hover(item: any, monitor) {
      //  console.log(ref.current);
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      //  console.log('--------'+dragIndex+'---'+hoverIndex)
      //  // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <Wrapper
      key={id}
      ref={ref}
      isSelected={isSelected}
      inDragLayer={inDragLayer}
      data-handler-id={handlerId}
      isDragHappening={isDragging}
      id={id}
      style={{ ...style, opacity }}
    >
      {data.name}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  isSelected: boolean;
  inDragLayer: boolean;
  isDragHappening: boolean;
}>`
  padding: 10px;
  background-color: #0a9688;
  color: #fff;
  border: 1px solid #ececec;
  margin: 5px 0px;
  cursor: pointer;
`;

export default observer(ShiftCardCrewItem);
