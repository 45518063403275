export interface APIRegionDepot {
  state: AIMS.State,
  region: AIMS.Region,
  depot: AIMS.Depot,
}

export const ADRegionDepot = (item: APIRegionDepot) => {
  const { depot, region, state } = item
  if (!depot || !region || !state) return {}
  return {
    label: depot.name,
    value: { depotId: depot.id, depotName: depot.name, regionId: region.id, regionName: region.name, stateId: state.id, stateName: state.name },
  }
}