import _set from 'lodash/set'
import _get from 'lodash/get'
import { ASSET_LOCATION_OPTIONS } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import { isAS, isSS } from '../EditDRSStore'

export default function getReworkSectionData(data: DRS.RootData) {
  const asReworkSummary = getReworkSummary(true, data)
  const asAssets = getAssetPanels(true, data)
  const ssReworkSummary = getReworkSummary(false, data)
  const ssAssets = getAssetPanels(false, data)
  let panels: any[] = []
  if (isAS(data)) {
    panels = [
      asReworkSummary, 
      ...asAssets,  
    ]
  }
  if (isSS(data)) {
    panels = [ 
      ...panels, 
      ssReworkSummary,
      ...ssAssets,
    ]
  }
  const section = {
    label: 'REWORK',
    panels,
  }
  return section
}

function getReworkSummary(isAs: boolean, data: DRS.RootData) {
  const label = isAs ? 'Rework Asphalt' : 'Rework Spray Seal'
  const path = isAs ?  `rework.asphalt.summary` : `rework.spraySeal.summary`
  const reworkSummary = {
    label,
    repeatable: false,
    path,
    fields: [
      ...getReworkSummaryFields(isAs, data, path),
    ],
  }
  return reworkSummary
}

function checkPerformed({
  rootData,
  parentPathOfPerformed,
  planOrActual = 'plan',
}: {
  rootData: DRS.RootData,
  parentPathOfPerformed: string,
  planOrActual: 'plan' | 'actual',
}) {
  const parentData = _get(rootData, `${parentPathOfPerformed}`)
  const { performed, detail, summary } = parentData
  // if not performed for the whole, definitely, it's not performed
  if (!performed) {
    return false
  }
  let summaryFlag = false
  // if summary is not deleted, it's performed
  if (summary[planOrActual] && 
      (!summary[planOrActual].deleted && summary[planOrActual].available)) {
      summaryFlag = true
  }
  let detailFlag = false
  // if all detail not deleted, it's performed
  if (detail
      .some((asset: any) => {
        return asset[planOrActual] && (!asset[planOrActual].deleted && asset[planOrActual].available)
      })
  ) {
    detailFlag = true
  }
  if (planOrActual === 'actual') {
    console.log(summaryFlag, detailFlag)
  }
  if (summaryFlag || detailFlag) {
    return true
  }
  return false
}

export const NOT_INIT = 'not-init' // no object 
export const NOT_PERFORMED = 'not-performed' // all deleted
export const AGREED = 'agreed' // all not available and not deleted
export const EDITED = 'edited' // some available

function getEditState ({
  rootData,
  parentPathOfPerformed,
}: {
  rootData: DRS.RootData,
  parentPathOfPerformed: string,
}) {
  const parentData = _get(rootData, `${parentPathOfPerformed}`)
  const { performed, detail, summary } = parentData
  if (!performed) {
    return NOT_INIT
  }
  let deleteCount = 0
  let availableCount = 0
  if (
    summary.edit.deleted
  ) {
    deleteCount ++
  }
  if (
    summary.edit.available
  ) {
    availableCount ++
  }
  detail.forEach((item: any) => {
    if (item.edit.deleted) {
      deleteCount ++
    }
    if (item.edit.available) {
      availableCount ++
    }
  })
  if (availableCount === 0) {
    if (deleteCount === 0) {
      return AGREED
    } else {
      return NOT_PERFORMED
    }
  } else {
    return EDITED
  }
}

export function getNull (obj: any) {
  let result = {
    ...obj
  }
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      result[key] = { id: null }
    } else if (typeof obj[key] === 'boolean') {
      // result[key] = obj[key]
      // result[key] = undefined
    } else {
      result[key] = null
    }
  })
  return result
}

function getReworkSummaryFields(isAs: boolean, data: any, path: string) {
  const label = isAs ? 'Any Asphalt Rework Performed?' : 'Any Spray Seal Rework Performed?'
  const performedPath = isAs ? `rework.asphalt.performed` : `rework.spraySeal.performed`
  const parentPath = isAs ? `rework.asphalt` : `rework.spraySeal`
  const performedField = {
    label,
    type: 'performed',
    absolutePath: performedPath,

    meta: {
      parentPath,
      planPerformed: checkPerformed({ rootData: data, parentPathOfPerformed: parentPath, planOrActual: 'plan'}),
      actualPerformed: checkPerformed({ rootData: data, parentPathOfPerformed: parentPath, planOrActual: 'actual'}),
      editState: getEditState({ rootData: data, parentPathOfPerformed: parentPath}),
      template: {
        detail: {
          'mobileAssetDepotId': '',
          'mobileAsset': {
            'id': '',
            'description': '',
          },
          'hours': '',
        },
        summary: {
          reworkEmulsion: '',
          reworkLabourHours: '',
          reworkPercentage: '',
          reworkQuantity: '',
        }
      }
    },
    editable: true,
    format: ({ performedCombinationCode, colName }: {
      performedCombinationCode: any,
      colName: DRS.PlanActualEdit,
    }) => {
      if (colName === 'edit') {
        if ([2, 5].includes(performedCombinationCode)) {
          return 'NO'
        } else {
          return 'YES'
        }
      } else if (colName === 'actual') {
        if ([1, 2, 3, 4, 5, 6].includes(performedCombinationCode)) {
          return 'YES'
        } else {
          return 'NO'
        }
      } else if (colName === 'plan') {
        if ([1, 2, 3, 7, 9].includes(performedCombinationCode)) {
          return 'YES'
        } else {
          return 'NO'
        }
      }
    },
  }
  let restFields: any[] = []
  if (_get(data, performedPath)) {
    restFields = [
      {
        label: '% of Rework',
        type: 'number',
        path,
        relativePath: 'reworkPercentage',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Rework Tonnes',
        type: 'number',
        path,
        relativePath: 'reworkQuantity',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Hours - Labour',
        type: 'number',
        path,
        relativePath: 'reworkLabourHours',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Emulsion (L)',
        type: 'number',
        path,
        relativePath: 'reworkEmulsion',
        editable: true,
        showTrending: true,
      },
    ].map(item => {
      return {
        ...item,
        showDeletedFunc: ({
          rootData,
          panelPath, 
          relativePath,
        }: {
          rootData: any,
          panelPath: string, 
          relativePath: string,
        }) => {
          const deletedIndicator = _get(rootData, `${panelPath}.edit.deleted`)
          return deletedIndicator
        }
      }
    })
  }
  
  return [ performedField, ...restFields ]
}

function getAssetPanels(isAs: boolean, data: any) {
  const label = isAs ? `Rework Asphalt Assets` : `Rework Spray Seal Assets`
  const path = isAs ? `rework.asphalt.detail` : `rework.spraySeal.detail`
  const assetsData = _get(data, path)
  const assets = assetsData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: index !== 0,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'mobileAssetDepotId': {
          id: '',
        },
        'mobileAsset': {
          'id': '',
          'description': '',
        },
        'hours': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getAssetFields(`${path}.${index}`),
    }
  })
  return assets
}
function getAssetFields(path: string) {
  const shouldHideFunc = ({
    data,
    planOrEdit, // asset.0.plan
  }: {
    data: any,
    planOrEdit: 'plan' | 'edit',
  }) => {
    const asset = _get(data, `${path}.${planOrEdit}`)
    if (!asset) {
      return true
    }
    const { hours, mobileAsset } = asset
    if (hours === null && mobileAsset.id === null) {
      return true
    }
    return false
  }

  return [
    {
      label: 'Asset Depot',
      type: 'select',
      // shouldHideFunc,
      path,
      relativePath: 'mobileAssetDepotId',
      editable: true,
      options: ASSET_LOCATION_OPTIONS.map(item => ADIDNameToValueLabel(item)),
      format: (value: any) => value ? value.id : '',
    },
    {
      label: 'Mobile Asset Number',
      type: 'asset-autocomplete',
      // shouldHideFunc,
      path,
      relativePath: 'mobileAsset',
      editable: true,
      format: (value: any) => value ? value.id : '',
    },
    {
      label: 'Asset Description',
      type: 'text',
      // shouldHideFunc,
      path,
      relativePath: 'mobileAsset.description',
      editable: true,
      readonly: true,
    },
    {
      label: 'Asset Hours',
      type: 'number',
      // shouldHideFunc,
      path,
      relativePath: 'hours',
      editable: true,
      showTrending: true,
    },
  ]
}

