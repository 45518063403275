import React from 'react'
import styled from 'styled-components'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import DRSReworkAssetsFields, { DRSReworkAssetDisplay, DRSAssetDepotType } from '../../2-Rework/parts/ReworkAssetsFields'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'
import { DRSReworkAssetPAE } from '@src/types/drs/rework/Rework'
import { DRSReworkAssetRow } from '../../2-Rework/parts/ReworkAssets'
import { transformAssetData, transformMobileAssetData } from '../../2-Rework/utils/transformFromFormData'
import DRSMobileAssetsFields, { DRSMobileAssetDisplay } from './MobileAssetsFields'
import { DRSMobileAssetPAE } from '@src/types/drs/mobile-assets/MobileAsset'


export interface DRSMobileAssetRow extends DRSMobileAssetDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}
const NULL_VALUES = {
  comments: null,
  hours: null,
  mobileAsset: null,
}
const ZERO_VALUES = {
  comments: '',
  hours: '0',
  mobileAsset: null,
}

interface Props {
  projectTypeId: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal,
  type: any
}

const PartDRSMobileAssets = (props: Props) => {
  const {
    projectTypeId,
    type
  } = props
  const normalizedType = normalizeProjectTypeId(projectTypeId)
  const { id } = useParams<{ id: string }>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSMobileAssetRow>({})
  const { depotId, stateId } = useAppStore()
  if (drs === null) {
    return null
  }
  const onSave = async (form: DRSMobileAssetRow) => {

    if (modalData === null) {
      addItem(form)
    } else {
      editItem(form)
    }
  }
  const fieldKeys: any = [
    'assetDepotType',
    'mobileAsset',
    'assetDescription',
    'hours',
    'backupMachine'

  ];

  if(type === 1) 
      fieldKeys.push('notUsed');
const tblFields: any = DRSMobileAssetsFields;

if(type === 0) {
 
  delete tblFields[tblFields.length -1];
}else{
  delete tblFields[4];
  delete fieldKeys[4];
}
  const addItem = (form: DRSMobileAssetRow) => {
    const retained = getRetained()
    const finalForm = transformMobileAssetData(form);
    const finalDataForm: any = finalForm;
    if (typeof finalDataForm.backupMachine != "undefined") {
      const backUpDataField: boolean = finalDataForm.backupMachine[0] == 'Asset Used' ? true : false;
      finalDataForm.backupMachine = backUpDataField;
    }
  
    if (typeof finalDataForm.notUsed != "undefined") {
      const notUsedField: boolean = finalDataForm.notUsed[0] == 'Asset Used' ? false : true;
      finalDataForm.notUsed = notUsedField;
      // finalDataForm.mobileAsset.notUsed = notUsed;
    }
   
    const updatedData = [
      ...retained,
      {
        [updatingColumn]: {
          ...finalDataForm
        }
      }
    ];
 
    update(updatedData)
  }
  const handleSaveToAll = async (form: DRSMobileAssetRow) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure to apply Hours to all mobile assets?'
      })
      const finalForm = transformMobileAssetData(form)
      saveToAll(finalForm)
    } catch (e) { }
  }
  const saveToAll = async (form: DRSMobileAssetRow) => {
    const retained = getRetained()
    const updatedAssets = retained.map((asset: DRSMobileAssetPAE) => {
      if (isPlan) {
        return {
          ...asset,
          [updatingColumn]: {
            ...asset[updatingColumn],
            hours: form.hours,
            // backupMachine:form.backupMachine
          }
        }
      } else {
        let finalAsset = asset.edit.mobileAsset
        if (!finalAsset) {
          finalAsset = asset.actual.mobileAsset
        }
        return {
          ...asset,
          [updatingColumn]: {
            ...asset[updatingColumn],
            hours: form.hours,
            mobileAsset: finalAsset,
          }
        }
      }
    })

    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const retainedAsset = updatedAssets[updatingIndex]
    const data = [
      ...updatedAssets.slice(0, updatingIndex),
      {
        ...retainedAsset,
        [updatingColumn]: {
          ...form
        }
      },
      ...updatedAssets.slice(updatingIndex + 1),
    ]
    update(data)
  }
  const editItem = (form: DRSMobileAssetRow) => {
    const finalForm = transformAssetData(form)
    const finalDataForm: any = finalForm;
    if (typeof finalDataForm.backupMachine != "undefined") {
      const backUpDataField: boolean = finalDataForm.backupMachine[finalDataForm.backupMachine.length - 1] == 'Y' ? true : false;
      finalDataForm.backupMachine = backUpDataField;
    }
    
    if (typeof finalDataForm.notUsed != "undefined") {
      console.log('-----');
      console.log(finalDataForm.notUsed);
      console.log('-----');
      const notUsedField: boolean = finalDataForm.notUsed[finalDataForm.notUsed.length - 1] == 'Asset Used' ? false : true;
      finalDataForm.notUsed = notUsedField;
     
    }
   
    updateItemByValues(form, finalDataForm)
  }
  function handleRevert(form: DRSMobileAssetRow) {
    updateItemByValues(form, NULL_VALUES)
  }
  function handleMarkZero(form: DRSMobileAssetRow) {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = (form: DRSMobileAssetRow) => {
    const retained = getRetained()
    if (form._no_ === undefined || form._type_ === undefined) {
      return []
    }
    const updatingIndex = form._no_
    const data = [
      ...retained.slice(0, updatingIndex),
      ...retained.slice(updatingIndex + 1),
    ]

    update(data)
  }
  const getRetained = () => {
    return drs.mobileAssets.boral[normalizedType].assets || []
  }
  const getUpdatingDetails = (originDetails: DRSMobileAssetPAE[], form: DRSMobileAssetRow, values: any) => {
    if (form._no_ === undefined || form._type_ === undefined) {
      return originDetails
    }
    const updatingIndex = form._no_
    const retainedDetail = originDetails[updatingIndex]
    return [
      ...originDetails.slice(0, updatingIndex),
      {
        ...retainedDetail,
        [updatingColumn]: {
          ...values
        }
      },
      ...originDetails.slice(updatingIndex + 1),
    ]
  }
  const updateItemByValues = (form: DRSMobileAssetRow, values: any) => {
    const retained = getRetained()
    update(getUpdatingDetails(retained, form, values))
  }
  const update = (data: any) => {
  
    genericUpdateDRSSection({
      sectionName: DRSSectionName.MobileAssets,
      path: `boral.${normalizedType}`,
      data: {
        assets: data,
        performed: true,
      },
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const getTableData = () => {
    const assets = drs.mobileAssets.boral[normalizedType].assets
    if (!assets || !assets.length) {
      return []
    }
    const doubled = doubleUp(assets, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions.map((asset: any) => {
      //console.log('--->>>',JSON.stringify(asset));
      let assetDepotType = null
      if (isEdit)
        asset._editable_ = true;
        if (isPlan)
            delete asset.notUsed;
      if (asset.mobileAsset && asset.mobileAsset.depot && asset.mobileAsset.depot.id) {
        if (asset.mobileAsset.depot.id === depotId) {
          assetDepotType = {
            id: DRSAssetDepotType.MyDepot,
            description: 'My Depot',
          }
        } else {
          assetDepotType = {
            id: DRSAssetDepotType.MyState,
            description: 'My State',
          }
        }
      }
     
     // console.log('----->>>>>',asset._type_,asset.notUsed)
      return {
        ...asset,
        assetDepotType,
        assetDescription: asset.mobileAsset ? asset.mobileAsset.description : ''
       
      }
    })
  }
  return <Wrapper>
    <FormPanel title={`Mobile Assets`}>
      <FormPAETable<DRSMobileAssetRow>
        fields={tblFields}
        data={getTableData()}
        PAEType={'mobileassets'}
        editable={true}
        isPlan={isPlan}
        isSelectable={true}
        onEditRow={(row: DRSMobileAssetRow) => {
          let rowBackupMachine: any = row.backupMachine;
          let backUpMachineData: any = rowBackupMachine == true ? ['Y'] : ['N'];

          let rowNotused: any = row.notUsed;
          let rowNotusedData: any = rowNotused == true ? ['Asset Not Used'] : ['Asset Used'];
          if(isPlan)
          openAndSetData({
            ...row,
            hours: { id: row.hours } as any,
            backupMachine: backUpMachineData
            

          })
          else
          openAndSetData({
            ...row,
            hours: { id: row.hours } as any,
            backupMachine: backUpMachineData,
            notUsed: rowNotusedData

          }) 
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Mobile Asset
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSMobileAssetRow>
        maxWidth={'xs'}
        open={open}
        title='Mobile Assets'
        data={{
          rowData: modalData,
          fields: DRSMobileAssetsFields,
          columns: [
            {
              title: 'Asset Information',
              fieldKeys: fieldKeys,
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={onSave}
        onClose={closeModal}
        onCancel={closeModal}
        extraAction1Label={modalData === null ? '' : 'Apply To All'}
        onExtraAction1={handleSaveToAll}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default PartDRSMobileAssets