import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSLabourSummary } from '@src/types/drs/labour/Labour'

export interface CrewSummaryRowDisplay extends DRSLabourSummary {
  // crewCount: number,
}

export const CrewSummaryFields: FormTableField<CrewSummaryRowDisplay>[] = [
  {
    key: 'totalCrewCount',
    type: FormTableFieldType.Readonly,
    label: 'Crew Count',
  },
  {
    key: 'crewHours',
    type: FormTableFieldType.Readonly,
    label: 'Crew Hours',
  },
  {
    key: 'foremanHours',
    type: FormTableFieldType.Readonly,
    label: 'Foreman Hours',
  },
  {
    key: 'supervisorHours',
    type: FormTableFieldType.Readonly,
    label: 'Supervisor Hours',
  },
  {
    key: 'pmHours',
    type: FormTableFieldType.Number,
    label: 'PM Hours',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'totalHours',
    type: FormTableFieldType.Readonly,
    label: 'Total Hours',
  },
]

export default CrewSummaryFields