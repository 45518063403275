import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSLabourSummary } from '@src/types/drs/labour/Labour'

export interface CrewSummaryRowDisplay extends DRSLabourSummary {

}

export const CrewExtraFields: FormTableField<CrewSummaryRowDisplay>[] = [
  {
    key: 'accommodation',
    type: FormTableFieldType.Number,
    label: 'Accommodation',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'hoursOnSite',
    type: FormTableFieldType.Number,
    label: 'Hours on site',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'siteAllowance',
    type: FormTableFieldType.Text,
    label: 'Site Allowance',
  },
]

export default CrewExtraFields