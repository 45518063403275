import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { ADIDNameToValueLabel } from '../../api-adaptors/id-name-to-value-label'
import { apiProfileSearch } from '../../apis/profile-search'
import { CONST_ROLES_ENUM } from '../../constants/roles-enum'

interface Options extends AIMS.Autocomplete {
  stateId: string,
  onChange(value: { raw: AIMS.Profile }): void,
}
const ForemanAutocomplete = ({
  stateId,
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
}: Options) => {
  const fetch = async (query: string) => {
    const result: any = await apiProfileSearch({
      query,
      stateId,
      roleId: CONST_ROLES_ENUM.FOREMAN,
    })
    return result.data.profiles
      .slice(0, 5)
      .map((profile: AIMS.Profile) => {
        const { email, firstName, lastName } = profile
        return {
          value: email,
          label: `${firstName} ${lastName}`,
          raw: profile,
        }
      })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={'Foreman'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default ForemanAutocomplete