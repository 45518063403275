import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useProjectFilter, getFilterOptions, FilterBy } from '@src/modules/HeaderFilters/ProjectFilter/ProjectFilterStore'
import { useAppStore } from '@src/AppStore'
import { apiProjects, apiQueryProjects } from '@src/apis/projects'
import EmptyListHint from '@src/modules/ProjectManager/Empty'
import ProjectsGroupByDepot from '@src/modules/ProjectManager/comps/ProjectsGroupByDepot'
import AssetMgmt from '../Dashboard/parts/AssetMgmt'
import { observer } from 'mobx-react-lite'
import CreateProjectModal from './parts/CreateProjectModal'
import IconButton from '@material-ui/core/IconButton'
import { Project } from '@src/types/project/Project'
import usePermission from '@src/utils/hooks/usePermission'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const DashboardProjects = () => {
  const [refreshToken, setRefreshToken] = useState<number>(0)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<Project[]>([])
  const { filterBy, projectNumber, PM, setPM, status, depot, setDepot } = useProjectFilter()
  const { stateId, userEmail, depotId: globalDepotId, depotName, username: globalUsername, profile, appStoreRefreshHook } = useAppStore()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { canCreateDraftProject } = usePermission()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (appStoreRefreshHook) {
      setRefreshToken(refreshToken + 1)
    }
  }, [appStoreRefreshHook])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const fetchProjects = async () => {
    try {
      let result: any
      setIsLoading(true)
      if (filterBy === FilterBy.CONDITIONS) {
        if (!status || !globalDepotId || !depotName) {
          setIsLoading(false)
          return
        }
        let options: any = {
          userSerialId: PM ? PM.serialId : undefined,
          status,
        }
        { depot ? options.depot = depot : options.depot = { name: depotName, id: globalDepotId } }
        result = await apiProjects(options)
      } else {
        if (!projectNumber) {
          setIsLoading(false)
          return
        }
        const options = {
          query: projectNumber
        }
        result = await apiQueryProjects(options)
      }
      // const result: Promise<AIMS.Result> = await apiProjects(options)
      if (firstLoad) {
        setFirstLoad(false)
      }
      if (result.data && result.data.projects) {
        setIsLoading(false)
        setProjects(result.data.projects)
      } else {
        // we queried and got no project
        setIsLoading(false)
      }
    } catch (error) {
      setProjects([])
    }
  }

  // set options on first load
  useEffect(() => {
    setDepot({ name: depotName, id: globalDepotId })
    setPM(profile!)
  }, [])
  useEffect(() => {
    // console.log('fire')
    fetchProjects()
  }, [filterBy, projectNumber, depot, status, PM, refreshToken])
  // refresh on status changed
  const handleStatusChanged = (project: Project) => {
    setRefreshToken(refreshToken + 1)
  }
  // refresh on create success
  const handleSuccess = () => {
    setRefreshToken(refreshToken + 1)
  }
  const renderEmpty = () => {
    if (!firstLoad && projects.length === 0) {
      return <EmptyListHint />
    }
  }
  return <React.Fragment>
    <Wrapper>
      <LeftWrapper>
        {canCreateDraftProject &&
          <ActionWrapper>
            <IconButton onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id='project action menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                setModalOpen(true)
                setAnchorEl(null)
              }}>Add draft project</MenuItem>
            </Menu>
          </ActionWrapper>
        }
        <ProjectsWrapper>
          <ProjectsGroupByDepot
            projects={projects}
            afterStatusChanged={handleStatusChanged}
          />
          {isLoading && <SpinnerWrapper><Spinner size={50} /></SpinnerWrapper>}
          {!isLoading && renderEmpty()}
        </ProjectsWrapper>
      </LeftWrapper>
      <AssetMgmt />
    </Wrapper>
    {modalOpen &&
      <CreateProjectModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        onSuccess={() => {
          setRefreshToken(refreshToken + 1)
          setModalOpen(false)
        }}
      />
    }
  </React.Fragment>
}

const Wrapper = styled.div`
  display: flex;
`
const LeftWrapper = styled.div`
  flex-grow: 1;
`
const ProjectsWrapper = styled.div`
`
const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const Spinner = styled(CircularProgress)`
  position: relative;
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
`

export default observer(DashboardProjects)