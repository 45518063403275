import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { useAppStore } from '@src/AppStore'
import usePermission from '@src/utils/hooks/usePermission'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core'
import FormModalTextareaLarge from '@src/components/FormModal/TextareaLarge'
import { getLength } from '@src/utils/validateLength'
import { apiCreateShiftNote } from '@src/apis/shift-note/shift-note-search'
import { apiUpdateShiftNote } from '@src/apis/shift-note/shift-note-update'
import { useConfirm } from '@src/components/GlobalConfirmDialog'

interface Props {
    open: boolean,
    ShiftID?: number,
    commentData?: any,
    onClose(): void,
    onSave(): void,
    createdBy?: string,
    fetchComments(): void,
}

const CommentDialog = (props: Props) => {
    const {
        open,
        ShiftID,
        onClose,
        onSave,
        fetchComments,
        commentData
    } = props
    const { confirm } = useConfirm()
    const [noteDesc, setNoteDesc] = useState<string>('')
    const [shiftCalendarNotesID, setShiftCalendarNotesID] = useState<string>('')
    const { username } = useAppStore()
    const { alertDone, alertError, alert } = useSnackbar()
    const { canEditShiftNote } = usePermission()
    const [isSaving, setIsSaving] = useState<any>(false)
    const [isDeleting, setIsDeleting] = useState<any>(false)
    const [fieldsInError, setFieldsInError] = useState<any[]>([])
    const [isAcknowledge, setIsAcknowledge] = useState<boolean>(false)
    const [isAcknowledgeDiabled, setIsAcknowledgeDisabled] = useState<boolean>(false)
    const [remainigChars, setRemainigChars] = useState<number>(1000)
    
    useEffect(() => {
            if(commentData){
                if(commentData.ShiftCalendarNotesID){
                    setShiftCalendarNotesID(commentData.ShiftCalendarNotesID);
                    setNoteDesc(commentData.Comment);
                    setIsAcknowledge(commentData.IsAcknowledge);

                    setIsAcknowledgeDisabled(true);
                    setRemainigChars(1000 - commentData.Comment.length);
                }
            }
    }, [commentData])
    let validateNotes: any = null;
    let date = new Date()

    const handleChangeCheckBox = (event: any) => {
        setIsAcknowledge(event.target.checked)
    }
    const handleSave = async () => {
        if (!ShiftID) {
            alert({
                type: 'error',
                message: 'ShiftID is not available',
            })
            onClose()
            return
        }

        if (!noteDesc) {

            alert({
                type: 'error',
                message: 'Note description required',
            })
            return
        }

        if (noteDesc.length > 1000) {
            alert({
                type: 'error',
                message: 'Max 1000 characters allowed in Note description.',
            })
            return
        }

        setIsSaving(true)
        try {
          
            if(shiftCalendarNotesID!='') { 

              
                apiUpdateShiftNote({
                    Comment: noteDesc,
                    ShiftID: ShiftID!,
                    ShiftCalendarNotesID:shiftCalendarNotesID,
                    Date: commentData.Date,
                    CreatedBy: username,
                    Active: true,
                    IsAcknowledge: isAcknowledge
    
                }).then((response: any) => {
                    alertDone()
                    setIsSaving(false)
                    onSave()
                    fetchComments()
                    onClose()
                })
            }
            else {
                
                apiCreateShiftNote({
                    Comment: noteDesc,
                    ShiftID: ShiftID!,
                    Date: date.toISOString(),
                    CreatedBy: username,
                    Active: true,
                    IsAcknowledge: isAcknowledge
    
                }).then((response: any) => {
                    alertDone()
                    setIsSaving(false)
                    onSave()
                    fetchComments()
                    onClose()
                })
            }
         
        } catch (e) {
            alertError()
            onClose()
        }
    }

    const handleDelete = async () => {
        if (!shiftCalendarNotesID) {
            alert({
                type: 'error',
                message: 'ShiftID is not available',
            })
            onClose()
            return
        }


        setIsDeleting(true)
        try {
    
                apiUpdateShiftNote({
                    Comment: commentData.Comment,
                    ShiftID: ShiftID!,
                    ShiftCalendarNotesID:shiftCalendarNotesID,
                    Date: commentData.Date,
                    CreatedBy: username,
                    Active: false,
                    IsAcknowledge: isAcknowledge
    
                }).then((response: any) => {
                    alertDone()
                    setIsDeleting(false)
                    onSave()
                    fetchComments()
                    onClose()
                })
            
           
         
        } catch (e) {
            alertError()
            onClose()
        }
    }
    const beforeDelete = async (e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
        try {
          if (shiftCalendarNotesID == '') {
            alert({
              type: 'error',
              message: `Note can't be deleted .`
            })
            onClose()
            return
          }
    
          await confirm({
            title: 'Confirm Deletion of Shift Note',
            message: 'This will permanently remove shift note from the system and cannot be undone.',
          })
          handleDelete()
        } catch (e) { }
      }
    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={onClose}
    >
        <DialogTitle >
            <ModalTitle>Shift Note</ModalTitle>
        </DialogTitle>
        <DialogContent>
            <SectionWrapper>
                <Section>
                    <FieldWrapper>
                        <FormModalTextareaLarge label={'Comment description'} defaultValue={noteDesc.replace('blank', '')}   rows={6} disabled={!canEditShiftNote} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                            var val = e.target.value;
                           setRemainigChars(1000 - val.length);
                            if (validateNotes != null) {
                                clearTimeout(validateNotes)
                            }
                            validateNotes = setTimeout(() => {
                                if (getLength(val, 1000)) {
                                    setFieldsInError(_union(fieldsInError, [{ key: 'Comment description', helperTextError: "Max 1000 characters allowed" }]))
                                } else {
                                    setFieldsInError(_reject(fieldsInError, { key: 'Comment description' }))
                                }
                            }, 500)
                        }}
                            onBlur={(e) => {
                                setNoteDesc(e.target.value)
                            }}
                            helperText={
                                _some(fieldsInError, { key: 'Comment description' }) ?
                                    _find(fieldsInError, { key: 'Comment description' }).helperTextError
                                    : remainigChars >= 0 ? remainigChars+"/1000" : ""
                            }
                            error={_some(fieldsInError, { key: 'Comment description' })}
                        ></FormModalTextareaLarge>
                    </FieldWrapper>
                </Section>
            </SectionWrapper>
            <FormControlLabel
                checked={isAcknowledge}
                disabled={isAcknowledgeDiabled}
                onChange={handleChangeCheckBox}
                control={
                    <Checkbox size="small" />}
                label="Acknowledgement Required" />
        </DialogContent>

        <DialogActions>
            <Button onClick={(e: React.SyntheticEvent<any>) => {
                e.stopPropagation()
                onClose()
            }}>
                Cancel
            </Button>

            <Button color='primary' variant='contained' onClick={handleSave} disabled={isSaving || fieldsInError.length > 0 ? true : false || !canEditShiftNote}>
                {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
                {!isSaving && 'SAVE '}
            </Button>
            {shiftCalendarNotesID && <Button  variant='contained' style={{backgroundColor:'red',color:'white'}} onClick={beforeDelete} >
                {isDeleting && <React.Fragment><Spinner size={16} /> {`Deleting`} </React.Fragment>}
                {!isDeleting && 'Delete '}
            </Button> }
        </DialogActions>
    </Dialog>

}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`


const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width:400px;
  
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`


export default CommentDialog