import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { DRSMaterialAsphaltItem } from '@src/types/drs/material/MaterialAsphaltItem'
import { DRSCrewMember } from '@src/types/drs/labour/Labour'
import { Profile } from '@src/types/common/Profile'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import { getStartEndDiff, getHoursByLunchTaken } from '../utils/getHours'

export interface DRSCrewMembersFieldsRowDisplay extends DRSCrewMember {
  id: number,
  present: boolean,
  profile: Profile,
}

const DRSCrewMembersFields: FormTableField<DRSCrewMembersFieldsRowDisplay>[] = [
  {
    key: 'profile',
    type: FormTableFieldType.Readonly,
    label: 'Name',
    disabled: true,
    format: (profile: Profile) => formatProfileOrNullFirstLastName(profile),
  },
  {
    key: 'present',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Present',
    format: formatBooleanOrNullToYesNo,
    alsoUpdates: ['startTime', 'endTime', 'hours', 'laha', 'lunchTaken'],
    alsoUpdatesFunc: (present: any, form: any) => {
      if (!present) {
        return [null, null, '', null, null]
      }
      return []
    }
  },
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time',
    format: (timeString: string) => formatDateStringHHmm(timeString),
    alsoUpdates: ['hours', 'present'],
    alsoUpdatesFunc: (startTime: any, form: any) => {
      let hours = getStartEndDiff({
        start: startTime,
        end: form.endTime,
      })

      if (startTime) {
        return [hours, true]
      }

      return [hours, null]
    }
  },
  {
    key: 'endTime',
    type: FormTableFieldType.Time,
    label: 'End Time',
    format: (timeString: string) => formatDateStringHHmm(timeString),
    alsoUpdates: ['hours'],
    alsoUpdatesFunc: (endTime: any, form: any) => {
      return [
        getStartEndDiff({
          start: form.startTime,
          end: endTime,
        })
      ]
    }
  },
  {
    key: 'hours',
    type: FormTableFieldType.Readonly,
    label: 'Hours',
    disabled: true,
  },
  {
    key: 'laha',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Living Away from Home',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'lunchTaken',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Lunch Taken',
    format: formatBooleanOrNullToYesNo,
    alsoUpdates: ['hours'],
    alsoUpdatesFunc: (lunchTaken: any, form: any) => {
      let hours = getStartEndDiff({
        start: form.startTime,
        end: form.endTime,
      })
      hours = getHoursByLunchTaken(hours, lunchTaken)
      return [hours]
    }
  },
  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comments',
  },
]

export default DRSCrewMembersFields