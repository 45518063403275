import React, { useContext } from 'react'
import { observable, action, toJS } from 'mobx'
import axios from 'axios'

const latencyThreshold = 100

const status: any = observable({
  reqsTotal: 0,
  reqsCompleted: 0,
  progress: 0,
  setProgress: action((progress: number) => {
    status.progress = progress
  }),
  reqStart: action(() => {
    status.reqsTotal++
  }),
  reqComplete: action(() => {
    if (status.reqsTotal > 0) {
      status.reqsCompleted++
    }
  }),
  loading: false,
  setLoading: action((value: boolean) => {
    status.loading = value
  })
})

let autoIncrement = true
let incTimeout: any
let startTimeout: any

const startProgress = () => {
  status.setLoading(true)
  if (autoIncrement) {
    incTimeout = setInterval(function() {
      _inc()
    }, 250)
  }
}

const endProgress = () => {
  clearTimeout(startTimeout)
  clearInterval(incTimeout)
  status.setProgress(1)
  endLoading()
}

const MUI_PROGRESS_BAR_TRANSITION_DURATION = 400
const endLoadingDelay = MUI_PROGRESS_BAR_TRANSITION_DURATION + 50
let endLoadingTimeout: any

const endLoading = () => {
  clearTimeout(endLoadingTimeout)
  endLoadingTimeout = setTimeout(() => {
    status.setLoading(false)
    status.setProgress(0)
    status.reqsTotal = 0
    status.reqsCompleted = 0
  }, endLoadingDelay)
}

function _inc() {
  var stat = status.progress
  if (stat >= 1) {
    return
  }

  var rnd = 0

  // To improve: do this mathmatically instead of through conditions
 
  if (stat >= 0 && stat < 0.25) {
    // Start out between 3 - 6% increments
    rnd = (Math.random() * (5 - 3 + 1) + 3) / 100
  } else if (stat >= 0.25 && stat < 0.65) {
    // increment between 0 - 3%
    rnd = (Math.random() * 3) / 100
  } else if (stat >= 0.65 && stat < 0.9) {
    // increment between 0 - 2%
    rnd = (Math.random() * 2) / 100
  } else if (stat >= 0.9 && stat < 0.99) {
    // finally, increment it .5 %
    rnd = 0.005
  } else {
    // after 99%, don't increment:
    rnd = 0
    status.setProgress(1)
    return
  }

  status.setProgress(status.progress + rnd)
}

const startIntercepting = () => {
  // console.log('interception started')
  axios.interceptors.request.use(config => {
    if (status.reqsTotal === 0) {
      startTimeout = setTimeout(() => {
        startProgress()
      }, latencyThreshold)
    } 
    status.reqStart()
    status.setProgress(status.reqsCompleted / status.reqsTotal)
    // console.log('aaaaa', status.reqsCompleted, status.reqsTotal)
    return config
  })
  const update = (ev: any) => {
    // console.log(ev.loaded, ev.total, status.reqsCompleted, status.reqsTotal)
    if (ev.loaded === ev.total) {
      if (status.reqsTotal === status.reqsCompleted + 1) {
        endProgress()
      }
      status.reqComplete()
    }
  }
  axios.defaults.onDownloadProgress = update
  axios.defaults.onUploadProgress = update

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    if (response.status === 204) {
      // deal with 204 here
      if (status.reqsTotal === status.reqsCompleted + 1) {
        endProgress()
      }
      status.reqComplete()
    }
    return response
  }, function (error) {
    status.reqComplete()
  })
}

startIntercepting()

const context = React.createContext(status)

export const useProgressBarStatus = () => {
  return useContext(context)
}

export const startProgressBarIntercepting = startIntercepting