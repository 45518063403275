import React, { useContext } from 'react'
import { observable, action } from 'mobx'

const store: any = observable({

  isShow: true,
  setIsShow: action((val: boolean) => store.isShow = val),

  selectedDepot: {},
  setSelectedDepot: action((val: any) => store.selectedDepot = val),
  
})

const context = React.createContext(store)

export const useCrewFilter = () => {
  return useContext(context)
}