import { MaterialSpraySealProduct } from '@src/types/MaterialSpraySealProduct'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'

export const MaterialSpraySealProductFields: FormTableField<MaterialSpraySealProduct>[] = [
  {
    key: 'spraySealType',
    type: FormTableFieldType.Select,
    label: 'Seal Type',
    format: (cellData: MaterialSpraySealProduct['spraySealType']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    id: 'sprayBinderType',
    key: 'sprayBinderType',
    type: FormTableFieldType.Autocomplete,
    label: 'Spray Binder Type',
    format: (cellData: MaterialSpraySealProduct['sprayBinderType']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    key: 'area',
    type: FormTableFieldType.Number,
    label: 'Area (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'binderMixture',
    type: FormTableFieldType.Number,
    label: 'Binder Mixture Planned (AMC00 / % or parts for SA)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'orderedApplicationRate',
    type: FormTableFieldType.Number,
    label: 'Application rate ltsm2',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'residualBinder',
    type: FormTableFieldType.Number,
    label: 'Residual Binder @ 15ᴼ C (L)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'cutter',
    type: FormTableFieldType.Number,
    label: 'Cutter @ 15ᴼ C (L)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'adhesionAgent',
    type: FormTableFieldType.Number,
    label: 'Adhesion agent @ 15ᴼ C (L)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'depot',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot',
    format: (cellData: MaterialSpraySealProduct['depot']) => {
      if (cellData) {
        return cellData.name
      }
    },
  },
  {
    key: 'aggregateSize',
    type: FormTableFieldType.Autocomplete,
    label: 'Aggregate Size',
    format: (cellData: MaterialSpraySealProduct['aggregateSize']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    key: 'existingSurfaceConditions',
    type: FormTableFieldType.Textarea,
    label: 'Existing Surface Condition',
  },
  {
    key: 'aggregateStockpile',
    type: FormTableFieldType.Number,
    label: 'Aggregate in Stockpile',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'orderedRate',
    type: FormTableFieldType.Text,
    label: 'Ordered Rate (m2/m3)',
  },
  {
    key: 'otherSealType',
    type: FormTableFieldType.Text,
    label: 'Other Seal Type',
  },
  // not in table
  {
    key: 'comment',
    type: FormTableFieldType.Textarea,
    label: 'Comments',
  },
  {
    key: 'aggregateRequired_Tonnes',
    type: FormTableFieldType.Number,
    label: 'Aggregate Required (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'aggregateRequired_M3',
    type: FormTableFieldType.Number,
    label: 'Aggregate Required (m3)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'stockpileSite',
    type: FormTableFieldType.Text,
    label: 'Stockpile Site',
  },
  {
    key: 'preCoatRate',
    type: FormTableFieldType.Text,
    label: 'Precoat fluid rate of Application (l/m2)',
  },
  {
    key: 'stockpileInstructions',
    type: FormTableFieldType.Textarea,
    label: 'Stockpile Site Instructions',
  },
]