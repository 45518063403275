import { getEnvVariables } from './env-variables'

const ENV_VARIABLES = getEnvVariables()
const DOMAIN_BASE = ENV_VARIABLES.DOMAIN_BASE
const DOMAIN = ENV_VARIABLES.DOMAIN
const AUTH_DOMAIN = ENV_VARIABLES.AUTH_DOMAIN
const AUTH_TOKEN_URL = ENV_VARIABLES.AUTH_TOKEN_URL
const AUTH_USER_URL = ENV_VARIABLES.AUTH_USER_URL
const AUTHORIZE_URL = ENV_VARIABLES.AUTHORIZE_URL
const AUTHORIZATION_CODE = ENV_VARIABLES.AUTHORIZATION_CODE
const CLIENT_ID = ENV_VARIABLES.CLIENT_ID
const IDENTITY_PROVIDER = ENV_VARIABLES.IDENTITY_PROVIDER
const REDIRECT_AUTH_URL = ENV_VARIABLES.REDIRECT_AUTH_URL
const BASE_API_URL = ENV_VARIABLES.BASE_API_URL
const WEBSOCKET_API_URL = ENV_VARIABLES.WEBSOCKET_API_URL
const USER_POOL_REGION = ENV_VARIABLES.USER_POOL_REGION
const REFRESH_TOKEN_URL = ENV_VARIABLES.REFRESH_TOKEN_URL
const HEREMAP_KEY = ENV_VARIABLES.HEREMAP_KEY
const DEVICE_LIST = ENV_VARIABLES.DEVICE_LIST
const DEVICE_INFO = ENV_VARIABLES.DEVICE_INFO

export {
  DOMAIN_BASE,
  DOMAIN,
  AUTH_DOMAIN,
  AUTH_TOKEN_URL,
  AUTH_USER_URL,
  AUTHORIZE_URL,
  AUTHORIZATION_CODE,
  CLIENT_ID,
  IDENTITY_PROVIDER,
  REDIRECT_AUTH_URL,
  BASE_API_URL,
  WEBSOCKET_API_URL,
  USER_POOL_REGION,
  REFRESH_TOKEN_URL,
  HEREMAP_KEY,
  DEVICE_LIST,
  DEVICE_INFO
}


export const CookieOptions = {
  secure: true,
}

/**
 * https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html#amazon-cognito-user-pools-using-the-refresh-token
 * By default, the refresh token expires 30 days after your app user signs in to your user pool. When you create an app for your user pool, you can set the app's refresh token expiration (in days) to any value between 1 and 3650.
 */
export const REFRESH_TOKEN_EXPIRY_DAYS = 30
// export const REFRESH_TOKEN_EXPIRY_DAYS = 1 / (24 * 12) // for test