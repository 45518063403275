import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import _set from 'lodash/set'
import _isEmpty from 'lodash/isEmpty'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { BoralSwitch } from '../../components'
import DepotAutocomplete from '../../components/autocompletes/DepotAutocomplete'
import { CheckboxGroup } from '../DRS/legacy/Create/comps/f-EditableCheckboxGroup'
import CrewAutocomplete from '../../components/autocompletes/CrewAutocomplete'
import { useSnackbar } from '../../components/GlobalSnackbar'
import { apiLabourCreate } from '@src/apis/labour-create'
import { ADIDNameToValueLabel } from '@src/api-adaptors/id-name-to-value-label'
import { apiLabourUpdate } from '@src/apis/labour-update'
import { apiProfileSearchAdvanced } from '@src/apis/profile-search-advanced'
import SelectedCrews from './SelectedCrews'
import { DepotType, EmployeeType, formatDepotType, formatEmployeeType } from '@src/types/common/DepotType'
import { ValueLabel } from '@src/types/common/ValueLabel'
import { Role } from '@src/types/common/Role'
import { Profile } from '@src/types/common/Profile'
import { Crew } from '@src/types/Crew'
import { validateEmail } from '@src/utils/validateEmail'
import { CONST_ROLES_SYSTEM_ACCESS } from '@src/constants/roles-enum'

function LabourDialog({
  labour, // only exist in edition
  open,
  userType,
  roleOptions,
  hireCompanies,
  onClose = () => { },
  onCancel = () => { },
  onOk = () => { },
  onUpdateSuccess = () => { },
  onCreateSuccess = () => { },
  onOpenProfile = (labour: any) => { },
}: {
  labour?: Profile,
  open: boolean,
  userType: string,
  hireCompanies?:[],
  roleOptions: { value: string, label: string }[],
  onClose?(): void,
  onCancel?(): void,
  onOk?(): void,
  onUpdateSuccess?(): void,
  onCreateSuccess?(): void,
  onOpenProfile?(labour: any): void,
}) {
  const searchNames: boolean = false
  const fuzzyMatch: boolean = false
  const { alert } = useSnackbar()
  const [isEdit, setIsEdit] = useState<any>(false)
  const [firstName, setFirstName] = useState<any>('')
  const [lastName, setLastName] = useState<any>('')
  const [mobileNumber, setMobileNumber] = useState<any>('')
  const [employeeNumber, setEmployeeNumber] = useState<any>('')
  const [email, setEmail] = useState<any>('')
  const [matchingProfile, setMatchingProfile] = useState<any>()
  const [isMatchingProfile, setIsMatchingProfile] = useState<any>(false)
  const [isEditingSystemAccessUser, setIsEditingSystemAccessUser] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<any>(false)
  const [active, setActive] = useState<boolean>(true)
  const [searchEmail, setSearchEmail] = useState<string>('')
  const [depot, setDepot] = useState<ValueLabel>({ value: '', label: '' })
  const [depotPayroll, setDepotPayroll] = useState<ValueLabel>({ value: '', label: '' })

  const [selectedRoles, setSelectedRoles] = useState<Role[]>([])
  const [defaultRole, setDefaultRole] = useState<any>('')
  const [crews, setCrews] = useState<Crew[]>([])
  const [isFormValidated, setIsFormValidated] = useState<any>(false)
  const [depotType, setDepotType] = useState<null | undefined | { id: DepotType }>(null)

  const [employeeType, setEmployeeType] = useState<null | undefined | { id: EmployeeType }>(null)
  const [hireCompany, setHireCompany] = useState<any>('')
  const checkSystemAccessRoles = (roles: any[]) => {
    return new Promise((resolve, reject) => {

      const rolesIntersection = roles.filter((role: any) => CONST_ROLES_SYSTEM_ACCESS.includes(role.id))
      // if in Labour stream & the user has a system access role
      if (rolesIntersection.length > 0) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }
  // when adding a user & tabbing off the email text input
  useEffect(() => {
    if (searchEmail) {
      // check for an existing record with email address
      const result = apiProfileSearchAdvanced({
        search: searchEmail, searchNames, fuzzyMatch
      }).then(result => {
        // if a match & if editing not this record
        if (result.data && result.data.profiles && result.data.profiles.length > 0
          && (_isEmpty(labour) || (labour && !_isEmpty(labour) && labour.serialId != result.data.profiles[0].serialId))) {

          checkSystemAccessRoles(result.data.profiles[0].roles)
            .then(systemAccessUser => {
              if (systemAccessUser && userType === 'Crew Member') {
                setIsEditingSystemAccessUser(true)
                alert({
                  message: `A user already exists with email address ${email} that has a System Access role so must be edited by a Systems Administrator`,
                  type: 'error',
                })
              } else {
                setIsEditingSystemAccessUser(false)
                setMatchingProfile(result.data.profiles[0])
                setIsMatchingProfile(true)
                alert({
                  message: `A user already exists with email address ${email}`,
                  type: 'error',
                })
              }
            })

        } else {
          setIsEditingSystemAccessUser(false)
          setMatchingProfile({})
          setIsMatchingProfile(false)
        }

      })
    }
  }, [searchEmail])


  // when editing a user
  useEffect(() => {
    if (labour && labour.email) {
      console.log('labour');
      console.log(labour);
      console.log('labour');
      setIsEdit(true)
      const { email, firstName, lastName, mobileNumber, depot, depotPayroll, roles, crews, depotType, active ,employeeNumber,employeeType,labourHireCompany} = labour
      setEmail(email || '')
      setFirstName(firstName || '')
      setLastName(lastName || '')
      setMobileNumber(mobileNumber || '')
      setEmployeeNumber(employeeNumber || '')
      setDepot(ADIDNameToValueLabel(depot))
      setDepotPayroll({ label: depotPayroll ? depotPayroll.toString() : '' , value: depotPayroll ? depotPayroll.toString() : '' })
      setCrews(crews)
      setSelectedRoles(roles)
      setActive(active)
      if(employeeType == 'Boral'){
        setEmployeeType({id:EmployeeType.BORAL});
      }
      else if(employeeType == 'Labour Hire'){
        setEmployeeType({id:EmployeeType.LABOUR});
      } else{
        setEmployeeType(null);
      }
      setHireCompany(labourHireCompany || '')
      if (roles.length) {
        if (open && userType === 'Crew Member') {
          checkSystemAccessRoles(roles)
            .then(systemAccessUser => {
              if (systemAccessUser) {
                setIsEditingSystemAccessUser(true)
                alert({
                  message: `This user has a System Access role so must be edited by a Systems Administrator`,
                  type: 'error',
                })
              } else {
                setIsEditingSystemAccessUser(false)
              }
            })
        }

        const foundDefaultRole = roles.find(role => role.default === true)
        if (foundDefaultRole && foundDefaultRole.id) {
          setDefaultRole(foundDefaultRole.id)
        }
      }
      setDepotType(depotType)
    } else {
      setIsEdit(false)
      setIsFormValidated(false)
      setEmail('')
      setFirstName('')
      setLastName('')
      setMobileNumber('')
      setEmployeeNumber('')
      setDepot({ value: '', label: '' })
      setDepotPayroll({ value: '', label: '' })
      setCrews([])
      setSelectedRoles([])
      setDefaultRole('')
      setDepotType(null)
      setEmployeeType(null);
      setHireCompany('');
    }
  }, [open, labour])

  const handleCancel = async () => {
    setSearchEmail('')
    setMatchingProfile({})
    setIsMatchingProfile(false)
    setIsEditingSystemAccessUser(false)
    onCancel()
  }

  const handleClose = () => {
    setSearchEmail('')
    onClose()
  }

  const handleOk = async () => {
    const data: any = {
      ...labour,
      email,
      firstName,
      lastName,
      mobileNumber,
      employeeNumber,
      depotId: depot.value,
      roles: selectedRoles.map(role => {
        const isDefault = role.id === defaultRole
        return { ...role, default: isDefault }
      }),
      crews,
      handleProfileDepotType: true,
      depotPayroll: depotPayroll.label,
      depotType: depotType,
      employeeType: employeeType ? formatEmployeeType(employeeType.id) : '',
      labourHireCompany: hireCompany,
      active,
    }
   
    
    if (!firstName) {
      alert({
        message: 'First name is required',
        type: 'error',
      })
      return
    }
    if (!lastName) {
      alert({
        message: 'Last name is required',
        type: 'error',
      })
      return
    }
    if (!email || !validateEmail(email)) {
      alert({
        message: 'A valid Email is required',
        type: 'error',
      })
      return
    }
    if (!depot || !depot.value) {
      alert({
        message: 'Please enter Calendar Depot',
        type: 'error',
      })
      return
    }
    if (!depotPayroll || !depotPayroll.value) {
      alert({
        message: 'Please enter Payroll Depot',
        type: 'error',
      })
      return
    }
    if (!depotType || !depotType.id) {
      alert({
        message: 'Labour Pool is required',
        type: 'error',
      })
      return
    }
    if (!selectedRoles || !selectedRoles.length) {
      alert({
        message: 'Please select at least one role',
        type: 'error',
      })
      return
    }

    if (!defaultRole) {
      alert({
        message: 'Please select a default role',
        type: 'error',
      })
      return

    }
    if (!employeeType) {
      alert({
        message: 'Please select an Employee Type',
        type: 'error',
      })
      return

    }else{
      if(employeeType.id == EmployeeType.LABOUR){
        if(!hireCompany) {
          alert({
            message: 'Please select an Hire Company',
            type: 'error',
          })
          return
        }
      }
    }

    // check for an existing record with email address
    const options: any = { search: email, searchNames }
    setIsSaving(true)
    const result = apiProfileSearchAdvanced(options)
      .then(result => {

        // if a match & if editing not this record
        if (result.data && result.data.profiles && result.data.profiles.length > 0
          && (!data.serialId || (data.serialId && data.serialId != result.data.profiles[0].serialId))) {
          setIsSaving(false)
          checkSystemAccessRoles(result.data.profiles[0].roles)
            .then(systemAccessUser => {
              if (systemAccessUser) {
                setIsEditingSystemAccessUser(true)
                alert({
                  message: `A user already exists with email address ${email} that has a System Access role so must be edited by a Systems Administrator`,
                  type: 'error',
                })
              } else {
                setIsEditingSystemAccessUser(false)
                setMatchingProfile(result.data.profiles[0])
                setIsMatchingProfile(true)
                alert({
                  message: `A user already exists with email address ${email}`,
                  type: 'error',
                })
              }
            })
        } else {
          if (isEdit) {

            apiLabourUpdate(data)
              .then(result => {
                setIsSaving(false)
                alert({
                  message: `${email} updated.`
                })
                //onClose()
                handleClose()
                onUpdateSuccess()
              })
          } else {
            apiLabourCreate(data)
              .then(result => {
                setIsSaving(false)
                alert({
                  message: `${email} created.`
                })
                //onClose()
                handleClose()
                onCreateSuccess()
              })
          }
        }
      })
  }
  const handleEditMatchingProfile = async () => {
    //setSearchEmail('')
    setIsMatchingProfile(false)
    onOpenProfile(matchingProfile)
  }
  if (!open) {
    return null
  }
  const onTextChange = (fieldName: string) => (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value

    if (fieldName === 'firstName') {
      setFirstName(value)
    } else if (fieldName === 'lastName') {
      setLastName(value)
    } else if (fieldName === 'mobileNumber') {
      setMobileNumber(value)
    } else if (fieldName === 'email') {
      setEmail(value)
    } else if (fieldName === 'employeeNumber') {
      setEmployeeNumber(value)
    }
    
  }
  const onTextBlur = (fieldName: string) => (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value

    if (fieldName === 'email') {
      setSearchEmail(value)
    }
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'lg'}
    open={open}
    onClose={handleClose}
    aria-labelledby='labour-dialog-title'>
    <DialogTitle id='labour-dialog-title'>
      {isEdit ? `Edit ${userType}` : `Add ${userType}`}</DialogTitle>
    <DialogContent>
      <Section>
        <SectionTitle>Personal Details</SectionTitle>
        <GridContainer container>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                label={`${isEdit ? 'First Name' : 'Add First Name'}`}
                value={firstName}
                onChange={onTextChange('firstName')}
              />
              {/* </ValidatorField> */}
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                label={`${isEdit ? 'Last Name' : 'Add Last Name'}`}
                value={lastName}
                onChange={onTextChange('lastName')}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={`${isEdit ? 'Mobile Number' : 'Add Mobile Number'}`}
              value={mobileNumber}
              onChange={onTextChange('mobileNumber')}
            />
          </Grid>
        </GridContainer>
        <GridContainer container>
          <Grid item xs={4}>
            <TextField
              disabled={isEdit ? true : false}
              label={`${isEdit ? 'Email' : 'Add Email'}`}
              value={email}
              onChange={onTextChange('email')}
              onBlur={onTextBlur('email')}
            />
          </Grid>
          <Grid item xs={4}>
            <DepotAutocomplete
              label={`${isEdit ? 'Calendar Depot' : 'Add Calendar Depot'}`}
              selectedItem={depot}
              onChange={(value: ValueLabel) => {
                setDepot(value)
              }}
            />
          </Grid>


          <Grid item xs={4}>
            <FormControl>
              <FormLabel>Labour Pool</FormLabel>
              <RadioGroup
                value={depotType ? depotType.id : ''}
                style={{ flexDirection: 'row' }}
                onChange={e => {
                  setDepotType({ id: e.target.value as DepotType })
                }}>
                {DEPOT_TYPE_OPTIONS.map(depotType => {
                  return <FormControlLabel
                    key={depotType.value}
                    value={depotType.value}
                    control={<Radio />}
                    label={formatDepotType(depotType.value)} />
                })}
              </RadioGroup>
            </FormControl>

          </Grid>
        </GridContainer>
        <GridContainer container>
          <Grid item xs={4}>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <BoralSwitch
                checked={active}
                onChange={e => { setActive((e.target as HTMLInputElement).checked) }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              
              <TextField
              disabled={isEdit ? true : false}
              label={`${isEdit ? 'Employee Number' : 'Add Employee Number'}`}
              value={employeeNumber}
              onChange={onTextChange('employeeNumber')}
            />
            </FormControl>
          </Grid>
		  <Grid item xs={4}>
            <FormControl>
              <FormLabel>Employee Type</FormLabel>
              <RadioGroup
                value={employeeType ? employeeType.id : ''}
                style={{ flexDirection: 'row' }}
                
                onChange={e => {
                  setEmployeeType({ id: e.target.value as EmployeeType })
                }}>
                {EMPLOYEE_TYPE_OPTIONS.map(employeeType => {
                  return <FormControlLabel
                    key={employeeType.value}
                    value={employeeType.value}
                    control={<Radio />}
                    label={formatEmployeeType(employeeType.value)} />
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <DepotAutocomplete
              label={`${isEdit ? 'Payroll Depot' : 'Add Payroll Depot'}`}
              selectedItem={depotPayroll}
              onChange={(value: ValueLabel) => {
                setDepotPayroll(value)
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          {employeeType && <Grid item xs={4}>
          {employeeType.id == EmployeeType.LABOUR && <FormControl>
              <FormLabel>Hire Company</FormLabel>
              <RadioGroup
                value={hireCompany}
                style={{ flexDirection: 'row' }}
                
                onChange={e => {
                  setHireCompany( e.target.value )
                }}>
                {hireCompanies.map((hireCompany: any) => {
                  return <FormControlLabel
                    key={hireCompany.description}
                    value={hireCompany.description}
                    control={<Radio />}
                    label={(hireCompany.description)} />
                })}
              </RadioGroup>
            </FormControl>
}
          </Grid>
}
        </GridContainer>
      </Section>

      <Section>
        <SectionTitle>Role</SectionTitle>

        <Grid container>
          <Grid item xs={4}>
            <FormControl>
              <FormLabel>Assign Roles</FormLabel>
              <CheckboxGroup
                options={roleOptions}
                selectedItems={selectedRoles}
                onChange={(selected: Role[]) => {
                  setSelectedRoles(selected)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl disabled={!selectedRoles.length}>
              <FormLabel>Pick Default Role</FormLabel>
              <RadioGroup
                value={defaultRole}
                onChange={e => {
                  setDefaultRole(e.target.value)
                }}>

                {roleOptions.map(role => {
                  return <FormControlLabel
                    key={role.value}
                    value={role.value}
                    control={<Radio disabled={!selectedRoles.some(selectedRole => selectedRole.id === role.value)} />}
                    label={role.label} />
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <FormLabel>Assign to Crews</FormLabel>
              <CrewAutocomplete
                selectedItem={{ value: '', label: '' }}
                onChange={(value: {
                  label: string,
                  value: string,
                  raw: Crew,
                }) => {
                  const upcoming = value.raw
                  setCrews([...crews, upcoming])
                }}
              />
              <SelectedCrewBox>
                <SelectedCrews crews={crews} onRemove={(crew, index) => {
                  setCrews([
                    ...crews.slice(0, index),
                    ...crews.slice(index + 1),
                  ])
                }} />
              </SelectedCrewBox>
            </FormControl>
          </Grid>
        </Grid>
      </Section>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel}>
        Cancel
      </Button>
      {isMatchingProfile && !isEditingSystemAccessUser &&
        <Button variant='contained' color='primary' onClick={handleEditMatchingProfile} >
          {`Edit ${userType} ${email}`}
        </Button>}
      {!isMatchingProfile && <Button variant='contained' color='primary' disabled={isEditingSystemAccessUser || isSaving} onClick={handleOk} >
        {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
        {!isSaving && isEdit ? `Update ${userType}` : `Add ${userType}`}
      </Button>}
    </DialogActions>
  </Dialog >
}

const DEPOT_TYPE_OPTIONS = [
  {
    value: DepotType.ASPHALT,
  },
  {
    value: DepotType.SPRAYSEAL,
  },
]

const EMPLOYEE_TYPE_OPTIONS = [
	{
	  value: EmployeeType.BORAL,
	},
	{
	  value: EmployeeType.LABOUR,
	},
  ]

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
`
const Section = styled.div`
  margin-bottom: 40px;
`
const SectionTitle = styled.div`
  color: #009688;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const GridInner = styled.div`
  padding-right: 40px;
`
const SelectedCrewBox = styled.div`
  margin-top: 20px;
`
const GridContainer = styled(Grid)`
  margin-bottom: 20px;
`

export default LabourDialog