import React from 'react'
import moment, { Moment } from 'moment'
import { DatePicker } from '@src/components'
import IconButton from '@material-ui/core/IconButton'
import styled, { css } from 'styled-components'
import { styledWithOut } from '@src/utils/styledWithout'

function SingleDatePicker({
  variant = 'inline',
  selectedItem = {},
  onChange,
}: {
  variant?: "dialog" | "inline" | "static" | undefined,
  selectedItem: any,
  onChange(date: Moment): void,
}) {
  const renderDay = (date: Moment, selectedDate: Moment, dayInCurrentMonth: boolean) => {
    let dateClone = moment(date).clone()
    const isHighlighted = moment(selectedItem).isSame(dateClone, 'date')
    return (
      <DayWrapper
        dayInCurrentMonth={dayInCurrentMonth}
        onClick={handleClick(date)}
      >
        <Day isHighlighted={isHighlighted} >
          <span> {dateClone.format('DD')} </span>
        </Day>
      </DayWrapper>
    )
  }
  const handleClick = (date: Moment) => () => {
    // onClick
  }
  return (
    <DatePicker
      isKeyboardDatePicker={false}
      value={moment(selectedItem)}
      onChange={onChange}
      renderDay={renderDay}
      disableToolbar
      variant={variant}
    />
  )
}

const DayWrapper = styled.div<{ dayInCurrentMonth: boolean }>`
  ${props => {
    if (!props.dayInCurrentMonth) {
      return css`
        opacity: 0;
        pointer-events: none;
      `
    }
  }}
`
const Day = styled(styledWithOut('isHighlighted')(IconButton))`
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  color: inherit;
  ${props => {
    if (props.isHighlighted) {
      return css`
        background: #009688;
        color: #fff;
      `
    }
  }}
`

export default SingleDatePicker