import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RoleId } from '@src/types/common/RoleId'
import { useDRS } from './useDRS'
import Breadcrumbs from '@src/components/Breadcrumbs'
import LeftNavStickyWrapper from '@src/components/LeftNav/StickyWrapper'
import { formatDRSStatus } from '@src/types/drs/DRSStatus'
import { LeftNav } from '@src/components/LeftNav'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import Container from '@material-ui/core/Container'
import { NavDeepLink } from '@src/types/NavDeepLink'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import DRSJob from './parts/1-Job/Job'
import DRSRework from './parts/2-Rework/Rework'
import PartDRSSubcontractors from './parts/6-Subcontractors/Subcontractors'
import { PAE } from '@src/types/drs/PAE'
import CompDRSMobileAssets from './parts/5-MobileAssets/MobileAssets'
import PartDRSTransport from './parts/4-Transport/Transport'
import PartDRSSummary from './parts/9-Summary/Summary'
import PartDRSMaterial from './parts/3-Material/Material'
import PartDRSQa from './parts/8-Qa/Qa'
import DRSSectionLabour from './parts/7-Labour/Labour'
import DRSPreview from './parts/preview/Preview'
import CompAdminActions from './parts/actions/AdminActions'

export enum DRSPageType {
  Plan,
  Edit,
  Review,
}
export function mapDRSPageTypeToRoleId(type: DRSPageType): RoleId {
  switch(type) {
    case DRSPageType.Plan: return RoleId.ResourceScheduler
    case DRSPageType.Edit: return RoleId.ProjectManager
    case DRSPageType.Review: return RoleId.Administrator
  }
}

export function mapDRSPageTypeToPAE(type: DRSPageType): PAE {
  switch(type) {
    case DRSPageType.Plan: return PAE.Plan
    case DRSPageType.Edit: return PAE.Edit
    case DRSPageType.Review: return PAE.Edit
  }
}
interface Props {
  type: DRSPageType,
}

const DRSPage = (props: Props) => {
  const {
    type,
  } = props
  const showPreview = type === DRSPageType.Plan
  const { id } = useParams<{id: string}>()
  const { hash } = useLocation()
  const history = useHistory()
  const { 
    drs, 
    loading, 
    fetchDRS,
  } = useDRS()
  const [ activeSection, setActiveSection ] = useState<DRSSectionName>(DRSSectionName.Job)
  const activeIndex = deepLinks.findIndex((link: NavDeepLink<DRSSectionName>) => link.key === activeSection)
  useEffect(() => {
    if (id && DRSPageType[type]) {
      fetchDRS(id, type)
    }
  }, [id, type])
  // deep linking
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1)
      if (deepLinks.some((link: NavDeepLink<DRSSectionName>) => link.urlHash === trimmedHash)) {
        setActiveSection(
          deepLinks.find((link: NavDeepLink<DRSSectionName>) => link.urlHash === trimmedHash)!
            .key
        )
        return
      }
    }
    setActiveSection(DRSSectionName.Job)
  }, [id])
  // deep linking
  useEffect(() => {
    history.replace({
      hash: `#${deepLinks.find((link: NavDeepLink<DRSSectionName>) => link.key === activeSection)!
        .urlHash}`
    })
  }, [activeSection])

  if (drs === null) {
    return null
  }
  const { status } = drs.jobDetails
  const { date } = drs.jobDetails.shift
  const { id: projectId, projectInternalId, name: projectName } = drs.jobDetails.project
  const { suburb, streetName: street } = drs.jobDetails.plan

  const handleOnAnchorClick = (index: number) => {
    setActiveSection(deepLinks[index].key)
  }
  const renderMain = (): void => {
    switch(type) {
      case DRSPageType.Plan: {
        // return drs.jobDetails.project.id
      }
    }
    return null
  }
  const renderPart = () => {
    switch(activeSection) {
      case DRSSectionName.Job: {
        return <DRSJob />
      }
      case DRSSectionName.Rework: {
        return <DRSRework />
      }
      case DRSSectionName.Material: {
        return <PartDRSMaterial />
      }
      case DRSSectionName.MobileAssets: {
        return <CompDRSMobileAssets type={type} />
      }
      case DRSSectionName.Transport: {
        return <PartDRSTransport />
      }
      case DRSSectionName.Subcontractors: {
        return <PartDRSSubcontractors />
      }
      case DRSSectionName.Labour: {
        return <DRSSectionLabour />
      }
      case DRSSectionName.Quality: {
        return <PartDRSQa />
      }
      case DRSSectionName.Summary: {
        return <PartDRSSummary />
      }
    }
    return null
  }
  return <Wrapper maxWidth='xl'>
    <Breadcrumbs items={[
      { type: 'link', text: `Project ${projectId || projectInternalId}`, url: `/project/${projectInternalId}`},
      { type: 'text', text: `DRS ${id}`},
    ]}/>
    
    <MainWrapper>
      <Left>
        <LeftNavStickyWrapper top={16}>
          <LeftNav 
            title={`Daily Reporting Sheet`}
            statusName={formatDRSStatus(status.id)}
            sections={[
              {
                label: 'Project',
                value: `${projectId || projectInternalId} | ${projectName}`,
              },
              {
                label: 'Site',
                value: (suburb && street) ? `${suburb}, ${street}` : '',
              },
              {
                label: 'Time',
                value: formatDateStringOrNullDDMMYYYY(date),
              },
            ]}
            anchors={deepLinks.map((link: NavDeepLink<DRSSectionName>) => link.anchor)}
            activeIndex={activeIndex}
            onAnchorClick={handleOnAnchorClick}
          />
        </LeftNavStickyWrapper>
      </Left>
      <Center>
        <PartWrapper>
          { renderPart() }
          { renderMain() }
          {
            type === DRSPageType.Review &&
            <ActionsForAllSections>
              <CompAdminActions />
            </ActionsForAllSections>
          }
        </PartWrapper>
      </Center>
      { showPreview && 
        <Right>
          <DRSPreview 
            activeSection={activeSection}
          />
        </Right>
      }
    </MainWrapper>
  </Wrapper>
}

const Wrapper = styled(Container)`

`
const MainWrapper = styled.div`
  display: flex;
`
const PartWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  flex-grow: 1;
`
const ActionsForAllSections = styled.div`
  position: absolute;
  right: 30px;
  top: 0;
`
const Center = styled.div`
  position: relative;
  padding: 0 46px;
  flex-grow: 1;
`
const Left = styled.div`
  width: 208px;
  flex-shrink: 0;
  position: relative;
`
const Right = styled.div`
  width: 300px;
  flex-shrink: 0;
  position: relative;
`

export enum DRSSectionName {
  Job,
  Rework,
  Material,
  Transport,
  MobileAssets,
  Subcontractors,
  Labour,
  Quality,
  Summary,
}
const deepLinks: NavDeepLink<DRSSectionName>[] = [
  {
    anchor: 'Job',
    urlHash: 'Job',
    key: DRSSectionName.Job,
  },
  {
    anchor: 'Rework',
    urlHash: 'Rework',
    key: DRSSectionName.Rework,
  },
  {
    anchor: 'Material',
    urlHash: 'Material',
    key: DRSSectionName.Material,
  },
  {
    anchor: 'Transport',
    urlHash: 'Transport',
    key: DRSSectionName.Transport,
  },
  {
    anchor: 'Mobile Assets',
    urlHash: 'MobileAssets',
    key: DRSSectionName.MobileAssets,
  },
  {
    anchor: 'Subcontractors',
    urlHash: 'Subcontractors',
    key: DRSSectionName.Subcontractors,
  },
  {
    anchor: 'Labour',
    urlHash: 'Labour',
    key: DRSSectionName.Labour,
  },
  {
    anchor: 'QA Requirements',
    urlHash: 'Quality',
    key: DRSSectionName.Quality,
  },
  {
    anchor: 'Summary',
    urlHash: 'Summary',
    key: DRSSectionName.Summary,
  },
]

export default observer(DRSPage)