export enum SMIStatus {
  New = 1,
  Completed = 2,
  Updated = 3,
}

export function formatSMIStatus(status: SMIStatus) {
  switch (status) {
    case SMIStatus.New:
      return 'New'
    case SMIStatus.Completed:
      return 'Completed'
    case SMIStatus.Updated:
      return 'Updated'
  }
}