import { useState, useEffect } from 'react'

interface Options<T> {
  initialData?: T,
}
export default function useModal<T>(options: Options<T>): [boolean, T | null, typeof openAndSetData, typeof closeModal] {
  const {
    initialData,
  } = options
  const [ open, setOpen ] = useState<boolean>(false)
  const [ data, setData ] = useState<T | null>(null)
  function openAndSetData(data: T | null) {
    setOpen(true)
    setData(data)
  }
  function closeModal() {
    setOpen(false)
    setData(null)
  }
  return [ open, data, openAndSetData, closeModal ]
}