import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


interface Options {

  viewId: number,

  
 

}
export const apiUserShiftCardViewRemove = ({
  viewId,
 
}: Options) => {
  const data = {
    viewId
    
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/removeUsershiftcardview`,
    params: {
      viewId,
     
      'reqDev': 'web'
    }
  }
  return ax(options)
}