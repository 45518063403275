import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'



interface Options {
  formType: PackFormType,
  formId: number | string,
  roleId: string,
  serialId: number,
  s3Filename: string,
  s3locationName: string,
  orginalfileName:string,
  s3fileExtension: string,
  projectInternalId: any,
  IsSupervisor?: string,
  IsAdministrator?: string,
  IsResourceScheduler?: string,
  IsProjectManager?: string,
  IsForeman?: string,
  cpDocComment?: string,

}

export const apiUploadPreloadData = ({
  formType,
  formId,
  roleId,
  serialId,
  s3Filename,
  s3locationName,
  orginalfileName,
  s3fileExtension,
  projectInternalId,
  IsSupervisor,
  IsAdministrator,
  IsResourceScheduler,
  IsProjectManager,
  IsForeman,
  cpDocComment
}: Options) => {
  
  const data = new FormData()
data.append('s3locationName',s3locationName);
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/documents/${formType}/${formId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    params: {
      reqRole: roleId,
      reqSid: serialId,
      projectInternalId,
      IsSupervisor,
      IsAdministrator,
      IsResourceScheduler,
      IsProjectManager,
      IsForeman,
      cpDocComment,
      s3Filename,
      s3locationName,
      orginalfileName,
      s3fileExtension
    }
  }
  return ax(options)
}