import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useDRS } from '@src/modules/DRS/useDRS'
import { DRSCrewMemberPAE } from '@src/types/drs/labour/Labour'
import { Role } from '@src/types/common/Role'
import { RoleId } from '@src/types/common/RoleId'
import { Profile } from '@src/types/common/Profile'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'

interface Props {

}

const CompDRSCrewContactDetails = (props: Props) => {
  const {

  } = props
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  if (drs === null) {
    return null
  }
  const supervisors = drs.labour.detail
    .filter((member: DRSCrewMemberPAE) => {
      return member.profile.roles.some((role: Role) => role.default && role.id === RoleId.Supervisor)
    })
    .map((member: DRSCrewMemberPAE) => member.profile)
  return <Wrapper>
    <FormPanel title={`Contact Details`}>
    {
      supervisors.map((profile: Profile) => {
        return <SupervisorWrapper key={profile.serialId}>
          <Name>{`${formatProfileOrNullFirstLastName(profile)}(Supervisor)`}</Name>
          <Phone>{profile.mobileNumber}</Phone>
        </SupervisorWrapper>
      })
    }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
const SupervisorWrapper = styled.div`
  margin-bottom: 20px;
`
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
`
const Phone = styled.div`
  font-size: 16px;
`

export default CompDRSCrewContactDetails
