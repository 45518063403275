import React, { useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { useHistory } from 'react-router'
import usePermission from '@src/utils/hooks/usePermission'

export interface DRSRedirectConfirmDialogProps {
  data: number | string, // drs id
  open: boolean,
  onClose(): void,
  onCancel(): void,
}

const DRSRedirectConfirmDialog = (props: DRSRedirectConfirmDialogProps) => {
  const { open, onClose, onCancel, data } = props
  const [ value, setValue ] = useState<'plan' | 'edit' | 'review' | null>(null)
  const history = useHistory()
  const { canPlanDRS, canEditDRS, canReviewDRS } = usePermission()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue((event.target as HTMLInputElement).value)
  }
  const handleOk = () => {
    if (value === null) {
      return
    }
    const map = {
      plan: `#/drs/plan/${data}`,
      edit: `#/drs/edit/${data}`,
      review: `#/drs/review/${data}`,
    }
    window.open(map[value], '_blank')
  }
  return <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle> {`Select DRS Mode`} </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Select what you would like to do with the DRS from the following options
      </DialogContentText>
      <FormControl component='fieldset'>
        <RadioGroup aria-label='drs' name='drs1' value={value} onChange={handleChange}>
          { canPlanDRS && <FormControlLabel value='plan' control={<Radio />} label='Plan shift as a Resource Scheduler' />}
          { canEditDRS && <FormControlLabel value='edit' control={<Radio />} label='Review shift as a Project Manager' />}
          { canReviewDRS && <FormControlLabel value='review' control={<Radio />} label='Link shift to Scenario as an Administrator' /> }
        </RadioGroup>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button 
        disabled={!value}
        color='primary'
        variant='contained'
        onClick={handleOk} 
        >
        { `Select` }
      </Button>
    </DialogActions>
  </Dialog>
}
const Wrapper = styled.div``
const ProjectInfo = styled.span`
  color: #0A9688;
`
const Section = styled.div`
  margin-bottom: 40px;
`
const GridInner =styled.div`
  padding-right: 16px;
`
export default DRSRedirectConfirmDialog