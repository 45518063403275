import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import { FieldName } from './Field'
import ProductAutocomplete from '../../../../../components/autocompletes/ProductAutocomplete'

const FieldProduct = ({
  field,
  onChange,
}: DRS.Comp) => {
  const { id, description } = field.data
  const { disabled, plantId } = field.typeMeta!
  const selectedItem = {
    value: id,
    label: description,
  }
  const handleChange = (item: AIMS.ValueLabel) => {
    onChange({
      id: item.value,
      description: item.label,
    })
  }
  return <Wrapper>
    <FormControl fullWidth>
      <ProductAutocomplete 
        plantId={plantId}
        selectedItem={selectedItem}
        onChange={handleChange}
        disabled={disabled!}
        fullWidth={true}
      />
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
`
const Content = styled.div``

export default FieldProduct