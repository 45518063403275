import React from 'react'
import styled from 'styled-components'
import { useSRRInDRS } from '../../useSRRInDRS'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import CompPreviewFieldPair from '../../comps/FieldPair'
import { SRR } from '@src/types/SRR'
import SRRReworkFields from '@src/modules/SiteResoureRequirements/parts/3-Rework/fields'

interface Props {
  jobType: ProjectTypeId,
}
const CompPreviewRework = (props: Props) => {
  const { jobType } = props
  const [ srr ] = useSRRInDRS()
  if (jobType !== ProjectTypeId.Asphalt && jobType !== ProjectTypeId.SpraySeal) {
    return null
  }
  if (srr === null) {
    return null
  }
  const mappedRework = mapRework(srr, jobType)
  return <Wrapper>
    { mappedRework.map((item) => {
      return <CompPreviewFieldPair
        key={item.label}
        label={item.label}
        value={item.value}
      />
    })}
  </Wrapper>
}

function mapRework(srr: SRR, jobType: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal) {
  const data = srr.rework[normalizeProjectTypeId(jobType) as ('asphalt' | 'spraySeal')]
  if (data === null) {
    return []
  }
  return [
    {
      label: 'Rework Required',
      value: `Yes`,
    },
    ...SRRReworkFields
      .filter((field) => field.key !== 'reworkEmulsion')
      .map((field) => {
        return {
          label: field.label || '',
          value: `${data[field.key]}`,
        }
      })
  ]
       
}

const Wrapper = styled.div`

`

export default CompPreviewRework
