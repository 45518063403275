import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { FieldName } from './Field'
import { capWord } from '../../../../../utils/capWord'
import { Crew } from '@src/types/Crew'

const FieldJobCrew = ({
  field,
}: DRS.Comp) => {
  return <React.Fragment>
    <FieldName>
      Crew
    </FieldName>
    <CrewFlat crew={field.data as Crew} />
  </React.Fragment>
  
}

export function CrewFlat({ crew }: { crew: Crew }) {
  return <CrewWrapper>
    <Circle color={crew.colourCode}/>
    <Text>{`${crew.name}`}</Text>
  </CrewWrapper>
}

const CrewWrapper = styled.div`
  display: flex;
  align-items: center;
`

// const Crew = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-right: 20px;
// `

const Circle = styled.div`
  width: 16px;
  height: 16px;
  /* border-radius: 24px; */
  background-color: ${props => `#${props.color}`};
  /* margin-bottom: 8px; */
  cursor: pointer;
  margin-right: 8px;
`

const Text = styled(Typography)`
  font-size: 16px;
  /* color: #B7B7B7; */
  line-height: 24px;
`

export default FieldJobCrew