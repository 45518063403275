import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { WordAutocomplete } from '../../../../components/WordAutocomplete'
import { useAppStore } from '../../../../AppStore'
import { apiDepots } from '../../../../apis/depots'
import { ADDepot } from '../../../../api-adaptors/depot'

const DepotAutocomplete = ({ 
  id,
  selectedItem,
  onInput,
  onChange,
  onSelect,
}: {
  id?: string,
  selectedItem?: any,
  onInput?: any,
  onChange?: any,
  onSelect?: any,
  color?: string,
}) => {
  const [ inputValue, setInputValue ] = useState<any>('')
  const [ options, setOptions ] = useState<any>([])
  const finalOptions = [...options.filter((option: AIMS.ValueLabel) => {
    return (option.label || '').toLowerCase().includes(inputValue.toLowerCase())
  })]
  const { stateId } = useAppStore()

  useEffect(() => {
    const fetchDepotOptions = async () => {
      try {
        // querying all depots nationally, so, no param needed
        const result = await apiDepots({ })
        setOptions(
          result.data.depots
            .map((depot: any) => ADDepot(depot))
            .map((depot: AIMS.ValueLabel) => {
              let splitted = (depot.label || '').split('-')
              return { label: splitted[splitted.length - 1], value: depot.value }
            })
        )
      } catch (err) {
        console.log('can not get depots')
      }
    }
    fetchDepotOptions()
  }, [])
  
  const handleInput = (value: string) => {
    setInputValue(value)
  }
  const handleSelect = (item: any) => {
    setInputValue('')
    onSelect(item)
  }
  return <StyledDepotAutocomplete
    id='depot autocomplete'
    selectedItem={selectedItem}
    color='boral-light'
    onInput={handleInput}
    options={finalOptions}
    onChange={onChange}
    onSelect={handleSelect}
  />
}

const StyledDepotAutocomplete = styled(WordAutocomplete)`
  display: inline-block;
  color: ${props => props.theme.app.colors['boral-light']};
` as typeof WordAutocomplete

export default observer(DepotAutocomplete)