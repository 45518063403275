import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  vmpId: number,
  roleId: RoleId,
  serialId: number,
}

export const apiVmpGetByProject = (options: Options) => {
  const { vmpId, roleId, serialId } = options
  const data = {

  }
  // `/v2/vmp?reqRole=RESOURCE_SCHEDULER&reqSid=812&reqDev=web&vmp={VMP ID}&scope=PROJECT`
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/vmp`,
    data,
    params: {
      vmp: vmpId,
      reqSid: serialId,
      reqRole: roleId,
      reqDev: 'web',
      scope: 'PROJECT'
    }
  }
  return ax(axOptions)
}