import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { Project } from '@src/types/project/Project'
import { VMP, VMPBrief } from '@src/types/VMP'
import { useAppStore } from '@src/AppStore'
import { apiVmpGetByProject } from '@src/apis/vmp-get-by-project'
import { apiVmpCopyToVmp } from '@src/apis/vmp-copy-to-vmp'
import { Shift } from '@src/types/Shift'
import { VMPFormCard } from '@src/types/VMPFormCard'
import moment from 'moment'
const Format = 'DD/MM/YYYY'

export interface CopyVMPMapDialogProps {
  data: {
    project: Project,
    shift: Shift,
    vmpFormCard: VMPFormCard
  }
  open: boolean,
  onClose(): void,
  onCancel(): void,
  onSuccess(): void,
}

const CopyVMPMapDialog = (props: CopyVMPMapDialogProps) => {
  const { open, onClose, onCancel, data, onSuccess } = props
  const { serialId, defaultRoleId } = useAppStore()
  const { project, shift, vmpFormCard } = data
  //const [sifs, setSifs] = useState<SifBrief[]>([])
  const [vmps, setVMPs] = useState<VMPBrief[]>([])
  const [value, setValue] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [copying, setCopying] = useState<boolean>(false)

  useEffect(() => {
    const fetchVmps = async () => {
      setLoading(true)
      try {
        const result = await apiVmpGetByProject({
          vmpId: vmpFormCard.id,
          roleId: defaultRoleId,
          serialId,
        })
        //console.log('fetchVmps()');
        //console.log(result.data.siteMaps);
        const sortData = { 'shift.date' : 'desc' };
     const sitemapsData: any = multiSort(result.data.siteMaps,sortData);
     //console.log(sitemapsData);
        setVMPs(sitemapsData)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    } 
    fetchVmps()     
  }, [])


function multiSort(array: any, sortObject: any = {}) {
  const sortKeys = Object.keys(sortObject);

  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
      return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
      sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
  }


  const keySort = (a: any, b: any, direction: any) => {
      direction = direction !== null ? direction : 1;

      if (a === b) { // If the values are the same, do not switch positions.
          return 0;
      }
        console.log(a,b);
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
  };

  return array.sort((a:any, b: any) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];
          
          if (key) {
              const direction = sortObject[key];
              sorted = keySort(a.shift.date, b.shift.date, direction);
              index++;
          }
      }

      return sorted;
  });
}
  const handleOk = async () => {
    setCopying(true)
    try {
      const result = apiVmpCopyToVmp({
        siteMapId: value,
        vmpId: vmpFormCard.id,
        roleId: defaultRoleId,
        serialId,
      })
      setTimeout(() => {
        onSuccess()
        setCopying(false)
      }, 2000)
    } catch (e) {
      setCopying(false)
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number((event.target as HTMLInputElement).value))
  }
  function renderLabel(vmpBrief: any) {
    var html = '';

    return ``+(vmpBrief.shift.drsProjectType ? vmpBrief.shift.drsProjectType.name : '') + 
    (vmpBrief.shift.date ? ` ~ `  +moment(vmpBrief.shift.date).format(Format) : '') + ' ~ '+vmpBrief.id
    + (`<div>` + vmpBrief.shift.crew ? vmpBrief.shift.crew.name : ``) + `</div>`
    + (`` + vmpBrief.street ? vmpBrief.street+',' : ``)
    + (vmpBrief.suburb ? vmpBrief.suburb : ``);
    return vmpBrief.shift.drsProjectType ? vmpBrief.shift.drsProjectType.name+`,` : ``
    + vmpBrief.shift.date ? ` ~ `  +moment(vmpBrief.shift.date).format(Format) : ``
   + vmpBrief.id ?` ~ `  +vmpBrief.id : ` `
    
    + ` \n` + vmpBrief.shift.crew ? vmpBrief.shift.crew.name : ``
     +  `\n`  + vmpBrief.street ? vmpBrief.street+`,` : ``
     + vmpBrief.suburb ? vmpBrief.suburb+`,` : ``;

  }
  return <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle> {`Select Vehicle Movement Plan`} </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {!loading && vmps.length === 0 ? `No Vehicle Movement Plans available for this project` :
          `VMPs in ${project.id || project.projectInternalId} | ${project.name}`}
      </DialogContentText>
      <FormControl component='fieldset' style={{width: '100%'}}>
        <RadioGroup aria-label='drs' name='drs1' value={value} onChange={handleChange}>
          {vmps.map((vmpBrief: VMPBrief) => {
            return <div>
                  <RowDiv>
                    <ColDivRadio>
                    <FormControlLabel
                        key={vmpBrief.id}
                        value={vmpBrief.id} control={<Radio />}
                        label={''}
                      />  
                     </ColDivRadio>
                            <ColDiv>
                            <DivMainLabel>
                              {(vmpBrief.shift.drsProjectType ? vmpBrief.shift.drsProjectType.name : '') +  (vmpBrief.shift.date ? ` ~ `  +moment(vmpBrief.shift.date).format(Format) : '') + ' ~ '+vmpBrief.id}</DivMainLabel>
                            
                            { vmpBrief.shift.crew && <div>
                              <div style={{backgroundColor: '#'+vmpBrief.shift.crew.colourCode,height:'20px',width:'20px',marginRight:'5px',display:'inline-block'}}></div>
                              <CrewLabel >{ vmpBrief.shift.crew ? vmpBrief.shift.crew.name : `` }</CrewLabel>
                            </div>  }
                    
                          <div style={{fontSize:'1rem'}}>{(vmpBrief.street ? vmpBrief.street+`, ` : ``) + (vmpBrief.suburb ? vmpBrief.suburb : ``)}</div>
                        </ColDiv>
                  </RowDiv>
                  
           

              </div>
            // <FormControlLabel
            //   key={vmpBrief.id}
            //   value={vmpBrief.id} control={<Radio />}
            //   label={renderLabel(vmpBrief)}
            // />
          })}
        </RadioGroup>
      </FormControl>
    </DialogContent>
    <DialogActions>
      {
        !loading && vmps.length !== 0 &&
        <React.Fragment>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={!value || copying}
            color='primary'
            variant='contained'
            onClick={handleOk}
          >
            {`Copy Vehicle Movement Plan`}
          </Button>
        </React.Fragment>
      }

      {!loading && vmps.length === 0 &&
        <Button
          color='primary'
          variant='contained'
          onClick={onCancel}
        >
          {`OK, got it`}
        </Button>
      }

    </DialogActions>
  </Dialog>
}
const CrewLabel = styled.div`
 
  line-height: 20px;
  display: inline-block;
  vertical-align: top;

`
const DivMainLabel = styled.div`
 
  font-size: 1rem;
  color: #009688;
  height: 30px

`

const RowDiv = styled.div`
 
  display: flex;
  border-bottom: 1px solid grey;
  padding: 7px 0px;
  align-items: center;
  justify-content: center;

`
const ColDiv = styled.div`
 
  flex: 90%;

`
const ColDivRadio = styled.div`
 
  flex: 10%;

`
export default CopyVMPMapDialog