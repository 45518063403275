import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { formatTimeStringOrNullHHmm } from '@src/types/format/timeStringOrNullHHmm'
import { IdDescription, NullableIdDescription } from '@src/types/utils/IdDescription'

export interface MachineResource {
  id: number,
  attribute: NullableIdDescription,
  number: number,
  message: string,
  startTime: string,
}
export const ResourceFields: FormTableField<MachineResource>[] = [
  {
    key: 'number',
    type: FormTableFieldType.Number,
    label: 'Quantity',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'message',
    type: FormTableFieldType.Textarea,
    label: 'Message',
  },
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
]