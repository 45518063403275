import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import CrewCard from './CrewCard'
import MemberTable from './MemberTable'
import Grid from '@material-ui/core/Grid'
import { AssetAutocomplete } from '../../../components'
import { useAppStore } from '../../../AppStore'
import LabourAutocomplete from '../../../components/autocompletes/LabourAutocomplete'
import AssetTable from './AssetTable'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {
  crews: AIMS.Crew[],
  onEditCrew?(crew: AIMS.Crew) : void,
  onChangeCrewStatus?(crew: AIMS.Crew, upcoming: boolean): void,
  onLoadCrewInfo?(crew: AIMS.Crew): void,
  onAddMember?(crew: AIMS.Crew, upcoming: AIMS.Profile): void,
  onRemoveMember?(crew: AIMS.Crew, membersAfterRemoved: AIMS.Profile[]): void,
  onAddAsset?(crew: AIMS.Crew, upcoming: AIMS.MobileAsset): void,
  onRemoveAsset?(crew: AIMS.Crew, asset: AIMS.MobileAsset, assetIndex: number): void,
}

function CrewAccording (props: Props) {
  const {
    crews = [],
    onEditCrew = () => {},
    onChangeCrewStatus = () => {},
    onLoadCrewInfo = () => {},
    onAddMember = () => {},
    onRemoveMember = () => {},
    onAddAsset = () => {},
    onRemoveAsset = () => {},
  } = props
  const [ openCrewId, setOpenCrewId ] = useState<any>('')
  const { canEditCrewInMgmt } = usePermission()
  const handleCrewCardClick = (crew: AIMS.Crew) => () => {
    if (openCrewId !== crew.id + '') {
      setOpenCrewId(crew.id + '')
      onLoadCrewInfo(crew)
    } else {
      setOpenCrewId('')
    }
  }
  const handleMemberRemove = (crew: AIMS.Crew) => (membersAfterRemoved: AIMS.Profile[]) => {
    onRemoveMember(crew, membersAfterRemoved)
  }
  const handleAssetRemove = (crew: AIMS.Crew) => (asset: AIMS.MobileAsset, assetIndex: number) => {
    onRemoveAsset(crew, asset, assetIndex)
  }
  return <Wrapper>
    { crews.map((crew: AIMS.Crew) => {
      return <React.Fragment key={crew.id}>
        <CrewCard 
          data={crew} 
          onClick={handleCrewCardClick(crew)}
          onEditCrew={onEditCrew}
          onChangeCrewStatus={onChangeCrewStatus}
          />

        <Collapse in={openCrewId === crew.id + ''}>
          <Grid container>
            <Grid item xs={6}>
              <MemberWrapper>
                { canEditCrewInMgmt &&
                  <LabourAutocomplete 
                    label='Add Member'
                    selectedItem={{ value: '', label: ''}}
                    active={true}
                    roleType={`crewMember`}
                    onChange={(value: {
                      value: string,
                      label: string,
                      raw: AIMS.Profile,
                    }) => {
                      const upcoming = value.raw
                      onAddMember(crew, upcoming)
                    }}
                  />
                }
                <MemberTable
                  data={crew.members || []}
                  onRemove={handleMemberRemove(crew)}
                />
              </MemberWrapper>
            </Grid>
            <Grid item xs={6}>
              <AssetWrapper>
                { canEditCrewInMgmt &&
                  <AssetAutocomplete
                    label='Add Asset'
                    selectedItem={{ value: '', label: ''}}
                    onChange={(value: {
                      value: string,
                      label: string,
                      raw: AIMS.MobileAsset,
                    }) => {
                      const upcoming = value.raw
                      onAddAsset(crew, upcoming)
                    }}
                  />
                }
                <AssetTable
                  data={crew.mobileAssets || []}
                  onRemove={handleAssetRemove(crew)}
                />
              </AssetWrapper>
            </Grid>
          </Grid>
        </Collapse>
        
      </React.Fragment>
    })}
  </Wrapper>

}

const Wrapper = styled.div`
`
const MemberWrapper = styled.div`
  border-right: 1px solid #E3E0E0;
  padding-right: 80px;
`
const AssetWrapper = styled.div`
  padding-left: 80px;
`

export default observer(CrewAccording)