import _set from 'lodash/set'
import _get from 'lodash/get'
import { getAsphaltLayers, getWasteReturnReasons, ASSET_LOCATION_OPTIONS, CHECKBOX_SELECT_OPTIONS, getSealTypes } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import { MaterialsAsProductPath, MaterialsAsPath, MaterialsSsProductsPath, MaterialsSsPath } from '../../Create/sections/constants'
import { isAS, isSS } from '../EditDRSStore'

export default function getMaterialSectionData(data: DRS.RootData) {
  const asPanels = getAsPanels(data)
  const asOther = getAsOther(data)
  const ssPanels = getSsPanels(data)
  const ssOther = getSsOther(data)
  let panels: any[] = []
  if (isAS(data)) {
    panels = [
      ...asPanels,
      asOther,
    ]
  }
  if (isSS(data)) {
    panels = [
      ...panels,
      ...ssPanels,
      ssOther,
    ]
  }
  const section = {
    label: 'MATERIAL',
    panels,
  }
  return section
}

function getAsPanels(data: DRS.RootData) {
  const asProductData = _get(data, MaterialsAsProductPath)
  const asProducts = asProductData.map((asset: any, index: number) => {
    return {
      label: `Material Asphalt #${index + 1}`,
      repeatable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'asphaltLayer': {
          id: '',
          name: '',
        },
        'supplyPlant': {
          id: '',
          name: ''
        },
        'product': {
          id: '',
          description: '',
        },
        'maxRap': '',
        'customerSpecifiedMix': false,
        'depth': '',
        'area': '',
        'tonnes': '',
        'lotNumber': '',
        'controlLine': '',
        'wasteReturn': '',
        'wasteReturnReason': {
          id: '',
          description: '',
        },
      },
      repeatPath: MaterialsAsProductPath,
      path: `${MaterialsAsProductPath}.${index}`,
      fields: getAsFields(`${MaterialsAsProductPath}.${index}`),
    }
  })
  return asProducts
}
function getAsFields(path: string) {
  return [
    {
      label: 'Asphalt Layer',
      type: 'select',
      path,
      relativePath: 'asphaltLayer',
      editable: true,
      format: (value: any) => value ? value.id : '',
      options: getAsphaltLayers().map(item => ADIDNameToValueLabel(item)),
    },
    {
      label: 'Supply Plant',
      type: 'autocomplete',
      path,
      relativePath: 'supplyPlant',
      autocompleteConf: {
        type: 'supplyPlant',
        labelKey: 'name',
      },
      editable: true,
      format: (value: any) => value ? value.name : '',
    },
    {
      label: 'Product',
      type: 'autocomplete',
      path,
      relativePath: 'product',
      autocompleteConf: {
        type: 'product',
        labelKey: 'description',
      },
      editable: true,
      format: (value: any) => value ? value.description : '',
    },
    {
      label: 'Max Rap',
      type: 'text',
      path,
      relativePath: 'maxRap',
      editable: true,
    },
    {
      label: 'Customer Specified Mix',
      type: 'checkbox-select',
      path,
      relativePath: 'customerSpecifiedMix',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Depth (mm)',
      type: 'number',
      path,
      relativePath: 'depth',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Area (m2)',
      type: 'number',
      path,
      relativePath: 'area',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Tonnes',
      type: 'number',
      path,
      relativePath: 'tonnes',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Lot Number',
      type: 'text',
      path,
      relativePath: 'lotNumber',
      editable: true,
    },
    {
      label: 'Control Line',
      type: 'text',
      path,
      relativePath: 'controlLine',
      editable: true,
    },
    {
      label: 'Waste Return (T)',
      type: 'text',
      path,
      relativePath: 'wasteReturn',
      editable: true,
    },
    {
      label: 'Reason',
      type: 'select',
      path,
      relativePath: 'wasteReturnReason',
      editable: true,
      format: (value: any) => value ? value.description : '',
      options: getWasteReturnReasons().map(item => ADIDNameToValueLabel(item)),
    },
  ]
}

function getAsOther(data: DRS.RootData) {
  const path = `${MaterialsAsPath}.summary`
  return {
    label: `Other Materials Used`,
    repeatable: false,
    path,
    fields: [
      {
        label: 'Grit (T)',
        type: 'number',
        path,
        relativePath: 'grit',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Cat Eye covers (Quantity used)',
        type: 'number',
        path,
        relativePath: 'catEyeCovers',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Hatelite/Bitac (meters)',
        type: 'number',
        path,
        relativePath: 'hateliteBitac',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Road Base (T)',
        type: 'number',
        path,
        relativePath: 'roadBase',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Sticks & Stomps (Quantity used)',
        type: 'number',
        path,
        relativePath: 'sticksNStomps',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Dry Ice',
        type: 'checkbox-select',
        path,
        relativePath: 'dryIce',
        editable: true,
        options: CHECKBOX_SELECT_OPTIONS,
        format: (value: any) => value ? 'YES' : 'NO',
      },
      {
        label: 'Geo fabric (m2)',
        type: 'number',
        path,
        relativePath: 'geoFabric',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Emulsion (L)',
        type: 'number',
        path,
        relativePath: 'emulsion',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Tip Fees',
        type: 'number',
        path,
        relativePath: 'tipFees',
        editable: true,
        showTrending: true,
      }
    ]
  }
}

function getSsPanels(data: DRS.RootData) {
  const ssProductData = _get(data, MaterialsSsProductsPath)
  const ssPanels = ssProductData.map((asset: any, index: number) => {
    return {
      label: `Material Spray Seal #${index + 1}`,
      repeatable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'spraySealType': {
          id: '',
          description: '',
        },
        'binderType': {
          id: '',
          description: ''
        },
        'depot': {
          id: '',
          name: ''
        },
        'adhesionAgent': '',
        'aggregateInStockpile': '',
        'aggregateRequired_M3': '',
        'aggregateRequired_Tonnes': '',
        'aggregateSize': {
          id: '',
          description: ''
        },
        'area': '',
        'comments': '',
        'cutter': '',
        'existingSurfaceCondition': '',
        'orderedApplicationRate': '',
        'orderedRate': '',
        'otherSealType': '',
        'residualBinder': '',
        'spraySheetNumber': '',
      },
      repeatPath: MaterialsSsProductsPath,
      path: `${MaterialsSsProductsPath}.${index}`,
      fields: getSsFields(`${MaterialsSsProductsPath}.${index}`),
    }
  })
  return ssPanels
}
function getSsFields(path: string) {
  return [
    {
      label: 'Seal Type',
      type: 'select',
      path,
      relativePath: 'spraySealType',
      editable: true,
      format: (value: any) => value ? value.description : '',
      options: getSealTypes().map(item => ADIDNameToValueLabel(item)),
    },
    {
      label: 'Spray Binder Type',
      type: 'autocomplete',
      path,
      relativePath: 'binderType',
      autocompleteConf: {
        type: 'binderType',
        labelKey: 'description',
      },
      format: (value: any) => value ? value.description : '',
      editable: true,
    },
    {
      label: 'Spray Depot',
      type: 'autocomplete',
      path,
      relativePath: 'depot',
      autocompleteConf: {
        type: 'depot',
        labelKey: 'name',
      },
      format: (value: any) => value ? value.name : '',
      editable: true,
    },
    // {
    //   label: 'Depth (mm)',
    //   type: 'number',
    //   path,
    //   relativePath: 'depth',
    //   editable: true,
    //   showTrending: true,
    // },
    {
      label: 'Area (m2)',
      type: 'number',
      path,
      relativePath: 'area',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Binder Mixture Planned(AMC00 / % or parts for SA)',
      type: 'number',
      path,
      relativePath: 'binderMixture',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Ordered application rate ltsm2',
      type: 'number',
      path,
      relativePath: 'orderedApplicationRate',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Residual Binder @ 15ᴼ C (L)',
      type: 'number',
      path,
      relativePath: 'residualBinder',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Cutter @ 15ᴼ C (L)',
      type: 'number',
      path,
      relativePath: 'cutter',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Adhesion agent @ 15ᴼ C (L)',
      type: 'number',
      path,
      relativePath: 'adhesionAgent',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Aggregate Size (nominal size in mm)',
      type: 'autocomplete',
      path,
      relativePath: 'aggregateSize',
      autocompleteConf: {
        type: 'aggregateSize',
        labelKey: 'description',
      },
      format: (value: any) => value ? value.description : '',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Aggregate Required (m3)',
      type: 'number',
      path,
      relativePath: 'aggregateRequired_M3',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Aggregate Required (tonnes)',
      type: 'number',
      path,
      relativePath: 'aggregateRequired_Tonnes',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Aggregate in Stockpile',
      type: 'number',
      path,
      relativePath: 'aggregateInStockpile',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Aggregate Used',
      type: 'number',
      path,
      relativePath: 'aggregateUsed',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Ordered Rate (m2/m3)',
      type: 'number',
      path,
      relativePath: 'orderedRate',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Other Seal Type',
      type: 'number',
      path,
      relativePath: 'otherSealType',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Existing Surface Condition',
      type: 'text',
      path,
      relativePath: 'existingSurfaceCondition',
      editable: true,
    },
    {
      label: 'Spray Sheet Number',
      type: 'text',
      path,
      relativePath: 'spraySheetNumber',
      editable: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}

function getSsOther(data: DRS.RootData) {
  const path = `${MaterialsSsPath}.summary`
  return {
    label: `Other Materials Used`,
    repeatable: false,
    path,
    fields: [
      {
        label: 'Grit (T)',
        type: 'number',
        path,
        relativePath: 'grit',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Cat Eye covers (Quantity used)',
        type: 'number',
        path,
        relativePath: 'catEyeCovers',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Hatelite/Bitac (meters)',
        type: 'number',
        path,
        relativePath: 'hateliteBitac',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Sticks & Stomps (Quantity used)',
        type: 'number',
        path,
        relativePath: 'sticksNStomps',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Geo fabric (m2)',
        type: 'number',
        path,
        relativePath: 'geoFabric',
        editable: true,
        showTrending: true,
      },
      {
        label: 'Emulsion (L)',
        type: 'number',
        path,
        relativePath: 'emulsion',
        editable: true,
        showTrending: true,
      }
    ]
  }
}