import { capWord } from '../utils/capWord'

/**
 * adapt profile to { value, label }
 * where value is email, label is `First Last`
 * 
 * 
 */


export const ADProfile = (profile: any) => {
  try {
    const { firstName, lastName, email } = profile
    return {
      value: email,
      label: `${capWord(firstName)} ${capWord(lastName)}`,
      raw: profile,
    }
  } catch (err) {
    console.log('err adapting profile')
  }
  return profile
}