import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import _unionBy from 'lodash/unionBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'


import { useSnackbar } from '@src/components/GlobalSnackbar'



import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'

import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiMapResolveIssue } from '@src/apis/map-resolve-issue'
import { useAppStore } from '@src/AppStore'




const Format = 'DD/MM/YYYY'
interface Props {
  open: boolean,
  onClose(): void,
  onSave(): void,
  data: any,
 
}

const MapIssuesDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
    data
   
  } = props
  const isAdd = data === null
  const isEdit = !isAdd
  const { alertDone, alertError, alert } = useSnackbar()
  const { confirm } = useConfirm()
  const issues = [];
 
  const { serialId } = useAppStore()
  const [isSaving, setIsSaving] = useState<any>(false)






  


 

  const handleOnUpdated = async (item: any) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Sure to resolve  this issue?',
      })
      const result = await  apiMapResolveIssue({serialId:serialId,assetId:item.assetId,questionId:item.questionId,answer:true})
      onClose();
    } catch (e) {
      alertError();
     }
  }
  

  
 

  useEffect(() => {
    if (data !== null) {
     
     
    }
  }, [data])

  const handleChange = (id: any) => {
    const dataID: any = data[id];
      if(dataID.answer) {
        dataID.answer = false;
        data[id] = dataID;
        return;
      }else{
        dataID.answer = true;
        data[id] = dataID;
        return;
      }
  //  let issueData = issues;
   
      //console.log(eve nt.target.checked);
      if(issues.indexOf(id)==-1) {

        issues.push(id);
       
       //setIssues(issueData);
      }else{
        issues.splice(issues.indexOf(id),1);
      //  setIssues(issueData);
      }
      console.log(issues);
    
   
};
  const handleSave = async () => {
   
  
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>Issues</ModalTitle>
    </DialogTitle>
    <DialogContent>
  
          <FieldsWrapper>
          {data.map((item: any,index: number)=>{
            return item.answer == true ? null :
            <IssueSection>
              <IssueSectionItem>
                <FieldWrapperTitle>{item.questionText}</FieldWrapperTitle>
                {item.questionFullText}
                <SectionWrapper>
                <CompThemeButton onClick={()=>{
                  handleOnUpdated(item);
                 }}>Resolve</CompThemeButton>
                </SectionWrapper>
             
              </IssueSectionItem>
              
         
            </IssueSection>
                })}
          </FieldsWrapper>
          
            {/* {data.map((item: any,index: number)=>{
              return  <FormControlLabel control={ <CompThemeButton onClick={()=>{}}>Resolve</CompThemeButton>} label={item.questionText + issues.indexOf(item.questionId)} />
            })} */}
            
        
    </DialogContent>
    <DialogActions>
     
      
      
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
      
        onClose()
      }}>
        Cancel
      </Button>
     
    </DialogActions>
  </Dialog>
}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
  margin-top:5px;
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`
const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const IssueSection = styled.div`
width: 100%;
padding: 13px;
border: 1px solid #ececec;
`
const IssueSectionItem = styled.div`
display: inline-block;
margin-left: 5px;


`
const IssueSectionItemBtn = styled.div`
display: inline-block;
margin-left: 5px;

`
const FieldWrapperTitle = styled.h1`
color: #129688;
font-size: 18px;
`
const FormModalSelectWrapper = styled.div`
  min-width: 200px;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`


export default MapIssuesDialog