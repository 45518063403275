import { ax } from "../utils/axios/axios";
import { BASE_API_URL } from "../constants";

interface Options {
  userSearialId: number;
  viewName: string;
  selectedItems: string;
  sharedUserSearialId: string;
  isDefault: boolean;
}
export const apiUserShiftCardViewCreate = ({
  userSearialId,
  viewName,
  selectedItems,
  sharedUserSearialId,
  isDefault,
}: Options) => {
  const data = {
    userSearialId,
    viewName,
    selectedItems,
    sharedUserSearialId,
    isDefault,
  };
  const options = {
    method: "POST",
    url: `${BASE_API_URL}/ref/shiftcardviews`,
    data,
  };
  return ax(options);
};
