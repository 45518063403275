import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Field from './Field'

const Row = ({
  row,
  onFieldChange,
}: {
  row: any,
  onFieldChange(field: { path: string, addTableRow?: boolean, removeTableRow?: boolean, rowIndex?: number }, val: any): void,
}) => {
  const handleOnFieldChange = (field: DRS.Field) => (val: any, options: any) => {
    if (field.typeMeta!.isTable) {
      if (options.isCell) {
        onFieldChange({
          path: options.path,
        }, val)
      } else if (options.isAdd) {
        onFieldChange({
          addTableRow: true,
          path: options.path,
        }, val)
      } else if (options.isRemove) {
        onFieldChange({
          removeTableRow: true,
          rowIndex: options.rowIndex,
          path: options.path,
        }, val)
      } else {
        onFieldChange({
          path: options.fieldPath,
        }, val)
      }
    } else if (field.typeMeta!.type === 'editable-checkbox-group-flat') {
      onFieldChange({
        path: options.path,
      }, val)
    } else {
      onFieldChange(field, val)
    }
  }

  let span: any = 2
  if (row.type === 'single-field-row' || row.type === 'single-button') {
    span = 12
  }

  let xs: any = 12
  let md: any = span === 12 ? 12 : 6
  let lg: any = span === 12 ? 12 : 4
  
  return <StyledGridContainer container>
    { row.fields.map((field: DRS.Field) => (
      <Grid key={field.path || ''} item xs={12} md={md} lg={lg} xl={span} >
        <Field field={field} onChange={handleOnFieldChange(field)}/>
      </Grid>
    ))}
  </StyledGridContainer>
}

const StyledGridContainer = styled(Grid)`
  margin-bottom: 40px;
`

export default Row