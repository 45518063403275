import React from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import { PartialBy } from '@src/types/utils/PartialBy'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import ButtonBase from '@material-ui/core/ButtonBase'
import { DrawingMode } from './types/DrawingMode'

interface Props {
  mode: DrawingMode,
  initialText: string,
  onAddArrow(): void,
  onAddRectangle(): void,
  onAddText(text: string): void,
}

const CompElementStore = (props: Props) => {
  const {
    mode,
    initialText,
    onAddArrow,
    onAddRectangle,
    onAddText,
  } = props
  const PRESET_TEXTS = [
    'WORKSITE',
    'MARSHALLING AREA',
    'ENTRY',
    'EXIT',
    'NO U TURN',
    'CLEAN OUT AREA',
  ]
  const handleAddArrow = () => {
    onAddArrow()
  }
  const handleAddRectangle = () => {
    onAddRectangle()
  }
  const handleAddText = (text: string) => () => {
    onAddText(text)
  }
  return <Wrapper>
    <Section>
      <Title>Pick Shape</Title>
      <FlexWrapper>
        <ArrowIcon active={mode === DrawingMode.AddArrow ? 1 : 0} onClick={handleAddArrow}>
          <ArrowRightAltIcon />
        </ArrowIcon>
        <RectIcon active={mode === DrawingMode.AddRectangle ? 1 : 0} onClick={handleAddRectangle}>
          <RectInner />
        </RectIcon>
      </FlexWrapper>
    </Section>
    <Section>
      <Title>or Pick Text</Title>
      <FlexWrapper>
        { PRESET_TEXTS.map((text: string) => {
          const active = mode === DrawingMode.AddTextBox && text === initialText ? 1 : 0
          return <TextPlaceholder 
            key={text}
            active={active} 
            onClick={handleAddText(text)}>{text}</TextPlaceholder>
        })}
        <FreeTextPlaceholder active={mode === DrawingMode.AddTextBox && initialText === '' ? 1 : 0} onClick={handleAddText('')}></FreeTextPlaceholder>
      </FlexWrapper>
    </Section>
  </Wrapper>
}

const Wrapper = styled.div`
  padding: 0 20px;
`
const Section = styled.div`
  margin-bottom: 10px;
`
const Title = styled.div`
  font-size: 24px;
  color: #645E5E;
  font-weight: 500;
  margin-bottom: 6px;
`
const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ArrowIcon = styled(ButtonBase)<{active: number}>`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
  ${props => {
    if (props.active) {
      return css`
        background-color: #189688;
      `
    } else {
      return css`
        background-color: #D8D8D8;
      `
    }
  }}
`
const RectIcon = styled(ButtonBase)<{active: number}>`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
  ${props => {
    if (props.active) {
      return css`
        background-color: #189688;
      `
    } else {
      return css`
        background-color: #D8D8D8;
      `
    }
  }}
`
const RectInner = styled.div`
  width: 20px;
  height: 12px;
  border: 1px dashed #F8F7F8;
`
const TextPlaceholder = styled(ButtonBase)<{active: number}>`
  padding: 8px;
  margin: 0 20px 20px 0;
  cursor: pointer;
  ${props => {
    if (props.active) {
      return css`
        padding: 7px;
        border: 2px solid #189688;
      `
    } else {
      return css`
        border: 1px dashed #707070;
      `
    }
  }}
`

const FreeTextPlaceholder = styled(TextPlaceholder)`
  width: 100px;
`
export default CompElementStore