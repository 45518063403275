import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

export const apiATPSectionPDF = ({
  id, // VMP Site Map ID
  serialId,
}: {
  id: number,
  serialId: number,
}) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/pdf/atp/${id}`,
    responseType: 'blob',
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager
      // ATPAllpdf: id
    }, 
    headers: {
      Accept: 'application/pdf',
    },
  }
  return ax(options)
}
