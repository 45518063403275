import React from 'react'
import styled, { css } from 'styled-components'
import Chip from '@material-ui/core/Chip'

export interface LeftNavAnchorProps {
  activeIndex: number,
  anchors: string[],
  onClick?(anchor: string, index: number): void,
  chipIndex?: number,
  chipText?: string,
}

const Anchors = (props: LeftNavAnchorProps) => {
  const {
    anchors, 
    activeIndex, 
    onClick = () => {} ,
    chipIndex = -1,
    chipText = '',
  } = props
  const handleClick = (anchor: string, index: number) => {
    onClick(anchor, index)
  }
  return <Wrapper>
    { anchors.map((anchor: string, index: number) => {
      const highlighted = index === activeIndex
      return <Item key={anchor}>

        <Dot highlighted={highlighted}/>

        { index !== anchors.length - 1 ? <Line /> : null }
        <TextChipWrapper onClick={() => handleClick(anchor, index)}>
          <Text highlighted={highlighted}>
            {anchor}
          </Text>
          { chipIndex === index && <SChip size='small' color='primary' label={chipText} /> }
        </TextChipWrapper>

      </Item>
    })}
  </Wrapper>
}

const Wrapper = styled.div``
const Item = styled.div`
  position: relative;
  height: 58px;
`
const Dot = styled.div<{ highlighted: boolean }>`
  ${props => {
    if (props.highlighted) {
      return css`
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background-color: #009688;
        left: 0px;
        top: 0px;
      `
    } else {
      return css`
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #000;
        left: 4px;
        top: 4px;
      `
    }
  }}
  position: absolute;
  z-index: 1;
  
`
const Line = styled.div`
  height: 50px;
  width: 2px;
  background-color: #000;
  position: absolute;
  left: 7px;
  top: 12px;
`
const TextChipWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: -2px;
  display: flex;
`
const Text = styled.div<{ highlighted: boolean }>`
  ${props => {
    if (props.highlighted) {
      return css`
        font-size: 16px;
        color: #189F93;
      `
    } else {
      return css`
        font-size: 14px;
      `
    }
  }}
`
const SChip = styled(Chip)`
  margin-left: 20px;
  .MuiChip-label {
    font-size: 10px;
  }
`
export default Anchors