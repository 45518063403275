import React, { useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { useTabs, ASSSTabs, getTabsByType } from '@src/components/Tabs/useTabs'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import TabsWrapper from '../../comps/TabsWrapper'
import CompAsMaterial from './parts/AsMaterial'
import CompSsMaterial from './parts/SsMaterial'
import { useSRR } from '../../useSRR'
import { ProjectTypeId } from '@src/types/ProjectTypeId'

interface Props {

}

const SRRMaterial = (props: Props) => {
  const {

  } = props
  const [ tabIndex, tabs, handleTabChange, setTabs ] = useTabs(ASSSTabs)
  const [ srr, srrLoading, refresh ] = useSRR()
  useEffect(() => {
    if (srr !== null) {
      setTabs(getTabsByType(srr.job.shiftDetails.shiftType.id))
    }
  }, [srr])

  const renderAsOrSS = () => {
    const currentTab: Tab = tabs[tabIndex]
    if (currentTab.key === ProjectTypeId.Asphalt) {
      return <CompAsMaterial />
    } else {
      return <CompSsMaterial />
    }
  }
  return <Wrapper>
    <FormSectionTitle>Material</FormSectionTitle>
    <TabsWrapper>
      <TabsComp 
        tabs={tabs} 
        activeIndex={tabIndex}
        onChange={handleTabChange}
      />
    </TabsWrapper>
    { renderAsOrSS() }
    
  </Wrapper>
}

const Wrapper = styled.div`

`

export default SRRMaterial