import React, { useState } from 'react'
import styled from 'styled-components'
import { Project } from '@src/types/project/Project'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ProjectAutocomplete from '@src/components/autocompletes/ProjectAutocomplete'
import LinkIcon from '@material-ui/icons/Link'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiProjectLink } from '@src/apis/project-link'

interface Props {
  open: boolean,
  onSuccess(): void,
  onClose(): void,
  onCancel(): void,
  data: {
    project: Project,
  }
}

const LinkDraftProjectDialog = (props: Props) => {
  const { 
    open,
    onSuccess,
    onClose,
    onCancel,
    data: {
      project,
    }
  } = props
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ selectedProject, setSelectedProject ] = useState<Project | undefined>()
  const { confirm } = useConfirm()
  const handleOk = async () => {
    try {
      await confirm({
        title: `Confirm if you’ve entered the correct Project Number`,
        message: `${selectedProject!.id}`,
      })
      const result = await apiProjectLink({
        projectInternalId: project.projectInternalId,
        scenarioProjectInternalId: selectedProject!.projectInternalId,
      })
      onSuccess()
    } catch (e) {
      console.log('no Let me re-enter')
    }
  }

  return <Dialog 
    fullWidth
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle>
      {/* <LinkIcon /> */}
      Link to Scenario
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Select a Scenario project number to link this draft project. All project financials from this draft project will integrate with Scenario.
      </DialogContentText>
      <Section>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled
                label={`Project`}
                value={project.name}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled
                label={`Customer`}
                value={project.customer ? project.customer.name : ''}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled
                label={`Location`}
                value={project.location}
              />
            </GridInner>
          </Grid>
        </Grid>
      </Section>
      <AutocompleteWrapper>
        <ProjectAutocomplete 
          label='Select Scenario project number'
          fullWidth
          isLinked={false}
          onChange={(project: Project) => {
            setSelectedProject(project)
          }}
          selectedItem={
            selectedProject ? {
              label: `${selectedProject.id} | ${selectedProject.name}`,
              value: selectedProject.id,
            } : {
              label: '',
              value: '',
            }
          }
        />
      </AutocompleteWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button 
        color='primary'
        variant='contained'
        disabled={!selectedProject || loading} 
        onClick={handleOk} 
        >
        { `Link Draft` }
      </Button>
    </DialogActions>
  </Dialog>
}

const Section = styled.div`

`
const GridInner = styled.div`
  padding-right: 16px;
`
const AutocompleteWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
  padding: 0 160px;
`

export default LinkDraftProjectDialog