import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSMobileAsset } from '@src/types/drs/mobile-assets/MobileAsset'


export enum DRSAssetDepotType {
  MyDepot = 'My Depot',
  MyState = 'My State',
}
export interface DRSMobileAssetDisplay extends DRSMobileAsset {
  assetDepotType: {
    id: DRSAssetDepotType,
    description: string,
  },
  assetDescription: string,

}

const DRSMobileAssetsFields: FormTableField<DRSMobileAssetDisplay>[] = [
  {
    id: 'DRSAssetDepotType',
    key: 'assetDepotType',
    type: FormTableFieldType.Select,
    label: 'Asset Depot',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    id: 'drs-rework-asset',
    key: 'mobileAsset',
    type: FormTableFieldType.Autocomplete,
    label: 'Asset Number',
    format: (item: any) => {
      return item ? item.id : ''
    },
    alsoUpdates: ['assetDescription'],
    alsoUpdatesFunc: (item: any, form: any) => {
      let valDesc: any = document.getElementById('assetDescription');
      let valDescLabel: any = document.getElementById('assetDescription-label');
      valDesc.value = item.description;
      form.assetDescription = item.description;
      valDescLabel.setAttribute('class', 'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-focused Mui-focused');
      valDescLabel.setAttribute('data-shrink', true);
      return [item.description]
    }
  },
  {
    id: "assetDescription",
    key: 'assetDescription',
    type: FormTableFieldType.Text,
    label: 'Asset Description',
    disabled: true

  },
  {
    id: 'mobile-asset-hours',
    key: 'hours',
    type: FormTableFieldType.Select,
    label: 'Hours',
    format: (cellData: any) => {
      return cellData
    }
  },
  {
    id: 'mobile-asset-backup',
    key: 'backupMachine',
    type: FormTableFieldType.CheckboxGroup,
    label: 'Backup Machine',
    options: ([{ value: "Y", label: "Required as backup machine only?" }]),
    format: (cellData: any) => {
      if (cellData == true) {
        return 'Y';
      } else {
        return 'N'
      }
      //return cellData
    }

  },
  {
    id: 'mobile-asset-notused',
    key: 'notUsed',
    type: FormTableFieldType.CheckboxGroup,
    label: 'Asset Used',
    options: ([{ value: 'Asset Used', label: "Asset Used?" }]),
    format: (cellData: any) => {
      if (cellData === true) {
        return 'Asset Not Used';
      } else if(cellData == null) {
        return null
      } else if(cellData===false) {
        return 'Asset Used';
      }
      return cellData
    }

  }
]

export default DRSMobileAssetsFields