import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TimePicker from '../TimePicker'
import moment, { Moment } from 'moment'

interface Props {
  label: string,
  value: string,
  timeFormat?: string,
  onChange(time: Moment): void,
}

export const FormModalTimePickerFormat = 'HH:mm:ss'

const FormModalTimePicker = (props: Props) => {
  const {
    label,
    value,
    timeFormat,
    onChange,
  } = props
  
  const handleChange = (time: Moment | null) => {
    if (time !== null) {
      // onChange(time.format(FormModalTimePickerFormat))
      time.seconds(0).milliseconds(0)
      onChange(time)
    }
  }
  let finalValue = null
  if (value) {
    if (timeFormat) {
      finalValue = moment(value, timeFormat)
    } else {
      finalValue = moment(value)
    }
  }
  return <FormControl component='fieldset'>
    <FormLabel component='legend'>{label}</FormLabel>
    <TimePicker 
      ampm={false}
      value={finalValue}
      onChange={handleChange}
    />
  </FormControl>
}

const Wrapper = styled.div`

`

export default FormModalTimePicker