import React from 'react'
import styled from 'styled-components'
import CompPreviewSection from '../../comps/Section'
import CompPreviewFieldPair, { PlaceholderPreviewFieldPair } from '../../comps/FieldPair'
import { useSRRInDRS } from '../../useSRRInDRS'
import { SRR } from '@src/types/SRR'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { formatTimeStringOrNullHHmm } from '@src/types/format/timeStringOrNullHHmm'
import { formatNullStringNumberToString } from '@src/types/format/formatNullStringNumberToString'

interface Props {

}

const CompPreviewJob = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  const mappedSRRDetails = mapSRRDetails(srr)
  const mappedShiftDetails = mapShiftDetails(srr)
  const mappedSiteDetails = mapSiteDetails(srr)
  return <Wrapper>
    <CompPreviewSection name='SRR Details'>
      <FlexFields>
        { mappedSRRDetails.map((item) => {
          return <CompPreviewFieldPair
            halfWidth
            key={item.label}
            label={item.label}
            value={item.value}
          />
        })}
      </FlexFields>
    </CompPreviewSection>
    <CompPreviewSection name='Shift Details'>
      <FlexFields>
        { mappedShiftDetails.map((item) => {
          return <CompPreviewFieldPair
            halfWidth
            key={item.label}
            label={item.label}
            value={item.value}
          />
        })}
      </FlexFields>
    </CompPreviewSection>
    <CompPreviewSection name='Site Details'>
      <FlexFields>
        { mappedSiteDetails.map((item) => {
          return <CompPreviewFieldPair
            halfWidth
            key={item.label}
            label={item.label}
            value={item.value}
          />
        })}
      </FlexFields>
    </CompPreviewSection>
  </Wrapper>
}

function mapSRRDetails(srr: SRR) {
  return [
    {
      label: 'SRR Created on',
      value: formatDateStringOrNullDDMMYYYY(srr.job.shiftResourceRequirementInformation.creationDate),
    },
    {
      label: 'SRR Created by',
      value: formatProfileOrNullFirstLastName(srr.job.shiftResourceRequirementInformation.createdBy),
    },
    {
      label: 'SRR Updated on',
      value: formatDateStringOrNullDDMMYYYY(srr.job.shiftResourceRequirementInformation.updatedDate),
    },
    {
      label: 'SRR Updated by',
      value: formatProfileOrNullFirstLastName(srr.job.shiftResourceRequirementInformation.updatedBy),
    },
  ]
}

function mapShiftDetails(srr: SRR) {
  return [
    {
      label: 'Weekend Work',
      value: formatBooleanOrNullToYesNo(srr.job.shiftDetails.weekendWork),
    },
    {
      label: 'Handwork',
      value: formatBooleanOrNullToYesNo(srr.job.shiftDetails.handWork),
    },
  ]

}
function mapSiteDetails(srr: SRR) {
  return [
    {
      label: 'Map Reference',
      value: formatNullStringNumberToString(srr.siteInformation.siteDetails.mapReference),
    },
    PlaceholderPreviewFieldPair,

    {
      label: 'Time on Site',
      value: formatTimeStringOrNullHHmm(srr.siteInformation.siteDetails.timeOnSite),
    },
    {
      label: 'Time off site',
      value: formatTimeStringOrNullHHmm(srr.siteInformation.siteDetails.timeOffSite),
    },

    {
      label: 'Site Specific Induction',
      value: formatBooleanOrNullToYesNo(srr.siteInformation.siteDetails.siteSpecificInduction),
    },
    {
      label: 'Plant Risk Assessments',
      value: formatBooleanOrNullToYesNo(srr.siteInformation.siteDetails.plantRiskAssessmentsRequired),
    },

    {
      label: 'Emergency Assembly Point',
      value: formatNullStringNumberToString(srr.siteInformation.siteDetails.emergencyAssemblyPoint),
    },
    {
      label: 'Nearest Hospital',
      value: formatNullStringNumberToString(srr.siteInformation.siteDetails.nearestHospital),
    },

    {
      label: 'UHF Channel',
      value: formatNullStringNumberToString(srr.siteInformation.siteDetails.uhfChannel),
    },
    PlaceholderPreviewFieldPair,

    {
      label: 'Base Condition Description',
      value: formatNullStringNumberToString(srr.siteInformation.siteDetails.baseCondition),
    },
  ]
}

const Wrapper = styled.div`

`
const FlexFields = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default CompPreviewJob