import React from 'react'
import styled from 'styled-components'
import CompAsProduct from './AsProduct/AsProduct'
import CompAsOther from './AsOther/AsOther'

interface Props {

}

const CompAsMaterial = (props: Props) => {
  const {

  } = props
  return <Wrapper>
    <React.Fragment>
      <CompAsProduct />
      <CompAsOther />
    </React.Fragment>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompAsMaterial