/**
 * read only field for job type 
 * 
 * const TYPES = [ 'ASPHALT', 'SPRAY-SEAL', 'ASPHALT-SPRAY-SEAL' ]
 * 
 * 
 */

import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
`
const ContentText = styled.div`

`
const SmallerRectangle = styled.div<{ colourCode: string }>`
  width: 12px;
  height: 12px;
  background-color: ${props => `#${props.colourCode}`};
  margin-right: 8px;
`

function CrewStatic ({
  crew,
}: {
  crew: {
    name: string,
    colourCode: string,
  },
}) {
  const { name, colourCode } = crew
  const colorfulRectangle = colourCode ? <SmallerRectangle colourCode={colourCode} /> : null
  return <ContentWrapper>
    { colorfulRectangle }
    <ContentText>{name}</ContentText>
  </ContentWrapper>
  
 }

 export default CrewStatic