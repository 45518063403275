import React, { useState, useEffect } from "react";
import { useCrewCalender } from "../../modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

function useWindowSize(cols?: any | undefined) {
  let [windowSize, setWindowSize] = useState<any>(getSize());
  const { setGridWidth } = useCrewCalender();
  function handleResize() {
    setWindowSize(getSize());
    setGridWidth(getSize().innerWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}

export default useWindowSize;
