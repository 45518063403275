import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { ADIDNameToValueLabel } from '../../api-adaptors/id-name-to-value-label'
import { apiSupplyPlantSearch } from '../../apis/supply-plant-search'

const SupplyPlantAutocomplete = ({
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
}: AIMS.Autocomplete) => {
  const fetch = async (query: string) => {
    const result: any = await apiSupplyPlantSearch({query})
    return result.data.plants
      .slice(0, 5)
      .map((plant: any) => {
        return {
          ...ADIDNameToValueLabel(plant),
          raw: plant,
        }
      })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label || 'Supply Plant'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default SupplyPlantAutocomplete