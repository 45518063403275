import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import _unionBy from 'lodash/unionBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import FormControl from '@material-ui/core/FormControl'
import FormModalCheckboxGroup from '@src/components/FormModal/CheckboxGroup'
import CustomCheckboxGroup from '@src/components/FormModal/CustomCheckboxGroup'
interface Props {
  open: boolean,
  type: string
  currentValue: any,
  options: any,
  label?: string
  onClose(): void,
  onSave(dates: any): void,

}

const OptionFilterDialog = (props: Props) => {
  const {
    open,
    type,
    onClose,
    onSave, label,
    options,
    currentValue
  } = props
  const [field, setFieldData] = useState<any>(['ALL'])
  const setField = (key: any, item: any,current: string) => { 
    if(item.length < options.length &&  item.indexOf('ALL')!== -1){
      item.splice(item.indexOf('ALL'),1);  
    
    }
    if(current == 'ALL' && key ==  true){
      for(var i in options){
        var itemsData: any = i;
        if(item.indexOf(options[itemsData].value) == -1)
          item.push(options[itemsData].value);
       }
       setFieldData(item);
       return;
    }
    if(current == 'ALL' && key ==  false){
      item = [];
      setFieldData(item);
      return;
    }
    setFieldData(item);
    //  return;
  }
  const handleFilter = () => {
    onSave(field);
  }
  useEffect(() => {
//  console.log(currentValue)
//  console.log(field)
 setFieldData(currentValue);
  }, [currentValue])
  return <Dialog
    fullWidth={false}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>{label ? label : 'Choose Option'}</ModalTitle>
    </DialogTitle>
    <DialogContent>
      <SectionWrapper>
        {
          <FormControl fullWidth>
            <CustomCheckboxGroup
              label={label ? label : 'Choose Option'}
              value={field}
              options={options}
              onChange={(item: any,isChecked: boolean,current: string) => {
                
                setField(isChecked, item,current)
              }}
            
            />
          </FormControl>
        }
      </SectionWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
        onClose()
      }}>
        Cancel
      </Button>
      <Button color='primary' variant='contained' onClick={handleFilter} >
        Filter
      </Button>
    </DialogActions>
  </Dialog>
}


const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`


const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const Title = styled.div`
  font-size: 16px;

  margin: 24px 0;
  min-height: 28px;
`

const FormItem = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`
export default OptionFilterDialog