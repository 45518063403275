import React from 'react'
import styled from 'styled-components'
import CommonFormFieldLabel from './CommonLabel'

export interface FieldPairProps {
  label: string,
  value: string,
}

const FieldPair = (props: FieldPairProps) => {
  const { label, value } = props
  return <Wrapper>
    <CommonFormFieldLabel>{label}</CommonFormFieldLabel>
    <Value>{value}</Value>
  </Wrapper>
}

const Wrapper = styled.div`
`

const Value = styled.div`
font - size: 16px;
/* height: 21px; */
line - height: 21px;
color: #000;
overflow-wrap: break-word;
`

export default FieldPair