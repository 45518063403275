import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { JobType } from '@src/types/JobType'

interface Options {
  srrId: number | string,
  serialId: number,
  jobType: JobType,
  reworkPercentage?: number | string,
  reworkTonnes?: number | string,
  hours?: number | string,
  reworkEmulsion?: number | string,
}

export const apiSrrReworkUpdate = (options: Options) => {
  const { 
    srrId, 
    serialId,
    jobType,
    reworkPercentage,
    reworkTonnes,
    hours,
    reworkEmulsion,
  } = options

  const data = {
    srrId,
    shiftType: {
      id: jobType,
    },
    reworkPercentage,
    reworkTonnes,
    hours,
    reworkEmulsion,
  }
  const asOrSs = jobType === JobType.Asphalt ? 'asphalt' : 'sprayseal'
  const oneOrTwo = jobType === JobType.Asphalt ? 1 : 2
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/rework/${asOrSs}/details/${oneOrTwo}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}