import React, { useContext } from "react";
import { observable, action } from "mobx";
import moment, { Moment } from "moment-timezone";
import { Crew } from "@src/types/Crew";
import { CrewNote, Shift } from "@src/types/Shift";
import { getStoredValue, setStoredValue } from '@src/utils/storedValue'
import { number, string } from "prop-types";

const VIEW_TYPE_ENUM = {
  WEEK: "WEEK",
  DAY: "DAY",
  MONTH: "MONTH",
};
function filterArr(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}
function mergeLeaveNotes(result: any, leaveData: any, batchLength: number, batchStartDay: any) {

  leaveData.forEach((lvNote: any) => {
    const { LeaveDate, CrewID } = lvNote;
    const foundIndx = result.findIndex((crew: Crew) => crew.id === CrewID);
    var a = moment(LeaveDate);
    var b = moment(batchStartDay);
    if (foundIndx != -1) {
      if (a.diff(b, 'days') < batchLength && a.diff(b, 'days') >= 0) {
        result[foundIndx].drss[a.diff(b, 'days')].push(lvNote);
      }
    }
  })
  return (result);

}
function handleCrewsCache(crewData: any, crewLocalData: any) {
  var finalCrewData: any = [];
  console.log('----->>><<<<<<<<_______________');
  console.log(crewData.length);
  console.log(crewLocalData.length);
  console.log('----->>><<<<<<<<_______________');
  if (crewData.length > crewLocalData.length) {
    finalCrewData = crewLocalData;
    finalCrewData.forEach((loopCrew: any,indexLoop: any) => {
      const activeIndex = crewData.findIndex((data: any) => data.id === loopCrew.id);
      if(activeIndex== -1) {
        finalCrewData.splice(indexLoop,1);
      }
    });
    crewData.forEach((loopCrew: any,indexLoop: any) => {
      const activeIndex = crewLocalData.findIndex((data: any) => data.id === loopCrew.id);
      if(activeIndex== -1) {
        finalCrewData.push(loopCrew);
      }
    });
   
    return finalCrewData;
  }
  if (crewData.length < crewLocalData.length) {
    finalCrewData = crewData;
    crewLocalData.forEach((loopCrew: any,indexLoop: any) => {
      const activeIndex = crewLocalData.findIndex((data: any) => data.id === loopCrew.id);
      if(activeIndex== -1) {
        finalCrewData.push(loopCrew);
      }
    });
    console.log('----->>><<<<<<<<_________1______');
    console.log((crewData.length));
    console.log((crewLocalData.length));
    console.log('----->>><<<<<<<<_________1______');
    return finalCrewData;
  }

}
export const defaultViewOptions = [
  'Project Number',
  'Customer',
  'Street',
  'Suburb',
  'Mix Type & Tonnes'
]

const store: any = observable({
  isCalendarLoading: false,
  setIsCalendarLoading: action((val: boolean) => store.isCalendarLoading = val),
  isCalendarControlsDisabled: false,
  setCalendarControlsDisabled: action((val: boolean) => store.isCalendarControlsDisabled = val),
  refreshHook: 0,
  webhookAnimationInterval: -1,
  goRefresh: action(() => store.refreshHook++),
  gridNumber: getStoredValue<number>("gridNumber", 8),
  fullScreen: false,
  webHookTime: -1,
  setFullScreen: action((flag: boolean) => (store.fullScreen = flag)),
  setWebHookTime: action((value: number) => (store.webHookTime = value)),
  setWebhookAnimationInterval: action((value: number) => (store.webhookAnimationInterval = value)),
  isDepotNoteAppear: getStoredValue<Boolean>("isDepotNoteAppear", false),
  gridWidth: 0,
  crewNameWidth: 0,

  isShiftCardViewDragHappening: false,

  setIsShiftCardViewDragHappening: action((isDragHappening: boolean) => {
    store.isShiftCardViewDragHappening = isDragHappening
  }),
  selectedShiftCardViews: [],
  selectedShiftCardViewsCount: 0,
  setGridWidth: action((innerWidth: number) => {
    let adjRatio: number;
    switch (store.gridNumber) {
      case 8:
        adjRatio = 0.5;
        break;
      case 15:
        adjRatio = 0.2;
        break;
      case 22:
      case 29:
        adjRatio = 0;
        break;
      default:
        adjRatio = 0.5;
    }

    const widthRed = (innerWidth / store.gridNumber) * adjRatio;
    const widthAdd = widthRed / store.gridNumber;
    store.gridWidth = innerWidth / store.gridNumber + widthAdd;
    store.crewNameWidth = innerWidth / store.gridNumber - widthRed;
  }),
  setGridColsNumber: action((cols: number, innerWidth: number) => {
    store.gridNumber = cols;
    setStoredValue("gridNumber", cols)
    let adjRatio: number;
    switch (cols) {
      case 8:
        adjRatio = 0.5;
        break;
      case 15:
        adjRatio = 0.2;
        break;
      case 22:
      case 29:
        adjRatio = 0;
        break;
      default:
        adjRatio = 0.5;
    }

    const widthRed = (innerWidth / cols) * adjRatio;
    const widthAdd = widthRed / cols;
    store.gridWidth = innerWidth / cols + widthAdd;
    store.crewNameWidth = innerWidth / cols - widthRed;
  }),
  get batchLength() {
    if (store.gridNumber) {
      return store.gridNumber - 1;
    }
    return 0;
  },
  get currentBatch() {
    if (store.batchStartDay && store.batchLength) {
      let start = store.batchStartDay.clone();
      let temp = start;
      let result: moment.Moment[] = [];
      new Array(store.batchLength as number)
        .fill(undefined)
        .forEach((item, index) => {
          result.push(temp.clone());
          temp = temp.add(1, "day");
        });
      return result;
    } else {
      return [];
    }
  },
  today: null,
  firstLoad: true,
  currentShiftCardView: [],
  allUserShiftCardViews: [],
  setAllUserShiftCardViews: action((views: any) => (store.allUserShiftCardViews = views)),
  setFirstLoad: action((val: any) => (store.firstLoad = val)),
  setToday: action((today: moment.Moment) => {
    let startOfWeek = moment().startOf('day');
    startOfWeek = startOfWeek.startOf('week');
    const offset = today.diff(startOfWeek, 'days') - 1
    store.today = today;
    store.setBatchStartDay(today.clone().day(offset));
  }),
  batchStartDay: null,
  setBatchStartDay: action((val: any) => (store.batchStartDay = val)),

  currentWeek: null,
  currentMonth: null,
  viewType: VIEW_TYPE_ENUM.WEEK,
  get isWeekView() {
    return store.viewType === VIEW_TYPE_ENUM.WEEK;
  },
  get isDayView() {
    return store.viewType === VIEW_TYPE_ENUM.DAY;
  },
  get isMonthView() {
    return store.viewType === VIEW_TYPE_ENUM.MONTH;
  },

  get monthText() {
    if (store.batchStartDay) {
      const firstDay = store.batchStartDay.clone();
      const lastDay = store.batchStartDay
        .clone()
        .add(store.batchLength - 1, "days");
      if (firstDay.month() === lastDay.month()) {
        return store.batchStartDay.format("MMMM YYYY");
      } else if (firstDay.year() !== lastDay.year()) {
        return `${firstDay.format("MMM YYYY")} - ${lastDay.format("MMM YYYY")}`;
      }
      return `${firstDay.format("MMM")} - ${lastDay.format("MMM YYYY")}`;
    }
    return "";
  },

  get AllShiftlocalStoreData() {
    return store.allShiftCardOptions.filter(filterArr)
  },

  get UserShiftCardViews() {
    const defaultView = { id: moment().unix(), name: 'Default View', data: store.defaultShiftCardOptions };
    const userShiftCardViews = localStorage.getItem('userShiftCardViews');
    if (userShiftCardViews) {
      if (userShiftCardViews !== "") {
        let userShiftCardViewsData: any = JSON.parse(userShiftCardViews);
        if (userShiftCardViewsData.length > 0) {
          return userShiftCardViewsData;
        } else {
          localStorage.setItem('userShiftCardViews', JSON.stringify([defaultView]));
          return [defaultView];
        }
      } else {
        localStorage.setItem('userShiftCardViews', JSON.stringify([defaultView]));
        return [defaultView];
      }
    } else {
      localStorage.setItem('userShiftCardViews', JSON.stringify([defaultView]));
      //store.setCurrentShiftCardViewId(defaultView.id);
      return [defaultView];
    }
  },

  setCurrentShiftCardViewFields: action((viewData: any) => (store.currentShiftCardView = viewData)),
  removeUserShiftCardView: action((id: number) => {
    const userShiftCardViews = localStorage.getItem('userShiftCardViews');
    if (userShiftCardViews) {
      if (userShiftCardViews != "") {
        let userShiftCardViewsData: any = JSON.parse(userShiftCardViews);
        if (userShiftCardViewsData.length > 0) {
          const activeIndex = userShiftCardViewsData.findIndex((data: any) => data.id === id)
          userShiftCardViewsData.splice(activeIndex, 1);
          localStorage.setItem('userShiftCardViews', JSON.stringify(userShiftCardViewsData));
        }
      }

    }
  }),
  setUserShiftCardView: action((selectedItems: any, viewName: any, id: number) => {
    const userShiftCardViews = localStorage.getItem('userShiftCardViews');
    const currentItem: any = { id: moment().unix(), name: viewName, data: selectedItems };
    if (userShiftCardViews) {
      if (userShiftCardViews != "") {
        let userShiftCardViewsData: any = JSON.parse(userShiftCardViews);
        if (userShiftCardViewsData.length > 0) {
          if (id == 0) {
            userShiftCardViewsData.push(currentItem)
            localStorage.setItem('userShiftCardViews', JSON.stringify(userShiftCardViewsData));
          } else {
            for (var i = 0; i < userShiftCardViewsData.length; i++) {
              if (userShiftCardViewsData[i].id == id) {
                userShiftCardViewsData[i].name = viewName;
                userShiftCardViewsData[i].data = selectedItems;
                break;
              }
            }
            localStorage.setItem('userShiftCardViews', JSON.stringify(userShiftCardViewsData));
          }

        } else {
          localStorage.setItem('userShiftCardViews', JSON.stringify([currentItem]));
        }


      } else {
        localStorage.setItem('userShiftCardViews', JSON.stringify([currentItem]));
      }
    } else {
      localStorage.setItem('userShiftCardViews', JSON.stringify([currentItem]));
    }
  }),
  shiftCardType: string,
  setShiftCardType: action((str: string) => (store.shiftCardType = str)),
  shiftCardTargetID: string,
  setShiftCardTargetID: action((str: string) => (store.shiftCardTargetID = str)),
  crews: [],
  setCrews: action((crews: Crew) => (store.crews = crews)),
  storeDepot: null,
  setStoreDepot: action((depot: any) => (store.storeDepot = depot)),
  storeCardCrews: [],
  setStoreCardCrews: action((crews: any) => {
    localStorage.setItem('storeCrews', JSON.stringify(crews));
  }),
  drss: [],
  allCardsOptions: [
    'Shuttle Buggy',
    'Transport Details',
    'Compaction Testing / Asphalt Test',
    'Default PM',
    'Supervisor',
    'Mix Type (DRS)',
    'Sealing Requirements',
    'APEX Dispatch Order / Sales Order',
    'TC Comments',
    'Type of Seal',
    'Spray Binder Type',
    'Area (m2)',
    'Application rate ltsm2',
    'Residual Binder (L)',
    'Aggregate Size',
    'Ordered Rate (m3/m2)',
    'Aggregate Tonnes',
    'Labour Accommodation'
  ],
  allShiftViewsOptions: [
    'Project Number',
    'Customer',
    'Street',
    'Suburb',
    'Mix Type & Tonnes',
    'Shuttle Buggy',
    'Transport Details',
    'Compaction Testing / Asphalt Test',
    'Default PM',
    'Supervisor',
    'Mix Type (DRS)',
    'Sealing Requirements',
    'APEX Dispatch Order / Sales Order',
    'TC Comments',
    'Type of Seal',
    'Spray Binder Type',
    'Area (m2)',
    'Application rate ltsm2',
    'Residual Binder (L)',
    'Aggregate Size',
    'Ordered Rate (m3/m2)',
    'Aggregate Tonnes',
    'Labour Accommodation'
  ],
  defaultViewOptions: defaultViewOptions,
  allShiftCardOptions: [

    { value: 'Shuttle Buggy', label: 'Shuttle Buggy' },
    { value: 'Transport Details', label: 'Transport Details' },
    { value: 'Compaction Testing / Asphalt Test', label: 'Compaction Testing / Asphalt Test' },
    { value: 'Default PM', label: 'Default PM' },
    { value: 'Supervisor', label: 'Supervisor' },
    { value: 'Mix Type (DRS)', label: 'Mix Type (DRS)' },
    { value: 'Sealing Requirements', label: 'Sealing Requirements' },
    { value: 'APEX Dispatch Order / Sales Order', label: 'APEX Dispatch Order / Sales Order' },
    { value: 'TC Comments', label: 'TC Comments' }


  ],
  defaultShiftCardOptions: [{ value: 'Project Number', label: 'Project Number' },
  { value: 'Customer', label: 'Customer' },
  { value: 'Street', label: 'Street' },
  { value: 'Suburb', label: 'Suburb' },
  { value: 'Mix Type & Tonnes', label: 'Mix Type & Tonnes' }],
  crewNotes: [],
  leaveNotes: [],
  currentShiftCardViewId: null,
  setCurrentShiftCardViewId: action((id: number) => {
    store.currentShiftCardViewId = id;
    localStorage.setItem('currentShiftCardViewId', id.toString());
  }),



  setDrss: action((drss: any) => (store.drss = drss)),
  setDefaultShiftCardOptions: action((fields: any) => (store.defaultShiftCardOptions = fields)),
  setAllShiftCardOptions: action((fields: any) => (store.allShiftCardOptions = fields)),
  setAllShiftViewsOptions: action((fields: any) => (store.allShiftViewsOptions = fields)),
  setCrewNotes: action((notes: any) => (store.crewNotes = notes)),
  setLeaveNotes: action((notes: any) => (store.leaveNotes = notes)),
  get currentShiftCardViewLocalId() {

    let currentShiftCardViewIdData: number = store.currentShiftCardViewId;
    if (currentShiftCardViewIdData) {


    } else {
      let id: any = localStorage.getItem('currentShiftCardViewId');
      if (id) {
        if (typeof id != undefined && typeof id == 'string') {
          currentShiftCardViewIdData = parseInt(id);
        }
      }
    }

    return currentShiftCardViewIdData;

  },
  get DefaultShiftlocalStoreData() {
    let defaultData: any = localStorage.getItem('userViewShiftCardData');
    let currentShiftCardViewIdData: number = store.currentShiftCardViewId;
    if (currentShiftCardViewIdData) {

      return store.currentShiftCardView;

    } else {

      return [];
    }




  },
  get storeCrewData() {
    let crewData = store.crews;
    let crewStoreData = localStorage.getItem('storeCrews');
    if (crewStoreData) {
      let crewStoreDataObj = JSON.parse(crewStoreData);
      if (crewStoreDataObj) {
        if (crewStoreDataObj.depotID == store.storeDepot) {
          
          if (store.crews.length == crewStoreDataObj.cardCrews.length) {
            crewData = crewStoreDataObj.cardCrews;
          } else {
            // console.log(`store.crews`);
            // console.log(store.crews);
            // console.log(`store.crews`);
           crewData = handleCrewsCache(crewData,crewStoreDataObj.cardCrews);
          }

        }
      }
    }
    return crewData;
  },
  get shiftsData() {

    let crewData = store.storeCrewData;


    let result = crewData.map((crew: Crew) => {
      const { name, colourCode, id } = crew;
      let newDrss: any[] = [];
      for (let i = 0; i < store.batchLength; i++) {

        newDrss.push([]);
      }
      newDrss = newDrss.map(() => []);

      //for (var j = 0; j < newDrss.length; j++) {
      //   newDrss[j]['sortOrder'] = j;
      // if (typeof store.crewNotes[crew.id] !== "undefined") {
      //   store.crewNotes[crew.id].forEach((notes: any) => {
      //     const { Date } = notes;
      //     const dayIndexNote = moment(Date)
      //       .clone()
      //       .startOf("day")
      //       .diff(store.batchStartDay.clone().startOf("day"), "days");
      //     if (dayIndexNote < store.batchLength && j >= 0 && j == dayIndexNote) {
      //       if (newDrss[j]) {

      //         newDrss[j].push(notes);
      //       }

      //     }
      //   });
      // }

      // if (typeof store.leaveNotes[crew.id] !== "undefined") {
      //   console.log(JSON.stringify(store.leaveNotes[crew.id]));
      //   store.leaveNotes[crew.id].forEach((notes: any) => {
      //     const { LeaveDate } = notes;
      //     const dayIndexNoteLeave = moment(LeaveDate)
      //       .clone()
      //       .startOf("day")
      //       .diff(store.batchStartDay.clone().startOf("day"), "days");
      //     if (dayIndexNoteLeave < store.batchLength && dayIndexNoteLeave >= 0 && j == dayIndexNoteLeave) {
      //       if (newDrss[j]) {

      //         newDrss[j].push(notes);
      //       }

      //     }
      //   });
      // }
      //   }


      return {
        name,
        colourCode: `#${colourCode}`,
        id,
        drss: newDrss,
      };
    });
    store.crewNotes.forEach((cNote: any) => {
      const found = result.find((crew: Crew) => crew.id == cNote.CrewID);
      let cDate = cNote.Date.split('T');
      // const crewDateRecord  = moment(cDate[0]).clone().startOf("day").tz('Australia/Sydney');
      // const batchStardDateRecord  = moment(store.batchStartDay).clone().startOf("day").tz('Australia/Sydney');
      // console.log(`crewDateRecord-------`);

      // console.log(crewDateRecord);
      // console.log(batchStardDateRecord);
      // console.log(`crewDateRecord-------`);
      const dayIndex = moment(cDate[0])
        .clone()
        .startOf("day")
        .diff(store.batchStartDay.clone().startOf("day"), "days");
      // var cDateTemp = moment(moment(cDate[0]).tz('Australia/Sydney').format('YYYY-MM-DD'));
      // var batchStartDayTemp = moment(moment(store.batchStartDay).tz("Australia/Sydney").format('YYYY-MM-DD'));
      if (found) {
        if (dayIndex < store.batchLength && dayIndex >= 0) {
          found.drss[dayIndex].push(cNote);
        }
      }
    })
    store.leaveNotes.forEach((lvNote: any) => {
      const found = result.find((crew: Crew) => crew.id == lvNote.CrewID);
      let lvDate = lvNote.LeaveDate.split('T');
      // const leaveRecordDate  = moment(lvDate[0]).clone().startOf("day").tz('Australia/Sydney');
      // const batchStardDateRecord  = moment(store.batchStartDay).clone().startOf("day").tz('Australia/Sydney');
      // var LeaveDateTemp = moment(moment(lvDate[0]).tz('Australia/Sydney').format('YYYY-MM-DD'));
      // var batchStartDayTemp = moment(moment(store.batchStartDay).tz('Australia/Sydney').format('YYYY-MM-DD'));
      // console.log(LeaveDateTemp);
      // console.log(batchStartDayTemp);
      const dayIndex = moment(lvDate[0])
        .clone()
        .startOf("day")
        .diff(store.batchStartDay.clone().startOf("day"), "days");
      if (found) {
        if (dayIndex < store.batchLength && dayIndex >= 0) {
          found.drss[dayIndex].push(lvNote);
        }
      }
    })
    // console.log('resu', store.drss)
    store.drss.forEach((drs: Shift) => {
      const { id, date } = drs;
      const crew = drs.plan.crew;
      const dayIndex = moment(date)
        .clone()
        .startOf("day")
        .diff(store.batchStartDay.clone().startOf("day"), "days");
      const { id: crewId } = crew;
      const found = result.find((crew: Crew) => crew.id === crewId);

      if (found) {
        // console.log('dayIndex', dayIndex, store.batchLength)
        if (dayIndex < store.batchLength && dayIndex >= 0) {

          found.drss[dayIndex].push(drs);
        }



      } else {
        // console.log(crewId, date)
      }
    });

    return result;
  },

  moveShift: action(
    ({ drsId, targetDate }: { drsId: number; targetDate: Moment }) => {
      store.drss.find(
        (drs: Shift) => drs.id === drsId
      ).shiftDate = targetDate.format("YYYY-MM-DD");
    }
  ),
  createShift: action(
    ({
      crewId,
      crewIndex,
      date, //: startDate.format('YYYY-MM-DD'),
      projectId, //: selectedItem.value,
      drsId, //: 22,
      shiftType,
    }: any) => {
      store.drss.push({
        id: drsId,
        shiftDate: date,
        projectID: projectId,
        crew: {
          id: crewId,
        },
        shiftType: {
          id: shiftType,
          name: shiftType,
        },
      });
    }
  ),
  startCrewIndex: -1,
  endCrewIndex: -1,
  selectedStart: -1,
  selectedEnd: -1,
  dragStartDate: '',
  setDragStartDate: action((date: string) => {
    //console.log(`useCrewCalender setDragStartDate() date=${date}`);
    store.dragStartDate = date;
  }),
  updateSelectedStartBox: action((index: number, crewIndex: number) => {
    //console.log(`useCrewCalender updateSelectedStartBox() index=${index}`);
    store.selectedStart = index;
    store.startCrewIndex = crewIndex;
    store.endCrewIndex = crewIndex;
  }),
  updateSelectedStartNote: action((index: number) => {
    //console.log(`useCrewCalender updateSelectedStartBox() index=${index}`);
    store.selectedStart = index;

  }),
  updateSelectedEndBox: action((index: number, crewIndex: number) => {
    //console.log(`useCrewCalender updateSelectedEndBox() index=${index}`);
    store.selectedEnd = index;
    store.endCrewIndex = crewIndex;
  }),

  updateSelectedEndNote: action((index: number) => {
    //console.log(`useCrewCalender updateSelectedEndBox() index=${index}`);
    store.selectedEnd = index;

  }),
  crewData: [],
  addSelectedShiftCards: action((selected: any) => {
    let found = store.selectedShiftCardViews.find((o: any) => o.id === selected.id);
    if (!found) {
      store.selectedShiftCardViews.push(selected)
      // sortSelecetdShift()
      // findShiftCrewsCantBeChanged()
      store.selectedShiftCardViewsCount++
    }
  }),
  clearSelectedShiftCards: action(() => {
    store.selectedShiftCardViews = []
    store.selectedShiftCardViewsCount = 0

  }),
});

const context = React.createContext(store);

export const useCrewCalender = () => {
  return useContext(context);
};