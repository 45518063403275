import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSReworkSummary } from '@src/types/drs/rework/Rework'

const DRSReworkSummaryFields: FormTableField<DRSReworkSummary>[] = [
  {
    key: 'reworkPercentage',
    type: FormTableFieldType.Number,
    label: '% of Rework',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'reworkQuantity',
    type: FormTableFieldType.Number,
    label: 'Rework Tonnes/Litres',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'reworkLabourHours',
    type: FormTableFieldType.Number,
    label: 'Hours - Labour',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'reworkEmulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion (L)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'depot',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot',
    format: (item: any) => {
      if (item === null) {
        return ''
      }
      return item.name
    }
  }
]

export default DRSReworkSummaryFields