export enum ShiftTypeId {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}

export function formatShiftTypeId(id: ShiftTypeId): string {
  return {
    'DAY': 'Day',
    'NIGHT': 'Night',
  }[id]
}