import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useTabs, getTabsByType } from '@src/components/Tabs/useTabs'
import { ProjectTypeId } from '@src/types/ProjectTypeId'
import { Tab } from '@src/components/Tabs/Tabs'
import { useSRRInDRS } from './useSRRInDRS'
import CompPillTabs from '@src/components/Tabs/PillTabs'
import CompUpdateButton from './comps/UpdateButton'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { AntTabs, AntTab } from './comps/PreviewTabs'
import { useDRS } from '../../useDRS'
import { DRSSectionName } from '../../DRSPage'
import CompPreviewJob from './parts/srr-summary/Job'
import CompPreviewRework from './parts/srr-summary/Rework'
import CompPreviewMaterialAsphalt from './parts/srr-summary/MaterialAsphalt'
import CompPreviewMaterialAsphaltOther from './parts/srr-summary/MaterialAsphaltOther'
import CompPreviewMaterialSpraySeal from './parts/srr-summary/MaterialSpraySeal'
import CompPreviewMaterialSpraySealOther from './parts/srr-summary/MaterialSpraySealOther'
import CompPreviewTransportDeliveryProfiling from './parts/srr-summary/TransportDeliveryProfiling'
import CompPreviewTransportAdditionalInfo from './parts/srr-summary/TransportAdditionalInfo'
import CompPreviewMobileAssets from './parts/srr-summary/MobileAssets'
import CompPreviewQA from './parts/srr-summary/QA'
import AsphaltTab from './parts/booked-assets/TabAsphalt'
import SpraySealTab from './parts/booked-assets/TabSpraySeal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { MaterialSpraySealProduct } from '@src/types/MaterialSpraySealProduct'

interface Props {
  activeSection: DRSSectionName, 
}
const DRSPreview = (props: Props) => {
  const { 
    activeSection,
  } = props
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh, drsId } = useDRS()
  const { confirm } = useConfirm()
  const { alertDone, alertError } = useSnackbar()
  const [ asssActiveIndex, asssTabs, handleAsssTabChange, setAsssTabsFunc ] = useTabs([])
  const [ activeIndex, tabs, handleTabChange ] = useTabs([
    {
      key: 'bookedAssets',
      label: 'Booked Assets',
    },
    {
      key: 'srrSummary',
      label: 'SRR Summary',
    },
  ])
  const [ srr,, fetchSRR ] = useSRRInDRS()
  useEffect(() => {
    if (drsId) {
      fetchSRR(drsId)
    }
  }, [drsId])
  useEffect(() => {
    if (drs !== null && drs.jobDetails.drsProjectType.id) {
      setAsssTabsFunc(getTabsByType(drs.jobDetails.drsProjectType.id))
    }
  }, [drs])

  if (drs === null) {
    return null
  }
  const asssTabsNeeded = (activeIndex === 0) || [DRSSectionName.Rework, DRSSectionName.Material, DRSSectionName.Quality].includes(activeSection)

  const updateButtonNeeded = (activeIndex === 1) && [DRSSectionName.Job, DRSSectionName.Material].includes(activeSection)

  const handleUpdate = async () => {
    if (srr === null) {
      return
    }
    const asphalt = asssTabs[asssActiveIndex].key === ProjectTypeId.Asphalt
    if (activeSection === DRSSectionName.Job) {
      try {
        await confirm({
          title: `Are you sure you want to update Daily Reporting Sheet With values from Shift Resources Requirement?`,
          message: `Updating will replace all existing values in the DRS with values from the SRR`,
          confirmButtonText: `Yes, update my DRS`,
        })
        updateJob()
      } catch(e) {}
    } else if (activeSection === DRSSectionName.Material) {
      if (asphalt) {
        try {
          await confirm({
            title: `Are you sure you want to update Daily Reporting Sheet With values from Shift Resources Requirement?`,
            message: `Updating will replace all existing values in the DRS with values from the SRR`,
            confirmButtonText: `Yes, update my DRS`,
          })
          updateMaterialAsphaltProducts()
        } catch(e) {}
      } else {
        try {
          await confirm({
            title: `Are you sure you want to update Daily Reporting Sheet With values from Shift Resources Requirement?`,
            message: `Updating will replace all existing values in the DRS with values from the SRR`,
            confirmButtonText: `Yes, update my DRS`,
          })
          updateMaterialSpraySealProducts()
        } catch(e) {}
      }
    }
  }
  async function updateJob() {
    if (drs === null || srr === null) {
      return
    }
    const retained = drs.jobDetails
    try {
      const result = await updateSectionViaPath({
        sectionName: DRSSectionName.Job,
        path: `plan`,
        data: {
          ...retained.plan,
          streetName: srr.siteInformation.siteDetails.street,
          nearestCrossStreet: srr.siteInformation.siteDetails.nearestCrossStreet,
          suburb: srr.siteInformation.siteDetails.suburb,
        },
      })
      refresh()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  async function updateMaterialAsphaltProducts() {
    if (drs === null || srr === null) {
      return
    }
    if (!srr.material.asphalt.products.length) {
      return
    }
    const retained = drs.material
    try {
      const result = await updateSectionViaPath({
        sectionName: DRSSectionName.Material,
        path: `asphalt`,
        data: {
          summary: retained.asphalt.summary || {},
          detail: srr.material.asphalt.products.map((asphaltProduct: MaterialAsphaltProduct) => {
            return {
              plan: {
                asphaltLayer: idNullObject(asphaltProduct.asphaltLayer),
                supplyPlant: idNullObject(asphaltProduct.plant),
                product: idNullObject(asphaltProduct.product),
                tonnes: asphaltProduct.tonnes,
                customerSpecifiedMix: asphaltProduct.customerSpecificMix,
                depth: asphaltProduct.depth,
                lotNumber: asphaltProduct.lotNumber,
                controlLine: asphaltProduct.controlLine,
                area: asphaltProduct.area,
              }
            }
          })
        },
      })
      refresh()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  async function updateMaterialSpraySealProducts() {
    if (drs === null || srr === null) {
      return
    }
    if (!srr.material.spraySeal.products.length) {
      return
    }
    const retained = drs.material
    try {
      const result = await updateSectionViaPath({
        sectionName: DRSSectionName.Material,
        path: `spraySeal`,
        data: {
          summary: retained.spraySeal.summary || {},
          detail: srr.material.spraySeal.products.map((spraySealProduct: MaterialSpraySealProduct) => {
            return {
              plan: {
                spraySealType: idNullObject(spraySealProduct.spraySealType),
                binderType: idNullObject(spraySealProduct.sprayBinderType),
                depot: idNullObject(spraySealProduct.depot),
                area: spraySealProduct.area,
                binderMixture: spraySealProduct.binderMixture,
                orderedApplicationRate: spraySealProduct.orderedApplicationRate,
                residualBinder: spraySealProduct.residualBinder,
                cutter: spraySealProduct.cutter,
                adhesionAgent: spraySealProduct.adhesionAgent,
                aggregateSize: idNullObject(spraySealProduct.aggregateSize),
                aggregateRequired_M3: spraySealProduct.aggregateRequired_M3,
                aggregateRequired_Tonnes: spraySealProduct.aggregateRequired_Tonnes,
                aggregateInStockpile: spraySealProduct.aggregateStockpile,//
                orderedRate: spraySealProduct.orderedRate,
                otherSealType: spraySealProduct.otherSealType,
                existingSurfaceCondition: spraySealProduct.existingSurfaceConditions,
                comments: spraySealProduct.comment,
              }
            }
          })
        },
      })
      refresh()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const renderContent = () => {
    if (!asssTabs.length) {
      return null
    }
    const asphalt = asssTabs[asssActiveIndex].key === ProjectTypeId.Asphalt
    if (activeIndex === 0) {
      if (asphalt) {
        return <AsphaltTab />
      } else {
        return <SpraySealTab />
      }
    } else {
      if (activeSection === DRSSectionName.Job) {
        return <CompPreviewJob />
      } else if (activeSection === DRSSectionName.Rework) {
        if (asphalt) {
          return <CompPreviewRework jobType={ProjectTypeId.Asphalt}/>
        } else {
          return <CompPreviewRework jobType={ProjectTypeId.SpraySeal}/>
        } 
      } else if (activeSection === DRSSectionName.Material) {
        if (asphalt) {
          return <React.Fragment>
            <CompPreviewMaterialAsphalt />
            <CompPreviewMaterialAsphaltOther />
          </React.Fragment>
        } else {
          return <React.Fragment>
            <CompPreviewMaterialSpraySeal />
            <CompPreviewMaterialSpraySealOther />
          </React.Fragment>
        }
      } else if (activeSection === DRSSectionName.Transport) {
        return <React.Fragment>
          <CompPreviewTransportDeliveryProfiling />
          <CompPreviewTransportAdditionalInfo />
        </React.Fragment>
      } else if (activeSection === DRSSectionName.MobileAssets) {
        return <CompPreviewMobileAssets />
      } else if (activeSection === DRSSectionName.Labour) {
        return 'No Information available for labour section in SRR'
      } else if (activeSection === DRSSectionName.Subcontractors) {
        return 'No Information available for subcontractors section in SRR'
      } else if (activeSection === DRSSectionName.Quality) {
        if (asphalt) {
          return <CompPreviewQA jobType={ProjectTypeId.Asphalt}/>
        } else {
          return <CompPreviewQA jobType={ProjectTypeId.SpraySeal}/>
        } 
      } else if (activeSection === DRSSectionName.Summary) {
        // return <CompPreviewSummary />
      }  
      return null
    }
  }
  return <Wrapper>
    <AntTabs value={activeIndex} onChange={(e, index) => {
      handleTabChange(tabs[index], index)
    }}>
      { tabs.map((tab: Tab) => {
        return <AntTab key={tab.key} label={tab.label} />
      })}
    </AntTabs>
    <TabPanelWrapper>
      <Padding24>
        { asssTabsNeeded && 
          <MarginBottom16>
            <CompPillTabs 
              tabs={asssTabs}
              activeIndex={asssActiveIndex}
              onChange={handleAsssTabChange}
            />
          </MarginBottom16>
        }
        { renderContent() }
      </Padding24>
      { updateButtonNeeded &&
        <PositionForButton>
          <CompUpdateButton onClick={handleUpdate}/>
        </PositionForButton>
      }
    </TabPanelWrapper>
  </Wrapper>
}

const TabPanelWrapper = styled.div`
  position: relative;
  background-color: #E4F4F2;
`

const Wrapper = styled.div`
  position: sticky;
  top: 16px;
`
const Padding24 = styled.div`
  padding: 16px 24px;
`
const MarginBottom16 = styled.div`
  margin-bottom: 16px;
`
const PositionForButton = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;
`
function idNullObject (obj: null | object) {
  if (obj === null) {
    return {
      id: null
    }
  }
  return obj
}
export default observer(DRSPreview)