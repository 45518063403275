import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

export const AntTabs = withStyles({
  root: {
    border: '1px solid #E3E0E0',
  },
  indicator: {
    backgroundColor: '#009688',
  },
})(Tabs)

export const AntTab: any = withStyles(theme => ({
  root: {
    minWidth: '150px',
    height: '40px',
    lineHeight: '40px',
    textTransform: 'none',
    // minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    '&:hover': {
      color: 'green',
      opacity: 1,
    },
    '&$selected': {
      color: '#009688',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#009688',
    },
  },
  selected: {},
} as any))(props => <Tab {...props} />)