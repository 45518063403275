import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import Container from '@material-ui/core/Container'
import Breadcrumbs from '@src/components/Breadcrumbs'
import CompMobileAssetDetailInfo from './parts/AssetDetail'
import { useTabs } from '@src/components/Tabs/useTabs'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import TabsWrapper from '../SiteResoureRequirements/comps/TabsWrapper'
import CompMobileAssetPrestartTable from './parts/PrestartTable'
import { useMobileAssetPrestartsByAssetId } from '@src/utils/hooks/useMobileAssetPrestartsByAssetId'
import { useMobileAsset } from '@src/utils/hooks/useMobileAsset'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { apiMobileAssetPrestartsByAssetIssues } from '@src/apis/mobile-asset-prestarts-by-asset-issue'
import { useAppStore } from '@src/AppStore'
import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import MapIssuesDialog from './MapIssuesDialog'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import CompMobileAssetPaperPrestartTable from './parts/PaperPrestartTable'
import { useMobileAssetPaperPrestartsByAssetId } from '@src/utils/hooks/useMobileAssetPaperPrestartsByAssetId'
interface Props {

}

const MobileAssetDetailPage = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{ id: string }>()
  const { serialId } = useAppStore()
  const [filter, setFilter] = useState<number>(2)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [issueDialog, setIssueDialog] = useState<any>(false)
  const [isFirstLoad, setFirstLoad] = useState<any>(false)
  const [issueData, setIssueData] = useState<any>([])
  const [issueAssetID, setIssueAssetID] = useState<any>('')
  const [mobileAssetPrestarts] = useMobileAssetPrestartsByAssetId(id, filter)
  const [mobileAssetPaperPrestarts] = useMobileAssetPaperPrestartsByAssetId(id, filter)
  
  const { alertDone, alertError } = useSnackbar()
  useEffect(() => {
   // setIsLoading(!isLoading)
    setTimeout(()=>{
      handleListIssues(false);
      setFirstLoad(true);
     },3000)
   
  });
  const [mobileAsset] = useMobileAsset(id)
  const [tabIndex, tabs, handleTabChange] = useTabs([
    {
      key: 'prestarts',
      label: 'Asset Pre-starts',
    },
    {
      key: 'paperprestarts',
      label: 'Paper Pre-starts',
    },
  ])
 
  const handleListIssues = async  (isFromAlert: any) => {
    try {
    if(mobileAsset) {
      if(isFirstLoad == false || isFromAlert) {
        const result = await apiMobileAssetPrestartsByAssetIssues({
          assetId: mobileAsset.id,
          serialId
        })
        const  checkListData: any = result.data.checklist;
        if(checkListData.length > 0) {
          const checkedIssues = [];  
          for(var i  = 0; i< checkListData.length;i++) {
            let issue: any = checkListData[i];
             if(issue.answer!=true)
                checkedIssues.push(issue);
          }
          setIssueData(checkedIssues);
        }
        // console.log(`result`);
        //   console.log(result);
        // console.log(`result`);
       
      }
    }
    } catch (e) {
      console.log(e)
    }
  }
 
  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    setIsLoading(true)
    setFilter(Number(event.target.value))
  }
 
  const renderParts = () => {
    const currentTab: Tab = tabs[tabIndex]
    switch (currentTab.key) {
      case 'prestarts': {

        if (mobileAssetPrestarts.length && mobileAsset != null)
          return <React.Fragment>
            <CompMobileAssetPrestartTable mobileAssetPrestarts={mobileAssetPrestarts} assets={mobileAsset}   />
          </React.Fragment>
        else
          return <Typography align={'center'}>No Asset pre-starts found.</Typography>
      }
      case 'paperprestarts': {

        if (mobileAssetPaperPrestarts.length && mobileAsset != null)
          return <React.Fragment>
            <CompMobileAssetPaperPrestartTable mobileAssetPrestarts={mobileAssetPaperPrestarts} assets={mobileAsset}   />
          </React.Fragment>
        else
          return <Typography align={'center'}>No Paper pre-starts found.</Typography>
      }
    }
  }
  if (mobileAsset === null) {
    return null
  }
  const { description } = mobileAsset
  return <Wrapper>
    <TopPart maxWidth='xl'>
      <Breadcrumbs items={[
        { type: 'link' as const, url: `/mgmt/mobile-asset`, text: `Mobile Asset Management` },
        { type: 'text' as const, text: `${id} ${description}`, active: true, }
      ]} />
      {/* large chunk of info on top */}
      <TopElementIssues>
      {issueData.length > 0 &&   <h3>Current Faults</h3>}
      <TopElementIssuesMain>
            {issueData.map((issues: any,index: number)=>{
              return (issues.answer!=true) ? <TopElementIssuesChild key={'issue_'+index}>{issues.questionText}</TopElementIssuesChild> : null;
            })}
       </TopElementIssuesMain>
      {issueData.length > 0 && <CompThemeButton onClick={()=>{
        setIssueDialog(true);
      }}>Resolve</CompThemeButton>}
      </TopElementIssues>
      <CompMobileAssetDetailInfo mobileAsset={mobileAsset} issueData={issueData}  />
    </TopPart>
    <Container maxWidth='xl'>
      <TabsWrapper>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TabsComp
              tabs={tabs}
              activeIndex={tabIndex}
              onChange={handleTabChange}
            />
          </Grid>
          {tabIndex == 0 && <Grid item xs={2}>
            <Select
              labelId=""
              id=""
              value={filter}
              onChange={handleChange}
            >
              <MenuItem value={0}>Pre-start completed</MenuItem>
              <MenuItem value={1}>Pre-start not done</MenuItem>
              <MenuItem value={2}>All</MenuItem>
            </Select>
          </Grid> }
        </Grid>
      </TabsWrapper>
      {isLoading ? <Wrapper><Spinner size={40} /></Wrapper> : renderParts()}
    </Container>
    {issueDialog && (
        <MapIssuesDialog
          open={issueDialog}
          data={issueData}
         
         
          onClose={() => {
            setIssueDialog(false);
            handleListIssues(true);
            alertDone();
           
          }}
          onSave={() => {
           
          }}
        />
      )}
  </Wrapper>
}

const Wrapper = styled.div`
`
const TopElementIssues = styled.div`
float:right;
clear:both;
display:inline-block;
margin-top:5px;

`
const TopElementIssuesChild = styled.div`

`
const TopElementIssuesMain = styled.div`
  text-align:justify;
  height:82px;
  overflow:hidden;
`
const TopPart = styled(Container)`
  position: relative;
  background-color: #E7F4F2;
  color: #707070;
  padding-bottom: 12px;
`

const Spinner = styled(CircularProgress)`
  position: absolute;
  top: 60%;
  left: 50%;
  margin-top: 30px;
  margin-left: -12px;
`

export default MobileAssetDetailPage
