import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  shiftId: number,
  dates: string[],
  serialId: number,
}

export const apiShiftDuplicate = ({
  shiftId,
  dates,
  serialId,
}: Options) => {
  const data = {
    dates,
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/shifts/${shiftId}`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}