import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useAppStore } from "@src/AppStore";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import { capWord } from "@src/utils/capWord";
import { useConfirm } from "@src/components/GlobalConfirmDialog";
import { apiLabourDepotUpdate } from "@src/apis/labour-depot-update";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { apiDepots } from "@src/apis/depots";
import { ADRegionDepot } from "@src/api-adaptors/regionDepot";
import useButtonStyles from "@src/utils/hooks/useButtonStyles";
import { CONST_SYSTEM_ACCESS_ROLE_OPTIONS } from "@src/constants/roles-enum";
import { Settings } from "@material-ui/icons";
import UserViewDialog from "./UserViewDialog";

import { apiUserViewsLists } from "@src/apis/user-view-list";
const ProfilePopover = ({
  anchorEl,
  open,
  onClose,
  
}: {
  anchorEl: any;
  open: boolean;
  onClose(): void;
}) => {
  const {
    serialId,
    firstName,
    defaultRoleId,
    lastName,
    depotId,
    depotName,
    regionName,
    stateId,
    setDepot,
    setRegion,
    setState,
    goRefreshAppStore,
    setUserSettingsOpen,
  } = useAppStore();
  const { alert } = useSnackbar();
  const { confirm } = useConfirm();
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [menuOptions, setMenuOptions] = useState<any>([]);
  const classes: any = useButtonStyles();
  const [userViewDialog, setUserViewDialog] = useState<boolean>(false);

  const [userViews, setUserViews] = useState<any>([]);
  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleViewClick = async () => {
    const userViewsData: any = await apiUserViewsLists({
      userViewSerialId: serialId,
    });
    if (userViewsData) {
      setUserViews(userViewsData.data.shiftcardviews);
    }
    setUserViewDialog(true);
    setUserSettingsOpen(true);
  };

  const handleClose = () => {
    setAnchorElMenu(null);
  };
 
  useEffect(() => {
    const fetchDepots = async () => {
      const result: any = await apiDepots({});
      return result.data.depots
        .map((depot: any) => ADRegionDepot(depot))
        .map((regionDepot: any) => {
          return { label: regionDepot.label, value: regionDepot.value };
        });
    };

    fetchDepots().then((options: any) => {
      setMenuOptions(options);
    });
  
 
  }, []);

  function roleName() {
    for (var i = 0; i < CONST_SYSTEM_ACCESS_ROLE_OPTIONS.length; i++) {
      if (CONST_SYSTEM_ACCESS_ROLE_OPTIONS[i].value == defaultRoleId) {
        return CONST_SYSTEM_ACCESS_ROLE_OPTIONS[i].label;
      }
    }
  }
  const handleMenuSelect = (event: any) => {
    const regionDepot: AIMS.ValueLabel2 =
      menuOptions[parseInt(event.target.value)];

    const { label, value } = regionDepot;
    setAnchorElMenu(null);

    if (depotId !== value.depotId) {
      try {
        confirm({
          title: "Confirm",
          message: `Are you sure you want to change your Depot to ${label} ?`,
        }).then(() => {
          console.log(`Change`);
          onClose();
          const data: any = {
            serialId,
            depotId: value.depotId,
          };
          console.log(`ProfilePopover data=${JSON.stringify(data, null, 2)}`);
          apiLabourDepotUpdate(data).then((result) => {
            console.log(`ChangeD`);
            setState({
              id: value.stateId,
              name: value.stateName,
            });
            setRegion({
              id: value.regionId,
              name: value.regionName,
            });
            setDepot({
              id: value.depotId,
              name: value.depotName || "",
            });

            goRefreshAppStore();
            if (result.data) {
              alert({
                message: `Depot was updated to ${label}`,
              });
            }
          });
        });
      } catch (e) {}
    }
  };

  return (
    <Popover
      id="profile-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <StyledWrapper>
        <BasicProfile>
          <Grid container spacing={2}>
            <Grid item xs={1} className={classes.centerColumn}>
              <IconButton
                aria-label="Account of current user in menu"
                color="inherit"
                size="small"
              >
                <AccountCircle />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <BoldTypography variant="body1">
                {" "}
                {`${capWord(firstName)} ${capWord(lastName)}`}
              </BoldTypography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.mediumButton}
                size={"small"}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleEditClick}
              >
                <EditIcon className={classes.mediumIcon} />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleClose}
              >
                {menuOptions.map((option: AIMS.ValueLabel, index: number) => {
                  const { value, label } = option;
                  return (
                    <MenuItem
                      key={index}
                      value={index}
                      onClick={handleMenuSelect}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">{`${depotName} Depot`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{`${regionName} Region, ${stateId}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{`${roleName()}`}</Typography>
            </Grid>

            <Grid item xs={1}>
              <IconButton
                className={classes.mediumButton}
                size={"small"}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleViewClick}
              >
                <Settings className={classes.mediumIcon} />
              </IconButton>
            </Grid>
            <Grid item xs={11} onClick={handleViewClick} id={'user-settings-btn'}>
              <Typography variant="body1">{`User Settings`}</Typography>
            </Grid>
          </Grid>
        </BasicProfile>
      </StyledWrapper>

      {userViewDialog && (
        <UserViewDialog
          open={userViewDialog}
          myViews={userViews}
          onClose={() => setUserViewDialog(false)}
          onSave={() => {
            setUserViewDialog(false);
          }}
        />
      )}
    </Popover>
  );
};

const StyledWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 350px;
`;

const BoldTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
` as typeof Typography;

const BasicProfile = styled.div`
  padding: 4px 16px 0;
`;

export default observer(ProfilePopover);
