import { useEffect, useState } from 'react'
import { SRR } from '@src/types/SRR'
import { useAppStore } from '@src/AppStore'
import { useSRRDetailStore } from '@src/modules/SiteResoureRequirements/SRRDetailStore'
import { apiSrrGetOneByDRSId } from '@src/apis/srr-get-one-by-drsId'

interface Options {
  // srrId: number,
}

export function useSRRInDRS(): [ SRR | null, boolean, typeof fetch] {
  const { serialId } = useAppStore()
  const { srr, setSrr } = useSRRDetailStore()
  const [ loading, setLoading ] = useState<boolean>(false)
  const fetch = async (drsId: number) => {
    setLoading(true)
    try {
      const result = await apiSrrGetOneByDRSId({
        drsId: drsId,
        serialId,
      })
      // console.log(result)
      setSrr(result.data.srr)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return [ srr, loading, fetch ]
}

// const [ srr, srrLoading, refresh ] = useSRR()