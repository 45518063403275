import React, { useState, useEffect, SyntheticEvent } from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Profile } from "@src/types/common/Profile";
import LabourAutocomplete from "@src/components/autocompletes/LabourAutocomplete";
import ProfileChip from "@src/components/Profile/Chip";
import { AutocompleteOnChangeItem } from "@src/types/AutocompleteOnChangeItem";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import { useAppStore } from "@src/AppStore";
import { apiUserShiftCardViewCreate } from "@src/apis/user-view-create";
interface Props {
  viewData: any;
  open: boolean;
  onClose(): void;

  onSuccess?(viewDataSaved?: any): void;
}

const ShareViewDialogue = (props: Props) => {
  const { viewData, open, onClose, onSuccess = () => {} } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { serialId } = useAppStore();
  const [collaborators, setCollaborators] = useState<Profile[]>([]);
  const { alert } = useSnackbar();
  const handleOk = async () => {
    if (collaborators.length == 0) {
      alert({
        message: "Select at least one user from the list",

        type: "error",
      });
      return false;
    }
    try {
      setLoading(true);

      for (var i = 0; i < collaborators.length; i++) {
        await apiUserShiftCardViewCreate({
          userSearialId: collaborators[i].serialId,
          viewName: viewData.viewName,
          isDefault: false,
          selectedItems: viewData.selectedItems,
          sharedUserSearialId: serialId.toString(),
        });
      }
      setLoading(false);
      onSuccess();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleCollaboratorRemove = (index: number) => () => {
    setCollaborators([
      ...collaborators.slice(0, index),
      ...collaborators.slice(index + 1),
    ]);
  };
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={onClose}>
      <DialogTitle id="share-view-dialogue">Share View</DialogTitle>
      <DialogContent>
        <Section>
          <Grid container>
            <Grid item xs={12}>
              <LabourAutocomplete
                fullWidth={true}
                label={`Sharable User`}
                selectedItem={{ value: "", label: "" }}
                onChange={(item: AutocompleteOnChangeItem<Profile>) => {
                  if (item.raw.serialId != serialId) {
                    setCollaborators([...collaborators, item.raw]);
                  } else {
                    alert({
                      message: `You can't choose yourself`,
                      type: "error",
                    });
                    return false;
                  }
                  ///  console.log(item.raw.serialId);
                }}
              />
              <CollaboratorsWrapper>
                {collaborators.map((profile: Profile, index: number) => {
                  return (
                    <ProfileChip
                      key={profile.serialId}
                      profile={profile}
                      removable
                      onRemove={handleCollaboratorRemove(index)}
                    />
                  );
                })}
              </CollaboratorsWrapper>
            </Grid>
          </Grid>
        </Section>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleOk}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Section = styled.div`
  margin-bottom: 40px;
`;
const GridInner = styled.div`
  padding-right: 16px;
`;
const CollaboratorsWrapper = styled.div`
  display: flex;
  margin-top: 6px;
`;

export default ShareViewDialogue;
