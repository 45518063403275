import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { CONST_ROLES_LABOUR, CONST_ROLES_WO_ADMIN } from '@src/constants/roles-enum'

const store: any = observable({
  active: true,
  setActive: action((active: boolean | null) => store.active = active),
  roleIds: CONST_ROLES_WO_ADMIN,
  setRoleIds: action((roleIds: string[] | null) => store.roleIds = roleIds),
  depot: {
    value: '',
    label: '',
  },

  depotPayroll: {
    value: '',
    label: '',
  },
  setDepot: action((val: any) => store.depot = val),
  setDepotPayroll: action((val: any) => store.depotPayroll = val),
  labourSortOrder: 'LastName',
  setLabourSortOrder: action((val: string) => store.labourSortOrder = val),
  labourAscDesc: 'ASC',
  setLabourAscDesc: action((sort: string, ascDesc: string) => {
    store.labourSortOrder = sort;
    store.labourAscDesc = ascDesc;
  }),
  search: '',
  setSearch: action((val: string) => store.search = val),
})

const context = React.createContext(store)

export const useRSMgmtStore = () => {
  return useContext(context)
}