import React, { useState, useEffect, SyntheticEvent } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import { useCrewCalender } from "../StoreCrewCalender";
import { useDrop, DropTargetMonitor } from "react-dnd";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DRSItem from "./DRSItem";
import { CrewNote, Shift } from "@src/types/Shift";
import usePermission from "@src/utils/hooks/usePermission";
import useHover from "../../../../utils/hooks/useHover";
import { DragItemTypes } from "@src/types/DragItemTypes";
import ShiftOptionsDialog from "./ShiftOptionsDialog";
import ShiftDialog from "./ShiftDialog";
import CompCrewNoteDialog from "./CrewNoteDialog";
import CrewNoteItem from "./CrewNoteItem";
import LeaveNoteItem from "./LeaveNoteItem";
import { LeaveNote } from "@src/types/LeaveNote";
import CompLeaveNoteDialog from "./LeaveNoteDialog";
import { useSocketClient } from "@src/utils/hooks/StoreSocketClient";

import { apiShiftSortOrder } from "@src/apis/shift-sort-order";
import { useAppStore } from "@src/AppStore";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import { stringify } from "qs";
export interface SyntheticFunction {
  (e: SyntheticEvent): void;
}

interface DragItem {
  type: string;
}

const DRSBox = ({
  drs,
  crewId,
  crewName,
  crewIndex,
  batchIndex,
  onDRSBoxClick,
  onDRSItemClick,
  onDuplicateDrsOpt,
  duplicateData,
  opt,
  shiftCollapsed,
  hideCrew,
  crewIDsToggle
}: {
  drs: any[];
  crewId: number;
  crewName: string;
  crewIndex: number;
  batchIndex: number;
  shiftCollapsed: boolean;
  onDRSBoxClick(e: SyntheticEvent): void;
  onDRSItemClick(data: Shift): SyntheticFunction;
  onDuplicateDrsOpt(opt: any, obj: any): void;
  duplicateData: any;
  opt: boolean;
  hideCrew: boolean;
  crewIDsToggle: any
}) => {
  const { serialId } = useAppStore();
  const {
    socketRefreshHook,
    setShiftIds,
    setWatch,
    setListenBy,
    doConnectWebSocket,
    sendData,
  } = useSocketClient();
  const {
    gridWidth,
    updateSelectedEndBox,
    crews,
    storeCrewData,
    currentBatch,
    goRefresh,
    isCalendarControlsDisabled,
  } = useCrewCalender();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [shiftingOrder, setShiftingOrder] = useState<boolean>(false);
  
  const [shiftOrder, setShiftOrder] = useState<string>("back");
  const [isShiftOptionsDialogOpen, setShiftOptionsDialogOpen] =
    useState<boolean>(false);
  const [isShiftDialogOpen, setShiftDialogOpen] = useState<boolean>(false);
  const [shiftDialogData, setShiftDialogData] = useState<null | Shift>(null);
  const { canCreateShift, canEditShift } = usePermission();
  const [isCrewDialogOpen, setCrewDialogOpen] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [crewNoteData, setCrewNoteData] = useState<null | CrewNote>(null);
  const [isLeaveNoteDialogOpen, setLeaveNoteDialogOpen] =
    useState<boolean>(false);
  // const [isMultiShift, setIsMultiShift] = useState<boolean>(false);
  const [leaveNoteData, setLeaveNoteData] = useState<null | LeaveNote>(null);
  const [duplicateDrsSections, setDuplicateDrsSections] = useState<[]>([]);
  const [drsData, setDrsData] = useState<any>([]);
  const [drsDataReverse, setDrsDataReverse] = useState<any>([]);
  const { alertDone, alertError, alert } = useSnackbar();
  const [showStack, setShowStack] = useState<boolean>(false);
  useEffect(() => {
    // console.log(`drs`);
    // console.log(drs);
    // console.log(`drs`); 
    setDrsData(multiSort(drs, { SortOrder: "asc" }));
    const drsAny = drs.reverse();
    setDrsDataReverse(drsAny)
    setCurrentIndex(0);
  }, [drs]);

  const [boxRef, hovered] = useHover();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragItemTypes.DRSItem,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      console.log(`DRSBox useDrop() hover`);
    },
    collect: (monitor) => ({
      isOver: !isCalendarControlsDisabled && !!monitor.isOver(),
      canDrop: !isCalendarControlsDisabled,
    }),
    drop: () => {
      updateSelectedEndBox(batchIndex, crewIndex);
    },
  });
  drop(boxRef);

  const handleCreateShift = (e: React.SyntheticEvent<any>) => {
    if (!canCreateShift) {
      return;
    }

    if (boxRef && boxRef.current && boxRef.current === e.target) {
      setShiftDialogData(null);
      setShiftOptionsDialogOpen(true);
    }
  };
  const handleFabCreateShift = (e: React.SyntheticEvent<any>) => {
    if (!canCreateShift) {
      return;
    }
    setShiftDialogData(null);
    setShiftOptionsDialogOpen(true);
  };
  const handleEditShift = (shift: Shift) => {
    if (!canEditShift) {
      return;
    }
    setShiftDialogData(shift);
    setShiftDialogOpen(true);
  };
  const handleShiftClick = (shift: Shift) => () => {
   // console.log(`DRSBox handleShiftClick() canEditShift=${canEditShift}`);
    if (opt) {
      // console.log('do-selection')
      const shiftElement: any = document.getElementById("shift_" + shift.id);
      const shiftElementWrapper: any = document.getElementById("shiftblockshift_" + shift.id);
     //  shiftElement.parentElement
      //  console.log(shiftElementWrapper.classList.value);
      const sourceElementClassList = shiftElementWrapper.classList.value;
      if (sourceElementClassList.indexOf("sourceShiftClass") == -1)
        shiftElement.classList.toggle("activeShift");
    } else {
      setShiftDialogData(shift);
      //setShiftOptionsDialogOpen(true)
      setShiftDialogOpen(true);
    }
  };
  const handleCrewNoteClick = (crewNote: any) => () => {
    console.log(`DRSBox handleCrewNoteClick() `);
    setCrewDialogOpen(true);
    setCrewNoteData(crewNote);
  };
  const handleLeaveNoteClick = (note: any) => () => {
    console.log(`DRSBox handleLeaveNoteClick() `);
    setLeaveNoteDialogOpen(true);
    setLeaveNoteData(note);
  };
  const handleDuplicateDrsData = (data: any) => {
    console.log(`DRSBox handleDuplicateDrs() `);
    console.log(data);
    setDuplicateDrsSections(data.drsSection);
    if (data.drsSection.length > 0) {
      onDuplicateDrsOpt(true, data);
    } else {
      onDuplicateDrsOpt(false, data);
    }
  };
  function moveCard(dragIndex: number, hoverIndex: number) {
    const dragItem = drsDataReverse[dragIndex];
   
    if (dragItem &&  typeof dragItem.CrewCalendarNotesID == "undefined" &&
    typeof dragItem.LeaveCalendarNotesID == "undefined") {
       setDrsDataReverse((prevState: any) => {
        const coppiedStateArray = [...prevState];
        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        return coppiedStateArray;
      });
      const dbData = drsDataReverse;
      const prevItem = dbData.splice(hoverIndex, 1, dragItem);
      // remove item by "dragIndex" and put "prevItem" instead
      dbData.splice(dragIndex, 1, prevItem[0]);
      let dragOrder: number = drsDataReverse[hoverIndex].SortOrder;
      let hoverOrder: number = drsDataReverse[dragIndex].SortOrder;
      if(dragOrder == hoverOrder) {
        hoverOrder = dragIndex;
        dragOrder = hoverIndex;
      }
      callShiftOrder(
        [{id: drsDataReverse[dragIndex].id,SortOrder: dragOrder},
        {id: drsDataReverse[hoverIndex].id,SortOrder: hoverOrder}]
        );
    }
  }
  function callShiftOrder(dBData: any ) {
  
    if(!shiftingOrder) {
      setShiftingOrder(true);
      apiShiftSortOrder({
        data: dBData,
        serialId,
      }).then((response: any) => {
        setShiftingOrder(false);
        console.log(response);
        goRefresh();
        alertDone();
      });
    }
  
  
  }
  function multiSort(array: any, sortObject: any = {}) {
    const sortKeys = Object.keys(sortObject);

    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }

    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === "desc" || sortObject[key] === -1
          ? -1
          : sortObject[key] === "skip" || sortObject[key] === 0
            ? 0
            : 1;
    }

    const keySort = (a: any, b: any, direction: any) => {
      direction = direction !== null ? direction : 1;

      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }
     // console.log(a, b);
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };

    return array.sort((a: any, b: any) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];

        if (key) {
          const direction = sortObject[key];
          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }

      return sorted;
    });
  }
  function ShiftOrderHandle(type: any, shift: Shift) {
    if (
      (type == "plus" && drs.indexOf(shift) == 0) ||
      (type == "minus" && drs.indexOf(shift) == drs.length - 1)
    ) {
      //move forward
      //console.log('noting to do al.. good');
      alert({
        type: "error",
        message: "Card already at starting point",
      });
      return;
      return false;
    }
    let dataUpdate: any = [];
    if ((type == "plus" && shift.SortOrder != 0) || drs.indexOf(shift) != 0) {
      const currentOrder: any =
        shift.SortOrder == 0 && drs.indexOf(shift) == 0
          ? 0
          : drs.indexOf(shift);
      const newOrder: any = currentOrder - 1;
      dataUpdate = [
        { id: shift.id, SortOrder: newOrder },
        { id: drs[drs.indexOf(shift) - 1].id, SortOrder: currentOrder },
      ];
    }
    if (type == "minus" && shift.SortOrder != drs.length - 1) {
      const currentOrder: any = shift.SortOrder;
      const newOrder: any = currentOrder + 1;
      dataUpdate = [
        { id: shift.id, SortOrder: newOrder },
        { id: drs[drs.indexOf(shift) + 1].id, SortOrder: currentOrder },
      ];
    }
    apiShiftSortOrder({
      data: dataUpdate,
      serialId,
    }).then((response: any) => {
      console.log(response);
      goRefresh();
      alertDone();
    });
  }

  const renderShiftOrLeaveData = (currentShift: any, shiftIndx: number) => {
    const total = drs.length;

    if (
      typeof currentShift.LeaveCalendarNotesID != "undefined" &&
      typeof currentShift.CrewCalendarNotesID == "undefined"
    )
      return (
        <LeaveNoteItem className="animate__animated animate__fadeInDown animate__slow"
          data={currentShift}
          key={"LeaveNoteItem" + shiftIndx}
          currentIndex={shiftIndx}
          batchIndex={batchIndex}
          crewIndex={crewIndex}
          total={total}
          inDragLayer={false}
          onClick={handleLeaveNoteClick(currentShift)}
          onPrevious={() =>
            setCurrentIndex(
              shiftIndx - 1 === -1 ? total - 1 : shiftIndx - 1
            )
          }
          onNext={() =>
            setCurrentIndex(shiftIndx + 1 === total ? 0 : shiftIndx + 1)
          }
        />
      );
    if (
      typeof currentShift.CrewCalendarNotesID != "undefined" &&
      typeof currentShift.LeaveCalendarNotesID == "undefined"
    )
      return (
        <CrewNoteItem
        key={"CrewNoteItem" + shiftIndx}
          className="animate__animated animate__fadeInDown animate__slow"
          data={currentShift}
          currentIndex={shiftIndx}
          batchIndex={batchIndex}
          crewIndex={crewIndex}
          total={total}
          inDragLayer={false}
          onClick={handleCrewNoteClick(currentShift)}
          onPrevious={() =>
            setCurrentIndex(
              shiftIndx - 1 === -1 ? total - 1 : shiftIndx - 1
            )
          }
          onNext={() =>
            setCurrentIndex(shiftIndx + 1 === total ? 0 : shiftIndx + 1)
          }
        />
      );
    if (
      typeof currentShift.CrewCalendarNotesID == "undefined" &&
      typeof currentShift.LeaveCalendarNotesID == "undefined"
    ) {
      return <DRSItem
       key={"shift_" + currentShift.id}
        className="animate__animated animate__fadeInDown animate__slow"
        data={currentShift}
        id={"shift_" + currentShift.id}
       
        drsOpt={opt}
        isMultiShift={!shiftCollapsed || !hideCrew ? false : true}
        currentIndex={shiftIndx}
        batchIndex={batchIndex}
        isDuplicateOperation={duplicateData
          ? duplicateData.drsSection
            ? duplicateData.drsSection.length > 0
              ? duplicateDrsSections.length > 0 &&
              currentShift.id == duplicateData.shift.id
                ? "sourceShiftClass"
                : opt
                  ? "onbackcolor"
                  : ""
              : ""
            : ""
          : ""}
        crewIndex={crewIndex}
        total={total}
        inDragLayer={false}
        onClick={handleShiftClick(currentShift)}
        moveCard={moveCard}
        onPrevious={() =>
          setCurrentIndex(
            shiftIndx - 1 === -1 ? total - 1 : shiftIndx - 1
          )
        }
        onToggleShift={(isMulti: boolean) => {
          //  setIsMultiShift(isMulti);
          // console.log(isMulti);
        }}
        onNext={() =>
          setCurrentIndex(shiftIndx + 1 === total ? 0 : shiftIndx + 1)
        }
        onEdit={handleEditShift}

        onCardOrder={(type) => ShiftOrderHandle(type, currentShift)}
        onDuplicateDrs={(copyData) => handleDuplicateDrsData(copyData)}
      />
    } else {


    }
  }
  const renderOneShift = () => {
    if (currentIndex >= drs.length || currentIndex < 0) {
      return null;
    }
    const shift: any = drs[currentIndex];
    const total = drs.length;
    return (!shiftCollapsed || !hideCrew) ?
        <MultiDrsBox className="animate__animated animate__slideInDown">
        {drsDataReverse.map((shiftMulti: any, shiftIndx: number) => {
          return renderShiftOrLeaveData(shiftMulti, shiftIndx);
        })}
      </MultiDrsBox> : renderShiftOrLeaveData(shift, currentIndex);

  }
  function getShiftID() {
    const shift: any = drs[currentIndex];

    return shift ? shift.id : "";
  }

  return (
    <Wrapper
      className={
        duplicateData
          ? duplicateData.drsSection
            ? duplicateData.drsSection.length > 0
              ? duplicateDrsSections.length > 0 &&
                getShiftID() == duplicateData.shift.id
                ? "div-block-with-all-shifts-in-one-day-for-some-crew sourceShiftClassParent"
                : opt
                  ? "onbackcolor"
                  : ""
              : "div-block-with-all-shifts-in-one-day-for-some-crew"
            : "div-block-with-all-shifts-in-one-day-for-some-crew"
          : "div-block-with-all-shifts-in-one-day-for-some-crew"
      }
      ref={boxRef}
      gridWidth={gridWidth}
      onClick={handleCreateShift}
      isOver={isOver}
      id={"shift_wrapper" + getShiftID()}
    //style={{background: duplicateData ? (duplicateData.drsSection ?  (duplicateData.drsSection.length > 0 ?  ((duplicateDrsSections.length > 0) ? 'grey' : (opt ? 'rgb(226, 230, 232)' : '')) : '') : '') : ''}}
    >
      {renderOneShift()}

      {
        <SFab
          isHovered={drs.length !== 0 && hovered}
          color="primary"
          aria-label="create-shift"
          onClick={handleFabCreateShift}
          size="small"
        >
          <AddIcon />
        </SFab>
      }
      {isShiftOptionsDialogOpen && (
        <ShiftOptionsDialog
          setShiftOptionsDialogOpen={setShiftOptionsDialogOpen}
          open={isShiftOptionsDialogOpen}
          data={shiftDialogData}
          crewOptions={storeCrewData}
          originCrew={storeCrewData[crewIndex]}
          originDate={currentBatch[batchIndex]}
          onClose={() => {
            // console.log(1111);
            setShiftOptionsDialogOpen(false);
          }}
          onSave={() => {
            goRefresh();
            setShiftOptionsDialogOpen(false);
          }}
        />
      )}
      {isShiftDialogOpen && (
        <ShiftDialog
          open={isShiftDialogOpen}
          data={shiftDialogData}
          crewOptions={storeCrewData}
          originCrew={storeCrewData[crewIndex]}
          originDate={currentBatch[batchIndex]}
          onClose={() => {
            console.log(2222);
            setShiftDialogOpen(false);
            goRefresh();
          }}
          onSave={() => {
            setShiftDialogOpen(false);
            goRefresh();
          }}
        />
      )}
      {isCrewDialogOpen && (
        <CompCrewNoteDialog
          open={isCrewDialogOpen}
          data={crewNoteData}
          originCrew={storeCrewData[crewIndex]}
          originDate={currentBatch[batchIndex]}
          onClose={() => {
            console.log(3333);
            setCrewDialogOpen(false);
            goRefresh();
          }}
          onSave={() => {
            goRefresh();
            setCrewDialogOpen(false);
          }}
        />
      )}
      {isLeaveNoteDialogOpen && (
        <CompLeaveNoteDialog
          open={isLeaveNoteDialogOpen}
          data={leaveNoteData}
          crewOptions={storeCrewData}
          originCrew={storeCrewData[crewIndex]}
          originDate={currentBatch[batchIndex]}
          onClose={() => {
            console.log(4444);
            setLeaveNoteDialogOpen(false);
            goRefresh();
          }}
          onSave={() => {
            setLeaveNoteDialogOpen(false);
            goRefresh();
          }}
        />
      )}
    </Wrapper>
  );
};

const Box = styled.div<{ gridWidth: number }>`
  flex-grow: 1;
  width: ${(props) => `${props.gridWidth}px`};
  /* height: ${(props) => `${props.gridWidth}px`}; */
  /* height: 200px; */
  /* overflow-y: auto; */
  overflow-y: hidden;
`;
const MultiDrsBox = styled.div`
`;
const Wrapper = styled(Box) <{ isOver: boolean }>`
  position: relative;
  padding: 10px;
  border: 1px solid #e3e0e0;
  cursor: pointer;
  ${(props) => {
    if (props.isOver) {
      return css`
        border: 1px solid #009688;
        background-color: #f5f5f5;
      `;
    }
  }}
`;

const SFab = styled(Fab) <{ isHovered: number }>`
  position: absolute;
  right: 2px;
  bottom: 2px;
  visibility: ${(props) => (props.isHovered ? "visible" : "hidden")};
`;
export default observer(DRSBox);
