import React from 'react'
import styled from 'styled-components'

const LeftNavStickyWrapper = styled.div<{ top: number }>`
  position: relative;
  > div {
    position: sticky;
    top: ${props => props.top}px;
  }
`

export default LeftNavStickyWrapper