import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  sifId: number,
  shiftId: number,
  serialId: number,
}

export const apiSifUninkCrewPack = (options: Options) => {
  const { 
    sifId, 
    shiftId, 
    serialId,
  } = options
  const data = {

  }
  const axOptions = {
    method: 'DELETE',
    url: `${BASE_API_URL}/v2/shifts/${shiftId}`,
    data,
    params: {
      sif: sifId,
      reqSid: serialId,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}