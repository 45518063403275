import React, { useState, SyntheticEvent, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { DrawingElement, DrawingElementType, ArrowElement, RectangleElement, TextBoxElement, DrawingElementProperties, isDrawingElement, isArrowElement, isRectangleElement, isTextBoxElement } from './types/DrawingElement'
import CompElementStore, {  } from './ElementStore'
import CompPropertySettings from './PropertySettings'
import CompDrawingBoard from './DrawingBoard'
import shortid from 'shortid'
import { Point } from './comps/Arrow'
import Alert from '@material-ui/lab/Alert'
import { DrawingMode, formatDrawingMode } from './types/DrawingMode'

const ARROW_MIN_LENGTH = 30

interface Props {
  bgDataURLString: string;
  exportTrigger: number,
  onExport(svgData: string): void,
}

const CompDrawingTool = (props: Props) => {
  const {
    bgDataURLString,
    exportTrigger,
    onExport,
  } = props
  const [ selectedIndex, setSelectedIndex ] = useState<number>(-1)
  // const [ selectedElement , setSelectedElement ] = useState<DrawingElement | null>(null)
  const [ elements, setElements ] = useState<DrawingElement[]>([])
  let selectedElement: null | DrawingElement = null
  if (selectedIndex === -1 || selectedIndex >= elements.length) {
    selectedElement = null
  } else {
    selectedElement = elements[selectedIndex]
  } 
  const [ dragging, setDragging ] = useState(false)
  const [ mode, setMode ] = useState<DrawingMode>(DrawingMode.Idle)
  const [ elementIdMouseOver , setElementIdMouseOver ] = useState<string>('')
  const [ moveStartPoint, setMoveStartPoint ] = useState<null | Point>(null)
  const [ rectFixedPoint, setRectFixedPoint ] = useState<null | Point>(null)
  const [ initialText, setInitialText ] = useState<string>('')
  
  function removeSelectedElement() {
    if (selectedElement !== null) {
      setElements([
        ...elements.slice(0, selectedIndex),
        ...elements.slice(selectedIndex + 1),
      ])
    }
  }
  function updateSelectedElement(element: DrawingElement) {
    setElements([
      ...elements.slice(0, selectedIndex),
      element,
      ...elements.slice(selectedIndex + 1),
    ])
  }
  function updateSelectedElementProps(elementProps: Partial<DrawingElementProperties>) {
    if (isDrawingElement(selectedElement)) {
      updateSelectedElement({
        ...selectedElement,
        properties: {
          ...selectedElement.properties,
          ...elementProps,
        }
      })
    }
  }
  const handleAddRectangle = () => {
    setMode(DrawingMode.AddRectangle)
  }
  const handleAddText = (text: string) => {
    setInitialText(text)
    setMode(DrawingMode.AddTextBox)
    // setSelectedIndex(elements.length)
    // setElements([...elements, {
    //   id: shortid.generate(),
    //   type: DrawingElementType.TextBox,
    //   properties: {
    //     x: getRandomPoint().x,
    //     y: getRandomPoint().y,
    //     width: 30, 
    //     height: 20,
    //     rotation: 0,
    //     text: 'MARSHALLING AREA',
    //   }
    // }])
  }
  const handleChange = (p: keyof DrawingElementProperties, value: any) => {
    if (selectedElement === null) {
      return
    }
    setElements([
      ...elements.slice(0, selectedIndex),
      {
        ...selectedElement,
        properties: {
          ...selectedElement.properties,
          [p]: value,
        }
      },
      ...elements.slice(selectedIndex + 1),
    ])
  }
  
  const handleMouseDown = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point) => {
    setDragging(true)
    if ((e.target as HTMLElement).nodeName === 'svg') {
      // mouse down on empty area
      switch(mode) {
        case DrawingMode.Idle: {
          setSelectedIndex(-1)
          return
        }
        case DrawingMode.AddArrow: {
          setElements([...elements, getInitialArrowElement(p)])
          setSelectedIndex(elements.length)
          return
        }
        case DrawingMode.AddRectangle: {
          setElements([...elements, getInitalRectangleElement(p)])
          setSelectedIndex(elements.length)
          setRectFixedPoint(p)
          return
        }
        case DrawingMode.AddTextBox: {
          setElements([...elements, getInitalTextBoxElement(p, initialText)])
          setSelectedIndex(elements.length)
          setMoveStartPoint(p)
          return
        }
      }
    } else {
      // mousedown on elements, handled by other handlers
    }
    
  }
  const handleMouseMove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point) => {
    // clear hover indicator when not over elements
    if ((e.target as HTMLElement).nodeName === 'svg') {
      setElementIdMouseOver('')
    }
    
    switch(mode) {
      case DrawingMode.Idle: {
        return
      }
      case DrawingMode.AddArrow: {
        if (isArrowElement(selectedElement) && dragging) {
          updateSelectedElementProps({
            toX: p.x,
            toY: p.y,
          })
        }
        return
      }
      case DrawingMode.MoveArrow: {
        if (moveStartPoint !== null && isArrowElement(selectedElement)) {
          const deltaX = p.x - moveStartPoint.x
          const deltaY = p.y - moveStartPoint.y
          setMoveStartPoint(p)
          updateSelectedElementProps({
            fromX: selectedElement.properties.fromX + deltaX,
            fromY: selectedElement.properties.fromY + deltaY,
            toX: selectedElement.properties.toX + deltaX,
            toY: selectedElement.properties.toY + deltaY,
          })
        }
        return
      }
      case DrawingMode.MoveArrowStart: {
        if (isArrowElement(selectedElement)) {
          updateSelectedElementProps({
            fromX: p.x,
            fromY: p.y,
          })
        }
        return
      }
      case DrawingMode.MoveArrowEnd: {
        if (isArrowElement(selectedElement)) {
          updateSelectedElementProps({
            toX: p.x,
            toY: p.y,
          })
        }
        return
      }
      case DrawingMode.AddRectangle: {
        if (isRectangleElement(selectedElement) && dragging && rectFixedPoint !== null) {
          updateSelectedElementProps(getRectFromTo(rectFixedPoint, p))
        }
        return
      }
      case DrawingMode.MoveRectangle: {
        if (moveStartPoint !== null && isRectangleElement(selectedElement)) {
          const deltaX = p.x - moveStartPoint.x
          const deltaY = p.y - moveStartPoint.y
          setMoveStartPoint(p)
          updateSelectedElementProps({
            fromX: selectedElement.properties.fromX + deltaX,
            fromY: selectedElement.properties.fromY + deltaY,
            toX: selectedElement.properties.toX + deltaX,
            toY: selectedElement.properties.toY + deltaY,
          })
        }
        return
      }
      case DrawingMode.MoveRectangleTopLeft: 
      case DrawingMode.MoveRectangleTopRight: 
      case DrawingMode.MoveRectangleBottomLeft: 
      case DrawingMode.MoveRectangleBottomRight: {
        if (isRectangleElement(selectedElement) && rectFixedPoint !== null) {
          updateSelectedElementProps(getRectFromTo(rectFixedPoint, p))
        }
        return
      }
      case DrawingMode.RotateRectangleBottomRight: {
        if (isRectangleElement(selectedElement) && rectFixedPoint !== null) {
          const from = { x: selectedElement.properties.fromX, y: selectedElement.properties.fromY }
          const to = { x: selectedElement.properties.toX, y: selectedElement.properties.toY }
          updateSelectedElementProps({
            rotation: getRotation(p, from, to),
          })
        }
        return
      }
      case DrawingMode.AddTextBox: {
        if (isTextBoxElement(selectedElement) && dragging) {
          updateSelectedElementProps({
            toX: p.x,
            toY: p.y,
          })
        }
        return
      }
      case DrawingMode.MoveTextBox: {
        if (moveStartPoint !== null && isTextBoxElement(selectedElement)) {
          const deltaX = p.x - moveStartPoint.x
          const deltaY = p.y - moveStartPoint.y
          setMoveStartPoint(p)
          updateSelectedElementProps({
            fromX: selectedElement.properties.fromX + deltaX,
            fromY: selectedElement.properties.fromY + deltaY,
            toX: selectedElement.properties.toX + deltaX,
            toY: selectedElement.properties.toY + deltaY,
          })
        }
        return
      }
      case DrawingMode.MoveTextBoxBottomRight: {
        if (isTextBoxElement(selectedElement)) {
          updateSelectedElementProps({
            toX: p.x,
            toY: p.y,
          })
        }
        return
      }
    }
    
  }
  const handleMouseUp = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point) => {
    setDragging(false)
    switch(mode) {
      case DrawingMode.AddArrow: {
        if (!isArrowElement(selectedElement)) {
          return
        }
        const { fromX, fromY, toX, toY } = selectedElement.properties
        
        if (toX === fromX && toY === fromY) {
          // user clicks, make arrow points to where user clicks
          const minX = Math.sqrt(Math.pow(ARROW_MIN_LENGTH, 2) / 2)
          updateSelectedElementProps({
            fromX: fromX - minX,
            fromY: fromY + minX,
            toX: fromX,
            toY: fromY,
          })
        } else if (Math.sqrt(Math.pow(toX - fromX, 2) + Math.pow(toY - fromY, 2)) < ARROW_MIN_LENGTH) {
          // arrow too short, make it longer
          const ratio = Math.sqrt(Math.pow(toX - fromX, 2) + Math.pow(toY - fromY, 2)) / ARROW_MIN_LENGTH
          updateSelectedElementProps({
            toX: ((toX - fromX) / ratio) + fromX,
            toY: ((toY - fromY) / ratio) + fromY,
          })
        }
        return
      }
      case DrawingMode.MoveArrow:
      case DrawingMode.MoveArrowStart:
      case DrawingMode.MoveArrowEnd: {
        // should ensure arrow has min length, small issue
        setMode(DrawingMode.Idle)
        return
      }

      case DrawingMode.AddRectangle: {
        return
      }
      case DrawingMode.AddTextBox: {
        return
      }
      default: {
        setMode(DrawingMode.Idle)
      }
    }
  }
  const handleAddArrow = () => {
    setMode(DrawingMode.AddArrow)
    setSelectedIndex(-1)
  }
  const handleElementMouseOver = (element: DrawingElement) => {
    setElementIdMouseOver(element.id)
  }
  const handleElementClick = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
  }
  const handleElementMouseDown = (element: DrawingElement, p: Point) => {
    if (element.type === DrawingElementType.Arrow) {
      setSelectedIndex(elements.findIndex((item) => item.id === element.id))
      setMoveStartPoint(p)
      setMode(DrawingMode.MoveArrow)
    } else if (element.type === DrawingElementType.Rectangle) {
      setSelectedIndex(elements.findIndex((item) => item.id === element.id))
      setMoveStartPoint(p)
      setMode(DrawingMode.MoveRectangle)
    } else if (element.type === DrawingElementType.TextBox) {
      setSelectedIndex(elements.findIndex((item) => item.id === element.id))
      setMoveStartPoint(p)
      setMode(DrawingMode.MoveTextBox)
    }
  }
  const handleArrowStartMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    setMode(DrawingMode.MoveArrowStart)
  }
  const handleArrowEndMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    setMode(DrawingMode.MoveArrowEnd)
  }
  const handleRectTopLeftMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    // bottom right as fixed point, aka pivot
    setRectFixedPoint({
      x: element.properties.toX,
      y: element.properties.toY,
    })
    setMode(DrawingMode.MoveRectangleTopLeft)
  }
  const handleRectTopRightMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    // bottom left as fixed point, aka pivot
    setRectFixedPoint({
      x: element.properties.fromX,
      y: element.properties.toY,
    })
    setMode(DrawingMode.MoveRectangleTopRight)
  }
  const handleRectBottomLeftMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    // top right as fixed point, aka pivot
    setRectFixedPoint({
      x: element.properties.toX,
      y: element.properties.fromY,
    })
    setMode(DrawingMode.MoveRectangleBottomLeft)
  }
  const handleRectBottomRightMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    // top left as fixed point, aka pivot
    setRectFixedPoint({
      x: element.properties.fromX,
      y: element.properties.fromY,
    })
    setMode(DrawingMode.MoveRectangleBottomRight)
  }
  const handleRectBottomRightRotateMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    // top left as fixed point, aka pivot
    setRectFixedPoint({
      x: (element.properties.toX - element.properties.fromX) / 2,
      y: (element.properties.toY - element.properties.fromY) / 2,
    })
    setMode(DrawingMode.RotateRectangleBottomRight)
  }
  const handleTextBoxRightMouseDown = (element: DrawingElement) => {
    setSelectedIndex(elements.findIndex((item) => item.id === element.id))
    setMode(DrawingMode.MoveTextBoxBottomRight)
  }
  return <Wrapper>
    <MainBoard>
      <BoardWrapper>
        <BgImage>
          <img src={bgDataURLString}/>  
        </BgImage>
        <DrawingLayerWrapper>
          <CompDrawingBoard 
            mode={mode}
            elements={elements}
            exportTrigger={exportTrigger}
            onExport={onExport}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onElementMouseOver={handleElementMouseOver}
            elementIdMouseOver={elementIdMouseOver}
            onElementClick={handleElementClick}
            selectedElement={selectedElement}
            onElementMouseDown={handleElementMouseDown}
            onArrowStartMouseDown={handleArrowStartMouseDown}
            onArrowEndMouseDown={handleArrowEndMouseDown}
            onRectTopLeftMouseDown={handleRectTopLeftMouseDown}
            onRectTopRightMouseDown={handleRectTopRightMouseDown}
            onRectBottomLeftMouseDown={handleRectBottomLeftMouseDown}
            onRectBottomRightMouseDown={handleRectBottomRightMouseDown}
            onRectBottomRightRotateMouseDown={handleRectBottomRightRotateMouseDown}
            onTextBoxBottomRightMouseDown={handleTextBoxRightMouseDown}
          />
        </DrawingLayerWrapper>
      </BoardWrapper>
    </MainBoard>
    <PanelWrapper>
      <SAlert variant='outlined' severity='info'>
        {`Mode: ${formatDrawingMode(mode)}`}
      </SAlert>
      <CompElementStore 
        mode={mode}
        initialText={initialText}
        onAddArrow={handleAddArrow}
        onAddRectangle={handleAddRectangle} 
        onAddText={handleAddText}
      />
      <CompPropertySettings selectedElement={selectedElement} onChange={handleChange} onRemoveSelected={removeSelectedElement}/>
    </PanelWrapper>
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const MainBoard = styled.div`
  width: 710px;
  border: 1px solid #E3E0E0;
`
const PanelWrapper = styled.div`
  width: 336px;
  border: 1px solid #E3E0E0;
  margin-left: 1px;
`
const BoardWrapper = styled.div`
  position: relative;
  min-height: 400px;
`
const BgImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 710px;
  z-index: 0;
  height: 400px;
  // height: 100%;
  img {
    width: 100%;
  }
`
const DrawingLayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 710px;
  z-index: 1;
`
const SAlert = styled(Alert)`
  border: none;
`

export default CompDrawingTool


function getRandomPoint() {
  const x = Math.random() * 500
  const y = Math.random() * 300
  return { x, y }
}

function getInitialArrowElement(p: Point): ArrowElement {
  return {
    id: shortid.generate(),
    type: DrawingElementType.Arrow,
    properties: {
      fromX: p.x,
      fromY: p.y,
      toX: p.x,
      toY: p.y,
    }
  }
}

function getInitalRectangleElement(p: Point): RectangleElement {
  return {
    id: shortid.generate(),
    type: DrawingElementType.Rectangle,
    properties: {
      fromX: p.x,
      fromY: p.y,
      toX: p.x,
      toY: p.y,
    }
  }
}

function getInitalTextBoxElement(p: Point, initialText: string): TextBoxElement {
  return {
    id: shortid.generate(),
    type: DrawingElementType.TextBox,
    properties: {
      fromX: p.x,
      fromY: p.y,
      toX: p.x,
      toY: p.y,
      text: initialText,
    }
  }
}

function getRectFromTo(fixedPoint: Point, p: Point) {
  const { x: x0, y: y0 } = fixedPoint
  const { x, y } = p
  let fromX = Math.min(x0, x)
  let fromY = Math.min(y0, y)
  let toX = Math.max(x0, x)
  let toY = Math.max(y0, y)
  return {
    fromX,
    fromY,
    toX,
    toY,
  }
}

function getRotation(p: Point, from: Point, to: Point): number {
  let result = 0
  const { x, y } = p
  const { x: x0, y: y0 } = from
  const { x: x1, y: y1 } = to
  const angleA = Math.atan2(y - ((y1 + y0) / 2), x - ((x1 + x0) / 2)) 
  const angleA0 = Math.atan2(y1 - y0, x1 - x0)
  const angle = angleA - angleA0
  result = angle * 180 / Math.PI
  return result
}