
import moment from 'moment'
const FORMAT = 'DD/MM/YYYY HH:SS'


export function formatDateStringOrNullDDMMYYYYHHSS(dateString: string | null): string {
  if (dateString === null) {
    return ''
  }
  return moment(dateString).format(FORMAT)
}



