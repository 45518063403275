import { useEffect, useState } from "react";
import { useAppStore } from "@src/AppStore";
import { MobileAssetPrestart } from "@src/types/MobileAssetPrestart";
import { apiMobileAssetPrestartsByAsset } from "@src/apis/mobile-asset-prestarts-by-asset";
import { apiMobileAssetPaperPrestartsByAsset } from "@src/apis/mobile-asset-paper-prestarts-by-asset";

export function useMobileAssetPaperPrestartsByAssetId(
  assetId: string,
  filter: any
): [MobileAssetPrestart[]] {
  const [prestarts, setPrestarts] = useState<MobileAssetPrestart[]>([]);
  const { serialId } = useAppStore();
  let active = false;
  useEffect(() => {
    async function fetchShift() {
      try {
        const result = await apiMobileAssetPaperPrestartsByAsset({
          assetId: assetId,
          serialId,
        });
        if (active) {
          let mobileAssetPrestarts = result.data.maps as MobileAssetPrestart[];

          switch (filter) {
            case 0:
              //console.log(`useMobileAssetPrestartsByAssetId() Pre-start completed filter=0`);
              mobileAssetPrestarts = mobileAssetPrestarts.filter(
                (mobileAssetPrestart) => mobileAssetPrestart.status.id != 1
              );
              break;
            case 1:
              //console.log(`useMobileAssetPrestartsByAssetId() Pre-start not done filter=1`);
              mobileAssetPrestarts = mobileAssetPrestarts.filter(
                (mobileAssetPrestart) => mobileAssetPrestart.status.id === 1
              );
              break;
            case 2:
              //console.log(`useMobileAssetPrestartsByAssetId() All filter=2`);
              break;
          }

          //console.log(mobileAssetPrestarts);
          setPrestarts(mobileAssetPrestarts);
          //setPrestarts(result.data.maps as MobileAssetPrestart[]);
        }
      } catch (e) {}
    }
    if (!active) {
      active = true;
      fetchShift();
    }
    return () => {
      active = false;
    };
  }, [filter]);
  return [prestarts];
}
