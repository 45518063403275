import React from 'react'
import styled from 'styled-components'
import CommonFormFieldLabel from './CommonLabel'
import Textarea from './Textarea'

export interface TextareaFieldProps {
  label: string,
  value: string,
  readonly?: boolean,
}

const TextareaField = (props: TextareaFieldProps) => {
  const { label, value, readonly = false } = props
  return <Wrapper>
    <CommonFormFieldLabel>{ label }</CommonFormFieldLabel>
    <Textarea value={value} readonly={readonly} />
  </Wrapper>
}

const Wrapper = styled.div`
`

export default TextareaField