import React, { useState } from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { TimePicker } from '../../../../../components'
import CalendarToday from '@material-ui/icons/CalendarToday'
import { makeStyles } from '@material-ui/core/styles'
import moment, { Moment } from 'moment'


function FieldEditableTime ({
  field,
  onChange,
}: DRS.Comp) {
  // const classes = useStyles();
  // const [ isOpen, setIsOpen ] = useState<any>(false)
  const handleChange = (date: Moment) => {
    // without seconds or milliseconds.
    const value = moment(date).seconds(0).milliseconds(0).toISOString()
    if (value) {
      onChange(value)
    }
  }
  return <Wrapper>
    <TimePicker
      label={field.name}
      ampm={false}
      // autoOk
      // open={isOpen}
      // onClose={() => setIsOpen(false)}
      value={field.data}
      onChange={handleChange}
      // InputProps={{
      //   // placeholder: 'Date & Day',
      //   label: ' ',
      // }}
      // InputAdornmentProps={{ 
      //   position: "start",
      //   component: () => <StyledIcon onClick={e => setIsOpen(true)}/>,
      // }}
      // variant="inline"
      // format='HH:mm'
      // style={{
      //   width: 'calc(100% - 48px)'
      // }}
      // error
      // helperText="Your error message"
      // ToolbarComponent={() => <Content>sss</Content>}
    />
  </Wrapper>
}

const Wrapper = styled.div`
  padding-top: 8px;
`

const Content = styled.div``

const StyledIcon = styled(CalendarToday)`
  cursor: pointer;
  margin-right: 5px;
`
export default FieldEditableTime