import React from 'react'
import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog'
import Carousel from 'react-material-ui-carousel'
import { Grid, IconButton, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Image } from '@src/types/Image'
import PanViewer from './PanViewer'

const Transition: any = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = (props: any) => {
  return (
    <ImageWrapper>
      <PanViewer image={props.item.uri} alt="" />
    </ImageWrapper>
  )
}

interface Props {
  open: boolean,
  onClose(): void,
  images: Image[],
  imageIndex: number,
}

const CompCarouselDialogSingle = (props: Props) => {
  const {
    open,
    onClose,
    images,
    imageIndex,
  } = props
// console.log(`images`);
// console.log(images);
// console.log(`images`);
  return <Dialog
    TransitionComponent={Transition}
    fullScreen={true}
    open={open}
    onClose={onClose}
  >
    <AppBar position='static' color='default'>
      <Toolbar >
        <Grid container justify="flex-end">
          <IconButton edge="end" color="inherit" onClick={(e: React.SyntheticEvent<any>) => {
            e.stopPropagation()
            onClose()
          }} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
    <Wrapper>
      <Carousel
        autoPlay={false}
        animation={"fade"}
        indicators={false}
        timeout={500}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={false} 
        index={imageIndex}
      >
        {
          images.map((image, index) => {
           // console.log(image);
            return <Item item={image} key={index} />
          })
        }
      </Carousel>
    </Wrapper>
  </Dialog>
}

const Wrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export default CompCarouselDialogSingle