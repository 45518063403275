import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import { MaterialSpraySealProductFields } from '@src/modules/SiteResoureRequirements/parts/4-Material/parts/SsProduct/fields'

interface Props {

}

const CompPreviewMaterialSpraySeal = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  return <Wrapper>
    { srr.material.spraySeal.products
        .map((product, index) => {
          return <CompPreviewSection 
            key={product.id}
            name={`Spray Seal Material ${index + 1}`}
          >
            <CompFlatFields
              value={product}
              fields={MaterialSpraySealProductFields}
            />
          </CompPreviewSection>
        })
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompPreviewMaterialSpraySeal