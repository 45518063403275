const PROJECT_MANAGER = 'PROJECT_MANAGER'
const RESOURCE_SCHEDULER = 'RESOURCE_SCHEDULER'
const ADMINISTRATOR = 'ADMINISTRATOR'
const FOREMAN = 'FOREMAN'
const GENERAL_CREW = 'GENERAL_CREW'
const SUPERVISOR = 'SUPERVISOR'

export const CONST_ROLES_ENUM_VALUES = [PROJECT_MANAGER, RESOURCE_SCHEDULER, ADMINISTRATOR, FOREMAN]
export const CONST_ROLES_SYSTEM_ACCESS = [ADMINISTRATOR, RESOURCE_SCHEDULER, PROJECT_MANAGER]
export const CONST_ROLES_LABOUR = [SUPERVISOR, FOREMAN, GENERAL_CREW]
export const CONST_ROLES_WO_ADMIN = [PROJECT_MANAGER, RESOURCE_SCHEDULER, SUPERVISOR, FOREMAN, GENERAL_CREW]

const ROLES: { [key: string]: any } = {
  PROJECT_MANAGER,
  RESOURCE_SCHEDULER,
  ADMINISTRATOR,
  FOREMAN,
}

export const CONST_ROLES_ENUM = ROLES

export const CONST_CREW_MEMBER_ROLE_OPTIONS: { value: string, label: string }[] = [
  {
    value: 'SUPERVISOR',
    label: 'Supervisor',
  },
  {
    value: 'FOREMAN',
    label: 'Foreman',
  },
  {
    value: 'GENERAL_CREW',
    label: 'General Crew',
  },
]

export const CONST_SYSTEM_ACCESS_ROLE_OPTIONS: { value: string, label: string }[] = [
  {
    value: 'SYSTEM_ADMINISTRATOR',
    label: 'System Administrator',
  },
  {
    value: 'ADMINISTRATOR',
    label: 'Administrator',
  },
  {
    value: 'RESOURCE_SCHEDULER',
    label: 'Resource Scheduler',
  },
  {
    value: 'PROJECT_MANAGER',
    label: 'Project Manager',
  },
  {
    value: 'SUPERVISOR',
    label: 'Supervisor',
  },
  {
    value: 'FOREMAN',
    label: 'Foreman',
  },
]