import axios from 'axios'
import { AUTH_TOKEN_URL, AUTHORIZATION_CODE, CLIENT_ID, DOMAIN, REFRESH_TOKEN_URL } from '../constants'
import qs from 'qs'
import { getCookieRefreshToken } from '../utils/cookies'

export const apiToken = (code: string) => {
  const data = { 
    grant_type: AUTHORIZATION_CODE, 
    client_id: CLIENT_ID, 
    redirect_uri: `${DOMAIN}`, 
    code: code, 
  }
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: AUTH_TOKEN_URL,
  }
  return axios(options as any)
}

export const apiRefreshToken = () => {
  return axios({
    url: REFRESH_TOKEN_URL,
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
    mode: 'cors',
    cache: 'no-cache',
    method: 'POST',
    data: {
      ClientId: CLIENT_ID,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: getCookieRefreshToken(),
      }
    },
  } as any)
}