import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import _unionBy from 'lodash/unionBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useSnackbar } from '@src/components/GlobalSnackbar'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import FormControl from '@material-ui/core/FormControl'
import usePermission from '@src/utils/hooks/usePermission'
import FormLabel from '@material-ui/core/FormLabel'

import moment, { Moment } from 'moment'

import FilterDatePicker from './FilterDatePicker'
import { Grid } from '@material-ui/core'

interface Props {
  open: boolean,
  dates:any,
  onClose(): void,
  onSave(dates: any): void,
  
}

const DateFilterDialog = (props: Props) => {
  const {
    open,
    dates,
    onClose,
    onSave,
    
  } = props


  const { alertDone, alertError, alert } = useSnackbar()


  const [startDate, setStartDate] = useState<Moment>(moment())
  const [endDate, setEndDate] = useState<Moment>(moment())


  const handleFilter = () => {
    if(startDate > endDate) {
      alert({message:"start date cannot be greater than end date",type:'error'});
      return false;
    }
    onSave([startDate,endDate]);
  }
  let validateNotes: any = null;
  useEffect(() => {
    if(dates.length > 0){
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  }, [dates])

  return <Dialog
    fullWidth={false}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>Date Range</ModalTitle>
    </DialogTitle>
    <DialogContent>
      <SectionWrapper>
      <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
          <FormControl fullWidth>
              <FormLabel>Start Date</FormLabel> 
              <FilterDatePicker
                    variant='static'
                    value={startDate}
                    onChange={date => {
                      setStartDate(moment(date))  
                    }}
               />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
          <FormControl fullWidth>
              <FormLabel>End Date</FormLabel>
                <FilterDatePicker
                    variant='static'
                    value={endDate}
                    onChange={date => { 
                      setEndDate(moment(date))  
                    }}
                />
            </FormControl>
          </Grid>
      </Grid>
        
      </SectionWrapper>

  
    </DialogContent>

    <DialogActions>
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
        onClose()
      }}>
        Cancel
      </Button>

      <Button color='primary' variant='contained' onClick={handleFilter} >
        Filter
      </Button>
    </DialogActions>
  </Dialog>
}


const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`


const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const Title = styled.div`
  font-size: 16px;

  margin: 24px 0;
  min-height: 28px;
`

const FormItem = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`
export default DateFilterDialog