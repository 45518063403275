import _map from 'lodash/map'
import _sumBy from 'lodash/sumBy'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _isNumber from 'lodash/isNumber'
import _isNull from 'lodash/isNull'
import _sum from 'lodash/sum'
import _values from 'lodash/values'
import _merge from 'lodash/merge'
import _keys from 'lodash/keys'
import _reject from 'lodash/reject'
import _omitBy from 'lodash/omitBy'
import { LostTimeFields } from '@src/modules/DRS/parts/7-Labour/parts/LostTimeFields'
import { getStartEndDiff, getHoursByLunchTaken } from '@src/modules/DRS/parts/7-Labour/utils/getHours'

const subStringToFind: string = "DT"
const LostTimeFieldNames: string[] = _map(LostTimeFields, 'key')

export const checkLostTime = (drs: any) => {
  const message: string = `Please enter hrs for Lost Time before submitting the DRS`;
  const mergedLostTimeHours: any = _merge({}, _pick(drs.labour.summary.actual, LostTimeFieldNames), _omitBy(_pick(drs.labour.summary.edit, LostTimeFieldNames), _isNull))
  const totalLostTime: number = _sum(_values(mergedLostTimeHours))

  console.log(`checkLostTime() lostTime
  \nactual=${JSON.stringify(_pick(drs.labour.summary.actual, LostTimeFieldNames), null, 2)}
  \nedit=${JSON.stringify(_pick(drs.labour.summary.edit, LostTimeFieldNames), null, 2)}
  \nedit !null=${JSON.stringify(_omitBy(_pick(drs.labour.summary.edit, LostTimeFieldNames), _isNull), null, 2)}
  \nmergedLostTimeHours=${JSON.stringify(mergedLostTimeHours, null, 2)}
  \n`)
  console.log(`checkLostTime() totalLostTime=${totalLostTime}`)
  let result: boolean;
  {
    !drs.jobDetails.project.id.includes(subStringToFind) ? result = false
      : result = totalLostTime < 1
  }

  return { result, message }
}

export const compareWorkedLostTime = (drs: any) => {
  const keyNames: string[] = ['startTime', 'endTime', 'lunchTaken']

  const totalHours: number = _sum(_map(drs.labour.detail, function (o: any, index: number) {
    const mergedActualEditHours: any = _merge({}, _pick(o.actual, keyNames), _omitBy(_pick(o.edit, keyNames), _isNull))
    return getHoursByLunchTaken(getStartEndDiff({
      start: mergedActualEditHours.startTime,
      end: mergedActualEditHours.endTime,
    }), mergedActualEditHours.lunchTaken)
  })) ?? 0;

  const mergedLostTimeHours: any = _merge({}, _pick(drs.labour.summary.actual, LostTimeFieldNames), _omitBy(_pick(drs.labour.summary.edit, LostTimeFieldNames), _isNull))

  console.log(`compareWorkedLostTime() lostTime 
  \nactual=${JSON.stringify(_pick(drs.labour.summary.actual, LostTimeFieldNames), null, 2)}
  \nedit=${JSON.stringify(_pick(drs.labour.summary.edit, LostTimeFieldNames), null, 2)}
  \nedit !null=${JSON.stringify(_omitBy(_pick(drs.labour.summary.edit, LostTimeFieldNames), _isNull), null, 2)}
  \nmergedLostTimeHours=${JSON.stringify(mergedLostTimeHours, null, 2)}
  \n`)


  const totalLostTime: number = _sum(_values(mergedLostTimeHours))
  const message: string = `The DRS can not be approved while Lost Time ${totalLostTime} hrs are higher than Total Hrs Worked ${totalHours} !`

  let result: boolean;
  {  
    if(drs.jobDetails.project.id) {
      !drs.jobDetails.project.id.includes(subStringToFind) ? result = false
      : result = totalLostTime > totalHours
    }else {
      result = false
    }
    
  }

  return { totalHours, totalLostTime, result, message }
}

/*
export const checkLostTime = (drs: any) => {
  const LostTimeObj: any = _pick(drs.labour.summary.edit, LostTimeFieldNames);
  const LostTimeObjNulls: any = _pickBy(LostTimeObj, _isNull);
  const numNullKeys: number = _keys(LostTimeObjNulls).length
  const message: string = `Please enter hrs for Lost Time before submitting DRS`;

  let result: boolean;

  result = true
  {
    !drs.jobDetails.project.id.includes(subStringToFind) ? result = false
      : result = numNullKeys > 0
  }

  return { result, message }
}

const totalHours: number = _sum(_map(drs.labour.detail, function (o: any, index: number) {
    const mergedActualEditHours: any = _merge({}, _pick(o.actual, keyNames), _omitBy(_pick(o.edit, keyNames), _isNull))
    console.log(`\n\n * compareWorkedLostTime() email = ${ JSON.stringify(o.profile.email, null, 2) } `)
    console.log(`compareWorkedLostTime() ${ index } actual = ${ JSON.stringify(_pick(o.actual, keyNames), null, 2) } `)
    console.log(`compareWorkedLostTime() ${ index } edit = ${ JSON.stringify(_pick(o.edit, keyNames), null, 2) } `)
    console.log(`compareWorkedLostTime() ${ index } edit not null = ${ JSON.stringify(_omitBy(_pick(o.edit, keyNames), _isNull), null, 2) } `)
    console.log(`compareWorkedLostTime() ${ index } mergedActualEditHours = ${ JSON.stringify(mergedActualEditHours, null, 2) } `)
    return getHoursByLunchTaken(getStartEndDiff({
      start: mergedActualEditHours.startTime,
      end: mergedActualEditHours.endTime,
    }), mergedActualEditHours.lunchTaken)
  })) ?? 0;
*/