import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


interface Options {
  CrewID: number,
  LeaveType: number,
  Active: boolean,
  LeaveDate: string,
  ModifiedBy: string,
  CreatedBy: string
}
export const apiLeavetNoteCreate = ({
  LeaveType,
  CrewID,
  LeaveDate,
  
  CreatedBy,
  Active,
  ModifiedBy,

}: Options) => {
  const data = {
    LeaveType,
    CrewID,
    LeaveDate,
    CreatedBy,
    Active,
    ModifiedBy,
  }
  console.log(`LeaveDate----`);
  console.log(LeaveDate);
  console.log(`LeaveDate----`);
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/leaveNote`,
    data
  }
  return ax(options)
}