import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiMobileAssetSearch } from '../../apis/mobile-asset-search'

const AssetAutocomplete = ({
  id = '',
  label = '',
  depotId,
  stateId,
  onChange,
  selectedItem,
  active,
  fullWidth = false,
}: {
  depotId?: string,
  stateId?: string,
  id?: string,
  label?: string,
  onChange(value: AIMS.ValueLabel): void,
  selectedItem: AIMS.ValueLabel,
  active?: boolean,
  fullWidth?: boolean,
}) => {
  const fetch = async (query: string) => {
    try {
      const result: any = await apiMobileAssetSearch({ 
        query,
        depotId,
        stateId,
        active,
      })
      return result.data.mobileAssets
        .slice(0, 5)
        .map((asset: AIMS.MobileAsset) => {
          return {
            ...asset,
            value: asset.id,
            label: asset.id,
            raw: asset,
          }
        })
    } catch(e) {
      return []
    }
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label || 'Mobile Asset Number'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default AssetAutocomplete