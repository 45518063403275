import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'


interface Props {
  type?: 'text' | 'number',
  label: string,
  value: string | null,
  disabled?: boolean,
}

const FormModalTextFieldReadOnly = (props: Props) => {
  const {
    type = 'text',
    label,
    value,
    disabled = false,
  } = props
  // console.log(typeof value, value)
  return <Wrapper
    fullWidth
    type={type}
    label={label}
    value={value}
    inputProps={
      { readOnly: true, }
    }
    disabled={disabled}
  >

  </Wrapper>
}

const Wrapper = styled(Textfield)`

`

export default FormModalTextFieldReadOnly