import React, { useState, useContext, useRef, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Logo from './parts/Logo'
import Center from './parts/Center'
import Right from './parts/Right'
import { ProgressBar } from '../../components'
import { useCrewCalender } from '../../modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender'
const Header = ({

}) => {
  const { fullScreen,setFullScreen } = useCrewCalender()
  return <AppBar position='static' color='default' id="appBar"> 
    <SToolbar>
      <Logo />
      <Center />
      <Right />
      <ProgressBar />
    </SToolbar>
  </AppBar>
}

const SToolbar = styled(Toolbar)`
  position: relative;
  min-height: 96px;
  width: "100%"
` as typeof Toolbar

export default Header