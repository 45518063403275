
import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'

interface Options {
  serialId: number,
  id: number,
  roleId: string
}

export const apiRemoveFileATP = ({
  roleId,
  serialId,
  id
}: Options) => {

  const data = {  
      id,
     roleId,
    serialId
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/documents/atp_pdf_files/${id}`,
    data,
    
    params: {
      reqRole: roleId,
      reqSid: serialId,
      reqDev: 'web',
      active: false,
      id
    }
  }
  return ax(options)
}