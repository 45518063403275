import { ax } from '@src/utils/axios'

interface Options {
  url: string,
  fields: any
}
export const apiUploadFileS3 = ({
 
  fields,
  url
}: Options) => {
  let image: any = null;
  const data = new FormData()
  data.append('key', fields.key)
  data.append('bucket', fields.bucket)
  data.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
  data.append('X-Amz-Credential', fields['X-Amz-Credential'])
  data.append('X-Amz-Date', fields['X-Amz-Date'])
  data.append('X-Amz-Security-Token', fields['X-Amz-Security-Token'])
  data.append('Policy', fields.Policy)
  data.append('X-Amz-Signature', fields['X-Amz-Signature'])
  data.append('file', fields.file)
  const options = {
    method: 'post',
    url,
    headers:{ 'enctype': 'multipart/form-data'},
   
    data
  }
  ;
//  return fetch(url, {
//     method: 'PUT',
//     data,
//     headers:{ 'Content-Type': 'application/pdf'}
//   })
  return ax(options)
  // return axios.put(url, {body: file}, {
  //   headers: {
  //     'Content-Type': 'application/pdf'
  //   }
  // });
}