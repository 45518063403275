import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DefaultRoleIcon from '@material-ui/icons/VerifiedUserRounded'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {
  data: AIMS.Profile[],
  onRemove(membersAfterRemoved: AIMS.Profile[]):void,
}
const CrewMgmtMemberTable  = (props: Props) => {
  const { 
    data,
    onRemove = () => {},
  } = props
  const { canEditCrewInMgmt } = usePermission()
  const { confirm } = useConfirm()
  if (!data) {
    return null
  }
  const handleRemove = (row: AIMS.Profile, rowIndex: number) => {
    onRemove([
      ...data.slice(0, rowIndex),
      ...data.slice(rowIndex + 1),
    ])
  }
  const beforeRemove = (row: AIMS.Profile, rowIndex: number) => async () => {
    try {
      await confirm({
        title: 'Confirm removal of this item',
        message: 'This will erase any changes you have made to the fields and cannot be undone',
        confirmButtonText: 'Remove',
      })
      handleRemove(row, rowIndex)
    } catch (error) {

    }
  }
  return <STable>
  <TableHead>
    <TableRow>
      <TableHeaderCell>First Name</TableHeaderCell>
      <TableHeaderCell>Last Name</TableHeaderCell>
      <TableHeaderCell>Role</TableHeaderCell>
      { canEditCrewInMgmt && <TableHeaderCell>Remove</TableHeaderCell> }
    </TableRow>
  </TableHead>
  <TableBody>
    { data.map((row, rowIndex) => {
      return <TableRow 
        hover
        key={row.email} 
        >
        <SCell>
          {row.firstName}
        </SCell>
        <SCell>
          {row.lastName}
        </SCell>
        <SCell>
          {row.roles.map(role => {
            return (
              <RoleWrapper key={role.id}>
                <Typography>
                  { role.name }
                </Typography>
               { role.default && <DefaultRoleIcon />}
              </RoleWrapper>
            )
          })}
        </SCell>
        { canEditCrewInMgmt &&
          <SCell>
            <IconButton onClick={beforeRemove(row, rowIndex)}>
              <DeleteIcon />
            </IconButton>
          </SCell>
        }
      </TableRow>
    })}
  </TableBody>
</STable>
}

const STable = styled(Table)`
  margin: 10px 0 40px;
`
const TableHeaderCell = styled(TableCell)`
  border-bottom: none;
  background-color: #F5F5F5;
`
const SCell = styled(TableCell)`
  padding: 22px 16px;
`
const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: #9a9898;
    font-size: 18px;
    margin-left: 4px;
  }
`


export default CrewMgmtMemberTable