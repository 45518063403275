import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import DRSMaterialSpraySealItemFields, { DRSMaterialSpraySealItemRowDisplay } from './MaterialSpraySealItemsFields'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { DRSPageType, DRSSectionName } from '@src/modules/DRS/DRSPage'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'
import { DRSMaterialSpraySealItemPAE } from '@src/types/drs/material/MaterialSpraySealItem'

interface DRSMaterialSpraySealItemRow extends DRSMaterialSpraySealItemRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  adhesionAgent: null,
  aggregateInStockpile: null,
  aggregateRequired_M3: null,
  aggregateRequired_Tonnes: null,
  aggregateSize: null,
  aggregateUsed: null,
  area: null,
  binderMixture: null,
  binderType: null,
  comments: null,
  cutter: null,
  depot: null,
  existingSurfaceCondition: null,
  orderedApplicationRate: null,
  orderedRate: null,
  otherSealType: null,
  preCoatUsed: null,
  residualBinder: null,
  spraySealType: null,
  spraySheetNumber: null,
}
const ZERO_VALUES = {
  adhesionAgent: '0',
  aggregateInStockpile: null,
  aggregateRequired_M3: '0',
  aggregateRequired_Tonnes: '0',
  aggregateSize: null,
  aggregateUsed: '0',
  area: '0',
  binderMixture: null,
  binderType: null,
  comments: null,
  cutter: '0',
  depot: null,
  existingSurfaceCondition: null,
  orderedApplicationRate: '0',
  orderedRate: null,
  otherSealType: null,
  preCoatUsed: null,
  residualBinder: '0',
  spraySealType: null,
  spraySheetNumber: '0',
}

interface Props { onUpdated?(): void }

const DRSSectionMaterialSpraySealItems = (props: Props) => {
  const { onUpdated } = props
  const { drs, updateSectionViaPath, drsPageType, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSMaterialSpraySealItemRow>({})
  const { depotId, stateId } = useAppStore()
  if (drs === null) {
    return null
  }
  const onSave = async (form: DRSMaterialSpraySealItemRow) => {
    if (modalData === null) {
      addItem(form)
    } else {
      editItem(form)
    }
  }
  const addItem = (form: DRSMaterialSpraySealItemRow) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: [
        ...retained.detail,
        {
          [updatingColumn]: {
            ...form
          }
        }
      ],
    }
    update(data)
  }
  const editItem = (form: DRSMaterialSpraySealItemRow) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: getUpdatingDetails(retained.detail, form, form),
    }
    update(data)
  }
  const handleRevert = (form: DRSMaterialSpraySealItemRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleMarkZero = (form: DRSMaterialSpraySealItemRow) => {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = (form: DRSMaterialSpraySealItemRow) => {
    const retained = getRetained()
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const data = {
      ...retained,
      detail: [
        ...retained.detail.slice(0, updatingIndex),
        ...retained.detail.slice(updatingIndex + 1),
      ],
    }
    update(data)
  }
  const getRetained = () => {
    const retainedDetails = drs.material.spraySeal.detail
    let retainedSummary = drs.material.spraySeal.summary
    // always make sure other has something
    if (retainedSummary === null) {
      retainedSummary = {} as any
    }
    return {
      summary: retainedSummary,
      detail: retainedDetails,
    }
  }
  const getUpdatingDetails = (originDetails: DRSMaterialSpraySealItemPAE[], form: DRSMaterialSpraySealItemRow, values: any) => {
    if (form._no_ === undefined || form._type_ === undefined) {
      return originDetails
    }
    const updatingIndex = form._no_
    const retainedDetail = originDetails[updatingIndex]
    return [
      ...originDetails.slice(0, updatingIndex),
      {
        ...retainedDetail,
        [updatingColumn]: {
          ...values
        }
      },
      ...originDetails.slice(updatingIndex + 1),
    ]
  }
  const updateItemByValues = (form: DRSMaterialSpraySealItemRow, values: any) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: getUpdatingDetails(retained.detail, form, values),
    }
    update(data)
  }
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Material,
      path: `spraySeal`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    }).then(() => {
      if (onUpdated)
        onUpdated()
    })
  }
  const getTableData = () => {
    const details = drs.material.spraySeal.detail
    if (!details || !details.length) {
      return []
    }
    const doubled = doubleUp(details, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions
  }
  const fieldsInTable: (keyof DRSMaterialSpraySealItemRowDisplay)[] = ['spraySealType', 'binderType', 'area', 'residualBinder', 'aggregateSize', 'aggregateRequired_M3', 'aggregateRequired_Tonnes']
  return <Wrapper>
    <FormPanel title={`Material Spray Seal`}>
      <FormPAETable<DRSMaterialSpraySealItemRow>
        fields={DRSMaterialSpraySealItemFields.filter((field) => fieldsInTable.includes(field.key))}
        data={getTableData()}
        editable={true}
        onEditRow={(row: DRSMaterialSpraySealItemRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
        isSelectable={true}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Spray Seal Material
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSMaterialSpraySealItemRow>
        maxWidth={'lg'}
        open={open}
        title='Material'
        data={{
          rowData: modalData,
          fields: DRSMaterialSpraySealItemFields,
          columns: [
            {
              title: 'Material Information',
              fieldKeys: [
                'spraySealType',
                'binderType',
                'area',
                'orderedApplicationRate',
                'depot',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'residualBinder',
                'cutter',
                'adhesionAgent',
                'aggregateSize',
                'preCoatUsed',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'aggregateRequired_M3',
                'aggregateRequired_Tonnes',
                'aggregateUsed',
                'orderedRate',
                'aggregateInStockpile',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'otherSealType',
                'existingSurfaceCondition',
                'spraySheetNumber',
                'comments',
              ],
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={onSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`
`

export default DRSSectionMaterialSpraySealItems