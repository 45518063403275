import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { MaterialAsphaltOtherProduct } from '@src/types/MaterialAsphaltOtherProduct'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { DRSMaterialAsphaltOther } from '@src/types/drs/material/MaterialAsphaltOther'

export interface DRSMaterialAsphaltOtherRowDisplay extends DRSMaterialAsphaltOther {

}

const DRSMaterialAsphaltOtherFields: FormTableField<DRSMaterialAsphaltOtherRowDisplay>[] = [
  {
    key: 'grit',
    type: FormTableFieldType.Number,
    label: 'Grit (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'tipFees',
    type: FormTableFieldType.Number,
    label: 'Tip Fees',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'depot',
    type: FormTableFieldType.Autocomplete,
    label: 'Depot',
    format: (item: any) => {
      if (item) {
        return item.name
      }
    }
  },
  {
    key: 'catEyeCovers',
    type: FormTableFieldType.Number,
    label: 'Cat eye covers',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'hateliteBitac',
    type: FormTableFieldType.Number,
    label: 'Hatelite/Bitac (meters)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'roadBase',
    type: FormTableFieldType.Number,
    label: 'Road Base (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'sticksNStomps',
    type: FormTableFieldType.Number,
    label: 'Sticks & Stomps',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'geoFabric',
    type: FormTableFieldType.Number,
    label: 'Geo fabric (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'emulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'dryIce',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Dry Ice',
    format: (cellData: MaterialAsphaltOtherProduct['dryIce']) => {
      if (cellData === null) {
        return ''
      }
      return formatBooleanToYesNo(cellData)
    },
  },
]

export default DRSMaterialAsphaltOtherFields