import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FieldName } from './Field'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import IconDay from '@material-ui/icons/WbSunny'
import IconNight from '@material-ui/icons/Brightness3'
import { styledWithOut } from '../../../../../utils/styledWithout'

// const Day = ({
//   status,
//   onClick,
// }) => {
//   return <Option onClick={onClick}>
//     <StyledDay status={status}/>
//     <Text>Day</Text>
//   </Option>
// }

// const Night = ({
//   status,
//   onClick,
// }) => {
//   return <Option onClick={onClick}>
//     <StyledNight status={status}/>
//     <Text>Night</Text>
//   </Option>
// }


// const renderContent = ({
//   field,
//   dayStatus,
//   setDayStatus,
//   nightStatus,
//   setNightStatus,
// }) => {
//   let content
//   if (field.data.id === 'DAY') {
//     content = <React.Fragment>
//       <StyledDay status={'active'}/>
//       <Text>Day</Text>
//     </React.Fragment>
//   } else {
//     content = <React.Fragment>
//       <StyledNight status={'active'}/>
//       <Text>Night</Text>
//     </React.Fragment>
//   }
//   return <ContentWrapper>
//     {content}
//   </ContentWrapper>
// } 

function ShiftType ({
  field,
  onChange,
}: DRS.Comp) {
  const [ dayStatus, setDayStatus ] = useState<any>('active')
  const [ nightStatus, setNightStatus ] = useState<any>('active')

  return <Wrapper>
     <FieldName>
      {field.name}
    </FieldName>
    <ShiftTypeStatic
      shiftTypeId={field.data.id as string}
    />
    {/* {renderContent({field, dayStatus, setDayStatus, nightStatus, setNightStatus})} */}
  </Wrapper>
}

export function ShiftTypeStatic ({
  shiftTypeId,
}: {
  shiftTypeId: string,
}) {
  let content
  if (shiftTypeId === 'DAY') {
    content = <React.Fragment>
      <StyledDay status={'active'}/>
      <Text>Day</Text>
    </React.Fragment>
  } else {
    content = <React.Fragment>
      <StyledNight status={'active'}/>
      <Text>Night</Text>
    </React.Fragment>
  }
  return <ContentWrapper>
    {content}
  </ContentWrapper>
}

const Wrapper = styled.div``

const Content = styled.div``
const ContentWrapper = styled.div`
  display: flex;
  text-align: center;
`

const StyledNight = styled(styledWithOut('status')(IconNight))`
  transform: rotate(45deg);
  color: ${props => props.status === 'active' ? 'inherit' : '#d3cfcf'};
`

const StyledDay = styled(styledWithOut('status')(IconDay))`
color: ${props => props.status === 'active' ? 'inherit' : '#d3cfcf'};
`

const Text = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  line-height: 24px;
`

const Option = styled.div`
  margin-right: 16px;
`

export default ShiftType
