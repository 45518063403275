import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'

export const apiDRSGet = ({
  drsId,
  roleId,
}: {
  drsId: string,
  roleId: string,
}) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/drs/${drsId}/details`,
    params: {
      role: roleId,
    },
  }
  return ax(options)
}