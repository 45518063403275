import { SupplierAgreement } from '@src/types/SupplierAgreement'

interface Row {
  supplierAgreement: SupplierAgreement,
}

export function getSupplierAgreementActivityRowData(row: Row) {
  let finalSupplierAgreement: any = null 
  let finalActivity: any = null 
  if (row.supplierAgreement) {
    finalSupplierAgreement = {
      id: row.supplierAgreement.id,
      description: row.supplierAgreement.id
    }
    finalActivity = {
      id: row.supplierAgreement.itemNumber,
      description: row.supplierAgreement.itemDescription,
    }
  }
  return {
    ...row,
    supplierAgreement: finalSupplierAgreement,
    activity: finalActivity,
  } as any
}