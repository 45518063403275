export interface APIDepot {
  depot: AIMS.Depot,
}

export const ADDepot = (item: APIDepot) => {
  const { depot } = item
  if (!depot) return {}
  const { id, name } = depot
  return {
    label: name,
    value: id,
  }
}