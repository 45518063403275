import React, { useState, useContext, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useProgressBarStatus } from '../../../components/ProgressBarContext'

const ProgressBar = () => {
  let status = useProgressBarStatus()
  if (!status.loading) {
    return null
  }
  return <React.Fragment>
    <StyledProgress variant='determinate' value={status.progress * 100} />
  </React.Fragment>
}

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: '#000',
  },
})(LinearProgress);

const StyledProgress = styled(ColorLinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
` as typeof ColorLinearProgress

export default observer(ProgressBar)