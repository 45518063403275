import React from 'react'
import styled from 'styled-components'
import DatePicker from '../DatePicker'
import { Moment } from 'moment'

export interface DialogFieldInlineDatePickerProps {
  label: string,
  value?: Moment,
  onChange(dueDate: Moment): void,
  disabled?: boolean,
}

const DialogFieldInlineDatePicker = (props: DialogFieldInlineDatePickerProps) => {
  const { label, value, onChange, disabled } = props
  return <DatePicker 
    // variant='inline'
    format='DD/MM/YYYY'
    label={label}
    onChange={onChange}
    value={value ? value : null}
    disabled={disabled}
  />
}

export default DialogFieldInlineDatePicker