import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { Sif } from '@src/types/Sif'
import RowFlex from '@src/components/FormRow/RowFlex'
import { Attachment } from '@src/types/Attachment'
import AttachmentField from '@src/components/Form/AttachmentField'
import { apiGetFiles } from '@src/apis/files/get-files'
import { PackFormType } from '@src/types/PackFormType'
import { useAppStore } from '@src/AppStore'
import CompSiteDetail from './comps/SiteDetail'

interface Props {
  sif: Sif,
}

const SiteInformation = (props: Props) => {
  const { sif } = props
  const [ attachments, setAttachments ] = useState<Attachment[]>([])
  const { serialId } = useAppStore()
  useEffect(() => {
    const fetchFiles = async () => {
      const result = await apiGetFiles({
        formType: PackFormType.PROJECT_SITE,
        formId: sif.siteId,
        serialId,
      })
      setAttachments(result.data.documents)
    }
    fetchFiles()
  }, [])

  return <Wrapper>
    <FormSectionTitle>{`SITE INFORMATION`}</FormSectionTitle>
    <FormPanel
      title={`Site Details`}
    >
      <CompSiteDetail siteInformation={sif.siteInformation} />
    </FormPanel>
    <FormPanel
      title={`Site Photographs`}
    >
      <RowFlex>
        { attachments.map((attachment: Attachment) => {
          return <AttachmentField key={attachment.id} attachment={attachment}/>
        })}
      </RowFlex> 
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div``
const RowWrapper = styled.div`
  margin-bottom: 48px;
`
export default SiteInformation