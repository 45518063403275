import React, { useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

const FieldCommentText = ({
  field,
  onChange,
}: DRS.Comp) => {
  const [ open, setOpen ] = useState<any>(Boolean(field.data))

  return <Wrapper>
      
    { !open &&
      <Tooltip title={field.data || ''} placement='bottom-start'>
        <IconButton size='small' onClick={() => setOpen(true)}>
          <CommentIcon />
        </IconButton>
        </Tooltip>
    }
    { open && 
      <Tooltip title={field.data || ''} placement='bottom-start'>
        <TextField
          id={field.path}
          name={field.path}
          value={field.data || ''}
          onChange={(e: SyntheticEvent) => onChange((e.target as HTMLInputElement).value)}
        /> 
      </Tooltip>
    }
    
  </Wrapper>
}

const Wrapper = styled.div`
  padding-top: 8px;
`

export default observer(FieldCommentText)