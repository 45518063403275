import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options  {
  srrId: number | string,
  serialId: number,
  id: number,
}

export const apiSrrProfilerRemove = (options: Options) => {
  const { 
    srrId, 
    serialId,
    id,
  } = options

  const axOptions = {
    method: 'DELETE',
    url: `${BASE_API_URL}/v2/srr/${srrId}/machine/profiling/details/${id}`,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}