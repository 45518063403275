import { useAppStore } from '@src/AppStore'
import { Attachment } from '@src/types/Attachment'
import { useEffect, useState } from 'react'
import { apiGetFiles } from '@src/apis/files/get-files'
import { PackFormType } from '@src/types/PackFormType'

export function useAttachmentsInPackForm(packFormType: PackFormType, packFormId: string | number) {
  const [ attachments, setAttachments ] = useState<Attachment[]>([])
  const { serialId } = useAppStore()
  const refresh = async() => {
    if (!packFormId) {
      return
    }
    try {
      const result = await apiGetFiles({
        formType: packFormType,
        formId: packFormId,
        serialId,
      })
      if (result && result.data.documents) {
        setAttachments(result.data.documents as Attachment[])
      }
    } catch (e) {}
  }
  useEffect(() => {
    let once = false
    if (packFormId && !once) {
      once = true
      refresh()
    }
  }, [packFormId])
  return {
    attachments,
    refresh,
  }
}