import React, { useState } from 'react'
import styled from 'styled-components'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker as DatePickerOrigin,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Moment } from 'moment'

const DatePicker = ({
  isKeyboardDatePicker = true,
  onFocus = () => { },
  onBlur = () => { },
  ...props
}: {
  isKeyboardDatePicker?: boolean,
  open?: boolean,
  value?: Moment | null,
  onChange(val: any): void,
  onFocus?(): void,
  onBlur?(): void,
  onClose?(): void,
  InputProps?: any,
  InputAdornmentProps?: any,
  format?: string,
  renderDay?: any,
  label?: string,
  labelFunc?: any,
  disableToolbar?: boolean,
  variant?: 'dialog' | 'inline' | 'static' | undefined,
  disabled?: boolean,
}) => {
  // const [selectedDate, setSelectedDate] = useState<any>()
  // function handleDateChange(date) {
  //   setSelectedDate(date)
  //   props.onChange(date)
  // }
  if (!isKeyboardDatePicker) {
    return <Styled>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePickerOrigin
          value={props.value}
          // @ts-ignore
          onChange={props.onChange}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </Styled>
  }
  return <Styled>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        value={props.value}
        // @ts-ignore
        onChange={props.onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />
    </MuiPickersUtilsProvider>
  </Styled>
}

const Styled = styled.div`

`

export default DatePicker