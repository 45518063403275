import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiBinderTypeSearch } from '../../apis/binder-type-search'

const BinderTypeAutocomplete = ({
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
}: AIMS.Autocomplete) => {
  const fetch = async (query: string) => {
    const result: any = await apiBinderTypeSearch({ query })
    return result.data.spraybindertypes
      .slice(0, 5)
      .map((binderType: any) => {
        return {
          value: binderType.id,
          label: binderType.description,
          raw: binderType,
        }
      })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label || 'Binder Type'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default BinderTypeAutocomplete