import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import { getTypeColor } from '../ProjectCard'


const StyledPlaceholder = styled(Typography)`
  color: #707070;
  /* padding-top: 20px; */
  margin-bottom: 36px;
`

const StyledFlexBetween = styled.div`
  width: 236px;
  display: flex;
  align-content: space-around;
`
const Flex1 = styled.div`
  flex: 1 1 auto;
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ProjectTypeCircle = styled.div<{ grey?: boolean, type: string }>`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: ${props => {
    if (props.grey) {
      return '#707070'
    } else {
      return getTypeColor(props.type)
    }
  }
  };
  margin-bottom: 8px;
  cursor: pointer;
`

const StyleProjectType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
`
const Styled58px = styled.div`
  width: 58px;
  text-align: center;
`
const ProjectType = ({
  data, 
  type,
}: {
  data: any,
  type: AIMS.ProjectTypeID | null,
}) => {
  return (<StyleProjectType>
    <ProjectTypeCircle type={data.type} grey={type !== data.type}></ProjectTypeCircle>
    <Styled58px>{data.label}</Styled58px>
  </StyleProjectType>)
}

const ProjectTypeRadio = ({
  type,
  onChange,
}: {
  type: AIMS.ProjectTypeID | null,
  onChange(p: string): void,
}) => {
  const options = [
    {
      label: 'Asphalt & Spray Seal',
      type: 'ASPHALT-SPRAY-SEAL',
      color: '#983636'
    },
    {
      label: 'Asphalt',
      type: 'ASPHALT',
      color: '#6A5681'
    },
    {
      label: 'Spray Seal',
      type: 'SPRAY-SEAL',
      color: '#366B98'
    },
  ]
  return (
    <FlexColumn>
      <StyledPlaceholder>Assign Project Type</StyledPlaceholder>
      <StyledFlexBetween>
        { options.map(option => {
          return <Flex1 key={option.label} onClick={e => onChange(option.type)}>
            <ProjectType data={option} type={type}/>
          </Flex1>
        })}
        
      </StyledFlexBetween>
    </FlexColumn>
  )
}

export default ProjectTypeRadio