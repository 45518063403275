import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiCustomerSearch } from '../../apis/customer-search'
import { ADIDNameToValueLabel } from '../../api-adaptors/id-name-to-value-label'
import { apiSupplierSearch } from '@src/apis/supplier-search'
import { Supplier } from '@src/types/Supplier'

function SupplierAutocomplete ({
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth,
}: AIMS.Autocomplete) {
  const fetch = async (query: string) => {
    try {
      const result = await apiSupplierSearch({ query })
      return (result.data.suppliers as Supplier[])
        .slice(0, 5)
        .map((supplier: Supplier) => {
          return {
            value: supplier.id,
            label: supplier.name,
            raw: supplier,
          }
        })
    } catch(e) {
      return []
    }
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default SupplierAutocomplete