import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import RowGrid from '@src/components/FormRow/RowGrid'
import TextareaField from '@src/components/Form/TextareaField'
import { Attachment } from '@src/types/Attachment'
import AttachmentField from '@src/components/Form/AttachmentField'
import RowFlex from '@src/components/FormRow/RowFlex'
import { useAppStore } from '@src/AppStore'
import { Sif } from '@src/types/Sif'
import { apiGetFiles } from '@src/apis/files/get-files'
import { PackFormType } from '@src/types/PackFormType'

interface Props {
  sif: Sif,
}

const SiteDiagram = (props: Props) => {
  const {
    sif,
  } = props
  const { serialId } = useAppStore()
  const [drawings, setDrawings] = useState<Attachment[]>([])
  const [attachments, setAttachments] = useState<Attachment[]>([])
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const result = await apiGetFiles({
          formType: PackFormType.SITE_ACCESS_DIAGRAM,
          formId: sif.siteId,
          serialId,
        })
        setDrawings(result.data.documents.filter((document: Attachment) => document.isSiteDiagram))
        setAttachments(result.data.documents.filter((document: Attachment) => !document.isSiteDiagram))
      } catch (e) {

      }
    }
    fetchFiles()
  }, [])
  return <Wrapper>
    <FormSectionTitle>{`Site Diagram`}</FormSectionTitle>
    {/* <FormPanel
      title={`Other Comments`}
    >
      <RowGrid size={4}>
        <TextareaField 
          label={'Comments on Base Condition'}
          value={`Sealed two days ago - potential soft spots. See attached photos`}
          readonly
          />
      </RowGrid>

    </FormPanel> */}
    <FormPanel
      title={`Site Diagram`}
    >
      <RowFlex>
        {drawings.map((attachment: Attachment) => {
          return <AttachmentField key={attachment.id} attachment={attachment} />
        })}
      </RowFlex>
    </FormPanel>
    <FormPanel
      title={`Photographs`}
    >
      <RowFlex>
        {attachments.map((attachment: Attachment) => {
          return <AttachmentField key={attachment.id} attachment={attachment} />
        })}
      </RowFlex>
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div``

export default SiteDiagram