import { useEffect, useState } from 'react'
import { SRR } from '@src/types/SRR'
import { useAppStore } from '@src/AppStore'
import { apiSrrGetOne } from '@src/apis/srr-get-one'
import { useParams } from 'react-router'
import { useSRRDetailStore } from './SRRDetailStore'

interface Options {
  // srrId: number,
}

export function useSRR(): [SRR | null, boolean, typeof fetch] {
  // const {
  //   srrId
  // } = options
  const { id } = useParams<{ id: string }>()
  const { serialId } = useAppStore()
  const { srr, setSrr, fetchSrr } = useSRRDetailStore()
  const [loading, setLoading] = useState<boolean>(false)
  const fetch = async () => {
    setLoading(true)
    try {
      const result = await apiSrrGetOne({
        id: Number(id),
        serialId,
      })
      // console.log(result)
      setSrr(result.data.srr)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return [srr, loading, fetch]
}

// const [ srr, srrLoading, refresh ] = useSRR()