import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { DepotType } from '@src/types/common/DepotType'


export interface APILabourOptions {
  email: string,
  firstName: string,
  lastName: string,
  mobileNumber: string,
  employeeNumber?:string,
  pictureLink: string,
  active: boolean,
  depotId: string,
  roles: any,
  crews: any,
  serialId: number,

  handleProfileDepotType?: boolean,
  depotType: null | undefined | {
    id: DepotType,
  },
  depotPayroll: string,
  employeeType: any,
  labourHireCompany: any
}

export const apiLabourUpdate = ({
  email,
  firstName,
  lastName,
  mobileNumber,
  employeeNumber,
  pictureLink = '',
  active = true,
  depotId,
  roles,
  crews,
  serialId,
  handleProfileDepotType,
  depotType,
  depotPayroll,
  employeeType,
  labourHireCompany,
}: APILabourOptions) => {
  const data = {
    serialId,
    email,
    firstName,
    lastName,
    mobileNumber,
    employeeNumber,
    pictureLink,
    active,
    depot: {
      id: depotId,
    },
    roles,
    crews,
    depotType,
    depotPayroll,
    employeeType,
    labourHireCompany,
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/profiles`,
    data,
    params: {
      handleProfileDepotType,
    }
  }
  return ax(options)
}