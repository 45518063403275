import React from 'react'
import styled from 'styled-components'

interface Props {
  color: string,
}

const ColorSquare = (props: Props) => {
  const { color } = props
  return <Square color={color}>

  </Square>
}

const Square = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${props => props.color};
`

export default ColorSquare