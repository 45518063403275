import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { MaterialSpraySealOtherProduct } from '@src/types/MaterialSpraySealOtherProduct'

const SRRMaterialSpraySealOtherFields: FormTableField<MaterialSpraySealOtherProduct>[] = [
  {
    key: 'grit',
    type: FormTableFieldType.Number,
    label: 'Grit (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'dialBeforeYouDig',
    type: FormTableFieldType.Text,
    label: 'Dial Before You Dig',
  },
  {
    key: 'catEyeCovers',
    type: FormTableFieldType.Number,
    label: 'Cat eye covers',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'trafficLoops',
    type: FormTableFieldType.Text,
    label: 'Traffic Loops',
  },
  {
    key: 'hateliteBitac',
    type: FormTableFieldType.Number,
    label: 'Hatelite/Bitac (meters)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'sticksNStomps',
    type: FormTableFieldType.Number,
    label: 'Sticks & Stomps',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'geoFabric',
    type: FormTableFieldType.Number,
    label: 'Geo fabric (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'emulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
]

export default SRRMaterialSpraySealOtherFields