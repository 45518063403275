import { useEffect, useState } from 'react'
import { SRR } from '@src/types/SRR'
import { useAppStore } from '@src/AppStore'
import { apiSrrGetOne } from '@src/apis/srr-get-one'
import { useParams } from 'react-router'
import { VMP } from '@src/types/VMP'
import { useStoreVMP } from './Store.VMP'
import { apiVMPGetOne } from '@src/apis/vmp-get-one'

interface Options {
  // srrId: number,
}

export function useVMP(): [ VMP | null, boolean, typeof fetch] {
  const { id } = useParams<{id: string}>()
  const { serialId } = useAppStore()
  const { vmp, setVMP } = useStoreVMP()
  const [ loading, setLoading ] = useState<boolean>(false)
  useEffect(() => {
    if (!vmp && id) {
      fetch()
    }
  }, [id])
  const fetch = async () => {
    setLoading(true)
    try {
      const result = await apiVMPGetOne({
        id: Number(id),
        serialId,
      })
      // console.log(result)
      setVMP(result.data.vmp as VMP)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return [ vmp, loading, fetch ]
}
