import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { SRR } from '@src/types/SRR'
import { useAppStore } from '@src/AppStore'
import { apiSrrGetOne } from '@src/apis/srr-get-one'

interface SRRDetailStore {
  id: number | null,
  setId(id: number): void,
  srr: SRR | null,
  setSrr(srr: SRR): void,
  setAny(): void,
  fetchSrr(options: fetchOptions): void,
}

interface fetchOptions {
  serialId: number, 
  srrId: number | string,
}

export const store: SRRDetailStore = observable<SRRDetailStore>({
  id: null,
  setId: action((id: number) => store.id = id),
  srr: null,
  setSrr: action((srr: SRR) => {
    
    store.srr = srr
  }),
  setAny: action(() => {
    const { serialId } = useAppStore()
    console.log(serialId)
  }),
  fetchSrr: action((options: fetchOptions) => {
    const {
      serialId,
      srrId,
    } = options
    const fetch = async () => {
      // setLoading(true)
      try {
        const result = await apiSrrGetOne({
          id: Number(srrId),
          serialId,
        })
        console.log(result)
        store.setSrr(result.data.srr)
        // setLoading(false)
      } catch (e) {
        // setLoading(false)
      }
    }
    fetch()
  })
})

const context: React.Context<SRRDetailStore> = React.createContext<SRRDetailStore>(store)

export const useSRRDetailStore = () => {
  return useContext<SRRDetailStore>(context)
}