import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { DepotType } from '@src/types/common/DepotType'

interface Options {
  email: string,
  firstName: string,
  lastName: string,
  mobileNumber: string,

  employeeType: string,
  labourHireCompany: string,

  employeeNumber?: string,
  pictureLink: string,
  active: boolean,
  depotId: string,
  depotPayroll: string,
  roles: any,
  crews: any,
  handleProfileDepotType?: boolean,
  depotType: null | undefined | {
    id: DepotType,
  },
}

export const apiLabourCreate = ({
  email,
  firstName,
  lastName,
  mobileNumber,

  employeeType,
  labourHireCompany,

  pictureLink = '',
  active = true,
  depotId,
  roles,
  crews,
  depotType,
  depotPayroll,
  handleProfileDepotType,
  employeeNumber
}: Options) => {
  const data = {
    email,
    firstName,
    lastName,
    mobileNumber,
    pictureLink,
	employeeType,
	labourHireCompany,
    active,
    depot: {
      id: depotId,
    },
    roles,
    crews,
    depotType,  depotPayroll,
    employeeNumber
  
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/profiles`,
    data,
    params: {
      handleProfileDepotType,
    }
  }
  return ax(options)
}