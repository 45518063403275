import React, { useContext } from 'react'
import { observable, action } from "mobx";
import moment from 'moment'
import { WEBSOCKET_API_URL } from '@src/constants'
import { getCookieIdToken, getCookieRefreshToken, setCookieAccessToken, setCookieIdToken } from '@src/utils/cookies'
import { apiRefreshToken } from '@src/apis/token'
import { trim } from 'lodash';

const store: any = observable({
    wsocket_host: '',
    socketRetry:0,
    socketRefreshHook: 0,
    listenBy: 'depot',
    shiftIds: [],
    region: null,
    depot: null,
    shiftOrigDate: null,
    rangeStartDate: null,
    rangeEndDate: null,
    ws: null,
    setShiftIds: action((shiftIds: number[]) => {
        store.shiftIds = shiftIds
    }),
    setWatch: action(({ start, end, region, depot }: {
        start: string, end: string, region: string, depot: string
    }) => {
        //console.log(`useSocketClient setWatch() region=${region} depot=${depot} start=${start} end=${end}`)
        store.region = region;
        store.depot = depot;
        store.rangeStartDate = start;
        store.rangeEndDate = end;
    }),
    setListenBy: action((listenBy: string) => {
        store.listenBy = listenBy
    }),
    resetCookiesConnectWebSocket: action(() => {

        apiRefreshToken().then(refreshResponse => {
            if (
                refreshResponse.data
                && refreshResponse.data.AuthenticationResult
                && refreshResponse.data.AuthenticationResult.IdToken
            ) {
                const { AuthenticationResult } = refreshResponse.data
                setCookieIdToken(AuthenticationResult.IdToken)
                setCookieAccessToken(AuthenticationResult.AccessToken)
                store.wsocket_host = `${WEBSOCKET_API_URL}?token=${AuthenticationResult.IdToken}&reqdev=web`
                console.log(`useSocketClient resetCookies() store.wsocket_host=${store.wsocket_host}`)
                store.connectWebSocket()
            }
        }).catch(error => {
            console.log(`useSocketClient resetCookies() error=${JSON.stringify(error, null, 2)}`)
            console.log(error)
        })
    }),
    connectWebSocket: action(() => {
        if(store.socketRetry < 10) {
            store.ws = new WebSocket(store.wsocket_host);
            store.socketRetry++;
            store.ws.onopen = function () {
                console.log(`useSocketClient onopen CONNECTED3 ${moment().format()}`);
                store.socketRetry = 0;
            };
            
        
       
        store.ws.onmessage = function (e: any) {
            const messageObj = JSON.parse(e.data);
            //console.log(`\n\nuseSocketClient onmessage ${JSON.stringify(e.data, null, 2)}`)
            //console.log(`useSocketClient store.shiftIds.length=${store.shiftIds.length} store.shiftIds=${JSON.stringify(store.shiftIds, null, 2)}`)
            console.log(`useSocketClient() store.listenBy=${store.listenBy}`)
            // console.log(`messageObj`);
            // console.log(store.shiftIds);
            // console.log(`messageObj`);
            //console.log(`useSocketClient onConnMessage same Depot=${messageObj.detail.DepotID === store.depot} NewDate isBetween=${moment(messageObj.detail.ShiftDate * 1000).isBetween(store.rangeStartDate, store.rangeEndDate)} OriginalDate isBetween=${moment(messageObj.detail.ShiftOriginalDate * 1000).isBetween(store.rangeStartDate, store.rangeEndDate)}`)
            //console.log(`useSocketClient onConnMessage Depot=${messageObj.detail.DepotID} store.depot=${store.depot}`)
            //console.log(`\nuseSocketClient onConnMessage NewDate=${moment(messageObj.detail.ShiftDate * 1000).format('DD-MM-YY')} OriginalDate=${moment(messageObj.detail.ShiftOriginalDate * 1000).format('DD-MM-YY')} `)
            //console.log(`useSocketClient onConnMessage Date Range=${moment(store.rangeStartDate).format('DD-MM-YY')} to ${moment(store.rangeEndDate).format('DD-MM-YY')}`)
          //  store.socketRefreshHook++;
            if (messageObj.event === 'ShiftCalendarUpdate') {
                console.log(`useSocketClient() DO RERESH`)
                store.socketRefreshHook++;
                // if a change to on of the Shifts in view OR a new Shift created in date range AND (depot or region depending on viewing Cres in Depot or Region) 
                // if (store.shiftIds.includes(messageObj.detail.ShiftID)
                //     || (moment(messageObj.detail.ShiftDate * 1000).isBetween(store.rangeStartDate, store.rangeEndDate)) &&
                //     ((store.listenBy === 'depot' && messageObj.detail.DepotID === store.depot) || (store.listenBy === 'region' && messageObj.detail.RegionID === store.region))) {
                //     console.log(`useSocketClient() DO RERESH`)
                //     store.socketRefreshHook++;
                // } else {
                //     console.log(`useSocketClient() DON'T DO RERESH`)
                // }
            }
        }
        };

        store.ws.onclose = function (e: any) {
            console.log(`useSocketClient onclose DISCONNECTED ${moment().format()}`);
            console.log('Socket is closed. Reconnect will be attempted in 1 second reason=', e.reason);
            console.log(e);
            setTimeout(function () {
                // if disconnected because the websocket has timed out otherwise if IdToken token has expired
                if (trim(e.reason) === "Going away") {
                    store.wsocket_host = `${WEBSOCKET_API_URL}?token=${getCookieIdToken()}&reqdev=web`
                    console.log(`useSocketClient onclose store.wsocket_host=${store.wsocket_host}`);
                    console.log(`\nuseSocketClient onclose call connectWebSocket()`);
                    store.connectWebSocket();
                    
                } else {
                    console.log(`\nuseSocketClient onclose call resetCookiesConnectWebSocket()`);
                    store.resetCookiesConnectWebSocket();
                }
            }, 1000);
        };

        store.ws.onerror = function (err: any) {
            console.error('useSocketClient encountered error: ', err.message, 'Closing socket');
            store.ws.close();
            
        };
    }),
    doConnectWebSocket: action(() => {
        store.wsocket_host = `${WEBSOCKET_API_URL}?token=${getCookieIdToken()}&reqdev=web`
        console.log(`useSocketClient doConnectWebSocket() store.wsocket_host=${store.wsocket_host}`);
        store.connectWebSocket()
    }),
    sendData: action((data: any) => {
        console.log(`--------------------`);
        store.ws.send(JSON.stringify(data));
    }),
});

const context = React.createContext(store);

export const useSocketClient = () => {
    return useContext(context);
};