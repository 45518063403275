import { DRSStatus } from "@src/types/drs/DRSStatus";
import { Shift } from "@src/types/Shift";

const useEditShiftRules = (data: null | Shift) => {
  let canShiftDateBeChanged: boolean = true;
  let canShiftCrewBeChanged: boolean = true;
  let canShiftCrewBeDeleted: boolean = true;

  if (
    data &&
    (data.status.id === DRSStatus.APPROVED_PM ||
      data.status.id === DRSStatus.COMPLETED)
  ) {
    canShiftDateBeChanged = false;
  }

  if (
    data &&
    (data.status.id === DRSStatus.SUBMITTED_PM ||
      data.status.id === DRSStatus.APPROVED_PM ||
      data.status.id === DRSStatus.COMPLETED)
  ) {
    canShiftCrewBeChanged = false;
  }

  if (
    data &&
    (data.status.id === DRSStatus.DRAFT ||
      data.status.id === DRSStatus.CANCELLED ||
      data.status.id === DRSStatus.SUBMITTED_FORMAN)
  ) {
    canShiftCrewBeDeleted = false;
  }

  return [canShiftDateBeChanged, canShiftCrewBeChanged, canShiftCrewBeDeleted];
};

export default useEditShiftRules;