import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import DRSMaterialAsphaltItemFields, { DRSMaterialAsphaltItemRowDisplay } from './MaterialAsphaltItemsFields'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { DRSPageType, DRSSectionName } from '@src/modules/DRS/DRSPage'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'
import { DRSMaterialAsphaltItemPAE } from '@src/types/drs/material/MaterialAsphaltItem'

interface DRSMaterialAsphaltItemRow extends DRSMaterialAsphaltItemRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}
const NULL_VALUES = {
  asphaltLayer: null,
  supplyPlant: null,
  product: null,
  area: null,
  controlLine: null,
  customerSpecifiedMix: null,
  depth: null,
  lotNumber: null,
  maxRap: null,
  tonnes: null,
}
const ZERO_VALUES = {
  asphaltLayer: null,
  supplyPlant: null,
  product: null,
  area: '0',
  controlLine: null,
  customerSpecifiedMix: null,
  depth: '0',
  lotNumber: null,
  maxRap: null,
  tonnes: '0',
}

interface Props { onUpdated?(): void }
const DRSSectionMaterialAspahltItems = (props: Props) => {
  const { onUpdated } = props
  const { drs, updateSectionViaPath, drsPageType, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSMaterialAsphaltItemRow>({})
  const { depotId, stateId } = useAppStore()
  if (drs === null) {
    return null
  }
  const onSave = async (form: DRSMaterialAsphaltItemRow) => {
    if (modalData === null) {
      addItem(form)
    } else {
      editItem(form)
    }
  }
  const addItem = (form: DRSMaterialAsphaltItemRow) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: [
        ...retained.detail,
        {
          [updatingColumn]: {
            ...form
          }
        }
      ],
    }
    update(data)
  }
  const editItem = (form: DRSMaterialAsphaltItemRow) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: getUpdatingDetails(retained.detail, form, form),
    }
    update(data)
  }
  const handleRevert = (form: DRSMaterialAsphaltItemRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleMarkZero = (form: DRSMaterialAsphaltItemRow) => {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = (form: DRSMaterialAsphaltItemRow) => {
    const retained = getRetained()
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const data = {
      ...retained,
      detail: [
        ...retained.detail.slice(0, updatingIndex),
        ...retained.detail.slice(updatingIndex + 1),
      ],
    }
    update(data)
  }
  const getRetained = () => {
    const retainedDetails = drs.material.asphalt.detail
    let retainedSummary = drs.material.asphalt.summary
    // always make sure other has something
    if (retainedSummary === null) {
      retainedSummary = {} as any
    }
    return {
      summary: retainedSummary,
      detail: retainedDetails,
    }
  }
  const getUpdatingDetails = (originDetails: DRSMaterialAsphaltItemPAE[], form: DRSMaterialAsphaltItemRow, values: any) => {
    if (form._no_ === undefined || form._type_ === undefined) {
      return originDetails
    }
    const updatingIndex = form._no_
    const retainedDetail = originDetails[updatingIndex]
    return [
      ...originDetails.slice(0, updatingIndex),
      {
        ...retainedDetail,
        [updatingColumn]: {
          ...values
        }
      },
      ...originDetails.slice(updatingIndex + 1),
    ]
  }
  const updateItemByValues = (form: DRSMaterialAsphaltItemRow, values: any) => {
    const retained = getRetained()
    const data = {
      ...retained,
      detail: getUpdatingDetails(retained.detail, form, values),
    }
    update(data)
  }
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Material,
      path: `asphalt`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    }).then(() => {
      if (onUpdated)
        onUpdated()
    })
  }
  function getTableData() {
    if (drs === null) {
      return []
    }

    const details = drs.material.asphalt.detail
    if (!details || !details.length) {
      return []
    }
    const doubled = doubleUp(details, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    console.log(`doubledWithActions`);
    console.log(JSON.stringify(details));
    console.log(JSON.stringify(drsPageType));
    console.log(JSON.stringify(doubledWithActions));
    console.log(`doubledWithActions`);
    return doubledWithActions
  }
  const fieldsInTable: (keyof DRSMaterialAsphaltItemRowDisplay)[] = ['asphaltLayer', 'supplyPlant', 'product', 'area', 'tonnes']

  function getCol2Fields() {
    const pmFields: (keyof DRSMaterialAsphaltItemRow)[] = ['area', 'depth', 'maxRap', 'wasteReturn',]
    const rsFields: (keyof DRSMaterialAsphaltItemRow)[] = ['area', 'depth', 'maxRap',]
    if (isEdit) {
      return pmFields
    }

    return rsFields
  }
  function getCol3Fields() {
    const pmFields: (keyof DRSMaterialAsphaltItemRow)[] = ['tonnes', 'lotNumber', 'controlLine', 'wasteReturnReason',]
    const rsFields: (keyof DRSMaterialAsphaltItemRow)[] = ['tonnes', 'lotNumber', 'controlLine',]
    if (isEdit) {
      return pmFields
    }

    return rsFields
  }
  return <Wrapper>
    <FormPanel title={`Material Asphalt`}>
      <FormPAETable<DRSMaterialAsphaltItemRow>
        fields={DRSMaterialAsphaltItemFields.filter((field) => fieldsInTable.includes(field.key))}
        data={getTableData()}
        editable={true}
        onEditRow={(row: DRSMaterialAsphaltItemRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
        isSelectable={true}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Asphalt Material
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSMaterialAsphaltItemRow>
        maxWidth={'md'}
        open={open}
        title='Material'
        data={{
          rowData: modalData,
          fields: DRSMaterialAsphaltItemFields,
          columns: [
            {
              title: 'Material Information',
              fieldKeys: [
                'asphaltLayer',
                'supplyPlant',
                'product',
                'customerSpecifiedMix',
              ],
            },
            {
              title: '',
              fieldKeys: getCol2Fields(),
            },
            {
              title: '',
              fieldKeys: getCol3Fields(),
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={onSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default DRSSectionMaterialAspahltItems