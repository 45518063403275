import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { Bundle } from './types/common/Bundle'
import { VMPSiteDiagram } from './types/VMPSiteMap'

export let statusBundleStore: 'init' | 'initializing' | 'initialized' = 'init'

interface BundleStore {
  bundle: Bundle,
  setBundle(bundle: Bundle): void,
  setInitializing(): void,
}


export const store: BundleStore = observable<BundleStore>({
  bundle: {
    aggregates: [],
    answers: [],
    asphaltLayers: [],
    asphaltProcess: [],
    assetTypes: [],
    delayReasons: [],
    equipmentTypes: [],
    jobSpecifications: [],
    millSizes: [],
    millTypes: [],
    mobileAssetTypes: [],
    providers: [],
    qualityOfWorkmanships: [],
    rapSuitables: [],
    reworkTypeReasons: [],
    spraySealTypes: [],
    transportTypes: [],
    wasteReturnReasons: [],
    asphProcess:[],
    weathers: [],
    workTypes: [],
    hirecompanies: [],
    srrMachineResourceSizes: [],
    srrMachineResourceTypes: [],
    srrProfilingMillSizes: [],
    srrProfilingMillTypes: [],
    vmpDefaultSiteDiagram: {} as VMPSiteDiagram,
    vmpDefaultSiteRules: [],
  } as Bundle,
  setBundle: action((bundle: Bundle) => {
    store.bundle = bundle
    statusBundleStore = 'initialized'
  }),
  setInitializing: () => statusBundleStore = 'initializing',
})

const context: React.Context<BundleStore> = React.createContext<BundleStore>(store)

export const useBundleStore = () => {
  return useContext<BundleStore>(context)
}