import { capWord } from '@src/utils/capWord'

export function formatEmailToFirstLastName(email: string) {
  try {
    const withDot = email.substring(0, email.indexOf('@'))
    const firstName = withDot.split('.')[0]
    const lastName = withDot.split('.')[1]
    return `${capWord(firstName)} ${capWord(lastName)}`
  } catch(e) {
    return ''
  }
  
}