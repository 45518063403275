import { useDRS } from '@src/modules/DRS/useDRS'
import { apiGetFilesByDRSId } from '@src/apis/files/get-files-by-drs-id'
import { useAppStore } from '@src/AppStore'
import { Attachment } from '@src/types/Attachment'
import { useEffect } from 'react'

export function useAttachmentsInDRS() {
  const { attachments, setAttachments, drsId } = useDRS()
  const { serialId } = useAppStore()
  const refresh = async() => {
    if (!drsId) {
      return
    }
    try {
      const result = await apiGetFilesByDRSId({
        drsId: drsId + '',
        serialId,
      })
      if (result && result.data.documents) {
        setAttachments(result.data.documents as Attachment[])
      }
    } catch (e) {}
  }
  useEffect(() => {
    let once = false
    if (drsId && !once) {
      once = true
      refresh()
    }
  }, [drsId])
  return {
    attachments,
    refresh,
  }
}