import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  query: string
}

export const apiCustomerSearch = ({
  query,
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/customers`,
    params: {
      search: query,
    }
  }
  return ax(options)
}