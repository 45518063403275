import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { WordAutocomplete } from '../../../../components/WordAutocomplete'
import { apiPMInState } from '../../../../apis/pm-in-state'
import { useAppStore } from '../../../../AppStore'
import { ADSomeones } from '../../../../api-adaptors/someones'
import { ADProfile } from '../../../../api-adaptors/profile'

const WhoseAutocomplete = ({ 
  selectedItem,
  onInput,
  onChange,
  onSelect,
  color,
}: {
  selectedItem?: any,
  onInput?: any,
  onChange?: any,
  onSelect?: any,
  color?: string,
}) => {
  
  const [ inputValue, setInputValue ] = useState<any>('')
  const [ options, setOptions ] = useState<any>([])
  const FixedWhoOptions = [
    {
      label: 'My',
      value: 'my',
    },
    {
      label: `Everyone's`,
      value: 'all',
    },
  ]
  const finalOptions = [...FixedWhoOptions, ...options.filter((option: AIMS.ValueLabel)=> {
    return (option.label || '').toLowerCase().includes(inputValue.toLowerCase())
  })]
  const { stateId, userEmail } = useAppStore()
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const result = await apiPMInState(stateId)
        setOptions(
          result.data.profiles
            .map((profile: AIMS.Profile) => ADSomeones(ADProfile(profile)))
            .filter((option: any) => option.value !== userEmail))
      } catch (err) {
        console.log('can not get pm option')
      }
    }
    fetchOptions()
  }, [])
  const handleInput = (value: string) => {
    setInputValue(value)
  }
  const handleSelect = (item: any) => {
    setInputValue('')
    onSelect(item)
  }
  return <StyledWho
    id='whose autocomplete'
    selectedItem={selectedItem}
    color='boral-dark'
    onInput={handleInput}
    options={finalOptions}
    onChange={onChange}
    onSelect={handleSelect}
  />
}

const StyledWho = styled(WordAutocomplete)`
  display: inline-block;
  color: ${props => props.theme.app.colors['boral-dark']};
`


export default WhoseAutocomplete