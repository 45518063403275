import { BASE_API_URL } from "@src/constants"
import { ax } from "@src/utils/axios"

interface Options {
    Comment: string,
    ShiftID: number,
    ShiftCalendarNotesID:string
    Date: string,
    CreatedBy: string,
    Active: boolean,
    IsAcknowledge: boolean,
}

export const apiUpdateShiftNote = ({
    Comment,
    ShiftID,
    Date,
    CreatedBy,
    Active,
    IsAcknowledge,
    ShiftCalendarNotesID
}: Options) => {
    const data = {
        Comment,
        ShiftID,
        Date,
        CreatedBy,
        Active,
        IsAcknowledge,
        ShiftCalendarNotesID
    }
    const options = {
        method: 'PUT',
        url: `${BASE_API_URL}/ref/shiftNotes`,
        data: data
    }
    return ax(options)
}