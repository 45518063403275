import React, { useContext } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Link from '../../../components/Link'
import AccountCircle from '@material-ui/icons/AccountCircle'

import { useAppStore } from '../../../AppStore'
import { capWord } from '@src/utils/capWord'


const ProfileButton = () => {
  const { firstName, lastName } = useAppStore()
  return <React.Fragment>

      <IconButton
        aria-label='Account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        color='inherit'
        size='small'
      >
        <AccountCircle />
      </IconButton>
      <Typography variant='body2'>{`${capWord(firstName)} ${capWord(lastName).substring(0, 1)}`}</Typography>
  </React.Fragment>
}

export default ProfileButton