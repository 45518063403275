import React, { useState, useEffect } from 'react'
import Autocomplete from '../../../../components/GeneralAutocomplete/Autocomplete'
import { apiCustomerSearch } from '../../../../apis/customer-search'
import { ADIDNameToValueLabel } from '../../../../api-adaptors/id-name-to-value-label'
import { Customer } from '@src/types/Customer'

const AutocompleteCustomer = ({
  onChange,
  selectedItem,
  id = '',
  label = '',
  fullWidth = true,
  disabled = false,
} : {
  onChange(p: any): void,
  selectedItem: AIMS.ValueLabel,
  id?: string,
  label?: string,
  fullWidth?: boolean,
  disabled?: boolean,
}) => {
  const fetch = async (query: string) => {
    const result = await apiCustomerSearch({ query })
    return result.data.customers
      .slice(0, 5)
      .map((customer: Customer) => {
        return {
          value: customer.id,
          label: customer.name,
          raw: customer,
        }
      })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }

  return <Autocomplete
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
    disabled={disabled}
  />
}


export default AutocompleteCustomer