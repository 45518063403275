import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  DepotCalendarNotesID: string,
  Comment: string,
  RGBColorCode: string,
  CreatedBy?: string,
  Active: boolean
  Date: string,
  ModifiedBy: string
}

export const apiDepotNoteUpdate = ({
  DepotCalendarNotesID,
  Comment,
  RGBColorCode,
  CreatedBy,
  Active,
  Date,
  ModifiedBy
}: Options) => {
  const data = {
    DepotCalendarNotesID,
    Comment,
    RGBColorCode,
    CreatedBy,
    Active,
    Date,
    ModifiedBy
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/depotNotes`,
    data,
  }
  return ax(options)
}