import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  noteId: string,
  serialId: number,
}

export const apiDepotNoteRemove = (options: Options) => {
  const { 
    noteId,
    serialId,
  } = options
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/removeDepotNote`,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ResourceScheduler,
      reqDev: 'web',
      noteId:noteId
    },
  }
  return ax(axOptions)
}