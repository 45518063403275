import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { VMPSiteMap } from '@src/types/VMPSiteMap'

interface Options {
  shiftId: number,
  submittedBy: string,
  agreedByCustomer?:string,
  customerContact?:string,
  section?: string,
  contractNo?: string,
  type?: string,
  holdPointNumber?: string,
  aspectPreparationSurface?: string,
  
  detailsComments?: string,
  comments?: string,
  status?: string,
  asphalt?:[],
  aspect?:[],
  serialId?: number,
 spray?:[],
 idempToken?:string
}
export const apiATPSubmit = (options: Options) => {
  const { shiftId, serialId,submittedBy,agreedByCustomer,
    customerContact,section,contractNo,type,holdPointNumber,
    aspectPreparationSurface,detailsComments,
    comments,status,asphalt,spray,idempToken,aspect
   } = options
  const axOptions = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/atp`,
    data: options,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}