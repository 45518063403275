import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


export const apiRefBundle = () => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/bundle`,
  }
  return ax(options)
}