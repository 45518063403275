import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import BoralRadio from '../../../../../components/BoralRadio'

const FieldAssetDepotRadio = ({
  field,
  onChange,
}: DRS.Comp) => {
  const Options = [
    {
      value: 'My Depot',
      label: 'My Depot',
    },
    {
      value: 'My State',
      label: 'My State',
    }
  ]
  return <Wrapper>
    <FormControl>
      <RadioGroup
        aria-label='asset depot'
        name='asset depot1'
        value={field.data}
        onChange={e => onChange({ id: e.target.value })}
      >
        { Options.map(option => {
          const { value, label } = option
          return <FormControlLabel key={value} value={value} control={<BoralRadio />} label={label} />
        })}
      </RadioGroup>
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div``

export default FieldAssetDepotRadio