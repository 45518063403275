import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const PanelName = ({
  name,
}: {
  name: string,
}) => {
  return <Styled>
    {name}
  </Styled>
}

const Styled = styled(Typography)`
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  padding-top: 16px;
  margin-bottom: 30px;
`

export default PanelName