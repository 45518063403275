import React from 'react'
import styled from 'styled-components'

import { Link } from 'react-router-dom'


const AdapterLink: any = React.forwardRef((props: { to: string }, ref: any) => <Link innerRef={ref} {...props} />)

export default styled(AdapterLink)`
  text-decoration: none;
`