import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import usePermission from '@src/utils/hooks/usePermission'
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from 'mobx-react-lite'
import { useAppStore } from '@src/AppStore'

export interface DRSRedirectConfirmDialogProps {
    data: number | string, // drs id
    open: boolean,
    onClose(): void,
    onCancel(): void,
    onSave(drs: any):void
}

const DRSDuplicateDialog = observer((props: DRSRedirectConfirmDialogProps) => {
    const { open, onClose, onCancel, data ,onSave} = props
    const { copiedDRS, setCopiedDRS } = useAppStore()
    const [selectedCopies, setSelectedCopies] = useState<any | null>({
        labour: false,
        mobileAssets: false,
        material: false,
        subcontractor: false,
        qa: false
    })
    const { labour, mobileAssets, material, subcontractor, qa } = selectedCopies;
    const { canPlanDRS, canEditDRS, canReviewDRS } = usePermission()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
        setSelectedCopies({
            ...selectedCopies,
            [event.target.name]: event.target.checked,
        });
    };

    const submitDRSDuplication = () => {
        setCopiedDRS({ ...selectedCopies, id: data })
        onSave({ ...selectedCopies, id: data })
    }
    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={onClose}
    >
        <DialogTitle> {`Copy DRS Sections`} </DialogTitle>
        <DialogContent>
            <FormControl component='fieldset'>
                <FormControlLabel control={<Checkbox checked={labour} onChange={handleChange} name={"labour"} />} label='Labour' />
                <FormControlLabel control={<Checkbox checked={mobileAssets} onChange={handleChange} name={"mobileAssets"} />} label='Mobile Assets' />
                <FormControlLabel control={<Checkbox checked={material} onChange={handleChange} name={"material"} />} label='Material' />
                <FormControlLabel control={<Checkbox checked={subcontractor} onChange={handleChange} name={"subcontractor"} />} label='Subcontractor' />
                <FormControlLabel control={<Checkbox checked={qa} onChange={handleChange} name={"qa"} />} label='QA' />
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>
                Cancel
            </Button>
            <Button
                disabled={!Object.values(selectedCopies).some((v) => v === true)}
                color='primary'
                variant='contained'
                onClick={submitDRSDuplication}
            >
                {`NEXT`}
            </Button>
        </DialogActions>
    </Dialog>
})

export default DRSDuplicateDialog