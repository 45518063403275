import React, { useState } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { useAppStore } from '@src/AppStore'
import usePermission from '@src/utils/hooks/usePermission'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TableContainer,Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import FormModalTextareaLarge from '@src/components/FormModal/TextareaLarge'
import { getLength } from '@src/utils/validateLength'
import { apiCreateShiftNote } from '@src/apis/shift-note/shift-note-search'

interface Props {
    open: boolean,
   
    onClose(): void,
    
    shiftCommentData:[]
}

const ShitLogNotesDialog = (props: Props) => {
    const {
     
        onClose,
      open,
       
        shiftCommentData
    } = props

   
    let validateNotes: any = null;
    let date = new Date()

 
   
    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={onClose}
    >
        <DialogTitle >
            <ModalTitle>Shift Log Notes</ModalTitle>
        </DialogTitle>
        <DialogContent>
            <SectionWrapper>
                <Section>
                <TableContainer style={{ marginBottom: '15px' }}>
                <Table  aria-labelledby="commentlist" style={{ border: '2px solid' }}>
                    <TableHeaderCell>
                        <TableRow>
                            <TableCellCusHead align="center">Logged By Name</TableCellCusHead>

                            <TableCellCusHead align="center">Log Date</TableCellCusHead>
                         
                        </TableRow>
                    </TableHeaderCell>
                    <TableBody>
                        {shiftCommentData.length > 0 && shiftCommentData.map((item: { LoggedByName: string, LogDate: string,ShiftLogCalendarNotesID:string}) => (
                            <TableRow>
                                <TableCellCus key={item.ShiftLogCalendarNotesID} align="center">{item.LoggedByName}</TableCellCus>
                                <TableCellCus key={item.ShiftLogCalendarNotesID} align="center">{item.LogDate}</TableCellCus>
                             
                            </TableRow>)
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
                </Section>
            </SectionWrapper>
        
        </DialogContent>

        <DialogActions>
            <Button onClick={(e: React.SyntheticEvent<any>) => {
                e.stopPropagation()
                onClose()
            }}>
                Close
            </Button>

          
        </DialogActions>
    </Dialog>

}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`


const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width:400px;
  
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`

const TableHeaderCell = styled(TableHead)`
        background-color: #F5F5F5;
        `
const TableCellCusHead = styled(TableCell)({
    border: '2px solid #f7f8f9',
    color: '#707070',
    fontWeight: 'bold',
    fontSize: '16px'
})
const TableCellCus = styled(TableCell)({
    border: '2px solid #f7f8f9',
})
export default ShitLogNotesDialog