import moment from 'moment'
import { FormModalTimePickerFormat } from '@src/components/FormModal/TimePicker'

const FORMAT = 'HH:mm'

export function formatTimeStringHHmm(timeString: string) {
  if (!timeString) {
    return ''
  }
  return moment(timeString, FormModalTimePickerFormat).format(FORMAT)
}