export enum PackFormType {
  PROJECT = 'project',
  DAILY_REPORTY_SHEET = 'DRS',
  SITE_INSPECTION_FORM = 'SIF',
  PROJECT_SITE = 'project-site',
  SITE_ACCESS_DIAGRAM = 'site-access',

  HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION = 'hse',
  HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION_QUESTIONNAIRE = 'hseq',
  VEHICLE_MOVEMENT_PLAN = 'vmp',
  VEHICLE_MOVEMENT_PLAN_SITE_MAP_DIAGRAM = 'vmp-site-diagram',
  SHIFT_RESOURCE_REQUIREMENT = 'SRR',
  MOBILITY_ASSET_PRESTARTS = 'MAP',
  SRR_SITE_ACCESS = 'srr-site-access',

  DRS_SUBCONTRACTOR_ASPHALT_MOBILE_ASSET = 'drs-subcontractor-asphalt-mobile-asset',
  DRS_SUBCONTRACTOR_SPRAY_SEAL_MOBILE_ASSET = 'drs-subcontractor-spray-seal-mobile-asset',
  DRS_SUBCONTRACTOR_TRANSPORT = 'drs-subcontractor-transport',

  QUANTITY_AGREEMENT_SHEET = 'qas',
  SITE_MEMO_INSTRUCTION = 'smi',
  
  CREW_PACK_PHOTO = 'crew_pack_photo',
  ATP_CARD= 'atp',
  ATP_PDF_FILE= 'atp_pdf_files',
}

export function formatPackFormType(type: PackFormType) {
  switch (type) {
    case PackFormType.PROJECT:
      return 'Project'
    case PackFormType.DAILY_REPORTY_SHEET:
      return 'Daily Reporting Sheet'
    case PackFormType.SITE_INSPECTION_FORM:
      return 'Site Inspection Form'
    case PackFormType.SHIFT_RESOURCE_REQUIREMENT:
      return 'Shift Resource Requirement'
    case PackFormType.MOBILITY_ASSET_PRESTARTS:
      return 'Mobile Asset Prestarts'
    case PackFormType.HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION:
      return 'Health, Safety Environment Site Induction'
    case PackFormType.VEHICLE_MOVEMENT_PLAN:
      return 'Vehicle Movement Plan'
    case PackFormType.QUANTITY_AGREEMENT_SHEET:
      return 'Quantity Agreement Sheets'
    case PackFormType.SITE_MEMO_INSTRUCTION:
      return 'Site Memo and Instruction'
      case PackFormType.CREW_PACK_PHOTO:
        return 'Photos & PDFs'
        case PackFormType.ATP_CARD:
          return 'Approval to Proceed'
  }
  return ''
}