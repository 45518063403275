export enum PAE {
  Plan = 'plan',
  Actual = 'actual',
  Edit = 'edit',
}

export interface PAEData {
  plan: any,
  actual: any,
  edit: any,
}

export interface PAEDataWithId extends PAEData {
  id: number,
}