import React from 'react'
import styled from 'styled-components'
import { Attachment } from '@src/types/Attachment'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import PhotoIcon from '@material-ui/icons/InsertPhoto'
import PersonIcon from '@material-ui/icons/Person'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { Mime } from '@src/types/Mime'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatEmailToFirstLastName } from '@src/types/format/emailToFirstLastName'
import { BASE_API_URL } from '@src/constants'
import { useAppStore } from '@src/AppStore'
import qs from 'qs'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import RemoveIcon from '@material-ui/icons/Remove'
import EditIcon from '@material-ui/icons/Edit'

export interface AttachmentFieldProps {
  attachment: Attachment,
  removable?: boolean,
  editable?:boolean,
  onRemove?(attachment: Attachment): void,
  onEdit?(attachment: Attachment): void,
  listingIndex?: number,
  extImageHandling?: boolean,
  onImageAttachmentClick?(attachment: Attachment, listingIndex: number): void,
}

const AttachmentField = (props: AttachmentFieldProps) => {
  const {
    attachment,
    removable = false,
    editable = false,
    onRemove,
    onEdit,
    listingIndex,
    extImageHandling,
    onImageAttachmentClick,
  } = props
  const { id, originalName, email, dateCreated, fileType, name } = attachment
  const { description: mime } = fileType
  const { serialId } = useAppStore()
  const AcceptingImageFileMimeTypes: string[] = ['image/jpeg', 'image/png']
  const handleClick = () => {
    // if not an image open in a new browser tab
    if (!extImageHandling || !AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) {
      const fileUrl: string = `${BASE_API_URL}/v2/documents/document/${name}?${qs.stringify({
        reqSid: serialId,
      })}`
      window.open(fileUrl, '_blank')
    } else if (onImageAttachmentClick && typeof onImageAttachmentClick === 'function') {
      if (typeof listingIndex !== 'undefined')
        onImageAttachmentClick(attachment, listingIndex)
    }
  }
  return <Wrapper>
    {removable &&
      <DeleteButton
        size='small'
        onClick={() => {
          if (onRemove && typeof onRemove === 'function') {
            onRemove(attachment)
          }
        }}><RemoveIcon />
      </DeleteButton>
    }
     {editable &&
      <EditButton
        size='small'
        onClick={() => {
          if (onEdit && typeof onEdit === 'function') {
            onEdit(attachment)
          }
        }}><EditIcon />
      </EditButton>
    }
    <MainWrapper onClick={handleClick}>
      <Preview>{mime === Mime.PDF ? <FileIcon /> : <PhotoIcon />}</Preview>
      <Title>{originalName}</Title>
      <Additional>
        <Uploader><PersonIcon fontSize='inherit' />{formatEmailToFirstLastName(email)}</Uploader>
        <Date><CalendarTodayIcon fontSize='inherit' />{formatDateStringDDMMYYYY(dateCreated)}</Date>
      </Additional>
      
    </MainWrapper>
  </Wrapper>
}

const Wrapper = styled.div`
  position: relative;
`
const MainWrapper = styled.div`
  cursor: pointer;
`
const Preview = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #F8F8F8;
  color: #129688;
`
const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  max-width: 120px;
  word-break: break-word;
`
const Additional = styled.div`
  margin-top: 8px;
  font-size: 10px;
`
const Uploader = styled.div`
  svg {
    margin-right: 4px;
  }
`
const Date = styled.div`
  svg {
    margin-right: 4px;
  }
`
const DeleteButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`
const EditButton = styled(IconButton)`
  position: absolute;
  left:0;
  top: 0;
`

AttachmentField.defaultProps = {
  extImageHandling: false,
}

export default AttachmentField