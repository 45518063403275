import { BASE_API_URL } from "@src/constants"
import { ax } from "@src/utils/axios"

interface Options {
    ShiftCalendarNotesID: string
   
}

export const apiShiftLogNotes = ({
    ShiftCalendarNotesID,

}: Options) => {
   

    const options = {
        method: 'GET',
        url: `${BASE_API_URL}/ref/shiftLogNotes`,
       
        params: {
            shiftCalendarNotesID: ShiftCalendarNotesID,
           
          }
    }
    return ax(options)
}