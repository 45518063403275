import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import Checkbox from '@material-ui/core/Checkbox'

const Wrapper = styled.div`
  /* padding-top: 8px; */
`

function FieldMemberCheckbox({
    field,
    onChange
  }: DRS.Comp){
    return <Wrapper>
        <Checkbox
            checked={Boolean(field.data)}
            onChange={onChange}
            color='primary'
        />
    </Wrapper>
}



export default FieldMemberCheckbox;