import React, { useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import Axios from 'axios'
import { observer } from 'mobx-react-lite'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import _get from 'lodash/get'
import moment from 'moment'
import { RoleId } from '@src/types/common/RoleId'
import { useAppStore } from '@src/AppStore'
import { BASE_API_URL } from '@src/constants'
import { getCookieIdToken } from '@src/utils/cookies'
import { useDRS } from '../../useDRS'
import { getDRSPdfName } from '../../utils/getDRSPdfName'
import { DRSPageType } from '@src/modules/DRS/DRSPage'

type SectionValue = 'jobDetails' | 'rework' | 'material' | 'transport' | 'mobileAssets' | 'subcontractors' | 'labour' | 'qa' | 'summary' | 'shiftImages'
function CompPdfDialog({
  open,
  onClose,
  // onOk,
}: {
  open: boolean,
  onClose(): void,
}) {
  const SectionMapping: ({
    label: string,
    value: SectionValue,
    disabled?: boolean,
  })[] = [
      {
        label: 'Job Details',
        value: 'jobDetails',
        disabled: true,
      },
      {
        label: 'Rework',
        value: 'rework',
      },
      {
        label: 'Material',
        value: 'material',
      },
      {
        label: 'Transport',
        value: 'transport',
      },
      {
        label: 'Mobile Assets',
        value: 'mobileAssets',
      },
      {
        label: 'Subcontractors',
        value: 'subcontractors',
      },
      {
        label: 'Labour',
        value: 'labour',
      },
      {
        label: 'QA Requirements',
        value: 'qa',
      },
      {
        label: 'Summary',
        value: 'summary',
      },
      // {
      //   label: 'Shift Images',
      //   value: 'shiftImages',
      // },
    ]
  const [checkedSections, setCheckedSections] = useState<{ [key: string]: boolean }>({
    jobDetails: true,
    rework: false,
    material: false,
    transport: false,
    mobileAssets: false,
    subcontractors: false,
    labour: false,
    qa: false,
    summary: false,
    shiftImages: true,
  })
  const [isLoading, setIsLoading] = useState<any>(false)
  const { stateId, serialId } = useAppStore()
  const { drs, drsId, drsPageType } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  function getRole() {
    if (isPlan) {
      return RoleId.ResourceScheduler
    } else if (isEdit) {
      return RoleId.ProjectManager
    } else {
      return RoleId.Administrator
    }
  }
  const roleId = getRole()
  const handleChange = (sectionValue: SectionValue) => (e: SyntheticEvent) => {
    const checked = (e.target as HTMLInputElement).checked
    setCheckedSections({
      ...checkedSections,
      [sectionValue]: checked,
    })
  }
  const handlePdfDownloadConfirmed = async () => {
    if (!drs) {
      return
    }
    setIsLoading(true)
    try {
      const result = await Axios({
        url: `${BASE_API_URL}/pdf/drs/${drsId}`,
        method: 'GET',
        //responseType: 'blob',
        headers: {
          // Accept: 'application/pdf',
          Accept: 'application/json',
          'Authorization': getCookieIdToken(),
        },
        params: {
          drs: drsId,
          state: stateId,
          role: roleId,
          ...checkedSections,
        }
      })
      // console.log(`result`);
      // console.log(result);
      // console.log(`result`);
      if (typeof result != "undefined"){
        if(typeof result.data.url!="undefined") {
          await downloadPDFS3(result.data.url);
        }else{
          await downloadPDFS3(result.data);
        }
        
      }else {
        setIsLoading(false)
      }


      /* const url = window.URL.createObjectURL(new Blob([result.data]))
       const link = document.createElement('a')
       link.href = url
       const pdfName = getDRSPdfName(drs)
       link.setAttribute('download', pdfName)
       document.body.appendChild(link)
       link.click()
       setIsLoading(false) */
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const downloadPDFS3 = async (pdfName: string) => {
    if (!pdfName) {
      return
    }
    setIsLoading(true);
    try {
      
      const result = await Axios({
        url:  pdfName,
        method: 'GET',
        responseType: 'blob',
        headers: {
			Accept: 'application/pdf'
        },
      })
      const shiftDate: any = drs?.jobDetails.shift.date.toString();
      const shiftType: any = drs?.jobDetails.shift.plan.shiftType.id;
      const crewName: any = drs?.jobDetails.shift.plan.crew.name;
      const fileName = drs?.jobDetails.project.id+'-'+formatDateStringDDMMYYYY(shiftDate) + '-'+crewName+'-'+shiftType+'.pdf';
     

      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank";

      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }
  const downloadPDF = async (pdfName: string) => {
    if (!pdfName) {
      return
    }
    setIsLoading(true);
    try {
      //   url: `${BASE_API_URL}/documents/document/`+pdfName,
      //   method: 'GET',
      //   responseType: 'blob',
      //   headers: {
      //     Accept: 'application/pdf',
      //     'Authorization': getCookieIdToken(),
      //   },

      // })
      // console.log(`result`);
      // console.log(result);
      // console.log(`result`);
      const url = `${BASE_API_URL}/v2/documents/document/${pdfName}?reqSid=` + serialId;
      //  const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank";
      // const pdfNameData = getDRSPdfName(pdfName)
      link.setAttribute('download', pdfName)
      document.body.appendChild(link)
      link.click()
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }
  const allSelected = !Object.keys(checkedSections).some(sectionKey => !checkedSections[sectionKey])
  const handelCheckAll = () => {
    if (!allSelected) {
      setCheckedSections({
        jobDetails: true,
        rework: true,
        material: true,
        transport: true,
        mobileAssets: true,
        subcontractors: true,
        labour: true,
        qa: true,
        summary: true,
        shiftImages: true,
      })
    } else {
      setCheckedSections({
        jobDetails: true,
        rework: false,
        material: false,
        transport: false,
        mobileAssets: false,
        subcontractors: false,
        labour: false,
        qa: false,
        summary: false,
        shiftImages: true,
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Select which sections you would like in your PDF</DialogTitle>
      <DialogContent>
        <div>
          <FormControlLabel
            control={<Checkbox
              checked={allSelected}
              onChange={handelCheckAll}
              indeterminate={!allSelected}
            />
            }
            label={'Entire DRS'}
          />
        </div>
        <div>
          <FormControl>
            <FormGroup>
              {SectionMapping.map(item => {
                const { label, value, disabled } = item
                return (
                  <FormControlLabel
                    key={value}
                    control={<Checkbox
                      checked={Boolean(checkedSections[value])}
                      onChange={handleChange(value)}
                      disabled={disabled}
                      value={value}
                    />}
                    label={label}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <DownloadButtonWrapper>
          <Button
            onClick={handlePdfDownloadConfirmed}
            color='primary'
            variant='contained'
            disabled={isLoading}
          >
            Download PDF
          </Button>
          {isLoading && <Spinner size={24} />}
        </DownloadButtonWrapper>
      </DialogActions>
    </Dialog>
  )
}

const DownloadButtonWrapper = styled.div`
  position: relative;
`
const Spinner = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

export default observer(CompPdfDialog)