import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import FileChip, { FileChipStatus, FileChipFile } from './FileChip'
import { useDRS } from '../StoreDRS'
import _get from 'lodash/get'
import { BASE_API_URL } from '@src/constants'
import qs from 'qs'
import { useAppStore } from '@src/AppStore'
import { apiRemoveFile } from '@src/apis/files/remove-file'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { useParams } from 'react-router-dom'
import FileBox from './FileBox'
import { RoleId } from '@src/types/common/RoleId'
import { PackFormType } from '@src/types/PackFormType'

let lastFileId: number


interface Props extends DRS.Comp {
  roleId: RoleId,
}
const FieldFileUpload = ({
  field,
  onChange,
  roleId
}: Props) => {
  const { data } = useDRS()
  const { drsId } = useParams<{ drsId: string }>();
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  return (
    <FileUpload
      field={field}
      onChange={onChange}
      data={data}
      drsId={drsId!}
      roleId={roleId}
      serialId={serialId}
      alert={alert}
    />
  )
}

interface FileUploadProps extends DRS.Comp {
  data: DRS.RootData,
  drsId: string,
  roleId: string,
  serialId: number,
  alert(options: AIMS.AlertOptions): void,
}
export const FileUpload = ({
  field,
  onChange,
  data,
  drsId,
  roleId,
  serialId,
}: FileUploadProps) => {
  const { path } = field
  const uploadedFiles = _get(data, path, [])
  const [pendings, setPendings] = useState<{ file: File, fileChip: FileChipFile }[]>([])
  const handleFileDropOrSelect = useCallback((files: FileList) => {
    const newFiles: { file: File, fileChip: FileChipFile }[] = generateNewFiles(files)
    const updatedUploadedFiles: any = _get(data, path, [])
    onChange([
      ...updatedUploadedFiles,
      ...newFiles.map(((item: { file: File, fileChip: FileChipFile }) => item.fileChip)),
    ])
    setPendings([
      ...pendings,
      ...newFiles,
    ])
  }, [/* pendings */])

  useEffect(() => {
    console.log('pendings updated')
    if (pendings.length) {
      startUploading(pendings[0])
    }
  }, [pendings])

  /** generate new file id */
  function generateUploadingFileId() {
    if (lastFileId) {
      lastFileId = lastFileId + 100
      return lastFileId + 100
    }
    if(uploadedFiles) {
      if (uploadedFiles.length) {
        const lastFieldId: any = uploadedFiles[uploadedFiles.length - 1];
        lastFileId = lastFieldId.id
      } else {
        lastFileId = 100
      }
    }else{
      lastFileId = 100
    }
    
    let newId = lastFileId + 100
    lastFileId = newId
    return newId
  }
  function generateNewFiles(files: FileList): { file: File, fileChip: FileChipFile }[] {
    return Array.from(files).map((file: File) => {
      const newFileId = generateUploadingFileId()
      const newFile: FileChipFile = {
        id: newFileId,
        name: file.name,
        status: FileChipStatus.PENDING,
        physicalName: '',
      }
      return {
        file,
        fileChip: newFile,
      }
    })
  }
  const startUploading = async (item: { file: File, fileChip: FileChipFile }) => {
    if (item) {
      console.log(`starting to upload file: ${item.file.name}`,)
      try {
        const pendingFileIndex = pendings.findIndex((_item: { file: File, fileChip: FileChipFile }) => item.fileChip.id === _item.fileChip.id)
        setPendings([
          ...pendings.slice(0, pendingFileIndex),
          ...pendings.slice(pendingFileIndex + 1),
        ])
        const result: any = await apiUploadFile({
          formType: PackFormType.DAILY_REPORTY_SHEET,
          formId: drsId,
          roleId,
          serialId,
          file: item.file,
          projectInternalId: _get(data, `jobDetails.project.projectInternalId`, []) ? _get(data, `jobDetails.project.projectInternalId`, []) : 0,
        })
        console.log('done uploaded', item.file.name)
        const updatedUploadedFiles: any = _get(data, path, [])
        const uploadFileIndex = updatedUploadedFiles.findIndex((file: FileChipFile) => file.id === item.fileChip.id)
        onChange([
          ...updatedUploadedFiles.slice(0, uploadFileIndex),
          {
            id: result.data.document.id,
            name: result.data.document.originalName,
            status: FileChipStatus.NORMAL,
            physicalName: result.data.document.name,
          },
          ...updatedUploadedFiles.slice(uploadFileIndex + 1),
        ])

      } catch (err) {
        // console.log(err)
        // alert({
        //   message: 'Cannot upload file at the moment.'
        // })
        // const newData = _get(data, path, [])
        // onChange([
        //   ...newData.slice(0, newData.length - 1),
        // ])
      } finally {

      }
    }
  }
  const handleFileRemove = (index: number) => async (file: FileChipFile) => {
    try {
      onChange([
        ...uploadedFiles.slice(0, index),
        {
          ...uploadedFiles[index],
          status: FileChipStatus.PENDING,
        },
        ...uploadedFiles.slice(index + 1),
      ])
      await apiRemoveFile({
        id: file.id,
        formType: PackFormType.DAILY_REPORTY_SHEET,
        formId: drsId,
        roleId,
        serialId,
      })
      onChange([
        ...uploadedFiles.slice(0, index),
        ...uploadedFiles.slice(index + 1),
      ])
    } catch (e) {
      alert({
        message: 'Cannot remove file at the moment.'
      })
      onChange([
        ...uploadedFiles.slice(0, index),
        {
          ...uploadedFiles[index],
          status: FileChipStatus.NORMAL,
        },
        ...uploadedFiles.slice(index + 1),
      ])
    }
  }
  const handleFileClick = (file: FileChipFile) => {
    const fileUrl: string = `${BASE_API_URL}/v2/documents/document/${file.physicalName}?${qs.stringify({
      reqRole: roleId,
      reqSid: serialId,
    })}`
    if (!file.physicalName) {
      return
    }
    window.open(fileUrl, '_blank')
  }
  return (
    <Wrapper>
      <FileBox onDropOrSelect={handleFileDropOrSelect} />
      <FileChipsWrapper>
        {[...uploadedFiles].map((file: FileChipFile, index: number) => {
          return <FileChip
            key={file.id}
            file={file}
            removable={true}
            onRemove={handleFileRemove(index)}
            onClick={handleFileClick}
            onRemoveTitle={`Confirm removal of ${file.name}`}
            onRemoveMessage={`This action will remove the file attached to this DRS.`}
          />
        })}
      </FileChipsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const FileChipsWrapper = styled.div`
  margin-top: 8px;
`

export default observer(FieldFileUpload)