// hard code, frontend only
export const ASSET_LOCATION_OPTIONS = [
  { 'id': 'My Depot', 'name': 'My Depot' },
  { 'id': 'My State', 'name': 'My State' },
]
export const CHECKBOX_SELECT_OPTIONS = [
  {
    value: true,
    label: 'YES',
  },
  {
    value: false,
    label: 'NO',
  },
]

export const YES_NO_NULL_SELECT_OPTIONS = [
  {
    value: null,
    label: 'Null',
  },
  {
    value: 'Y',
    label: 'YES',
  },
  {
    value: 'N',
    label: 'NO',
  },
]

export function formatYesNoNullSelect(value: any) {
  if (value) {
    if (value.id === 'Y') {
      return 'YES'
    } else {
      return 'NO'
    }
  } else {
    return ''
  }
}
let  ASPHALT_PROCESS_OPTIONS = [
  
    {id:"1",label: "Supply & Lay",description: "Supply & Lay"},
    {id:"2",label: "Profile & Fill",description: "Profile & Fill"},
    {id:"3",label: "Profile, Key & Overlay",description: "Profile, Key & Overlay"},
    {id:"4",label: "Profile & Patch",description: "Profile & Patch"},
    {id:"5",label: "Repair",description: "Repair"}
  
].map((item: any) => ({ id: item.id, name: item.description }))
let  SPRAY_EXISTING_SURFACE_OPTIONS = [
  
  {id:"Gravel",label: "Gravel",description: "Gravel"},
  {id:"Spray Seal",label: "Spray Seal",description: "Spray Seal"},
  {id:"Asphalt",label: "Asphalt",description: "Asphalt"},
  {id:"Concrete",label: "Concrete",description: "Concrete"},
  {id:"Bridge Deck",label: "Bridge Deck",description: "Bridge Deck"}

].map((item: any) => ({ id: item.id, name: item.description }))

let ASPHALT_LAYER_OPTIONS: any[] = []
export const getAsphaltLayers = () => ASPHALT_LAYER_OPTIONS

let DELAY_REASON_OPTIONS: any[] = []
export const getDelayReasons = () => DELAY_REASON_OPTIONS

let JOB_SPECIFICATION_OPTIONS: any[] = []
export const getJobSpecifications = () => JOB_SPECIFICATION_OPTIONS

let MILL_SIZE_OPTIONS: any[] = []
export const getMillSizes = () => MILL_SIZE_OPTIONS

let MILL_TYPE_OPTIONS: any[] = []
export const getMillTypes = () => MILL_TYPE_OPTIONS

let QUALITY_OF_WORKMANSHIP_OPTIONS: any[] = []
export const getQualityOfWorkmanships = () => QUALITY_OF_WORKMANSHIP_OPTIONS

let SEAL_TYPE_OPTIONS: any[] = []
export const getSealTypes = () => SEAL_TYPE_OPTIONS

let WASTE_REASON_OPTIONS: any[] = []
export const getWasteReturnReasons = () => WASTE_REASON_OPTIONS

let WEATHER_OPTIONS: any[] = []
export const getWeathers = () => WEATHER_OPTIONS


export const getAsphProcess = () => ASPHALT_PROCESS_OPTIONS
export const getExistingSurface = () => SPRAY_EXISTING_SURFACE_OPTIONS

export const setRefBundle = (bundleData: any) => {
  const {
    asphaltLayers,
    assetTypes, // not used
    delayReasons,
    equipmentTypes, // not used
    jobSpecifications,
    millSizes,
    millTypes,
    qualityOfWorkmanships,
    asphProcess,
    rapSuitables, // not used
    reworkTypeReasons,// not used
    spraySealTypes,
    transportTypes,// not used
    wasteReturnReasons,
    weathers,
    workTypes,// not used
  } = bundleData
  ASPHALT_LAYER_OPTIONS = asphaltLayers.map((item: any) => ({ id: item.id, name: item.description }))
  DELAY_REASON_OPTIONS = delayReasons.map((item: any) => ({ id: item.id, name: item.description }))
  JOB_SPECIFICATION_OPTIONS = jobSpecifications.map((item: any) => ({ ...item, id: item.id, name: item.description }))
  MILL_SIZE_OPTIONS = millSizes.map((item: any) => ({ id: item.id, name: `${item.id} ${item.description}` }))
  MILL_TYPE_OPTIONS = millTypes.map((item: any) => ({ id: item.id, name: item.description }))
  QUALITY_OF_WORKMANSHIP_OPTIONS = qualityOfWorkmanships.map((item: any) => ({ id: item.id, name: item.description }))
  SEAL_TYPE_OPTIONS = spraySealTypes.map((item: any) => ({ id: item.id, name: item.description }))
  WASTE_REASON_OPTIONS = wasteReturnReasons.map((item: any) => ({ id: item.id, name: item.description }))
  WEATHER_OPTIONS = weathers.map((item: any) => ({ id: item.id, name: item.description }))
  ASPHALT_PROCESS_OPTIONS = asphProcess.map((item: any) => ({ id: item.id, name: item.description }))
}

// export const ASPHALT_LAYER_OPTIONS = [
//   { 'id': 'Base 1', 'description': 'Base 1' },
//   { 'id': 'Base 2', 'description': 'Base 2' },
//   { 'id': 'Base 3', 'description': 'Base 3' },
//   { 'id': 'Base 4', 'description': 'Base 4' },
//   { 'id': 'Base 5', 'description': 'Base 5' },
//   { 'id': 'Intermediate 1', 'description': 'Intermediate 1' },
//   { 'id': 'Intermediate 2', 'description': 'Intermediate 2' },
//   { 'id': 'Intermediate 3', 'description': 'Intermediate 3' },
//   { 'id': 'Wearing Course', 'description': 'Wearing Course' },
//   { 'id': 'Correction 1', 'description': 'Correction 1' },
//   { 'id': 'Correction 2', 'description': 'Correction 2' },
//   { 'id': 'Correction 3', 'description': 'Correction 3' }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const DELAY_REASON_OPTIONS = [
//   {
//     'id': 'A',
//     'description': 'Approved / Authorised variation to plan',
//     'active': true
//   },
//   {
//     'id': 'BO',
//     'description': 'Boral Equipment breakdown (Other)',
//     'active': true
//   },
//   {
//     'id': 'BP',
//     'description': 'Boral Equipment breakdown (Paver)',
//     'active': true
//   },
//   { 'id': 'CC', 'description': 'Customer changed plan/scope', 'active': true },
//   { 'id': 'CN', 'description': 'Customer no ready', 'active': true },
//   { 'id': 'D', 'description': 'Public delay', 'active': true },
//   { 'id': 'EQ', 'description': 'Equipment move/reposition', 'active': true },
//   { 'id': 'F', 'description': 'Spray Seal/Tack Coat issue', 'active': true },
//   { 'id': 'H1', 'description': 'Waiting to heat (supplier)', 'active': true },
//   { 'id': 'H2', 'description': 'Waiting to heat (sprayer)', 'active': true },
//   { 'id': 'I', 'description': 'Traffic control caused delay', 'active': true },
//   {
//     'id': 'L',
//     'description': 'Late to/from site travel issue',
//     'active': true
//   },
//   {
//     'id': 'MO',
//     'description': 'Waiting for mix mix ordered late',
//     'active': true
//   },
//   {
//     'id': 'MP',
//     'description': 'Waiting for mix plant caused delay',
//     'active': true
//   },
//   {
//     'id': 'MT',
//     'description': 'Waiting for mix trucks caused by delay',
//     'active': true
//   },
//   { 'id': 'P', 'description': 'Profiling issue', 'active': true },
//   {
//     'id': 'PS',
//     'description': 'Planning issue actual scope different to plan',
//     'active': true
//   },
//   { 'id': 'RW', 'description': 'Rework on shift', 'active': true },
//   {
//     'id': 'S',
//     'description': 'Boral employee late/sick/absent',
//     'active': true
//   },
//   {
//     'id': 'SC',
//     'description': 'Subcontractor delay (not profiling)',
//     'active': true
//   },
//   {
//     'id': 'UG',
//     'description': 'Damage to underground services',
//     'active': true
//   },
//   { 'id': 'W', 'description': 'Inclement weather', 'active': true },
//   {
//     'id': 'X',
//     'description': 'Safety or environmental incident',
//     'active': true
//   }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const JOB_SPECIFICATION_OPTIONS = [
//   { 'id': 'A10E', 'description': 'A10E', 'asphalt': true, 'spraySeal': false },
//   { 'id': 'A15E', 'description': 'A15E', 'asphalt': true, 'spraySeal': false },
//   { 'id': 'A25E', 'description': 'A25E', 'asphalt': true, 'spraySeal': false },
//   { 'id': 'C320', 'description': 'C320', 'asphalt': false, 'spraySeal': true }
// ].map((item: any) => ({ ...item, id: item.id, name: item.description }))

// export const MILL_SIZE_OPTIONS = [
//   { 'id': 0.5, 'description': 'Meters' },
//   { 'id': 1.0, 'description': 'Meters' },
//   { 'id': 1.5, 'description': 'Meters' },
//   { 'id': 2.0, 'description': 'Meters' }
// ].map((item: any) => ({ id: item.id, name: `${item.id} ${item.description}` }))

// export const MILL_TYPE_OPTIONS = [
//   { 'id': 'STD', 'description': 'Standard' },
//   { 'id': 'FINE', 'description': 'Fine Mill' }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const QUALITY_OF_WORKMANSHIP_OPTIONS = [
//   { 'id': 'Acceptable', 'description': 'Acceptable', 'active': true },
//   { 'id': 'Unacceptable', 'description': 'Unacceptable', 'active': true }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const SEAL_TYPE_OPTIONS = [
//   { 'id': 1, 'description': 'Prime' },
//   { 'id': 2, 'description': 'PrimerSeal' },
//   { 'id': 3, 'description': 'Reseal' },
//   { 'id': 4, 'description': 'DoubleDouble' },
//   { 'id': 5, 'description': 'SingleDouble' },
//   { 'id': 6, 'description': 'Emulsion' },
//   { 'id': 7, 'description': 'Debond' },
//   { 'id': 8, 'description': 'Multiple' },
//   { 'id': 9, 'description': 'BridgeDecks' }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const WASTE_REASON_OPTIONS = [
//   { 'id': 1, 'description': 'End of day clean up' },
//   { 'id': 2, 'description': 'Client Cause Delay' },
//   { 'id': 3, 'description': 'Other' },
//   { 'id': 4, 'description': 'Over Ordered - Boral' },
//   { 'id': 5, 'description': 'Over Ordered - Client' },
//   { 'id': 6, 'description': 'Quality Issue' },
//   { 'id': 7, 'description': 'Wrong Material Ordered' },
//   { 'id': 8, 'description': 'Wrong Material Supplied' },
//   { 'id': 9, 'description': 'Ramps (not budgeted)' },
//   { 'id': 10, 'description': 'Weather' },
//   { 'id': 11, 'description': 'Mobile Plant Breakdown' },
//   { 'id': 12, 'description': 'Truck Breakdown' }
// ].map((item: any) => ({ id: item.id, name: item.description }))

// export const WEATHER_OPTIONS = [
//   { 'id': 'SHOWERS', 'description': 'Showers', 'active': true },
//   { 'id': 'THUNDERSTORMS', 'description': 'Thunderstorms', 'active': true },
//   { 'id': 'WINDY', 'description': 'Windy', 'active': true },
//   { 'id': 'FINE', 'description': 'Fine', 'active': true },
//   { 'id': 'CLOUDY', 'description': 'Cloudy', 'active': true },
//   { 'id': 'SUNNY', 'description': 'Sunny', 'active': true },
//   { 'id': 'FROST', 'description': 'Frost', 'active': true },
//   { 'id': 'RAIN', 'description': 'Rain', 'active': true }
// ].map((item: any) => ({ id: item.id, name: item.description }))

export const SHIFT_TYPE_OPTIONS = [
  { 'id': 'DAY', 'name': 'Day' },
  { 'id': 'NIGHT', 'name': 'Night' },
]




export const EQUIPMENT_TYPE_OPTIONS = [
  {
    'id': 1,
    'description': 'Skid Steer',
    'boralEquipment': false,
    'contractorEquipment': true
  },
  {
    'id': 2,
    'description': 'Water Cart',
    'boralEquipment': false,
    'contractorEquipment': true
  },
  {
    'id': 3,
    'description': 'Tipper',
    'boralEquipment': false,
    'contractorEquipment': true
  },
  {
    'id': 4,
    'description': 'Suction Sweeper',
    'boralEquipment': false,
    'contractorEquipment': true
  }
].map((item: any) => ({ id: item.id, name: item.description }))

export const ASSET_TYPE_OPTIONS = [
  {
    'id': 'PROFILING',
    'description': 'Profiling',
    'boralAsset': false,
    'contractorAsset': true
  },
  {
    'id': 'GENERAL',
    'description': 'General',
    'boralAsset': false,
    'contractorAsset': true
  }
].map((item: any) => ({ id: item.id, name: item.description }))

export const BINDER_TYPE_OPTIONS = [
  { 'id': 1, 'description': 'AMC0' },
  { 'id': 2, 'description': 'AMC00' },
  { 'id': 3, 'description': 'AMC1' },
  { 'id': 4, 'description': 'AMC2' },
  { 'id': 5, 'description': 'AMC3' },
  { 'id': 6, 'description': 'AMC4' },
  { 'id': 7, 'description': 'AMC5' },
  { 'id': 8, 'description': 'AMC6' },
  { 'id': 9, 'description': 'AMC7' },
  { 'id': 10, 'description': 'C170' },
  { 'id': 11, 'description': 'C240' },
  { 'id': 12, 'description': 'C320' },
  { 'id': 13, 'description': 'CRS' },
  { 'id': 14, 'description': 'CRS60' },
  { 'id': 15, 'description': 'K2 PRIME' },
  { 'id': 16, 'description': 'K2P' },
  { 'id': 17, 'description': 'Multiple' },
  { 'id': 18, 'description': 'QDP' },
  { 'id': 19, 'description': 'S10E' },
  { 'id': 20, 'description': 'S15E' },
  { 'id': 21, 'description': 'S20E' },
  { 'id': 22, 'description': 'S25E' },
  { 'id': 23, 'description': 'S30E' },
  { 'id': 24, 'description': 'S35E' },
  { 'id': 25, 'description': 'S45R' },
  { 'id': 26, 'description': 'Emulsion Prime' },
  { 'id': 27, 'description': 'CRS65' }
].map((item: any) => ({ id: item.id, name: item.description }))
