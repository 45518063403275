import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PanelHeader from './PanelHeader'
import Field from './Field'
import Collapse from '@material-ui/core/Collapse'
import Row from './Row'

interface Panel {
  name: string,
  repetitive?: boolean,
  removable?: boolean,
  index?: number,
  path?: string,
  rows?: any[],
  showDivider?: boolean,
}

const PanelWrapperOutmost = styled.div`
  /* border-bottom: 2px solid #E3E0E0;
  
  
  &:last-child {
    border-bottom: none;
  } */
`
const PanelWrapper = styled.div`
  /* padding-bottom: 30px; */
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #f8f8f8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
`
const Divider = styled.div`
  height: 2px;
  background-color: #E3E0E0;
  margin-top: 30px;
  margin-bottom: 14px;
  margin-left: 20px;
  margin-right: 20px;
`

const Panel = ({
  panel, 
  showDivider = true,
  onFieldChange = () => {},
  children,
  onPanelRemove = () => {},
  onPanelAdd = () => {},
}: {
  panel: Panel | null, 
  showDivider?: boolean,
  onFieldChange?(field: { path: string }, value: any): void,
  children?: any,
  onPanelRemove?(path: string, index: number): void,
  onPanelAdd?(path: string, index: number): void,
}) => {
  const [ isCollapsed, setIsCollapsed ] = useState<any>(false)
  
  if (!panel || !panel.rows || !panel.rows.length) {
    // do not render the whole panel if no field
    return null
  }

  const content = <Collapse in={!isCollapsed}>
    { 

      panel.rows.map((row, index) => {
        return <Row key={row.name} row={row} onFieldChange={onFieldChange}/>
      })
    }
  </Collapse>

  const handlePanelRemove = () => {
    onPanelRemove(panel.path || '', panel.index || -1)
  }
  const handlePanelAdd = () => {
    onPanelAdd(panel.path || '', panel.index || -1)
  }
  return <PanelWrapperOutmost key={panel.name} >

    <PanelWrapper>

      <PanelHeader
        name={panel.name}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        fieldsCompleted={true}
        repetitive={panel.repetitive}
        onAdd={handlePanelAdd}
        onRemove={handlePanelRemove}
        removable={panel.removable}
        />

      { content }

    </PanelWrapper>

    { showDivider && <Divider /> }

  </PanelWrapperOutmost>
}



export default observer(Panel)