export enum DepotType {
  ASPHALT = 'ASPHALT',
  SPRAYSEAL = 'SPRAYSEAL',
}

export function formatDepotType(type: DepotType): string {
  if (type === DepotType.ASPHALT) {
    return 'Asphalt'
  } else {
    return 'Spray Seal'
  }
}


export enum EmployeeType {
	BORAL = 'Boral',
	LABOUR = 'LABOUR',
}

export function formatEmployeeType(type: EmployeeType): string {
	if (type === EmployeeType.BORAL) {
	  return 'Boral'
	} else {
	  return 'Labour Hire'
	}
  }
  