import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'


interface Props {
  onClick(): void,
  children?: React.ReactNode,
}
const CompDeleteButton = (props: Props) => {
  const {
    onClick,
    children,
  } = props
  return <Wrapper
       
    onClick={onClick}
  >
    { children }
  </Wrapper>
}

const Wrapper = styled(Button)`
  color: #fff;
  background-color: red;
  margin-left:3px;
` as typeof Button

export default CompDeleteButton