import React from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FolderIcon from '@material-ui/icons/Folder'
import EditIcon from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import usePermission from '@src/utils/hooks/usePermission'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useCrewCalender } from '../StoreCrewCalender'

interface Props {
  anchorEl: null | HTMLElement,
  onClose(): void,
  onEdit?(): void,
  onOpenCrewpack?(): void,
  onOpenDRS?(): void,
  onOpenDuplicateDRS?(): void,
  onDelete?(): void,
  onDeleteMultiple?(): void,
  onDuplicate?(): void,
  onCardOder?(type: string): void,
  selectedShifts?:[]
}

const ShiftMoreActionsMenu = (props: Props) => {
  const {
    anchorEl = null,
    onClose,
    onEdit = () => { },
    onOpenCrewpack = () => { },
    onOpenDRS = () => { },
    onOpenDuplicateDRS = () => { },
    onDelete = () => { },
    onDeleteMultiple = () => { },
    onDuplicate = () => { },
    onCardOder = (type: string) => { },
    selectedShifts =[]
  } = props
  let menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()
  const { canCreateShift, canEditShift } = usePermission()
  const { isCalendarControlsDisabled } = useCrewCalender()
  const { alert } = useSnackbar()

  const handleCard = (targetOrderBy: any) => () => {
    onCardOder(targetOrderBy);
  }
  const beforeEdit = async (e: React.SyntheticEvent<any>) => {
    onEdit()
  }
  const beforeOpenCrewpack = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onOpenCrewpack()
  }
  const beforeOpenDRS = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onOpenDRS()
  }
  const beforeDuplicateDRS = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onOpenDuplicateDRS()
  }
  const beforeDelete = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    try {
      if (isCalendarControlsDisabled) {
        alert({
          type: 'error',
          message: `Shifts can't be deleted while the Calendar is updating.`
        })
        onClose()
        return
      }

      await confirm({
        title: 'Confirm Deletion of Shift and DRS Data',
        message: 'This will permanently delete any saved DRS information from the system and cannot be undone.',
      })
      onDelete()
    } catch (e) { }
  }
  const beforeDeleteMultiple = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    try {
      if (isCalendarControlsDisabled) {
        alert({
          type: 'error',
          message: `Shifts can't be deleted while the Calendar is updating.`
        })
        onClose()
        return
      }

      await confirm({
        title: 'Confirm Deletion of Multiple Shift and DRS Data',
        message: 'This will permanently delete any saved DRS information from the system and cannot be undone.',
      })
      onDeleteMultiple()
    } catch (e) { }
  }
  
  const beforeDuplicate = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onDuplicate()
  }
  return <Wrapper>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={onClose}
    >
      <MenuItem onClick={beforeEdit} disabled={!canEditShift}>
        <SListItemIcon>
          <EditIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Edit Shift Info</Typography>
      </MenuItem>
      <MenuItem onClick={beforeOpenCrewpack}>
        <SListItemIcon>
          <FolderIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Open Crewpack</Typography>
      </MenuItem>
      <MenuItem onClick={beforeOpenDRS}>
        <SListItemIcon>
          <FolderIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Open DRS</Typography>
      </MenuItem>
      <MenuItem onClick={beforeDuplicate} disabled={!canCreateShift}>
        <SListItemIcon>
          <FileCopyIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Duplicate</Typography>
      </MenuItem>
      <MenuItem onClick={beforeDuplicateDRS} disabled={!canCreateShift}>
        <SListItemIcon>
          <FileCopyIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Copy DRS Section</Typography>
      </MenuItem>
      <MenuItem onClick={beforeDelete} disabled={!canEditShift}>
        <SListItemIcon>
          <SDeleteIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Delete Shift</Typography>
      </MenuItem>
      { selectedShifts.length > 1 && <MenuItem onClick={beforeDeleteMultiple} disabled={!canEditShift}>
        <SListItemIcon>
          <SDeleteIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Delete Multiple Shift</Typography>
      </MenuItem> }
      <MenuItem disabled={!canEditShift}>
        <SListItemIcon onClick={handleCard('plus')} >
          <Add fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Card Order</Typography>
        <SListItemIcon style={{ marginLeft: '12%' }} onClick={handleCard('minus')}>
          <RemoveCircleOutline fontSize='small' style={{ color: 'red' }} />
        </SListItemIcon>
      </MenuItem>
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
`

export default ShiftMoreActionsMenu