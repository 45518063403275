import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

interface Props {
  onClick(): void,
  children?: React.ReactNode,
}
const FieldCreateButton = (props: Props) => {
  const {
    onClick,
    children,
  } = props
  return <Wrapper
    startIcon={<AddCircleIcon />}
    onClick={onClick}
  >
    { children }
  </Wrapper>
}

const Wrapper = styled(Button)`
  color: #009688;
  background-color: #E7F4F2;
  margin-left: 10px;
  margin-top: 20px;
` as typeof Button

export default FieldCreateButton