import React from 'react'
import styled from 'styled-components'
import CompFlatTable from '@src/modules/SiteResoureRequirements/comps/FlatTable'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useAppStore } from '@src/AppStore'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import useModal from '@src/components/FormModal/useModal'
import { SRRFloat } from '@src/types/SRR'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import Modal from '@src/components/FormModal/Modal'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { apiSrrFloatUpdate } from '@src/apis/srr-float-update'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const MachineFloat = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.machine.float.float
  const handleSave = async (form: any) => {
    console.log(form)
    try {
      const result = await apiSrrFloatUpdate({
        srrId: srr.id, 
        serialId,
        partial: {
          ...form,
        }
      })
      alert({
        message: `Done.`
      })
      refresh()
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  } 
  return <Wrapper>
    <FormPanel
      title={`Float`}
    >
      <CompFlatTable<SRRFloat>
        data={data}
        fields={SRRFloatFields}
        editable={canEditSubmitSRR}
        onEdit={() => {
          openAndSetData(data)
        }}
      />
      { open && <Modal<SRRFloat>
        maxWidth='md'
        open={open}
        title='Float Information'
        data={{
          rowData: modalData,
          fields: SRRFloatFields,
          columns: [
            {
              title: 'Geography',
              fieldKeys: [
                'parkingStreet',
                'parkingCrossStreet',
                'parkingSuburb',
                'parkingMapReference',
              ],
            },
            {
              title: 'Float Details',
              fieldKeys: [
                'floatWithinShift',
                'permitRequired',
                'pilotRequired',
              ],
            },
            {
              title: 'Requirements',
              fieldKeys: [
                'specialRequirements',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
export const SRRFloatFields: FormTableField<SRRFloat>[] = [
  {
    key: 'parkingStreet',
    type: FormTableFieldType.Text,
    label: 'Equipment Parking - Street',
  },
  {
    key: 'parkingCrossStreet',
    type: FormTableFieldType.Text,
    label: 'Equipment Parking -Cross Street',
  },
  {
    key: 'parkingSuburb',
    type: FormTableFieldType.Text,
    label: 'Equipment Parking - Suburb',
  },
  {
    key: 'parkingMapReference',
    type: FormTableFieldType.Text,
    label: 'Equipment Parking - Map Reference',
  },
  {
    key: 'floatWithinShift',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Float within Shift',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'permitRequired',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Permit Required',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'pilotRequired',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Pilot Required',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'specialRequirements',
    type: FormTableFieldType.Textarea,
    label: 'Special Requirements',
  },
]

export default MachineFloat