import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { FloatActionButton } from '../../components'
import { AssetMgmtHeader } from '../MgmtLabour/MgmtLabourPage'
import MobileAssetTable from './parts/MobileAssetTable'
import usePermission from '@src/utils/hooks/usePermission'
import Breadcrumbs from '@src/components/Breadcrumbs'
import useModal from '../../components/FormModal/useModal'
import Modal from '../../components/FormModal/Modal'
import MobileAssetFields, { MobileAsset } from './fields'
import { apiMobileAssetsCreate } from '@src/apis/mobile-asset-create'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiMobileAssetsUpdate } from '@src/apis/mobile-asset-update'
import { useRSMgmtStore } from '../MgmtLabour/Store'
import { useMobileAssetsFilteredByDepot } from './useMobileAssetsFilteredByDepot'
import { observer } from 'mobx-react-lite'
import { FormControl, TextField } from '@material-ui/core'


interface Props {}

function MgmtMobileAssetPage (props: Props) {
  const { canAddMobileAssetsInMgmt } = usePermission()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<MobileAsset>({})
  const { alertDone, alertError, alert } = useSnackbar()
  const { depot, active } = useRSMgmtStore()
  const [ assets, refreshAssets ] = useMobileAssetsFilteredByDepot(depot, active)

  const [ searchTerm, setSearchTerm] = useState<String>('');
  const handleAddNewAsset = () => {
    openAndSetData(null)
  }
  const handleEditAsset = (asset: any) => {
    openAndSetData(asset)
  }
  const onSearchChange = async (searchterm: any) => {
    setSearchTerm(searchterm);
    //console.log(searchterm)
  }
  
  const handleSave = async (asset: MobileAsset) => {
    // validation
    if (!asset.type) {
      alert({
        message: `Asset Type required.`,
        type: 'error',
      })
      return
    }
    if (!asset.costingSystemAssetNumber) {
      alert({
        message: `Costing System Asset Number required.`,
        type: 'error',
      })
      return
    }
    if ((/\s/).test(asset.costingSystemAssetNumber)) {
      alert({
        message: `Costing System Asset Number has a blank space.`,
        type: 'error',
      })
      return
    }
    if (!asset.nextServiceDue && asset.nextServiceDue.toString()!='0') {
      alert({
        message: `Next Service Due required.`,
        type: 'error',
      })
      return
    }
    if (!asset.id) {
      alert({
        message: `Asset Number required.`,
        type: 'error',
      })
      return
    }
    if ((/\s/).test(asset.id)) {
      alert({
        message: `Asset Number has a blank space.`,
        type: 'error',
      })
      return
    }
    if (!asset.description) {
      alert({
        message: `Asset Description required.`,
        type: 'error',
      })
      return
    }
    if (!asset.depot) {
      alert({
        message: `Depot required.`,
        type: 'error',
      })
      return
    }
    const finalAssetId = asset.id.replace(/\s/g , '')
    if (modalData === null) {
      try {
        const result = await apiMobileAssetsCreate({
          ...asset,
          id: finalAssetId,
          active: true,
        })
        alertDone()
        refreshAssets()
        closeModal()
      } catch(e) {
        alertError()
      }
    } else {
      try {
        const result = await apiMobileAssetsUpdate({
          ...asset,
          id: finalAssetId,
        })
        alertDone()
        refreshAssets()
        closeModal()
      } catch(e) {
        alertError()
      }
    }
  }
  return <Container maxWidth='xl'>
    <Breadcrumbs items={[{ type: 'text', text: `Mobile Asset Management`}]}/>
    <AssetMgmtHeader>ASSET MANAGEMENT: MOBILE ASSETS</AssetMgmtHeader>
    <MainWrapper>
    <SearchField>
    <FormControl fullWidth>
    <TextField id="searchtext" label="Search Assets" variant="outlined" fullWidth={true}  value={searchTerm}
      onChange={(e: SyntheticEvent) => onSearchChange((e.target as HTMLInputElement).value)} />
   
  </FormControl>
    </SearchField>
   
      <MobileAssetTable
        assets={assets}
        searchTerm={searchTerm}
        onEditAsset={handleEditAsset}
        onSuccess={refreshAssets}
      />

    </MainWrapper>
    { open && 
      <Modal<MobileAsset>
        maxWidth={'md'}
        open={open}
        title='Mobile Asset'
        data={{
          rowData: modalData,
          fields: MobileAssetFields,
          columns: [
            {
              title: 'Asset Details',
              fieldKeys: [
                'type', 
                'costingSystemAssetNumber', 
                'nextServiceDue', 
              ],
            },
            {
              title: '',
              fieldKeys: [
                'id', 
                'description', 
                'depot', 
              ],
            },
            {
              title: 'Asset Crew',
              fieldKeys: [
                'crews', 
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
    { canAddMobileAssetsInMgmt && 
      <FloatActionButton
        tooltipText='Add New Mobile Asset'
        onClick={handleAddNewAsset}
      />
    }
  </Container>
}

const MainWrapper = styled.div`
  margin: 40px 50px;
`
const SearchField = styled.div`
  float:left;
  width:270px;
  margin-bottom:15px
`

export default observer(MgmtMobileAssetPage)