import axios from 'axios'
import { DEVICE_LIST } from '../constants'

export const apiDeviceList = (token: any) => {
  const options = {
    method: 'GET',
    headers: { 
      'Authorization': `Bearer ${token}`
    },
    url: DEVICE_LIST,
  }
  return axios(options as any)
}