import { useState, useEffect } from 'react'
import { Tab } from './Tabs'
import { ProjectTypeId } from '@src/types/ProjectTypeId'

export function useTabs(initialTabs: Tab[]): [ number, Tab[], typeof handleTabChange, typeof setTabsFunc ] {
  const [ tabs, setTabs ] = useState<Tab[]>(initialTabs)
  const [ activeIndex, setActiveIndex ] = useState<number>(0)
  function getTabsIdentity(tabs: Tab[]) {
    return tabs.map((tab: Tab) => tab.key).join(' ')
  }
  function setTabsFunc (newTabs: Tab[]) {
    if (getTabsIdentity(newTabs) === getTabsIdentity(tabs)) {
      // if it's the same tabs, do nothing
    } else {
      setActiveIndex(0)
      setTabs(newTabs)
    }
  }
  function handleTabChange(tab: Tab, index: number) {
    setActiveIndex(index)
  }
  return [ activeIndex, tabs, handleTabChange, setTabsFunc ]
}

// const [ tabIndex, tabs, handleTabChange ] = useTabs()

const ASTab: Tab = {
  key: ProjectTypeId.Asphalt,
  label: 'Asphalt',
}
const SSTab: Tab = {
  key: ProjectTypeId.SpraySeal,
  label: 'Spray Seal',
}

export const ASSSTabs: Tab[] = [ ASTab, SSTab ]

export function getTabsByType(id: ProjectTypeId) {
  return {
    'ASPHALT': [ ASTab ],
    'SPRAY-SEAL': [ SSTab ],
    'ASPHALT-SPRAY-SEAL': [ ASTab, SSTab ],
  }[id]
}