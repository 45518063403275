import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType, FormPAETableInteralData } from '@src/components/Form/PAETable'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { useDRS } from '@src/modules/DRS/useDRS'
import useModal from '@src/components/FormModal/useModal'
import Modal from '@src/components/FormModal/Modal'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { DRSSectionName, DRSPageType } from '@src/modules/DRS/DRSPage'
import { PAE } from '@src/types/drs/PAE'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'

interface ShiftLocationDetail {
  streetName: string,
  nearestCrossStreet: string,
  suburb: string,
  kmPlantToDepot: string,
}
const SHIFT_LOCATION_DETAIL_NULL_VALUES = {
  streetName: null,
  nearestCrossStreet: null,
  suburb: null,
  kmPlantToDepot: null,
}
const SHIFT_LOCATION_DETAIL_ZERO_VALUES = {
  streetName: '',
  nearestCrossStreet: '',
  suburb: '',
  kmPlantToDepot: 0,
}

const Fields: FormTableField<ShiftLocationDetail>[] = [
  {
    key: 'streetName',
    label: 'Street',
    type: FormTableFieldType.Text,
  },
  {
    key: 'nearestCrossStreet',
    label: 'Nearest Cross Street',
    type: FormTableFieldType.Text,
    length: 200,
    helperTextError: 'Max 200 character allow'
  },
  {
    key: 'suburb',
    label: 'Suburb',
    type: FormTableFieldType.Text,
  },
  {
    key: 'kmPlantToDepot',
    label: 'Kms from Plant',
    type: FormTableFieldType.Number,
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
]

const PartDRSShiftLocationDetails = () => {
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const [open, modalData, openAndSetData, closeModal] = useModal<ShiftLocationDetail>({})
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const { alertError, alertDone } = useSnackbar()
  if (drs === null) {
    return null
  }

  const handleSave = async (form: ShiftLocationDetail) => {
    const jobDetails = drs.jobDetails
    const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
    const data = {
      ...jobDetails[updatingColumn],
      ...form,
    }
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Job,
      path: `${updatingColumn}`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const handleRevert = async (form: ShiftLocationDetail) => {
    const jobDetails = drs.jobDetails
    const updatingColumn = PAE.Edit
    const data = {
      ...jobDetails[updatingColumn],
      ...SHIFT_LOCATION_DETAIL_NULL_VALUES,
    }
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Job,
      path: `${updatingColumn}`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const handleMarkZero = async (form: ShiftLocationDetail) => {
    const jobDetails = drs.jobDetails
    const updatingColumn = PAE.Edit
    const data = {
      ...jobDetails[updatingColumn],
      ...SHIFT_LOCATION_DETAIL_ZERO_VALUES,
    }
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Job,
      path: `${updatingColumn}`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  function getTableData() {
    if (drs === null) {
      return []
    }
    const mappedPAEData = {
      id: drs.jobDetails.id,
      plan: {
        streetName: drs.jobDetails.plan.streetName,
        nearestCrossStreet: drs.jobDetails.plan.nearestCrossStreet,
        suburb: drs.jobDetails.plan.suburb,
        kmPlantToDepot: drs.jobDetails.plan.kmPlantToDepot,
      },
      actual: {
        streetName: drs.jobDetails.actual.streetName,
        nearestCrossStreet: drs.jobDetails.actual.nearestCrossStreet,
        suburb: drs.jobDetails.actual.suburb,
        kmPlantToDepot: drs.jobDetails.actual.kmPlantToDepot,
      },
      edit: {
        streetName: drs.jobDetails.edit.streetName,
        nearestCrossStreet: drs.jobDetails.edit.nearestCrossStreet,
        suburb: drs.jobDetails.edit.suburb,
        kmPlantToDepot: drs.jobDetails.edit.kmPlantToDepot,
      },
    }
    function isEditAvailableFunc(actual: any, edit: any) {
      if (edit.streetName !== null
        || edit.nearestCrossStreet !== null
        || edit.suburb !== null
        || edit.kmPlantToDepot !== null
      ) {
        return true
      }
      return false
    }
    const doubled = doubleUp(
      [mappedPAEData],
      drsPageType,
      isEditAvailableFunc,
    )
    return doubled.map((data: any) => {
      if (data._type_ === PAETableRowType.Second) {
        return {
          ...data,
          _more_actions_: [
            PAETableMoreActionType.Revert,
            // PAETableMoreActionType.MarkZero,
          ],
        }
      }
      return data
    })
  }
  return <Wrapper>
    <FormPanel title={`Shift Location Details`}>
      <FormPAETable<any>
        fields={Fields}
        data={getTableData()}
        editable={true}
        onEditRow={(form: ShiftLocationDetail) => {
          openAndSetData(form)
        }}
        showPAEType={!isPlan}
        showMoreActions={isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        isSelectable={true}
      />
    </FormPanel>
    {open &&
      <Modal<ShiftLocationDetail>
        maxWidth={'xs'}
        open={open}
        title='Shift Location Details'
        data={{
          rowData: modalData,
          fields: Fields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'streetName',
                'nearestCrossStreet',
                'suburb',
                'kmPlantToDepot',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default PartDRSShiftLocationDetails