import React from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Notifications } from './modules'
import FourOFour from './modules/_ErrorPages/404'
import { ProjectDetailPage } from './modules/ProjectDetail'
import { MgmtLabourPage } from './modules/MgmtLabour'
import { MgmtCrewPage } from './modules/MgmtCrew'
import MgmtMobileAssetPage from './modules/MgmtMobileAsset/MgmtMobileAssetPage'
import DashboardPage from './modules/Dashboard/DashboardPage'
import ProjectCrewPackPage from './modules/CrewPack/CrewPackDetailPage'
import ProjectSifPage from './modules/SiteInspectionForm/SIFDetailPage'
import SRRPage from './modules/SiteResoureRequirements/SRRPage'
import MAPListPage from './modules/MobileAssetPrestarts/ShiftPrestartListPage/MAPListPage'
//import MAPDetailPage from './modules/MobileAssetPrestarts/DetailPage/MAPDetailPage'
import MobileAssetDetailPage from './modules/MobileAsset/MobileAssetDetailPage'
import DRSPage, { DRSPageType } from './modules/DRS/DRSPage'
//import Demo from './modules/VehicleMovementPlan/DrawingDemo'
//import CompDemo2 from './modules/VehicleMovementPlan/parts/drawing-tools/DrawingTool'
import VMPDetailPage from './modules/VehicleMovementPlan/VMPDetailPage'
import DeviceInfoPage from './modules/DeviceInfo/DeviceInfoPage';
import DeviceRawData from './modules/DeviceInfo/parts/DeviceRawData';
import CrewPackPhotos from './modules/CrewPack/comps/CrewPackPhotos'
import ApprovalDetailPage from './modules/Approval/ApprovalDetailPage'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' render={props => {
        return <Redirect to='/dashboard/projects' />
      }} />


      <Route exact path='/dashboard/:part' component={DashboardPage} />


      <Route exact path='/mgmt/labour' component={MgmtLabourPage} />
      <Route exact path='/mgmt/crew' component={MgmtCrewPage} />
      <Route exact path='/mgmt/mobile-asset' component={MgmtMobileAssetPage} />


      <Route path='/notifications' component={Notifications} />

      <Route exact path='/project/:id' render={props => {
        // redirect to crew-pack tab
        return <Redirect to={`/project/${props.match.params.id}/crew-packs`} />
      }} />
      {/* <Route path='/project/:id' component={ProjectDetailPage} /> */}
      <Route path='/project/:id/:part' component={ProjectDetailPage} />

      <Route path='/crew-pack/:id' component={ProjectCrewPackPage} />
      <Route path='/sif/:id' component={ProjectSifPage} />

      {/* srr */}
      <Route path='/srr/:id' component={SRRPage} />

      {/* map */}
      <Route path='/map-list/:shiftId' component={MAPListPage} />
      {/* <Route path='/prestart/:id' component={MAPDetailPage} />  generate pdf instead*/}

      {/* mobile asset */}
      <Route path='/mobile-asset/:id' component={MobileAssetDetailPage} />

      <Route path='/vmp/:id' component={VMPDetailPage} />

      <Route path='/drs/plan/:id' render={() => <DRSPage type={DRSPageType.Plan} />} />
      <Route path='/drs/edit/:id' render={() => <DRSPage type={DRSPageType.Edit} />} />
      <Route path='/drs/review/:id' render={() => <DRSPage type={DRSPageType.Review} />} />
      {/* <Route path='/drs/:projectId' component={DRS} /> */}

      <Route path='/vmp/:id' component={VMPDetailPage} />
      <Route path='/approval/:drsId/:id' component={ApprovalDetailPage} />
      {/* device info */}
      <Route path='/device/info/:id' component={DeviceInfoPage} />
      <Route path='/device/rawdata/:id' component={DeviceRawData} />
      <Route path='/crewpack/photos/:crewPackId/:projectInternalId' component={CrewPackPhotos} />
      <Route component={FourOFour} />
    </Switch>
  )
}

export default observer(Routes)