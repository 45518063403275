import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import ProjectCard from './ProjectCard'
import { Project } from '@src/types/project/Project'
import { ProjectGroup } from '@src/types/project/ProjectGroup'

const ProjectGroupComp = ({
  projectGroup,
  afterStatusChanged = () => {},
}: {
  projectGroup: ProjectGroup,
  afterStatusChanged?(project: Project): void,
}) => {
  const { id, name, projects } = projectGroup
  return <Wrapper>
    <GroupName>{`${name} Depot`}</GroupName>
    { 
      projects.map((project, index) => (
        <ProjectCard 
          key={project.projectInternalId} 
          project={project} 
          afterStatusChanged={afterStatusChanged}
        />
      ))
    }
  </Wrapper>
}

const Wrapper = styled.div`
`
const GroupName = styled(Typography)`
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
`

export default ProjectGroupComp