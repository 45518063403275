import React from 'react'
import styled, { css } from 'styled-components'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'

const TableCell = styled(TableCellOrigin)<{ width: number }>`
  font-size: 12px;
  padding: 4px 4px;
  border-bottom: 0;
  color: #9A9898;
  ${props => {
    if (props.width) {
      return css`
        width: ${props.width};
      `
    }
  }}
` as any

export default TableCell
