import React, { useState, useContext, useEffect } from 'react'
import './App.css'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Breadcrumbs from './layout/Breadcrumbs'
import ThemeProvider from './ThemeProvider'
import Header from './layout/Header/Header'
import { GlobalSnackbar } from './components/GlobalSnackbar'
import Routes from './AppRoutes'
import useLoginEffect from './utils/login-flow/useLoginEffect'
import { useAppStore } from './AppStore'

import { GlobalConfirmDialog } from './components/GlobalConfirmDialog'
// import { Welcome } from './components/Welcome'

const App = observer(() => {
  const { loggedIn } = useAppStore()

  useLoginEffect()

  if (!loggedIn) {
    return null
  }
  /** Juicy detail starts from here. */
  return (
    <Router>
      <React.Fragment>
     
        <Route component={Header}></Route>
        <Route component={Breadcrumbs}></Route>
        <Routes></Routes> 
        <GlobalSnackbar />
        <GlobalConfirmDialog />
      </React.Fragment>
    </Router>
  )
})

/**
 * cannot use global context before we provide it
 */
const Wrapper = () => {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  )
}

export default Wrapper