import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import { SRRTransportAdditionalInfoFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/1-Transport/AdditionalTransportInfo'

interface Props {

}

const CompPreviewTransportAdditionalInfo = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  return <Wrapper>
    <CompPreviewSection 
      name={`Time & Delivery Rate`}
    >
      <CompFlatFields
        value={srr.machine.transport.additionalInformation}
        fields={SRRTransportAdditionalInfoFields}
      />
    </CompPreviewSection>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompPreviewTransportAdditionalInfo