import React from 'react'
import styled from 'styled-components'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'


interface Props {
  label: string,
  value: any[],
  onChange(value: any[],isChecked: boolean,currentName: string): void,
  options?: {value: string, label: string}[],
  disabled?: boolean,
}

const CustomCheckboxGroup = (props: Props) => {
  const {
    label,
    value,
    options = [],
    onChange,
    disabled,
  } = props
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let final: typeof value = value.slice()
    let isChecked: boolean = false;
    // console.log(`-----`);
    // console.log(event.target.name);
    // console.log(event.target.checked);
    // console.log(`-----`);
    if (!event.target.checked) {
      final.splice(final.findIndex((item: any) => item === event.target.name), 1)
      isChecked = false;
    } else {
      final.push(event.target.name)
      isChecked = true;
    }
    onChange(final,isChecked,event.target.name)
  }

  return <FormControl component='fieldset'>
    <FormLabel component='legend'>{label}</FormLabel>
    <FormGroup>
      { options.map((option) => {
          return <FormControlLabel
          disabled={disabled}
          key={option.value}
          label={option.label}
          control={
            <Checkbox 
              checked={value.some(valueItem => valueItem === option.value)} 
              onChange={handleChange}
              name={option.value} 
            />
          }
        />
      })}
    </FormGroup>
    {/* <FormHelperText>You can display an error</FormHelperText> */}
  </FormControl>
}

const Wrapper = styled.div`

`

export default CustomCheckboxGroup