import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { Profile } from './types/common/Profile'
import { Role } from './types/common/Role'
import { RoleId } from './types/common/RoleId'
import { ADRegionDepot } from '@src/api-adaptors/regionDepot'

interface AppStore {
  appStoreRefreshHook: number,
  goRefreshAppStore(): void,
  loggedIn: boolean,
  setLoggedIn(val: boolean): void,
  setCopiedDRS(val: any): void,
  profile?: Profile,
  setProfile(profile: Profile): void,
  firstName: string,
  lastName: string,
  serialId: number,
  userEmail: string,
  username: string,
  state: AIMS.State,
  stateId: string,
  stateName: string,
  region: AIMS.Region,
  regionId: string,
  regionName: string,
  regionDepot: any,
  depot: AIMS.Depot,
  depotPayroll: AIMS.Depot,
  depotId: string,
  depotName: string,
  isUserSettingsOpen: boolean,
  setUserSettingsOpen(val: boolean): void,
  setDepot(val: AIMS.Depot): void,
  setDepotPayroll(val: AIMS.Depot): void,
  setRegion(val: AIMS.Region): void,
  setState(val: AIMS.State): void,
  roles: Role[],
  setRoles(roles: Role[]): void,
  defaultRoleId: RoleId,
  copiedDRS: any,
}

export const store: AppStore = observable<AppStore>({
  appStoreRefreshHook: 0,
  goRefreshAppStore: action(() => store.appStoreRefreshHook++),
  loggedIn: false,
  copiedDRS: {},
  setCopiedDRS: action((val: any) => {
    store.copiedDRS = val
  }),
  setLoggedIn: action((val: any) => {
    store.loggedIn = val
  }),
  setUserSettingsOpen: action((val: boolean) => {
    store.isUserSettingsOpen = val;
  })
  ,
  setProfile: action((profile: Profile) => {
    store.profile = profile
    const { firstName, lastName, state, region, depot, depotPayroll, roles, email, serialId } = profile
    store.firstName = firstName
    store.lastName = lastName
    store.state = state
    store.region = region
    store.regionDepot = {
      label: depot.name,
      value: {
        depotId: depot.id,
        depotName: depot.name,
        regionId: region.id,
        regionName: region.name
      }
    }
    store.depot = depot
    store.depotPayroll = depotPayroll;
    store.userEmail = `${email}`
    store.serialId = serialId
    store.setRoles(roles)
  }),
  firstName: '',
  lastName: '',
  serialId: 0,
  userEmail: '',
  isUserSettingsOpen: false,
  get username() {
    return `${store.firstName} ${store.lastName}` // First Last
  },

  state: { id: '', name: '' },
  get stateId() {
    return store.state.id
  },
  get stateName() {
    return store.state.name
  },

  region: { id: '', name: '' },
  get regionId() {
    return store.region.id
  },
  get regionName() {
    return store.region.name
  },

  regionDepot: { id: '', name: '' },

  depot: { id: '', name: '' },
  setDepot(val: AIMS.Depot) {
    store.depot = val
  },
  depotPayroll: { id: '', name: '' },
  setDepotPayroll(val: AIMS.Depot) {
    store.depot = val
  },
  setRegion(val: AIMS.Region) {
    store.region = val
  },
  setState(val: AIMS.State) {
    store.state = val
  },
  get depotId() {
    return store.depot.id
  },
  get depotName() {
    if (store.depot.name) {
      let splitted = store.depot.name.split('-')
      return splitted[splitted.length - 1]
    }
    return ''
  },

  roles: [],
  setRoles(roles: Role[]) {
    store.roles = roles
  },
  get defaultRoleId() {
    if (store.roles && store.roles.length) {
      let defaultRole = store.roles.find((role) => role.default)
      if (defaultRole) {
        return defaultRole.id
      }
    }
    return RoleId.ResourceScheduler
  }
})

const context: React.Context<AppStore> = React.createContext<AppStore>(store)

export const useAppStore = () => {
  return useContext<AppStore>(context)
}