import React, { useState } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useAppStore } from '@src/AppStore'
import { apiDepotNoteCreate } from '@src/apis/depot-note-create'
import moment, { Moment } from 'moment'
import DatePicker from './NoteDatePicker'

interface Props {
  open: boolean,
  noteId: string,
  content: string,
  colorCode: string,
  onClose(): void,
  onSave(): void,
  currentDate: string,
  dateModified?: string,
  createdBy?: string,
}

const DuplicateNoteDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
    content,
    colorCode,
    createdBy
  } = props

  const { alertDone, alertError } = useSnackbar()
  const [isSaving, setIsSaving] = useState<any>(false)
  const [dates, setDates] = useState<Moment[]>([])
  const [date, setDate] = useState<Moment>(moment())
  const { username, depotId } = useAppStore()

  const handleSave = async () => {

    const sleep = (ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
    setIsSaving(true)
    for (let date of dates) {
      await saveDuplicate(moment(date).format('YYYY-MM-DD'));
      await sleep(1500)
    }
    alertDone()
    setIsSaving(false)
    onSave();
  }
  const saveDuplicate = async (noteDate: any) => {
    try {
      const response = await apiDepotNoteCreate({
        Comment: content,
        RGBColorCode: colorCode,
        DepotID: depotId,
        Date: noteDate,
        CreatedBy: createdBy as string,
        Active: true,
      })
    } catch (e) {
      alertError()
    }
  }
  return <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
    <DialogTitle id='form-dialog-title'>{`Duplicate Note`}</DialogTitle>

    <DialogContent>
      <DatePicker
        variant='static'
        value={date}
        selectedItems={dates}
        onChange={date => {
          const index = dates.findIndex(item => {
            return moment(date).isSame(moment(item), 'date')
          })
          setDate(moment(date))
          if (index !== -1) {
            setDates([...dates.slice(0, index), ...dates.slice(index + 1)])
          } else {
            setDates([...dates, date])
          }
        }}
      />
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={handleSave} color='primary'>
        {isSaving && <React.Fragment><Spinner size={16} /> {`Duplicating`} </React.Fragment>}
        {!isSaving && 'Duplicate '}
      </Button>
    </DialogActions>
  </Dialog>

}


const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`

export default DuplicateNoteDialog