import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import UndoIcon from '@material-ui/icons/Undo'
import DeleteIcon from '@material-ui/icons/Delete'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import FolderIcon from '@material-ui/icons/Folder'
import EditIcon from '@material-ui/icons/Edit'
import usePermission from '@src/utils/hooks/usePermission'
import Button from '@material-ui/core/Button';

interface Props {
  anchorEl: null | HTMLElement,
  onClose(): void,
  onOpenDrs?(): void,
  onOpenCrewpack?(): void,
}

const ShiftShortActionsMenu = (props: Props) => {
  const {
    anchorEl = null,
    onClose,
    onOpenDrs = () => { },
    onOpenCrewpack = () => { },
  } = props
  const menuOpen = Boolean(anchorEl)
  const beforeOpenDrs = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onOpenDrs()
  }
  const beforeOpenCrewpack = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onOpenCrewpack()
  }
  return <Wrapper>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={onClose}
    >
      <MenuItem onClick={beforeOpenDrs} >
        <SListItemIcon>
          <FolderIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Open DRS</Typography>
      </MenuItem>
      <MenuItem onClick={beforeOpenCrewpack}>
        <SListItemIcon>
          <FolderIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Open Crewpack</Typography>
      </MenuItem>
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
`

export default ShiftShortActionsMenu