import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { SubcontractorGeneralAsset } from '@src/types/drs/subcontractor/SubcontractorGeneral'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import { SupplierAgreementActivity } from '@src/types/SupplierAgreement'
import { PAETableRowType } from '@src/components/Form/PAETable'

interface DRSSubcontractorGeneralAssetDisplay extends SubcontractorGeneralAsset {
  activity: SupplierAgreementActivity,
}

export interface DRSSubcontractorGeneralAssetRow extends DRSSubcontractorGeneralAssetDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const DRSSubcontractorGeneralAssetsFields: FormTableField<DRSSubcontractorGeneralAssetDisplay>[] = [
  {
    id: 'supplier',
    key: 'supplier',
    type: FormTableFieldType.Autocomplete,
    label: 'Supplier',
    format: (item: any) => {
      if (item) {
        return item.name
      }
    },
  },
  {
    id: 'supplier-agreement',
    key: 'supplierAgreement',
    type: FormTableFieldType.Select,
    label: 'Supplier Agreement',
    format: (item: any, row: SubcontractorGeneralAsset) => {
      if (item) {
        return item.description
      }
    }
  },
  {
    id: 'supplier-agreement-item',
    key: 'activity',
    type: FormTableFieldType.Select,
    label: 'Activity',
    format: (item: any, row: SubcontractorGeneralAsset) => {
      if (row.supplierAgreement) {
        return row.supplierAgreement.itemDescription
      }
    }
  },
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time',
    format: formatDateStringHHmm,
  },
  {
    key: 'quantity',
    type: FormTableFieldType.Number,
    label: 'Quantity/Hours',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'docketNumber',
    type: FormTableFieldType.Text,
    label: 'Docket Number',
  },

  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comment',
  },
]

export default DRSSubcontractorGeneralAssetsFields