import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName } from '@src/modules/DRS/DRSPage'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import useModal from '@src/components/FormModal/useModal'
import { useAppStore } from '@src/AppStore'
import { transformSectionNameToDataSectionName, useDRS } from '@src/modules/DRS/useDRS'
import { PAE } from '@src/types/drs/PAE'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import DRSCrewMembersFields, { DRSCrewMembersFieldsRowDisplay } from './CrewMembersFields'
import Modal from '@src/components/FormModal/Modal'
import { DRSCrewMemberPAE, DRSCrewMember } from '@src/types/drs/labour/Labour'
import CompDRSAddCrewMemberDialog from '../comps/AddCrewMemberDialog'
import { Profile } from '@src/types/common/Profile'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { getStartEndDiff, getHoursByLunchTaken } from '../utils/getHours'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { adjustLabourStartEndTime } from '../utils/adjustStartEndTime'

export interface DRSCrewMembersFieldsRow extends DRSCrewMembersFieldsRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  present: null,
  comments: null,
  depot: null,
  startTime: null,
  endTime: null,
  hours: null,
  laha: null,
  lunchTaken: null,
}

interface Props { }
const CompDRSCrewMembers = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alert, alertDone } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSCrewMembersFieldsRowDisplay>({})
  const { depotId, stateId } = useAppStore()
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  if (drs === null) {
    return null
  }

  const handleAddNewCrewMember = async (profile: Profile) => {
    const retainedDetails = drs.labour.detail
    if (retainedDetails.some((member: DRSCrewMemberPAE) => {
      return member.profile.serialId === profile.serialId
    })) {
      alert({
        type: 'warning',
        message: `${formatProfileOrNullFirstLastName(profile)} already in Labour!`,
      })
      return
    }
    const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
    const data = [
      ...retainedDetails,
      {
        profile,
        [updatingColumn]: {
          present: true,
        }
      }
    ]
    await update(data)
    setAddDialogOpen(false)
  }
  const handleSave = async (form: DRSCrewMembersFieldsRow) => {
    const finalForm = adjustLabourStartEndTime(form, drs.jobDetails.date)
    updateItemByValues(form, finalForm)
  }
  const handleSaveToAll = async (form: DRSCrewMembersFieldsRow) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Are you sure to apply Present, Start Time, End Time, LAHA, Lunch Taken to all crew members?'
      })
      const finalForm = adjustLabourStartEndTime(form, drs.jobDetails.date) as DRSCrewMembersFieldsRow
      saveToAll(finalForm)
    } catch (e) { }
  }
  const saveToAll = async (form: DRSCrewMembersFieldsRow) => {
    const retainedDetails = drs.labour.detail
    const updatedMembers = retainedDetails.map((member: DRSCrewMemberPAE) => {
      if (isPlan) {
        return {
          ...member,
          [updatingColumn]: {
            ...member[updatingColumn],
            startTime: form.startTime,
            endTime: form.endTime,
            hours: form.hours,
            laha: form.laha,
            lunchTaken: form.lunchTaken,
            present: form.present,
          }
        }
      } else {
        let pickedComments = member.edit.comments
        if (!pickedComments) {
          pickedComments = member.actual.comments
        }
        return {
          ...member,
          [updatingColumn]: {
            ...member[updatingColumn],
            startTime: form.startTime,
            endTime: form.endTime,
            hours: form.hours,
            laha: form.laha,
            lunchTaken: form.lunchTaken,
            present: form.present,
            comments: pickedComments,
          }
        }
      }
    })

    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const retainedCrewMember = updatedMembers[updatingIndex]
    const data = [
      ...updatedMembers.slice(0, updatingIndex),
      {
        ...retainedCrewMember,
        [updatingColumn]: {
          ...form
        }
      },
      ...updatedMembers.slice(updatingIndex + 1),
    ]
    update(data)
  }
  const handleRevert = async (form: DRSCrewMembersFieldsRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleDelete = async (form: DRSCrewMembersFieldsRow) => {
    const retainedDetails = drs.labour.detail
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
  
    const data = [
      ...retainedDetails.slice(0, updatingIndex),
      ...retainedDetails.slice(updatingIndex + 1),
    ]
    await update(data)
  }
  
  const handleSelectedDelete = async (forms: DRSCrewMembersFieldsRow[]) => {
    let retainedDetails = drs.labour.detail

    function removeWhitespaceAndConvertToInt(str) {
      return parseInt((str.split(" "))[0]);
    }
    forms.forEach(item => {
      if(item._no_ === undefined || item._type_ === undefined) return;
      retainedDetails = retainedDetails.filter((drs) => drs.serialId !== removeWhitespaceAndConvertToInt(item._key_));
  })

    await update(retainedDetails)
  }
  
  
  async function update(data: any[]) {
    try {
      await updateSectionViaPath({
        sectionName: DRSSectionName.Labour,
        path: `detail`,
        data,
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const updateItemByValues = (form: DRSCrewMembersFieldsRow, values: any) => {
    const retainedDetails = drs.labour.detail
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const retainedCrewMember = retainedDetails[updatingIndex]
    const data = [
      ...retainedDetails.slice(0, updatingIndex),
      {
        ...retainedCrewMember,
        [updatingColumn]: {
          ...values
        }
      },
      ...retainedDetails.slice(updatingIndex + 1),
    ]
    update(data)
  }
  function getTableData() {
    if (drs === null) {
      return []
    }
    const crewMembers: DRSCrewMemberPAE[] = drs.labour.detail
    if (!crewMembers || !crewMembers.length) {
      return []
    }
    // spread profile into PAE
    const finalMembers = crewMembers.map((item: DRSCrewMemberPAE) => {
      return {
        ...item,
        id: item.serialId,
        plan: {
          ...item.plan,
          profile: item.profile,
        },
        actual: {
          ...item.actual,
          profile: item.profile,
        },
        edit: {
          ...item.edit,
          profile: item.profile,
        },
      }
    })
    const isEditAvailableFunc = (actual: any, edit: DRSCrewMember) => {
      return edit.available
    }
    const doubled = doubleUp(finalMembers, drsPageType, isEditAvailableFunc)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType, [
      PAETableMoreActionType.Revert,
    ]))
    return doubledWithActions
      .map((labour: any) => {
        return {
          ...labour,
          hours: getHoursByLunchTaken(getStartEndDiff({
            start: labour.startTime,
            end: labour.endTime,
          }), labour.lunchTaken),
        }
      })
  }
  return <Wrapper>
    <FormPanel title={`Crew Details`}>
      <FormPAETable<DRSCrewMembersFieldsRow>
        fields={DRSCrewMembersFields}
        data={getTableData()}
        editable={true}
        isSelectable={true}
        onEditRow={(row: DRSCrewMembersFieldsRowDisplay) => {
          openAndSetData(row)
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onDelete={handleDelete}
        onDeleteSelected={handleSelectedDelete}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            setAddDialogOpen(true)
          }}>
            Add Crew Member
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>
    {addDialogOpen &&
      <CompDRSAddCrewMemberDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSave={handleAddNewCrewMember}
      />
    }
    {open &&
      <Modal<DRSCrewMembersFieldsRowDisplay>
        maxWidth={'md'}
        open={open}
        title='Labour'
        data={{
          rowData: modalData,
          fields: DRSCrewMembersFields,
          columns: [
            {
              title: 'Crew Member',
              fieldKeys: [
                'profile',
                'present',
              ],
            },
            {
              title: 'Time',
              fieldKeys: [
                'startTime',
                'endTime',
                'hours',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'laha',
                'lunchTaken',
                'comments',
              ],
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
        extraAction1Label='Apply To All'
        onExtraAction1={handleSaveToAll}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSCrewMembers