import React from 'react'
import styled from 'styled-components'

import TableCell from './base-TableCell'

const LabourMemberTableHeaderCell = styled(TableCell)`
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  background-color: #DCDCDC;
` as any

export default LabourMemberTableHeaderCell 