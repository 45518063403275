import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { VMPSiteMap } from '@src/types/VMPSiteMap'

interface Options {
  vmpId: number,
  siteMapId: number,
  serialId: number,
}

export const apiVMPRemoveSiteMap = (options: Options) => {
  const { vmpId, siteMapId, serialId } = options
  const axOptions = {
    method: 'DELETE',
    url: `${BASE_API_URL}/v2/vmp/${vmpId}/sites/${siteMapId}`,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}