import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import Modal from '@src/components/FormModal/Modal'
import { SRRTrafficControl } from '@src/types/SRR'
import FormTable, { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrTrafficControlCreate } from '@src/apis/srr-traffict-control-create'
import { apiSrrTrafficControlUpdate } from '@src/apis/srr-traffict-control-update'
import { apiSrrTrafficControlRemove } from '@src/apis/srr-traffict-control-remove'
import { formatTimeStringHHmm } from '@src/types/format/timeStringHHmm'
import { formatTimeStringOrNullHHmm } from '@src/types/format/timeStringOrNullHHmm'
import usePermission from '@src/utils/hooks/usePermission'
import { toValidTimeString } from '@src/utils/time/toValidTime'

interface Props {

}

const CompSRRTrafficControl = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.machine.trafficControl.trafficControl
  const handleSave = async (form: any) => {
    console.log(form)
    const finalForm = {
      ...form,
      closureStartTime: toValidTimeString(form.closureStartTime, 'HH:mm'),
    }
    if (modalData === null) {
      try {
        const result = await apiSrrTrafficControlCreate({
          srrId: srr.id, 
          serialId,
          partial: {
            ...finalForm,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrTrafficControlUpdate({
          srrId: srr.id, 
          serialId,
          partial: {
            ...finalForm,
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  const hanldeEditRow = (row: any) => {
    openAndSetData(row)
  }
  const hanldeRemoveRow = async (row: SRRTrafficControl) => {
    try {
      await confirm({
        title: `Confirm deletion`,
        message: ``,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: SRRTrafficControl) => {
    try {
      const result = await apiSrrTrafficControlRemove({
        srrId: srr.id,
        serialId,
        id: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel title={`Traffic Control`}>
      <FormTable<SRRTrafficControl>
        fields={SRRTrafficControlFields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      { canEditSubmitSRR && 
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Traffic Control
          </CompCreateButton>
        </MarginTop>
      }
      { open && <Modal<SRRTrafficControl>
        maxWidth='sm'
        open={open}
        title='Traffic Control'
        data={{
          rowData: modalData,
          fields: SRRTrafficControlFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'provider',
                'company',
                'closureStartTime',
                'existingTCPAvailable',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'specialRequirement',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
export const SRRTrafficControlFields: FormTableField<SRRTrafficControl>[] = [
  {
    id: 'MachineProvider',
    key: 'provider',
    type: FormTableFieldType.Select,
    label: 'Traffic Control Provider',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    key: 'company',
    type: FormTableFieldType.Text,
    label: 'Traffic Control Company',
  },
  {
    key: 'closureStartTime',
    type: FormTableFieldType.Time,
    label: 'Closure Start Time',
    format: formatTimeStringOrNullHHmm,
    timeFormat: 'HH:mm:ss',
  },
  {
    key: 'existingTCPAvailable',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Existing TCP Available',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'specialRequirement',
    type: FormTableFieldType.Textarea,
    label: 'Special Requirement: (e.g. After care, Bump truck, Police, VM… Utes, Controllers)',
  },
]

export default CompSRRTrafficControl