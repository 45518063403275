import { BASE_API_URL } from "@src/constants"
import { ax } from "@src/utils/axios"


export const apiSearchShiftNote = (ShiftID?: number) => {
    const options = {
        method: 'GET',
        url: `${BASE_API_URL}/ref/shiftNotes?shiftID=${ShiftID}`,
    }
    return ax(options)
}