import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { Project } from '@src/types/project/Project'

interface Options {
  copyItems: string[],
  TargetShiftIDs: [],

  serialId: number,
  drsProjectTypeId: string,

  drsID: string,
  SourceShiftID: string,
  targetDate: string
}
export const apiShiftDRSDuplicate = ({
  copyItems,
  TargetShiftIDs,
  drsID,
  SourceShiftID,
  drsProjectTypeId,
  serialId,
  targetDate

}: Options) => {
  const data = {
    copyItems,
    TargetShiftIDs,
    drsID,
    SourceShiftID,
    drsProjectTypeId,
    targetDate
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/shifts`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}