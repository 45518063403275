import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

interface Props {
  onClick(): void,
}

const AttachSifTrigger = (props: Props) => {
  const { onClick } = props
  return <Wrapper onClick={onClick}>
    <SButton variant='contained' color='primary'>Add Site Inspection</SButton>
  </Wrapper>
}

const Wrapper = styled.div`
  width: 200px;
  height: 240px;
  background-color: #F9F9F9;
  border-radius: 8px;
  border: 1px dashed #009688;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SButton = styled(Button)`

`
export default AttachSifTrigger