import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  serialId: number,
  projectInternalId: number
}

export const apiSifGetByProject = (options: Options) => {
  const { serialId, projectInternalId } = options
  const data = {

  }
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/sif`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
      project: projectInternalId,
    }
  }
  return ax(axOptions)
}