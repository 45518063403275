import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { Link, useHistory } from 'react-router-dom'
import FormPanel from '@src/components/FormPanel/FormPanel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import usePermission from '@src/utils/hooks/usePermission'
import { Sif, SifBrief } from '@src/types/Sif'
import { RoleId } from '@src/types/common/RoleId'
import { Mime } from '@src/types/Mime'
import SifCard from '../comps/SifCard'
import CreateSifDialog from '../comps/CreateSifDialog'
import { apiSifGetByProject } from '@src/apis/sif-get-by-project'
import { useAppStore } from '@src/AppStore'
import { Project } from '@src/types/project/Project'
import { SifStatus } from '@src/types/SifStatus'


interface Props {
  project: Project,
}
const ProjectShiftDocs = (props: Props) => {
  const { project } = props
  const history = useHistory()
  const { serialId } = useAppStore()
  const { canCreateSif } = usePermission()
  const [ openCreateSifDialog, setOpenCreateSifDialog ] = useState<boolean>(false)
  const [ sifs, setSifs ] = useState<SifBrief[]>([])
  const [ refreshToken, setRefreshToken ] = useState<number>(1)
  const [ dialogSif, setSialogSif ] = useState<SifBrief | undefined>(undefined)
  useEffect(() => {
    const fetchSifs = async () => {
      const result = await apiSifGetByProject({
        serialId,
        projectInternalId: project.projectInternalId,
      })
      setSifs(
        result.data.sifs
          .filter((sif : SifBrief) => {
            return sif.status.id !== SifStatus.Cancelled
          })
      )
    }
    fetchSifs()
  }, [refreshToken])
  const onCreateSif = () => {
    setSialogSif(undefined)
    setOpenCreateSifDialog(true)
  }
  const handleSifCardClick = (sif: SifBrief) => () => {
    history.push({
      pathname: `/sif/${sif.id}`,
    })
  }
  return <Wrapper>
    <FormPanel title={`Site Inspections`}>
      { canCreateSif && 
        <CreateSifButton
          variant='contained'
          color='primary'
          endIcon={<AddCircleIcon />}
          onClick={onCreateSif} 
        >
          Assign New SIF
        </CreateSifButton>
      }
      { openCreateSifDialog && <CreateSifDialog 
        open={openCreateSifDialog}
        onCancel={() => setOpenCreateSifDialog(false)}
        onClose={() => setOpenCreateSifDialog(false)}
        onSuccess={() => {
          setOpenCreateSifDialog(false)
          setRefreshToken(refreshToken + 1)
        }}
        data={{
          project: {
            projectInternalId: project.projectInternalId,
            id: project.id,
            name: project.name,
          },
          sif: dialogSif,
        }}
      /> }
      <SifCardsWrapper>
      { sifs.map((sif: SifBrief) => {
        return <CardWrapper key={sif.id}>
          <SifCard sifBrief={sif} onClick={handleSifCardClick(sif)} onEdit={() => {
            setSialogSif(sif)
            setOpenCreateSifDialog(true)
          }}/>
        </CardWrapper>
      })}

      </SifCardsWrapper>
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`
`
const CreateSifButton = styled(Button)`
  margin-bottom: 40px;
`
const SifCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CardWrapper = styled.div`
  margin-right: 40px;
  margin-bottom: 20px;
`

export default ProjectShiftDocs