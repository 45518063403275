import _set from 'lodash/set'
import _get from 'lodash/get'
import shortid from 'shortid'
import { ASSET_LOCATION_OPTIONS, getJobSpecifications, CHECKBOX_SELECT_OPTIONS } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import moment from 'moment'
import { isAS, isSS } from '../EditDRSStore'

export default function getQARequirementsSectionData(data: DRS.RootData) {
  const summaryAs = getAsSummaryPanel(data)
  const summarySs = getSsSummaryPanel(data)
  let panels: any[] = []
  if (isAS(data)) {
    panels = [
      summaryAs, 
    ]
  }
  if (isSS(data)) {
    panels = [ 
      ...panels, 
      summarySs,
    ]
  }
  const section = {
    label: 'QA REQUIREMENTS',
    panels,
  }
  return section
}


export function getAsSummaryPanel(data: DRS.RootData) {
  const label = `QA Requirements - Asphalt`
  const path = `qaRequirements.asphalt`
  const summaryPanel = {
    label,
    repeatable: false,
    path,
    fields: getAsFields(data, path),
  }
  return summaryPanel
}

function getAsFields(data: DRS.RootData, path: string) {
  return [
    // {
    //   label: 'Asphalt Layer',
    //   type: 'select',
    //   path,
    //   relativePath: 'asphaltLayer',
    //   editable: true,
    //   displayFunc: value => value.name,
    //   options: getJobSpecifications().filter(item => item.asphalt).map(item => ADIDNameToValueLabel(item)),
    // },
    {
      label: 'Asphalt Laying Record',
      type: 'checkbox-select',
      path,
      relativePath: 'asphaltLayingRecord',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Hold Point Release Form',
      type: 'checkbox-select',
      path,
      relativePath: 'holdPointReleaseForm',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Compaction Testing',
      type: 'checkbox-select',
      path,
      relativePath: 'compactionTesting',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Measure Dip Report',
      type: 'checkbox-select',
      path,
      relativePath: 'measureDipReport',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Asphalt Testing',
      type: 'checkbox-select',
      path,
      relativePath: 'asphaltTesting',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Lab Tech Onsite',
      type: 'checkbox-select',
      path,
      relativePath: 'labTechOnsite',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}

export function getSsSummaryPanel(data: DRS.RootData) {
  const label = `QA Requirements - Spray Seal`
  const path = `qaRequirements.spraySeal`
  const summaryPanel = {
    label,
    repeatable: false,
    path,
    fields: getSsFields(data, path),
  }
  return summaryPanel
}

function getSsFields(data: DRS.RootData, path: string) {
  return [
    // {
    //   label: 'Asphalt Layer',
    //   type: 'select',
    //   path,
    //   relativePath: 'asphaltLayer',
    //   editable: true,
    //   displayFunc: value => value.name,
    //   options: getJobSpecifications().filter(item => item.spraySeal).map(item => ADIDNameToValueLabel(item)),
    // },
    {
      label: 'Hold Point Release Form',
      type: 'checkbox-select',
      path,
      relativePath: 'holdPointReleaseForm',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Compaction Testing',
      type: 'checkbox-select',
      path,
      relativePath: 'compactionTesting',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Measure Dip Report',
      type: 'checkbox-select',
      path,
      relativePath: 'measureDipReport',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Ball Pen Testing',
      type: 'checkbox-select',
      path,
      relativePath: 'ballPenTesting',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Lab Tech Onsite',
      type: 'checkbox-select',
      path,
      relativePath: 'labTechOnsite',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Seal Design',
      type: 'checkbox-select',
      path,
      relativePath: 'sealDesign',
      editable: true,
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}