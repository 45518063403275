import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import DRSQaAsphaltFields, { DRSQaAsphaltRowDisplay, DRSQaAsphaltModalFields, DRSQaAsphaltModalField, DRSQaAsphaltRequirementFields } from './QaAsphaltFields'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage' 
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'

interface DRSQaAsphaltRow extends DRSQaAsphaltRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  asphaltLayingRecord: null,
  asphaltTesting: null,
  comments: null,
  compactionTesting: null,
  holdPointReleaseForm: null,
  jobSpecification: null,
  labTechOnsite: null,
  measureDipReport: null,
}

const DRSSectionQaAspahlt = () => {
  const { id } = useParams<{id: string}>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone, } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<DRSQaAsphaltModalField>({})
  if (drs === null || drs.qa.asphalt === null) {
    return null
  }
  
  const onEditRow = (row: DRSQaAsphaltRow) => {
    openAndSetData({
      ...row,
      requirements: DRSQaAsphaltRequirementFields
          .filter((field) => row[field.key] === true)
          .map((field) => field.key)
    })
  }
  
  const handleSave = async (form: DRSQaAsphaltModalField) => {
    const retained = drs.qa.asphalt
    const data = {
      ...retained,
      [updatingColumn]: {
        ...form,
        ...DRSQaAsphaltRequirementFields
          .map((field) => {
            return {
              key: field.key,
              value: form.requirements.includes(field.key),
            }
          })
          .reduce((acc, curr) => {
            return {
              ...acc,
              [curr.key]: curr.value
            }
          }, {})
      },
    }
    update(data)
  }
  const handleRevert = (row: DRSQaAsphaltRow) => {
    // updatingColumn is edit here
    const retained = drs.qa.asphalt
    const data = {
      ...retained,
      [updatingColumn]: {
        ...NULL_VALUES,
      }
    }
    update(data)
  }
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Quality,
      path: `asphalt`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const getTableData = () => {
    const rows = [ drs.qa.asphalt ]
    if (!rows || !rows.length) {
      return []
    }
    const doubled = doubleUp(rows, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType, [PAETableMoreActionType.Revert]))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`QA Asphalt`}>
      <FormPAETable<DRSQaAsphaltRow>
        fields={DRSQaAsphaltFields}
        data={getTableData()}
        editable={true}
        onEditRow={onEditRow}
        showMoreActions={isEdit}
        onRevert={handleRevert}
      />
    </FormPanel>

    { open && 
      <Modal<DRSQaAsphaltModalField>
        maxWidth={'xs'}
        open={open}
        title='QA Asphalt'
        data={{
          rowData: modalData,
          fields: DRSQaAsphaltModalFields,
          columns: [
            {
              title: 'QA',
              fieldKeys: [
                'jobSpecification',
                'requirements',
                'comments',
              ],
            },
          ],
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default DRSSectionQaAspahlt