import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  srrId: number,
  siteId: number,
  serialId: number,
  street?: string,
  nearestCrossStreet?: string,
  suburb?: string,
  mapReference?: string,
  timeOnSite?: string,
  timeOffSite?: string,
  siteSpecificInduction?: boolean,
  plantRiskAssessmentsRequired?: boolean,
  uhfChannel?: string,
  emergencyAssemblyPoint?: string,
  nearestHospital?: string,
  baseCondition?: string,
  generalSiteObservations?: string,
  machineAccess?: string,
  machineParking?: string,
}

export const apiSrrUpdateSiteInformation = (options: Options) => {
  const { 
    srrId, 
    siteId,
    serialId,
    street,
    nearestCrossStreet,
    suburb,
    mapReference,
    timeOnSite,
    timeOffSite,
    siteSpecificInduction,
    plantRiskAssessmentsRequired,
    uhfChannel,
    emergencyAssemblyPoint,
    nearestHospital,
    baseCondition,
    generalSiteObservations,
    machineAccess,
    machineParking,
  } = options

  const data = {
    srrId,
    street,
    nearestCrossStreet,
    suburb,
    mapReference,
    timeOnSite,
    timeOffSite,
    siteSpecificInduction,
    plantRiskAssessmentsRequired,
    uhfChannel,
    emergencyAssemblyPoint,
    nearestHospital,
    baseCondition,
    generalSiteObservations,
    machineAccess,
    machineParking,
  }
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/siteinformation/sitedetails/details/${siteId}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}