import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import ProjectSearchBar from '@src/modules/HeaderFilters/ProjectFilter/ProjectSearchBar'
import ProfileButton from '../comps/ProfileButton'
import ProfilePopover from '../comps/ProfilePopover'

const Right = () => {
  return <StyledRightPart>
    <Route exact path='/dashboard/projects' component={() => <ProjectSearchBar />} />
    <AlertsAndProfile />
  </StyledRightPart>

  // {/* shows responsively */}
  // <StyledRightMenu>
  //   <MenuIcon />
  // </StyledRightMenu>
}

const AlertsAndProfile = ({ 

}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const open = Boolean(anchorEl)

  function handleMenu(e: SyntheticEvent) {
    setAnchorEl(e.currentTarget as HTMLElement)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  return <React.Fragment>
    <StyledIconWithLabelBox onClick={handleMenu} id={'profile-main-btn-data'}>
      <ProfileButton/>
    </StyledIconWithLabelBox>
    <ProfilePopover 
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    />
  </React.Fragment>
}

const StyledRightPart = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: inline-flex;
    align-items: flex-end;
  }
`
const StyledRightMenu = styled.div`
  @media (min-width: 960px) {
    display: none;
  }
`
const StyledIconWithLabelBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button {
    display: block;
  }
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`

export default Right