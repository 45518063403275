import React, { useState, useEffect, ReactNode } from 'react'
import BaseAutocomplete from './BaseAutocomplete'
import useDebounce from '../../utils/hooks/useDebounce'

interface Props {
  fetch(query: string): Promise<any>,
  selectedItem: AIMS.ValueLabel,
  onChange(p: any): void,

  dropdownAutoWidth?: boolean,
  fullWidth?: boolean,
  disabled?: boolean,
  id?: string,
  label?: string,
  startAdornment?: ReactNode,
}

const Autocomplete = ({
  fetch,
  selectedItem,
  onChange,
  dropdownAutoWidth = false,
  fullWidth = true,
  disabled = false,
  id = '',
  label = '',
  startAdornment,
}: Props) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ inputValue, setInputValue ] = useState<string>('')
  const [ options, setOptions ] = useState<any>([])
  // disabel fetch when input state change such as selects an item, or click away
  const [ disableFetch, setDisableFetch ] = useState<any>(false)

  const debounced = useDebounce(inputValue, 200)

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(debounced)
      setOptions(result)
      setIsLoading(false)
      setIsOpen(true)
    }
    if (debounced) {
      if (disableFetch) {
        setDisableFetch(false)
      } else {
        fetchData()
      }
    } else {
      setIsOpen(false) // do not open when no input
      setIsLoading(false)
      setOptions([])
    }
  }, [debounced])

  const handleInput = (value: string) => {
    setInputValue(value || '')
  }
  const handleInternalOpen = () => {
    if (!isLoading) {
      setIsLoading(true)
      setIsOpen(true)
    }
  }
  const handleInternalClose = (inputValueWhenClickOutside: string) => {
    setIsOpen(false)
    setIsLoading(false)
    setOptions([])
    if (typeof inputValueWhenClickOutside !== 'undefined') {
      setInputValue(inputValueWhenClickOutside || '')
      setDisableFetch(true)
    }
  }

  const handleSelect = (item: AIMS.ValueLabel) => {
    setInputValue(item.label || '')
    setDisableFetch(true)
    onChange(item)
  }

  return  <BaseAutocomplete 

    isLoading={isLoading}

    isOpen={isOpen}
    dropdownAutoWidth={dropdownAutoWidth}
    fullWidth={fullWidth}
    disabled={disabled}
    
    onInternalOpen={handleInternalOpen}
    onInternalClose={handleInternalClose}
    // onEsc={handleEsc}

    selectedItem={selectedItem}
    id={id}
    label={label}
    
    // hasTopOption={true}
    // topOptionLabel='Create New Customer'
    // onTopOption={e => setIsCreatingNewCustomer(!isCreatingNewCustomer)}
    onSelect={handleSelect}
    onInput={handleInput}
    options={options}

    startAdornment={startAdornment}
   />
}




export default Autocomplete