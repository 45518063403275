import { useState, useEffect } from 'react'
import { useAppStore } from '@src/AppStore'
import { apiMobileAssetsList } from '@src/apis/mobile-asset-list'
import { MobileAsset } from './fields'

export function useMobileAssetsFilteredByDepot(depot: any, active: boolean): [ MobileAsset[], typeof fetch ] {
  const [ assets, setAssets ] = useState<MobileAsset[]>([])
  const { regionId } = useAppStore()
  const fetch = async () => {
    let finalDepotId
    let finalRegionId
    if (depot.value === 'all') {
      finalRegionId = regionId
    } else {
      finalDepotId = depot.value
    }
    const result = await apiMobileAssetsList({
      depotId: finalDepotId,
      regionId: finalRegionId as string,
      active,
    })
    if (result.data) {
      setAssets(
        result.data.mobileAssets as MobileAsset[]
      )
    }
  }
  useEffect(() => {
    if (depot.value) {
      fetch()
    } else {
      console.log(depot)
    }
  }, [depot, active])

  return [ assets, fetch ]
}