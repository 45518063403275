import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  projectInternalId: number,
  scenarioProjectInternalId: number,
}
export const apiProjectLink = (options: Options) => {
  const {
    projectInternalId,
    scenarioProjectInternalId,
  } = options
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/projects/${projectInternalId}/link`,
    data: {
      projectInternalId,
      scenarioProjectInternalId,
    }
  }
  return ax(axOptions)
}