import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Box from '@material-ui/core/Box'
import { WhoseAutocomplete, DepotAutocomplete, StatusSelect } from './comps/index'
import { useProjectFilter, FilterBy } from './ProjectFilterStore'
import { useAppStore } from '@src/AppStore'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import { Profile } from '@src/types/common/Profile'
import { AutocompleteOnChangeItem } from '@src/types/AutocompleteOnChangeItem'


const ProjectFilter = () => {

  const { stateId, serialId, depotId, depotName, profile, appStoreRefreshHook, goRefreshAppStore } = useAppStore()
  const { depot, PM, status, setPM, setDepot, setStatus, filterBy } = useProjectFilter()
  const open: boolean = filterBy === FilterBy.CONDITIONS
  const [selectedDepot, setSelectedDepot] = useState<AIMS.ValueLabel>({
    label: depot ? depot.name : depotName,
    value: depot ? depot.id : depotId,
  })
  let initialWhoLabel: any = PM ? PM.firstName : ''
  let initialWhoValue: any = PM ? PM.serialId : ''
  if (!initialWhoValue) {
    initialWhoLabel = 'My'
    initialWhoValue = 'my'
  } else if (initialWhoValue === serialId) {
    initialWhoLabel = 'My'
    initialWhoValue = 'my'
  }

  const [selectedWho, setSelectedWho] = useState<AIMS.ValueLabel>({
    label: initialWhoLabel,
    value: `${initialWhoValue}`,
  })
  const [selectedStatus, setSelectedStatus] = useState<number>(status || ProjectStatus.Active)

  useEffect(() => {
    if (appStoreRefreshHook) {
      setSelectedDepot({ value: depotId, label: depotName })
      setDepot({
        id: depotId,
        name: depotName || '',
      })
    }
  }, [appStoreRefreshHook])

  const handleWhoSelect = (item: AutocompleteOnChangeItem<Profile>) => {
    setSelectedWho(item)
    if (item.value === 'my') {
      setPM(profile!)
    } else {
      setPM(item.raw)
    }
  }

  const handleDepotSelect = (item: AIMS.ValueLabel) => {
    setSelectedDepot(item)
    setDepot({
      id: item.value,
      name: item.label || '',
    })
  }
  if (!open) {
    return null
  }
  return <StyledBox>

    {/* `View My active Projects in Sydney Depot` */}

    <PaddingSpan>View</PaddingSpan>

    <WhoseAutocomplete
      selectedItem={selectedWho}
      color='boral'
      onSelect={handleWhoSelect}
    />

    <PaddingSpan></PaddingSpan>

    <StatusSelect
      selected={selectedStatus || ProjectStatus.Active}
      onChange={(val: ProjectStatus) => {
        setSelectedStatus(val)
        setStatus(val)
      }}
    />

    <PaddingSpan>Projects</PaddingSpan>

    <PaddingSpan>in</PaddingSpan>

    <DepotAutocomplete
      selectedItem={selectedDepot}
      color='boral'
      onSelect={handleDepotSelect}
    />

    <PaddingSpan>Depot</PaddingSpan>

  </StyledBox>
}

const StyledBox = styled(Box)`
  font-size: 24px;
`
const PaddingSpan = styled.span`
  padding: 0 2px;
`
const SecondHalf = styled.div`
  display: block;
  @media (min-width: 960px) {
    display: inline-block;
  }
`

export default observer(ProjectFilter)