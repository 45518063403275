import React from 'react'
import styled from 'styled-components'

export interface Point {
  x: number,
  y: number,
}

interface Props {
  from: Point,
  to: Point,
  stroke?: string,
  onMouseOver(): void,
  hovered?: boolean,
  onMouseDown(e: React.MouseEvent<SVGSVGElement, MouseEvent>): void,
  onClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>): void,
  onStartMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
  onEndMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
}

const CompArrow = (props: Props) => {
  const {
    from,
    to,
    stroke = 'black',
    onMouseOver,
    hovered = false,
    onMouseDown,
    onClick,
    onStartMouseDown,
    onEndMouseDown,
  } = props
  function buildPath() {
    const dM = `M 0 0`
    const dLine = `L ${getLineLength(from, to)} 0`
    return `${dM} ${dLine}`
  }

  function buildTrianglePath() {
    const endX = getLineLength(from, to)
    const dM = `M ${endX - 10} ${0 - 5}`
    const dLine = `L ${endX} 0 ${endX - 10} ${0 + 5}`
    return `${dM} ${dLine}Z`
  }
  
  const rotation = getDegree((to.x - from.x), (to.y - from.y))

  function getLineLength(from: Point, to: Point) {
    return Math.sqrt(Math.pow(to.x - from.x, 2) + Math.pow(to.y - from.y, 2))
  }
  function getDegree(x: number, y: number){
    var d = Math.atan2(y, x) * (180 / Math.PI)
    // if(d < 0){ d = 180 - d; }
    return d
  }
  return <Wrapper
    transform={`translate(${from.x}, ${from.y}) rotate(${rotation})`} 
    onMouseOver={onMouseOver}
    onMouseDown={onMouseDown}
    onClick={onClick}
  >
    { hovered && 
      <path 
        d={buildPath()} 
        stroke='#56c3e4' 
        strokeWidth='5'
      />
    }
    <path 
      d={buildPath()} 
      stroke={stroke}
      strokeWidth='3'
    />
   
    <path
      d={buildTrianglePath()} 
      fill={stroke}
      stroke={stroke} 
      strokeWidth='3'
    />
    { hovered && 
      <React.Fragment>
        <CircleHandle r='5' cx={0} cy={0} 
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onStartMouseDown(e)
            }
          } 
        />
        <CircleHandle r='5' cx={getLineLength(from, to)} cy={0}  
          onMouseDown={
            (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
              e.stopPropagation()
              onEndMouseDown(e)
            }
          } 
        />
      </React.Fragment>
    }
  </Wrapper>
}

const Wrapper = styled.g`
  &:hover {
    cursor: move;
  }
`
export const CircleHandle = styled.circle`
  stroke: white;
  stroke-width: 2;
  fill: #56c3e4;
  cursor: default;
`

export default CompArrow
