import React from 'react'
import AutocompleteComp from '../GeneralAutocomplete/Autocomplete'
import { apiDepotSearch } from '../../apis/depot-search'
import { DepotRegionState } from '@src/types/compose/DepotRegionState'
import { Autocomplete } from '@src/types/Autocomplete'

function DepotAutocomplete({
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
  disabled = false,
}: Autocomplete) {
  const fetch = async (query: string) => {
    const result: any = await apiDepotSearch({ query })
    return result.data.depots
      .slice(0, 5)
      .map((item: DepotRegionState) => {
        const { id, name } = item.depot
        return {
          value: id,
          label: name,
          raw: item.depot,
        }
      })

  }
  let finalSelectedItem
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <AutocompleteComp
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
    disabled={disabled}
  />
}


export default DepotAutocomplete