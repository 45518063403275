import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'

import { useConfirm } from '@src/components/GlobalConfirmDialog'
import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import { useSnackbar } from '@src/components/GlobalSnackbar'

import { useAppStore } from '@src/AppStore'
import { RoleId } from '@src/types/common/RoleId'

import RowFlex from '@src/components/FormRow/RowFlex'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatEmailToFirstLastName } from '@src/types/format/emailToFirstLastName'
import { Attachment } from '@src/types/Attachment'
import { Image } from '@src/types/Image'
import { BASE_API_URL } from '@src/constants'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { compressImageFile } from '@src/apis/files/compress-image-file'

import pdfIcon from '@src/assets/pdficon.svg'
import CompCarouselDialog from '@src/modules/DRS/parts/9-Summary/parts/carousel/CarouselDialog'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { useParams,useLocation } from 'react-router'
import { apiGetFilesByCrewPackId } from '@src/apis/files/get-files-by-crewpack-id'
import AttachmentField from '@src/components/Form/AttachmentField'
import { CircularProgress, Container } from '@material-ui/core'
import Breadcrumbs from '@src/components/Breadcrumbs'
import CrewPackUploadAttachmentTrigger from '../parts/UploadTrigger'
import { PackFormType } from '@src/types/PackFormType'
import Grid from '@material-ui/core/Grid'
import Tabs from '../parts/Tabs'
import CrewPackShiftDocumentDialog from './CrewPackShiftDocumentDialog'
import LockIcon from '@material-ui/icons/Lock'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { CheckCircleOutline } from '@material-ui/icons'
import { apiUpdateFile } from '@src/apis/files/update-file'
import CompDeleteButton from '@src/components/Buttons/CompDeleteButton'
import { apiDeleteFile } from '@src/apis/files/delete-file'
import { apiPresignedToken } from '@src/apis/files/presign-file-token'
import { apiUploadFileS3 } from '@src/apis/files/upload-file-s3'
import { apiUploadPreloadData } from '@src/apis/files/upload-preload-data'
import { apiLargeFileFormFileS3 } from '@src/apis/files/get-large-file-from-s3'
interface Props { }

const CrewPackPhotos = (props: Props) => {


  const { confirm } = useConfirm()
  const { alert, alertDone, alertError } = useSnackbar()
  const anchorRef = useRef(null)
  const { crewPackId, projectInternalId } = useParams<{ crewPackId: string, projectInternalId: string }>();
  //const { smiId } = useSMI(id)
  const [open, setOpen] = useState<boolean>(false) // more action dropdown
  const [editMode, setEditMode] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isCarouselDialogOpen, setCarouselDialogOpen] = useState<boolean>(false)
  const [isShiftDocumentDialogOpen, setShiftDocumentDialogOpen] = useState<boolean>(false)
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [clickedImage, setClickedImage] = useState<string | null>(null)
  const { serialId, defaultRoleId, roles } = useAppStore()
  const AcceptingImageFileMimeTypes: string[] = ['image/jpeg', 'image/png']
  const [normalAttachments, setNormalAttachments]: any = useState([]);
  const [tabIndex, setTabIndex] = useState<any>(0)
  const roleId = RoleId.Administrator;
  let plannedImageAttachments: Attachment[] = normalAttachments;
  const [filePdf1, setFilePdf1] = useState<any>()
  const [filePdfTemp, setFilePdfTemp] = useState<any>()
  const [filePdf2, setFilePdf2] = useState<any>()
  const [filePdf3, setFilePdf3] = useState<any>()
  const [currentSelectedFileName, setCurrentSelectedFileName] = useState<any>([])
  const { hash } = useLocation()
  const [currentAttachment, setCurrentAttachment] = useState<any>()

  // eslint-disable-next-line no-lone-blocks
  { normalAttachments ? plannedImageAttachments = normalAttachments.filter((attachment: Attachment) => AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) : plannedImageAttachments = [] }
  const plannedImages: Image[] = plannedImageAttachments.map((imageAttachment: Attachment, index: number) => {
    return {
      id: parseInt(`${imageAttachment.id}`),
      originalName: `${imageAttachment.originalName}`,
      'name': `${imageAttachment.name}`,
      location: `${imageAttachment.location}`,
      serialId: parseInt(`${imageAttachment.serialId}`),
      uri: `${BASE_API_URL}/v2/documents/document/${imageAttachment.name}?${qs.stringify({
        reqSid: serialId,
      })}`,
    }
  })


  let images: Image[] = plannedImages;

  const refresh = async ()=>{
    try {
      apiGetFilesByCrewPackId({
        crewPackId: crewPackId + '',
        serialId,
      }).then((result) => {
        if (result && result.data.documents) {
        //  console.log(result.data.documents);
        const sortData = { 'dateModified' : 'desc' };
        const attachmentData: any = multiSort(result.data.documents,sortData);
          setNormalAttachments(attachmentData as Attachment[])
        }
      })

    }
    catch (e) {
      console.log(e);
    }
  }
 
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1);
      if(trimmedHash == 'pdf'){
        setTabIndex(1);
      }
      
      
     
    }
   // setTabIndex(possiblePartValues.indexOf(part))
  }, [hash])
  useEffect(() => {

    refresh();
    // console.log(`defaultRoleId`);
    // console.log(defaultRoleId);
    // console.log(`defaultRoleId`);
    if (clickedImage) {
      const selectedImageIndex = images.map(function (item) { return item.name; }).indexOf(clickedImage);
      setImageIndex(selectedImageIndex)
      setCarouselDialogOpen(true)
    }
  }, [clickedImage])
  const handleImageAttachmentClick = async (attachment: Attachment, listingIndex: number) => {
    try {
      const { name } = attachment
      //console.log(`Summary handleImageAttachmentClick() listingIndex=${listingIndex}`)
      setClickedImage(name)
    } catch (e) { }
  }
  const onEdit = async (attachment: Attachment) => {
    setCurrentSelectedFileName([]);
   if(checkRole(attachment)) {
      setEditMode(true);
      setShiftDocumentDialogOpen(true);
      setCurrentAttachment(attachment);
   }
   
  }
  const onRemove = async (attachment: Attachment) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Sure to delete this file?',
      })
      remove(attachment)
    } catch (e) { }
  }
  const remove = async (attachment: Attachment) => {

  }
  //sort function for document with date 
  function multiSort(array: any, sortObject: any = {}) {
    const sortKeys = Object.keys(sortObject);
  
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
        return array;
    }
  
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
        sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
    }
  
  
    const keySort = (a: any, b: any, direction: any) => {
        direction = direction !== null ? direction : 1;
  
        if (a === b) { // If the values are the same, do not switch positions.
            return 0;
        }
         // console.log(a,b);
        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
    };
  
    return array.sort((a:any, b: any) => {
        let sorted = 0;
        let index = 0;
  
        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
            const key = sortKeys[index];
            
            if (key) {
                const direction = sortObject[key]; 
                sorted = keySort(a.dateModified, b.dateModified, direction);
                index++;
            }
        }
  
        return sorted;
    });
  }
  const handlePdfClick = (attachment: Attachment) => {
    // if not an image open in a new browser tab
    if(checkRole(attachment)) {
   // console.log('here');
    if (!AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) {
      //console.log('here');
      const fileUrl: string = `${BASE_API_URL}/v2/documents/document/${attachment.name}?${qs.stringify({
        reqSid: serialId,
      })}`
      apiLargeFileFormFileS3({
        formType: PackFormType.CREW_PACK_PHOTO,
        formId: crewPackId,
       
        serialId,
       fileName:attachment.name
       
      }).then((response)=>{
        let url ='';
        if(response){
          if(response.data){
            if(response.data.documents){
              url = response.data.documents;
            }
          }
        }
        if(url) {
          window.open(response.data.documents, '_blank');
        }else{
          alertError()
        }
      })
     //window.open(fileUrl, '_blank')
    }
  }
  }
  
  function getImageAttachment(attachment: any) {
   
    return <AttachmentField
      key={attachment.id}
      attachment={attachment}
      removable={roleId === attachment.roleId}
      onRemove={onRemove}
      listingIndex={1}
      extImageHandling={true}

      onImageAttachmentClick={handleImageAttachmentClick}
    />;
  }
  function findRoles(attachmentRoleID: string) {
    for(var i = 0;i<roles.length;i++) {
      if((roles[i].id == attachmentRoleID  &&  roles[i].default == true)) {
        return true;
      }
    }
  }
  function checkRole(attachment: Attachment) {

      if(defaultRoleId == RoleId.SystemAdministrator) {
        return true;
      }
      if(attachment.IsAdministrator == "Y" && findRoles(RoleId.Administrator)) {
        return true;
      }
      if(attachment.IsAdministrator == "Y" && (defaultRoleId).indexOf('ADMINISTRATOR')!= - 1) {
        return true;
      }
      if(attachment.IsForeman == "Y" && findRoles(RoleId.Foreman)) {
        return true;
      }

      if(attachment.IsSupervisor == "Y" && findRoles(RoleId.Supervisor)) {
        return true;
      }
      if(attachment.IsProjectManager == "Y" && findRoles(RoleId.ProjectManager)) {
        return true;
      }
      if(attachment.IsResourceScheduler == "Y" && findRoles(RoleId.ResourceScheduler)) {
        return true;
      }
      return false;
  }
  
  const handleFileSelectDocument = async () => {
    setShiftDocumentDialogOpen(true);
  }
  const handleFileSelect = async (files: FileList, imageFiles: FileList, nonImageFiles: FileList) => {

    try {
      setIsLoading(true)

      // TODO: remove me only for crew pack photos
      //   Promise.all(
      //     Array.from(imageFiles).map((imageFile) => {
      //       let maxWidth: number = 1000
      //       let maxHeight: number = 1000
      //       let maxFileSize: number = 250000

      //       return compressImageFile({ imageFile, maxWidth, maxHeight, maxFileSize })
      //     })
      //   )
      //     .then((compressedImageFiles) => {
      //       return 

      Promise.all(
        Array.from(imageFiles)
          .map((file: File) => {
            return apiUploadFile({
              formType: PackFormType.CREW_PACK_PHOTO,
              formId: crewPackId,
              roleId: RoleId.Foreman,
              serialId,
              file: file,
              projectInternalId: parseInt(projectInternalId),
            })
          })
      ).then(() => {
        return Promise.all(
          Array.from(nonImageFiles)
            .map((file: File) => {
              return apiUploadFile({
                formType: PackFormType.CREW_PACK_PHOTO,
                formId: crewPackId,
                roleId: RoleId.Foreman,
                serialId,
                file: file,
                projectInternalId: parseInt(projectInternalId),
              })
            })
        )
      })
        .then(() => {
          setIsLoading(false)
          alertDone()
          window.location.reload();
          //  refresh()
        })

    } catch (e) {
      alertError()
    }
  }
  const onDelete = async (attachment: Attachment) => {

    try {
      await confirm({
        title: 'Confirm Deletion of Document',
        message: 'This will permanently delete any saved Document information from the system and cannot be undone.',
      })
     
     return apiDeleteFile(attachment) .then(() => {
      setIsLoading(false)
      alertDone()
      refresh()
    }).catch(() => {
      setIsLoading(false)
      alertError();
      refresh()
    })
    } catch (e) { }
  } 

  const handlePdfSave = async (data: any) => {
    if(editMode) {
      currentAttachment['cpDocComment'] = data[5];
      
      return apiUpdateFile(currentAttachment) .then(() => {
        setIsLoading(false)
        alertDone()
          refresh()
          setShiftDocumentDialogOpen(false);
          setCurrentAttachment(null);
          setEditMode(false);
      }).catch(() => {
        setIsLoading(false)
        alertError();
        setShiftDocumentDialogOpen(false);
        setCurrentAttachment(null);
        setEditMode(false);
        refresh()
      })
    }else{
      
    
    try {
      if (filePdf2) {
        setIsLoading(true);
 
        Promise.all(
          Array.from(filePdf2)
            .map((file: any) => {
              return apiUploadFile({
                formType: PackFormType.CREW_PACK_PHOTO,
                formId: crewPackId,
                roleId: defaultRoleId,
                serialId,
                file: file,
                projectInternalId: parseInt(projectInternalId),
               
                IsAdministrator: data[0] == true ? 'Y' : 'N',
                IsResourceScheduler: data[1] == true ? 'Y' : 'N',
                IsProjectManager: data[2] == true ? 'Y' : 'N',
                IsSupervisor: data[3] == true ? 'Y' : 'N',
                IsForeman: data[4] == true ? 'Y' : 'N',
                cpDocComment: data[5]
              })
            })
        ).then(() => {
          return Promise.all(
            Array.from(filePdf3)
              .map((file: any) => {
                
                return apiPresignedToken({
                  formType: PackFormType.CREW_PACK_PHOTO,
                  formId: crewPackId,
                  roleId: defaultRoleId,
                  serialId,
                 
                  preupload:'pdf'
                  
                }).then(async (responsePresign: any)=>{
                
                  if(responsePresign.data.documents) {
                  const fields = responsePresign.data.documents.fields;
                      fields['file'] = file;
                    return apiUploadFileS3({fields ,url: responsePresign.data.documents.url}).then((responseS3Upload)=>{
                      
                      return apiUploadPreloadData({
                        formType: PackFormType.CREW_PACK_PHOTO,
                        formId: crewPackId,
                        roleId: defaultRoleId,
                        serialId,
                        s3Filename: responsePresign.data.documents.name,
                        s3locationName: responsePresign.data.documents.location,
                       orginalfileName:responsePresign.data.documents.name,
                       s3fileExtension:'pdf',
                        projectInternalId: parseInt(projectInternalId),
                        IsAdministrator: data[0] == true ? 'Y' : 'N',
                      IsResourceScheduler: data[1] == true ? 'Y' : 'N',
                      IsProjectManager: data[2] == true ? 'Y' : 'N',
                      IsSupervisor: data[3] == true ? 'Y' : 'N',
                      IsForeman: data[4] == true ? 'Y' : 'N',
                      cpDocComment: data[5],


                      });
                    })
                  } 
                  console.log(file);
                  console.log(responsePresign)
                })
              })
          )
        })
          .then(() => {
            setIsLoading(false)
            alertDone()
            setShiftDocumentDialogOpen(false);
             refresh()
          }).catch((response: any) => {
           console.log(`response`);
           console.log(response);
           console.log(`response`);
           setIsLoading(false);
            alert({
              message: 'File is too big - please select another PDF file - Maximum 8mb.',
              type:'error'
            })
            // alertError();
           //   refresh()
          })
      } else {
        setIsLoading(false);
        setShiftDocumentDialogOpen(false);
        alertError();
      }

    } catch (e) {
      alertError()
    }
  }
  }
  const setFilesPdf = (files: FileList, imageFiles: FileList, nonImageFiles: FileList) => {
    console.log(files);
    console.log(nonImageFiles);
    setFilePdf1(files);
    setFilePdf2(imageFiles);
    setFilePdf3(nonImageFiles);
    var nonImageFilesArr: any = [];
    if (nonImageFiles.length > 0){
      for(var j = 0; j<nonImageFiles.length; j++) {
        nonImageFilesArr.push('(' + ((j + 1).toString()) + ')' + ' ' + nonImageFiles[j].name);
      }
      setCurrentSelectedFileName(nonImageFilesArr);
    }
  }
  const handleTabClick = async (label: string, index: number) => {
    setTabIndex(index);
  }
  const renderParts = () => {
    return [
      <WrapperDiv>
        <Wrapper>

          <RowWrapper>

            {isLoading && <SpinnerWrapper><Spinner size={24} /></SpinnerWrapper>}

            {!isLoading && <TriggerWrapper>
              <CrewPackUploadAttachmentTrigger accept='image/jpeg, image/png' id='drs-attach-actual' onSelect={handleFileSelect} attachments={normalAttachments} />
            </TriggerWrapper>}
            <RowFlex>
              {normalAttachments.map((attachment: Attachment) => {
                return attachment.fileType.description !== 'application/pdf' ? getImageAttachment(attachment) : null
              })}
            </RowFlex>
          </RowWrapper>



        </Wrapper>
        {isCarouselDialogOpen &&
          <CompCarouselDialog
            open={isCarouselDialogOpen}
            images={images}
            imageIndex={imageIndex}
            onClose={() => {
              setCarouselDialogOpen(false)
            }}
          />
        }
      </WrapperDiv>,
      <WrapperDiv>
        <Wrapper>

          <RowWrapper style={{ position:'relative' }}>

           

            
            {!isLoading &&  <PlusButton onClick={handleFileSelectDocument}><AddOutlinedIcon style={{color:'#009688',fontSize:'36px',fontWeight:'bold'}} /></PlusButton> }
          </RowWrapper>
         
          <TableContainer style={{ marginBottom: '15px',position:'relative' }}>
          
            <Table aria-labelledby="commentlist" style={{ border: '2px solid' }}>
              <TableHeaderCell>
                <TableRow>
                  <TableCellCusHead align="center">Document</TableCellCusHead>
                  <TableCellCusHead align="center">Comment</TableCellCusHead>
                  <TableCellCusHead align="center">Creator Name</TableCellCusHead>
                  <TableCellCusHead align="center">Date</TableCellCusHead>
                  <TableCellCusHead align="center">Action</TableCellCusHead>
                  {/* <TableCellCusHead align="center"></TableCellCusHead> */}
                </TableRow>
              </TableHeaderCell>
              <TableBody>
                {normalAttachments.map((attachment: Attachment) => {
                  return  attachment.fileType.description ==  'application/pdf' ?  <TableRow>
                    <TableCellCus  align="center" style={{position:'relative'}}>
                     
                      <img style={{ display: 'inline-block',cursor:'pointer' }} src={pdfIcon} height={50} onClick={() => {
                        handlePdfClick(attachment);
                      }} /><br/>
                       {attachment.originalName}
                     {!checkRole(attachment) &&  <LockDiv><LockIcon style={{color:'grey'}}/></LockDiv> }
                      </TableCellCus>
                    <TableCellCus align="center">{attachment.cpDocComment}</TableCellCus>
                    <TableCellCus align="center">{formatEmailToFirstLastName(attachment.email)}</TableCellCus>
                    <TableCellCus align="center">{formatDateStringDDMMYYYY(attachment.dateCreated)}</TableCellCus>
                    <TableCellCus align="center">
                      <CompThemeButton onClick={() => {
                         onEdit(attachment);
                      }}>
                        Edit
                      </CompThemeButton>
                      <CompDeleteButton onClick={() => {
                         onDelete(attachment);
                      }}>
                        DELETE
                      </CompDeleteButton>
                    </TableCellCus>
                  </TableRow>
                    : null 
                })}
              </TableBody>
            </Table>
          </TableContainer>


        </Wrapper>
        {isShiftDocumentDialogOpen &&
          <CrewPackShiftDocumentDialog
            open={isShiftDocumentDialogOpen}
            editMode={editMode}
            onCancel={() => {
              setShiftDocumentDialogOpen(false)
              setEditMode(false);
              setCurrentAttachment(null);
              setIsLoading(false);
              currentSelectedFileName.length = 0
              setCurrentSelectedFileName([]);
            }}
            onClose={() => {
              setShiftDocumentDialogOpen(false)
              setEditMode(false);
              setCurrentAttachment(null);
              setIsLoading(false);
              setCurrentSelectedFileName([]);
              currentSelectedFileName.length = 0
            }}
            onSuccess={() => {
              setShiftDocumentDialogOpen(false)
              setEditMode(false);
              setCurrentAttachment(null);
              setIsLoading(false);
              setCurrentSelectedFileName([]);
              currentSelectedFileName.length = 0
            }}
            onFileSlect={(files: FileList, imageFiles: FileList, nonImageFiles: FileList) => {
              setFilesPdf(files, imageFiles, nonImageFiles);
              // handleFileSelect(files,imageFiles,nonImageFiles);
            }}
            currentSelectedFileName={currentSelectedFileName}
            normalAttachments={normalAttachments}
            attachment={currentAttachment}
            onSave={(obj: any) => {
              handlePdfSave(obj);
              currentSelectedFileName.length = 0
            }}
            isLoader={isLoading}
          />
        }
      </WrapperDiv>
    ][tabIndex];

  }

  return <Wrapper >
    <Breadcrumbs items={[
      { type: 'link', text: `CrewPack ${crewPackId}`, url: `/crew-pack/${crewPackId}` },
      { type: 'text', text: `Photos & PDFs ${crewPackId}` },
    ]} />



    <TabsWrapper>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Tabs
            labels={[' Photos ', ' PDFs ']}
            value={tabIndex}
            onClick={handleTabClick}
          />
        </Grid>
        <Grid item xs={7}>

        </Grid>
      </Grid>

      <PartsWrapper>
        {renderParts()}
      </PartsWrapper>
    </TabsWrapper>

  </Wrapper>
}
const TabsWrapper = styled.div`
`
const MainRow = styled.div`
margin: 20px 20px;
`
const AttachDiv = styled.div`

`
const Wrapper = styled.div`
margin: 0 20px;
`
const LockDiv = styled.div`
position: absolute;
    right: 50%;
    top: 15px;
`
const PlusButton = styled.div`
position: absolute;
    right: 0;
    top: 0px;

    cursor:pointer
`
const TitleWrapper = styled.div`
  display: flex;
`
const SFormSectionTitle = styled(FormSectionTitle)`
  flex-grow: 1;
`
const ActionWrapper = styled.div`
`

const WrapperDiv = styled.div`
`
const RowWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0A9688;
`
const RowLabelPlanned = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
  width: 100px;
  color: #9A9898;
`
const RowLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
  width: 100px;
  color: #129688;
`
const TriggerWrapper = styled.div`
  margin-right: 16px;
`

const Spinner = styled(CircularProgress)`
  position: relative;
`

const SpinnerWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const PartsWrapper = styled.div`
  /* margin-top: 40px; */
`
const WrapperLabel = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  background-color: #E7F4F2;
  color: #129688;
  text-decoration: underline;
`
const Label = styled.label`
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  svg {
    position: relative;
    top: 6px;
  }
`
const TableHeaderCell = styled(TableHead)`
        background-color: #F5F5F5;`
const TableCellCusHead = styled(TableCell)({
  border: '2px solid #f7f8f9',
  color: '#707070',
  fontWeight: 'bold',
  fontSize: '16px'
})
const TableCellCus = styled(TableCell)({
  border: '2px solid #f7f8f9',
  wordBreak: 'break-all',
})
export default CrewPackPhotos