import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { MaterialAsphaltOtherProduct } from '@src/types/MaterialAsphaltOtherProduct'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'

const SRRMaterialAsphaltOtherFields: FormTableField<MaterialAsphaltOtherProduct>[] = [
  {
    key: 'grit',
    type: FormTableFieldType.Number,
    label: 'Grit (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'tipFees',
    type: FormTableFieldType.Text,
    label: 'Tip Fees',
  },
  {
    key: 'dialBeforeYouDig',
    type: FormTableFieldType.Text,
    label: 'Dial Before You Dig',
  },
  {
    key: 'glassGrid',
    type: FormTableFieldType.Text,
    label: 'Glass Grid',
  },
  {
    key: 'crackSealingDrums',
    type: FormTableFieldType.Text,
    label: 'Crack Sealing Drums',
  },
  {
    key: 'catEyeCovers',
    type: FormTableFieldType.Number,
    label: 'Cat eye covers',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'trafficLoops',
    type: FormTableFieldType.Text,
    label: 'Traffic Loops',
  },
  {
    key: 'hateliteBitac',
    type: FormTableFieldType.Number,
    label: 'Hatelite/Bitac (meters)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'roadBase',
    type: FormTableFieldType.Number,
    label: 'Road Base (T)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'sticksNStomps',
    type: FormTableFieldType.Number,
    label: 'Sticks & Stomps',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'dryIce',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Dry Ice',
    format: (cellData: MaterialAsphaltOtherProduct['dryIce']) => {
      if (cellData === null) {
        return ''
      }
      return formatBooleanToYesNo(cellData)
    },
  },
  {
    key: 'geoFabric',
    type: FormTableFieldType.Number,
    label: 'Geo fabric (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'emulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
]

export default SRRMaterialAsphaltOtherFields