import React, { useState, useEffect, useRef } from 'react'
import './Deviceinfo.css';
import { Container, Grid, GridList, Card, Modal, Box, Slider, Button, MenuItem, Select, LinearProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { HEREMAP_KEY } from '@src/constants';
import { Chart as ChartJS, registerables, ChartOptions } from 'chart.js';
import 'chartjs-adapter-moment';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { PollOutlined, Visibility, CropFree, Close, GridOn } from '@material-ui/icons';
import moment from 'moment';
import { apiDeviceInfo } from '../../apis/device-info';
import { apiDeviceFilter } from '../../apis/device-filter';
import { useHistory } from 'react-router-dom'
import { checkToken } from '../GenerateToken';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import DataLoadingError from '@src/assets/times-circle.svg';

ChartJS.register(...registerables)
// ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, Title, LinearScale, BarElement, PointElement, LineElement, Filler, TimeScale);
declare global {
  interface Window {
      H:any;
  }
}

const marks = [
    {
      value: 0,
      label: '12:00 AM',
    },
    {
      value: 25,
      label: '06:00 AM',
    },
    {
      value: 50,
      label: '12:00 PM',
    },
    {
      value: 75,
      label: '06:00 PM',
    },
    {
      value: 100,
      label: '12:00 AM',
    }
];

const BarChartData = {
    labels: ['Aug', 'Sep', 'Oct',  'Nov', 'Dec', 'Jan'],
    datasets: [{
        data: [3300, 2700, 850, 3000, 3900, 1150],
        backgroundColor: "rgba(2, 181, 173, 1)",
    }],
}

let tempChartData: any = {
    labels: [],
    datasets: [{
        data: [],
        fill: {
            target: 'origin',
            above: ''
        },
        backgroundColor: "",
        borderColor: "",
        pointRadius: 1,
        pointHoverRadius: 2
    }],
}

const defaultSparklines = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
let speedChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let distanceChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let asphaltChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let ambientChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let ignitionChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let screedChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let forwardtravelChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let enginehoursChartDataRef = JSON.parse(JSON.stringify(tempChartData));
let screedhoursChartDataRef = JSON.parse(JSON.stringify(tempChartData));
speedChartDataRef.datasets[0].backgroundColor = "#e083004f";
speedChartDataRef.datasets[0].borderColor = "#e08300";
speedChartDataRef.datasets[0].fill.above = "#e083004f";
distanceChartDataRef.datasets[0].backgroundColor = "#ff45004f";
distanceChartDataRef.datasets[0].borderColor = "#ff4500";
distanceChartDataRef.datasets[0].fill.above = "#ff45004f"
asphaltChartDataRef.datasets[0].backgroundColor = "#c61f2f4f";
asphaltChartDataRef.datasets[0].borderColor = "#c61f2f";
asphaltChartDataRef.datasets[0].fill.above = "#c61f2f4f";
ambientChartDataRef.datasets[0].backgroundColor = "#3c88b74f";
ambientChartDataRef.datasets[0].borderColor = "#3c88b7";
ambientChartDataRef.datasets[0].fill.above = "#3c88b74f";
ignitionChartDataRef.datasets[0].backgroundColor = "#c4a1484f";
ignitionChartDataRef.datasets[0].borderColor = "#c4a148";
ignitionChartDataRef.datasets[0].fill.above = "#c4a1484f";
screedChartDataRef.datasets[0].backgroundColor = "#7d45c14f";
screedChartDataRef.datasets[0].borderColor = "#7d45c1";
screedChartDataRef.datasets[0].fill.above = "#7d45c14f";
forwardtravelChartDataRef.datasets[0].backgroundColor = "#3c88b74f";
forwardtravelChartDataRef.datasets[0].borderColor = "#3c88b7";
forwardtravelChartDataRef.datasets[0].fill.above = "#3c88b74f";
enginehoursChartDataRef.datasets[0].backgroundColor = "#ffff004f";
enginehoursChartDataRef.datasets[0].borderColor = "#ffff00";
enginehoursChartDataRef.datasets[0].fill.above = "#ffff004f";
screedhoursChartDataRef.datasets[0].backgroundColor = "#730e494f";
screedhoursChartDataRef.datasets[0].borderColor = "#730e49";
screedhoursChartDataRef.datasets[0].fill.above = "#730e494f";
   

const peiChartDefault = [0, 0, 0, 0, 0, 100];
let pieChartData: any = {
    labels: ['Isolated', 'Ignition Off', 'Ignition On', 'Engine Running', 'Paving', 'Offline'],
    datasets: [{
        data: peiChartDefault,
        backgroundColor:[
            "rgb(49, 208, 217, 1)",
            "rgb(252, 192, 39, 1)",
            "rgb(91, 155, 213, 1)",
            "rgb(0, 229, 166, 1)",
            "rgb(220, 57, 18, 1)",
            "rgb(144, 144, 144, 1)"
        ],
        borderWidth: 0,
    }],
}
const pieChartoptions = {
    responsive: true,
    plugins: {
        legend: {
            display:false
        },
        tooltip: {
            callbacks: {
                title: (tooltipItems: any) => {
                    const label = tooltipItems[0].label;
                    const dataset = tooltipItems[0].dataset.data;
                    const currentValue = tooltipItems[0].parsed;
                    let total = 0;
                    for (let i = 0; i < dataset.length; i++) {
                      total += dataset[i];
                    }
                    const percentage = (currentValue / total * 100).toFixed(0);
                    return `${percentage}%`;
                },
            },
        },
    }
};

const LineChartOptions: any = {
    scales: {
        x: {
            parsing: false,
            type: "time",
            distribution: "linear",
            ticks: {
                source: "auto"
            },
            time: {
                displayFormats: {
                    hour: "MMM D hA",
                    day: "MMM D hA",
                }
            }
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: ''
            },
            // ticks: {
            //     precision:0
            // },
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: (data: any) => {
                    return `${data.raw} km/h`;
                },
                title: (t:any) => {
                    return `${moment.unix(t[0].label).format('MMM DD YYYY, HH:mm:ss A')}`
                }
            }
        },
    },
    responsive: true,
    maintainAspectRatio: true
} as ChartOptions;
let speedChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let distanceChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let asphaltChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let ambientChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let ignitionChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let screedChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let forwardtravelChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let enginehoursChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
let screedhoursChartOptions = JSON.parse(JSON.stringify(LineChartOptions));
speedChartOptions.scales.y.title.text = "mtrs/min";
speedChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} mtrs/min`;};
distanceChartOptions.scales.y.title.text = "km/h";
distanceChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} km/h`;};
asphaltChartOptions.scales.y.title.text = "°C";
asphaltChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} °C`;};
ambientChartOptions.scales.y.title.text = "°C";
ambientChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} °C`;};
enginehoursChartOptions.scales.y.title.text = "hrs";
enginehoursChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} hrs`;};
screedhoursChartOptions.scales.y.title.text = "hrs";
screedhoursChartOptions.plugins.tooltip.callbacks.label = (data: any) => {return `${data.raw} hrs`;};
const BarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false
};

const comments = [
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Louis Matthees", topic: "Service Entry: #444", message: "Repaired passenger side door and lower rocler panel.", date: "15-11-2021 17:30:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Martha Godwin", topic: "Service Entry: #36", message: "Fixed cracked windshield. Didn't have to replace.", date: "15-11-2021 15:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Robert Watkins", topic: "issue: #160312", message: "Don't know how much longer it will last", date: "15-11-2021 14:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Kellie Linden", topic: "Fuel Entry: #229", message: "Repaired passenger side door and lower rocler panel.", date: "14-11-2021 17:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Juan Miller", topic: "Contact: #49", message: "Repaired passenger side door and lower rocler panel.", date: "10-11-2021 17:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Louis Matthees", topic: "Service Entry: #444", message: "Repaired passenger side door and lower rocler panel.", date: "15-11-2021 17:30:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Martha Godwin", topic: "Service Entry: #36", message: "Fixed cracked windshield. Didn't have to replace.", date: "15-11-2021 15:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Robert Watkins", topic: "issue: #160312", message: "Don't know how much longer it will last", date: "15-11-2021 14:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Kellie Linden", topic: "Fuel Entry: #229", message: "Repaired passenger side door and lower rocler panel.", date: "14-11-2021 17:00:00"},
    {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Juan Miller", topic: "Contact: #49", message: "Repaired passenger side door and lower rocler panel.", date: "10-11-2021 17:00:00"},
    // {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Louis Matthees", topic: "Service Entry: #444", message: "Repaired passenger side door and lower rocler panel.", date: "15-11-2021 17:30:00"},
    // {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Martha Godwin", topic: "Service Entry: #36", message: "Fixed cracked windshield. Didn't have to replace.", date: "15-11-2021 15:00:00"},
    // {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Robert Watkins", topic: "issue: #160312", message: "Don't know how much longer it will last", date: "15-11-2021 14:00:00"},
    // {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Kellie Linden", topic: "Fuel Entry: #229", message: "Repaired passenger side door and lower rocler panel.", date: "14-11-2021 17:00:00"},
    // {image: "https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg", user: "Juan Miller", topic: "Contact: #49", message: "Repaired passenger side door and lower rocler panel.", date: "10-11-2021 17:00:00"},
];

let mapDiv: any, map: any, location: any, interval: any = null, pieChartRef: any;
const DeviceInfoPage = (props: any) => {
    const [device, setDevice]: any = useState([])
    const [speedChart,setSpeedChart] = useState(false);
    const [distanceChart,setDistanceChart] = useState(false);
    const [ambientChart,setAmbientChart] = useState(false);
    const [asphaltChart,setAsphaltChart] = useState(false);
    const [ignitionChart,setIgnitionChart] = useState(false);
    const [screedChart,setScreedChart] = useState(false);
    const [forwardtravelChart,setForwardTravelChart] = useState(false);
    const [enginehoursChart,setEngineHoursChart] = useState(false);
    const [screedhoursChart,setScreedHoursChart] = useState(false);
    const [errorspeedChart,setErrorSpeedChart] = useState(false);
    const [errordistanceChart,setErrorDistanceChart] = useState(false);
    const [errorambientChart,setErrorAmbientChart] = useState(false);
    const [errorasphaltChart,setErrorAsphaltChart] = useState(false);
    const [errorignitionChart,setErrorIgnitionChart] = useState(false);
    const [errorscreedChart,setErrorScreedChart] = useState(false);
    const [errorforwardtravelChart,setErrorForwardTravelChart] = useState(false);
    const [errorenginehoursChart,setErrorEngineHoursChart] = useState(false);
    const [errorscreedhoursChart,setErrorScreedHoursChart] = useState(false);
    const [ChartState,setChartState] = useState(0);
    const [fullChartState,setfullChartState] = useState(0);
    const [SpeedData, setSpeedData]: any = useState(defaultSparklines);
    const [DistanceData, setDistancData]: any = useState(defaultSparklines);
    const [AmbientData, setAmbientData]: any = useState(defaultSparklines);
    const [AsphaltData, setAsphaltData]: any = useState(defaultSparklines);
    const [IgnitionData, setIgnitionData]: any = useState(defaultSparklines);
    const [ScreedData, setScreedData]: any = useState(defaultSparklines);
    const [ForwardData, setForwardData]: any = useState(defaultSparklines);
    const [IgnitionHourData, setIgnitionHourData]: any = useState(defaultSparklines);
    const [ScreedHourData, setScreedHourData]: any = useState(defaultSparklines);
    const [ChartModal, setChartModal] = React.useState(false);
    const [ChartRangeModal, setChartRangeModal] = React.useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'weeks'));
    const [endDate, setEndDate] = useState(moment());
    const [startTime, setStart] = useState(moment().subtract(30, 'minutes').unix().valueOf());
    const [endTime, setEnd] = useState(moment().unix().valueOf());
    const handleOpen = () => setChartModal(true);
    const handleClose = () => setChartModal(false);
    const handleChartRangeOpen = () => setChartRangeModal(true);
    const handleChartRangeClose = () => setChartRangeModal(false);
    const [CurrentChart, setCurrentChart]: any = useState(['']);
    const [ShowChartLoader, setShowChartLoader]: any = useState(false);
    const [speedChartData, setspeedChartData] = useState(speedChartDataRef);
    const [distanceChartData, setdistanceChartData] = useState(distanceChartDataRef);
    const [asphaltChartData, setasphaltChartData] = useState(asphaltChartDataRef);
    const [ambientChartData, setambientChartData] = useState(ambientChartDataRef);
    const [ignitionChartData, setignitionChartData] = useState(ignitionChartDataRef);
    const [screedChartData, setscreedChartData] = useState(screedChartDataRef);
    const [forwardtravelChartData, setforwardtravelChartData] = useState(forwardtravelChartDataRef);
    const [enginehoursChartData, setenginehoursChartData] = useState(enginehoursChartDataRef);
    const [screedhoursChartData, setscreedhoursChartData] = useState(screedhoursChartDataRef);
    const history = useHistory();
    const mapRef = React.createRef();
    let pieChart: any = React.createRef();
    const H = window.H;
    const platform = new H.service.Platform({
        apikey: HEREMAP_KEY
    });
    let deviceid = props.match.params.id;
    const defaultLayers = platform.createDefaultLayers();

    useEffect(() => {
        fetchDeviceInfo();
        fetchDeviceFilter();
        // mapDiv = mapRef.current;
        // mapDiv = mapRef.current;
        pieChartRef = pieChart;
        map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
            center: { lat: 50, lng: 5 },
            zoom: 6,
            pixelRatio: window.devicePixelRatio || 1
          }
        )
        location = new H.map.Marker({ lat: 50, lng: 5 });
        map.addObject(location);
        const ui = H.ui.UI.createDefault(map, defaultLayers);
        ui.getControl('mapsettings').setVisibility(false)
        ui.getControl('scalebar').setVisibility(false)
        interval = setInterval(() => {
            fetchDeviceInfo();
        }, 10000);
        return () => {
            if(interval) {
                clearInterval(interval)
            }
            const state = {...pieChartData};
            for(let i = 0; i < 6; i++) {
                state.datasets[0].data[i] = i < 5 ? 0 : 100;
            }
        };
    }, []);
    
    const fetchDeviceInfo = async () => {
        //const result: any = await apiDeviceInfo(getCookieAccessToken(), deviceid)
        const status = await checkToken();
        if(status) {
            const result: any = await apiDeviceInfo(localStorage.getItem('apiToken'), deviceid);
            if(!result){fetchDeviceInfo()}
            if(result && result.data.length > 0) {
                const latlon= result.data[0];
                setDevice(result.data[0]);
                if(latlon.gps_lat && latlon.gps_lon) {
                    map.setCenter({"lat": latlon.gps_lat,"lng": latlon.gps_lon});
                    location.setGeometry({lat: latlon.gps_lat, lng: latlon.gps_lon});
                }
            }
        } else {
            history.push({
                pathname: '/dashboard/projects',
            })
        }
    }
    
    const fetchDeviceFilter = async () => {
        const end = moment().unix().valueOf();
        const start = moment().subtract(12, 'hours').unix().valueOf();
        setFilterData(start, end);
    }

    const fetchChartFilter = async (time: any) => {
        const end = moment().unix().valueOf();
        // const end = Date.now();
        const start = await getStartTime(time);
        boxChartData(start, end, time);
    }

    const getStartTime = (time: any) => {
        if(time === 'month') {
            return moment().subtract(1, 'months').unix().valueOf();
        } else if(time === 'week') {
            return moment().subtract(1, 'weeks').unix().valueOf();
        } else if(time === 'day') {
            return moment().subtract(1, 'days').unix().valueOf();
        } else if(time === 'hour') {
            return moment().subtract(1, 'hours').unix().valueOf();
        } else {
            return moment().subtract(12, 'hours').unix().valueOf();
        }
    }

    // const handleStatus = (box: string) => {
    //     if(box == "speed"){
    //         setSpeedChart(!speedChart);
    //     }else if(box == "distance"){
    //         setDistanceChart(!distanceChart);
    //     }else if(box == "ambient"){
    //         setAmbientChart(!ambientChart);
    //     }
    // }

    const status = (state: any = 0) => {
        if(state == 1){return "Isolated"}
        if(state == 2){return "Ignition Off"}
        if(state == 3){return "Ignition On"}
        if(state == 4){return "Engine Running"}
        if(state == 5){return "Paving"} else {return "Unknown"}
    }

    const boxChartData = async (start: any, end: any, time: any) => {
        setShowChartLoader(true);
        const status = await checkToken();
        if(status) {
            // const result: any = await apiDeviceFilter(getCookieAccessToken(), deviceid, start, end);
            const result: any = await apiDeviceFilter(localStorage.getItem('apiToken'), deviceid, start, end);
            let labels = [], dataRef = [], index = 0;
            if(result && result.data.length > 0) {
                let speed = [], distance = [], ambient = [], asphalt = [], ignition = [], screed = [], forwardtravel = [], enginehours = [], screedhours = [];
                const data: any = result.data;
                for(let i = 0, len = data.length; i < len; ++i){
                    if(CurrentChart == 'speed' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].cp3 ? data[i].cp3 : 0);
                    } else if(CurrentChart == 'speed' && time == "default") {
                        speed.push(data[i].cp3 ? data[i].cp3 : 0);
                    }
                    if(CurrentChart == 'distance' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].cp2 ? data[i].cp2 : 0);
                    }  else if(CurrentChart == 'distance' && time == "default") {
                        distance.push(data[i].cp2 ? data[i].cp2 : 0);
                    }
                    if(CurrentChart == 'ambient' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].ambient ? data[i].ambient : 0);
                    }  else if(CurrentChart == 'ambient' && time == "default") {
                        ambient.push(data[i].ambient ? data[i].ambient : 0);
                    }
                    if(CurrentChart == 'asphalt' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].current1 ? data[i].current1 : 0);
                    }  else if(CurrentChart == 'asphalt' && time == "default") {
                        asphalt.push(data[i].current1 ? data[i].current1 : 0);
                    }
                    if(CurrentChart == 'ignition' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].digital1 ? data[i].digital1 : 0);
                    } else if(CurrentChart == 'ignition' && time == "default") {
                        ignition.push(data[i].digital1 ? data[i].digital1 : 0);
                    }
                    if(CurrentChart == 'screed' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].digital2 ? data[i].digital2 : 0);
                    }  else if(CurrentChart == 'screed' && time == "default") {
                        screed.push(data[i].digital2 ? data[i].digital2 : 0);
                    }
                    if(CurrentChart == 'forwardtravel' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].digital3  ? data[i].digital3 : 0);
                    }  else if(CurrentChart == 'forwardtravel' && time == "default") {
                        forwardtravel.push(data[i].digital3 ? data[i].digital3 : 0);
                    }
                    if(CurrentChart == 'enginehours' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].cp4 ? data[i].cp4 : 0);
                    }  else if(CurrentChart == 'enginehours' && time == "default") {
                        enginehours.push(data[i].cp4 ? data[i].cp4 : 0);
                    }
                    if(CurrentChart == 'screedhours' && time != "default") {
                        labels.push(new Date(1e3 * data[i].ts));
                        dataRef.push(data[i].digital2_hrs ? data[i].digital2_hrs : 0);
                    }  else if(CurrentChart == 'screedhours' && time == "default") {
                        screedhours.push(data[i].digital2_hrs ? data[i].digital2_hrs : 0);
                    }
                    if(i === (len - 1)) {
                        if(CurrentChart == 'speed' && time != "default"){
                            let tempData = speedChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setspeedChartData(tempData);
                            setSpeedChart(true);
                            setErrorScreedChart(false);
                        } else if(CurrentChart == 'speed' && time == "default") {
                            setSpeedChart(false);
                            setErrorScreedChart(false);
                            setSpeedData(speed);
                        }
                        if(CurrentChart == 'distance' && time != "default"){
                            let tempData = distanceChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setdistanceChartData(tempData);
                            setDistanceChart(true);
                            setErrorDistanceChart(false);
                        } else if(CurrentChart == 'distance' && time == "default") {
                            setErrorDistanceChart(false);
                            setDistanceChart(false);
                            setDistancData(distance);
                        }
                        if(CurrentChart == 'asphalt' && time != "default"){
                            let tempData = asphaltChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setasphaltChartData(tempData);
                            setErrorAsphaltChart(false);
                            setAsphaltChart(true);
                        } else if(CurrentChart == 'asphalt' && time == "default") {
                            setErrorAsphaltChart(false);
                            setAsphaltChart(false);
                            setAsphaltData(asphalt);
                        }
                        if(CurrentChart == 'ambient' && time != "default"){
                            let tempData = ambientChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setambientChartData(tempData);
                            setAmbientChart(true);
                            setErrorAmbientChart(false);
                        } else if(CurrentChart == 'ambient' && time == "default") {
                            setErrorAmbientChart(false);
                            setAmbientChart(false);
                            setAmbientData(ambient);
                        }
                        if(CurrentChart == 'ignition' && time != "default"){
                            let tempData = ignitionChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setignitionChartData(tempData);
                            setIgnitionChart(true);
                            setErrorIgnitionChart(false);
                        } else if(CurrentChart == 'ignition' && time == "default") {
                            setErrorIgnitionChart(false);
                            setIgnitionChart(false);
                            setIgnitionData(ignition);
                        }
                        if(CurrentChart == 'screed' && time != "default"){
                            let tempData = screedChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setscreedChartData(tempData);
                            setScreedChart(true);
                            setErrorScreedChart(false);
                        } else if(CurrentChart == 'screed' && time == "default") {
                            setErrorScreedChart(false);
                            setScreedChart(false);
                            setScreedData(screed);
                        }
                        if(CurrentChart == 'forwardtravel' && time != "default"){
                            let tempData = forwardtravelChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setforwardtravelChartData(tempData);
                            setForwardTravelChart(true);
                            setErrorForwardTravelChart(false);
                        } else if(CurrentChart == 'forwardtravel' && time == "default") {
                            setErrorForwardTravelChart(false);
                            setForwardTravelChart(false);
                            setForwardData(forwardtravel);
                        }
                        if(CurrentChart == 'enginehours' && time != "default"){
                            let tempData = enginehoursChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setenginehoursChartData(tempData);
                            setEngineHoursChart(true);
                            setErrorEngineHoursChart(false);
                        } else if(CurrentChart == 'enginehours' && time == "default") {
                            setErrorEngineHoursChart(false);
                            setEngineHoursChart(false);
                            setIgnitionHourData(enginehours);
                        }
                        if(CurrentChart == 'screedhours' && time != "default"){
                            let tempData = screedhoursChartData;
                            tempData.labels = labels;
                            tempData.datasets[0].data = dataRef;
                            setscreedhoursChartData(tempData);
                            setScreedHoursChart(true);
                            setErrorScreedHoursChart(false);
                        } else if(CurrentChart == 'screedhours' && time == "default") {
                            setErrorScreedHoursChart(false);
                            setScreedHoursChart(false);
                            setScreedHourData(screedhours);
                        }
                        setShowChartLoader(false);
                    }
                }
            } else {
                if(CurrentChart == 'speed'){ setErrorSpeedChart(true); setSpeedChart(false); } 
                if(CurrentChart == 'distance'){ setErrorDistanceChart(true); setDistanceChart(false) }
                if(CurrentChart == 'asphalt'){ setErrorAsphaltChart(true); setAsphaltChart(false) } 
                if(CurrentChart == 'ambient'){ setErrorAmbientChart(true); setAmbientChart(false) }
                if(CurrentChart == 'ignition'){ setErrorIgnitionChart(true); setIgnitionChart(false) }
                if(CurrentChart == 'screed'){ setErrorScreedChart(true); setScreedChart(false) }
                if(CurrentChart == 'forwardtravel'){ setErrorForwardTravelChart(true); setForwardTravelChart(false) }
                if(CurrentChart == 'enginehours'){ setErrorEngineHoursChart(true); setEngineHoursChart(false) }
                if(CurrentChart == 'screedhours'){ setErrorScreedHoursChart(true); setScreedHoursChart(false) }
                setShowChartLoader(false);
            }
        } else {
            history.push({
                pathname: '/dashboard/projects',
            })
        }
    }

    const setFilterData = async (start: any, end: any) => {
        const status = await checkToken();
        if(status) {
            // const result: any = await apiDeviceFilter(getCookieAccessToken(), deviceid, start, end);
            const result: any = await apiDeviceFilter(localStorage.getItem('apiToken'), deviceid, start, end);
            if(!result){fetchDeviceFilter()}
            if(result && result.data.length > 0) {
                setFilteredChartData(result.data);
                result.data = result.data.reverse();
                setDevice(result.data[0]);
                const latlon = result.data[0];
                result.data.splice(0,1);
                if(latlon.gps_lat && latlon.gps_lon) {
                    map.setCenter({"lat": latlon.gps_lat,"lng": latlon.gps_lon});
                    location.setGeometry({lat: latlon.gps_lat, lng: latlon.gps_lon});
                }
            } else if(result && result.data.length == 0){
                const state = {...pieChartData};
                for(let i = 0; i < 6; i++) {
                    state.datasets[0].data[i] = i < 5 ? 0 : 100;
                }
                pieChartRef.update();
                setSpeedData(defaultSparklines);
                setDistancData(defaultSparklines);
                setAmbientData(defaultSparklines);
                setAsphaltData(defaultSparklines);
                setIgnitionData(defaultSparklines);
                setScreedData(defaultSparklines);
                setForwardData(defaultSparklines);
                setIgnitionHourData(defaultSparklines);
                setScreedHourData(defaultSparklines);
            }
        } else {
            history.push({
                pathname: '/dashboard/projects',
            })
        }
    }

    const setFilteredChartData = (data: any) => {
        const state = {...pieChartData};
        let speed = [], distance = [], ambient = [], asphalt = [], ignition = [], screed = [], forward = [], ignitionhour = [], screedhour = [];
        for(let i = 0, len = data.length; i < len; ++i){
            speed.push(data[i].cp3 ? data[i].cp3 : 0);
            distance.push(data[i].cp2 ? data[i].cp2 : 0);
            ambient.push(data[i].ambient ? data[i].ambient : 0);
            asphalt.push(data[i].current1 ? data[i].current1 : 0);
            ignition.push(data[i].digital1 ? data[i].digital1 : 0);
            screed.push(data[i].digital2 ? data[i].digital2 : 0);
            forward.push(data[i].digital3 ? data[i].digital3 : 0);
            ignitionhour.push(data[i].cp4 ? data[i].cp4 : 0);
            screedhour.push(data[i].digital2_hrs ? data[i].digital2_hrs : 0);
            if(data[i].state && data[i].state > 0) {
                state.datasets[0].data[data[i].state] += 1;
            }
            if(!data[i].state || data[i].state == 0) {
                state.datasets[0].data[5] += 1;
            }
        }
        setSpeedData(speed);
        setDistancData(distance);
        setAmbientData(ambient);
        setAsphaltData(asphalt);
        setIgnitionData(ignition);
        setScreedData(screed);
        setForwardData(forward);
        setIgnitionHourData(ignitionhour);
        setScreedHourData(screedhour);
        pieChartRef.update();
    }

    const setstartdate = (date:any) => {
        setStart(moment(date).unix().valueOf());
        setStartDate(date);
    }
    const setenddate = (date:any) => {
        setEnd(moment(date).unix().valueOf());
        setEndDate(date);
    }

    const customTime = (data: any = 0) => {
        if(data > 0 && checkIsFloat(data)) {
            return `${(data).toString().split('.')[0]} h ${(data).toString().split('.')[1]} m`
        } else if(data > 0 && !checkIsFloat(data)){
            return `${(data).toString().split('.')[0]} h 0 m`
        } else {
            return '0 h 0 m';
        }
    }

    const checkIsFloat = (val: any) => {
        return Number(val) === val && val % 1 !== 0;
    }
    const setchartstate = (e: any) => {
        setChartState(e.target.value);
    }

    return <Container maxWidth='xl' style={{padding: '15px'}}>
        <GridList cols={1} cellHeight="auto">
            <Grid style={{textAlign: 'center'}}>
                <div className='page-header'>
                    <ActionButton className='raw-data' startIcon={<GridOn />} onClick={() => {
                        history.push(`/device/rawdata/${deviceid}`);
                    }}>Raw Data</ActionButton>
                    <h2 style={{margin: '0'}}>NPV104</h2>
                    <h2 style={{marginTop: '0', fontSize: '1.2rem'}}>PAVER: LEASED-Roadtec 2.5m (RP175EX)</h2>
                </div>
                <GridList cols={5} cellHeight="auto" className="box-area">
                    <Grid className={speedChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Speed <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('speed');}} /></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'speed' ? <div className='chart-loader'><div></div></div> : ''}
                                {speedChart ? <div className="box-chart"> 
                                        <Line data={speedChartData} options={speedChartOptions} redraw={true}/>
                                    </div> : 
                                        errorspeedChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div>
                                    : <>
                                    <h1 style={{margin: '0'}}>{device.cp3 ? device.cp3 : 0} mtrs/min</h1>
                                    <div className="line-chart">
                                        <Sparklines data={SpeedData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#f8c397", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div> </>
                                }
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[cp3] <VisibilityCus /></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={distanceChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                        <div style={cardHeaderStyle}>Distance Travelled <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('distance');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'distance' ? <div className='chart-loader'><div></div></div> : ''}
                                {distanceChart ? 
                                    <div className="box-chart"> 
                                        <Line data={distanceChartData} options={distanceChartOptions} redraw={true}/>
                                    </div> :
                                        errordistanceChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                    : <>
                                    <h1 style={{margin: '0'}}>{device.cp2 ? device.cp2 : 0.4} km</h1>
                                    <div className="line-chart">
                                        <Sparklines data={DistanceData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#f9ac98", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[cp2] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Status <PollOutlinedCus /></div>
                            <div style={statecardContentStyle}>
                                <h1 style={{margin: '0', textTransform: 'uppercase'}}>{status(device.state)}</h1>
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[cp1] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={asphaltChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Asphalt Temp <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('asphalt');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'asphalt' ? <div className='chart-loader'><div></div></div> : ''}
                                {asphaltChart ? 
                                    <div className="box-chart"> 
                                        <Line data={asphaltChartData} options={asphaltChartOptions} redraw={true}/>
                                    </div> : 
                                        errorasphaltChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                    : <> 
                                    <h1 style={{margin: '0'}}>{device.current1} degC</h1>
                                    <div className="line-chart">
                                        <Sparklines data={AsphaltData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#c4535b", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[current1] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={ambientChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                        <div style={cardHeaderStyle}>Ambient Temp<PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('ambient');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'ambient' ? <div className='chart-loader'><div></div></div> : ''}
                                {ambientChart ?  <div className="box-chart"> 
                                    <Line data={ambientChartData} options={ambientChartOptions} redraw={true}/>
                                </div> :  
                                    errorambientChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{device.ambient ? device.ambient : "31.7"} °C</h1>
                                    <div className="line-chart">
                                        <Sparklines data={AmbientData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#9bbfd7", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </> }
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[ambient] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={ignitionChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Ignition<PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('ignition');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'ignition' ? <div className='chart-loader'><div></div></div> : ''}
                                {ignitionChart ? <div className="box-chart"> 
                                    <Line data={ignitionChartData} options={ignitionChartOptions} redraw={true}/>
                                </div> :  
                                    errorignitionChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{device.digital1 ? device.digital1 : '0'}</h1>
                                    <div className="line-chart">
                                        <Sparklines data={IgnitionData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#e0cba6", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[digital1] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={screedChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Screed <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('screed');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'screed' ? <div className='chart-loader'><div></div></div> : ''}
                                {screedChart ?  <div className="box-chart"> 
                                    <Line data={screedChartData} options={screedChartOptions} redraw={true}/>
                                </div>: 
                                    errorscreedChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{device.digital2 ? device.digital2: '0'}</h1>
                                    <div className="line-chart">
                                        <Sparklines data={ScreedData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#c0a6dd", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[digital2] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={forwardtravelChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Forward Travel <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('forwardtravel');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'forwardtravel' ? <div className='chart-loader'><div></div></div> : ''}
                                {forwardtravelChart ? <div className="box-chart"> 
                                    <Line data={forwardtravelChartData} options={forwardtravelChartOptions} redraw={true}/>
                                </div> : 
                                    errorforwardtravelChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{device.digital3 ? device.digital3 : '0'}</h1>
                                    <div className="line-chart">
                                        <Sparklines data={ForwardData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#c0a6dd", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[digital3] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={enginehoursChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Engine Hours<PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('enginehours');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'enginehours' ? <div className='chart-loader'><div></div></div> : ''}
                                {enginehoursChart ? <div className="box-chart"> 
                                    <Line data={enginehoursChartData} options={enginehoursChartOptions} redraw={true}/>
                                </div> : 
                                    errorenginehoursChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{customTime(device.cp4)}</h1>
                                    <div className="line-chart">
                                        <Sparklines data={IgnitionHourData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#dfb9a2", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[digital1_hrs] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                    <Grid className={screedhoursChart && !ShowChartLoader ? 'large-chart' : ''} style={{padding: '5px 0px', paddingTop: '17%', marginBottom: 10, position: 'relative'}}>
                        <CardCus>
                            <div style={cardHeaderStyle}>Screed Hours <PollOutlinedCus onClick={() => {setChartRangeModal(true); setCurrentChart('screedhours');}}/></div>
                            <div style={cardContentStyle}>
                                {ShowChartLoader && CurrentChart === 'screedhours' ? <div className='chart-loader'><div></div></div> : ''}
                                {screedhoursChart ? <div className="box-chart"> 
                                    <Line data={screedhoursChartData} options={screedhoursChartOptions} redraw={true}/>
                                </div>: 
                                    errorscreedhoursChart ? <div style={{textAlign: 'center'}}><img src={DataLoadingError} style={{width: '3em'}} /><div>Error Loading Data</div></div> 
                                : <>
                                    <h1 style={{margin: '0'}}>{customTime(device.digital2_hrs)}</h1>
                                    <div className="line-chart">
                                        <Sparklines data={ScreedHourData} limit={20} width={213} height={69}>
                                            <SparklinesSpots size={3}></SparklinesSpots>
                                            <SparklinesLine style={{ stroke: "#bd95a6", strokeWidth: 3, fill: "none" }} ></SparklinesLine>
                                        </Sparklines>
                                    </div>
                                </>}
                            </div>
                            <div style={cardBottomStyle}>
                                <span>{moment.unix(device.ts).format("DD-MMM-YYYY")} <br /> {moment.unix(device.ts).format("HH:mm:ss")}</span>
                                <span>[digital2_hrs] <VisibilityCus/></span>
                            </div>
                        </CardCus>
                    </Grid>
                </GridList>
            </Grid>
        </GridList>
        <GridList cols={2} cellHeight="auto">
            <Grid style={{width: '70%'}}>
                <GridList cols={1} cellHeight="auto">
                    <Grid>
                        <GridList cols={2} cellHeight="auto">
                            <Grid style={{width: '70%'}}>
                                <GridList cols={1} cellHeight="auto">
                                        <Grid>
                                            <div ref={mapRef as React.RefObject<HTMLDivElement> } style={{ height: "350px", width: '100%' }} />
                                            {/* <Slider defaultValue={75} step={1} aria-label="Small" valueLabelDisplay="auto" marks={marks} style={{marginLeft: '30px', width: '87%'}} /> */}
                                        </Grid>
                                        <Grid>
                                            <div style={{display: 'flex', justifyContent: 'space-around', marginBottom:'20px', marginTop:'20px'}}>
                                                <div style={{width: '49%'}}>
                                                    <div style={customDivHead}>
                                                        <h2 style={{margin: '0'}}>Device Info</h2>
                                                    </div>
                                                    <div style={{padding: '0 5px'}}>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Device ID:</b></span> {device.deviceid}
                                                        </div>
                                                        <div style={divEvenStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Model:</b></span> ORB-C1-G
                                                        </div>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Firmware:</b></span> SFW001-4.1.1-Dev-00pa
                                                        </div>
                                                        <div style={divEvenStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Bass Interval:</b></span> 5 seconds
                                                        </div>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Wifi IP:</b></span> {device.wifi_ip}
                                                        </div>
                                                        <div style={divEvenStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Wifi Signal:</b></span> {device.wifi_rssi ? device.wifi_rssi : '0'} dbm
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '49%', position: 'relative'}}>
                                                    <div style={customDivHead}>
                                                        <h2 style={{margin: '0'}}>GPS Info</h2>
                                                    </div>
                                                    <div style={{padding: '0 5px'}}>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Lat/Lon:</b></span> {device.gps_lat ? device.gps_lat : 0}, {device.gps_lon ? device.gps_lon : 0}
                                                        </div>
                                                        <div style={divEvenStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Satelites:</b></span> {device.gps_nsat}
                                                        </div>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>HDOP:</b></span> {device.gps_hdop}
                                                        </div>
                                                        <div style={divEvenStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Heading:</b></span> 201.6 °
                                                        </div>
                                                        <div style={divOddStyle}>
                                                            <span style={{marginRight: '5px'}}><b>Altitude:</b></span> {device.gps_altitude && `${device.gps_altitude} m`}
                                                        </div>
                                                    </div>
                                                    <div style={customDivBottom}>
                                                        <span>{moment.unix(device.ts).format("DD-MMM-YYYY HH:MM:SS")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                </GridList>
                            </Grid>
                            <Grid style={{width: '30%'}}>
                                <GridList cols={1} cellHeight="auto">
                                    <Grid style={{padding: '2px 5px'}}>
                                        <Select disableUnderline={true} id="group-select" value={ChartState} style={selectStyle} onChange={setchartstate}>
                                            <MenuItem value={0}>All Groups</MenuItem>
                                            <MenuItem value={1}>Fuel Costs</MenuItem>
                                            <MenuItem value={2}>Utilisation</MenuItem>
                                        </Select>
                                        {ChartState === 0 || ChartState === 1 ? 
                                            <>
                                                <div className='chart-title'>
                                                    <h3>Fuel Costs</h3>
                                                    <ButtonIcon className="icon-btn" startIcon={<CropFree />} onClick={() => { setfullChartState(0); handleOpen();}}></ButtonIcon>
                                                </div>
                                                <div className='bar-chart'>
                                                <Bar options={BarChartOptions} data={BarChartData} />    
                                                </div>
                                                </> : ''
                                        }
                                    </Grid>
                                    {ChartState === 0 || ChartState === 2 ? 
                                    <Grid style={{padding: '2px 5px'}}>
                                        <div className='chart-title'>
                                            <h3>Utilisation</h3>
                                            <ButtonIcon className="icon-btn" startIcon={<CropFree />} onClick={() => { setfullChartState(1); handleOpen();}}></ButtonIcon>
                                        </div>
                                        <div className='pie-chart-section'>
                                            <Pie data={pieChartData} options={pieChartoptions} ref={(reference: any) => {pieChart = reference}} redraw={true}/>
                                        </div>
                                        <div className='pie-info'>
                                            <span className='prev-circle'>Isolated</span>
                                            <span className='prev-circle cl1'>Ignition Off</span>
                                            <span className='prev-circle cl2'>Ignition On</span>
                                            <span className='prev-circle cl3'>Engine Running</span>
                                            <span className='prev-circle cl4'>Paving</span>
                                            <span className='prev-circle cl5'>Offline</span>
                                        </div>
                                    </Grid> : '' }
                                </GridList>
                            </Grid>
                        </GridList>
                    </Grid>
                </GridList>
            </Grid>
            <Grid className="commentGrid">
                {comments.map((row, index) => {
                    return <div className="comment-section" key={index}>
                        <span className="profile"><img src={row.image} /></span>
                        <div className="comment-info">
                            <b>{row.user} commented on <a href="#">{row.topic}</a></b>
                            <span>{row.message}</span>
                        </div>
                        <span className="time">{calDiff(row.date)}</span>
                    </div>;
                })}                  
            </Grid>
        </GridList>
        <Modal open={ChartModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <MolalBox>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h3>{fullChartState === 0 ? 'Fuel Costs' : 'Utilisation'}</h3>
                        <ButtonIcon startIcon={<Close />} onClick={handleClose}></ButtonIcon>
                    </div>
                    {fullChartState === 0 ? 
                        <div className='bar-chart'>
                            <Bar options={BarChartOptions} data={BarChartData} />
                        </div> :
                        <div>
                            <div className='pie-chart-section'>
                                <Pie data={pieChartData} options={pieChartoptions} ref={(reference: any) => {pieChart = reference}} redraw={true}/>
                            </div>
                            <div className='pie-info'>
                                <span className='prev-circle'>Isolated</span>
                                <span className='prev-circle cl1'>Ignition Off</span>
                                <span className='prev-circle cl2'>Ignition On</span>
                                <span className='prev-circle cl3'>Engine Running</span>
                                <span className='prev-circle cl4'>Paving</span>
                                <span className='prev-circle cl5'>Offline</span>
                            </div>
                        </div>
                    }
                </div>
            </MolalBox>
        </Modal>
        <Modal open={ChartRangeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <MolalBox className='chart-range-filter'>
                <h3 style={{borderBottom: '1px solid #ccc', margin: 0, padding: 10}}>Chart Range</h3>
                <div className='filter-button'>
                    <FilterButton onClick={() => {fetchChartFilter('month'); handleChartRangeClose();}}>month</FilterButton>
                    <FilterButton onClick={() => {fetchChartFilter('week'); handleChartRangeClose();}}>week</FilterButton>
                    <FilterButton onClick={() => {fetchChartFilter('day'); handleChartRangeClose();}}>day</FilterButton>
                    <FilterButton onClick={() => {fetchChartFilter('hour'); handleChartRangeClose();}}>hour</FilterButton>
                    <FilterButton onClick={() => {fetchChartFilter('default'); handleChartRangeClose();}}>single measurement</FilterButton>
                </div>
                <div className='date-picker-area range-area'>
                    <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="picker">
                                <span>From: </span>
                                <KeyboardDateTimePicker value={startDate} onChange={setstartdate} name="start" onError={console.log} maxDate={moment().subtract(10, 'minutes')}
                                    format="MMM DD, YYYY HH:mm a" InputProps={{ readOnly: true }}/>
                            </div>
                            <div className="picker">
                                <span>To: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <KeyboardDateTimePicker value={endDate} onChange={setenddate} name="end" onError={console.log} maxDate={moment()}
                                    format="MMM DD, YYYY HH:mm a" InputProps={{ readOnly: true }}/>
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div style={{textAlign: 'right', padding: '10px 15px', borderTop: '1px solid #ccc',}}>
                    <RangeCancelButton onClick={handleChartRangeClose}>Cancel</RangeCancelButton>
                    <RangeApplyButton onClick={() => {handleChartRangeClose(); boxChartData(startTime, endTime, 'custom');}}>Apply</RangeApplyButton>
                </div>
            </MolalBox>
        </Modal>
    </Container>
}

function calDiff(date: any) {
    const startTime = moment(date, 'DD-MM-YYYY HH:mm:ss');
    const endTime = moment();
    if(endTime.diff(startTime, "seconds") <= 60) { return endTime.diff(startTime, "seconds")+ " second ago" }
    if(endTime.diff(startTime, "minutes") <= 60) {return endTime.diff(startTime, "minutes") + " minutes ago"}
    if(endTime.diff(startTime, "hours") <= 23) {return endTime.diff(startTime, "hours") + " hours ago"}
    if(endTime.diff(startTime, "days") <= 29) {return endTime.diff(startTime, "days") + " day ago"}
    if(endTime.diff(startTime, "month") <= 12) {return endTime.diff(startTime, "month") + " month ago"}
    if(endTime.diff(startTime, "year")) {return endTime.diff(startTime, "year") + " year ago"}

}

function abbreviate(n: any) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

const MolalBox = styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    width: 400,
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);',
    padding: 15,
    outline: 0
})

const FilterButton = styled(Button)({
    background: "#00999b",
    color: "#ffffff",
    fontSize: "12px",
    padding: '3px 8px',
    margin: '5px 3px',
    textTransform: "capitalize",
    '&:hover' : {
        background: "#00999b !important",
        color: "#ffffff !important"
    },
});
const ActionButton = styled(Button)({
    background: "#00999b",
    color: "#ffffff",
    fontSize: "13px",
    marginRight: '15px',
    textTransform: "capitalize",
    '&:hover' : {
      background: "#00999b !important",
      color: "#ffffff !important"
    },
    '&:disabled' : {
        background: "#00999b61 !important",
        color: "#ffffff !important"
    }
});
const RangeApplyButton = styled(Button)({
    background: "#00999b",
    color: "#ffffff",
    fontSize: "13px",
    width: '60px !important',
    padding: '2px 0 !important',
    textTransform: "capitalize",
    '&:hover' : {
      background: "#00999b !important",
      color: "#ffffff !important"
    }
});
const RangeCancelButton = styled(Button)({
    background: "#292e2e",
    color: "#ffffff",
    fontSize: "13px",
    marginRight: '10px',
    width: '60px !important',
    padding: '2px 0 !important',
    textTransform: "capitalize",
    '&:hover' : {
      background: "#292e2e !important",
      color: "#ffffff !important"
    }
});
const ButtonIcon = styled(Button)({
    '&:hover' : {
        background: "transparent !important",
      }
});
const selectStyle = {
    'border': '1px solid rgba(0, 0, 0, 0.42)',
    'paddingLeft': '10px',
    'borderRadius': '3px',
    'width': '100%',
    'fontSize': '12px'
};
const CardCus = styled(Card)({
    border: '1px solid #d9d8d9',
    borderRadius: '0',
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    width: 'calc(100% - 10px)'
})
const PollOutlinedCus = styled(PollOutlined)({
    fontSize: '1rem',
    cursor: 'pointer'
})
const VisibilityCus = styled(Visibility)({
    fontSize: '13px',
    marginBottom: '-3px'
});
const cardHeaderStyle = {
    'background': '#f3f2f3',
    'display': 'flex',
    //'fontWeight': 'bold',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'padding': '5px',
    'fontSize': '16px',
    'fontWeight': 'bold',
    'borderBottom': '1px solid #d9d8d9'
} as React.CSSProperties
const cardContentStyle = {
    'padding': '5px 20px',
    'height': '100%',
    'position': 'relative' as 'relative'
}
const statecardContentStyle = {
    'padding': '10px',
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
}
const cardBottomStyle = {
    'background': '#f3f2f3',
    'display': 'flex',
    'justifyContent': 'space-around',
    'alignItems': 'center',
    'padding': '5px',
    'fontSize': '11px',
    'borderTop': '1px solid #d9d8d9',
    'width': '100%',
}
const  customDivHead = {
    'background': '#f3f2f3',
    'borderBottom': '1px solid #d9d8d9',
    'fontSize': '11px',
    'padding': '5px 10px',
    'marginBottom': '10px'
}
const  customDivBottom = {
    'position': 'absolute' as 'absolute',
    'background': '#f3f2f3',
    'borderTop': '1px solid #d9d8d9',
    'fontSize': '12px',
    'padding': '5px 10px',
    'width': '100%',
    'bottom': '-5px'
}
const divOddStyle = {
    'display': 'flex',
    'background': '#f3f2f3',
    'padding': '3px 7px',
    'fontSize': '14px',
    'alignItems': 'center',
    'margin': '5px 0'
}
const divEvenStyle = {
    'display': 'flex',
    'padding': '3px 7px',
    'fontSize': '14px',
    'alignItems': 'center',
    'margin': '5px 0'
}
export default DeviceInfoPage