import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import Radio from '@material-ui/core/Radio'
import CheckIcon from '@material-ui/icons/Check'

export interface CrewColorPaletteColor {
  hex: string,
  desc: string,
}
const DefaultColorOptions: CrewColorPaletteColor[] = [
  {
    hex: '#FF0000',
    desc:	'Red',
  },
  {
    hex: '#800000',
    desc:	'Maroon',
  },
  {
    hex: '#FF00FF',
    desc:	'Fuchsia',
  },
  {
    hex: '#800080',
    desc:	'Purple',
  },
  {
    hex: '#7B55C4',
    desc:	'Violet',
  },
  {
    hex: '#000080',
    desc:	'Navy',
  },
  {
    hex: '#87CEEB',
    desc:	'Sky',
  },
  {
    hex: '#00B9F7',
    desc:	'Toucoise',
  },
  {
    hex: '#00FFFF',
    desc:	'Aqua',
  },
  {
    hex: '#008080',
    desc:	'Teal',
  },
  {
    hex: '#00A86B',
    desc:	'Jade',
  },
  {
    hex: '#008000',
    desc:	'Green',
  },
  {
    hex: '#00FF00',
    desc:	'Lime',
  },
  {
    hex: '#FFFF00',
    desc:	'Yellow',
  },
  {
    hex: '#F9A602',
    desc:	'Gold',
  },
  {
    hex: '#CC7722',
    desc:	'Ochre',
  },
  {
    hex: '#FC6600',
    desc:	'Orange',
  },
  {
    hex: '#C0C0C0',
    desc:	'Silver',
  },
  {
    hex: '#808080',
    desc:	'Grey',
  },
  {
    hex: '#000000',
    desc:	'Black',
  },
]

interface CrewColorPaletteProps {
  selectedHex?: string,
  onChange(colorHex: CrewColorPaletteColor): void,
  options?: CrewColorPaletteColor[],
}

const CrewColorPalette = ({
  selectedHex,
  onChange,
  options = DefaultColorOptions,
}: CrewColorPaletteProps) => {
  const handleChange = (color: CrewColorPaletteColor) => () => {
    onChange(color)
  }
  return (
    <RadioWrapper>
      { options.map((color: CrewColorPaletteColor) => {
        return (
          <Tooltip placement='right' title={color.desc} key={color.hex}>
            <SRadio
              color='default'
              checked={color.hex === selectedHex}
              onChange={handleChange(color)}
              value={color.hex}
              data-testid='color-hex-radio'
              name={'color-hex-radio'}
              icon={<RadioIcon hex={color.hex}/>}
              checkedIcon={
                <SCheckIcon hex={color.hex} data-testid='color-checked-icon' />
              }
            />
          </Tooltip>
        )
      })}
    </RadioWrapper>
  )
}

const RadioWrapper = styled.div`
  max-width: 260px;
`
const SRadio = styled(Radio)`
  margin: 2px;
  padding: 0;
` as typeof Radio

const RadioIcon = styled.div<{ hex: string }>`
  width: 48px;
  height: 48px;
  background: ${props => props.hex};
`
const SCheckIcon = styled(CheckIcon)<{ hex: string }>`
  font-size: 30px;
  width: 48px;
  height: 48px;
  background: ${props => props.hex};
  color: #fff;
`

export default CrewColorPalette