import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'

interface Options {
  formType: PackFormType | any,
  formId: number | string,
  serialId: number,
  fileName: string,
}

export const apiLargeFileFormFileS3 = ({
  formType,
  formId,
  serialId,
  fileName
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/documents/document/${fileName}`,
    params: {
      // reqRole, // no role!
      reqSid: serialId,
      reqDev: 'web',
      largeS3:formId,
      largeFolder:formType

    },
  }
  return ax(options)
}