import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { useCrewCalender } from "../StoreCrewCalender";

const WeekPicker = ({ ...rest }) => {
  const { monthText } = useCrewCalender();
  return (
    <Wrapper {...rest}>
      <MonthText>{monthText}</MonthText>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const MonthText = styled(Typography)`
  color: #009688;
  font-size: 24px;
` as typeof Typography;

export default WeekPicker;
