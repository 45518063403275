import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import qs from 'qs'
import Dialog from '@material-ui/core/Dialog'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useApproval } from '../../useApproval'
import { useAppStore } from '@src/AppStore'
import TabsWrapper from '@src/modules/SiteResoureRequirements/comps/TabsWrapper'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import { useLocation } from 'react-router'
import { useTabs, getTabsByType } from '@src/components/Tabs/useTabs'
import FormPAETable from '@src/components/Form/PAETable'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import Modal from '@src/components/FormModal/Modal'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import useModal from '@src/components/FormModal/useModal'
import Button from '@material-ui/core/Button'
import FormModalRadioGroup from '@src/components/FormModal/RadioGroup'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import FormModalTextField from '@src/components/FormModal/Textfield'
import CircularProgress from '@material-ui/core/CircularProgress'
import { apiATPSubmitPut } from '@src/apis/atp-submit-put'
import SectionAsphaltItemFields, { SectionAsphaltItemRowDisplay } from './parts/SectionAsphaltItemsFields'
import SectionSprayItemFields, { SectionSprayItemRowDisplay } from './parts/SectionSprayItemsFields'
import { doubleUpNormal } from '@src/modules/DRS/utils/doubleUp'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { Checkbox, FormControl, FormControlLabel, Grid, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core'
import FormModalTextareaCustom from '@src/components/FormModal/TextareaBig'
import pdfIcon from '@src/assets/pdficon.svg'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiATPSectionPDF } from '@src/apis/atp-section-pdf'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatEmailToFirstLastName } from '@src/types/format/emailToFirstLastName'
import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import CompDeleteButton from '@src/components/Buttons/CompDeleteButton'
import ATPDocumentDialog from './comps/ATPDocumentDialog'
import ATPTabs from './parts/Tabs'
import { apiGetFilesByATPId } from '@src/apis/files/get-files-by-atp-id'

import { apiUploadFileATP } from '@src/apis/files/upload-file-atp'

import { apiRemoveFileATP } from '@src/apis/files/remove-file-atp'
import { BASE_API_URL } from '@src/constants'
import { apiLargeFileFormFileS3 } from '@src/apis/files/get-large-file-from-s3'
import { CheckboxGroup } from '@src/modules/DRS/legacy/Create/comps/f-EditableCheckboxGroup'
import { PackFormType } from '@src/types/PackFormType'
import { apiPresignedToken } from '@src/apis/files/presign-file-token'
import { apiUploadFileS3 } from '@src/apis/files/upload-file-s3'
import { apiUploadPreloadData } from '@src/apis/files/upload-preload-data'
import Axios from 'axios'
import { apiATPSectionPDFProcess } from '@src/apis/atp-section-pdf-process'
interface Props {
  activeIndex: number,
  primaryMapIndex?: number,
  shiftId?: number,
  commentsHeaders: [],
  allCommentsHeaders: [],
  drsProjectType: any,
  atpAspetcts: []
}
interface SectionAsphaltItemRow extends SectionAsphaltItemRowDisplay {
  _key_: string,
  _type_: any,
  _no_?: number,
  _editable_: boolean,
}
interface SectionSprayItemRow extends SectionSprayItemRowDisplay {
  _key_: string,
  _type_: any,
  _no_?: number,
  _editable_: boolean,
}
function getFormatedAspectsForDropDown(aspectsData: any) {
  const aspectDropdown: any = [];
  for (var i = 0; i < aspectsData.length; i++) {
    if (aspectDropdown.indexOf(aspectsData[i].templateID.toString()) == -1)
      aspectDropdown.push({ id: aspectsData[i].templateID.toString() });
  }
  /// console.log(aspectDropdown);
  return aspectDropdown;
}

const CompATPSection = (props: Props) => {
  const {
    activeIndex,
    primaryMapIndex,
    drsProjectType,
    shiftId,
    allCommentsHeaders,
    commentsHeaders,
    atpAspetcts
  } = props

  const [tabIndex, tabs, handleTabChange, setTabs] = useTabs(getTabsByType(drsProjectType))
  const [vmp, loading, fetch, fetchATP, sections] = useApproval()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const [formType, setFormType] = useState<any>('');
  const [approvalCheck, setApprovalCheck] = useState<any>('Yes');
  const [approvalVerifyCheck, setApprovalVerifyCheck] = useState<any>('Yes');
  const [sectionType, setSectionTypeData] = useState<any>(sections[activeIndex - 1].type);
  const [asphProcess, setAsphProcess] = useState<any>({ id: '1', description: 'Process 1' });
  const { serialId, username, defaultRoleId,userEmail } = useAppStore();
  const [section, setSection] = useState<any>(sections[activeIndex - 1].section);
  const [contractNo, setContractNo] = useState<any>(sections[activeIndex - 1].contractNo);
  const [holdPoint, setHoldPoint] = useState<any>(sections[activeIndex - 1].holdPointNumber);
  const [customerRes, setCustomerRes] = useState<any>(sections[activeIndex - 1].customerContact);
  const [comment, setComment] = useState<any>(sections[activeIndex - 1].comments);
  const [isATPDocumentDialogOpen, setATPDocumentDialogOpen] = useState<boolean>(false)
  const [statusType, setStatusType] = useState<any>('Pending');
  const [isAsphUpdate, setIsAsphUpdate] = useState<boolean>(false);
  const [activeAsph, setActiveAsph] = useState<number>(-1)
  const [tabIndexMain, setTabIndexMain] = useState<number>(0)
  const [activeSpray, setActiveSpray] = useState<number>(-1)
  const [ATPDocumentEditMode, SetATPDocumentEditMode] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<any>(false)
  const [aspectSurfaces, setAspectSurfaces] = useState<(any)[]>(getFormatedAspectsForDropDown(sections[activeIndex - 1].aspect))
  const [selectedMainComment, setSelectedMainComment] = useState<any>('')
  const { alert, alertDone, alertError } = useSnackbar()
  const [isLoading, setIsLoading] = useState<any>(false)
  const [currentSelectedFileName, setCurrentSelectedFileName] = useState<any>([])
  const [allPdfs, setAllPdfs] = useState<any>([])
  const [currentAttachment, setCurrentAttachment] = useState<any>()
  const [allFilePdf, setAllFilePdf] = useState<any>()
  const SURFACE_TYPE_OPTIONS = [];
  const SURFACE_TYPE_OPTIONS2 = [];
  const surfaceCount = atpAspetcts.length / 2;
  const { hash } = useLocation()
  const { confirm } = useConfirm()
  atpAspetcts.forEach((item: any, index) => {
    if (index > surfaceCount) {
      SURFACE_TYPE_OPTIONS2.push({ value: item.id.toString(), label: item.aspectTemplateText });
    } else {
      SURFACE_TYPE_OPTIONS.push({ value: item.id.toString(), label: item.aspectTemplateText });
    }

  });
  useEffect(() => {
    if (hash) {
      refreshPDF(sections[activeIndex - 1].id)



    }
    // setTabIndex(possiblePartValues.indexOf(part))
  }, [hash])
  // [{"value":"Calibration Records - Equipment","label":"Calibration Records - Equipment"},{"value":"Calibration Records - Production Plant","label":"Calibration Records - Production Plant"},{"value":"Change of Plant / Equipment","label":"Change of Plant / Equipment"},{"value":"Change of Method","label":"Change of Method"},{"value":"Conformance details of Underlying Work","label":"Conformance details of Underlying Work"},{"value":"Existing Surface Inspection","label":"Existing Surface Inspection"},{"value":"Submission - Lot Conformance","label":"Submission - Lot Conformance"},{"value":"Submission - Conformance Report","label":"Submission - Conformance Report"}]

  //[{"value":"Submission - Construction Plan","label":"Submission - Construction Plan"},{"value":"Submission HSEQ Management Plans","label":"Submission HSEQ Management Plans"},{"value":"Submission - Traffic Management Plans","label":"Submission - Traffic Management Plans"},{"value":"Plant and Equipment Details","label":"Plant and Equipment Details"},{"value":"Preparation of Existing Surface","label":"Preparation of Existing Surface"},{"value":"Testing Facilities","label":"Testing Facilities"},{"value":"Work Method / Layout Sequence","label":"Work Method / Layout Sequence"}]
  if (sections === null) {
    return null
  } else {

  }


  const onSave = async (form: any) => {

  }

  const Fields: FormTableField<any>[] = [
    {
      key: 'section',
      label: 'Section/Chainage',
      type: FormTableFieldType.Text,
    },
    {
      key: 'contractNo',
      label: 'Contract Number',
      type: FormTableFieldType.Text,
    },
    {
      key: 'holdPointNumber',
      label: 'ATP Number',
      type: FormTableFieldType.Text,
    },

    {
      key: 'type',
      label: 'Type',
      type: FormTableFieldType.Text,
    },
    {
      key: 'customerContact',
      label: 'Customer Representative',
      type: FormTableFieldType.Text,
    },


  ];





  const FieldsAsphalt: FormTableField<any>[] = [
    {
      key: 'layer',
      label: 'Layer',
      type: FormTableFieldType.Text,
    },
    {
      key: 'plantId',
      label: 'Plant',
      type: FormTableFieldType.Text,
    },
    {
      key: 'productId',
      label: 'Product',
      type: FormTableFieldType.Text,
    },
    {
      key: 'asphaltProcess',
      label: 'Process',
      type: FormTableFieldType.Text,
    },

    {
      key: 'layerThickness',
      label: 'Layer Thickness',
      type: FormTableFieldType.Number,
    },
    {
      key: 'asphaltLotNoClauseNo',
      label: 'Lot Cause No',
      type: FormTableFieldType.Text,
    },



  ]
  const FieldsSpray: FormTableField<any>[] = [
    {
      key: 'sprayProcess',
      label: 'Process',
      type: FormTableFieldType.Text,
    },
    {
      key: 'existingSurface',
      label: 'Surface',
      type: FormTableFieldType.Text,
    },

    {
      key: 'lotNoClauseNo',
      label: 'Lot Cause No',
      type: FormTableFieldType.Text,
    },



  ]


  const formatAspects = (aspectData: any) => {
    if (aspectData.length > 0) {
      const aspectRequestData: any = [];
      for (var i = 0; i < aspectData.length; i++) {
        if (aspectRequestData.indexOf({ templateID: parseInt(aspectData[i].id) }) == -1)
          aspectRequestData.push({ templateID: parseInt(aspectData[i].id) });

      }
      return aspectRequestData;
    } else {
      return [];
    }
  }
  const handleSaveSection = (type: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (approvalCheck != 'Yes' || approvalVerifyCheck != 'Yes') {
          alert({ type: 'error', message: 'Approval checkbox are required!' })
          return false;
        }

        console.log(aspectSurfaces);
        setIsSaving(true)


        await apiATPSubmitPut({
          shiftId: shiftId,
          contractNo: contractNo,
          section: section,
          type: sectionType,
         // holdPointNumber: holdPoint,
          serialId: serialId,
          comments: comment,
          submittedBy: username,
          id: sections[activeIndex - 1].id,
          idempToken: new Date().getTime().toString(),
          status: type,
          aspect: formatAspects(aspectSurfaces),
          customerContact: customerRes,
          asphalt: sections[activeIndex - 1]['asphalt'],
          spray: sections[activeIndex - 1]['spray'],

        })
        setIsSaving(false)
        closeModal();
        resolve({})
        alertDone();
        await fetchATP(shiftId)
      } catch (e) {
        setIsSaving(false)
        alertError();
      }
    })
  }
  function getTableData() {

    return [sections[activeIndex - 1]];
  }
  function getAsphaltData() {
    //console.log(JSON.stringify(sections));
    // return [];
    const details = sections[activeIndex - 1]['asphalt'];
    if (!details || !details.length) {
      return []
    }
    const doubled = doubleUpNormal(details)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, 0))
    // console.log(`doubledWithActions`);
    // console.log(JSON.stringify(details));
    // console.log(JSON.stringify(0));
    // console.log(JSON.stringify(doubledWithActions));
    // console.log(`doubledWithActions`);
    return doubledWithActions
  }
  function getSprayData() {
    // only one row of data
    //return [];

    // return [];
    const details = sections[activeIndex - 1]['spray'];
    if (!details || !details.length) {
      return []
    }
    const doubled = doubleUpNormal(details)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, 0))
    // console.log(`doubledWithActions`);
    // console.log(JSON.stringify(details));
    // console.log(JSON.stringify(0));
    // console.log(JSON.stringify(doubledWithActions));
    // console.log(`doubledWithActions`);
    return doubledWithActions
  }
  const onDeleteAsphalt = (data: any) => {
    return new Promise(async (resolve, reject) => {
      try {

        const details = sections[activeIndex - 1]['asphalt'];
        //console.log('before-----'+JSON.stringify(details));
        const foundIndex = details.findIndex(item => item.id === data.id)
        details.splice(foundIndex, 1);
        await apiATPSubmitPut({
          asphalt: details,
          spray: sections[activeIndex - 1]['spray'],
          id: sections[activeIndex - 1].id,
          idempToken: new Date().getTime().toString(),
          shiftId: shiftId,
          status: sections[activeIndex - 1].status,
          submittedBy: username,

        })
        closeModal();
        fetchATP(shiftId);
        resolve({})
        // console.log(data);
      } catch (e) {
        closeModal();
        fetchATP(shiftId)
      }
    })
  }
  const onDeleteSpray = (data: any) => {
    return new Promise(async (resolve, reject) => {
      try {

        const details = sections[activeIndex - 1]['spray'];
        // console.log('before-----'+JSON.stringify(details));
        const foundIndex = details.findIndex(item => item.id === data.id)
        details.splice(foundIndex, 1);
        await apiATPSubmitPut({
          spray: details,
          asphalt: sections[activeIndex - 1]['asphalt'],

          id: sections[activeIndex - 1].id,
          idempToken: new Date().getTime().toString(),
          shiftId: shiftId,
          status: sections[activeIndex - 1].status,
          submittedBy: username,

        })
        closeModal();
        fetchATP(shiftId);
        resolve({})
        // console.log(data);
      } catch (e) {
        closeModal();
        fetchATP(shiftId)
      }
    })
  }


  const saveAsphalt = (data: any) => {
    return new Promise(async (resolve, reject) => {
      try {

        let currentAsphalt: any = sections[activeIndex - 1]['asphalt'];
        //console.log(JSON.stringify(data));
        
        if (currentAsphalt.length > 0) {
          if (activeAsph != -1) {
            currentAsphalt[activeAsph] = {
              asphaltProcess: data.asphProcess.id,
              layer: data.asphaltLayer.id,
              layerThickness: parseFloat(data.layerThickness),
              asphaltLotNoClauseNo: data.asphaltLotNoClauseNo,
              productId: data.product.description,
              plantId: data.supplyPlant.name,
              id: data.id

            };
          } else {
            currentAsphalt.push({
              asphaltProcess: data.asphProcess.id,
              layer: data.asphaltLayer.id,
              layerThickness: parseFloat(data.layerThickness),
              asphaltLotNoClauseNo: data.asphaltLotNoClauseNo,
              productId: data.product.description,
              plantId: data.supplyPlant.name

            });
          }

        } else {
          currentAsphalt = [
            {
              asphaltProcess: data.asphProcess.id,
              layer: data.asphaltLayer.id,
              layerThickness: parseFloat(data.layerThickness),
              asphaltLotNoClauseNo: data.asphaltLotNoClauseNo,
              productId: data.product.id,
              plantId: data.supplyPlant.id
            }
          ];
        }
        await apiATPSubmitPut({
          asphalt: currentAsphalt,
          spray: sections[activeIndex - 1]['spray'],
          id: sections[activeIndex - 1].id,
          idempToken: new Date().getTime().toString(),
          shiftId: shiftId,
          status: sections[activeIndex - 1].status,
          submittedBy: username,

        })
        closeModal();
        fetchATP(shiftId);
        resolve({})

      } catch (e) {
        console.log('errorr in block');
        console.log(e);
        console.log('errorr in block');
        closeModal();
        fetchATP(shiftId)
      }
    })
  }


  //sort function for document with date 
  function multiSort(array: any, sortObject: any = {}) {
    const sortKeys = Object.keys(sortObject);

    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }

    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
    }


    const keySort = (a: any, b: any, direction: any) => {
      direction = direction !== null ? direction : 1;

      if (a === b) { // If the values are the same, do not switch positions.
        return 0;
      }
      // console.log(a,b);
      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };

    return array.sort((a: any, b: any) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];

        if (key) {
          const direction = sortObject[key];
          sorted = keySort(a.dateModified, b.dateModified, direction);
          index++;
        }
      }

      return sorted;
    });
  }
  const saveSpray = (data: any) => {

    console.log(`data`);
    console.log(data);
    console.log(activeSpray);
    console.log(`data`);
    return new Promise(async (resolve, reject) => {
      try {
        let currentSpray: any = sections[activeIndex - 1]['spray'];
        if (currentSpray.length > 0) {
          if (activeSpray !== -1) {
            currentSpray[activeSpray] = {
              existingSurface: data.existingSurface.id,
              lotNoClauseNo: data.lotNoClauseNo,
              sprayProcess: data.sprayProcess.id,
              id: data.id
            };
          } else {
            currentSpray.push({
              existingSurface: data.existingSurface.id,
              lotNoClauseNo: data.lotNoClauseNo,
              sprayProcess: data.sprayProcess.id
            });
          }

        } else {
          currentSpray = [{
            existingSurface: data.existingSurface.id,
            lotNoClauseNo: data.lotNoClauseNo,
            sprayProcess: data.sprayProcess.id
          }];
        }
        await apiATPSubmitPut({
          spray: currentSpray,
          id: sections[activeIndex - 1].id,
          asphalt: sections[activeIndex - 1]['asphalt'],
          idempToken: new Date().getTime().toString(),
          shiftId: shiftId,
          status: sections[activeIndex - 1].status,
          submittedBy: username,

        })
        closeModal();
        fetchATP(shiftId);
        resolve({})

      } catch (e) {
        closeModal();
        fetchATP(shiftId)
      }
    })
  }
  const approvalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('hhh')
    if (!event.target.checked) {
      setApprovalCheck('No');
    } else {
      setApprovalCheck('Yes');
    }
  }

  const handleUpdateSection = (type: any) => {

    setComment(comment !== '' ? comment : sections[activeIndex - 1].comments);
    setSection(sections[activeIndex - 1].section);
    setContractNo(sections[activeIndex - 1].contractNo);
    setHoldPoint(sections[activeIndex - 1].holdPointNumber ? sections[activeIndex - 1].holdPointNumber?.length : null);
    handleSaveSection(type);
  }
  const handleCommentMain = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('hhh-->>>>'+event.target.value)
    setSelectedMainComment(event.target.value);
  }
  const handleChangeSelectAspect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue: any = event.target.value;
    let tmpAspects = aspectSurfaces;
    console.log(tmpAspects);
    if (tmpAspects.indexOf(currentValue) == -1) {
      console.log('addd-->>>>' + event.target.value)
      tmpAspects.push(currentValue);
      setAspectSurfaces(tmpAspects);
      return true;
    }
    if (tmpAspects.indexOf(currentValue) != -1) {
      console.log("delete", Math.random());
      tmpAspects.splice(tmpAspects.indexOf(currentValue));
      setAspectSurfaces(tmpAspects);
      return true;
    }


  }
  const handleCommentFinal = (event: React.ChangeEvent<HTMLInputElement>) => {

    const commentFinal: any = comment + '\r\n' + event.target.value;
    setComment(commentFinal);
    setSelectedMainComment('');
    closeModal();
  }

  const handleFileSelectDocument = async () => {
    setATPDocumentDialogOpen(true);
  }
  const handleTabClick = async (label: string, index: number) => {
    // if(possiblePartValues.indexOf(part) != index) {
    setTabIndexMain(index);
    if (index == 1) {
      refreshPDF(sections[activeIndex - 1].id)
    }
    // }
  }
  const refreshPDF = async (atpId: any) => {
    try {
      apiGetFilesByATPId({
        atpId: atpId + '',
        serialId,
      }).then((result) => {
        if (result && result.data.documents) {
          //  console.log(result.data.documents);
          const sortData = { 'dateModified': 'desc' };
          const attachmentData: any = multiSort(result.data.documents, sortData);
          setAllPdfs(attachmentData)
        }
      })

    }
    catch (e) {
      console.log(e);
    }
  }
  
  const downloadPDFS3 = async (pdfName: string) => {
    console.log('heree' + pdfName);
    if (!pdfName) {
      return
    }
    setIsLoading(true);
    try {

      const result = await Axios({
        url: pdfName,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf'
        },
      })



      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank";

      link.setAttribute('download', pdfName)
      document.body.appendChild(link)
      link.click()
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }

  }

  const handleDownloadPDFATP = async () => {

    const currentMapIndex = activeIndex - 1
    const atpID = sections[currentMapIndex].id

    // Open the signed URL in a new tab
    // console.log(allPdfs);
    /*let fileNames: any[] = allPdfs.map((pdf: any) => {
      return pdf.name;
    })*/

    try {
        setIsLoading(true)
        const result = await apiATPSectionPDFProcess({
          id: atpID,
          serialId,
          emailAddress: userEmail //'bernard.garekani@boral.com.au'
        })
        setIsLoading(false)
        if (typeof result != "undefined") {
          if (typeof result.url != "undefined") {
            alert({
              message: 'Email Sent Successfully',
              type: 'success'
            })
            setIsLoading(false)
            //open aws signed pdf link into another Tab
            window.open(result.url, '_blank');
          } else {
            console.log(result)
            setIsLoading(false)
          }
        } else {
          console.log(`PDF download and email sending failed.`);
          setIsLoading(false)
          alert({
            message: 'PDF download and email sending failed.',
            type: 'error'
          })
        }
    } catch (e) {
      setIsLoading(false)
      alert({
        message: 'PDF download and email sending failed.',
        type: 'error'
      })
      console.log(`e`);
      console.log(e);
      console.log(`e`);
    }
  }
  const onEditDocument = async (attachment: any) => {
    setCurrentSelectedFileName([]);

    SetATPDocumentEditMode(true);
    setATPDocumentDialogOpen(true);
    setCurrentAttachment(attachment);


  }

  const onRemoveDocument = async (attachment: any) => {

    try {
      await confirm({
        title: 'Confirm Deletion of Document',
        message: 'This will permanently delete any saved Document information from the system and cannot be undone.',
      })
      console.log(`attachment`);
      console.log(attachment);
      console.log(`attachment`);

      return apiRemoveFileATP({ id: attachment.id, roleId: defaultRoleId, serialId: serialId }).then(() => {
        setIsLoading(false)
        alertDone()
        refreshPDF(sections[activeIndex - 1].id)
      }).catch(() => {
        setIsLoading(false)
        alertError();
        refreshPDF(sections[activeIndex - 1].id)
      })
    } catch (e) {
      setIsLoading(false)
      alertError();
      refreshPDF(sections[activeIndex - 1].id)
    }


  }
  const handlePdfClick = (attachment: any) => {
    // if not an image open in a new browser tab

    // console.log('here');

    //console.log('here');
    const fileUrl: string = `${BASE_API_URL}/v2/documents/document/${attachment.name}?${qs.stringify({
      reqSid: serialId,
    })}`
    apiLargeFileFormFileS3({
      formType: 'atp_pdf_files',
      formId: sections[activeIndex - 1].id,

      serialId,
      fileName: attachment.name

    }).then((response) => {
      let url = '';
      if (response) {
        if (response.data) {
          if (response.data.documents) {
            url = response.data.documents;
          }
        }
      }
      if (url) {
        window.open(response.data.documents, '_blank');
      } else {
        alertError()
      }
    })





  }
  const handlePdfSave = async (data: any) => {
    try {
      setIsLoading(true);
      return Promise.all(
        Array.from(allFilePdf)
          .map((file: any) => {
            return apiPresignedToken({
              formType: PackFormType.ATP_PDF_FILE,
              formId: sections[activeIndex - 1].id,
              roleId: defaultRoleId,
              serialId,

              preupload: 'pdf'

            }).then(async (responsePresign: any) => {

              if (responsePresign.data.documents) {
                const fields = responsePresign.data.documents.fields;
                fields['file'] = file;
                return apiUploadFileS3({ fields, url: responsePresign.data.documents.url }).then((responseS3Upload) => {

                  return apiUploadPreloadData({
                    formType: PackFormType.ATP_PDF_FILE,
                    formId: sections[activeIndex - 1].id,
                    roleId: defaultRoleId,
                    serialId,
                    s3Filename: responsePresign.data.documents.name,
                    s3locationName: responsePresign.data.documents.location,
                    orginalfileName: responsePresign.data.documents.name,
                    s3fileExtension: 'pdf',
                    projectInternalId: null,
                    IsAdministrator: 'Y',
                    IsResourceScheduler: 'Y',
                    IsProjectManager: 'Y',
                    IsSupervisor: 'Y',
                    IsForeman: 'Y',
                    cpDocComment: file.name,


                  });
                })
              }
              console.log(file);
              console.log(responsePresign)
            })
          })
      )

        .then(() => {
          setIsLoading(false)
          alertDone()
          setATPDocumentDialogOpen(false);
          refreshPDF(sections[activeIndex - 1].id)
        }).catch((response: any) => {
          setIsLoading(false);
          alert({
            message: 'File is too big - please select another PDF file - Maximum 8mb.',
            type: 'error'
          })
        })

    } catch (e) {
      alertError()
    }
  }
  const handlePdfSaveOLD = async (data: any) => {
    try {
      setIsLoading(true);
      return Promise.all(
        Array.from(allFilePdf)
          .map((file: any) => {
            const fields = {};
            fields['file'] = file;
            fields['projectInternalId'] = null;
            fields['reqRole'] = defaultRoleId;
            fields['serialId'] = serialId;
            fields['atpId'] = sections[activeIndex - 1].id;
            fields['cpDocComment'] = file.name;
            return apiUploadFileATP({
              fields: fields

            }).then(async (responsePresign: any) => {
              console.log(file);
              console.log(responsePresign)
            })
          })
      )

        .then(() => {
          setIsLoading(false)
          alertDone()
          setATPDocumentDialogOpen(false);
          refreshPDF(sections[activeIndex - 1].id)
        }).catch((response: any) => {
          //  console.log(`response`);
          //  console.log(response);
          //  console.log(`response`);
          setIsLoading(false);
          alert({
            message: 'File is too big - please select another PDF file - Maximum 8mb.',
            type: 'error'
          })
          // alertError();
          //   refresh()
        })


    } catch (e) {
      alertError()
    }

  }
  function checkAspectValue(value: any) {
    return aspectSurfaces.indexOf(value) != -1 ? true : false
  }
  const setFilesPdf = (files: FileList, nonImageFiles: FileList) => {
    // console.log(files);
    // console.log(nonImageFiles);

    setAllFilePdf(nonImageFiles);
    var nonImageFilesArr: any = [];
    if (nonImageFiles.length > 0) {
      for (var j = 0; j < nonImageFiles.length; j++) {
        nonImageFilesArr.push('(' + ((j + 1).toString()) + ')' + ' ' + nonImageFiles[j].name);
      }
      setCurrentSelectedFileName(nonImageFilesArr);
    }
  }
  const approvalVerifyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('approvalVerifyChanged')
    if (!event.target.checked) {
      setApprovalVerifyCheck('No');
    } else {
      setApprovalVerifyCheck('Yes');
    }
  }
  const renderTabsParts = () => {
    return [<WrapperDiv>
      <WrapperUpdateBtnSection>
        <WrapperUpdateBtn>
          {(sections[activeIndex - 1]['status'] != 'Completed') && <CompThemeButton onClick={() => {
            handleUpdateSection('Pending')
          }}>Save</CompThemeButton>}
          {(sections[activeIndex - 1]['status'] == 'Completed') && <CompThemeButton onClick={handleDownloadPDFATP}>{isLoading ? 'Downloading...' : 'Download Pdf'}</CompThemeButton>}
        </WrapperUpdateBtn>
        <WrapperUpdateBtn>
          {(sections[activeIndex - 1]['status'] != 'Completed') &&
            <CompThemeButton onClick={() => {
              handleUpdateSection('Completed')
            }}>Complete</CompThemeButton>
          }
        </WrapperUpdateBtn>
      </WrapperUpdateBtnSection>

      <FormPanel title={``}>

        <FormPAETable<any>
          fields={Fields}
          data={getTableData()}
          showPAEType={false}
          showMoreActions={false}
        />

      </FormPanel>
      {sections[activeIndex - 1].status !== 'Completed' && <SectionDiv>
        <CompThemeButton onClick={() => {
          setComment(comment != '' ? comment : sections[activeIndex - 1].comments);
          setSection(sections[activeIndex - 1].section);
          setContractNo(sections[activeIndex - 1].contractNo);
          setHoldPoint(sections[activeIndex - 1].holdPointNumber);
          openAndSetData(null)
          setFormType('section');

        }}>
          Update Shift Details
        </CompThemeButton>
      </SectionDiv>}
      <div style={{marginTop:'20px'}}></div>
      <TabsWrapper>
        <TabsComp
          tabs={tabs}
          activeIndex={tabIndex}
          onChange={handleTabChange}
        />
      </TabsWrapper>
      {renderAsOrSS()}
      <div style={{ marginTop: '20px' }}></div>
      <FormPanel title={`Customer Representative or Inspector`} >

        <FormControlLabel
          key={''}
          labelPlacement={'start'}
          label={'Approval is requested to proceed with the above process commencing.'}
          control={
            <Checkbox
              disabled={sections[activeIndex - 1].status == 'Completed'}
              checked={approvalCheck == 'Yes' ? true : false}
              onChange={approvalChanged}
              name={'Yes'}
            />
          }
        />
        <FormControlLabel
          key={''}
          disabled={sections[activeIndex - 1].status == 'Completed'}
          labelPlacement={'start'}
          label={'I verify that the following aspects of the process meet the requirements of the contract specification and the quality plan.'}
          control={
            <Checkbox
              checked={approvalVerifyCheck == 'Yes' ? true : false}
              onChange={approvalVerifyChanged}
              name={'Yes'}
            />
          }
        />

      </FormPanel>
      <div style={{ marginTop: '20px' }}></div>

      <FormPanel title={`Aspects of the Process Surface`} >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FieldWrapper >
              <CheckboxGroup
                disabled={sections[activeIndex - 1].status == 'Completed'}
                selectedItems={aspectSurfaces}
                options={SURFACE_TYPE_OPTIONS}
                onChange={(selectedItems: any[]) => setAspectSurfaces(selectedItems)}
              />
            </FieldWrapper>

          </Grid>
          <Grid item xs={12} md={6}>
            <FieldWrapper>

              <CheckboxGroup
                disabled={sections[activeIndex - 1].status == 'Completed'}
                selectedItems={aspectSurfaces}
                options={SURFACE_TYPE_OPTIONS2}
                onChange={(selectedItems: any[]) => setAspectSurfaces(selectedItems)}
              />

              {/* <FormModalCheckboxGroup
              label={''}
              key={'option_right'}
              value={getFormatedAspectsForDropDown(sections[activeIndex - 1].aspect)}
              options={SURFACE_TYPE_OPTIONS2}
              onChange={(item: any) => {
                setAspectSurfaces(item)
              }}

            /> */}
            </FieldWrapper>


          </Grid>
        </Grid>
      </FormPanel>

      <div style={{ marginTop: '20px' }}></div>
      <FormPanel title={`Comments`} >
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FormModalTextareaCustom
              label={'Comments'}
              width={'850px'}
              height={'450px'}
              disabled={sections[activeIndex - 1].status == 'Completed'}
              value={comment}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                setComment(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <LinkItem key={'tmplcmt'}
              onClick={async () => {
                if (sections[activeIndex - 1].status != 'Completed') {
                  openAndSetData(null)
                  setFormType('comment');
                }

              }}
            >Select Template</LinkItem>
          </Grid>
        </Grid>


      </FormPanel>
     




      {open && formType == 'section' &&
        <Dialog
          fullWidth={true}

          open={open}
          onClose={onSave}
        >
          <DialogTitle>
            <ModalTitle>{"Update Shift Details"}</ModalTitle>
          </DialogTitle>
          <DialogContent>
            <SectionWrapper2>
              <FormModalTextField
                label={'Section/Chainage'}
                id={'section'}
                defaultValue={section}


                helperText={"Enter Section/Chainage"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSection(e.target.value)
                }}

              />
              <FormModalTextField
                label={'Contract No'}
                id={'contractno'}
                defaultValue={contractNo}


                helperText={"Contract No"
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setContractNo(e.target.value)
                }}

              />
              <FormModalTextField
                label={'Customer Representative Name'}
                id={'custRName'}
                defaultValue={customerRes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCustomerRes(e.target.value)
                }}

              />
            </SectionWrapper2>
            <SectionWrapper style={{ marginTop: '15px', marginBottom: '15px' }}>
              <FormModalRadioGroup label='Type' value={sectionType} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                //  console.log(e.target.value);
                setSectionTypeData(e.target.value)
              }} options={[{ value: 'Approval to Proceed', label: 'Approval to Proceed' }, { value: 'Hold Point Release', label: 'Hold Point Release' }, { value: 'Witness Point', label: 'Witness Point' }]} key={'sectionTypeOption'} />

            </SectionWrapper>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>
              Cancel
            </Button>

            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                handleSaveSection('Pending')
              }}

            >

              {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
              {!isSaving && 'SAVE '}
            </Button>
          </DialogActions>
        </Dialog>
      }

      {open && formType == 'asphalt' &&
        <Modal<SectionAsphaltItemRow>
          maxWidth={'sm'}
          open={open}
          title='Asphalt'
          data={{
            rowData: modalData,
            fields: SectionAsphaltItemFields,
            columns: [
              {
                title: 'Asphalt Information',
                fieldKeys: [
                  'asphaltLayer',
                  'supplyPlant',
                  'product',
                  'asphProcess', 
				  'layerThickness', 
				  'asphaltLotNoClauseNo'
                ],
              },


            ],

          }}
          onSuccess={saveAsphalt}
          onClose={closeModal}
          onCancel={closeModal}
        />
      }


      {open && formType == 'spray' &&
        <Modal<SectionSprayItemRow>
          maxWidth={'sm'}
          open={open}
          title='Spray Seal'
          data={{
            rowData: modalData,
            fields: SectionSprayItemFields,
            columns: [
              {
                title: 'Spray Seal Information',
                fieldKeys: [
                  'sprayProcess',
                  'existingSurface', 'lotNoClauseNo'
                ],
              },


            ],

          }}
          onSuccess={saveSpray}
          onClose={closeModal}
          onCancel={closeModal}
        />
      }

      {open && formType == 'comment' &&
        <Dialog
          fullWidth={true}
          open={open}
          onClose={onSave}
        >
          <DialogTitle>
            <ModalTitle>{"ATP Header Comments"}</ModalTitle>
          </DialogTitle>
          <DialogContent>
            {commentsHeaders.map((option: any) => {
              return selectedMainComment == '' && <SectionWrapper2>
                <FormControlLabel value={option.id} control={<Radio value={option.id} onChange={handleCommentMain} />} label={option.headerText} />


              </SectionWrapper2>
            })}

            {allCommentsHeaders.map((option: any) => {
              return selectedMainComment == option.id && <SectionWrapper2>
                <FormControlLabel value={option.optionText} control={<Radio value={option.optionText} onChange={handleCommentFinal} />} label={option.optionText} />


              </SectionWrapper2>
            })
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>
              Cancel
            </Button>


          </DialogActions>
        </Dialog>
      }

    </WrapperDiv>, 

      <WrapperDiv>
    <WrapperUpdateBtnSection>
        <WrapperUpdateBtn>
          {(sections[activeIndex - 1]['status'] != 'Completed') && <CompThemeButton onClick={() => {
            handleUpdateSection('Pending')
          }}>Save</CompThemeButton>}
          {(sections[activeIndex - 1]['status'] == 'Completed') && <CompThemeButton onClick={handleDownloadPDFATP}>{isLoading ? 'Downloading...' : 'Download Pdf'}</CompThemeButton>}
        </WrapperUpdateBtn>
        <WrapperUpdateBtn>
          {(sections[activeIndex - 1]['status'] != 'Completed') &&
            <CompThemeButton onClick={() => {
              handleUpdateSection('Completed')
            }}>Complete</CompThemeButton>
          }
        </WrapperUpdateBtn>
      </WrapperUpdateBtnSection>
        <Wrapper>

          <RowWrapper style={{ position: 'relative' }}>




            {!isLoading && sections[activeIndex - 1]['status'] != 'Completed' && <PlusButton onClick={handleFileSelectDocument}><AddOutlinedIcon style={{ color: '#009688', fontSize: '36px', fontWeight: 'bold' }} /></PlusButton>}
          </RowWrapper>

          <TableContainer style={{ marginBottom: '15px', position: 'relative' }}>

            <Table aria-labelledby="commentlist" style={{ border: '2px solid' }}>
              <TableHeaderCell>
                <TableRow>
                  <TableCellCusHead align="center">Document</TableCellCusHead>
                  {/* <TableCellCusHead align="center">Comment</TableCellCusHead> */}
                  <TableCellCusHead align="center">Creator Name</TableCellCusHead>
                  <TableCellCusHead align="center">Date</TableCellCusHead>
                  {sections[activeIndex - 1]['status'] != 'Completed' && <TableCellCusHead align="center">Action</TableCellCusHead>}
                  {/* <TableCellCusHead align="center"></TableCellCusHead> */}
                </TableRow>
              </TableHeaderCell>
              <TableBody>
                {allPdfs.map((attachment: any) => {
                  return <TableRow>
                    <TableCellCus align="center" style={{ position: 'relative' }}>

                      <img style={{ display: 'inline-block', cursor: 'pointer' }} src={pdfIcon} height={50} onClick={() => {
                        handlePdfClick(attachment);
                      }} /><br />
                      {attachment.cpDocComment ? attachment.cpDocComment : attachment.originalName}

                    </TableCellCus>
                    {/* <TableCellCus align="center">{attachment.cpDocComment}</TableCellCus> */}
                    <TableCellCus align="center">{formatEmailToFirstLastName(attachment.email)}</TableCellCus>
                    <TableCellCus align="center">{formatDateStringDDMMYYYY(attachment.dateCreated)}</TableCellCus>
                    {sections[activeIndex - 1]['status'] != 'Completed' && <TableCellCus align="center">

                      <CompDeleteButton onClick={() => {
                        onRemoveDocument(attachment);
                      }}>
                        DELETE
                      </CompDeleteButton>
                    </TableCellCus>}
                  </TableRow>

                })}
              </TableBody>
            </Table>
          </TableContainer>


        </Wrapper>
        {isATPDocumentDialogOpen &&
          <ATPDocumentDialog
            open={isATPDocumentDialogOpen}
            editMode={ATPDocumentEditMode}
            onCancel={() => {
              setATPDocumentDialogOpen(false)
              SetATPDocumentEditMode(false);

              setIsLoading(false);

            }}
            onClose={() => {
              setATPDocumentDialogOpen(false)
              SetATPDocumentEditMode(false);

              setIsLoading(false);
            }}
            onSuccess={() => {
              setATPDocumentDialogOpen(false)
              SetATPDocumentEditMode(false);

              setIsLoading(false);
            }}
            onFileSlect={(files: FileList, imageFiles: FileList, nonImageFiles: FileList) => {
              setFilesPdf(files, nonImageFiles);
              // handleFileSelect(files,imageFiles,nonImageFiles);
            }}
            currentSelectedFileName={currentSelectedFileName}
            normalAttachments={allPdfs}
            attachment={currentAttachment}
            onSave={(obj: any) => {
              handlePdfSave(obj);
              currentSelectedFileName.length = 0
              // setATPDocumentDialogOpen(false)
              // SetATPDocumentEditMode(false);

              // setIsLoading(false);
            }}
            isLoader={isLoading}
          />
        }
      </WrapperDiv>

   ][tabIndexMain]
  }
  const renderAsOrSS = () => {
    const currentTab: Tab = tabs[tabIndex]
    if (currentTab.key === 'ASPHALT') {
      return <React.Fragment>
        <FormPanel title={``}>

          <FormPAETable<any>
            fields={FieldsAsphalt}
            data={getAsphaltData()}
            showPAEType={false}
            editable={sections[activeIndex - 1]['status'] != 'Completed'}
            onDelete={onDeleteAsphalt}
            onEditRow={(row: any) => {
              let rowData = row;
              rowData['asphProcess'] = { id: row.asphaltProcess, description: row.asphaltProcess };
              rowData['asphaltLayer'] = { id: row.layer, description: row.layer };
              delete rowData['layer'];
              rowData['product'] = { id: row.productId, description: row.productId };
              rowData['supplyPlant'] = { id: row.plantId, name: row.plantId };
              const index = sections[activeIndex - 1]['asphalt'].findIndex(item => item.id === row.id)
              setActiveAsph(index);
              setIsAsphUpdate(true);
              setFormType('asphalt');
              openAndSetData(rowData);

            }}
            showMoreActions={true}
          />

        </FormPanel>
        <SectionDiv>
          {sections[activeIndex - 1]['status'] != 'Completed' && <CompCreateButton onClick={() => {
            openAndSetData(null)
            setIsAsphUpdate(false);
            setActiveAsph(-1);
            setFormType('asphalt');
          }}>
            Add Asphalt
          </CompCreateButton>}
        </SectionDiv>

      </React.Fragment>
    } else {
      return <React.Fragment>
        <FormPanel title={``}>
          <FormPAETable<any>
            fields={FieldsSpray}
            data={getSprayData()}
            showPAEType={false}
            showMoreActions={true}
            onDelete={onDeleteSpray}
            editable={sections[activeIndex - 1]['status'] != 'Completed'}
            onEditRow={(row: any) => {
              let rowData = row;
              console.log(`rowData`);
              console.log(rowData);
              console.log(`rowData`);
              rowData['sprayProcess'] = { id: row.sprayProcess, description: row.sprayProcess };
              rowData['existingSurface'] = { id: row.existingSurface, description: row.existingSurface };
            //  rowData['existing'] = { id: row.sprayProcess, description: row.sprayProcess };
              const index = sections[activeIndex - 1]['spray'].findIndex(item => item.id === row.id)
              setActiveSpray(index);
              setFormType('spray');
              openAndSetData(rowData);
            }}
          />

        </FormPanel>
        <SectionDiv>
          {sections[activeIndex - 1]['status'] != 'Completed' &&
            <CompCreateButton onClick={() => {
              openAndSetData(null)
              setFormType('spray');
              setActiveSpray(-1);

            }}>
              Add Spray Seal
            </CompCreateButton>
          }
        </SectionDiv>
      </React.Fragment>
    }
  }
  return <Wrapper>

    <FormSectionTitle>{`ATP - ${sections[activeIndex - 1].id}`}</FormSectionTitle>

    <TabsWrapper>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <ATPTabs
            labels={['ATP Form', 'PDFs']}
            value={tabIndexMain}
            onClick={handleTabClick}
          />
        </Grid>
        <Grid item xs={7}>

        </Grid>
      </Grid>

      <PartsWrapper>
        {renderTabsParts()}
      </PartsWrapper>
    </TabsWrapper>



  </Wrapper>

}

const Wrapper = styled.div`
  position: relative;
`
const WrapperUpdateBtnSection = styled.div`
display: inline-block;
position: relative;
width: auto;

float: right;
margin-top: -67px;
right: -22px;
`
const WrapperUpdateBtnSectionPDF = styled.div`
display: inline-block;
position: relative;
width: auto;

float: right;
margin-top: -67px;
right: 0px;
`
const WrapperUpdateBtn = styled.div`
display: inline-block;
margin-left:10px;
margin-right: 10px;

`
const ColumnFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-right: 10px;
`
const SectionWrapper2 = styled.div`
  display: column;
  width:400px;
`
const PrimarySetter = styled.div`
  position: absolute;
  left: 232px;
  top: 3px;
  background: #F2F8F7;
  border-radius: 8px;
  padding-left: 14px;
`

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  display: flex;
`
const Section = styled.div`
  padding: 0 30px;
  width: 360px;
`
const SectionDiv = styled.div`
  margin-top: 10px;
  width: 360px;
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin-bottom: 24px;
  min-height: 28px;
`
const FieldWrapper = styled.div`
  margin-bottom: 40px;
`
const SCell = styled(TableCell)`

`
const SubHeader = styled.div`
font-size: 16px;
color: #009688;
margin-bottom: 24px;
min-height: 28px;
`
const LinkItem = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #189F93;
  cursor: pointer;
`
const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const WrapperDiv = styled.div`
`
const RowWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0A9688;
`
const PlusButton = styled.div`
position: absolute;
    right: 0;
    top: 0px;

    cursor:pointer
`
const TableHeaderCell = styled(TableHead)`
        background-color: #F5F5F5;`
const TableCellCusHead = styled(TableCell)({
  border: '2px solid #f7f8f9',
  color: '#707070',
  fontWeight: 'bold',
  fontSize: '16px'
})
const TableCellCus = styled(TableCell)({
  border: '2px solid #f7f8f9',
  wordBreak: 'break-all',
})
const PartsWrapper = styled.div`
  /* margin-top: 40px; */
`
export default CompATPSection