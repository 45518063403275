import React, { useState, useContext, useEffect, useRef, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import BoralSwitch from '../../../components/BoralSwitch'
import { styledWithOut } from '../../../utils/styledWithout'
import { ShouldDisableStatusContext } from './ProjectsGroupByDepot'
import { apiProjectToggleActive } from '../../../apis/project-toggle-active'
import { useHover } from '@src/utils/hooks'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import usePermission from '@src/utils/hooks/usePermission'
import { ProjectStatus, formatProjectStatus } from '@src/types/project/ProjectStatus' 
import { Project } from '@src/types/project/Project'


const ProjectCard = ({
  project,
  hideStatusSwitch = false,
  afterStatusChanged = () => {},
}: {
  project: Project,
  hideStatusSwitch?: boolean,
  afterStatusChanged?(project: Project): void,
}) => {
  const shouldDisableStatus = useContext(ShouldDisableStatusContext)
  const history = useHistory()

  const handleNavigateToDetail = () => {
    history.push({
      pathname: `/project/${project.projectInternalId}`,
    })
  }
  const { status, shiftCompleted, shiftTotal } = project
  
  return <ProjectWrapperOutmost key={project.id}>
    <ProjectWrapper>
      <TextWrapper>
        <TextName variant='subtitle1' onClick={e => handleNavigateToDetail()}>
          {`${project.id || project.projectInternalId} | ${project.name}`}</TextName>
        <TextSub>{`Customer: ${project.customer ? project.customer.name : ''}`}</TextSub>
        <TextSub>{`Location: ${project.location ? project.location : ''}`}</TextSub>
        {/*  hide this for later <TextSub>{`Project Manager: Jack Smith`}</TextSub> */}
      </TextWrapper>
      <RightWrapper>
        <ProjectStatusComp status={`${status!.id}`}>
          { formatProjectStatus(status!.id) }
        </ProjectStatusComp>
        <ShiftCounts>
          { `Completed Shifts: ${shiftCompleted}/${shiftTotal}`}
        </ShiftCounts>
        <CollaboratorChips items={project.projectTeam || []}/>
      </RightWrapper>
    </ProjectWrapper>
  </ProjectWrapperOutmost>
}

export const CollaboratorChips = ({
  items = [],
}: {
  items: any[],
}) => {
  const { canAddCollaborator, canRemoveCollaborator } = usePermission()
  return <CollaboratorChipsWrapper>
    { items.map((item: any, index: number) => {
      return <CollaboratorChip key={index} item={item} removable={canRemoveCollaborator}/>
    })}
    { canAddCollaborator &&
      <AddPersonWrapper>
        <AddPerson>
          <PersonAddIcon />
        </AddPerson>
      </AddPersonWrapper>
    }
  </CollaboratorChipsWrapper>
}
const AddPersonWrapper = styled.div`
  display: none;
  width: 28px;
  height: 28px;
  margin-left: 16px;
`
const AddPerson = styled(IconButton)`
  svg {
    font-size: 16px;
  }
  color: #fff;
  padding: 6px; /* 16 + 6 * 2 === 28 */
  background-color: #0A9688;
`
const CollaboratorChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
`
const CollaboratorChip = ({
  item,
  onClick,
  removable = false,
}: {
  item: any,
  onClick?(): Function,
  removable?: boolean,
}) => {
  const [ hoverRef, isHovered ] = useHover()
  return <CollaboratorChipWrapper ref={hoverRef as any}>
    { `${item.firstName.substring(0, 1).toUpperCase()}${item.lastName.substring(0, 1).toUpperCase()}`}
    { <CollaboratorChipIconButton onClick={onClick} visible={removable && isHovered}>
          <CancelIcon />
        </CollaboratorChipIconButton> }
  </CollaboratorChipWrapper>
}
const CollaboratorChipWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid #0A9688;
  position: relative;
  font-size: 12px;
  text-align: center;
  line-height: 28px;
  margin-left: 16px;
  &:first-child {
    margin-left: 0;
  }
`
const CollaboratorChipIconButton = styled(styledWithOut('visible')(IconButton))`
  visibility: ${props => props.visible ? 'visible' : 'hidden' };  
  display: none; 
  padding: 0;
  position: absolute;
  top: -6px;
  right: -2px;
  color: #000;
  svg {
    width: 16px;
    height: 16px;
  }
`
const ShiftCounts = styled.div`
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  margin-top: 32px;
  margin-bottom: 27px;
`
export const ProjectStatusComp = styled.div<{ status: string }>`
  border-radius: 8px;
  color: #fff;
  height: 24px;
  width: 130px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  ${props => {
    switch (props.status) {
      // case 'active': {
      //   return css`
      //     background-color: #0A9688;
      //   `
      // }
      case `${ProjectStatus.Draft}`: {
        return css`
          background-color: #D32929;
        `
      }
      default: {
        return css`
          background-color: #0A9688;
        `
      }
    }
  }}
`
const ProjectWrapperOutmost = styled.div`
  /* height: 160px; */
  margin: 20px 0;
`

const ProjectWrapper = styled.div`
  display: flex;
  padding: 20px 24px 16px 22px;
  height: 100%;
  background-color: #F5F5F5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`

const Circle = styled.div`
  margin-top: 27px;
  text-align: center;
  line-height: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #fff;
  color: inherit;
  font-size: 12px;
  font-weight: 700;
`
const ProjectType = ({ 
  projectType, 
  ...props 
}: {
  projectType: string,
}) => {
  let text = ''
  if (projectType === 'ASPHALT') {
    text = 'As'
  } else if (projectType === 'SPRAY-SEAL') {
    text = 'SS'
  } else if (projectType === 'ASPHALT-SPRAY-SEAL') {
    text = `As +
SS`
  }
  return <div {...props}>
    <Circle>{text}</Circle>
  </div>
}
const getTypeColor = (projectType: string) => {
  if (projectType === 'ASPHALT') {
    return '#6A5681'
  } else if (projectType === 'SPRAY-SEAL') {
    return '#366B98'
  } else if (projectType === 'ASPHALT-SPRAY-SEAL') {
    return '#983636'
  } 
}

export { getTypeColor }

const StyledProjectType = styled(ProjectType)`
  width: 48px;
  height: 100%;
  background-color: ${props => getTypeColor(props.projectType)};
  color: ${props => getTypeColor(props.projectType)};
  display: flex;
  justify-content: center;
`
const TextWrapper = styled.div`
  flex-grow: 1;
`
const TextName = styled(Typography)`
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: #009688;
  }
  margin-bottom: 19px;
`
const TextSub = styled(Typography)`
  font-size: 14px;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }
`
const RightWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 135px;
  ${props => props.disabled && css`
    opacity: .5;
  `}
`

const StyledLabel = styled(styledWithOut('active')(Typography))`
  color: ${props => props.active ? '#72AD5C' : '#B1A9A9'};
  font-size: 12px;
  // margin-top: 4px;
`

export default ProjectCard