import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import FormControl from '@material-ui/core/FormControl'
import BoralTextField from '../../../../../components/BoralTextField'
import Tooltip from '@material-ui/core/Tooltip'
import _get from 'lodash/get'
import CommentIcon from '@material-ui/icons/Comment'
import Grid from '@material-ui/core/Grid'

function FieldComments ({
  field,
  onChange,
}: DRS.Comp) {
  const pulp = <FormControl>
    <BoralTextField
      id={field.path}
      name={field.path}
      label={field.name}
      value={field.data || ''}
      onChange={(e: SyntheticEvent) => onChange((e.target as HTMLInputElement).value)}
    />
  </FormControl>

  return <Wrapper>
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item>
        <CommentIcon />
      </Grid>
      <Grid item>
        <Tooltip title={field.data || ''} placement='bottom-start'>
          {pulp}
        </Tooltip>
      </Grid>
    </Grid>
    
  </Wrapper>
}

const Wrapper = styled.div`
  padding-top: 8px;
  width: 100%;
`

export default observer(FieldComments)