import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router'
import { useShift } from '@src/utils/hooks/useShift'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import Container from '@material-ui/core/Container'
import Breadcrumbs from '@src/components/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import CompPrestartGroup from './comps/PrestartGroup'
import { useMobileAssetPrestartsByShiftId } from '@src/utils/hooks/useMobileAssetPrestartsByShiftId'

interface Props {

}

const MAPListPage = (props: Props) => {
  const {

  } = props
  const { shiftId } = useParams<{ shiftId: string }>()
  const history = useHistory()
  const [ shift ] = useShift(shiftId)
  const [ mobileAssetPrestartsGroups ] = useMobileAssetPrestartsByShiftId(shiftId)
  if (shift === null) {
    return null
  }
  const { projectId, projectInternalId, date } = shift
  return <Wrapper maxWidth='xl'>
    <Breadcrumbs items={[
      { type: 'link', text: `Project ${projectId || projectInternalId}`, url: `/project/${projectInternalId}`},
      { type: 'text', text: `Shift ${formatDateStringOrNullDDMMYYYY(date)}`},
      { type: 'text', text: `Mobile Asset Prestarts`},
    ]}/>
    <Title>
      <TitleBold>MOBILE ASSET PRESTARTS | </TitleBold>
      <TitleNormal>{` ${formatDateStringOrNullDDMMYYYY(date)}`} </TitleNormal>
    </Title>
    <GroupsWrapper>
      { mobileAssetPrestartsGroups.map((item, index) => {
        return <GroupWrapper key={index}>
          <CompPrestartGroup mobileAssetPrestartsGroup={item}/>
        </GroupWrapper>
      })}
    </GroupsWrapper>
  </Wrapper>
}

const Wrapper = styled(Container)`

`

const Title = styled.div`
  display: flex;
`
const TitleBold = styled(Typography)`
  font-size: 36px;
  font-weight: bold;
  color: #009688;
`
const TitleNormal = styled(Typography)`
  font-size: 36px;
  color: #009688;
  padding-left: 10px;
`
const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const GroupWrapper = styled.div`
  flex-basis: 50%;
`

export default MAPListPage