import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const EmptyListHint = () => <STypography>Cannot find any projects. Please try another Search.</STypography>

const STypography = styled(Typography)`
  text-align: center;
  padding-top: 16px;
` as typeof Typography


export default EmptyListHint