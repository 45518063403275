import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import AggregateSizeAutocomplete from '../../../../../components/autocompletes/AggregateSizeAutocomplete'

const FieldAggregateSize = ({
  field,
  onChange,
}: DRS.Comp) => {
  const { id, description } = field.data
  const selectedItem = {
    value: id,
    label: description,
  }
  const handleChange = (item: AIMS.ValueLabel) => {
    onChange({
      id: item.value,
      description: item.label,
    })
  }
  return <Wrapper>
    <FormControl fullWidth>
      <AggregateSizeAutocomplete 
        selectedItem={selectedItem}
        onChange={handleChange}
        fullWidth={true}
      />
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
`

export default FieldAggregateSize