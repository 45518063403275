import React from 'react'
import styled from 'styled-components'
import { FormTableField } from '@src/components/Form/Table'
import CompPreviewFieldPair from './FieldPair'

interface Props<T> {
  value: T,
  fields: FormTableField<T>[],
}

function CompFlatFields<T>(props: Props<T>) {
  const {
    value,
    fields,
  } = props
  function renderCellString(field: FormTableField<T>, row: T) {
    const { key, type, label, format } = field
    const cellData: any = row[field.key]
    if (format && typeof format === 'function') {
      return format(cellData)
    }
    return cellData
  }
  return <Wrapper>
    { fields.map((field) => {
      return <CompPreviewFieldPair
        key={field.key as string}
        flat
        label={field.label || ''}
        value={renderCellString(field, value)}
      />
    })}
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompFlatFields
