import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { ProjectStatus } from '@src/types/project/ProjectStatus'
import { DownshiftSelect } from '@src/components'

const StatusOptions = [
  { value: ProjectStatus.Active, label: 'Active' },
  { value: ProjectStatus.Draft, label: 'Draft' },
  { value: ProjectStatus.PracticallyCompleted, label: 'Practically Completed' },
  { value: ProjectStatus.FinanciallyCompleted, label: 'Financially Completed' },
].map((option => {
  return {
    value: `${option.value}`,
    label: option.label,
  }
}))

const StatusSelect = ({
  selected,
  onChange = () => {},
}: {
  selected: number,
  onChange(value: number): void,
}) => {
  const selectedItem: any = StatusOptions.find(option => option.value === `${selected}`)
  const handleChange = (value: number) => {
    onChange(Number(value))
  }
  return (
    <DownshiftSelect 
      color='boral'
      options={StatusOptions} 
      value={{
        value: `${selectedItem!.value}`,
        label: selectedItem!.label,
      }} 
      onChange={handleChange}
      displayAsText={true} >
    </DownshiftSelect>
  )
}

export default observer(StatusSelect)