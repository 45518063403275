export enum JobType {
  Asphalt = 1,
  SpraySeal = 2,
}

export function formatJobType(type: JobType): string {
  switch (type) {
    case JobType.Asphalt:
      return 'Asphalt'
    case JobType.SpraySeal:
      return 'SpraySeal'
  }
}