import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'


interface Props {
  label: string,
  value: string,
  width: string,
  height: string,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
  disabled?: boolean,
}

const FormModalTextareaCustom = (props: Props) => {
  const {
    label,
    value,
    onChange,
    width,
    height,
    disabled
  } = props
  // console.log(typeof value, value)
  return <Wrapper
  style={{width:width}}
    multiline
    variant='outlined'
    minRows={12}
    label={label}
    value={value}
    onChange={onChange} 
    height={height}
    disabled={disabled}
  >

  </Wrapper>
}

const Wrapper = styled(Textfield)<{ height: string }>`
  

`

export default FormModalTextareaCustom