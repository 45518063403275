import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { useDRS } from '../../useDRS'
import PartDRSShiftDetails from './parts/ShiftDetails'
import PartDRSTimeDetails from './parts/TimeDetails'
import PartDRSShiftLocationDetails from './parts/ShiftLocationDetails'

interface Props {
  
}

const DRSJob = (props: Props) => { 
  const {

  } = props
  const { drs } = useDRS()

  if (drs === null) {
    return null
  }
  
  return <Wrapper>
    <FormSectionTitle>JOB DETAILS</FormSectionTitle>
    <PartDRSShiftDetails />
    <PartDRSShiftLocationDetails />
    <PartDRSTimeDetails />
  </Wrapper>
}

const Wrapper = styled.div`

`

export default DRSJob