import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  srrId: number,
  shiftId: number,
  serialId: number,
  weekendWork: boolean,
  handWork: boolean,
}

export const apiSrrUpdateShiftDetails = (options: Options) => {
  const { 
    srrId, 
    shiftId,
    serialId,
    weekendWork,
    handWork,
  } = options

  const data = {
    srrId,
    weekendWork,
    handWork,
  }
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/job/shift/details/${shiftId}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}