import { ax } from '../../utils/axios/axios'
import { BASE_API_URL } from '../../constants'


export const apiCrewUpdate = ({
  id,
  name,
  colourCode,
  depot,
  active,
  members,
  mobileAssets,
}: {
  id?: string | number | undefined,
  name?: string,
  colourCode?: string,
  depot?: AIMS.Depot,
  active?: boolean,
  members?: AIMS.Profile[],
  mobileAssets?: AIMS.MobileAsset[],
}) => {
  const data = {
    id,
    name,
    colourCode,
    depot,
    active,
    members,
    mobileAssets,
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/crews`,
    data,
  }
  return ax(options)
}