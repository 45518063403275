import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiProfileSearch } from '../../apis/profile-search'
import { Profile } from '@src/types/common/Profile'
import { RoleId } from '@src/types/common/RoleId'

const ProfileAutocomplete = ({
  stateId,
  id = '',
  label = '',
  onChange,
  selectedItem,
  fullWidth = false,
  roleId,
}: {
  stateId?: string,
  id?: string,
  label?: string,
  onChange(value: AIMS.ValueLabel): void,
  selectedItem: AIMS.ValueLabel,
  fullWidth?: boolean,
  roleId: RoleId,
}) => {
  const fetch = async (query: string) => {
    try {
      const result: any = await apiProfileSearch({
        query,
        stateId,
        roleId,
      })
      return result.data.profiles
        .slice(0, 5)
        .map((profile: Profile) => {
          const { email, firstName, lastName } = profile
          return {
            value: email,
            label: `${firstName} ${lastName}`,
            raw: profile,
          }
      })
    } catch(e) {
      return []
    }
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
  />
}


export default ProfileAutocomplete