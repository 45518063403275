import _set from 'lodash/set'
import _get from 'lodash/get'
import { ASSET_LOCATION_OPTIONS, CHECKBOX_SELECT_OPTIONS } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import moment from 'moment'
import { getStartEndDiff, formatHoursToDecimal } from '../../Create/comps/f-LabourMemberTable'
import { isAS, isSS } from '../EditDRSStore'

export default function getMobileAssetsSectionData(data: DRS.RootData) {
  const asAssets = getAssetPanels(true, data)
  const subcontractorAsphaltProfiling = getSubcontractorAsphaltProfilingPanels(data)
  const ssAssets = getAssetPanels(false, data)
  const subcontractorSpraySeal = getSubcontractorSpraySealPanels(data)
  let panels: any[] = []
  if (isAS(data)) {
    panels = [
      ...asAssets,  
      ...subcontractorAsphaltProfiling,
      ...getSubcontractorAsphaltGeneralPanels(data),
    ]
  }
  if (isSS(data)) {
    panels = [ 
      ...panels, 
      ...ssAssets,
      ...subcontractorSpraySeal,
    ]
  }

  const section = {
    label: 'MOBILE ASSETS',
    panels,
  }
  return section
}

function getAssetPanels(isAs: boolean, data: any) {
  const label = isAs ? `Asphalt Assets` : `Spray Seal Assets`
  const path = isAs ? `mobileAssets.boral.asphalt.assets` : `mobileAssets.boral.spraySeal.assets`
  const assetsData = _get(data, path)
  const assets = assetsData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'mobileAssetDepotId': { id: '' },
        'mobileAsset': {
          'id': '',
          'description': '',
          // 'depot': {},
          // 'region': {},
          // 'state': {},
          // 'active': false
        },
        'hours': '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getAssetFields(`${path}.${index}`),
    }
  })
  return assets
}
function getAssetFields(path: string) {
  return [
    {
      label: 'Asset Depot',
      type: 'select',
      path,
      relativePath: 'mobileAssetDepotId',
      editable: true,
      format: (value: any) => value ? value.id : '',
      options: ASSET_LOCATION_OPTIONS.map(item => ADIDNameToValueLabel(item)),
    },
    {
      label: 'Mobile Asset Number',
      type: 'asset-autocomplete',
      path,
      relativePath: 'mobileAsset',
      editable: true,
      format: (value: any) => value ? value.id : '',
    },
    {
      label: 'Asset Description',
      type: 'text',
      path,
      relativePath: 'mobileAsset.description',
      editable: true,
      readonly: true,
    },
    {
      label: 'Asset Hours',
      type: 'number',
      path,
      relativePath: 'hours',
      editable: true,
      showTrending: true,
    },
  ]
}

function getSubcontractorSpraySealPanels(data: DRS.RootData) {
  const label = `Subcontractor Spray Seal`
  const path = `mobileAssets.contractor.spraySeal.assets`
  const panelData: any = _get(data, path)
  const panels: any = panelData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'supplierName': '',
        'equipmentTypeDesc': '',
        'quantity': '',
        'docketNumber': '',
        'startTime': '',
        'endTime': '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getSubcontractorSpraySealFields(`${path}.${index}`),
    }
  })
  return panels
}

function getSubcontractorSpraySealFields(path: string) {
  return [
    {
      label: 'Supplier',
      type: 'text',
      path,
      relativePath: 'supplierName',
      editable: true,
    },
    {
      label: 'Equipment Type',
      type: 'text',
      path,
      relativePath: 'equipmentTypeDesc',
      editable: true,
    },
    {
      label: 'Quantity',
      type: 'text',
      path,
      relativePath: 'quantity',
      editable: true,
    },
    {
      label: 'Start Time',
      type: 'time',
      path,
      relativePath: 'startTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'End Time',
      type: 'time',
      path,
      relativePath: 'endTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'Hours',
      type: 'transport-table-hours',
      path,
      relativePath: 'hours',
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const typeTimeData: any =  _get(rootData, `${panelPath}.${planActualOrEdit}`);
          const { startTime, endTime } = typeTimeData
          return formatHoursToDecimal(getStartEndDiff({
            start: startTime,
            end: endTime,
          }))
        } catch (e) {
          return 0
        }
      },
      editable: true,
      readonly: true,
      editReadonly: true,
      showTrending: true,
    },
    {
      label: 'Docket Number',
      type: 'text',
      path,
      relativePath: 'docketNumber',
      editable: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}

function getSubcontractorAsphaltProfilingPanels(data: any) {
  const label = `Subcontractor Asset Type - Profiling`
  const path = `mobileAssets.contractor.asphalt.profilingAssets`
  const panelData = _get(data, path)
  const panels = panelData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'supplierName': '',
        'float': false,
        'tipSite': false,
        'millType': '',
        'millSize': '',
        'area': '',
        'depth': '',
        'rapSuitable': false,
        'docketNumber': '',
        'startTime': '',
        'endTime': '',
        'waitingTimeHours': '',
        'noOfTips': '',
        'noOfBlocks': '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getSubcontractorAsphaltProfilingFields(`${path}.${index}`),
    }
  })
  return panels
}

function getSubcontractorAsphaltProfilingFields(path: string) {
  return [
    {
      label: 'Supplier',
      type: 'text',
      path,
      relativePath: 'supplierName',
      editable: true,
    },
    {
      label: 'Mill Size (meters)',
      type: 'text',
      path,
      relativePath: 'millSize',
      editable: true,
    },
    {
      label: 'Mill Type',
      type: 'text',
      path,
      relativePath: 'millType',
      editable: true,
    },
    {
      label: 'Tip Site',
      type: 'checkbox-select',
      path,
      relativePath: 'tipSite',
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
      editable: true,
    },
    {
      label: 'Float',
      type: 'checkbox-select',
      path,
      relativePath: 'float',
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
      editable: true,
    },
    {
      label: 'Area',
      type: 'number',
      path,
      relativePath: 'area',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Depth',
      type: 'number',
      path,
      relativePath: 'depth',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Rap Suitable',
      type: 'checkbox-select',
      path,
      relativePath: 'rapSuitable',
      options: CHECKBOX_SELECT_OPTIONS,
      format: (value: any) => value ? 'YES' : 'NO',
      editable: true,
    },
    {
      label: 'Docket Number',
      type: 'text',
      path,
      relativePath: 'docketNumber',
      editable: true,
    },
    {
      label: 'Start Time',
      type: 'time',
      path,
      relativePath: 'startTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'End Time',
      type: 'time',
      path,
      relativePath: 'endTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'Hours',
      type: 'hours',
      path,
      relativePath: 'hours',
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const typeTimeData: any = _get(rootData, `${panelPath}.${planActualOrEdit}`);
          const { startTime, endTime } = typeTimeData
          return formatHoursToDecimal(getStartEndDiff({
            start: startTime,
            end: endTime,
          }))
        } catch (e) {
          return 0
        }
      },
      editable: true,
      readonly: true,
      editReadonly: true,
      showTrending: true,
    },
    {
      label: 'Waiting Time Hours',
      type: 'number',
      path,
      relativePath: 'waitingTimeHours',
      editable: true,
      showTrending: true,
    },
    {
      label: 'No. of Tips',
      type: 'number',
      path,
      relativePath: 'noOfTips',
      editable: true,
      showTrending: true,
    },
    {
      label: 'No. of Blocks',
      type: 'number',
      path,
      relativePath: 'noOfBlocks',
      editable: true,
      showTrending: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}
function getSubcontractorAsphaltGeneralPanels(data: any) {
  const label = `Subcontractor Asset Type - General`
  const path = `mobileAssets.contractor.asphalt.generalAssets`
  const panelData = _get(data, path)
  const panels = panelData.map((asset: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: asset.__drs__repeated_panel__,
      repeatItem: {
        'supplierName': '',
        'equipmentTypeDesc': '',
        'quantity': '',
        'docketNumber': '',
        'startTime': '',
        'endTime': '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getSubcontractorAsphaltGeneralFields(`${path}.${index}`),
    }
  })
  return panels
}

function getSubcontractorAsphaltGeneralFields(path: string) {
  return [
    {
      label: 'Supplier',
      type: 'text',
      path,
      relativePath: 'supplierName',
      editable: true,
    },
    {
      label: 'Equipment Type',
      type: 'text',
      path,
      relativePath: 'equipmentTypeDesc',
      editable: true,
    },
    {
      label: 'Quantity',
      type: 'text',
      path,
      relativePath: 'quantity',
      editable: true,
    },
    {
      label: 'Docket Number',
      type: 'text',
      path,
      relativePath: 'docketNumber',
      editable: true,
    },
    {
      label: 'Start Time',
      type: 'time',
      path,
      relativePath: 'startTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'End Time',
      type: 'time',
      path,
      relativePath: 'endTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
    },
    {
      label: 'Hours',
      type: 'hours',
      path,
      relativePath: 'hours',
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const typeTimeData: any = _get(rootData, `${panelPath}.${planActualOrEdit}`);
          const { startTime, endTime } = typeTimeData;
          return formatHoursToDecimal(getStartEndDiff({
            start: startTime,
            end: endTime,
          }))
        } catch (e) {
          return 0
        }
      },
      editable: true,
      readonly: true,
      editReadonly: true,
      showTrending: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}