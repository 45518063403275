import React from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import DeleteIcon from '@material-ui/icons/Delete'

import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useCrewCalender } from '../StoreCrewCalender'
import FileCopyIcon from '@material-ui/icons/FileCopy';
interface Props {
  anchorEl: null | HTMLElement,
  onClose(): void,

  onDelete?(): void,
  onDuplicateNote?(): void

}

const CrewNoteMoreActionsMenu = (props: Props) => {
  const {
    anchorEl = null,
    onClose,
  
    onDelete = () => { },
    onDuplicateNote = () => { }
  }  = props
  let menuOpen = Boolean(anchorEl)
  const { confirm } = useConfirm()

  const { isCalendarControlsDisabled } = useCrewCalender()
  const { alert } = useSnackbar()


  const beforeDelete = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    try {
      if (isCalendarControlsDisabled) {
        alert({
          type: 'error',
          message: `Note can't be deleted while the Calendar is updating.`
        })
        onClose()
        return
      }

      await confirm({
        title: 'Confirm Deletion of Crew Note',
        message: 'This will permanently delete crew note from the system and cannot be undone.',
      })
      onDelete()
    } catch (e) { }
  }
  const beforeDuplicateNote = async (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onDuplicateNote()
  }
  return <Wrapper>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={menuOpen}
      onClose={onClose}
    >
      
      <MenuItem onClick={beforeDelete} >
        <SListItemIcon>
          <SDeleteIcon fontSize='small' />
        </SListItemIcon>
        <Typography variant='inherit'>Delete Note</Typography>
      </MenuItem>
     
        <MenuItem onClick={beforeDuplicateNote}>
          <SListItemIcon>
            <FileCopyIcon fontSize='small' />
          </SListItemIcon>
          <Typography variant='inherit'>Note Duplicate</Typography>
        </MenuItem>
     
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div`
  svg {
    font-size: 36px;
    color: #0A9688;
  }
`
const SListItemIcon = styled(ListItemIcon)`
  color: #0A9688;
`
const SDeleteIcon = styled(DeleteIcon)`
  color: #D32929;
`

export default CrewNoteMoreActionsMenu