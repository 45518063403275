import React, { useEffect, useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { BoralSwitch } from '../../../components'
import TransportIcon from '@material-ui/icons/LocalShipping'
import Button from '@material-ui/core/Button'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {
  data: AIMS.Crew,
  onClick?(e: SyntheticEvent): void,
  onEditCrew?(crew: AIMS.Crew): void,
  onChangeCrewStatus?(crew: AIMS.Crew, checked: boolean): void,
}

function CrewCard (props: Props) {
  const { 
    data,
    onClick = () => {},
    onEditCrew = () => {},
    onChangeCrewStatus = () => {},
  } = props
  const { canEditCrewInMgmt, } = usePermission()
  const { id, name, colourCode, memberCount, assetCount, active } = data
  return <Wrapper>
    <LeftMain>
      <SCircle color={`#${colourCode}`}>
        <CrewIcon />
      </SCircle>
      <SName>
        { name }
      </SName>
      { canEditCrewInMgmt &&
        <IconButton onClick={() => onEditCrew(data)}>
          <EditIcon/>
        </IconButton>
      }
    </LeftMain>
    <RightActions>
      <Count>
        <SStatus inactive={!active}>
          { active ? 'Active' : 'Inactive' }
        </SStatus>
        { canEditCrewInMgmt && 
          <BoralSwitch
            checked={active}
            onChange={e => onChangeCrewStatus(data, (e.target as HTMLInputElement).checked)}
          />
        }
      </Count>
      <Count>
        <MemberIcon />
        { memberCount }
      </Count>
      <Count>
        <AssetIcon />
        { assetCount }
      </Count>
      <Button
        onClick={onClick}
        endIcon={<ArrowDropDown />}
      >
        { canEditCrewInMgmt ? `Manage Crew & Assets` : `View Crew & Assets` }
      </Button>
    </RightActions>
  </Wrapper>
  
}

const Wrapper = styled.div`
  border-top: 2px solid #E3E0E0;
  display: flex;
  /* justify-content: space-around; */
  padding: 12px 0 12px 12px;
`
const LeftMain = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`
const RightActions = styled.div`
  display: flex;
  align-items: center;
`
const Circle = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${props => {
    if (props.color) {
      return `${props.color}`
    }
    return `#F8D202`;
  }};
  text-align: center;
  line-height: 48px;
  color: #fff;
`
const SCircle = styled(Circle)`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
`
const CrewIcon = styled(SupervisorAccountIcon)`
  width: 22px;
  height: 22px;
`
export function CrewColoured ({
  colourCode,
}: {
  colourCode: string,
}) {
  return <SCircle color={`#${colourCode}`}>
    <CrewIcon />
  </SCircle>
}
const SName = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-left: 16px;
  margin-right: 16px;
`
const SStatus = styled.div<{inactive: boolean}>`
  color: #009688;
  ${props => props.inactive && `color: #b5adad;`}
`
const MemberIcon = styled(SupervisorAccountIcon)`
  color: #009688;
  margin-right: 9px;
`
const AssetIcon = styled(TransportIcon)`
  color: #009688;
  margin-right: 9px;
`
const Count = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  min-width: 50px;
`
  
export default CrewCard