import React from 'react'
import styled from 'styled-components'
import { JobTypeCircle } from './ASCircle'

const SSCircle = () => {
  return <JobTypeCircle>
    SS
  </JobTypeCircle>
}

export default SSCircle