import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { apiProjectGetOne } from '../../apis/project-get-one'
import { apiDRSListByProject } from '../../apis/drs-list-by-project'
import { ProjectDialog } from '../ProjectManager/comps/ProjectDialog'
import { useSnackbar } from '../../components/GlobalSnackbar/useSnackbar'
import _get from 'lodash/get'
import { useAppStore } from '../../AppStore'
import { ProjectDrsTable, ProjectDetailInfo } from './'
import ProjectCrewPacks from './parts/ProjectCrewPacks'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@src/components/Tab'
import { CollaboratorChips } from '../ProjectManager/comps/ProjectCard'
import ProjectShiftDocs from './parts/ProjectShiftDocs'
import { Project } from '@src/types/project/Project'
import CreateProjectModal from '../DashboardProjects/parts/CreateProjectModal'
import { apiShiftList } from '@src/apis/shift-list'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import Breadcrumbs from '@src/components/Breadcrumbs'
import usePermission from '@src/utils/hooks/usePermission'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import LinkDraftProjectDialog from './comps/LinkDraftProjectDialog'

type Part = 'project-documents' | 'shift-documents' | 'crew-packs' | 'photo-stream'

function ProjectDetailPage ({

}) {
  
  const history = useHistory()
  const [ tabValue, setTabValue ] = useState<any>(0)
  const { part, id } = useParams<{ part: Part, id: string }>()
  const projectId = id
  const location = useLocation()
  const [ project, setProject ] = useState<Project>()
  const [ drsList, setDRSList ] = useState<any>([])
  const [ editModalOpen, setEditModalOpen ] = useState<boolean>(false)
  const [ linkDraftModal, setLinkDraftModal ] = useState<boolean>(false)
  const { alert } = useSnackbar()
  const { canLinkDraftProject } = usePermission()
  const [ refreshToken, setRefreshToken ] = useState<number>(1)
  const possiblePartValues = [/* 'project-documents',  */'shift-documents', 'crew-packs'/* , 'photo-stream' */]
  useEffect(() => {
    setTabValue(possiblePartValues.indexOf(part))
  }, [ part ])
  
  useEffect(() => {
    const fetchProject = async () => {
      
      const result = await apiProjectGetOne(projectId)
      // queryString.parse(location.search).date
      setProject(result.data.project)
    }
    fetchProject()

    const fetchDRSList = async () => {
      const result = await apiShiftList({ projectInternalId: Number(projectId) })
      // queryString.parse(location.search).date
      setDRSList(result.data.shifts)
    }
    fetchDRSList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken])

  const handleEdit = () => {
    setEditModalOpen(true)
  }
  const handleClose = () => {
    setEditModalOpen(false)
  }
  // update project & show alert & hide modal
  const handleUpdateSuccess = (project: Project) => {
    setProject(project)
    alert({
      message: `Project updated.`
    })
    setEditModalOpen(false)
  }
  if (!project || !project.projectInternalId) {
    return null
  }
  
  
  const renderParts = () => {
    if (possiblePartValues.indexOf(part) === 0) {
      return <ProjectShiftDocs project={project} />
    }
    if (possiblePartValues.indexOf(part) === 1) {
      return <ProjectCrewPacks data={drsList} />
    }
    return null
  }

  return <React.Fragment>
    <BackgroundWrapper>
      <TopContainer maxWidth='xl' style={{ position: 'relative' }}>
        <Breadcrumbs items={[{ type: 'text', text: `Project ${project.id || project.projectInternalId}`}]}/>
        <FlexBox>
          <TitleWrapper status={`${project.status!.id}`}>
            <ProjectNumber component='span'>{`${project.id || project.projectInternalId} | `}</ProjectNumber>
            {project.name}
            {
              project.status!.id === ProjectStatus.Draft && canLinkDraftProject && 
              <LinkOffIconButton onClick={() => setLinkDraftModal(true)}><LinkOffIcon /></LinkOffIconButton>
            }
            { project.status!.id === ProjectStatus.Draft &&
              <AlertText />
            }
          </TitleWrapper>
          <CollaboratorWrapper>
            <CollaboratorChips items={project.projectTeam || []}/>
          </CollaboratorWrapper>
        </FlexBox>
        <SDivider />
        <ProjectDetailInfo
          project={project}
          drsList={drsList}
          onEdit={handleEdit}
        />
      </TopContainer>
    </BackgroundWrapper>
    <Container maxWidth='xl' style={{ position: 'relative' }}>
      <Wrapper>
        <MainWrapper>
          <TabsWrapper>
            <Tabs value={tabValue} onChange={(e, index) => {
                history.push({
                  pathname: `/project/${projectId}/${possiblePartValues[index]}`,
                })
              }}>
                {/* <Tab label='Project Documents'></Tab> */}
                <Tab label='Shift Documents'></Tab>
                <Tab label='Crew Packs'></Tab>
                {/* <Tab label='Photo Stream'></Tab> */}
              </Tabs>
          </TabsWrapper>
          
          { renderParts() }

        </MainWrapper>
        { editModalOpen &&
          <CreateProjectModal 
            project={project}
            open={editModalOpen}
            onClose={handleClose}
            onCancel={handleClose}
            onSuccess={handleUpdateSuccess}
          />
        }
        {
          linkDraftModal && 
          <LinkDraftProjectDialog 
            data={{project}}
            open={linkDraftModal}
            onClose={() => setLinkDraftModal(false)}
            onCancel={() => setLinkDraftModal(false)}
            onSuccess={() => {
              setLinkDraftModal(false)
              setRefreshToken(refreshToken + 1)
            }}
          />
        }
      </Wrapper> 
    </Container>
  </React.Fragment>
}

const Wrapper = styled.div``
const TopContainer = styled(Container)`
  padding-bottom: 52px;
`
const BackgroundWrapper = styled.div`
  background: #F8F8F8;
`
const TitleWrapper = styled.div<{ status: string }>`
  font-size: 36px;
  /* color: #009688; */
  line-height: 48px;
  height: 48px;
  ${props => {
    switch (props.status) {
      case `${ProjectStatus.Draft}`: {
        return css`
          color: #D32929;
        `
      }
      default: {
        return css`
          color: #0A9688;
        `
      }
    }
  }}
`
const ProjectNumber = styled(Typography)`
  font-size: 36px;
  font-weight: bold;
  color: inherit;
` as typeof Typography
const MainWrapper = styled.div`
`
const TabsWrapper = styled.div`
  margin: 26px 0 40px;
`

const SDivider = styled(Divider)`
  margin: 24px 0 14px;
  height: 2px;
  background-color: #129688;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`
const EditButton = styled(Button)`
  margin-right: 50px;
  align-self: center;
`
const CollaboratorWrapper = styled.div`
  margin-right: 50px;
  align-self: center;
  max-width: 600px;
`
const AlertText = () => {
  return <AlertTextWrapper>
    <div>All project financials from this draft project will NOT integrate with Scenario.</div>
    <div>This draft project will need to be linked to an active Scenario project number in the future</div>
  </AlertTextWrapper>
}
const AlertTextWrapper = styled.div`
  display: inline-block;
  width: 580px;
  font-size: 14px;
  line-height: 21px;
  margin-left: 20px;
`
const LinkOffIconButton = styled(IconButton)`
  color: #fff;
  background-color: #D32929;
  font-size: 16px;
  margin-left: 10px;
  padding: 4px;
  /* &:hover {
    background-color: #D32929;
  } */
`
export default observer(ProjectDetailPage)