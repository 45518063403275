import axios from 'axios'
import { DEVICE_INFO } from '../constants'

export const apiDeviceFilter = (token: any, id: string, start: number, end: number) => {
  const options = {
    method: 'GET',
    headers: { 
      'Authorization': `Bearer ${token}`
    },
    url: `${DEVICE_INFO}${id}/?from=${start}&to=${end}`,
  }
  return axios(options as any)
}