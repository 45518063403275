import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Breadcrumbs from '@src/components/Breadcrumbs'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import LeftNavStickyWrapper from '@src/components/LeftNav/StickyWrapper'
import { LeftNav } from '@src/components/LeftNav'
import { NavDeepLink } from '@src/types/NavDeepLink'
import CompVMPActionMenu from './parts/drawing-tools/comps/ActionMenu'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import CompVMPJob from './parts/1-Job/Job'
import CompVMPMap from './parts/2-9-Maps/VMPMap'
import { useVMP } from './useVMP'
import { formatVMPStatusId } from '@src/types/VMPStatusId'
import { observer } from 'mobx-react-lite'
import { VMPSiteMap } from '@src/types/VMPSiteMap'
import { apiVMPAddSiteMap } from '@src/apis/vmp-add-site-map'
import { useAppStore } from '@src/AppStore'
import { useBundle } from '@src/utils/hooks/useBundle'
import { apiVMPRemoveSiteMap } from '@src/apis/vmp-remove-site-map'
import { apiVMPDuplicateSiteMap } from '@src/apis/vmp-duplicate-site-map'
import { apiVMPSubmit } from '@src/apis/vmp-submit'
import { apiVMPSiteMapPDF } from '@src/apis/vmp-site-map-pdf'

const VMPDetailPage = () => {
  const { serialId } = useAppStore()
  const { alert, alertError, alertDone } = useSnackbar()
  const { id } = useParams<{id: string}>()
  const { hash } = useLocation()
  const history = useHistory()
  const [ bundle ] = useBundle()
  const [ vmp, loading, refresh ] = useVMP()
  const maps = vmp === null ? [] : vmp.siteMaps
  const primaryMapIndex: number = 1
  const deepLinks: NavDeepLink<any>[] = [
    {
      anchor: 'Job',
      urlHash: 'job',
      key: 'job',
    },
    ...maps.map((map: VMPSiteMap, index: number) => {
      return {
        anchor: `Site Map ${index + 1}`,
        urlHash: `map${index + 1}`,
        key: `map${index + 1}`,
      }
    })
  ]
  const [ activeSection, setActiveSection ] = useState<any>('job')
  const activeIndex = deepLinks.findIndex((link: NavDeepLink<any>) => link.key === activeSection)
  // deep linking
  useEffect(() => {
    if (!maps.length) {
      return 
    }
    if (hash) {
      const trimmedHash = hash.substring(1)
      if (deepLinks.some((link: NavDeepLink<any>) => link.urlHash === trimmedHash)) {
        setActiveSection(
          deepLinks.find((link: NavDeepLink<any>) => link.urlHash === trimmedHash)!
            .key
        )
        return
      }
    }
    setActiveSection('job')
  }, [id, maps])
  // deep linking
  useEffect(() => {
    if (!maps.length) {
      return 
    }
    const found = deepLinks.find((link: NavDeepLink<any>) => link.key === activeSection)
    if (!found) {
      return 
    }
    history.replace({
      hash: `#${found.urlHash}`
    })
  }, [activeSection, maps])
  if (vmp === null) {
    return null
  }
  const handleOnAnchorClick = (index: number) => {
    setActiveSection(deepLinks[index].key)
  }

  const handleSubmitVMP = async () => {

    try {
      await apiVMPSubmit({
        serialId,
        vmpId: vmp.id,
      })
      refresh()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const handleAddMap = async () => {
    if (maps.length >= 8) {
      alert({
        type: 'warning',
        message: 'Maximum 8 Maps',
      })
      return
    }
    try {
      const defaultSiteMap: VMPSiteMap = {
        rules: [],
        siteDiagram: {
          ...bundle.vmpDefaultSiteDiagram,
          vmpId: vmp.id,
        },
      }
      await apiVMPAddSiteMap({
        serialId,
        vmpId: vmp.id,
        siteMap: defaultSiteMap,
      })
      await refresh()
      alertDone()
      setActiveSection(`map${maps.length + 1}`)
    } catch (e) {
      alertError()
    }
  }
  const handleRemoveMap = async () => {
    const currentMapIndex = activeIndex - 1
    if (currentMapIndex >= 0 && currentMapIndex < maps.length) {
      try {
        await apiVMPRemoveSiteMap({
          serialId,
          vmpId: vmp.id,
          siteMapId: maps[currentMapIndex].siteDiagram.id,
        })
        refresh()
        alertDone()
        setActiveSection(deepLinks[activeIndex - 1].key)
      } catch (e) {
        alertError()
      }
    }
  }
  const handleDuplicateMap = async () => {
    if (maps.length >= 8) {
      alert({
        type: 'warning',
        message: 'Maximum 8 Maps',
      })
      return
    }
    const currentMapIndex = activeIndex - 1
    if (currentMapIndex >= 0 && currentMapIndex < maps.length) {
      try {
        await apiVMPDuplicateSiteMap({
          serialId,
          vmpId: vmp.id,
          siteMapId: maps[currentMapIndex].siteDiagram.id,
        })
        await refresh()
        alertDone()
        setActiveSection(`map${maps.length + 1}`)
      } catch (e) {
        alertError()
      }
    }
  }
  const handleDownloadPDFMap = async () => {
    const currentMapIndex = activeIndex - 1
    const siteMapId = maps[currentMapIndex].siteDiagram.id
    try {
      const result = await apiVMPSiteMapPDF({
        id: siteMapId,
        serialId,
      })
      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      const pdfName = `VMP-${vmp.id}--SiteMap-${siteMapId}.pdf`
      link.setAttribute('download', pdfName)
      document.body.appendChild(link)
      link.click()
    } catch (e) {

    }
  }
  const renderPart = () => {
    switch(activeSection) {
      case 'job': {
        return <CompVMPJob />
      }
      default: {
        return <CompVMPMap 
          activeIndex={activeIndex}
          // primaryMapIndex={primaryMapIndex}
        />
      }
    }
  }
  const { id: projectId, projectInternalId, projectName, } = vmp.job.projectDetails
  const { id: shiftId, street, suburb } = vmp.job.shiftDetails
  return <Wrapper maxWidth='xl'>
    <Breadcrumbs items={[
      { type: 'link', text: `Project ${projectId || projectInternalId}`, url: `/project/${projectInternalId}`},
      { type: 'text', text: `Vehicle Movement Plan`},
    ]}/>
    <MainWrapper>
      <LeftNavStickyWrapper top={16}>
        <LeftNav 
          title={`Vehicle Movement Plan`}
          statusName={formatVMPStatusId(vmp.status.id)}
          sections={[
            {
              label: 'Project',
              value: `${projectId || projectInternalId} | ${projectName}`,
            },
            {
              label: 'Site',
              value: (suburb && street) ? `${suburb}, ${street}` : '',
            },
            {
              label: 'Shift',
              value: `${shiftId}`,
            }
          ]}
          anchors={deepLinks.map((link: NavDeepLink<any>) => link.anchor)}
          activeIndex={activeIndex}
          onAnchorClick={handleOnAnchorClick}
          // chipIndex={primaryMapIndex}
          // chipText={'Primary'}
        />
      </LeftNavStickyWrapper>
      <PartWrapper>
        { renderPart() }
        <ActionWrapper>
          <CompVMPActionMenu 
            activeIndex={activeIndex}
            onSubmitVMP={handleSubmitVMP}
            onAddMap={handleAddMap}
            onRemoveMap={handleRemoveMap}
            onDuplicateMap={handleDuplicateMap}
            onDownloadPDFMap={handleDownloadPDFMap}
          />
        </ActionWrapper>
      </PartWrapper>
    </MainWrapper>
  </Wrapper>
}

const Wrapper = styled(Container)`

`
const MainWrapper = styled.div`
  display: flex;
`
const PartWrapper = styled.div`
  position: relative;
  padding: 0 40px;
  flex-grow: 1;
`
const ActionWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: -6px;
`

export default observer(VMPDetailPage)
