import _set from 'lodash/set'
import _get from 'lodash/get'
import { SHIFT_TYPE_OPTIONS, getWeathers } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import moment from 'moment'
import { getProjectTypeLabel } from '../../../../../constants/project-types-enum'

export default function getJobDetailSectionData(data: DRS.RootData) {
  // first section
  const projectDetailPanel = {
    label: 'Project Details',
    repeatable: false,
    path: `jobDetails`,
    fields: [
      {
        showOnlyPlan: true,
        label: 'Project Name',
        type: 'text',
        absolutePath: 'jobDetails.project.name',
        editable: false,
      },
      {
        showOnlyPlan: true,
        label: 'Project Number',
        type: 'text',
        absolutePath: 'jobDetails.project.id',
        editable: false,
      },
      {
        showOnlyPlan: true,
        label: 'Customer',
        type: 'text',
        absolutePath: 'jobDetails.project.customer.name',
        editable: false,
      },
      {
        showOnlyPlan: true,
        label: 'Job Type',
        type: 'text',
        absolutePath: 'jobDetails.project.type.id',
        editable: false,
        format: (value: any) => getProjectTypeLabel(value),
      },
    ]
  }
  const shiftLocationDetailPanel = {
    label: 'Shift Location Details',
    repeatable: false,
    path: `jobDetails`,
    fields: [
      {
        label: 'Street',
        type: 'text',
        path: `jobDetails`,
        relativePath: 'streetName',
        editable: true,
      },
      {
        label: 'Nearest Street Cross',
        type: 'text',
        path: `jobDetails`,
        relativePath: 'nearestCrossStreet',
        editable: true,
      },
      {
        label: 'Suburb',
        type: 'text',
        path: `jobDetails`,
        relativePath: 'suburb',
        editable: true,
      },
      {
        label: 'Kms from Plant',
        type: 'number',
        path: `jobDetails`,
        relativePath: 'kmPlantToDepot',
        editable: true,
        showTrending: true,
      },
    ]
  }
  const timeDetailPanel = {
    label: 'Time Details',
    repeatable: false,
    path: `jobDetails.shift`,
    fields: [
      {
        showOnlyPlan: true,
        label: 'Date',
        type: 'text',
        absolutePath: 'jobDetails.date',
        format: (value: any) => moment(value).format('DD/MM/YYYY'),
      },
      {
        label: 'Shift',
        type: 'select',
        path: `jobDetails.shift`,
        relativePath: 'shiftType',
        editable: true,
        format: (value: any) => value ? value.name : '',
        options: SHIFT_TYPE_OPTIONS.map(item => ADIDNameToValueLabel(item)),
      },
      {
        label: 'Weather',
        type: 'select',
        path: 'jobDetails',
        relativePath: 'weather',
        editable: true,
        format: (value: any) => value ? value.description : '',
        options: getWeathers().map(item => ADIDNameToValueLabel(item)),
      },
    ]
  }
  const crewDetailPanel = {
    label: 'Crew Details',
    repeatable: false,
    path: `jobDetails`,
    fields: [
      {
        label: 'Crew',
        type: 'text',
        path: `jobDetails`,
        relativePath: 'crew.name',
        editable: false,
      },
      {
        label: 'Shift Allowance',
        type: 'text',
        path: `jobDetails`,
        relativePath: 'siteAllowance',
        editable: true,
      },
      /* 
      {
        label: 'Tip Fees',
        type: 'number',
        path: `jobDetails`,
        relativePath: 'tipFees',
        editable: true,
        showTrending: true,
      },
      */
    ]
  }

  const section = {
    label: 'JOB DETAILS',
    panels: [ projectDetailPanel, shiftLocationDetailPanel, timeDetailPanel, crewDetailPanel ]
  }
  console.log(section)
  return section
}