import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { useTabs } from '@src/components/Tabs/useTabs'
import TabsComp, { Tab } from '@src/components/Tabs/Tabs'
import TabsWrapper from '../../comps/TabsWrapper'
import CompSRRTransport from './parts/1-Transport/Transport'
import CompAdditionalTransportInfo from './parts/1-Transport/AdditionalTransportInfo'
import ResourceExtraResources from './parts/2-Resource/11-ExtraResources'
import MachineProfiling from './parts/3-Profiling/Profiling'
import MachineFloat from './parts/4-Float/MachineFloat'
import ParkingPatrolInfo from './parts/5-TrafficControl/ParkingPatrolInfo'
import SRRTrafficControl from './parts/5-TrafficControl/TrafficControl'
import ResourcePaver from './parts/2-Resource/1-Paver'
import ResourceSteelRoller from './parts/2-Resource/2-SteelRoller'
import ResourceMultiRoller from './parts/2-Resource/3-MultiRoller'
import ResourceSkidSteerCombo from './parts/2-Resource/4-SkidSteerCombo'
import ResourceSkidSteerBroom from './parts/2-Resource/5-SkidSteerBroom'
import ResourceSuctionBroom from './parts/2-Resource/6-SuctionBroom'
import ResourceTractorBroom from './parts/2-Resource/7-TractorBroom'
import ResourceWaterCart from './parts/2-Resource/8-WaterCart'
import ResourceLineMarking from './parts/2-Resource/9-LineMarking'
import ResourceVMSBoards from './parts/2-Resource/10-VMSBoards'

interface Props {

}

const SRRMachine = (props: Props) => {
  const {

  } = props
  const [ tabIndex, tabs, handleTabChange ] = useTabs([
    {
      key: 'Transport',
      label: 'Transport',
    },
    {
      key: 'Resource',
      label: 'Resource',
    },
    {
      key: 'Profiling',
      label: 'Profiling',
    },
    {
      key: 'Float',
      label: 'Float',
    },
    {
      key: 'TrafficControl',
      label: 'Traffic Control',
    },
  ])
  const renderParts = () => {
    const currentTab: Tab = tabs[tabIndex]
    switch(currentTab.key) {
      case 'Transport': {
        return <React.Fragment>
          <CompSRRTransport />
          <CompAdditionalTransportInfo />
        </React.Fragment>
      }
      case 'Resource': {
        return <React.Fragment>
          <ResourcePaver />
          <ResourceSteelRoller />
          <ResourceMultiRoller />
          <ResourceSkidSteerCombo />
          <ResourceSkidSteerBroom />
          <ResourceSuctionBroom />
          <ResourceTractorBroom />
          <ResourceWaterCart />
          <ResourceLineMarking />
          <ResourceVMSBoards />
          <ResourceExtraResources />
        </React.Fragment>
      }
      case 'Profiling': {
        return <React.Fragment>
          <MachineProfiling />
        </React.Fragment>
      }
      case 'Float': {
        return <React.Fragment>
          <MachineFloat />
        </React.Fragment>
      }
      case 'TrafficControl': {
        return <React.Fragment>
          <SRRTrafficControl />
          <ParkingPatrolInfo />
        </React.Fragment>
      }
    }
  }
  return <Wrapper>
    <FormSectionTitle>MACHINE</FormSectionTitle>
    <TabsWrapper>
      <TabsComp 
        tabs={tabs} 
        activeIndex={tabIndex}
        onChange={handleTabChange}
      />
    </TabsWrapper>
    { renderParts() }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default SRRMachine