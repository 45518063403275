import React from 'react'
import styled from 'styled-components'
import CompPrestartItem from './PrestartItem'
import { useHistory } from 'react-router'
import { MobileAssetPrestartBrief } from '@src/types/MobileAssetPrestart'
import { MobileAssetPrestartBriefGroup } from '@src/utils/hooks/useMobileAssetPrestartsByShiftId'
import { apiMobileAssetPrestartGetPdf } from '@src/apis/mobile-asset-prestart-get-pdf'
import { RoleId } from '@src/types/common/RoleId'
import { useAppStore } from '@src/AppStore'

interface Props {
  mobileAssetPrestartsGroup: MobileAssetPrestartBriefGroup,
}

const CompPrestartGroup = (props: Props) => {
  const {
    mobileAssetPrestartsGroup,
  } = props
  const { serialId } = useAppStore()
  const history = useHistory()
  if (!mobileAssetPrestartsGroup) {
    return null
  }
  const handleClick = (row: MobileAssetPrestartBrief) => async () => {
    try {
      const result = await apiMobileAssetPrestartGetPdf({
        id: row.mapId,
        serialId,
        roleId: RoleId.ProjectManager,
      })
      
      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `MAP-${row.mapId}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.log(e)
    }
  }
  return <Wrapper>
    <TypeTitle>{mobileAssetPrestartsGroup.type.description}</TypeTitle>
    { mobileAssetPrestartsGroup.maps.map((item, index) => {
      return <CompPrestartItem 
        key={item.mapId} 
        mobileAssetPrestart={item}
        onClick={handleClick(item)} />
    })}
    
  </Wrapper>
}

const Wrapper = styled.div`
  margin-right: 80px;
  margin-top: 8px;
  margin-bottom: 8px;
`
const TypeTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #009688;
`

export default CompPrestartGroup