import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'

interface Options  {
  srrId: number | string,
  serialId: number,
  partial: Partial<MaterialAsphaltProduct>,
}

export const apiSrrTrafficControlUpdate = (options: Options) => {
  const { 
    srrId, 
    serialId,
    partial,
  } = options

  const data = {
    srrId,
    ...partial,
  }
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/machine/trafficcontrol/details/${partial.id}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}