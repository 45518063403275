/* rework */
export const ReworkPath = `rework`

export const ReworkAsPath = `${ReworkPath}.asphalt`
export const ReworkAsPerformedPath = `${ReworkAsPath}.performed`
export const ReworkAsDetailPath = `${ReworkAsPath}.detail`
export const ReworkAsSummaryPath = `${ReworkAsPath}.summary`

export const ReworkSsPath = `${ReworkPath}.spraySeal`
export const ReworkSsPerformedPath = `${ReworkSsPath}.performed`
export const ReworkSsDetailPath = `${ReworkSsPath}.detail`
export const ReworkSsSummaryPath = `${ReworkSsPath}.summary`

/* materials */

export const MaterialsPath = `materials`
export const MaterialsAsPath = `${MaterialsPath}.asphalt`
export const MaterialsAsProductPath = `${MaterialsAsPath}.detail`

export const MaterialsSsPath = `${MaterialsPath}.spraySeal`
export const MaterialsSsProductsPath = `${MaterialsSsPath}.detail`

/* transport */
export const TransportPath = `transport`

export const TransportBoralPath = `${TransportPath}.boral`
export const TransportAsBoralPath = `${TransportBoralPath}.asphalt`
export const TransportAsBoralPerformedPath = `${TransportAsBoralPath}.performed`

export const TransportSubContractorPath = `${TransportPath}.contractor`
export const TransportAsSubContractorPath = `${TransportSubContractorPath}.asphalt`
export const TransportAsSubContractorPerformedPath = `${TransportAsSubContractorPath}.performed`

export const TransportSsSubContractorPath = `${TransportSubContractorPath}.spraySeal`
export const TransportSsSubContractorPerformedPath = `${TransportSsSubContractorPath}.performed`

/* mobileAssets */
export const MobileAssetsPath = 'mobileAssets'

export const MobileAssetsBoralPath = `${MobileAssetsPath}.boral`

export const MobileAssetsBoralAsPath = `${MobileAssetsBoralPath}.asphalt`
export const MobileAssetsBoralAsPerformedPath = `${MobileAssetsBoralAsPath}.performed`

export const MobileAssetsBoralSsPath = `${MobileAssetsBoralPath}.spraySeal`
export const MobileAssetsBoralSsPerformedPath = `${MobileAssetsBoralSsPath}.performed`

export const MobileAssetsSubContractorPath = `${MobileAssetsPath}.contractor`
export const MobileAssetsSubContractorAsPath = `${MobileAssetsSubContractorPath}.asphalt`
export const MobileAssetsSubContractorAsPerformedPath = `${MobileAssetsSubContractorAsPath}.performed`
export const MobileAssetsSubContractorAsProfilingPath = `${MobileAssetsSubContractorAsPath}.profilingAssets`
export const MobileAssetsSubContractorAsDeliveryPath = `${MobileAssetsSubContractorAsPath}.generalAssets`

export const MobileAssetsSubContractorSsPath = `${MobileAssetsSubContractorPath}.spraySeal`
export const MobileAssetsSubContractorSsPerformedPath = `${MobileAssetsSubContractorSsPath}.performed`

/* labour */
export const LabourPath = 'labour'