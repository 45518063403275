import React, { useState, useEffect } from 'react'
import AutocompleteComp from '../GeneralAutocomplete/Autocomplete'
import { Autocomplete } from '@src/types/Autocomplete'
import { apiQueryProjects } from '@src/apis/projects'
import { Result, ProjectsResult } from '@src/types/api/Result'
import { Project } from '@src/types/project/Project'
import { ProjectStatus } from '@src/types/project/ProjectStatus'

interface Options extends Autocomplete {
  isLinked?: boolean,
  status?: ProjectStatus | ProjectStatus[],
  onChange(project: Project): void,
}

const ProjectAutocomplete = (options: Options) => {
  const {
    id = '',
    label = '',
    onChange,
    selectedItem,
    disabled = false,
    fullWidth = false,
    isLinked,
    status,
  } = options
  const fetch = async (query: string) => {
    const result: ProjectsResult = await apiQueryProjects({
      query,
      status,
      isLinked,
    })
    return result.data.projects
      .slice(0, 5)
      .map((project: Project) => {
        return {
          value: project.id,
          label: `${project.id || project.projectInternalId} | ${project.name}`,
          raw: project,
        }
      })
  }
  let finalSelectedItem
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  const handleChange = (item: any) => {
    onChange(item.raw)
  }
  return <AutocompleteComp
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={handleChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
    disabled={disabled}
  />
}

export default ProjectAutocomplete