import React, { useState } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import FormTable, { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import { SRRTransport } from '@src/types/SRR'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import Modal from '@src/components/FormModal/Modal'
import { apiSrrTransportCreate } from '@src/apis/srr-transport-create'
import { TransportTypeId, formatTransportTypeId } from '@src/types/TransportTypeId'
import { apiSrrTransportUpdate } from '@src/apis/srr-transport-update'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiSrrTransportRemove } from '@src/apis/srr-transport-remove'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const CompSRRTransport = (props: Props) => {
  const {

  } = props
  const [srr, srrLoading, refresh] = useSRR()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()
  const [transportType, setTransportType] = useState<TransportTypeId>(TransportTypeId.Delivery)
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data: SRRTransport[] = srr.machine.transport.details
  const anyDelivery: boolean = Boolean(data.find((transport: SRRTransport) => transport.transportType.id === TransportTypeId.Delivery))
  const anyProfiling: boolean = Boolean(data.find((transport: SRRTransport) => transport.transportType.id === TransportTypeId.Profiling))

  const hanldeEditRow = (row: SRRTransport) => {
    setTransportType(row.transportType.id)
    openAndSetData({
      ...row,
      machines: SRRTransportMachines.filter((machine) => {
        return row[machine.key] === true
      }).map((machine) => {
        return machine.key
      }),
    })
  }
  const handleSave = async (form: any) => {
    console.log(form, transportType)
    if (modalData === null) {
      try {
        const result = await apiSrrTransportCreate({
          srrId: srr.id,
          serialId,
          transportTypeId: transportType,
          partial: {
            ...form,
            ...SRRTransportMachines.map((machine) => {
              return {
                ...machine,
                checked: form.machines.some((item: string) => machine.key === item),
              }
            }).reduce((acc, curr) => {
              return {
                ...acc,
                [curr.key]: curr.checked,
              }
            }, {}),
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    } else {
      try {
        const result = await apiSrrTransportUpdate({
          srrId: srr.id,
          serialId,
          transportTypeId: transportType,
          partial: {
            ...form,
            ...SRRTransportMachines.map((machine) => {
              return {
                ...machine,
                checked: form.machines.some((item: string) => machine.key === item),
              }
            }).reduce((acc, curr) => {
              return {
                ...acc,
                [curr.key]: curr.checked,
              }
            }, {}),
          }
        })
        alert({
          message: `Done.`
        })
        refresh()
        closeModal()
      } catch (e) {
        alert({
          type: 'error',
          message: `Error.`
        })
      }
    }
  }
  const hanldeRemoveRow = async (row: SRRTransport) => {
    try {
      await confirm({
        title: `Confirm deletion`,
        message: ``,
      })
      confrimRemoveRow(row)
    } catch (e) {

    }
  }
  const confrimRemoveRow = async (row: SRRTransport) => {
    try {
      const result = await apiSrrTransportRemove({
        srrId: srr.id,
        serialId,
        id: row.id,
      })
      alert({
        message: `Removed.`
      })
      refresh()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel title={`Transport`}>
      <FormTable<SRRTransport>
        fields={fields}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={hanldeEditRow}
        onRemoveRow={hanldeRemoveRow}
      />
      {canEditSubmitSRR && !anyDelivery && <MarginTop>
        <CompCreateButton onClick={() => {
          setTransportType(TransportTypeId.Delivery)
          openAndSetData(null)
        }}>
          Add Delivery Transport
        </CompCreateButton>
      </MarginTop>
      }
      {canEditSubmitSRR && !anyProfiling && <MarginTop>
        <CompCreateButton onClick={() => {
          setTransportType(TransportTypeId.Profiling)
          openAndSetData(null)
        }}>
          Add Profiling Transport
        </CompCreateButton>
      </MarginTop>
      }
      {open && <Modal<TransportInModal>
        maxWidth='xs'
        open={open}
        title='Transport'
        data={{
          rowData: modalData,
          fields: modalFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'orderedTonnesPerRound',
                'machines',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
export const SRRTransportMachines: { key: keyof SRRTransport, label: string }[] = [
  {
    key: 'singleAxle',
    label: 'Single Axle',
  },
  {
    key: 'tandem',
    label: 'Tandem',
  },
  {
    key: 'n8WTwinSteer',
    label: '8W / Twin Steer',
  },
  {
    key: 'n20TNRigids',
    label: '20 TN Rigids',
  },
  {
    key: 'n20TNArt',
    label: '20 TN Art',
  },
  {
    key: 'triSemi',
    label: 'Tri Semi',
  },
  {
    key: 'tnd',
    label: 'T & D',
  },
  {
    key: 'floconRigid',
    label: 'Flocon (Rigid)',
  },
  {
    key: 'floconSemi',
    label: 'Flocon Semi',
  },
]

export const SRRTransportOrderedTonnesPerRoundField: FormTableField<SRRTransport | TransportInModal> = {
  key: 'orderedTonnesPerRound',
  type: FormTableFieldType.Number,
  label: 'Ordered tonnes per round',
  maxScale: 2,
  helperTextError: `only 2 decimal places allowed`,
}
const fields: FormTableField<SRRTransport>[] = [
  {
    key: 'transportType',
    type: FormTableFieldType.Text,
    label: 'Transport Type',
    format: (cellData: SRRTransport['transportType']) => {
      if (cellData && cellData.id) {
        return formatTransportTypeId(cellData.id)
      }
      return ''
    },
  },
  SRRTransportOrderedTonnesPerRoundField,
  ...SRRTransportMachines.map((machine) => {
    return {
      ...machine,
      type: FormTableFieldType.BooleanRadioGroup,
      format: (cellData: boolean | null) => {
        if (cellData === null) {
          return ''
        } else {
          return formatBooleanToYesNo(cellData)
        }
      }
    }
  }),
]
type TransportInModal = {
  id: number,
  orderedTonnesPerRound: number,
  machines: any[],
}
const modalFields: FormTableField<TransportInModal>[] = [
  SRRTransportOrderedTonnesPerRoundField,
  {
    key: 'machines',
    label: 'Select Resources',
    type: FormTableFieldType.CheckboxGroup,
    options: SRRTransportMachines.map((machine) => ({ value: machine.key, label: machine.label })),
  }
]
export default CompSRRTransport