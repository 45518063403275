import axios, { Canceler } from 'axios'
import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'
let cancel: Canceler 

export const apiMobileAssetSearch = ({
  query,
  depotId,
  stateId,
  active,
}: {
  query: string,
  depotId?: string,
  stateId?: string,
  active?: boolean,
}) => {
  if (cancel) {
    cancel()
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/mobileassets`,
    params: {
      search: query,
      depot: depotId,
      state: stateId,
      active,
    },
    cancelToken: new axios.CancelToken((c: Canceler) => {
      cancel = c
    }),
  }
  return ax(options)
}