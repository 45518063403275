import _orderBy from 'lodash/orderBy'
import _clone from 'lodash/clone'

export const reOrderSifs = (sifsToOrder: any[]) => {
  let lowerCaseSifs: any[] = _clone(sifsToOrder);
  lowerCaseSifs = lowerCaseSifs.map((sif) => {
    { sif.street ? sif.streetLower = sif.street.toLowerCase() : sif.streetLower = '' }
    return sif;
  });

  return _orderBy(lowerCaseSifs, ['streetLower'], ['asc'])
}