import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { useAppStore } from '@src/AppStore'
import usePermission from '@src/utils/hooks/usePermission'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import FormModalTextareaLarge from '@src/components/FormModal/TextareaLarge'
import { getLength } from '@src/utils/validateLength'


interface Props {
    open: boolean,
    commentData?: string,
    onClose(): void,
    onSave(req: string): void
 
 
}

const SealRequirementDialog = (props: Props) => {
    const {
        open,
        onClose,
        onSave,
        commentData
    } = props

    const [noteDesc, setNoteDesc] = useState<string>('')
    const {  alertError, alert } = useSnackbar()
    const { canEditNote } = usePermission()
    const [isSaving, setIsSaving] = useState<any>(false)

    const [fieldsInError, setFieldsInError] = useState<any[]>([])

    const [remainigChars, setRemainigChars] = useState<number>(100)

    useEffect(() => {
        console.log(commentData);
        if (commentData) {
            setNoteDesc(commentData);
            setRemainigChars(100 - commentData.length);
        }
    }, [commentData])
    let validateNotes: any = null;
    const handleSave = async () => {
         if (!noteDesc) {
            alert({
                type: 'error',
                message: 'Note description required',
            })
            return
        }
        if (noteDesc.length > 100) {
            alert({
                type: 'error',
                message: 'Max 100 characters allowed in Note description.',
            })
            return
        }
        setIsSaving(true)
        try {
            onSave(noteDesc);
            setIsSaving(false)
        } catch (e) {
            alertError()
            onClose()
        }
    }



    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={onClose}
    >
        <DialogTitle >
            <ModalTitle>Seal  Requirements</ModalTitle>
        </DialogTitle>
        <DialogContent>
            <SectionWrapper>
                <Section>
                    <FieldWrapper>
                        <FormModalTextareaLarge label={'Seal Requirements'} defaultValue={noteDesc.replace('blank', '')} rows={6} disabled={!canEditNote} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                            var val = e.target.value;
                            setRemainigChars(100 - val.length);
                            if (validateNotes != null) {
                                clearTimeout(validateNotes)
                            }
                            validateNotes = setTimeout(() => {
                                if (getLength(val, 100)) {
                                    setFieldsInError(_union(fieldsInError, [{ key: 'Seal Requirements', helperTextError: "Max 100 characters allowed" }]))
                                } else {
                                    setFieldsInError(_reject(fieldsInError, { key: 'Seal Requirements' }))
                                }
                            }, 500)
                        }}
                            onBlur={(e) => {
                                setNoteDesc(e.target.value)
                            }}
                            helperText={
                                _some(fieldsInError, { key: 'Seal Requirements' }) ?
                                    _find(fieldsInError, { key: 'Seal Requirements' }).helperTextError
                                    : remainigChars >= 0 ? remainigChars + "/100" : ""
                            }
                            error={_some(fieldsInError, { key: 'Seal Requirements' })}
                        ></FormModalTextareaLarge>
                    </FieldWrapper>
                </Section>
            </SectionWrapper>

        </DialogContent>

        <DialogActions>
            <Button onClick={(e: React.SyntheticEvent<any>) => {
                e.stopPropagation()
                onClose()
            }}>
                Cancel
            </Button>

            <Button color='primary' variant='contained' onClick={handleSave} disabled={isSaving || fieldsInError.length > 0 ? true : false || !canEditNote}>
                {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
                {!isSaving && 'SAVE '}
            </Button>

        </DialogActions>
    </Dialog>

}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`


const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width:400px;
  
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`


export default SealRequirementDialog