import axios from 'axios'
import { getCookieIdToken, setCookieIdToken, setCookieAccessToken } from '../cookies'
import { apiRefreshToken } from '@src/apis/token'
import moment from 'moment'
import _merge from 'lodash/merge'

const MAX_RETRY_NUMBER = 3
let retryCount = 0

export const ax = (options: any) => {
  let finalOptions = Object.assign({}, options, {
    headers: {
      ...options.headers,
      'Authorization': getCookieIdToken(),
    },
    params: {
      ...options.params,
      reqDev: 'web', // add to every request from portal
    }
  })
  if(finalOptions.url.indexOf('s3')!= -1) {
   delete  finalOptions.headers.Authorization;
   delete  finalOptions.params.reqDev;
  }
  return new Promise<any>((resolve, reject) => {
    //console.log(`\naxios ${moment().format()}`);
    axios(finalOptions)
      .then(function (response) {
        //console.log(`axios response=`)
        //console.log(response)
        if (response) {
          // handle success
          if (response.status === 200 || response.status === 201) {
            resolve(response.data)
          } else if (response.status === 204) {
            resolve({})
          } if (response.status >= 200 && response.status <= 300) {
            resolve({})
          } else {
            console.log(`responsed with status not 200-300`)
            reject()
          }
        } else {
          console.log(`axios response IS undefined 3`)
          reject()
        }
        // reset retry count
        retryCount = 0
        // console.log(response)
      })

      .catch(function (error) {
        console.log(`axios error=`)
        console.log(error)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          reject(error)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error)
          console.log(error.request)

          // if it's token expiry NOT CURRENTLY WORKING no error response sent from server
          if (error.config && error.config.headers['Authorization'] === getCookieIdToken()) {
            apiRefreshToken().then(refreshResponse => {
              if (
                refreshResponse.data
                && refreshResponse.data.AuthenticationResult
                && refreshResponse.data.AuthenticationResult.IdToken
              ) {
                const { AuthenticationResult } = refreshResponse.data
                setCookieIdToken(AuthenticationResult.IdToken)
                setCookieAccessToken(AuthenticationResult.AccessToken)
                if (retryCount < MAX_RETRY_NUMBER) {
                  // do the query again
                  retryCount++
                  console.log(`axios 2 retryCount=${retryCount}  ${moment().format()}`);
                  resolve(ax(options))
                } else {
                  console.log(`axios 2 Retried ${MAX_RETRY_NUMBER} times, rejecting`, retryCount)
                  reject(error)
                }
              }
            }).catch(error => {
              reject(error)
            })
          } else {
            reject(error)
          }

        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          reject(error)
        }
        console.log(error.config)
        // reject(error)
      })

      .finally(function () {
        // always executed
      })

  })
}



const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    // Successful response; just return it as is
    return response;
  },
  (error) => {
    // Network error or HTTP error
    if (!error.response) {
      // Handle network errors (e.g., no internet connection)
      console.error('Network error:', error.message);
      // You can show an appropriate error message to the user
      return Promise.reject(error);
    }

    // Handle HTTP errors (4xx and 5xx status codes)
    if (error.response.status === 400) {
      console.error('Bad Request:', error.response.data);
      // Handle the 400 error here
      // You can show an error message to the user or take other actions
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
