import { useEffect, useState } from 'react'
import { apiRefBundle } from '@src/apis/ref-bundle'
import { Bundle } from '@src/types/common/Bundle'
import { useBundleStore, statusBundleStore } from '@src/BundleStore'

export function useBundle(): [ Bundle ] {
  const { bundle, setBundle, setInitializing } = useBundleStore()
  let active = false
  useEffect(() => {
    async function fetchBundle() {
      const result = await apiRefBundle()
      if (active) {
        // console.log('setting bundle', result.data)
        setBundle(result.data as Bundle)
      }
    }
    if (statusBundleStore === 'init' && !active) {
      setInitializing()
      active = true
      fetchBundle()
    }
    return () => {
      active = false
    }
  }, [])
  return [ bundle ]
}