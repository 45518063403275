import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import { SRRQualityAsphaltFields } from '@src/modules/SiteResoureRequirements/parts/6-Quality/parts/ASQuality'
import { SRRQualitySpraySealFields } from '@src/modules/SiteResoureRequirements/parts/6-Quality/parts/SSQuality'
import { SRRQAAsphalt, SRRQSSsphalt } from '@src/types/SRR'

interface Props {
  jobType: ProjectTypeId,
}

const CompPreviewQA = (props: Props) => {
  const {
    jobType
  } = props
  const [ srr ] = useSRRInDRS()
  if (jobType !== ProjectTypeId.Asphalt && jobType !== ProjectTypeId.SpraySeal) {
    return null
  }
  if (srr === null) {
    return null
  }
  const data = srr.qa[normalizeProjectTypeId(jobType) as ('asphalt' | 'spraySeal')]
  if (data === null) {
    return null
  }
  const fields = jobType === ProjectTypeId.Asphalt ? SRRQualityAsphaltFields : SRRQualitySpraySealFields
  return <Wrapper>
    <CompPreviewSection 
      name={`QA Requirements`}
    >
      <CompFlatFields<any>
        value={data}
        fields={fields}
      />
    </CompPreviewSection>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompPreviewQA