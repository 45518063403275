import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';
import { apiDeviceList } from '../../apis/devices-list';
import { getCookieAccessToken } from '@src/utils/cookies';
import { useHistory } from 'react-router-dom'
import { checkToken } from '../GenerateToken';

interface DeviceArr {
  deviceid: String,
  name: String
}

const Devices = () => {
  const [devicesArr, setDevicesArr] = useState<DeviceArr[]>([])
  const history = useHistory();
  const rows = [
    {name: "Solar Test", deviceid: "WB39S34F1"},
    {name: "ORB Test",  deviceid: "WB39S34F1"},
    {name: "Cammy Test", deviceid: "WB39S34F1"},
  ];

  const fetchDevices = async () => {
    const status: any = await checkToken();
    if(status) {
      // const result: any = await apiDeviceList(getCookieAccessToken())
      const result: any = await apiDeviceList(localStorage.getItem('apiToken'));
      if(!result){fetchDevices()}
      if(result && result.data.length > 0) {
        setDevicesArr(result.data);
      }
    } else {
      history.push({
        pathname: '/dashboard/projects',
      })
    }
  }

  useEffect(() => {
    fetchDevices()
  }, [])
  const infoRedirect = (id: any) => {
    history.push(`/device/info/${id}`);
  }
  
  return <Container maxWidth='xl' style={{padding: '0', marginBottom: '15px'}}>
    <Box>
        <h1>Device Summary</h1>
        <FormControl fullWidth>
          <Select disableUnderline={true} defaultValue={0} inputProps={{ name: 'device-type', id: 'device-type' }} style={selectStyle} MenuProps={MenuProps}>
            <MenuItemProps value={0} >Show All</MenuItemProps>
            <MenuItemProps value={1} >Tram Trial</MenuItemProps>
            <MenuItemProps value={2} >Gearges Test Device</MenuItemProps>
            <MenuItemProps value={3} >Fri Trial</MenuItemProps>
          </Select>
        </FormControl>
        <TableContainer style={{marginBottom: '15px'}}>
          <Table aria-labelledby="devicelist" style={{border: '2px solid'}}>
            <TableHead>
              <TableRow>
                <TableCellCus padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={false}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCellCus>
                <TableCellCus align="center">Name</TableCellCus>
                <TableCellCus align="center">Events</TableCellCus>
                <TableCellCus align="center">Device ID</TableCellCus>
                <TableCellCus align="center">Last Contact</TableCellCus>
              </TableRow>
            </TableHead> 
            <TableBody>
              {devicesArr.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => {infoRedirect(row.deviceid)}}>
                      <TableCellCus padding="checkbox">
                        <Checkbox
                          color="primary"
                          inputProps={{'aria-labelledby': index.toString()}}
                        />
                      </TableCellCus>
                      <TableCellCus align="center">{row.name}</TableCellCus>
                      <TableCellCus align="center"></TableCellCus>
                      <TableCellCus align="center">{row.deviceid}</TableCellCus>
                      <TableCellCus align="center"></TableCellCus>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <ActionButton variant="outlined" startIcon={<AddIcon />}>Add Device</ActionButton>
        <ActionButton variant="outlined" startIcon={<MenuIcon />} style={{marginLeft: "10px"}}>Group</ActionButton>
    </Box>
  </Container>

}
const ActionButton = styled(Button)({
  background: "#00999b",
  color: "#ffffff",
  '&:hover' : {
    background: "#00999b !important",
    color: "#ffffff !important"
  }
});
const selectStyle = {
  'border': '1px solid rgba(0, 0, 0, 0.42)',
  'paddingLeft': '15px',
  'background': '#d3d3d347',
  'borderRadius': '3px',
};
const MenuProps = {
  PaperProps: {
    style: {
      width: '100%',
    },
  },
};
const MenuItemProps = styled(MenuItem) ({
  '&:hover' : {
    background: '#00999b !important',
    color: '#fff !important'
  }
});
const TableCellCus = styled(TableCell)({
  border: '2px solid #f7f8f9'
})
export default Devices