import { adjustDateTimeToDate } from './adjustDateTimeToDate'
import { DRSSubcontractorGeneralAssetRow } from '../parts/GeneralAssetsFields'
import { DRSSubcontractorProfilingAssetRow } from '../parts/ProfilingAssetsFields'
import { DRSSubcontractorTransportDeliveryRow } from '../parts/TransportDeliveryFields'
import { DRSSubcontractorTransportProfilingRow } from '../parts/TransportProfilingFields'
import { adjustEndTimeToStartTime } from './adjustEndTimeToStartTime'

type Form = DRSSubcontractorGeneralAssetRow | DRSSubcontractorProfilingAssetRow | DRSSubcontractorTransportDeliveryRow | DRSSubcontractorTransportProfilingRow

export function transformFromFormData(form: Form, drsDate: string) {
  let finalSupplierAgreement: any = null
  if (form.supplierAgreement && form.activity) {
    finalSupplierAgreement = {
      id: form.supplierAgreement.id,
      itemNumber: form.activity.id,
    }
  }
  let finalStartTime = adjustDateTimeToDate(form.startTime, drsDate)
  // @ts-ignore
  // endTime exists in Transports
  let finalEndTime = adjustEndTimeToStartTime(finalStartTime, form.endTime, drsDate)
  return {
    ...form,
    supplierAgreement: finalSupplierAgreement,
    startTime: finalStartTime,
    endTime: finalEndTime,
  }
}