import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import SatelliteIcon from '@material-ui/icons/Satellite';
import MapIcon from '@material-ui/icons/Map';
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab'
import { HEREMAP_KEY } from '@src/constants'

interface HeremapSuggest {
  id: string,
  title: string,
  address: {
    city: "Sydney"
    countryCode: "AUS"
    countryName: "Australia"
    district: "North Ryde"
    label: "North Ryde, Sydney, NSW 2113, Australia"
    postalCode: "2113"
    state: "New South Wales"
  },
  distance: number,
  localityType: string,
  mapView: {
    west: number,
    south: number,
    east: number,
    north: number,
  }
  position: {
    lat: number,
    lng: number,
  },
  resultType: string,
}
const baseUrl = 'https://autosuggest.search.hereapi.com/v1'
const API_KEY = HEREMAP_KEY

interface Props {
  mapType: string,
  zoom: number,
  center: any,
  onToggle(): void,
  onZoom(newZoom: number): void,
  onCenterChange(newCenter: any): void,
  onCaptured(dataURLString: string): void,
}
const HereMapSnapShot = (props: Props) => {
  const { mapType, zoom, center, onZoom, onCenterChange, onToggle, onCaptured } = props
  const MAP_CONTAINER_ID = 'mapContainer'
  const [map, setMap] = useState<any>()
  const [q, setQ] = useState<string>('')
  const [suggs, setSuggs] = useState<HeremapSuggest[]>([])
  const [behavior, setBehavior] = useState(null)
  const addMarkersToMap = (map: any) => {
    // @ts-ignore
    let H = window.H
    var AustraliaMarker = new H.map.Marker({ lat: -26.43909, lng: 133.28132 });
    map.addObject(AustraliaMarker);
  }

  const handleItemClick = (sugg: HeremapSuggest) => {
    if (sugg.position) {
      map.setCenter({ lng: sugg.position.lng, lat: sugg.position.lat })
    }
    // @ts-ignore
    else if (sugg.href) {
      // @ts-ignore
      fetch(sugg.href + '&apiKey=' + API_KEY, {
        method: 'GET'
      }).then(res => res.json()).then(data => {
        //console.log(data)
      })
    }
    //setSugg(sugg)
  }

  const getSuggs = async (key: string) => {
    if (!key) {
      setSuggs([])
      return
    }

    let query = `?in=bbox:111.7894,-45.09006,156.0315,-11.1392&limit=5&q=${key}&apiKey=${API_KEY}`

    let endpoint = '/autosuggest'
    fetch(baseUrl + endpoint + query).then(res => res.json()).then(data => {
      if (data.items) {
        setSuggs(data.items)
      }
    })
  }

  const handleInputChange = async (e: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    setQ(value)
    getSuggs(value)
  }

  useEffect(() => {
    // @ts-ignore
    const H = window.H
    // Initialize the platform object:
    var platform = new H.service.Platform({
      'apikey': API_KEY
    })
    // Obtain the default map types from the platform object
    var maptypes: any = platform.createDefaultLayers()
    // Instantiate (and display) a map object:
    let m: any
    if (mapType === 'map') {
      m = new H.Map(
        document.getElementById(MAP_CONTAINER_ID),
        maptypes.vector.normal.map,
        { zoom, center }
      )
    } else {
      m = new H.Map(
        document.getElementById(MAP_CONTAINER_ID),
        maptypes.raster.satellite.map,
        { zoom, center }
      )
    }
    m.addEventListener('mapviewchangeend', function () {
      onZoom(Math.round(m.getZoom()))
      onCenterChange(m.getCenter())
    })

    setMap(m)

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => m.getViewPort().resize());
    // Enable the event system on the map instance:
    var mapEvents = new H.mapevents.MapEvents(m);
    // Instantiate the default behavior, providing the mapEvents object:
    let behavior = new H.mapevents.Behavior(mapEvents);
    //console.log(`behavior=`);
    //console.log(behavior);
    setBehavior(behavior)
    // Create the default UI components
    var ui = H.ui.UI.createDefault(m, maptypes);
    addMarkersToMap(m)
    return () => {
      m.dispose();
    };
  }, [mapType])

  const handleCapture = () => {
    map.capture(function (canvas: any) {
      props.onCaptured((canvas as HTMLCanvasElement).toDataURL())
    }, [], 0, 0, 710, 400);
  }

  return (
    <Wrapper>
      <MapContainer id={MAP_CONTAINER_ID}></MapContainer>
      <SearcherWrapper>
        <ComboBox
          options={suggs}
          onInputChange={handleInputChange}
          onChange={(e: object, value: HeremapSuggest) => {
            handleItemClick(value)
          }}
        />
      </SearcherWrapper>
      <MapSatelliteButton onClick={onToggle}>
        {mapType === 'map' ? <SatelliteIcon /> : <MapIcon />}
      </MapSatelliteButton>
      <CaptureIcon onClick={handleCapture}>
        <CameraAltIcon />
      </CaptureIcon>
    </Wrapper>
  )
}

function ComboBox(props: {
  options: any[],
  onInputChange(e: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason): void,
  onChange(e: object, value: HeremapSuggest): void,
}) {
  return (
    <Autocomplete
      id='heremap-suggest-autocomplete'
      options={props.options}
      getOptionLabel={(option) => option.title}
      style={{ width: 500 }}
      onInputChange={props.onInputChange}
      onChange={props.onChange}
      renderInput={(params) => <TextField
        {...params}
        label='Search for street, suburb or location'
        variant='outlined'
      />}
    />
  );
}

const Wrapper = styled.div`
  padding: '1rem';
  position: relative;
`
const MapContainer = styled.div`
 width: 710px;
 height: 400px;
 margin: 0 auto;
`
const SearcherWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
`
const CaptureIcon = styled(IconButton)`
  position: absolute;
  bottom: 170px;
  right: 20px;
  background: #fff;
  color: #0A9688;
`
const MapSatelliteButton = styled(IconButton)`
  position: absolute;
  bottom: 90px;
  right: 20px;
  background: #fff;
  color: #0A9688;
`

export default HereMapSnapShot