export enum SifStatus {
  Assigned = 1,
  InProgress = 2,
  Submitted = 3,
  Cancelled = 4,
}

export function formatSifStatus(status: SifStatus) {
  switch (status) {
    case SifStatus.Assigned:
      return 'Assigned'
    case SifStatus.InProgress:
      return 'In Progress'
    case SifStatus.Submitted:
      return 'Submitted'
    case SifStatus.Cancelled:
      return 'Cancelled'
  }
}

export enum InspectionStatus {
  Assigned = 1,
  Active = 2,
  PracticallyCompleted = 3,
  FinanciallyCompleted = 4,
}

export function formatInspectionStatus(status: InspectionStatus) {
  switch (status) {
    case InspectionStatus.Active:
      return 'Active'
    case InspectionStatus.Assigned:
      return 'Inspection Required'
    case InspectionStatus.PracticallyCompleted:
      return 'Practically Complete'
    case InspectionStatus.FinanciallyCompleted:
      return 'Financially Complete'
  }
}
