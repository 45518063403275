import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'qs'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import SendIcon from '@material-ui/icons/Send'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiDRSUpdateStatus } from '@src/apis/drs-update-status'
import { useDRS } from '../../useDRS'
import { DRSStatus } from '@src/types/drs/DRSStatus'
import { useAppStore } from '@src/AppStore'
import { RoleId } from '@src/types/common/RoleId'
import { DRSPageType } from '../../DRSPage'
import CompDRSSummaryInfo from './parts/SummaryInfo'
import CompDRSDelayReasons from './parts/DelayReasons'
import CompDRSAttachments from './parts/attachments/Attachments'
import CompCarouselDialog from './parts/carousel/CarouselDialog'
import CompPdfDialog from './PdfDialog'
import { apiDrsPdf } from '@src/apis/drs-pdf'
import moment from 'moment'
import PDFIcon from '@material-ui/icons/PictureAsPdf'
import { getDRSPdfName } from '../../utils/getDRSPdfName'
import { Attachment } from '@src/types/Attachment'
import { Image } from '@src/types/Image'
import { BASE_API_URL } from '@src/constants'
import { useAttachmentsInDRS } from './utils/useAttachmentsInDRS'
import { compareWorkedLostTime, checkLostTime } from '@src/utils/busrules/LabourRules'

interface Props { }

const PartDRSSummary = (props: Props) => {
  const {

  } = props
  const { confirm } = useConfirm()
  const { alert } = useSnackbar()
  const anchorRef = useRef(null)
  const { drs, drsPageType, refresh, drsId } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const [open, setOpen] = useState<boolean>(false) // more action dropdown
  const [pdfDialogOpen, setPdfDialogOpen] = useState<boolean>(false)
  const [isCarouselDialogOpen, setCarouselDialogOpen] = useState<boolean>(false)
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [clickedImage, setClickedImage] = useState<string | null>(null)
  const { serialId } = useAppStore()
  const AcceptingImageFileMimeTypes: string[] = ['image/jpeg', 'image/png']
  const { attachments } = useAttachmentsInDRS()
  function groupData(attachments: Attachment[]) {
    let plannedAttachments: Attachment[] = [] // plan
    let actualAttachments: Attachment[] = [] // actual + edits
    attachments.forEach((attachment: Attachment) => {
      if (attachment.roleId === RoleId.ResourceScheduler) {
        plannedAttachments.push(attachment)
      } else {
        actualAttachments.push(attachment)
      }
    })
    return {
      plannedAttachments,
      actualAttachments,
    }
  }
  const { plannedAttachments, actualAttachments } = groupData(attachments)
  let plannedImageAttachments: Attachment[]
  let actualImageAttachments: Attachment[]
  { attachments ? plannedImageAttachments = plannedAttachments.filter((attachment: Attachment) => AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) : plannedImageAttachments = [] }
  { attachments ? actualImageAttachments = actualAttachments.filter((attachment: Attachment) => AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) : actualImageAttachments = [] }
  const plannedImages: Image[] = plannedImageAttachments.map((imageAttachment: Attachment, index: number) => {
    return {
      id: parseInt(`${imageAttachment.id}`),
      originalName: `${imageAttachment.originalName}`,
      'name': `${imageAttachment.name}`,
      location: `${imageAttachment.location}`,
      serialId: parseInt(`${imageAttachment.serialId}`),
      uri: `${BASE_API_URL}/v2/documents/document/${imageAttachment.name}?${qs.stringify({
        reqSid: serialId,
      })}`,
    }
  })
  const actualImages: Image[] = actualImageAttachments.map((imageAttachment: Attachment, index: number) => {
    return {
      id: parseInt(`${imageAttachment.id}`),
      originalName: `${imageAttachment.originalName}`,
      'name': `${imageAttachment.name}`,
      location: `${imageAttachment.location}`,
      serialId: parseInt(`${imageAttachment.serialId}`),
      uri: `${BASE_API_URL}/v2/documents/document/${imageAttachment.name}?${qs.stringify({
        reqSid: serialId,
      })}`,
    }
  })

  let images: Image[] = plannedImages;
  if (!isPlan) {
    images = plannedImages.concat(actualImages)
  }

  useEffect(() => {
    if (clickedImage) {
      const selectedImageIndex = images.map(function (item) { return item.name; }).indexOf(clickedImage);
      setImageIndex(selectedImageIndex)
      setCarouselDialogOpen(true)
    }
  }, [clickedImage])

  if (drs === null) {
    return null
  }
  const status = drs.jobDetails.status.id
  const submitDisabled = !(status === DRSStatus.DRAFT)
  const cancelDisabled = !(
    status === DRSStatus.DRAFT
    || status === DRSStatus.SUBMITTED_FORMAN
    || status === DRSStatus.INPROGRESS
  )
  const isDisabledForApproval = (
    status === DRSStatus.APPROVED_PM
    || status === DRSStatus.COMPLETED
  )

  const handleSubmit = async () => {
    try {
      await confirm({
        title: 'Confirm Submission of DRS',
        message: 'Foreman will receive this DRS if you submit the DRS',
      })
      submit()
    } catch (e) {

    }
  }
  const submit = async () => {
    if (!drsId || !isPlan) {
      return
    }
    try {
      const result = await apiDRSUpdateStatus({
        drsId: drsId,
        statusId: DRSStatus.SUBMITTED_FORMAN,
        roleId: RoleId.ResourceScheduler,
      })
      if (result.data) {
        alert({
          message: 'DRS has been submitted to Foreman.',
        })
        refresh()
      }
    } catch (e) { }
  }
  const handleCancelDRS = async () => {
    try {
      await confirm({
        title: 'Confirm Cancellation of DRS',
        message: 'You can’t make any edits to this DRS if you cancel it',
      })
      cancel()
    } catch (e) { }
  }
  const cancel = async () => {
    if (!drsId || !isPlan) {
      return
    }
    try {
      const result = await apiDRSUpdateStatus({
        drsId,
        statusId: DRSStatus.CANCELLED,
        roleId: RoleId.ResourceScheduler,
      })
      if (result.data) {
        alert({
          message: 'DRS has been cancelled.',
        })
        setOpen(false)
        refresh()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleToggle = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handlePdfDialogClose = () => {
    setPdfDialogOpen(false)
  }
  const onPdfClick = () => {
    setPdfDialogOpen(true)
  }
  const handleImageAttachmentClick = async (attachment: Attachment, listingIndex: number) => {
    try {
      const { name } = attachment
      //console.log(`Summary handleImageAttachmentClick() listingIndex=${listingIndex}`)
      setClickedImage(name)
    } catch (e) { }
  }
  const handleApprove = async () => {
    if (!drsId || !isEdit) {
      return
    }
    //console.log(`PartDRSSummary handleApprove() drs=${JSON.stringify(drs, null, 2)}`)

    const busRule: any = checkLostTime(drs)
    console.log(`PartDRSSummary handleApprove() busRule=${JSON.stringify(busRule, null, 2)}`)

    if (busRule.result) {
      alert({
        type: 'error',
        message: busRule.message,
      })
      return
    }

    const busRule2: any = compareWorkedLostTime(drs)
    //console.log(`PartDRSSummary handleApprove() busRule=${JSON.stringify(busRule, null, 2)}`)

    if (busRule2.result) {
      alert({
        type: 'error',
        message: busRule2.message,
      })
      return
    }
    try {
      await confirm({
        title: 'Confirm Approval of DRS',
        message: 'You will not be able to make any edits to this DRS once approved',
      })
      approve()
    } catch (e) {
      console.log(e)
    }
  }
  const approve = async () => {
    try {
      const result = await apiDRSUpdateStatus({
        drsId,
        statusId: DRSStatus.APPROVED_PM,
        roleId: RoleId.ProjectManager,
      })
      if (result.data) {
        alert({
          message: 'DRS has been approved.',
        })
        refresh()
      }
    } catch (e) {
      console.log(e)
    }
  }
  return <Wrapper>
    <TitleWrapper>
      <SFormSectionTitle>DRS Summary</SFormSectionTitle>
      {(isPlan || isEdit) &&
        <React.Fragment>
          <PDFButton
            variant='contained'
            onClick={onPdfClick}
          >
            Create PDF
          </PDFButton>
          <CompPdfDialog
            open={pdfDialogOpen}
            onClose={handlePdfDialogClose}
          />
        </React.Fragment>
      }
      <ActionWrapper>
        {isPlan &&
          <React.Fragment>
            <ButtonGroup variant='contained'
              color='primary'
              ref={anchorRef}
              aria-label='split button'
            >
              <Button
                disabled={submitDisabled}
                // disabled={false}
                endIcon={<SendIcon />}
                onClick={handleSubmit}>
                Submit DRS
              </Button>
              <Button
                color='primary'
                size='small'
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper id='menu-list-grow'>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        <MenuItem
                          disabled={cancelDisabled}
                          onClick={handleCancelDRS}
                        >
                          Cancel DRS
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        }
      </ActionWrapper>
      {isEdit &&
        <React.Fragment>
          <SubmitButton
            size='small'
            color='primary'
            variant='contained'
            disabled={isDisabledForApproval}
            endIcon={<SendIcon />}
            onClick={handleApprove}>
            Approve DRS
          </SubmitButton>
        </React.Fragment>
      }
    </TitleWrapper>
    <CompDRSDelayReasons />
    <CompDRSSummaryInfo />
    <CompDRSAttachments extImageHandling={true} handleImageAttachmentClick={handleImageAttachmentClick} />
    {isCarouselDialogOpen &&
      <CompCarouselDialog
        open={isCarouselDialogOpen}
        images={images}
        imageIndex={imageIndex}
        onClose={() => {
          setCarouselDialogOpen(false)
        }}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

const TitleWrapper = styled.div`
  display: flex;
`
const SFormSectionTitle = styled(FormSectionTitle)`
  flex-grow: 1;
`
const ActionWrapper = styled.div`
`
const PDFButton = styled(Button)`
  margin-right: 10px;
  color: #009688;
  background: #F5F5F5;
  height: 36px;
`
const SubmitButton = styled(Button)`
  margin-right: 8px;
  height: 36px;
`

export default PartDRSSummary