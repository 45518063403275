import React from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'

interface Options {
  tooltipText?: string,
  onClick(): void,
}

function FloatActionButton ({
  tooltipText = '',
  onClick,
}: Options) {
  return <Tooltip title={tooltipText} aria-label={tooltipText}>
  <SFab aria-label={tooltipText} onClick={onClick} size='small'>
    <AddIcon />
  </SFab>
</Tooltip>
}

const SFab = styled(Fab)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 3;
`

export default FloatActionButton