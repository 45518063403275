import { useEffect, useState } from 'react'
import { useAppStore } from '@src/AppStore'
import { useParams } from 'react-router'
import { DRS, DRSSection } from '@src/types/drs/DRS'
import { useDRSStore } from './DRSStore'
import { apiDRSGet } from '@src/apis/drs-get'
import { RoleId } from '@src/types/common/RoleId'
import { DRSSectionName, DRSPageType, mapDRSPageTypeToRoleId } from './DRSPage'
import { apiDRSUpdateSection } from '@src/apis/drs-update-section'
import _set from 'lodash/set'
import { Attachment } from '@src/types/Attachment'

interface ReturnType {
  drs: DRS | null,
  drsId: number,
  drsPageType: DRSPageType,
  loading: boolean,
  resetDRS(): void,
  fetchDRS(id: string, type: DRSPageType): void,
  refresh(): void,
  updateViaPath(path: string, data: any): void,
  updateSectionViaPath(options: { sectionName: DRSSectionName, path: string, data: any }): void,
  attachments: Attachment[],
  setAttachments(attachments: Attachment[]): void,
}

export function useDRS(): ReturnType {
  const { drs, setDRS, setId, id: drsId, drsPageType, setDRSPageType, attachments, setAttachments } = useDRSStore()
  const [loading, setLoading] = useState<boolean>(false)
  let active = false
  const resetdrs = () => {
    setDRS(null)
  }
  const fetch = async (id: string, type: DRSPageType) => {
    try {
      if (!active && DRSPageType[type]) {
        active = true
        setLoading(true)
        const result = await apiDRSGet({
          drsId: id,
          roleId: mapDRSPageTypeToRoleId(type),
        })
        // console.log(result)
        setDRS(result.data.drs)
        setId(Number(id))
        setDRSPageType(type)
        setLoading(false)
        active = false
      }
    } catch (e) {
      setLoading(false)
      active = false
    }
  }
  const refresh = async () => {
    return fetch(drsId + '', drsPageType)
  }
  const updateViaPath = async (path: string, data: any) => {

  }
  const updateSectionViaPath = async (
    { sectionName, path, data }:
      { sectionName: DRSSectionName, path: string, data: any }
  ) => {
    if (drs === null) {
      return
    }
    try {
      const sectionData = drs[transformSectionNameToDataSectionName(sectionName)] as DRSSection
      let finalData = Object.assign({}, sectionData)
      _set(finalData, path, data)
      console.log(path, data)
      const result = await apiDRSUpdateSection({
        sectionName: transformSectionNameToApiSectionName(sectionName),
        drsId: drs.jobDetails.id + '',
        roleId: mapDRSPageTypeToRoleId(drsPageType),
        data: finalData,
      })
    } catch (e) {

    }
  }
  return {
    drs,
    drsId: drsId!,
    drsPageType,
    loading,
    resetDRS: resetdrs,
    fetchDRS: fetch,
    updateViaPath,
    updateSectionViaPath,
    refresh,
    attachments,
    setAttachments,
  }
}

export function transformSectionNameToDataSectionName(sectionName: DRSSectionName): keyof DRS {
  switch (sectionName) {
    case DRSSectionName.Job: return 'jobDetails'
    case DRSSectionName.Rework: return 'rework'
    case DRSSectionName.Material: return 'material'
    case DRSSectionName.Transport: return 'transport'
    case DRSSectionName.MobileAssets: return 'mobileAssets'
    case DRSSectionName.Subcontractors: return 'subcontractor'
    case DRSSectionName.Labour: return 'labour'
    case DRSSectionName.Quality: return 'qa'
    case DRSSectionName.Summary: return 'summary'
  }
}
function transformSectionNameToApiSectionName(sectionName: DRSSectionName): string {
  switch (sectionName) {
    case DRSSectionName.Job: return 'jobDetails'
    case DRSSectionName.Rework: return 'rework'
    case DRSSectionName.Material: return 'materials'
    case DRSSectionName.Transport: return 'transport'
    case DRSSectionName.MobileAssets: return 'mobileAssets'
    case DRSSectionName.Subcontractors: return 'any'
    case DRSSectionName.Labour: return 'labour'
    case DRSSectionName.Quality: return 'qaRequirements'
    case DRSSectionName.Summary: return 'summary'
  }
}
