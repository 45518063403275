import React, { useState } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { useAttachmentsInDRS } from '../../utils/useAttachmentsInDRS'
import { Attachment } from '@src/types/Attachment'
import DRSUploadAttachmentTrigger from './UploadTrigger'
import { useDRS } from '@src/modules/DRS/useDRS'
import { PackFormType } from '@src/types/PackFormType'
import { apiUploadFile } from '@src/apis/files/upload-file'
import { compressImageFile } from '@src/apis/files/compress-image-file'
import { mapDRSPageTypeToRoleId, DRSPageType } from '@src/modules/DRS/DRSPage'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { PAETableRowType } from '@src/components/Form/PAETable'
import { RoleId } from '@src/types/common/RoleId'
import AttachmentField from '@src/components/Form/AttachmentField'
import RowFlex from '@src/components/FormRow/RowFlex'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiRemoveFile } from '@src/apis/files/remove-file'


interface Props {
  extImageHandling?: boolean,
  handleImageAttachmentClick?(attachment: Attachment, listingIndex: number): void,
}

const CompDRSAttachments = (props: Props) => {
  const {
    extImageHandling,
    handleImageAttachmentClick,
  } = props
  const [isLoading, setIsLoading] = useState<any>(false)
  const { attachments, refresh } = useAttachmentsInDRS()
  const { drs, drsId, drsPageType } = useDRS()
  const { serialId } = useAppStore()
  const { alertDone, alertError } = useSnackbar()
  const { confirm } = useConfirm()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const roleId = getRole()
  function getRole() {
    if (isPlan) {
      return RoleId.ResourceScheduler
    } else if (isEdit) {
      return RoleId.ProjectManager
    } else {
      return RoleId.Administrator
    }
  }
  const handleFileSelect = async (files: FileList, imageFiles: FileList, nonImageFiles: FileList) => {
    if (!drs) {
      return
    }
    try {
      setIsLoading(true)

      Promise.all(
        Array.from(imageFiles).map((imageFile) => {
          let maxWidth: number = 1000
          let maxHeight: number = 1000
          let maxFileSize: number = 250000

          return compressImageFile({ imageFile, maxWidth, maxHeight, maxFileSize })
        })
      )
        .then((compressedImageFiles) => {
          return Promise.all(
            Array.from(compressedImageFiles)
              .map((file: File) => {
                return apiUploadFile({
                  formType: PackFormType.DAILY_REPORTY_SHEET,
                  formId: drsId,
                  roleId: mapDRSPageTypeToRoleId(drsPageType),
                  serialId,
                  file: file,
                  projectInternalId: drs.jobDetails.project.projectInternalId,
                })
              })
          )
        })
        .then(() => {
          return Promise.all(
            Array.from(nonImageFiles)
              .map((file: File) => {
                return apiUploadFile({
                  formType: PackFormType.DAILY_REPORTY_SHEET,
                  formId: drsId,
                  roleId: mapDRSPageTypeToRoleId(drsPageType),
                  serialId,
                  file: file,
                  projectInternalId: drs.jobDetails.project.projectInternalId,
                })
              })
          )
        })
        .then(() => {
          setIsLoading(false)
          alertDone()
          refresh()
        })

    } catch (e) {
      alertError()
    }
  }
  function groupData(attachments: Attachment[]) {
    let first: Attachment[] = [] // plan
    let second: Attachment[] = [] // actual + edits
    attachments.forEach((attachment: Attachment) => {
      if (attachment.roleId === RoleId.ResourceScheduler) {
        first.push(attachment)
      } else {
        second.push(attachment)
      }
    })
    return {
      first,
      second,
    }
  }
  const onRemove = async (attachment: Attachment) => {
    try {
      await confirm({
        title: 'Confirm',
        message: 'Sure to delete this file?',
      })
      remove(attachment)
    } catch (e) { }
  }
  const remove = async (attachment: Attachment) => {
    try {
      await apiRemoveFile({
        id: attachment.id,
        formType: PackFormType.DAILY_REPORTY_SHEET,
        formId: drsId,
        roleId,
        serialId,
      })
      alertDone()
      refresh()
    } catch (e) {
      alertError()
    }
  }
  const { first, second } = groupData(attachments)
  return <Wrapper>
    <FormPanel title={`Attachments`}>
      <RowWrapper>
        <RowFlex>
          <RowLabelPlanned>Planned</RowLabelPlanned>
          {isPlan && <TriggerWrapper>
            <DRSUploadAttachmentTrigger id='drs-attach-planned' onSelect={handleFileSelect} attachments={attachments} />
          </TriggerWrapper>}

          {isPlan && isLoading && <SpinnerWrapper><Spinner size={24} /></SpinnerWrapper>}
          {first.map((attachment: Attachment) => {
            return <AttachmentField
              key={attachment.id} attachment={attachment}
              removable={roleId === attachment.roleId}
              onRemove={onRemove}
              listingIndex={0}
              extImageHandling={extImageHandling}
              onImageAttachmentClick={handleImageAttachmentClick}
            />
          })}
        </RowFlex>
      </RowWrapper>

      {!isPlan &&
        <RowWrapper>
          <RowLabel>Actual</RowLabel>
          {isEdit && <TriggerWrapper>
            <DRSUploadAttachmentTrigger id='drs-attach-actual' onSelect={handleFileSelect} attachments={attachments} />
          </TriggerWrapper>}
          {!isPlan && isLoading && <SpinnerWrapper><Spinner size={24} /></SpinnerWrapper>}
          <RowFlex>
            {second.map((attachment: Attachment) => {
              return <AttachmentField
                key={attachment.id}
                attachment={attachment}
                removable={roleId === attachment.roleId}
                onRemove={onRemove}
                listingIndex={1}
                extImageHandling={extImageHandling}
                onImageAttachmentClick={handleImageAttachmentClick}
              />
            })}
          </RowFlex>
        </RowWrapper>
      }

    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
const RowWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #0A9688;
`
const RowLabelPlanned = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
  width: 100px;
  color: #9A9898;
`
const RowLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
  width: 100px;
  color: #129688;
`
const TriggerWrapper = styled.div`
  margin-right: 16px;
`

const Spinner = styled(CircularProgress)`
  position: relative;
`

const SpinnerWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`
CompDRSAttachments.defaultProps = {
  extImageHandling: false,
}

export default CompDRSAttachments