import React, { useContext } from 'react'
import { observable, action } from "mobx";
import moment from 'moment'


const sortSelecetdNotes = () => {
  store.selecetdNotes.replace(store.selecetdNotes.slice().sort(function (a: any, b: any) {
    var c = moment(a.date);
    var d = moment(b.date);
    return c.diff(d, 'days')
  }));
}



const store: any = observable({
  SelecetdNoteCount: 0,
  selecetdNotes: [],
  isDragHappening: false,
  
  sortSelecetdNotes: action(() => {
  }),
  setIsDragHappening: action((isDragHappening: boolean) => {
    store.isDragHappening = isDragHappening
  }),
  removeSelecetdNote: action((note: any) => {
    store.selecetdNotes.splice(store.selecetdNotes.findIndex((o: any) => o.DepotCalendarNotesID === note.DepotCalendarNotesID), 1)
    store.SelecetdNoteCount--
  }),
  addSelectedNote: action((note: any) => {
    let found = store.selecetdNotes.find((o: any) => o.DepotCalendarNotesID === note.DepotCalendarNotesID);
    if (!found) {
      store.selecetdNotes.push(note)
      sortSelecetdNotes()
      
      store.SelecetdNoteCount++
    }
  }),
  clearSelectedNotes: action(() => {
    store.selecetdNotes = []
    store.SelecetdNoteCount = 0
  
  }),
});

const context = React.createContext(store);

export const useSelectedNotes = () => {
  return useContext(context);
};