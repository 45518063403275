import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSReworkAsset } from '@src/types/drs/rework/Rework'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'

export enum DRSAssetDepotType {
  MyDepot = 'My Depot', 
  MyState = 'My State',
}
export interface DRSReworkAssetDisplay extends DRSReworkAsset {
  assetDepotType: {
    id: DRSAssetDepotType,
    description: string,
  },
  assetDescription: string,

}

const DRSReworkAssetsFields: FormTableField<DRSReworkAssetDisplay>[] = [
  {
    id: 'DRSAssetDepotType',
    key: 'assetDepotType',
    type: FormTableFieldType.Select,
    label: 'Asset Depot',
    format: (cellData: any) => {
      if (cellData) {
        return cellData.description
      }
      return ''
    }
  },
  {
    id: 'drs-rework-asset',
    key: 'mobileAsset',
    type: FormTableFieldType.Autocomplete,
    label: 'Asset Number',
    format: (item: any) => {
      return item ? item.id : ''
    },
    alsoUpdates: [ 'assetDescription' ],
    alsoUpdatesFunc: (item: any) => {
      return [ item.description ]
    }
  },
  {
    key: 'assetDescription',
    type: FormTableFieldType.Text,
    label: 'Asset Description',
    disabled: true,
  },
  {
    id: 'mobile-asset-hours',
    key: 'hours',
    type: FormTableFieldType.Select,
    label: 'Hours',
    format: (cellData: any) => {
      return cellData
    }
  }
 
]

export default DRSReworkAssetsFields