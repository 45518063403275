import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'


interface Props {
  type?: 'text' | 'number',
  label: string,
  id?: string,
  defaultValue: string,
  error?: boolean,
  helperText: string,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
  onBlur(e: any): void,
  disabled?: boolean,
}

const FormModalTextField = (props: Props) => {
  const {
    type = 'text',
    label,
    defaultValue,
    error,
    helperText,
    onChange,
    onBlur,
    disabled = false,
    id
  } = props
 
  return <Wrapper
    fullWidth
    type={type}
    label={label}
    id={id}
    defaultValue={defaultValue}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    error={error}
    helperText={helperText}
  >

  </Wrapper>
}

FormModalTextField.defaultProps = {
  error: false,
  helperText: "",
  onBlur: () => {}
}

const Wrapper = styled(Textfield)`

`

export default FormModalTextField