import React, { useEffect } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import DRSMaterialAsphaltOtherFields, { DRSMaterialAsphaltOtherRowDisplay } from './MaterialAsphaltOtherFields'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { DRSPageType, DRSSectionName } from '@src/modules/DRS/DRSPage'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'

interface DRSMaterialAsphaltOtherRow extends DRSMaterialAsphaltOtherRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  catEyeCovers: null,
  depot: null,
  dryIce: null,
  emulsion: null,
  geoFabric: null,
  grit: null,
  hateliteBitac: null,
  region: null,
  roadBase: null,
  state: null,
  sticksNStomps: null,
  tipFees: null,
}
const ZERO_VALUES = {
  catEyeCovers: '0',
  depot: null,
  dryIce: null,
  emulsion: '0',
  geoFabric: '0',
  grit: '0',
  hateliteBitac: '0',
  region: null,
  roadBase: '0',
  state: null,
  sticksNStomps: '0',
  tipFees: null,
}

interface Props { refreshTrigger?: number }
const DRSSectionMaterialAspahltOthers = (props: Props) => {
  const { refreshTrigger } = props
  const { drs, updateSectionViaPath, drsPageType, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSMaterialAsphaltOtherRow>({})
  const { depotId, stateId } = useAppStore()

  // refresh if showing in ShiftDialig to show first record when first record has been created in MaterialAsphaltItems
  useEffect(() => {
    if (refreshTrigger) {
      refresh()
    }
  }, [refreshTrigger])
  if (drs === null) {
    return null
  }
  const onSave = async (form: DRSMaterialAsphaltOtherRow) => {
    if (modalData === null) {
      addOther(form)
    } else {
      EditOther(form)
    }
  }
  const addOther = (form: DRSMaterialAsphaltOtherRow) => {
    updateItemByValues(form, form)
  }
  const EditOther = (form: DRSMaterialAsphaltOtherRow) => {
    updateItemByValues(form, form)
  }
  const handleRevert = (form: DRSMaterialAsphaltOtherRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleMarkZero = (form: DRSMaterialAsphaltOtherRow) => {
    updateItemByValues(form, ZERO_VALUES)
  }
  const updateItemByValues = (form: DRSMaterialAsphaltOtherRow, values: any) => {
    const retained = drs.material.asphalt.summary
    const data = {
      ...retained,
      [updatingColumn]: {
        ...values,
      },
    }
    update(data)
  }
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Material,
      path: `asphalt.summary`,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const getTableData = () => {
    const other = drs.material.asphalt.summary
    //console.log(`DRSSectionMaterialAspahltOthers getTableData() other=${JSON.stringify(other, null, 2)}`)
    const others = [...(other === null ? [] : [other])]
    if (!others || !others.length) {
      return []
    }
    const doubled = doubleUp(others, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`Material Asphalt Other`}>
      <FormPAETable<DRSMaterialAsphaltOtherRow>
        fields={DRSMaterialAsphaltOtherFields}
        data={getTableData()}
        editable={true}
        onEditRow={(row: DRSMaterialAsphaltOtherRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        isSelectable={true}
      />
      {(isPlan || isEdit) && !getTableData().length &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Other Asphalt Material
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSMaterialAsphaltOtherRow>
        maxWidth={'md'}
        open={open}
        title='Material'
        data={{
          rowData: modalData,
          fields: DRSMaterialAsphaltOtherFields,
          columns: [
            {
              title: 'Material Information',
              fieldKeys: [
                'catEyeCovers',
                'depot',
                'emulsion',
                'dryIce',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'geoFabric',
                'grit',
                'hateliteBitac',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'roadBase',
                'sticksNStomps',
                'tipFees',
              ],
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={onSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`
`

export default DRSSectionMaterialAspahltOthers