import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell, { SortDirection } from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { MobileAssetPrestart, formatMobileAssetPrestartIssue } from '@src/types/MobileAssetPrestart'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { apiMobileAssetPrestartGetPdf } from '@src/apis/mobile-asset-prestart-get-pdf'
import { useAppStore } from '@src/AppStore'
import { RoleId } from '@src/types/common/RoleId'
import { ShiftTypeStatic } from '@src/modules/DRS/legacy/Create/comps/f-ShiftType'
import { formatDateStringOrNullDDMMYYYYHHSS } from '@src/types/format/dateStringOrNullDDMMYYYYHHSS'
import { MobileAsset } from '@src/modules/MgmtMobileAsset/fields'
import { BASE_API_URL } from '@src/constants'
import qs from 'qs'
import CompCarouselDialog from '@src/modules/DRS/parts/9-Summary/parts/carousel/CarouselDialog'
import { Image } from '@src/types/Image'

import CompCarouselDialogSingle from '@src/modules/DRS/parts/9-Summary/parts/carousel/CarouselDialogSingle'
interface Props {
  mobileAssetPrestarts: MobileAssetPrestart[],
  assets: MobileAsset,
}

const CompMobileAssetPaperPrestartTable = (props: Props) => {
  const {
    mobileAssetPrestarts,
    assets
  } = props
  const { type } = assets
  const [isCarouselDialogOpen, setCarouselDialogOpen] = useState<boolean>(false)
  const { serialId } = useAppStore()
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [images, setImages] = useState<Image[]>([])
 /* const plannedImages: Image[] = mobileAssetPrestarts.map((prestart: any, index: number) => {
    return {
      id: index+1,
      originalName: `${prestart.cpFileName}`,
      'name': `${prestart.cpFileName}`,
      location: `${prestart.fileLocation}`,
      serialId: index+1,
      uri: `${BASE_API_URL}/v2/documents/document/${prestart.cpFileName}?${qs.stringify({
      reqSid: serialId,
      })}`,
    }
  }) */
  const renderThumbnail = (row: MobileAssetPrestart) => {
          const imguri = `${BASE_API_URL}/v2/documents/document/${row.cpFileName}?${qs.stringify({
            reqSid: serialId,
          })}`;
              
        return <React.Fragment>
          <img src={imguri} style={{width:'100px'}} />
        </React.Fragment>
    
    return 'None'
  }
  const handleClick = (row: MobileAssetPrestart) => async () => {
    try {
      setCarouselDialogOpen(true);
      var  imageIndex = mobileAssetPrestarts.findIndex(item => item === row);
      setImageIndex(imageIndex);
      var currentImage: Image[] = [];
      currentImage.push(
        {
          id: imageIndex+1,
          originalName: `${row.cpFileName}`,
          'name': `${row.cpFileName}`,
          location: `${row.fileLocation}`,
          serialId: imageIndex+1,
          uri: `${BASE_API_URL}/v2/documents/document/${row.cpFileName}?${qs.stringify({
          reqSid: serialId,
          })}`,
        }
      )
      setImages(currentImage);
      // console.log(`row`);
      // console.log(row);
      // console.log(`row`);
      // const result = await apiMobileAssetPrestartGetPdf({
      //   id: row.id,
      //   serialId,
      //   roleId: RoleId.ProjectManager,
      // })

      // const url = window.URL.createObjectURL(new Blob([result]))
      // const link = document.createElement('a')
      // link.href = url
      // link.setAttribute('download', `MAP-${row.assetId}.pdf`)
      // document.body.appendChild(link)
      // link.click()
    } catch (e) {
      console.log(e)
    }
  }
  return <Wrapper>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Shift Date</TableHeaderCell>
          <TableHeaderCell>Location</TableHeaderCell>
          <TableHeaderCell>Project</TableHeaderCell>
          <TableHeaderCell>Shift Type</TableHeaderCell>
         
          <TableHeaderCell align='center'>Foreman</TableHeaderCell>
          <TableHeaderCell>Thumbnail</TableHeaderCell>
          <TableHeaderCell align='center'>Machine KMs</TableHeaderCell>
          <TableHeaderCell align='center'>Machine Hours</TableHeaderCell>
          <TableHeaderCell align='center'>Status</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mobileAssetPrestarts.map((row: MobileAssetPrestart, rowIndex) => {
          if (row.shift === null) {
            return null
          }
          const { street, suburb, foremen } = row.shift
          
          const { projectId, projectName, projectInternalId } = row.project
          const { preWorkOperator, postWorkOperator,preWorkSubmissionDate } = row
          return <TableRow
            hover
            key={row.id}
           
          >
            <SCell>
              {preWorkSubmissionDate ? formatDateStringOrNullDDMMYYYYHHSS(preWorkSubmissionDate) : formatDateStringOrNullDDMMYYYY(row.shift.date)}
            </SCell>
            <SCell>
              {row.street ? `${row.street}, ` : (street === null ? '' : `${street}, `)}
              {row.suburb ? `${row.suburb}` :  (suburb === null ? '' : `${suburb}`)}
            </SCell>
            <SCell>
              {row.street && row.suburb ? 'Mobile Prestart Only' : `${projectId || projectInternalId} ${projectName}`}
            </SCell>
            <SCell>
             {row.street && row.suburb ? '' :  <ShiftTypeStatic
                shiftTypeId={row.shift.type.id}
              />
        }
            </SCell>
         
            <SCell>
              { row.street && row.suburb ? '' : 
                foremen.map((foreman) => {
                  return <div>{formatProfileOrNullFirstLastName(foreman)}</div>
                })
              }
            </SCell>
            <SCell onClick={handleClick(row)}>
              {renderThumbnail(row)}
            </SCell>
            <SCell align='center'>
              {type.unit == 2 ? "N/A" : row.assetKM ?? ""}
            </SCell>
            <SCell align='center'>
              {type.unit == 1 ? "N/A" : row.assetHours ?? ""}
            </SCell>
            <SCell>
              <Status>{row.status ? row.status.description : 'Paper'}</Status>
            </SCell>
          </TableRow>
        })}

      </TableBody>
    </Table>
    {isCarouselDialogOpen &&
          <CompCarouselDialogSingle
            open={isCarouselDialogOpen}
            images={images}
            imageIndex={imageIndex}
            
            onClose={() => {
              setCarouselDialogOpen(false)
            }}
          />
        }
  </Wrapper>
}

const Wrapper = styled.div`
  margin-bottom: 60px;
`
const TableHeaderCell = styled(TableCell)`
  color: #707070;
  font-weight: bold;
  font-size: 16px;
  height: 14px;
  line-height: 14px;
  background-color: #F5F5F5;
  border-bottom: none;
`
const SCell = styled(TableCell)`
  padding: 22px 16px;
  /* background-color: #F5F5F5; */
  cursor: pointer;
  /* margin-bottom: 24px; */
`
const Issue = styled.div`
  color: #D32929;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  &:not(:first-child) {
    margin-top: 14px;
  }
`
const Operator = styled.div`
  line-height: 16px;
  margin-right: 10px;
`
const PreShiftIndicator = styled.div`
  color: #fff;
  background-color: #366B98;
  border-radius: 16px;
  width: 64px;
  font-size: 10px;
  text-align: center;
  height: 16px;
  line-height: 16px;
`
const PostShiftIndicator = styled.div`
  color: #fff;
  background-color: #129688;
  border-radius: 16px;
  width: 64px;
  font-size: 10px;
  text-align: center;
  height: 16px;
  line-height: 16px;
`

const Status = styled.div`
  color: #fff;
  background-color: #129688;
  border-radius: 16px;
  width: 64px;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
`

export default CompMobileAssetPaperPrestartTable
