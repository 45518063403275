import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import { SupplierAgreementActivity } from '@src/types/SupplierAgreement'
import { PAETableRowType } from '@src/components/Form/PAETable'
import { SubcontractorProfilingAsset } from '@src/types/drs/subcontractor/SubcontractorProfiling'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'

interface DRSSubcontractorProfilingAssetDisplay extends SubcontractorProfilingAsset {
  activity: SupplierAgreementActivity,
}

export interface DRSSubcontractorProfilingAssetRow extends DRSSubcontractorProfilingAssetDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const DRSSubcontractorProfilingAssetsFields: FormTableField<DRSSubcontractorProfilingAssetDisplay>[] = [
  {
    id: 'supplier',
    key: 'supplier',
    type: FormTableFieldType.Autocomplete,
    label: 'Supplier',
    format: (item: any) => {
      if (item) {
        return item.name
      }
    },
  },
  {
    id: 'supplier-agreement',
    key: 'supplierAgreement',
    type: FormTableFieldType.Select,
    label: 'Supplier Agreement',
    format: (item: any, row: SubcontractorProfilingAsset) => {
      if (item) {
        return item.description
      }
    }
  },
  {
    id: 'supplier-agreement-item',
    key: 'activity',
    type: FormTableFieldType.Select,
    label: 'Activity',
    format: (item: any, row: SubcontractorProfilingAsset) => {
      if (row.supplierAgreement) {
        return row.supplierAgreement.itemDescription
      }
    }
  },
  {
    key: 'startTime',
    type: FormTableFieldType.Time,
    label: 'Start Time',
    format: formatDateStringHHmm,
  },
  {
    key: 'hours',
    type: FormTableFieldType.Number,
    label: 'Quantity/Hours',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'docketNumber',
    type: FormTableFieldType.Text,
    label: 'Docket Number',
  },
  {
    key: 'area',
    type: FormTableFieldType.Number,
    label: 'Area (m2)',
    maxScale: 1,
    helperTextError: `only 1 decimal place allowed`,
  },
  {
    key: 'depth',
    type: FormTableFieldType.Number,
    label: 'Depth (mm)',
  },
  {
    key: 'rapSuitable',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Rap Suitable',
    format: formatBooleanOrNullToYesNo,
  },
  // {
  //   key: 'tipSite',
  //   type: FormTableFieldType.BooleanRadioGroup,
  //   label: 'Tip Site',
  // },
  {
    key: 'tipSiteLocation',
    type: FormTableFieldType.Text,
    label: 'Tip Site Location',
  },
  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comment',
  },
]

export default DRSSubcontractorProfilingAssetsFields