import React from 'react'
import styled from 'styled-components'
import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import HomeIcon from '@material-ui/icons/Home'
import Link from '../components/Link'
import { Link as ReactRouterLink, useLocation, useParams } from 'react-router-dom'
import { matchPath, match } from 'react-router'
import _get from 'lodash/get'

const Mapping: { [key: string]: any } = {
  'home': {
    url: '/',
    breadcrumbText: 'home',
  },
  'notifications': {
    url: '/notifications',
    breadcrumbText: 'notifications',
  },
  'projects': {
    url: '/projects',
    breadcrumbText: 'projects',
  },
  'project': {
    url: '#',
    breadcrumbText: 'project',
  },
  'mgmt-labour': {
    url: '/mgmt/labour',
    breadcrumbText: 'Labour Management',
  },
  'mgmt-mobile-asset': {
    url: '/mgmt/mobile-asset',
    breadcrumbText: 'Mobile Asset Management',
  },
  'mgmt-crew': {
    url: '/mgmt/crew',
    breadcrumbText: 'Crew Management',
  },
}

const InnerBreadcrumbs = ({
  mappingList,
}: {
  mappingList: string[],
}) => {
  return <MuiBreadcrumbs aria-label='Breadcrumb' separator={<Separator fontSize='large' />}>
    {
      mappingList.slice(0, mappingList.length - 1)/* .filter(mapName => Mapping[mapName]) */.map(mapName => {
        if (!Mapping[mapName]) {
          return <Typography color='textPrimary' key={mapName}>{mapName}</Typography>
        }
        const { url, breadcrumbText } = Mapping[mapName]
        if (mapName === 'home') {
          return <ReactRouterLink to='/' key={mapName}><IconButton size='small'><SHomeIcon /></IconButton></ReactRouterLink>
        }
        // https://github.com/mui-org/material-ui/issues/7877
        return <Button color='inherit' component={Link} {...{ to: url, key: mapName } as any}>
          {breadcrumbText}
        </Button>
      })
    }
    <LastBreadcrumb color='textPrimary'>
      {_get(Mapping, `${mappingList[mappingList.length - 1]}.breadcrumbText`) || mappingList[mappingList.length - 1]}
    </LastBreadcrumb>
  </MuiBreadcrumbs>
}

const Breadcrumbs = ({ }) => {
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>();
  switch (pathname) {
    case '/': {
      return null
    }
    case '/notifications': {
      return <InnerBreadcrumbs mappingList={['home', 'notifications']} />
    }
    case '/projects': {
      return <InnerBreadcrumbs mappingList={['home', 'projects']} />
    }
    case '/mgmt/labour': {
      return <InnerBreadcrumbs mappingList={['home', 'mgmt-labour']} />
    }
    case '/mgmt/mobile-asset': {
      return <InnerBreadcrumbs mappingList={['home', 'mgmt-mobile-asset']} />
    }
    case '/mgmt/crew': {
      return <InnerBreadcrumbs mappingList={['home', 'mgmt-crew']} />
    }
    default:
    //
  }
  if (matchPath(pathname, {
    path: '/project/:id/:part',
    exact: true,
  })) {
    const matched: match<{ id: string, part: string }> | null = matchPath<{ id: string, part: string }>(pathname, {
      path: '/project/:id/:part',
      exact: true,
    })
    return <InnerBreadcrumbs mappingList={['home', `Project ${matched!.params.id}`, matched!.params.part]} />
  }

  // New DRS
  if (matchPath(pathname, {
    path: '/drs/:id',
    exact: true,
  })) {
    return <InnerBreadcrumbs mappingList={['home', id || '', `New DRS`]} />
  }
  return null
}

function matchBreadcrumbs({
  pathname,
}: {
  pathname: string,
}) {
  let result = false
  switch (pathname) {
    case '/mgmt/labour':
    case '/mgmt/mobile-asset':
    case '/mgmt/crew': {
      result = true
      break
    }
  }
  if (matchPath(pathname, {
    path: '/project/:id/:part',
    exact: true,
  })) {
    result = true
  } else if (matchPath(pathname, {
    path: '/drs/:id',
    exact: true,
  })) {
    result = true
  }
  return result
}

function Wrapper({ ...props }) {
  const { pathname } = useLocation()
  return null
  const matched = matchBreadcrumbs({ pathname })
  if (!matched) {
    return null
  }
  return <Container maxWidth='xl'>
    <WrapperInner>
      <Breadcrumbs {...props} />
    </WrapperInner>
  </Container>
}

const WrapperInner = styled.div`
  margin: 24px 0;
`
const Separator = styled(NavigateNextIcon)`
  margin-left: -8px;
  margin-right: -8px;
  color: #D3CFCF;
`
const LastBreadcrumb = styled(Typography)`
  color: #009688;
` as typeof Typography
const SHomeIcon = styled(HomeIcon)`
  color: #D3CFCF;
`
export default Wrapper