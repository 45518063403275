import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { defaultViewOptions } from '@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender'

	
interface Options {
  userViewSerialId: number, 
}
export const apiUserViewsLists = ( {userViewSerialId}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/shiftcardviews`,
    params: {
      userViewSerialId,
      'reqDev': 'web'
    }
  }
  return ax(options)
}

export const addDefaultView = (userSerialId: number, userViewsData: any) => {
    const DefaultView = {
      viewId: "0",
      userSearialId: userSerialId,
      selectedItems: defaultViewOptions.join(","),
      sharedUserSearialId: "",
      viewName: "Default",
      isDefault: false,
    };
    let shiftcardviews = [...userViewsData.data.shiftcardviews, DefaultView];
    return shiftcardviews;
}