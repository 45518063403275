import React from 'react'
import Autocomplete from '../../../../components/GeneralAutocomplete/Autocomplete'
import { ADIDNameToValueLabel } from '../../../../api-adaptors/id-name-to-value-label'
import { apiProjects, apiQueryProjects } from '../../../../apis/projects'
import { Project } from '@src/types/project/Project'
import { ValueLabel } from '@src/types/common/ValueLabel'

const ProjectNumberAutocomplete = ({
  id = '',
  label = '',
  onChange = () => {},
  selectedItem,
  regionId,
  disabled = false,
}: {
  id?: string,
  label?: string,
  onChange(val: ValueLabel): void,
  selectedItem: ValueLabel,
  regionId?: string,
  disabled?: boolean,
}) => {

  const fetch = async (query: string) => {
    try {
      const result = await apiQueryProjects({
        query,
      })
      return result.data.projects
        .slice(0, 5)
        .map((project: Project) => {
          const { value, label } = ADIDNameToValueLabel(project)
          return {
            value,
            label: `${project.id || project.projectInternalId} | ${project.name}`,
            raw: project,
          }
        })
    } catch (err) {
      return []
    }
  }
  
  return <Autocomplete
    id='Enter Project Number'
    label='Enter Project Number'
    disabled={disabled}
    fetch={fetch}
    selectedItem={selectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
  />

}


export default ProjectNumberAutocomplete