import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import _unionBy from 'lodash/unionBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProjectAutocomplete from '@src/components/autocompletes/ProjectAutocomplete'
import { Shift } from '@src/types/Shift'
import { Project } from '@src/types/project/Project'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import FormModalTextField from '@src/components/FormModal/Textfield'
import FormModalTextFieldReadOnly from '@src/components/FormModal/TextfieldReadOnly'
import FormModalDatePicker, { FormModalDatePickerFormat } from '@src/components/FormModal/DatePicker'
import FormModalSelect from '@src/components/FormModal/Select'

import { ShiftTypeId } from '@src/types/ShiftTypeId'
import { Crew } from '@src/types/Crew'

import { ProjectTypeId, formatProjectTypeId } from '@src/types/ProjectTypeId'
import { useSnackbar } from '@src/components/GlobalSnackbar'

import { useAppStore } from '@src/AppStore'

import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { DRSMaterialSpraySealItem } from '@src/types/drs/material/MaterialSpraySealItem'
import ShiftShortActionsMenu from './ShiftShortActionsMenu'
import DRSRedirectConfirmDialog from './DRSRedirectConfirmDialog'

import { DRSFormCard } from '@src/types/DRSFormCard'

import useEditShiftRules from '@src/utils/hooks/useEditShiftRules'
import usePermission from '@src/utils/hooks/usePermission'

import { useDRS } from '@src/modules/DRS/useDRS'
import { DRSPageType } from '@src/modules/DRS/DRSPage'
import { useCrewCalender } from '../StoreCrewCalender'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'

import { LeaveNote, LeaveNoteType } from '@src/types/LeaveNote'
import { apiLeavetNoteCreate } from '@src/apis/leave-note-create'
import { apiLeavetNoteUpdate } from '@src/apis/leave-note-update'
import moment, { Moment } from "moment-timezone";
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
const Format = 'DD/MM/YYYY'
interface Props {
  open: boolean,
  onClose(): void,
  onSave(): void,
  data: null | LeaveNote,
  crewOptions: Crew[],
  originCrew: Crew,
  originDate: string,
}

const CompLeaveNoteDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
    data,
    crewOptions,
    originCrew,
    originDate,
  } = props
  const isAdd = data === null
  const isEdit = !isAdd
  const { alertDone, alertError, alert } = useSnackbar()
  const { serialId } = useAppStore()
  const { username } = useAppStore()
  const [project, setProject] = useState<null | Project>(null)
  const [street, setStreet] = useState<string>('')
  const [suburb, setSuburb] = useState<string>('')
  const [nearestCrossStreet, setNearestCrossStreet] = useState<string>('')
  const [date, setDate] = useState<string>('')
 
  const [drsId, setDRSId] = useState<string>('')
  const [drsPageType, setDrsPageType] = useState<DRSPageType>(-1)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [drsDialogOpen, setDrsDialogOpen] = useState<boolean>(false)
  const [drsFormCard, setDrsFormCard] = useState<DRSFormCard | undefined>()
  const [isSaving, setIsSaving] = useState<any>(false)

  const { canEditShift, canPlanDRS, canEditDRS, canReviewDRS } = usePermission()


  const [refreshTrigger, setRefreshTrigger] = useState<number>(0)
  const [fieldsInError, setFieldsInError] = useState<any[]>([])

  let validateStreet: any = null, validateNearest: any = null, validateSubrub: any = null;


 

  const handleOnUpdated = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [projectTypeIds, setProjectTypeIds] = useState<(ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal)[]>([])
  const PROJECT_TYPE_OPTIONS = [
    {
      value: ProjectTypeId.Asphalt,
      label: formatProjectTypeId(ProjectTypeId.Asphalt),
    },
    {
      value: ProjectTypeId.SpraySeal,
      label: formatProjectTypeId(ProjectTypeId.SpraySeal),
    },
  ]
  const [crew, setCrew] = useState<null | Crew>(null)
  const [leaveType, setLeaveType] = useState<null | any>(null)
  const CREW_OPTIONS = crewOptions.map((crew) => {
    return {
      ...crew,
      value: crew.id,
      label: crew.name,
    }
  })
  useEffect(() => {
    console.log(`originDate`);
    console.log(originDate);
    console.log(`originDate`);
    setCrew(originCrew)
    setDate(originDate)
  }, [])
  const getLeaveLabel =  (id:number) => {
    switch(id){
      case 0:
        	return 'Annual Leave';
        case 1:
        	return 'Public Holiday';
        case 2:
        	return 'RDO';
        case 3:
        	return 'Weekend';
        case 4:
        	return 'Work Other Depot';
        case 5:
        	return 'Subcontractor Not Required';
		case 6:
			return 'Split Crew';
        default:
          return '';
    }
   

  }
  useEffect(() => {
    if (data !== null) {
      setLeaveType(data.LeaveType)
      // setProjectTypeIds(finalProjectTypeIds)
      // setShiftTypeId(data.plan.shiftType.id)
      // setStreet(data.plan.street)
      // setSuburb(data.plan.suburb)
      // setNearestCrossStreet(data.plan.nearestCrossStreet)
      // setAsphaltProducts(data.products.asphalt)
      // setSpraySealProducts(data.products.spraySeal)
      // setDRSId(data.drsId.toString())
    }
  }, [data])

 
  const handleSave = async () => {
   
    if (!crew) {
      alert({
        type: 'error',
        message: 'Crew required',
      })
      return
    }
    if (!leaveType) {
      alert({
        type: 'error',
        message: 'Leave Type required',
      })
      return
    }
    setIsSaving(true)
    if (isAdd) {
      var idLeave: any = leaveType;
    
      try {
        apiLeavetNoteCreate({
          LeaveType: idLeave,
          LeaveDate: moment(date).format('YYYY-MM-DD'),
         
          CrewID: crew.id,
          Active: true,
          ModifiedBy:username,
          CreatedBy:username
        }).then((response: any) => {
          alertDone()
          setIsSaving(false)
          onSave()
        })

      } catch (e) {
        alertError()
      }
          


    } else {
      if (data === null) {
        return
      }

      var idLeave: any = leaveType;
    
      try {
        apiLeavetNoteUpdate({
          LeaveType: idLeave,
          LeaveCalendarNotesID: data.LeaveCalendarNotesID,
        
          Active: true,
          ModifiedBy:username,
         
        }).then((response: any) => {
       
          alertDone()
          setIsSaving(false)
        
          onSave()
        })

      } catch (e) {
        alertError()
      }

    }
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>Leave Shift</ModalTitle>
    </DialogTitle>
    <DialogContent>
          <FieldsWrapper>
          <FieldWrapper>
              {
                <FormModalSelectWrapper>
                  <FormModalSelect
                    type='leave-note-type'
                    label='Type of Leave'
                    value={ leaveType}
                    onChange={(item: any) => {
                      
                      setLeaveType(item.id)
                    }}
                  
                  />
                </FormModalSelectWrapper>
                
                
              }
            </FieldWrapper> 
            <FieldWrapper>
            {data ? <FormModalTextFieldReadOnly
                  type='text'
                  label='Leave Date'
                  value={data ? `${formatDateStringDDMMYYYY(data.LeaveDate)}` : null}
                /> :
                <FormModalDatePicker
                  label='Leave Date'
                  value={date}
                  onChange={(isoString: string) => {
                    setDate(isoString)
                  }}
                />
              
              }
            </FieldWrapper>
            <FieldWrapper>
              {data ?
              <FormModalTextFieldReadOnly
              type='text'
              label='Crew'
              value={crew ? crew.name : null}
            />
            :
                <FormModalSelectWrapper>
                  <FormModalSelect
                    type='create-shift-crews'
                    label='Crew'
                    value={crew}
                    onChange={(item: any) => {
                      setCrew(item.raw)
                    }}
                    options={CREW_OPTIONS}
                  />
                </FormModalSelectWrapper>
                
                
              }
            </FieldWrapper>
         
          </FieldsWrapper>
        
    </DialogContent>
    <DialogActions>
     
      <ShiftShortActionsMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onOpenDrs={() => {
          setDrsDialogOpen(true)
          setAnchorEl(null)
        }}
        onOpenCrewpack={() => {
          if (data !== null) {
            window.open(`#/crew-pack/${data.LeaveCalendarNotesID}`, '_blank')
          }
          setAnchorEl(null)
        }}
      />
      {drsFormCard && drsDialogOpen && <DRSRedirectConfirmDialog
        data={drsFormCard.id}
        open={drsDialogOpen}
        onCancel={() => setDrsDialogOpen(false)}
        onClose={() => setDrsDialogOpen(false)}
      />
      }
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
      
        onClose()
      }}>
        Cancel
      </Button>
      {canEditShift &&
        <Button
          color='primary'
          variant='contained'
          onClick={handleSave}
          disabled={fieldsInError.length > 0 ? true : false}
        >
          {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
          {!isSaving && 'SAVE '}
        </Button>
      }
    </DialogActions>
  </Dialog>
}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`
const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
`
const FormModalSelectWrapper = styled.div`
  min-width: 200px;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`


export default CompLeaveNoteDialog