import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import { apiProductSearch } from '../../apis/product-search'

interface Options extends AIMS.Autocomplete {
  plantId?: string,
  disabled?: boolean,
}
const ProductAutocomplete = ({
  id = '',
  label = '',
  plantId,
  onChange,
  selectedItem,
  disabled = false,
  fullWidth = false,
}: Options) => {
  const fetch = async (query: string) => {

    const result: any = await apiProductSearch({
      query,
      plantId,
    })
    return result.data.products
      .slice(0, 5)
      .map((product: any) => {
        return {
          value: product.id,
          label: product.description,
          raw: product,
        }
      })
    // return [
    //   {
    //     'id': '5',
    //     'description': 'XVP.244197 - DG10 A15E NSW-HD',
    //   },
    // ].map(item => {
    //   return {
    //     value: item.id,
    //     label: item.description,
    //   }
    // })
  }
  let finalSelectedItem 
  if (selectedItem && selectedItem.value) {
    finalSelectedItem = selectedItem
  } else {
    finalSelectedItem = { value: '', label: '' }
  }
  return <Autocomplete
    id={id}
    label={'Product'}
    fetch={fetch}
    selectedItem={finalSelectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={fullWidth}
    disabled={disabled}
  />
}


export default ProductAutocomplete