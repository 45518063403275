import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { MobileAsset } from '@src/modules/MgmtMobileAsset/fields'

// active: true
// condition: ""
// costingSystemAssetNumber: ""
// crews: [{…}]
// depot: {id: "10000", name: "Sydney Metro", info: {…}}
// description: "Truck - MITSUBISHI/CANTER"
// error: true
// errorDate: ""
// id: "NTT810"
// nextServiceDue: ""
// reading: ""
// region: {id: "1000", name: "Southern", active: true}
// rego: ""
// state: {id: "NSW", name: "New South Wales"}
// type: {id: 1, description: "Heavy Vehicle"}
interface APIMobileAssets {
  id: string,
  description: string,
  active: boolean,
  crews: any,
  depot: {
    id: string,
  },
  region?: string,
  state?: string,
}

export const apiMobileAssetsCreate = (asset: MobileAsset) => {
  const data = {
    ...asset,
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/mobileassets`,
    data,
  }
  return ax(options)
}