import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useCrewCalender } from '../StoreCrewCalender'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { useSelectedNotes } from '@src/utils/hooks/StoreSelectedNotes'
import NoteMoreActionsMenu from './NoteActionMenu'
import CompNoteDialog from './NoteDialog'
import { apiDepotNoteUpdate } from '@src/apis/depot-note-update'
import DuplicateNoteDialog from './DuplicateNoteDialog'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { DragItemTypes } from '@src/types/DragItemTypes'
import moment from 'moment'
import { useHover } from '@src/utils/hooks'
import _map from 'lodash/map'
import { apiDepotNoteRemove } from '@src/apis/depot-note-remove'
import { useAppStore } from '@src/AppStore'
interface DragItem {
  type: string
}
interface Props {
  isEdit: boolean,
  currentIndex: number,
  color: string,
  currentDate: string,
  onClick(e: SyntheticEvent): void,
  onPrevious(): void,
  onSave(): void,
  onNext(): void,
  hidden: boolean,
  content: string,
  noteId: string,
  dateModified?: string,
  createdBy?: string
  shiftNoteStyle: string,
  shiftNewStyle: string,
  colorCode: string,
  inDragLayer: boolean,
  data: any,
  batchIndex: number,
  modifiedBy: string,
  dateCreated: string,
  depotName: string
}

const NoteItem = ({
  isEdit,
  currentIndex,
  color,
  currentDate,
  onClick,
  onPrevious,
  onSave,
  onNext,
  hidden,
  content,
  noteId,
  dateModified,
  createdBy,
  shiftNoteStyle,
  shiftNewStyle,
  colorCode,
  inDragLayer,
  data,
  batchIndex,
  modifiedBy,
  dateCreated,
  depotName

}: Props) => {

  const { alert, alertError } = useSnackbar()
  const { serialId } = useAppStore();
  const { currentBatch, selectedStart, selectedEnd, updateSelectedStartBox, goRefresh, isCalendarControlsDisabled, setIsCalendarLoading, updateSelectedEndBox, setCalendarControlsDisabled } = useCrewCalender()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [date, setDate] = useState<string>('')
  const [hiddenBlockAction, setHiddenBlockAction] = useState<boolean>(true)

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [editable, setEditable] = useState<boolean>(false)
  const [duplicateNoteDialogOpen, setDuplicateNoteDialogOpen] = useState<boolean>(false)

  //const [canShiftDateBeChanged, canShiftCrewBeChanged, canShiftCrewBeDeleted] = useEditShiftRules(data)
  const { selectedNoteCount, addSelectedNote, clearSelectedNotes, isDragHappening, setIsDragHappening } = useSelectedNotes()

  useEffect(() => {
    let found = false;

    if (found)
      setIsSelected(true)
    else
      setIsSelected(false)
  }, [selectedNoteCount, currentIndex])
  useEffect(() => {
    if (currentBatch[selectedEnd])
      setDate(currentBatch[selectedEnd].toISOString())
  }, [selectedEnd])
  const handleMoreClicked = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    setAnchorEl(e.target as HTMLElement)
  }
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAddDialogOpen(true);
    if (noteId !== `0`)
      setEditable(true);
    onClick(e)
  }
  const handleDelete = async (noteId: string) => {
    try {
      apiDepotNoteRemove({
        noteId: noteId,
        serialId: serialId

      }).then((response: any) => {
        onSave()
      })
    } catch (e) {
      alertError()
    }
  }
  const handleUpdate = async (noteData: any) => {
    try {
      apiDepotNoteUpdate(noteData).then((response: any) => {
        onSave()
      })
    } catch (e) {
      alertError()
    }
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.DepotNote,
      note: data,
    },
    canDrag: false,
    begin: (monitor) => {
      console.log(isDragging);
      console.log(preview);
      if (!isCalendarControlsDisabled) {
        setIsDragHappening(true)
        updateSelectedStartBox(batchIndex);
        // add selected shift when not selecting multiple shifts
        addSelectedNote(data)
      } else {
        alert({
          type: 'error',
          message: `Shifts can't be dragged while the Calendar is updating.`
        })
      }
    },
    end: async (item: any, monitor) => {
      //console.log(`DRSItem useDrag() end`)
      if (!isCalendarControlsDisabled) {

        setIsDragHappening(false)
        const dropResult = monitor.getDropResult()
        // if dragging Shift has been dropped on a different date or Crew
        if (item && dropResult && (selectedStart !== selectedEnd)) {
          const draggingEndDate = moment(date).startOf('day');
          try {
            setIsCalendarLoading(true)
            setCalendarControlsDisabled(true);
            console.log(item);
            console.log(draggingEndDate);
            await handleUpdate({
              DepotCalendarNotesID: item.note.DepotCalendarNotesID,
              Comment: item.note.Comment,
              RGBColorCode: item.note.RGBColorCode,
              CreatedBy: item.note.CreatedBy,
              Active: item.note.Active,
              Date: draggingEndDate.format('YYYY-MM-DD'),

            });
            goRefresh()
          } catch (e) {
            alertError()
          }
        }
        clearSelectedNotes();
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
  })

  const [boxRef, hovered] = useHover()
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragItemTypes.DepotNote,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      //  console.log(`DRSBox useDrop() hover`)
    },
    collect: monitor => ({
      isOver: !isCalendarControlsDisabled && !!monitor.isOver(),
      canDrop: !isCalendarControlsDisabled,
    }),
    drop: (item: any, monitor) => {
      // if (item.note === {}) {
      //   return false;
      // }commented dated 29-aug-2022
      //console.log(`DRSBox useDrop() drop crewIndex=${crewIndex}, batchIndex=${batchIndex}`)
     //  updateSelectedEndBox(batchIndex, null) commented dated 29-aug-2022
    },
  })
  drop(boxRef);
  const handleEdit = () => {
    setAddDialogOpen(true);
  }
  const handleHiddenBlock = () => {
    hiddenBlockAction ? setHiddenBlockAction(false) : setHiddenBlockAction(true);
  }
  return <Wrapper className="menuNote" ref={boxRef} isSelected={isSelected} inDragLayer={inDragLayer} isDragHappening={isDragHappening}>
    <HiddenBlock id="hiddenNoteBlock" onClick={handleHiddenBlock} />
    <NoteBlock onClick={handleClick} style={{ background: color }}>
      {(content !== "") && (content !== "blank") && shiftNoteStyle === 'shfitNoteClass' &&
        <small >1/1</small>
      }
      <ShiftNo ref={drag} className={shiftNoteStyle + ' ' + shiftNewStyle}>
        {
          <React.Fragment>
            {content === 'blank' ? '' : ((depotName!='' ?  depotName : '')+content)}
            <NavIcons onClick={handleMoreClicked}>
              <MoreButton>
                <MoreVertIcon fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
              </MoreButton>
            </NavIcons>
          </React.Fragment>
        }
      </ShiftNo>
    </NoteBlock>
    {anchorEl !== null && <NoteMoreActionsMenu
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      onEdit={() => {
        handleEdit()
        setEditable(true);
        setAnchorEl(null)
      }}
      onAdd={() => {
        setEditable(false);
        setAddDialogOpen(true);
      }}

      onDuplicateNote={() => {
        setDuplicateNoteDialogOpen(true);
      }}

      onDelete={() => {
        handleDelete(noteId)
        setAnchorEl(null)
      }}
      isEdit={isEdit}

    />
    }
    {duplicateNoteDialogOpen &&
      <DuplicateNoteDialog
        open={duplicateNoteDialogOpen}
        colorCode={color}
        content={content}
        dateModified={dateModified}
        createdBy={createdBy}
        noteId={noteId}
        currentDate={currentDate}
        onClose={() => {
          setDuplicateNoteDialogOpen(false)
        }}
        onSave={() => {
          goRefresh()
          setDuplicateNoteDialogOpen(false)
          onSave()
        }} />
    }
    {addDialogOpen &&
      <CompNoteDialog
        open={addDialogOpen}
        editable={editable}
        colorCode={(editable === true) ? color : 'none'}
        content={content}
        dateModified={dateModified}
        createdBy={createdBy}
        noteId={noteId}
        currentDate={currentDate}
        modifiedBy={modifiedBy}
        dateCreated={dateCreated}
        isEdit={editable}
        onClose={() => {
          setAddDialogOpen(false)
        }}
        onSave={() => {
          goRefresh()
          setAddDialogOpen(false)
          onSave();
        }}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div<{ isSelected: boolean, inDragLayer: boolean, isDragHappening: boolean }>`
  margin-bottom: 5px;
  :last-child {
      margin - bottom: 0px;
  }
  ${props => {
    if (!props.inDragLayer && props.isSelected && props.isDragHappening) {
      return css`
        opacity: 0.3;
      `
    }
  }}
`
const HiddenBlock = styled.div` 
height:0;
 width:0;
 display:none;
`
const NoteBlock = styled.div` 
 position:relative;
  background-color: #F5F5F5;
  color: #6E6E6E;
  font-size: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  padding: 0 8px 8px 8px;
  border: 1px solid #009688;
  z-index:502;
  text-align:left;

`

const MoreButton = styled(IconButton)`
  position: absolute;
  right: -8px;
  top: 0;
  color: #0A9688;
  
 
`
const NavIcons = styled.div`
  white-space: nowrap;
`
const ShiftNo = styled.div`
  font-size: 12px;
 
  line-height: 30px;
  text-align: center;
    overflow-wrap: break-word;
    word-break: break-all;
    margin-top:7px;
  
`



export default observer(NoteItem)