import React, { useState, SyntheticEvent, useEffect } from 'react'
import styled from 'styled-components'
import CrewColorSelectedDot from './CrewColorSelectedDot'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import CrewColorPalette, { CrewColorPaletteColor } from './CrewColorPalette'

const TIP = 'Click swatch to change colour'
const TIP2 = 'Input Color Hex or Choose from Palette'

interface CrewColorProps {
  colorHex?: string,
  onChange(colorHex: string): void,
}

const CrewColor = ({
  colorHex = '',
  onChange,
}: CrewColorProps) => {
  const [ status, setStatus ] = useState<'view' | 'edit'>('view')
  const [ inputValue, setInputValue ] = useState<any>('')
  useEffect(() => {
    if (!colorHex) {
      setStatus('edit')
    } else {
      setStatus('view')
      setInputValue(`${colorHex}`)
    }
    return () => {
      setStatus('view')
      setInputValue('')
    }
  }, [])
  const handleDotClick = () => {
    setStatus('edit')
  }
  const handleInputChange = (e: SyntheticEvent) => {
    let color = (e.target as HTMLInputElement).value
    const isHex = (string: string) => /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(string)
    let isValidColor = false
    if (isHex(color)) {
      isValidColor = true
      if (color.indexOf('#') === -1) {
        color = `#${color}`
      }
    }
    if (isValidColor) {
      console.log('onchange', color)
      onChange(color)
    }
    setInputValue(color)
  }
  const handleColorChange = (color: CrewColorPaletteColor) => {
    onChange(color.hex)
    setInputValue(color.hex)
  }
  const renderView = () => {
    return <React.Fragment>
      <TipTypography>{ TIP }</TipTypography>
      <DotWrapper onClick={handleDotClick} data-testid='dot-click-trigger'>
        <CrewColorSelectedDot 
          data-testid='selected-dot-view'
          colorHex={colorHex!}
        />
      </DotWrapper>
    </React.Fragment>
  }
  const renderEdit = () => {
    return <React.Fragment>
      <TipTypography>{ TIP2 }</TipTypography>
      <DotInputGroup>
        { colorHex && 
          <DotWrapper>
            <CrewColorSelectedDot 
              data-testid='selected-dot-edit'
              colorHex={colorHex!}
            />
          </DotWrapper>
        }
        <HexInput 
          data-testid='hex-input'
          placeholder='Color Hex #111111'
          onChange={handleInputChange}
          value={inputValue}
        />
      </DotInputGroup>
      <ColorPaletteWrapper>
        <CrewColorPalette
          data-testid='color-palette'
          selectedHex={colorHex}
          onChange={handleColorChange}
        />
      </ColorPaletteWrapper>
    </React.Fragment>
  }
  return <Wrapper>
    { status === 'view' ? 
      renderView() :
      renderEdit()
    }
  </Wrapper>
}

const Wrapper = styled.div``
const DotWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
`
const DotInputGroup = styled.div`
  display: flex;
`
const TipTypography = styled(Typography)`
  color: #00000089;
  font-size: 14px;
`
const HexInput = styled(Input)`
  
` as typeof Input

const ColorPaletteWrapper = styled.div`
  margin-top: 6px;
`
export default CrewColor