import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { VMP } from '@src/types/VMP'

interface StoreVMP {
  id: number | null,
  setId(id: number): void,
  vmp: VMP | null,
  setVMP(vmp: VMP): void,
}

interface fetchOptions {
  serialId: number, 
  srrId: number | string,
}

export const store: StoreVMP = observable<StoreVMP>({
  id: null,
  setId: action((id: number) => store.id = id),
  vmp: null,
  setVMP: action((vmp: VMP) => {
    
    store.vmp = vmp
  }),
})

const context: React.Context<StoreVMP> = React.createContext<StoreVMP>(store)

export const useStoreVMP = () => {
  return useContext<StoreVMP>(context)
}