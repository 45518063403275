import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  id: string,
  error: boolean,
  date?: string,
}

export const apiMobileAssetsUpdateError = (options: Options) => {
  const {
    id,
    error,
    date,
  } = options
  const data = {
    id,
    error,
    date,
  }
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/mobileassets/${id}/error`,
    data,
  }
  return ax(axOptions)
}