import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// if (process.env.REACT_APP_AUTH && process.env.REACT_APP_AUTH === 'none') {
  // for dev locally, we do not need aws login at this point
  ReactDOM.render(<App />, document.getElementById('root'))
// } else {
  // ReactDOM.render(<AppWithAuth />, document.getElementById('root'))
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
