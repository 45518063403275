import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from './base-TableCell'
import { useDRS } from '../StoreDRS'

const FieldLabourCrewSummaryTable = ({
    field,
    onChange,
  }: {
    field: DRS.Field,
    onChange(val: any, options: any): void,
  }) => {
    const { data: rootData } = useDRS()
    const { data, path } = field
    const { crewCount, crewHours, foremanHours, supervisionHours, projectManagerHours, totalHours} = data;

    return <Wrapper>
        <Content>
          <Table>
            <TableHead>
                <TableRow>
                    <LabourCrewSummaryTableHeaderCell>Edit</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Crew Count</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Crew Hours</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Foreman Hours</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Supervision Hours</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Project Manager Hours</LabourCrewSummaryTableHeaderCell>
                    <LabourCrewSummaryTableHeaderCell>Total Hours</LabourCrewSummaryTableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* TODO: fix the value once finalised by backend */}
                <TableRow>
                    <TableBodyCell></TableBodyCell>
                    <TableBodyCell>{crewCount ? crewCount : '0'}</TableBodyCell>
                    <TableBodyCell>{crewHours ? crewHours : '0'}</TableBodyCell>
                    <TableBodyCell>{foremanHours ? foremanHours : '0'}</TableBodyCell>
                    <TableBodyCell>{supervisionHours ? supervisionHours : '0'}</TableBodyCell>
                    <TableBodyCell>{projectManagerHours ? projectManagerHours : '0'}</TableBodyCell>
                    <TableBodyCell>{totalHours ? totalHours : '0'}</TableBodyCell>
                </TableRow>
            </TableBody>
          </Table>
        </Content>
    </Wrapper>
  }

const LabourCrewSummaryTableHeaderCell = styled(TableCell)`
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  background-color: #DCDCDC;
  max-width: 80px;
` as any

const TableBodyCell = styled(TableCellOrigin)`
  border-bottom: 0;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
` as typeof TableCellOrigin
const Wrapper = styled.div``
const Content = styled.div`
  overflow-x: auto;
`

export default observer(FieldLabourCrewSummaryTable)