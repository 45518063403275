
import React, { useEffect, useRef, useState, useLayoutEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import deburr from 'lodash/deburr'
import debounce from 'lodash/debounce'

import Downshift from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import Fade from '@material-ui/core/Fade'

function renderInput(inputProps: any, defaultValue: any, color: any) {
  return (<MyInput inputProps={inputProps} defaultValue={defaultValue} color={color}/>
    
  )
}

const InvisibleDiv = styled.div<{ copiedStyle: any }>`
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
    height: 0px;
    overflow: hidden;
    white-space: pre;
    font-size: ${({copiedStyle}) => copiedStyle.fontSize};
    font-family: ${({copiedStyle}) => copiedStyle.fontFamily};
    font-weight: ${({copiedStyle}) => copiedStyle.fontWeight};
    font-style: ${({copiedStyle}) => copiedStyle.fontStyle};
    letter-spacing: ${({copiedStyle}) => copiedStyle.letterSpacing};
    text-transform: ${({copiedStyle}) => copiedStyle.textTransform};
`
const StyleInputWrapper = styled.div`

`
const InputBase = styled.input`
  font: inherit;
  color: currentColor;
  padding: 2px 0 2px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.1875em;
  // Reset (19px); match the native input line-height
  margin: 0;
  // Reset for Safari
  // Remove grey highlight
  -webkit-tap-highlight-color: transparent;
   
  display: block;
  // Make the flex item shrink with Firefox
  min-width: 0;
  width: 100%;
  
  // Edge
  &:focus {
    outline: 0;
  }
  // Reset Firefox invalid required input style
  &:invalid {
    box-shadow: none;
  }
  &::-webkit-search-decoration {
    // Remove the padding when type=search.
    -webkit-appearance: none;
  }
  border-radius: 0;
`

const StyledInput = styled(InputBase)`
  font: inherit;
  font-weight: 700;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  display: block;
  padding: 3px 0 3px;
  min-width: 20px;
  background: none;
  box-sizing: content-box;
  &:focus {
    outline: 0;
  }
  ${props => {
    if (props.color === 'default') {
      return `
        color: currentColor;
        border-bottom: 1px solid #fff;
      `
    } else if (props.color === 'boral-light') {
      return `
        color: #72AD5C;
        border-bottom: 1px solid #72AD5C;
      `
    } else if (props.color === 'boral-dark') {
      return `
        color: #365D14;
        border-bottom: 1px solid #365D14;
      `
    }
  }}
  
   
`

const MyInput = ({ 
  inputProps, 
  ...props 
}: {
  inputProps: any,
  color: string,
  defaultValue?: string,
}) => {
  const [ width, setWidth ] = useState<any>()
  const invisibleDivRef = useRef<any>(null)
  
  const { inputRef, ...other } = inputProps
  const [ copiedStyle, setCopiedStyle ] = useState<any>({})
  useLayoutEffect(() => {
    const copyStyles = (/* styles, node */) => {
      const { fontSize, fontFamily, fontWeight, fontStyle, letterSpacing, textTransform } = window.getComputedStyle(inputRef.current)
      setCopiedStyle( { fontSize, fontFamily, fontWeight, fontStyle, letterSpacing, textTransform } )
    }
    copyStyles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useLayoutEffect(() => {
    if (invisibleDivRef.current) {
      // console.log(invisibleDivRef.current.scrollWidth)
      setWidth(`${invisibleDivRef.current.scrollWidth + 2}px`)
      // setWidth(`${invisibleDivRef.current.scrollWidth}px`)
    }
  }, [inputProps.value])
  return <React.Fragment>
    <StyleInputWrapper>
      <StyledInput
        ref={inputRef}
        {...other}
        style={
          {
            width
          }
        }
        color={props.color}
      />
    </StyleInputWrapper>
    <InvisibleDiv ref={invisibleDivRef} copiedStyle={copiedStyle}>{inputProps.value}</InvisibleDiv>
  </React.Fragment>
}

function renderSuggestion(suggestionProps: any) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps
  
  const isHighlighted = highlightedIndex === index
  let isSelected = false
  if (selectedItem && 
    ((selectedItem.label || '').indexOf(suggestion.label) > -1)) {
    isSelected = true
  }
  
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    display:'inline-block'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    // marginTop: theme.spacing(1),
    // left: 0,
    // right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
} as any))


const WordAutocomplete = ({ 
  id = '',
  color = 'default',
  onInput = () => {},
  onChange = () => {},
  onSelect = () => {},
  onEsc = () => {},
  options = [], 
  selectedItem,
  placeholder,
  defaultValue,
}: {
  id?: string,
  color: string, //PropTypes.oneOf(['default', 'boral', 'boral-light', 'boral-dark']),
  onInput?(value: string): void,
  onChange?(): void,
  onSelect(e: any): void,
  onEsc?(): void,
  options: any[],
  selectedItem: any,
  // selectedItem: AIMS.ValueLabel | null,
  placeholder?: string,
  defaultValue?: string,
}) => {
  const inputRef = useRef(null)
  const classes: any = useStyles()
  const fireOnInput = debounce(value => {
    onInput(value)
  }, 200)
  const handleSelect = (e: any) => {
    //@ts-ignore
    inputRef.current.blur()
    onSelect(e)
  }
  return (
    <div className={classes.root}>
      <Downshift 
        id={id} 
        selectedItem={selectedItem}
        onChange={onChange}
        onSelect={handleSelect}
        itemToString={(item: AIMS.ValueLabel | null) => (item && item.label ? item.label : '')}
        >

        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          toggleMenu,
        }) => {
          const handleKeyUp = (e: SyntheticEvent) => {
            fireOnInput(inputValue)
          }
          const onFocus = (e: SyntheticEvent) => {
            toggleMenu()
          }
          const { ...inputProps } = getInputProps({
            placeholder: placeholder,
            onKeyDown: (e: KeyboardEvent) => {
              if (e.keyCode === 27) {
                e.stopPropagation()// esc
                e.preventDefault()
                //@ts-ignore
                e.nativeEvent.preventDownshiftDefault = true
                toggleMenu()
                //@ts-ignore
                inputRef.current.blur()
                onEsc()
              }
            },
            onKeyUp: handleKeyUp,
            onFocus,
          })

          return (
            <div className={classes.container}>
              {
                renderInput({ inputRef, ...inputProps }, defaultValue, color)
              }
              <Popper
                open={isOpen}
                anchorEl={inputRef.current}
                style={{zIndex: 1300}}
                placement='bottom-start'
                transition
              >
              {
                ({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={{appear: 200}}>
                    <div {...getMenuProps()}>
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {options.map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion }),
                              highlightedIndex,
                              selectedItem,
                            }),
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  </Fade>
                )
              }
              </Popper>
            </div>
          )
        }}
      </Downshift>
      
    </div>
  )
}
 
export default WordAutocomplete