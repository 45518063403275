import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useCrewCalender } from '../StoreCrewCalender'
import _merge from 'lodash/merge'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _reject from 'lodash/reject'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useAppStore } from '@src/AppStore'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useSelectedShifts } from '@src/utils/hooks/StoreSelectedShifts'
import DuplicateLeaveNoteDialog from './DuplicateLeaveNoteDialog'
import LeaveNoteMoreActionsMenu from './LeaveNoteActionMenu'
import { apiLeaveNoteRemove } from '@src/apis/leave-note-remove'


interface Props {
  data: any,
  currentIndex: number,
  crewIndex: number,
  batchIndex: number,
  total: number,
  inDragLayer: boolean,
  onClick(e: SyntheticEvent): void,
  onPrevious(): void,
  onNext(): void,
  className: string

}

const LeaveNoteItem = ({
  data,
  currentIndex,
  crewIndex,
  batchIndex,
  total,
  inDragLayer,
  onClick,
  onPrevious,
  onNext,
  className

}: Props) => {




  const { serialId, } = useAppStore()
  const { alert, alertError } = useSnackbar()
  const { currentBatch, selectedStart, selectedEnd,  goRefresh, isCalendarControlsDisabled, setIsCalendarLoading, setCalendarControlsDisabled } = useCrewCalender()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [date, setDate] = useState<string>('')

  const [duplicateNoteDialogOpen, setDuplicateNoteDialogOpen] = useState<boolean>(false)
  const [leaveNoteData, setLeaveNoteData] = useState<any>(null)
  const { selectedShiftCount, selectedShifts, clearSelectedShifts, isDragHappening } = useSelectedShifts()

  useEffect(() => {
    let found = selectedShifts.find((o: any) => o.LeaveCalendarNotesID === data.LeaveCalendarNotesID);
    if (found)
      setIsSelected(true)
    else
      setIsSelected(false)
  }, [selectedShiftCount, currentIndex])
  useEffect(() => {
    if (currentBatch[selectedEnd])
      setDate(currentBatch[selectedEnd].toISOString())
  }, [selectedEnd])

  useEffect(() => {
    if (data !== null) {

      //console.log(`DRSItem useEffect() data=${JSON.stringify(data, null, 2)}`)
    }
  }, [data])



  const handleMoreClicked = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    setAnchorEl(e.target as HTMLElement)
  }
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    //console.log(`DRSItem handleClick() e.ctrlKey=${e.ctrlKey}`)
    if (e.ctrlKey || e.altKey || e.metaKey) {
      if (isSelected) {

        return
      }

      if (isCalendarControlsDisabled) {
        alert({
          type: 'error',
          message: `Shifts can't be dragged while the Calendar is updating.`
        })
        return
      }

      return
    }
    clearSelectedShifts()
    onClick(e)
  }
 

  const handlePrevious = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onPrevious()
  }
  const handleDelete = async () => {
    if (!(data)) {
      alert({
        type: 'error',
        message: `Leave Shift #${data.LeaveCalendarNotesID} cannot be removed.`
      })
      return
    }
    try {
      setIsCalendarLoading(true)
      setCalendarControlsDisabled(true)
      apiLeaveNoteRemove({
        noteId: data.LeaveCalendarNotesID,
        serialId,
      }).then(() => {

        goRefresh()
        alert({
          message: `Leave Shift #${data.LeaveCalendarNotesID} was deleted.`
        })

        //window.location.reload();
      })
    } catch (e) {
      setIsCalendarLoading(false)
      setCalendarControlsDisabled(false)
      alertError()
    }
  }
  const showLeaveType = (leaveType: any) => {
    switch(leaveType){
      case 0:
        	return 'Annual Leave';
        case 1:
        	return 'Public Holiday';
        case 2:
        	return 'RDO';
        case 3:
        	return 'Weekend';
		case 4:
			return 'Work Other Depot';
		case 5:
			return 'Subcontractor Not Required';
		case 6:
			return 'Split Crew';				
        default:
          return '';
    }
  
  }
  const handleNext = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    onNext()
  }
  useEffect(() => {

  }, [])
  return <Wrapper isSelected={isSelected} inDragLayer={inDragLayer} className={className} isDragHappening={isDragHappening} >
    <ShiftBlock onClick={handleClick} isNight={0} isSelected={isSelected} >
      <NavIcons>
        <MoreButton isNight={0} size='small' onClick={handleMoreClicked}>
          <MoreVertIcon fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
        </MoreButton>
        <ShiftNo>
          {`${currentIndex + 1}/${total}`}
          {total !== 1 &&
            <React.Fragment>

              <NavIconButton
                isNight={0}
                disabled={total === 1}
                size='small'
                onClick={handlePrevious} >
                <KeyboardArrowLeft fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
              </NavIconButton>
              <NavIconButton
                isNight={0}
                disabled={total === 1}
                size='small'
                onClick={handleNext} >
                <KeyboardArrowRight fontSize="small" style={{ padding: 0, height: 14, width: 14 }} />
              </NavIconButton>

            </React.Fragment>
          }
        </ShiftNo>
      </NavIcons>
      <ShiftInfoRow isNight={0}>
        {
          
            <p style={{ margin: '0px',color:'#fff' }}>{showLeaveType(data.LeaveType)}</p>
       
        }
      </ShiftInfoRow>



    </ShiftBlock>
    {anchorEl !== null && <LeaveNoteMoreActionsMenu
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}

      onDuplicateNote={() => {
        setLeaveNoteData(data);
        setDuplicateNoteDialogOpen(true);
      }}
      onDelete={() => {
        handleDelete()
        setAnchorEl(null)
      }}

    />
    }
    {duplicateNoteDialogOpen &&
      <DuplicateLeaveNoteDialog
        open={duplicateNoteDialogOpen}
        leaveNoteData={leaveNoteData}
        IsAcknowledge={true}
        onClose={() => {
          setDuplicateNoteDialogOpen(false)
        }}
        onSave={() => {
          goRefresh()
          setDuplicateNoteDialogOpen(false)
          goRefresh();
        }} />
    }

  </Wrapper>
}

const Wrapper = styled.div<{ isSelected: boolean, inDragLayer: boolean, isDragHappening: boolean }>`
  margin-bottom: 5px;
  :last-child {
      margin - bottom: 0px;
  }
  ${props => {
    if (!props.inDragLayer && props.isSelected && props.isDragHappening) {
      return css`
        opacity: 0.3;
      `
    }
  }}
`

const ShiftBlock = styled.div<{ isNight: number, isSelected: boolean }>`
  flex-grow: 1;
  height: 100%;
  position: relative;
  background-color: #53544F;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  padding: 0 8px 8px 8px;
  border: 1px solid #fff;
  ${props => {
    if (props.isNight) {
      if (props.isSelected) {
        return css`
          background-color: #53544F;
          color: #fff;
          border-style: dashed;
        `
      } else {
        return css`
          background-color: #53544F;
          color: #fff;
          border: none;
        `
      }
    }
  }}
  ${props => {
    if (!props.isNight && props.isSelected) {
      return css`
        background-color: #e6f2fe;
        border-style: dashed;
      `
    }
  }}
`

const MoreButton = styled(IconButton) <{ isNight: number }>`
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  ${props => {
    if (props.isNight) {
      return css`
        color: #fff;
      `
    }
  }}
`

const ShiftNo = styled.div`
  font-size: 12px;
  height: 30px;
  line-height: 30px;
`

const NavIcons = styled.div`
  white-space: nowrap;
`

const NavIconButton = styled(IconButton) <{ isNight: number }>`
  color: #0A9688;
  ${props => {
    if (props.isNight) {
      return css`
        color: #fff;
      `
    }
  }}
`
const ShiftInfoRow = styled.div<{ isNight: number }>`
  min-height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
  color: #6E6E6E;
  

  word-break: break-all;
  word-wrap: break-word;

  ${props => {
    if (props.isNight) {
      return css`
        color: #F5F5F5;
      `
    }
  }}
`
const Status = styled.div<{ isNight: number }>`
  border-radius: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  background-color: #0A9688;
  padding: 4px 10px;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => {
    if (props.isNight) {
      return css`
      color: #0A9688;
      background-color: #fff;
      `
    }
  }}
`
export default observer(LeaveNoteItem)