import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable from '@src/components/Form/PAETable'
import DRSReworkSummaryFields from '../../2-Rework/parts/ReworkSummaryFields'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { useDRS } from '@src/modules/DRS/useDRS'
import { formatProjectTypeId } from '@src/types/ProjectTypeId'
import { Crew } from '@src/types/Crew'
import { CrewFlat } from '@src/modules/DRS/legacy/Create/comps/f-JobCrew'

const Fields: FormTableField<any>[] = [
  {
    key: 'projectName',
    label: 'Project Name',
    type: FormTableFieldType.Text,
  },
  {
    key: 'projectNumber',
    label: 'Project Number',
    type: FormTableFieldType.Text,
  },
  {
    key: 'customerName',
    label: 'Customer',
    type: FormTableFieldType.Text,
  },
  {
    key: 'jobType',
    label: 'Job Type',
    type: FormTableFieldType.Text,
  },
  {
    key: 'crew',
    label: 'Crew',
    type: FormTableFieldType.Text,
    render: (cellData: any, row: any) => {
      if (cellData) {
        return <CrewFlat crew={cellData as Crew} /> 
      }
    }
  },
]

const PartDRSShiftDetails = () => {
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  if (drs === null) {
    return null
  }
  const { project, drsProjectType, date } = drs.jobDetails
  const { id: projectId, projectInternalId, name: projectName, customer, } = project
  const { suburb, streetName, kmPlantToDepot, nearestCrossStreet, siteAllowance, weather } = drs.jobDetails.plan
  const { shiftType, crew } = drs.jobDetails.shift.plan
  function getTableData() {
    // only one row of data
    return [{
      id: 1,
      projectName: projectName || '',
      projectNumber: (`${projectId || projectInternalId}`) || '',
      customerName: customer ? customer.name : '',
      jobType: formatProjectTypeId(drsProjectType.id),
      crew: crew,
    }]
  }
  return <Wrapper>
    <FormPanel title={`Shift Details`}>
      <FormPAETable<any>
        fields={Fields}
        data={getTableData()}
        showPAEType={false}
        showMoreActions={false}
      />
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default PartDRSShiftDetails