import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'

const STab: any = withStyles(theme => ({
  root: {
    fontSize: '24px',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '40px',
    width: 'auto',
    'minWidth': 'unset',
    height: '32px',
    lineHeight: '32px',
    textTransform: 'none',
    color: '#000000',
    // minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    '&:hover': {
      color: 'green',
      opacity: 1,
    },
    '&$selected': {
      color: '#009688',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#009688',
    },
  },
  selected: {},
} as any))(props => <Tab {...props} />)

export default STab