import React from 'react'
import styled from 'styled-components'

const ASCircle = () => {
  return <JobTypeCircle>
    As
  </JobTypeCircle>
}

export const JobTypeCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #129688;
  color: #fff;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
`

export default ASCircle