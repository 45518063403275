import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import _get from 'lodash/get'
import FieldCommentText from './f-CommentText'
import FieldEditableNumber from './f-EditableNumber'
import FieldEditableText from './f-EditableText'
import FieldText from './f-Text'
import TableCell from './base-TableCell'
import TableHeaderCell from './base-TableHeaderCell'
import FieldEditableTime from './f-EditableTime'
import { getEmptyTransportTable, getTransportPlan, getTransportRow } from '../StoreDRS'
import FieldSupplier from './f-Supplier'
import { HoursDiff } from './f-LabourMemberTable'

const FieldTransportTable = ({
  field,
  onChange,
}: {
  field: DRS.Field,
  onChange(val: any, options: any): void,
}) => {
  const { data, path, typeMeta } = field
  const { deliveryOrProfiling, asOrSs } = typeMeta!
  let totalText = ''
  if (asOrSs === 'as' && deliveryOrProfiling === 'delivery') {
    totalText = `Total Asphalt Delivery Tonnes`
  } else if (asOrSs === 'as' && deliveryOrProfiling === 'profiling') {
    totalText = `Total Asphalt Profiling Tonnes`
  } else if (asOrSs === 'ss' && deliveryOrProfiling === 'delivery') {
    totalText = `Total Spray Seal Delivery Tonnes`
  }
  const handleCellChange = (row: any, rowIndex: number, cellPath: string) => (val: any) => {
    onChange(val, {
      isCell: true,
      tablePath: path,
      rowIndex,
      cellPath,
      path: [path, rowIndex, cellPath].join('.'),
    })
  }
  const handleAddRow = () => {
    const emptyRow = getTransportRow({ asOrSs, deliveryOrProfiling })
    onChange([
      ...data, {
       ...emptyRow,
      }
    ], {
      isCell: false,
      isAdd: true,
      tablePath: path,
      path,
    })
  }
  const onRemove = (rowIndex: number) => () => {
    onChange([
      ...data.slice(0, rowIndex),
      ...data.slice(rowIndex + 1),
    ], {
      isCell: false,
      isAdd: false,
      isRemove: true,
      rowIndex,
      tablePath: path,
      path,
    })
  }
  return <Wrapper>
    <Content>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Supplier</TableHeaderCell>
            <TableHeaderCell>Start Time</TableHeaderCell>
            <TableHeaderCell>End Time</TableHeaderCell>
            <TableHeaderCell>Hours</TableHeaderCell>
            <TableHeaderCell>Weight(T)</TableHeaderCell>
            <TableHeaderCell>Docket Number</TableHeaderCell>
            <TableHeaderCell>Comment</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { data.map((row: any, rowIndex: number) => {
            const { assetDepot, mobileAssetNumber, assetDescription, hours } = row
            return <TableRow key={row._react_key_}>
              <TableCell>
                <FieldEditableText
                  onChange={handleCellChange(row, rowIndex, 'plan.supplierName')}
                  field={
                    {
                      name: 'Supplier Name',
                      id: `${path}.${rowIndex}.plan.supplierName`,
                      path: `${path}.${rowIndex}.plan.supplierName`,
                      data: row.plan.supplierName,
                    }
                  }
                />
              </TableCell>
              <TableCellMinWidth100 width='130'>
                <FieldEditableTime
                    onChange={handleCellChange(row, rowIndex, 'plan.startTime')}
                    field={
                      {
                        name: 'Start Time',
                        id: `${path}.${rowIndex}.plan.startTime`,
                        path: `${path}.${rowIndex}.plan.startTime`,
                        data: row.plan.startTime,
                      }
                    }
                  />
              </TableCellMinWidth100>
              <TableCellMinWidth100 width='130'>
                <FieldEditableTime
                    onChange={handleCellChange(row, rowIndex, 'plan.endTime')}
                    field={
                      {
                        name: 'End Time',
                        id: `${path}.${rowIndex}.plan.endTime`,
                        path: `${path}.${rowIndex}.plan.endTime`,
                        data: row.plan.endTime,
                      }
                    }
                  />
              </TableCellMinWidth100>
              <TableCell>
                <HoursDiff 
                  start={row.plan.startTime}
                  end={row.plan.endTime}
                />
                {/* <FieldText 
                  field={
                    {
                      data: row.plan.totalHours,
                    }
                  }
                /> */}
              </TableCell>
              <TableCell>
                <FieldEditableNumber 
                  onChange={handleCellChange(row, rowIndex, 'plan.orderedTonnesPerRound')}
                  field={
                    {
                      name: 'orderedTonnesPerRound',
                      id: `${path}.${rowIndex}.plan.orderedTonnesPerRound`,
                      path: `${path}.${rowIndex}.plan.orderedTonnesPerRound`,
                      data: row.plan.orderedTonnesPerRound,
                    }
                  }
                />
              </TableCell>
              <TableCell>
              <FieldEditableText 
                  onChange={handleCellChange(row, rowIndex, 'plan.docketNumber')}
                  field={
                    {
                      name: 'docket number',
                      id: `${path}.${rowIndex}.plan.docketNumber`,
                      path: `${path}.${rowIndex}.plan.docketNumber`,
                      data: row.plan.docketNumber,
                    }
                  }
                />
              </TableCell>
              <TableCell>
                <FieldCommentText
                  onChange={handleCellChange(row, rowIndex, 'plan.comments')}
                  field={
                    {
                      name: 'Comments',
                      id: `${path}.${rowIndex}.plan.comments`,
                      path: `${path}.${rowIndex}.plan.comments`,
                      data: row.plan.comments,
                    }
                  }
                />
              </TableCell>
              <TableCell>
                { rowIndex !== 0 && 
                  <IconButton onClick={onRemove(rowIndex)}>
                    <DeleteIcon /> 
                  </IconButton>
                }
              </TableCell>
            </TableRow>
          })}
          
        </TableBody>
      </Table>
      <AddButton 
        onClick={handleAddRow}
        >
          <StyledAddIcon />
          Add New
      </AddButton>
      <TotalText>
        {`${totalText}: ${data.reduce((acc: number, curr: any) => {
          return Number(curr.plan.orderedTonnesPerRound) + acc
        }, 0)}`}
      </TotalText>
    </Content>
  </Wrapper>
}

const Wrapper = styled.div``
const Content = styled.div`
  overflow-x: auto;
`
const AddButton = styled(Button)`
  color: #009688;
  text-transform: none;
  margin-left: 6px;
`
const StyledAddIcon = styled(AddIcon)`
  margin-right: 8px;
`
const TableCellMinWidth100 = styled(TableCell)`
  min-width: 130px;
  max-width: 130px;
`
const TotalText = styled(Typography)`
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  padding-left: 32px;
  color: #009688;
  margin-top: 40px;
`

export default observer(FieldTransportTable)