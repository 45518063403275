import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import moment from 'moment'
import { FieldName } from './Field'

const renderContent = (field: DRS.Field) => {
  return moment(field.data).format('DD/MM/YYYY')
}

const FieldDate = ({
  field,
}: DRS.Comp) => {
  return <Wrapper>
    <FieldName>Date</FieldName>
    <Content>
      {renderContent(field)}
    </Content>
  </Wrapper>
}

const Wrapper = styled.div``

const Content = styled.div``

export default FieldDate