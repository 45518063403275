import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  stateId?: string,
  regionId: string,
  depotId: string,
  active?: boolean,
}

export const apiMobileAssetsList = ({
  stateId,
  regionId,
  depotId,
  active,
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/mobileassets`,
    params: {
      state: stateId,
      region: regionId,
      depot: depotId,
      active,
    }
  }
  return ax(options)
}