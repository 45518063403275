import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '@src/components/FormModal/Modal'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName } from '@src/modules/DRS/DRSPage'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import useModal from '@src/components/FormModal/useModal'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { useDRS } from '@src/modules/DRS/useDRS'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import CompDRSAddDelayReasonsDialog from '../comps/AddDelayReasonDialog'
import { DelayReasonPAE, DelayReason } from '@src/types/drs/summary/DelayReason'
import { PAE } from '@src/types/drs/PAE'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'

const NULL_VALUES = {
  duration: null,
}

const DelayReasonFields: FormTableField<DelayReasonField>[] = [
  {
    key: 'delayReason',
    label: 'Delay Reason',
    type: FormTableFieldType.Readonly,
    format: (item: any) => item ? item.description : '',
  },
  {
    key: 'duration',
    label: 'Delay Duration',
    type: FormTableFieldType.Number,
  },
]
interface DelayReasonField {
  // id: string,
  duration: number,
  delayReason: DelayReason,
}
interface DelayReasonRow extends DelayReasonField {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

interface DelayReasonSpread {
  id: string,
  plan: {
      delayReason: DelayReason,
      duration: number,
  },
  actual: {
      delayReason: DelayReason,
      duration: number,
  },
  edit: {
      delayReason: DelayReason,
      duration: number,
  },
  drsSummaryId: number,
  delayReason: DelayReason,
}

interface Props {}
const CompDRSDelayReasons = (props: Props) => {
  const { id } = useParams<{id: string}>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<DelayReasonRow>({})
  const [ addDialogOpen, setAddDialogOpen] = useState(false)
  if (drs === null) {
    return null
  }
  const existingDelayReasons: DelayReason[] = drs.summary.detail.map((pae: DelayReasonPAE) => {
    return {
      ...pae.delayReason
    }
  })
  // don't show delay reason in RS's view
  if (isPlan) {
    return null
  }

  const handleAddDelayReason = (delayReason: DelayReason, duration: number) => {
    const data = [
      ...drs.summary.detail,
      {
        delayReason,
        [updatingColumn]: {
          duration,
        }
      }
    ]
    update(data)
    setAddDialogOpen(false)
  }
  const onSave = async (form: DelayReasonRow) => {
    updateItemByValues(form, form)
  }
  const handleRevert = async (form: DelayReasonRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  
  const updateItemByValues = (form: DelayReasonRow, values: any) => {
    const retainedDetails = drs.summary.detail
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const retainedItem = retainedDetails[updatingIndex]
    const data = [ 
      ...retainedDetails.slice(0, updatingIndex), 
      {
        ...retainedItem,
        [updatingColumn]: {
          ...values
        }
      },
      ...retainedDetails.slice(updatingIndex + 1),
    ]
    update(data)
  }
  async function update(data: any[]) {
    try {
      await updateSectionViaPath({
        sectionName: DRSSectionName.Summary,
        path: `detail`,
        data,
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const getTableData = () => {
    const delayReasons = drs.summary.detail
    if (!delayReasons || !delayReasons.length) {
      return []
    }
    // spread profile into PAE
    const spreadReasons = delayReasons.map((item: DelayReasonPAE) => {
      return {
        ...item,
        id: item.delayReason.id,
        plan: {
          ...item.plan,
          delayReason: item.delayReason,
        },
        actual: {
          ...item.actual,
          delayReason: item.delayReason,
        },
        edit: {
          ...item.edit,
          delayReason: item.delayReason,
        },
      }
    })

    const singled = spreadReasons.map((item: DelayReasonSpread, index: number) => {
      if (item.edit.duration === null) {
        return {
          ...item.actual,
          _key_: `${item.id} ${PAETableRowType.Second}`, 
          _type_: PAETableRowType.Second,
          _no_: index,
          _editable_: isEdit,
        }
      }
      return {
        ...item.edit,
        _key_: `${item.id} ${PAETableRowType.Second}`, 
        _type_: PAETableRowType.Second,
        _no_: index,
        _editable_: isEdit,
      }
    })
    const singledWithActions = singled.map((data: any) => mapMoreActions(data, drsPageType, [
      PAETableMoreActionType.Revert, 
    ]))
    return singledWithActions
  }

  return <Wrapper>
    <FormPanel title={`Delay Reason`}>
      <FormPAETable<DelayReasonRow>
        fields={DelayReasonFields}
        data={getTableData()}
        editable={true}
        showNo={true}
        onEditRow={(row: DelayReasonRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isEdit}
        onRevert={handleRevert}
      />
      { isEdit &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            setAddDialogOpen(true)
          }}>
            Add Delay Reason
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    { open && 
      <Modal<DelayReasonRow>
        maxWidth={'xs'}
        open={open}
        title='Delay Reason'
        data={{
          rowData: modalData,
          fields: DelayReasonFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'delayReason',
                'duration',
              ],
            },
          ]
        }}
        onSuccess={onSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
    { addDialogOpen && 
      <CompDRSAddDelayReasonsDialog 
        existingDelayReasons={existingDelayReasons}
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSave={handleAddDelayReason}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSDelayReasons
