import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {

  serialId: number,
  assetId: string,
  questionId: number,
  answer: boolean

}
export const apiMapResolveIssue = ({
  serialId,
  assetId,
  questionId,
  answer

}: Options) => {
  const data = {
    assetId,
    questionId,
    answer
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/map/checklist`,
    data,
    params: {
      
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(options)
}