import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useHistory, useLocation} from 'react-router'
import Container from '@material-ui/core/Container'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormCard from '@src/components/AIMS-Form/Card'
import FormCardRow from '@src/components/AIMS-Form/CardRow'
import { apiShiftGetOne } from '@src/apis/shift-get-one'
import { useAppStore } from '@src/AppStore'
import { Shift } from '@src/types/Shift'
import { apiShiftGetPack } from '@src/apis/shift-get-pack'
import { apiShiftGetQAS } from '@src/apis/shift-get-qas'
import { DRSFormCard } from '@src/types/DRSFormCard'
import { apiShiftGetSMI } from '@src/apis/shift-get-smi'
import DRSRedirectConfirmDialog from './comps/DRSRedirectConfirmDialog'
import Breadcrumbs from '@src/components/Breadcrumbs'
import { formatDRSStatus } from '@src/types/drs/DRSStatus'
import { formatHSEStatus } from '@src/types/hse/HSEStatus'
import { formatQASStatus } from '@src/types/qas/QASStatus'
import { formatSMIStatus } from '@src/types/smi/SMIStatus'
import usePermission from '@src/utils/hooks/usePermission'
import AttachSifDialog from './comps/AttachSifDialog'
import CopyVMPMapDialog from './comps/CopyVMPMapDialog'
import AttachSifTrigger from './comps/AttachSifTrigger'
import CrewPackDetailInfo from './parts/DetailInfo'
import moment from 'moment'
import { SIFFormCard } from '@src/types/SIFFormCard'
import { PackFormType } from '@src/types/PackFormType'
import { formatSifStatus } from '@src/types/SifStatus'
import { apiSifUninkCrewPack } from '@src/apis/sif-unlink-crew-pack'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { SRRFormCard } from '@src/types/SRRFormCard'
import { formatSRRStatus } from '@src/types/SRRStatus'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { MAPFormCard } from '@src/types/MAPFormCard'
import { HSEFormCard } from '@src/types/HSEFormCard'
import { QASFormCard } from '@src/types/QASFormCard'
import { apiHSEGetPdf } from '@src/apis/hse-get-pdf'
import { apiQASGetPdf } from '@src/apis/qas-get-pdf'
import { SMIFormCard } from '@src/types/SMIFormCard'
import { apiSMIGetPdf } from '@src/apis/smi-get-pdf'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { VMPFormCard } from '@src/types/VMPFormCard'
import { formatVMPStatusId } from '@src/types/VMPStatusId'
import { CircularProgress } from '@material-ui/core'
import DRSSectionComment from '../DRS/parts/3-Material/parts/DRSSectionComment'
import Grid from '@material-ui/core/Grid'
import Tabs from './parts/Tabs'
import { BASE_API_URL } from '@src/constants'
import { getCookieIdToken } from '@src/utils/cookies'
import Axios from 'axios'
const Format = 'DD/MM/YYYY'

const ProjectCrewPackPage = () => {
  const { hash } = useLocation()
  const { id } = useParams<{ id: string }>()
  const { serialId, defaultRoleId } = useAppStore()
  const [shift, setShift] = useState<Shift>()
  const [drsFormCard, setDrsFormCard] = useState<DRSFormCard | undefined>()
  const [sifFormCard, setSifFormCard] = useState<SIFFormCard | undefined>()
  const [srrFormCard, setSrrFormCard] = useState<SRRFormCard | undefined>()
  const [mapFormCard, setMAPFormCard] = useState<MAPFormCard | undefined>()
  const [hseFormCard, setHSEFormCard] = useState<HSEFormCard | undefined>()
  const [vmpFormCard, setVMPFormCard] = useState<VMPFormCard | undefined>()
  const [crewpackFormCard, setCrewpackFormCard] = useState<SMIFormCard | undefined>()
  const [crewpackFormCardPDF, setCrewpackFormCardPDF] = useState<SMIFormCard | undefined>()
  const [qasFormCards, setQASFormCards] = useState<QASFormCard[] | undefined>()
  const [drsDialogOpen, setDrsDialogOpen] = useState<boolean>(false)
  const [sifDialogOpen, setSifDialogOpen] = useState<boolean>(false)
  const [vmpDialogOpen, setVmpDialogOpen] = useState<boolean>(false)
  const [totalQAS, setTotalQAS] = useState<number>(0)
  const [currentQASIndex, setCurrentQASIndex] = useState<number>(0)
  const [smiFormCards, setSMIFormCards] = useState<SMIFormCard[] | undefined>()
  const [totalSMI, setTotalSMI] = useState<number>(0)
  const [currentSMIIndex, setCurrentSMIIndex] = useState<number>(0)
  const { canPlanDRS, canEditDRS, canReviewDRS, canLinkSif, canUnlinkSif, canDownloadQASPdf, canDownloadSMIPdf } = usePermission()
  const [refreshToken, setRefreshToken] = useState<number>(1)
  const history = useHistory()
  const { confirm } = useConfirm()
  const { alert, alertDone, alertError } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0)
  const [tabIndex, setTabIndex] = useState<any>(0)
  const handleOnUpdated = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }
  useEffect(() => {
    const fetch = async () => {
      const result = await apiShiftGetOne({
        shiftId: id,
        serialId,
      })
      setShift(result.data.shift)
    }

    fetch()
  }, [])
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1);
      if(trimmedHash == 'shiftForms'){
        setTabIndex(2);
      }
      if(trimmedHash == 'documents'){
        setTabIndex(1);
      }
      
     
    }
   
  }, [id])
  useEffect(() => {
    const fetchPack = async () => {
      try {
        const result = await apiShiftGetPack({
          shiftId: id,
          serialId,
        })
        // dispatching forms to drs, sif ... and all other available forms
        if (result.data && result.data.forms) {
          setDrsFormCard(undefined)
          setSifFormCard(undefined)
          setMAPFormCard({ id: 1, formType: PackFormType.MOBILITY_ASSET_PRESTARTS })
          console.log(`result.data.forms`);
          console.log(result.data.forms);
          console.log(`result.data.forms`);
          result.data.forms.forEach((form: any) => {
          


            switch (form.formType) {
              case PackFormType.DAILY_REPORTY_SHEET:
                setDrsFormCard(form)
                return
              case PackFormType.SITE_INSPECTION_FORM:
                setSifFormCard(form)
                return
              case PackFormType.SHIFT_RESOURCE_REQUIREMENT:
                setSrrFormCard(form)
                return
              case PackFormType.HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION:
                //console.log(`CrewPackDetailPage HSEform=${JSON.stringify(form, null, 2)}`)
                setHSEFormCard(form)
                return
              case PackFormType.VEHICLE_MOVEMENT_PLAN:
                setVMPFormCard(form)
                return
                case 'crewPackPhoto':
                  console.log(`crewPackPhoto`);
                  console.log(form);
                  console.log(`crewPackPhoto`);
                  setCrewpackFormCard(form)
                  return
                  case 'crewPackPdfs':
                    console.log(`crewPackPdfs`);
                    console.log(form);
                    console.log(`crewPackPdfs`);
                    setCrewpackFormCardPDF(form)
                    return
            }
          })
        }
      } catch (e) {

      }
    }
    fetchPack()
  }, [refreshToken])

  useEffect(() => {
    const fetchQAS = async () => {
      try {
        const result = await apiShiftGetQAS({
          shiftId: id,
          serialId,
        })
        if (result.data) {
          const forms: any[] = result.data.map((qas: any) => {
            return {
              id: qas.id,
              "status": {
                "id": qas.status,
                "description": qas.status
              },
              "formType": "qas"
            }
          })

          setTotalQAS(forms.length)
          setQASFormCards(forms)
        }
      } catch (e) {

      }
    }
    fetchQAS()
  }, [refreshToken])

  useEffect(() => {
    const fetchSMI = async () => {
      try {
        const result = await apiShiftGetSMI({
          shiftId: id,
          serialId,
        })
        if (result.data) {
          const forms: any[] = result.data.map((smi: any) => {
            return {
              id: smi.siteMemoId,
              "status": smi.status,
              "formType": "smi"
            }
          })
          setTotalSMI(forms.length)
          setSMIFormCards(forms)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchSMI()
  }, [refreshToken])

  if (!shift) {
    return null
  }
  const { date, drsProjectType , projectId, projectName, projectInternalId, customerName } = shift
  const { crew, shiftType } = shift.plan
 

  // const handleOnUpdated = () => {
  //   setRefreshTrigger(refreshTrigger + 1)
  // }

  const handleDRSClick = () => {
    const allowActionCount = [canPlanDRS, canEditDRS, canReviewDRS].filter((item: boolean) => item).length
    if (allowActionCount > 1) {
      setDrsDialogOpen(true)
    } else if (allowActionCount === 0) {

    } else {
      if (canPlanDRS) {
        window.open(`#/drs/plan/${drsFormCard!.id}`, '_blank')
        return
      }
      if (canEditDRS) {
        window.open(`#/drs/edit/${drsFormCard!.id}`, '_blank')
        return
      }
      if (canReviewDRS) {
        window.open(`#/drs/review/${drsFormCard!.id}`, '_blank')
        return
      }
    }
  }
  const handleSIFClick = () => {
    if (!sifFormCard || !sifFormCard.id) {
      return
    }
    history.push({
      pathname: `/sif/${sifFormCard.id}`,
    })
  }
  const handleUnlinkSIF = async (action: string) => {
    if (!sifFormCard || !sifFormCard.id) {
      return
    }
    try {
      await confirm({
        title: 'Confirm if you want to unlink SIF from crew pack',
        message: `Unlinking SIF will remove SIF ${sifFormCard.id} from the crew pack for shift on ${formatDateStringDDMMYYYY(shift.date)}`,
        confirmButtonText: 'Yes, unlink SIF',
      })
      try {
        const result = await apiSifUninkCrewPack({
          sifId: sifFormCard.id,
          shiftId: shift.id,
          serialId,
        })
        setRefreshToken(refreshToken + 1)
      } catch (e) {

      }
    } catch (e) {

    }
  }
  const handleAddSif = () => {
    setSifDialogOpen(true)
  }
  const handleViewSifPhotos = () => {
    console.log('in photos views');
  }
  const handleHSEClick = async () => {
    if (!hseFormCard || loading) {
      return
    }
    try {
      setLoading(true)
      alert({
        type: 'info',
        message: 'Processing pdf...'
      })
      await handlePdfDownloadConfirmed(hseFormCard.id,defaultRoleId);
     /*   const result = await apiHSEGetPdf({
        id: hseFormCard.id,
        serialId,
        roleId: defaultRoleId,
      })

      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `HSE-${hseFormCard.id}.pdf`)
      document.body.appendChild(link)
      link.click() */
    
    } catch (e) {
      alertError()
      setLoading(false)
    }
  }
  
  const handlePdfDownloadConfirmed = async (id: any,roleId: any) => {
    if (!id) {
      return
    }
    setLoading(true)
    try {
      const result = await Axios({
    
        method: 'GET',
        //responseType: 'blob',
        headers: {
          // Accept: 'application/pdf',
          Accept: 'application/json',
          'Authorization': getCookieIdToken(),
        },
        url: `${BASE_API_URL}/pdf/hse/${id}`,
    params: {
      reqSid: serialId,
      reqRole: roleId,
    },
      })
      // console.log(`result`);
      // console.log(result);
      // console.log(`result`);
      if (typeof result != "undefined"){
        if(typeof result.data.url!="undefined") {
          setTimeout(async function(){
            await downloadPDFS3(result.data.url,id);
          },5000)
         

        }else{
          setTimeout(async function(){
            await downloadPDFS3(result.data,id);
          });
        }
        
      }else {
        setLoading(false)
      }


    
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadPDFS3 = async (pdfName: string,id: any) => {
    if (!pdfName) {
      return
    }
    setLoading(true);
    try {
      alert({
        type: 'info',
        message: 'Downloading pdf...'
      })
      const result = await Axios({
        url:  pdfName,
        method: 'GET',
        responseType: 'blob',
        headers: {
			Accept: 'application/pdf'
        },
      })
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.target = "_blank";

      link.setAttribute('download', "HSE-"+id+".pdf")
      document.body.appendChild(link)
      link.click()
      setLoading(false)
      alertDone()
    } catch (e) {
      console.log(e)
      setLoading(false)
    }

  }

  const handleQASClick = async () => {
    if (!qasFormCards || !canDownloadQASPdf || loading) {
      return
    }

    if (qasFormCards[currentQASIndex].status.id !== "completed") {
      alert({
        type: 'warning',
        message: `QAS Pdf can not be downloaded before it's status is Completed`,
      })
      return
    }

    try {
      setLoading(true)
      alert({
        type: 'info',
        message: 'Downloading pdf...'
      })
      const result = await apiQASGetPdf({
        id: qasFormCards[currentQASIndex].id,
        serialId,
        roleId: defaultRoleId,
      })

      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `QAS-${qasFormCards[currentQASIndex].id}.pdf`)
      document.body.appendChild(link)
      link.click()
      alertDone()
      setLoading(false)

    } catch (e) {
      alertError()
      setLoading(false)
    }
  }
  const handleSMIClick = async () => {
    if (!smiFormCards || !canDownloadSMIPdf || loading) {
      return
    }

    // if (smiFormCards[currentSMIIndex].status.id !== 2) {
    //   alert({
    //     type: 'warning',
    //     message: `SMI Pdf can not be downloaded before it's status is Completed`,
    //   })
    //   return
    // }

    try {
      setLoading(true)
      alert({
        type: 'info',
        message: 'Downloading pdf...'
      })
      const result = await apiSMIGetPdf({
        id: smiFormCards[currentSMIIndex].id,
        serialId,
        roleId: defaultRoleId,
      })

      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `SMI-${smiFormCards[currentSMIIndex].id}.pdf`)
      document.body.appendChild(link)
      link.click()
      alertDone()
      setLoading(false)

    } catch (e) {
      alertError()
      setLoading(false)
    }
  }
  const handleOpenVMPDialog = () => {
    setVmpDialogOpen(true)
  }

  const handleTabClick = async (label: string, index: number) => {
   // if(possiblePartValues.indexOf(part) != index) {
      setTabIndex(index);
   // }
  }
  const renderParts = () => {
   
    return [ <Section>
      {loading && <SpinnerWrapper><Spinner size={30} /></SpinnerWrapper>}
      <DRSSectionComment shiftID={shift.id} onUpdated={handleOnUpdated} />
    </Section>, 
  <FormPanel title=''>
  <FormCardRow>
    {sifFormCard &&
      <FormCard
        person={sifFormCard.supervisor ? `${sifFormCard.supervisor.firstName} ${sifFormCard.supervisor.lastName}` : ''}
        attachment={`${sifFormCard.numFiles}`}
        images={`${sifFormCard.numImages}`}
        status={formatSifStatus(sifFormCard.status.id)}
        formType={sifFormCard.formType}
        onClick={handleSIFClick}
        action={canUnlinkSif ? 'Unlink SIF' : undefined}
        onAction={handleUnlinkSIF}
      />
    }
    {canLinkSif && !sifFormCard &&
      <AttachSifTrigger onClick={handleAddSif} />
    }

    {srrFormCard && <FormCard
      person={formatProfileOrNullFirstLastName(srrFormCard.srrUpdatedBy)}
      status={formatSRRStatus(srrFormCard.status.id)}
      images={''}
      attachment={''}
      formType={PackFormType.SHIFT_RESOURCE_REQUIREMENT}
      onClick={() => {
        history.push({
          pathname: `/srr/${srrFormCard.id}`
        })
      }}
    />
    }

    {sifDialogOpen &&
      <AttachSifDialog
        data={{
          project: {
            projectInternalId: projectInternalId,
            id: projectId,
            name: projectName,
          },
          shift: shift,
        }}
        open={sifDialogOpen}
        onCancel={() => setSifDialogOpen(false)}
        onClose={() => setSifDialogOpen(false)}
        onSuccess={() => {
          setSifDialogOpen(false)
          setRefreshToken(refreshToken + 1)
        }}
      />
    }
    {smiFormCards &&
      <FormCard
        person={''}
        status={''}
        images={''}
        attachment={''} 
        pdfCounter={crewpackFormCardPDF  ? (crewpackFormCardPDF.crewPackNumberOfPdfs > 0 ? crewpackFormCardPDF.crewPackNumberOfPdfs.toString() : '') : ''}
        camCounter={crewpackFormCard  ? (crewpackFormCard.crewPackNumberOfPhotos > 0 ? crewpackFormCard.crewPackNumberOfPhotos.toString() : '') : ''}
        formType={PackFormType.CREW_PACK_PHOTO}
        onClick={() => {
          history.push({
            pathname: `/crewpack/photos/${id}/${projectInternalId}`
          })
        }}
      />
    }

  </FormCardRow>
</FormPanel>,
  <FormPanel title=''>
  <FormCardRow>
    {drsFormCard &&
      <FormCard
        person={drsFormCard.foreman ? `${drsFormCard.foreman.firstName} ${drsFormCard.foreman.lastName}` : ''}
        attachment={`${drsFormCard.numFiles}`}
        images={`${drsFormCard.numImages}`}
        status={formatDRSStatus(drsFormCard.status.id)}
        formType={drsFormCard.formType}
        onClick={handleDRSClick}
      />
    }
    {mapFormCard && <FormCard
      person={''}
      status={''}
      images={''}
      attachment={''}
      formType={PackFormType.MOBILITY_ASSET_PRESTARTS}
      onClick={() => {
        history.push({
          pathname: `/map-list/${id}`
        })
      }}
    />
    }
    {hseFormCard && <FormCard
      person={''}
      status={formatHSEStatus(hseFormCard.status.id)}
      images={''}
      attachment={''}
      formType={PackFormType.HEALTH_SAFETY_ENVIRONMENT_SITE_INDUCTION}
      onClick={handleHSEClick}
    />
    }
    {vmpFormCard && <FormCard
      person={''}
      status={formatVMPStatusId(vmpFormCard.status.id)}
      images={''}
      attachment={''}
      formType={PackFormType.VEHICLE_MOVEMENT_PLAN}
      onClick={() => {
        window.open(`#/vmp/${vmpFormCard!.id}`, '_blank')
      }}
      action={canEditDRS || canPlanDRS ? 'Copy VMP Map' : undefined}
      onAction={handleOpenVMPDialog}
    />
    }
       {<FormCard
      person={''}
      status={''}
      images={''}
      attachment={''}
      formType={PackFormType.ATP_CARD}
      onClick={() => {
        window.open(`#/approval/${drsFormCard!.id}/${id}`, '_blank')
      }}
     
     
    />
    }
    {qasFormCards && qasFormCards.length > 0 && <FormCard
      person={''}
      status={formatQASStatus(qasFormCards[currentQASIndex].status.id)}
      images={''}
      attachment={''}
      total={totalQAS}
      currentIndex={currentQASIndex}
      formType={PackFormType.QUANTITY_AGREEMENT_SHEET}
      onClick={handleQASClick}
      onPrevious={() => setCurrentQASIndex(currentQASIndex - 1 === -1 ? totalQAS - 1 : currentQASIndex - 1)}
      onNext={() => setCurrentQASIndex(currentQASIndex + 1 === totalQAS ? 0 : currentQASIndex + 1)}
    />
    }
    {smiFormCards && smiFormCards.length > 0 && <FormCard
      person={''}
      // status={formatSMIStatus(smiFormCards[currentSMIIndex].status.id)}
      status={formatSMIStatus(2)}
      images={''}
      attachment={''}
      total={totalSMI}
      currentIndex={currentSMIIndex}
      formType={PackFormType.SITE_MEMO_INSTRUCTION}
      onClick={handleSMIClick}
      onPrevious={() => setCurrentSMIIndex(currentSMIIndex - 1 === -1 ? totalSMI - 1 : currentSMIIndex - 1)}
      onNext={() => setCurrentSMIIndex(currentSMIIndex + 1 === totalSMI ? 0 : currentSMIIndex + 1)}
    />}
    {vmpDialogOpen && vmpFormCard &&
      <CopyVMPMapDialog
        data={{
          project: {
            projectInternalId: projectInternalId,
            id: projectId,
            name: projectName,
          },
          shift: shift,
          vmpFormCard: vmpFormCard
        }}
        open={vmpDialogOpen}
        onCancel={() => setVmpDialogOpen(false)}
        onClose={() => setVmpDialogOpen(false)}
        onSuccess={() => {
          setVmpDialogOpen(false)
          setRefreshToken(refreshToken + 1)
        }}
      />
    }
  </FormCardRow>
  {drsFormCard && drsDialogOpen && <DRSRedirectConfirmDialog
    data={drsFormCard.id}
    open={drsDialogOpen}
    onCancel={() => setDrsDialogOpen(false)}
    onClose={() => setDrsDialogOpen(false)}
  />
  }
</FormPanel>
  ][tabIndex]
  }
  return <Wrapper>
    <TopPart maxWidth='xl'>
      <Breadcrumbs items={[
        { type: 'link' as const, url: `/project/${projectInternalId}`, text: `Project ${projectId || projectInternalId}` },
        { type: 'text' as const, text: `Shift ${moment(date).format(Format)}`, active: true, }
      ]} />
      {/* large chunk of info on top */}
      <CrewPackDetailInfo shift={shift} />
    </TopPart>
    <FormPart maxWidth='xl'>

    <TabsWrapper>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Tabs
              labels={['Shift Notes', 'Documents','Shift Forms']}
              value={tabIndex}
              onClick={handleTabClick}
            />
          </Grid>
          <Grid item xs={7}>
           
          </Grid>
        </Grid>

        <PartsWrapper>
          {renderParts()}
        </PartsWrapper>
      </TabsWrapper>
   </FormPart>
  </Wrapper>
}

const Wrapper = styled.div`
`
const TopPart = styled(Container)`
  position: relative;
  background-color: #E7F4F2;
  color: #707070;
  padding-bottom: 12px;
`
const FormPart = styled(Container)`
  padding-top: 40px;
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`
const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`
const TabsWrapper = styled.div`
`
const PartsWrapper = styled.div`
  /* margin-top: 40px; */
`
export default ProjectCrewPackPage