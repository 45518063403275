import React, { useContext } from 'react'
import { observable, action } from 'mobx'
import { DRS } from '@src/types/drs/DRS'
import { DRSPageType } from './DRSPage'
import { Attachment } from '@src/types/Attachment'

interface DRSStore {
  id: number | null,
  setId(id: number): void,

  drsPageType: DRSPageType,
  setDRSPageType(type: DRSPageType): void,

  drs: DRS | null,
  setDRS(drs: DRS | null): void,

  attachments: Attachment[],
  setAttachments(attachments: Attachment[]): void,
}

export const store: DRSStore = observable<DRSStore>({
  id: null,
  setId: action((id: number) => store.id = id),

  drsPageType: 0,
  setDRSPageType: action((type: DRSPageType) => {
    store.drsPageType = type
  }),

  drs: null,
  setDRS: action((drs: DRS | null) => {
    store.drs = drs
  }),

  attachments: [],
  setAttachments: action((attachments: Attachment[]) => store.attachments = attachments)
})

const context: React.Context<DRSStore> = React.createContext<DRSStore>(store)

export const useDRSStore = () => {
  return useContext<DRSStore>(context)
}