import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DownshiftSelect } from '../../components'
import { useHistory, useLocation } from 'react-router-dom'


const OPTIONS = [
  { value: 'labour', label: 'Labour' },
  { value: 'mobile-asset', label: 'Mobile Assets' },
  { value: 'crew', label: 'Crew' },
]

function MgmtLinkSelect ({
}) {
  const history = useHistory()
  const { pathname } = useLocation()
  const pathMapping: { [key: string]: string } = {
    '/mgmt/labour': 'labour',
    '/mgmt/mobile-asset': 'mobile-asset',
    '/mgmt/crew': 'crew',
  }
  const currLink = pathMapping[pathname]

  const selectedItem = OPTIONS.find(option => option.value === currLink)

  const handleChange = (value: string) => {
    if (value !== currLink) {
      const targetPathname = Object.keys(pathMapping).find(key => {
        return pathMapping[key] === value
      })
      history.push({
        pathname: targetPathname
      })
    }
  }
  return (
    <DownshiftSelect
      color='boral'
      options={OPTIONS} 
      value={selectedItem} 
      onChange={handleChange}
      displayAsText={true} >
    </DownshiftSelect>
  )
}

MgmtLinkSelect.propTypes = {
  onChange: PropTypes.func,
}

MgmtLinkSelect.defaultProps = {
  onChange: () => {},
}

export default observer(MgmtLinkSelect)