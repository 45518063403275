import { DRSCrewMembersFieldsRow } from '../parts/CrewMembers'
import { adjustDateTimeToDate } from '../../6-Subcontractors/utils/adjustDateTimeToDate'
import { adjustEndTimeToStartTime } from '../../6-Subcontractors/utils/adjustEndTimeToStartTime'

export function adjustLabourStartEndTime(form: DRSCrewMembersFieldsRow, shiftDate: string) {
  let finalStartTime = adjustDateTimeToDate(form.startTime, shiftDate)
  let finalEndTime = adjustEndTimeToStartTime(finalStartTime, form.endTime, shiftDate)
  return {
    ...form,
    startTime: finalStartTime,
    endTime: finalEndTime,
  }
}