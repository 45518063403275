import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TimePicker from '../TimePicker'
import moment, { Moment } from 'moment'
import DatePicker from '../DatePicker'

interface Props {
  label: string,
  value: string,
  onChange(isoString: string): void,
}

export const FormModalDatePickerFormat = 'YYYY/MM/DD'

const FormModalDatePicker = (props: Props) => {
  const {
    label,
    value,
    onChange,
  } = props
  
  const handleChange = (time: Moment) => {
    onChange(time.format(FormModalDatePickerFormat))
  }
  return <FormControl component='fieldset'>
    <FormLabel component='legend'>{label}</FormLabel>
    <DatePicker 
      value={value ? moment(value, FormModalDatePickerFormat) : null}
      onChange={handleChange}
    />
  </FormControl>
}

const Wrapper = styled.div`

`

export default FormModalDatePicker