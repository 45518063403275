import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import CrewSummaryFields, { CrewSummaryRowDisplay } from './CrewSummaryFields'
import { DRSLabourSummaryPAE, DRSCrewMemberPAE } from '@src/types/drs/labour/Labour'
import { getGeneralCrewHours, getForemanHours, getSupervisorHours, getTotalHours } from '../utils/getHours'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'

interface CrewSummaryRow extends CrewSummaryRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const CompDRSCrewSummary = () => {
  const { id } = useParams<{ id: string }>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<CrewSummaryRow>({})
  if (drs === null) {
    return null
  }
  async function update(data: any) {
    try {
      await updateSectionViaPath({
        sectionName: DRSSectionName.Labour,
        path: `summary`,
        data,
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const handleSave = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        pmHours: form.pmHours,
      }
    }
    update(data)
  }
  const handleRevert = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        pmHours: null,
      }
    }
    update(data)
  }
  const handleMarkZero = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        pmHours: '0',
      }
    }
    update(data)
  }
  const getTableData = () => {
    const members: DRSCrewMemberPAE[] = drs.labour.detail
    const rows = [drs.labour.summary]
    if (!rows || !rows.length) {
      return []
    }
    const finalRows = rows.map((item: DRSLabourSummaryPAE) => {
      const secondRowMembers = members
        .map((member: DRSCrewMemberPAE) => {
          if (member.edit.available) {
            return member
          }
          return {
            ...member,
            edit: member.actual, // actual be the second row
          }
        })
      return {
        ...item,
        plan: {
          ...item.plan,
          totalCrewCount: members.length,
          crewHours: getGeneralCrewHours(members, PAE.Plan),
          foremanHours: getForemanHours(members, PAE.Plan),
          supervisorHours: getSupervisorHours(members, PAE.Plan),
          totalHours: getTotalHours(members, PAE.Plan, item.plan.pmHours),
        },
        actual: {
        },
        edit: {
          ...item.edit,
          totalCrewCount: members.length,
          crewHours: getGeneralCrewHours(secondRowMembers, PAE.Edit),
          foremanHours: getForemanHours(secondRowMembers, PAE.Edit),
          supervisorHours: getSupervisorHours(secondRowMembers, PAE.Edit),
          totalHours: getTotalHours(secondRowMembers, PAE.Edit, item.edit.pmHours),
        },
      }
    })
    const doubled = doubleUp(finalRows, drsPageType, () => true) // always show edit which is merged values
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`Crew Summary`}>
      <FormPAETable<CrewSummaryRow>
        fields={CrewSummaryFields}
        data={getTableData()}
        editable={true}
        isSelectable={true}
        onEditRow={(row: CrewSummaryRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
      />
    </FormPanel>

    {open &&
      <Modal<CrewSummaryRow>
        maxWidth={'sm'}
        open={open}
        title='Crew Summary'
        data={{
          rowData: modalData,
          fields: CrewSummaryFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'crewHours',
                'foremanHours',
                'supervisorHours',
                'pmHours',
              ],
            },
            {
              title: 'Totals',
              fieldKeys: [
                'totalCrewCount',
                'totalHours',
              ],
            },
          ],
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSCrewSummary