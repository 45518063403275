/* 
  Make one PAE item into two rows, which:
  - has plan row as the first row
  - has actual or edit row as the second row
  - whether or not to show the second row only when planning
  - plan or actual's editability depends on drsPageType which is plan/edit/review
*/
import { PAEDataWithId } from '@src/types/drs/PAE'
import { DRSPageType } from '../DRSPage'
import { PAETableRowType, FormPAETableInteralData } from '@src/components/Form/PAETable'

export function doubleUp<T extends PAEDataWithId>(
  array: T[], 
  drsPageType: DRSPageType,
  isEditAvailableFunc?: (actual: any, edit: any) => boolean,
): any[] {
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const isReview = drsPageType === DRSPageType.Review
  return array.reduce<(T | FormPAETableInteralData)[]>((acc, curr, currIndex) => {
    acc.push({
      ...curr.plan,
      _key_: `${curr.id} ${PAETableRowType.First}`, 
      _type_: PAETableRowType.First,
      _no_: currIndex,
      _editable_: isPlan,
    })
    if (!isPlan) {
      let finalSecondRow = curr.edit.available ? curr.edit : curr.actual
      if (isEditAvailableFunc && typeof isEditAvailableFunc === 'function') {
        finalSecondRow = isEditAvailableFunc(curr.actual, curr.edit) ? curr.edit : curr.actual
      }
      acc.push({
        ...finalSecondRow,
        _key_: `${curr.id} ${PAETableRowType.Second}`, 
        _type_: PAETableRowType.Second,
        _no_: currIndex,
        _editable_: isEdit,
      })
    }
    return acc
  }, [])
}

export function doubleUpNormal<T extends PAEDataWithId>(
  array: T[], 
  
  isEditAvailableFunc?: (actual: any, edit: any) => boolean,
): any[] {
 
  return array.reduce<(T | FormPAETableInteralData)[]>((acc, curr, currIndex) => {
    acc.push({
      ...curr,
      _key_: `${curr.id} ${PAETableRowType.First}`, 
      _type_: PAETableRowType.First,
      _no_: currIndex,
      _editable_: true,
    })
  
    return acc
  }, [])
}