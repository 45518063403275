import { PartialBy } from '@src/types/utils/PartialBy'

export type DrawingElement = ArrowElement | RectangleElement | TextBoxElement
export function isDrawingElement(element: null | DrawingElement): element is DrawingElement {
  return element !== null
}
type ArrowElementProperties = 
  Pick<DrawingElementProperties, 'fromX' | 'fromY' | 'toX' | 'toY' | 'stroke'>

export interface ArrowElement extends Pick<DrawingElementProps, 'id' | 'type'> {
  properties: PartialBy<ArrowElementProperties, 'stroke'>
}

export function isArrowElement(element: null | DrawingElement): element is ArrowElement {
  if (!isDrawingElement(element) || element.type !== DrawingElementType.Arrow) {
    return false
  }
  return true
}

type RectangleElementProperties = 
  Pick<DrawingElementProperties, 'fromX' | 'fromY' | 'toX' | 'toY' | 'stroke' | 'rotation'>

export interface RectangleElement extends Pick<DrawingElementProps, 'id' | 'type'> {
  properties: PartialBy<RectangleElementProperties, 'stroke' | 'rotation'>
}

export function isRectangleElement(element: null | DrawingElement): element is RectangleElement {
  if (!isDrawingElement(element) || element.type !== DrawingElementType.Rectangle) {
    return false
  }
  return true
}

type TextBoxElementProperties = 
  Pick<DrawingElementProperties, 'fromX' | 'fromY' | 'toX' | 'toY' | 'stroke' | 'text'>

export interface TextBoxElement extends Pick<DrawingElementProps, 'id' | 'type'> {
  properties: PartialBy<TextBoxElementProperties, 'stroke'>
}

export function isTextBoxElement(element: null | DrawingElement): element is TextBoxElement {
  if (!isDrawingElement(element) || element.type !== DrawingElementType.TextBox) {
    return false
  }
  return true
}


export interface DrawingElementProperties {
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
  text: string,
  fromX: number,
  fromY: number,
  toX: number,
  toY: number,
  stroke: string, // #56c3e4
}

interface DrawingElementProps {
  id: string,
  type: DrawingElementType,
  properties: DrawingElementProperties, 
}
export enum DrawingElementType {
  Rectangle,
  Arrow,
  TextBox,
}