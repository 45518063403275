

import { ADIDNameToValueLabel } from '@src/api-adaptors/id-name-to-value-label'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { getAsphProcess } from '@src/modules/DRS/legacy/Edit/Contants'
import { SectionAsphaltProduct } from '@src/types/SectionAsphaltProduct'
import { SectionAsphaltItem } from '@src/types/drs/material/SectionAsphaltItem'

export interface SectionAsphaltItemRowDisplay extends SectionAsphaltItem {

}

const SectionAsphaltItemFields: FormTableField<SectionAsphaltItemRowDisplay>[] = [
  {
    key: 'asphaltLayer',
    type: FormTableFieldType.Select,
    label: 'Asphalt Layer',
    format: (cellData: SectionAsphaltProduct['asphaltLayer']) => {
      if (cellData) {
        return cellData
      }
    },
  },
  {
    id: 'supplyPlant',
    key: 'supplyPlant',
    type: FormTableFieldType.Autocomplete,
    label: 'Supply Plant',
    format: (cellData: SectionAsphaltProduct['plant']) => {
      if (cellData) {
        return cellData.name
      }
    },
  },
  {
    key: 'product',
    type: FormTableFieldType.Autocomplete,
    label: 'Product',
    format: (cellData: SectionAsphaltProduct['product']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    key: 'asphProcess',
    type: FormTableFieldType.Select,
    label: 'Process',
    options: getAsphProcess().map(item => ADIDNameToValueLabel(item)),
    format: (cellData: SectionAsphaltProduct['asphProcess']) => {
      if (cellData) {
        return cellData
      }
    },
  },
  

  {
    key: 'layerThickness',
    type: FormTableFieldType.Number,
    label: 'Layer Thickness',
   
  },
  {
    key: 'asphaltLotNoClauseNo',
    type: FormTableFieldType.Text,
    label: 'Lot No/Clause No',
  },
 
 
]

export default SectionAsphaltItemFields