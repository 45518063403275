import React, { useEffect } from 'react'
import styled from 'styled-components'
import CompFlatFields from '../../comps/FlatFields'
import { useSRRInDRS } from '../../useSRRInDRS'
import CompPreviewSection from '../../comps/Section'
import { SRRTransportAdditionalInfoFields } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/1-Transport/AdditionalTransportInfo'
import { SRRTransport } from '@src/types/SRR'
import { SRRTransportMachines, SRRTransportOrderedTonnesPerRoundField } from '@src/modules/SiteResoureRequirements/parts/5-Machine/parts/1-Transport/Transport'
import { formatTransportTypeId } from '@src/types/TransportTypeId'
import CompPreviewFieldPair from '../../comps/FieldPair'
import { formatNullStringNumberToString } from '@src/types/format/formatNullStringNumberToString'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'

interface Props {

}

const CompPreviewTransportDeliveryProfiling = (props: Props) => {
  const {

  } = props
  const [ srr ] = useSRRInDRS()
  if (srr === null) {
    return null
  }
  const data = srr.machine.transport.details
  return <Wrapper>
    <CompPreviewSection 
      name={``}
    >
      <FlexWrapper>
        {
          data.map((item: SRRTransport) => {
            return <HalfWrapper>
              <HalfHeader>{ formatTransportTypeId(item.transportType.id)}</HalfHeader>
              <CompPreviewFieldPair
                label={SRRTransportOrderedTonnesPerRoundField.label || ''}
                value={formatNullStringNumberToString(item.orderedTonnesPerRound)}
              />
              { SRRTransportMachines.map((machine: { key: keyof SRRTransport, label: string }) => {
                return <CompCheckboxValue 
                  label={machine.label}
                  checked={Boolean(item[machine.key])}
                />
              })}
            </HalfWrapper>
          })
        }
        
      </FlexWrapper>
    </CompPreviewSection>
  </Wrapper>
}

const Wrapper = styled.div`

`
const FlexWrapper = styled.div`
  display: flex;
`
const HalfWrapper = styled.div`
  flex: 1;
  margin-right: 4px;
  margin-bottom: 4px;
`
const HalfHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #009688;
`

function CompCheckboxValue(props: {
  label: string,
  checked: boolean,
}) {
  const {
    label,
    checked,
  } = props
  return <SFormControlLabel disabled control={<Checkbox size='small' checked={checked} name={label} />} label={label} />
}

const SFormControlLabel = styled(FormControlLabel)`
  span {
    font-size: 14px;
  }
`

export default CompPreviewTransportDeliveryProfiling