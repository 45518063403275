import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import RowGrid from '@src/components/FormRow/RowGrid'
import RowFlex from '@src/components/FormRow/RowFlex'
import FieldPair from '@src/components/Form/FieldPair'
import { Attachment } from '@src/types/Attachment'
import AttachmentField from '@src/components/Form/AttachmentField'
import { RoleId } from '@src/types/common/RoleId'
import { Mime } from '@src/types/Mime'
import { Sif } from '@src/types/Sif'
import { apiGetFiles } from '@src/apis/files/get-files'
import { PackFormType } from '@src/types/PackFormType'
import { useAppStore } from '@src/AppStore'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'

interface Props {
  sif: Sif,
}
const JobDetails = (props: Props) => {
  const { sif } = props
  const { projectName, projectId, customerName, customerRepresentative, customerRepresentativeContactNumber } = sif.jobDetails.projectDetails
  const { inspectedBy, inspectionDate, inspectionUpdatedBy, lastInspectionUpdate } = sif.jobDetails.inspectionDetails
  const { proposedStartDate, noOfShifts, shiftType, weekendWork, handWork } = sif.jobDetails.shiftDetails
  const { suburb, street } = sif.siteInformation.siteDetails
  const [ attachments, setAttachments ] = useState<Attachment[]>([])
  const { serialId } = useAppStore()

  const inspectionDetailData: { label: string, value: string }[] = [
    {
      label: 'Inspection Date',
      value: formatDateStringOrNullDDMMYYYY(inspectionDate),
    },
    {
      label: 'Inspected By',
      value: inspectedBy ? formatProfileFirstLastName(inspectedBy) : '',
    },
    {
      label: 'Inspection Updated by',
      value: formatProfileOrNullFirstLastName(inspectionUpdatedBy),
    },
    {
      label: 'Last inspection update',
      value: formatDateStringOrNullDDMMYYYY(lastInspectionUpdate),
    },
  ]
  const projectDetailData: { label: string, value: string }[] = [
    {
      label: 'Project Number',
      value: projectId || '',
    },
    {
      label: 'Project Name',
      value: projectName || '',
    },
    {
      label: 'Customer',
      value: customerName || '',
    },
    {
      label: 'Customer Representative',
      value: customerRepresentative || '',
    },
    {
      label: 'Contact Number',
      value: customerRepresentativeContactNumber || '',
    },
  ]
  const shiftDetailData: { label: string, value: string }[] = [
    {
      label: 'Proposed Start Date',
      value: formatDateStringDDMMYYYY(proposedStartDate || ''),
    },
    {
      label: 'No of Shifts',
      value: `${noOfShifts}`,
    },
    {
      label: 'Shift Times',
      value: shiftType ? shiftType.id : '',
    },
    {
      label: 'Weekend Work',
      value: formatBooleanOrNullToYesNo(weekendWork),
    },
    {
      label: 'Handwork',
      value: formatBooleanOrNullToYesNo(handWork),
    },
  ]
  useEffect(() => {
    const fetchFiles = async () => {
      const result = await apiGetFiles({
        formType: PackFormType.SITE_INSPECTION_FORM,
        formId: sif.id,
        serialId,
      })
      setAttachments(result.data.documents)
    }
    fetchFiles()
  }, [])
  
  return <Wrapper>
    <FormSectionTitle>JOB DETAILS</FormSectionTitle>
    <FormPanel
      title={`Inspection Details`}
    >
      <RowGrid>
        { inspectionDetailData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel
      title={`Project Details`}
      >
      <RowGrid>
        { projectDetailData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel
      title={`Shift Details`}
      >
      <RowGrid>
        { shiftDetailData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel
      title={`Attachments`}
    >
      <RowFlex>
        { attachments.map((attachment: Attachment) => {
          return <AttachmentField key={attachment.id} attachment={attachment}/>
        })}
      </RowFlex> 
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div``

export default JobDetails