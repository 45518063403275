import React, { useState, useRef, SyntheticEvent, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useCrewCalender } from '../StoreCrewCalender'
import { useDrag, useDrop } from 'react-dnd'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import CreateShiftPopover from './CreateShiftPopover'
import { useSnackbar } from '../../../../components/GlobalSnackbar'
import moment from 'moment'
import DRSBox from './DRSBox'
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

import usePermission from '@src/utils/hooks/usePermission'
import { useHistory } from 'react-router'
import { Shift } from '@src/types/Shift'
import { Crew } from '@src/types/Crew'
import { DragItemTypes } from '@src/types/DragItemTypes'
import { IconButton } from '@material-ui/core'

export interface CrewRowInCrewView extends Crew {
  drss: (Shift[])[],
  crewIndex: number


}

function CrewRow({
  data,
  drsOpt,
  onDuplicateDrsOpt,
  duplicateData, shiftCollapsed
}: {
  data: CrewRowInCrewView,
  drsOpt: boolean,
  onDuplicateDrsOpt(opt: any, obj: any): void,
  duplicateData: any,
  shiftCollapsed: boolean
}) {
  const crewIDsToggleLocal = localStorage.getItem('crewIDsToggle') ? JSON.parse(localStorage.getItem('crewIDsToggle')) :[];
  const history = useHistory()
  const { alert } = useSnackbar()
  const { crewNameWidth, updateSelectedStartBox, updateSelectedEndBox } = useCrewCalender()
  const { id, name, colourCode, drss, crewIndex } = data
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [hideCrew, setHideCrew] = useState<boolean>(true)
  const [crewIDsToggle, setCrewIDsToggle] = useState<any[]>(crewIDsToggleLocal)
  const [anchorElView, setAnchorElView] = useState<any>(null)
  const [opt, setOpt] = useState<boolean>(false)
  const [viewData, setViewData] = useState<Shift>()
  const [initialStartDate, setInitialStartDate] = useState<any>(moment())
  const [initialEndDate, setInitialEndDate] = useState<any>(moment())
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: DragItemTypes.CrewRow,
    },
    begin: (monitor) => {
      console.log(`CrewRow useDrag() begin`)
      //updateSelectedStartBox(batchIndex, crewIndex)
    },
    end: (item: any, monitor) => {
      console.log(`CrewRow useDrag() end`)
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
  })
  const { canCreateShift, canEditShift } = usePermission()
  function handleClose() {
    setAnchorEl(null)
    updateSelectedStartBox(-1, crewIndex)
    updateSelectedEndBox(-1)
  }
  useEffect(() => {
    //  console.log(`data`)
    //  console.log(data)
    //  console.log(`data`)


  }, [data])
  useEffect(() => {

    setHideCrew(shiftCollapsed);

  }, [shiftCollapsed])
  function handleDrsOpt(opt: any, obj: any) {

    onDuplicateDrsOpt(opt, obj);
  }
  const handleDRSItemClick = (drsItem: Shift) => (e: SyntheticEvent) => {
    e.stopPropagation()
    // console.log(drsItem)
    // if user cannot edit shift, bring him/her to crew-pack detail page
    if (!canEditShift) {
      history.push({
        pathname: `/crew-pack/${drsItem.id}`,
      })
      return
    }
    history.push({
      pathname: `/crew-pack/${drsItem.id}`,
    })
    // setAnchorElView(e.currentTarget as HTMLElement)
    // setViewData(drsItem)
  }

  const toggleViewCrew = () => (e: SyntheticEvent) => {
    if (hideCrew == false) {
      if (crewIDsToggle.indexOf(id) != -1) {
        crewIDsToggle.splice(crewIDsToggle.indexOf(id), 1);
        setCrewIDsToggle(crewIDsToggle);
        localStorage.setItem('setCrewIDsToggle',JSON.stringify(crewIDsToggle));
      }
     
      setHideCrew(true);
      return;
    }
    if (hideCrew == true) {
      if (crewIDsToggle.indexOf(id) == -1) {
        crewIDsToggle.push(id);
        setCrewIDsToggle(crewIDsToggle);
        localStorage.setItem('setCrewIDsToggle',JSON.stringify(crewIDsToggle));
      }
      setHideCrew(false);
      
      return;
    }

  }
 
  const handleDateBoxClick = (batchIndex: number) => (e: SyntheticEvent) => {
    if (!canCreateShift) {
      return
    }
    // setInitialStartDate(currentBatch[batchIndex])
    // setInitialEndDate(currentBatch[batchIndex])
    // setAnchorEl(e.currentTarget as HTMLElement)
  }

  return <Wrapper>
    
    {<NameBox
      className='div-block-crew-info'
      gridWidth={crewNameWidth}
    ><Circle color={colourCode || ''}>
        <Icon />
      </Circle>
      <Name>{name}</Name>
      <IconElement > <IconButton onClick={toggleViewCrew()}> {hideCrew ? <ArrowDownward /> : <ArrowUpward />} </IconButton> </IconElement>
    </NameBox>
    }
    {<React.Fragment>
      {
        drss.map((drs: any[], index: number) => {
          return <DRSBox
            crewId={id}
            crewIDsToggle={crewIDsToggle}
            hideCrew={hideCrew}
            crewName={name || ''}
            crewIndex={crewIndex}
            batchIndex={index}
            key={'DRSbox_'+index+'cr_'+id+'_cri'+crewIndex}
            onDRSBoxClick={handleDateBoxClick(index)}
            onDRSItemClick={handleDRSItemClick}
            drs={drs}
            shiftCollapsed={shiftCollapsed}
            duplicateData={duplicateData}
            onDuplicateDrsOpt={(opt: any, obj: any) => { handleDrsOpt(opt, obj) }}
            opt={drsOpt}
          />
        })
      }

    </React.Fragment>
    }

    {Boolean(anchorEl) && <CreateShiftPopover
      type='create'
      crewId={id} 
      crewIndex={crewIndex}
      anchorEl={anchorEl}
      handleClose={handleClose}
      initialStartDate={initialStartDate}
      initialEndDate={initialEndDate}
    />}
    {Boolean(anchorElView) && <CreateShiftPopover
      type='edit'
      crewId={id}
      crewIndex={crewIndex}
      anchorEl={anchorElView}
      handleClose={() => setAnchorElView(null)}
      shiftData={viewData}
    />}

  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  position:relative;
  
`

const Box = styled.div<{ gridWidth: number }>`
  flex-grow: 0;
  width: ${props => `${props.gridWidth}px`};
  /* height: ${props => `${props.gridWidth}px`}; */
  min-height: 130px;
`

const NameBox = styled(Box) <{ gridWidth: number }>`
  background: #F5F5F5;
  border: 1px solid #E3E0E0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position:relative;
`
const Name = styled.div`
  margin-top: 9px;
  font-size: 16px;
  text-align: center;
  padding: 0 4px;
`
const CrewColorMapping: { [key: string]: string } = {
  yellow: '#F8D202',
  blue: '#C1E1E2',
  green: '#72AD5C',
  red: '#D91818',
  purple: '#7740B5',
  orange: '#F89002',
  pink: '#D323A4',
}
export const Circle = styled.div<{ color: string }>`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${props => {
    if (props.color) {
      return CrewColorMapping[props.color] || `${props.color}`
    }
    return `#F8D202`;
  }};
  text-align: center;
  line-height: 48px;
  color: #fff;
`
const Icon = styled(SupervisorAccountIcon)`
  width: 30px;
  height: 30px;
  margin-top: 8px;
`
const IconElement = styled.div`
position: absolute;
    right: 0px;
    top: -8px;
    z-index:1;
`

export default observer(CrewRow)