import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Container from '@material-ui/core/Container'
import { AssetMgmtHeader } from '../MgmtLabour/MgmtLabourPage'
import CrewAccording from './parts/CrewAccording'
import { FloatActionButton } from '../../components'
import CrewDialog from './comps/CrewDialog'
import { apiCrewUpdate } from '../../apis/crew/crew-update'
import { useRSMgmtStore } from '../MgmtLabour/Store'
import { useAppStore } from '../../AppStore'
import { apiCrewList } from '../../apis/crew/crew-list'
import { apiCrewGetInfo } from '../../apis/crew/crew-get-info'
import { apiMobileAssetsUpdate } from '../../apis/mobile-asset-update'
import { apiMobileAssetById } from '../../apis/mobile-asset-by-id'
import { useSnackbar } from '../../components/GlobalSnackbar'
import { apiCrewActivate } from '@src/apis/crew/crew-activate'
import { apiCrewDeactivate } from '@src/apis/crew/crew-deactivate'
import Breadcrumbs from '@src/components/Breadcrumbs'
import usePermission from '@src/utils/hooks/usePermission'
import { MobileAsset } from '../MgmtMobileAsset/fields'
import { useCrewCalender } from "@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";
const MgmtCrewPage = () => {
  const { canAddCrewInMgmt } = usePermission()
  const [modalState, setModalState] = useState<{
    open: boolean,
    data: AIMS.Crew | null,
  }>({
    open: false,
    data: null
  })
  const [crews, setNewCrews] = useState<AIMS.Crew[]>([])
  const [refreshToken, setRefreshToken] = useState<any>(0)
  const { depot } = useRSMgmtStore()
  const { regionId } = useAppStore()
  const { setCrews } = useCrewCalender();
  const { alert } = useSnackbar()

  useEffect(() => {
    const fetch = async () => {
      let finalDepotId
      let finalRegionId
      if (depot.value === 'all') {
        finalRegionId = regionId
      } else {
        finalDepotId = depot.value
      }
      const result = await apiCrewList({
        depotId: finalDepotId,
        regionId: finalRegionId,
      })
      if (result.data) {

        setNewCrews(result.data.crews);
        setCrews(result.data.crews)
        
      }
    }
    if (depot.value) {
      fetch()
    } else {
      console.log(depot)
    }
  }, [depot, refreshToken])

  const handleAddNewCrew = () => {
    setModalState({
      open: true,
      data: null,
    })
  }
  const handleStoredCrewData =  (crewData:any[]) => {
   
    let crewStoreData = localStorage.getItem('storeCrews');
    let notFoundDataObj: any = [];
    
    if (crewStoreData) {
      let crewStoreDataObj = JSON.parse(crewStoreData);
      let cardCrews: any = crewStoreDataObj.cardCrews;
      cardCrews.forEach((loopCrew: any,indexLoop: any) => {
        const activeIndex = crewData.findIndex((data: any) => data.id === loopCrew.id);
        if(activeIndex == -1) {
          notFoundDataObj.push(loopCrew);
        }
      });
      if(notFoundDataObj.length > 0) {
        notFoundDataObj.forEach((loopCrew: any,indexLoop: any) => {
          const activeIndex = cardCrews.findIndex((data: any) => data.id === loopCrew.id);
          if(activeIndex!= -1){
            cardCrews.splice(activeIndex,1);
          }
        })
      }
      crewData.forEach((loopCrew: any,indexLoop: any) => {
        const activeIndex = cardCrews.findIndex((data: any) => data.id === loopCrew.id);
        if(activeIndex == -1) {
          cardCrews.push(loopCrew);
        }
      })



      crewStoreDataObj['cardCrews'] = cardCrews;
      localStorage.setItem('storeCrews',JSON.stringify(crewStoreDataObj));
      console.log(notFoundDataObj);
      console.log(crewData);
     console.log(crewStoreDataObj);
    }
   
  }
  const handleEditCrew = async (crew: AIMS.Crew) => {
    const { id } = crew
    const result = await apiCrewGetInfo({
      crewId: id,
    })
    if (result.data) {
      const { crew } = result.data
      const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
      let crewData  = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          members: crew.members,
          mobileAssets: crew.mobileAssets,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
      
      setModalState({
        open: true,
        data: crew,
      })
    }
  }
  const handleChangeCrewStatus = async (crew: AIMS.Crew, upcoming: boolean) => {

    const result = upcoming ?
      await apiCrewActivate({ id: crew.id }) :
      await apiCrewDeactivate({ id: crew.id })
    if (result.data) {
      const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
      let crewData:any  = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          active: upcoming,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
      alert({
        message: `Status updated.`,
      })
    }
  }
  const handleUpdateSuccess = (crew: AIMS.Crew) => {
    setModalState({
      open: false,
      data: null,
    })
    const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
    let crewData: any = [
      ...crews.slice(0, foundCrewIndex),
      {
        ...crews[foundCrewIndex],
        ...crew,
      },
      ...crews.slice(foundCrewIndex + 1),
    ];
    setCrews(crewData)
    setNewCrews(crewData);
    handleStoredCrewData(crewData);
  }
  const handleCreateSuccess = () => {
    setModalState({
      open: false,
      data: null,
    })
    setRefreshToken(refreshToken + 1)
  }
  const handleLoadCrewInfo = async (crew: AIMS.Crew) => {
    const { id } = crew
    const result = await apiCrewGetInfo({
      crewId: id,
    })
    if (result.data) {
      const { crew } = result.data
      const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
      let crewData: any = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          members: crew.members,
          mobileAssets: crew.mobileAssets,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
    }
  }
  const handleAddMember = async (crew: AIMS.Crew, member: AIMS.Profile) => {
    const result = await apiCrewUpdate({
      ...crew,
      members: [
        ...(crew.members || []),
        member
      ]
    })
    if (result.data) {
      const foundCrewIndex = crews.findIndex(item => item.id === result.data.crew.id)
      let crewData: any  = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          ...result.data.crew,
          memberCount: result.data.crew.members.length,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
    }
  }
  const handleRemoveMember = async (crew: AIMS.Crew, membersAfterRemoved: AIMS.Profile[]) => {
    const result = await apiCrewUpdate({
      ...crew,
      members: membersAfterRemoved,
    })
    if (result.data) {
      const foundCrewIndex = crews.findIndex(item => item.id === result.data.crew.id)
     let crewData: any = [
      ...crews.slice(0, foundCrewIndex),
      {
        ...crews[foundCrewIndex],
        ...result.data.crew,
        memberCount: result.data.crew.members.length,
      },
      ...crews.slice(foundCrewIndex + 1),
    ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
    }
  }
  const handleAddAsset = async (crew: AIMS.Crew, asset: AIMS.MobileAsset) => {
    const result = await apiMobileAssetsUpdate({
      ...asset,
      // @ts-ignore
      crews: [
        // @ts-ignore
        ...(asset.crews || []),
        // @ts-ignore
        crew,
      ]
    })
    if (result.data) {
      const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
      let crewData: any = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          mobileAssets: [
            ...(crew.mobileAssets || []),
            asset,
          ],
          assetCount: crew.mobileAssets ? crew.mobileAssets.length + 1 : 1,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
    }
  }
  const handleRemoveAsset = async (crew: AIMS.Crew, asset: AIMS.MobileAsset, assetIndex: number) => {
    const queryResult = await apiMobileAssetById({ id: asset.id })
    const assetCrews = queryResult.data.mobileAsset.crews || []
    const foundCrewIndex = assetCrews.findIndex((item: AIMS.Crew) => item.id === crew.id)
    const result = await apiMobileAssetsUpdate({
      ...asset as MobileAsset,
      crews: [
        ...assetCrews.slice(0, foundCrewIndex),
        ...assetCrews.slice(foundCrewIndex + 1),
      ]
    })
    if (result.data) {
      const foundCrewIndex = crews.findIndex(item => item.id === crew.id)
      let crewData: any = [
        ...crews.slice(0, foundCrewIndex),
        {
          ...crews[foundCrewIndex],
          mobileAssets: [
            ...(crew.mobileAssets || []).slice(0, assetIndex),
            ...(crew.mobileAssets || []).slice(assetIndex + 1),
          ],
          assetCount: crew.mobileAssets ? crew.mobileAssets.length - 1 : 0,
        },
        ...crews.slice(foundCrewIndex + 1),
      ];
      setCrews(crewData);
      setNewCrews(crewData);
      handleStoredCrewData(crewData);
    }
  }
  return <Container maxWidth='xl'>
    <Breadcrumbs items={[{ type: 'text', text: `Crew Management` }]} />
    <AssetMgmtHeader>ASSET MANAGEMENT: CREW</AssetMgmtHeader>
    <MainWrapper>
      <CrewAccording
        crews={crews}
        onEditCrew={handleEditCrew}
        onChangeCrewStatus={handleChangeCrewStatus}
        onLoadCrewInfo={handleLoadCrewInfo}
        onAddMember={handleAddMember}
        onRemoveMember={handleRemoveMember}
        onAddAsset={handleAddAsset}
        onRemoveAsset={handleRemoveAsset}
      />
    </MainWrapper>

    {modalState.open &&
      <CrewDialog
        crew={modalState.data}
        open={modalState.open}
        onClose={() => setModalState({
          open: false,
          data: null,
        })}
        onCancel={() => setModalState({
          open: false,
          data: null,
        })}
        onUpdateSuccess={handleUpdateSuccess}
        onCreateSuccess={handleCreateSuccess}
      />
    }
    {canAddCrewInMgmt &&
      <FloatActionButton
        tooltipText='Add New Crew'
        onClick={handleAddNewCrew}
      />
    }
  </Container>
}

const MainWrapper = styled.div`
  margin: 40px 50px;
`

export default observer(MgmtCrewPage)