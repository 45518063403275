import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { Rework } from '@src/types/Rework'

const SRRReworkFields: FormTableField<Rework>[] = [
  {
    key: 'reworkPercentage',
    type: FormTableFieldType.Number,
    label: '% of Rework',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'reworkTonnes',
    type: FormTableFieldType.Number,
    label: 'Rework Tonnes/Litres',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'hours',
    type: FormTableFieldType.Number,
    label: 'Hours - Labour',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'reworkEmulsion',
    type: FormTableFieldType.Number,
    label: 'Emulsion (L)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
]

export default SRRReworkFields