import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { LabourCrewDetails } from '@src/types/LabourCrewDetails'

const confirmInfo: { key: keyof LabourCrewDetails, label: string}[] = [
    {
      key: 'laha',
      label: 'LAHA (Living Away from Home Allowance)',
    },
    {
      key: 'lunchTaken',
      label: 'Lunch Taken',
    },
];

export const LabourCrewDetailsFields: FormTableField<LabourCrewDetails>[] = [
    {
        key: 'name',
        type: FormTableFieldType.Text,
        label: 'Name',
    },
    {
        key: 'depotCode',
        type: FormTableFieldType.Text,
        label: 'Deport Code',
    },
    {
        key: 'confirm',
        type: FormTableFieldType.CheckboxGroup,
        label: 'Check',
        options: confirmInfo.map((info) => ({ value: info.key, label: info.label }))
    },
    {
        key: 'comments',
        type: FormTableFieldType.Textarea,
        label: 'Comments'
    }
]