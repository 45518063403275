import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { SectionNameUrlMapping } from './drs-get-section'

interface Options { 
  sectionName: string,
  drsId?: string,
  roleId: string,
  data: any,
}

export const apiDRSUpdateSection = ({
  sectionName = 'rework',
  drsId,
  roleId,
  data,
}: Options) => {
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/${SectionNameUrlMapping[sectionName]}`,
    params: {
      drs: drsId,
      role: roleId,
    },
    data,
  }
  return ax(options)
}