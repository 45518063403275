import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import moment, { Moment } from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import _get from 'lodash/get'
import FieldAssetDepotRadio from './f-AssetDepotRadio'
import FieldEditableNumber from './f-EditableNumber'
import FieldEditableText from './f-EditableText'
import FieldEditableDate from './f-EditableDate'
import FieldEditableTime from './f-EditableTime'
import FieldMemberTimePicker from './f-MemberTimePicker'
import FieldMemberCheckbox from './f-MemberCheckbox';
import FieldCommentText from './f-CommentText'
import FieldCreateButton from './f-CreateButton'
import { getEmptyLabourMember, useDRS } from '../StoreDRS'
import Grid from '@material-ui/core/Grid'
import TableHeaderCell from './base-TableHeaderCell'
import LabourMemberTableHeaderCell from './labour-member-TableHeaderCell'
import CrewMemberAutocomplete from '../../../../../components/autocompletes/CrewMemberAutocomplete'
import { capUsername } from '../../../../../utils/capUsername'
import { useSnackbar } from '../../../../../components/GlobalSnackbar'
import DeleteIcon from '@material-ui/icons/Delete'
import { LabourCrewDetails } from '@src/types/LabourCrewDetails'
import useModal from '@src/components/FormModal/useModal'
import Modal from '@src/components/FormModal/Modal'
import { LabourCrewDetailsFields } from './LabourCrewDetailsFields'

type MAP = LabourCrewDetails

export function getStartEndDiff ({
  start,
  end,
}: {
  start: Moment | string | null,
  end: Moment | string | null,
}) {
  if (!start || !end) {
    return null
  }
  let minutes = moment.duration(moment(end).diff(moment(start))).asMinutes()
  const MINUTES_IN_ONE_DAY = 24 * 60
  minutes = minutes % ( MINUTES_IN_ONE_DAY )
  if (minutes < 0) {
    minutes = minutes + MINUTES_IN_ONE_DAY
  }
  let hours = Number(minutes / 60)
  return hours
}

export function formatHoursToDecimal(hours: number | null) {
  try {
    if (!hours) {
      return null
    }
    return hours.toFixed(2)
  } catch(e) {
    return null
  }
}

export const HoursDiff = ({
  start,
  end,
}: {
  start: Moment | null,
  end: Moment | null,
}) => {
  const hours = getStartEndDiff({ start, end })
  let formatted: string | null = ''
  if (hours) {
    formatted = formatHoursToDecimal(hours)
  }
  return <div>{formatted}</div>
}

const FieldLabourMemberTable = ({
  field,
  onChange,
}: {
  field: DRS.Field,
  onChange(val: any, options: any): void,
}) => {
  const { data: rootData } = useDRS()
  const { data, path } = field
  const { alert } = useSnackbar()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<MAP>({})
  const [ newMember, setNewMember ] = useState<any>({
    value: '',
    label: '',
  })
  const handleCellChange = (row: any, rowIndex: number, cellPath: string) => (val: any) => {
    if (cellPath === 'plan.startTime') {
      let start = moment(val)
      let shiftDate = moment(_get(rootData, `jobDetails.shift.date`))
      start.set('date', shiftDate.get('date'))
      // without seconds or milliseconds.
      val = start.seconds(0).milliseconds(0).toISOString()
    } else if (cellPath === 'plan.endTime') {
      let start = moment(_get(rootData, [path, rowIndex, 'plan.startTime'].join('.')))
      let end = moment(val)
      let shiftDate = moment(_get(rootData, `jobDetails.shift.date`))
      end.set('date', shiftDate.get('date'))
      if (end.diff(start, 'minutes') < 0) {
        end.set('date', end.get('date') + 1)
      } else {
        end.set('date', end.get('date'))
      }
      // without seconds or milliseconds.
      val = end.seconds(0).milliseconds(0).toISOString()
    } else if (cellPath === 'plan.present' || cellPath === 'plan.laha' || cellPath === 'plan.lunchBreak') {
      val = !val;
    }

    onChange(val, {
      isCell: true,
      tablePath: path,
      rowIndex,
      cellPath,
      path: [path, rowIndex, cellPath].join('.'),
    })
  }
  const handleAddRow = (item: any) => {
    const { value, label, raw } = item
    if (data.some((item: any) => item.email === value)) {
      alert({
        type: 'warning',
        message: `${label} is already added.`,
      })
    } else {
      const emptyRow = {
        email: value,
        profile: raw,
        plan: {
          comments: '',
          startTime: '',
          endTime: '',
          present: true,
        }
      }
      onChange([ ...data, emptyRow ], {
        isCell: false,
        isAdd: true,
        tablePath: path,
        path,
      })
    }
    
  }
  const handleRemove = (rowIndex: number) => () => {
    onChange([
      ...data.slice(0, rowIndex),
      ...data.slice(rowIndex + 1),
    ], {
      isCell: false,
      isAdd: false,
      isRemove: true,
      rowIndex,
      tablePath: path,
      path,
    })
  }
  const handleApplyToAll = (fieldName: string, value: any) => {
    onChange(data.map((item: any) => {
      return {
        ...item,
        plan: {
          ...item.plan,
          [fieldName]: value,
        }
      }
    }), {
      fieldPath: path
    })
  }
  const getTimeFromDateStr = (dateStr: string) => {
    return moment(dateStr).format('HH:MM');
  }

  const getDataForDetailsModal = (row: any) => {
    return {
      id: row.profile.id,
      name: row.profile.firstName + ' ' + row.profile.lastName,
      depotCode: row.plan.depotCode,
      startTime: row.plan.startTime,
      endTime: row.plan.endTime,
      laha: row.plan.laha,
      lunchTaken: row.plan.lunchTaken,
      confirm: [
        {
          key:'laha',
          value: row.plan.laha
        },
        {
          key: 'lunchTaken',
          value: row.plan.lunchTaken
        }
      ],
      comments: row.plan.comments
    }
  }

  return <Wrapper>
    <Content>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableHeaderCell>Assigned</TableHeaderCell> */}
            <LabourMemberTableHeaderCell>Present</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Name</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Start Time</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>End Time</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Total Hours</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>LAHA(Living away from home allowance)</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Depot Code</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Lunch Break</LabourMemberTableHeaderCell>
            <LabourMemberTableHeaderCell>Comments</LabourMemberTableHeaderCell>
            {/* 
            <LabourMemberTableHeaderCell></LabourMemberTableHeaderCell>
            */}
          </TableRow>
        </TableHead>
        <TableBody>
          { data.map((row: any, rowIndex: number) => {
            const { assetDepot, mobileAssetNumber, assetDescription, hours, id } = row
            return <TableRow key={row.email}>
              {/* <TableCell>
                <Checkbox
                  checked={data[rowIndex].assigned}
                  onChange={e => handleCellChange(row, rowIndex, 'plan.assigned')(e.target.checked)}
                  color='primary'
                />
              </TableCell> */}
              <TableCellMinWidth50>
                <FieldMemberCheckbox
                  onChange={handleCellChange(row, rowIndex, 'plan.present')}
                  field={
                    {
                      name: '',
                      id: `labour.members.plan.present`,
                      path: `labour.members.${rowIndex}.plan.present`,
                      data: row.plan.present
                    }
                  }
                />
              </TableCellMinWidth50>
              <TableCellMinWidth50>
                <ClickableLabel onClick={() => {openAndSetData(getDataForDetailsModal(row))}}>
                  {`${row.profile.firstName} ${row.profile.lastName}`}
                </ClickableLabel>
              </TableCellMinWidth50>
              <TableCellMinWidth100>
                {getTimeFromDateStr(row.plan.startTime)}
                {/*   
                <FieldMemberTimePicker
                    onChange={handleCellChange(row, rowIndex, 'plan.startTime')}
                    onApplyToAll={() => handleApplyToAll('startTime', row.plan.startTime)}
                    field={
                      {
                        name: 'Start Time',
                        id: `labour.members.plan.startTime`,
                        path: `labour.members.${rowIndex}.plan.startTime`,
                        data: row.plan.startTime,
                        typeMeta: {
                          type: 'editable-time',
                          isStartTime: true,
                        }
                      }
                    }
                  />
                  */}
              </TableCellMinWidth100>
              <TableCellMinWidth100>
                {getTimeFromDateStr(row.plan.endTime)}
                {/* 
                <FieldMemberTimePicker
                    onChange={handleCellChange(row, rowIndex, 'plan.endTime')}
                    onApplyToAll={() => handleApplyToAll('endTime', row.plan.endTime)}
                    field={
                      {
                        name: 'End Time',
                        id: `labour.members.plan.endTime`,
                        path: `labour.members.${rowIndex}.plan.endTime`,
                        data: row.plan.endTime,
                        typeMeta: {
                          type: 'editable-time',
                          isEndTime: true,
                        }
                      }
                    }
                  />
                  */}
              </TableCellMinWidth100>
              <TableCellMinWidth50>
                <HoursDiff 
                  start={row.plan.startTime}
                  end={row.plan.endTime}
                />
              </TableCellMinWidth50>
              <TableCellMinWidth50>
               <FieldMemberCheckbox
                  onChange={handleCellChange(row, rowIndex, 'plan.laha')}
                  field={
                    {
                      name: '',
                      id: `labour.members.plan.laha`,
                      path: `labour.members.${rowIndex}.plan.laha`,
                      data: row.plan.laha
                    }
                  }
                />
              </TableCellMinWidth50>
              <TableCellMinWidth50>
                {row.plan.depotCode}
              </TableCellMinWidth50>
              <TableCellMinWidth50>
              <FieldMemberCheckbox
                  onChange={handleCellChange(row, rowIndex, 'plan.lunchbreak')}
                  field={
                    {
                      name: '',
                      id: `labour.members.plan.lunchbreak`,
                      path: `labour.members.${rowIndex}.plan.lunchbreak`,
                      data: row.plan.lunchbreak
                    }
                  }
                />
              </TableCellMinWidth50>
              <TableCellMinWidth50>
                {row.plan.comments}
                {/* 
                <FieldCommentText 
                  onChange={handleCellChange(row, rowIndex, 'plan.comments')}
                  field={
                    {
                      name: 'Comments',
                      id: `labour.members.plan.comments`,
                      path: `labour.members.${rowIndex}.plan.comments`,
                      data: row.plan.comments,
                    }
                  }
                />
                */}
              </TableCellMinWidth50>
              {/* 
              <TableCell>
                <IconButton onClick={handleRemove(rowIndex)}>
                  <DeleteIcon /> 
                </IconButton>
              </TableCell>
              */}
            </TableRow>
          })}
          
        </TableBody>
      </Table>
      <FieldCreateButton onClick={() => {openAndSetData(null)}}>Add Crew Member</FieldCreateButton>
      {
        open && <Modal<MAP>
          maxWidth='md'
          open={open}
          title='< Edit/Add > Crew Member'
          data={{
            rowData: modalData,
            fields: LabourCrewDetailsFields,
            columns: [
              {
                title: '',
                fieldKeys: [
                  'name',
                  'depotCode'
                ],
              },
              {
                title: '',
                fieldKeys: [
                  'confirm'
                ],
              },
              {
                title: '',
                fieldKeys: [
                  'comments',
                ],
              },
            ]
          }}
          onSuccess={() => {}}
          onClose={closeModal}
          onCancel={closeModal}
        /> 
      }
      {/* 
      <Grid container>
        <Grid item xs={3}>
          <CrewMemberAutocomplete 
            active={true}
            label='Add Member'
            onChange={item => {
              setNewMember({ value: '', label: ''})
              handleAddRow(item)
            }}
            selectedItem={newMember}
          />
        </Grid>
      </Grid>
      */}
    </Content>
  </Wrapper>
}

const TableCell = styled(TableCellOrigin)`
  border-bottom: 0;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
` as typeof TableCellOrigin
const Wrapper = styled.div``
const Content = styled.div`
  overflow-x: auto;
`
const ClickableLabel = styled.span``

const TableCellMinWidth50 = styled(TableCell)`
  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
` as typeof TableCell

const TableCellMinWidth100 = styled(TableCell)`
  min-width: 90px;
  max-width: 90px;
  font-size: 12px;
` as typeof TableCell

export default observer(FieldLabourMemberTable)