import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import { FieldName } from './Field'
import SupplierAutocomplete from '../../../../../components/autocompletes/SupplierAutocomplete'


interface Options extends DRS.Comp {
  label?: string,
}

const FieldSupplier = ({
  field,
  onChange,
  label = '',
}: Options) => {
  const { id, name } = field.data
  const selectedItem = {
    value: id,
    label: name,
  }
  return <Wrapper>
    <FormControl>
      <SupplierAutocomplete 
        label={label}
        selectedItem={selectedItem}
        onChange={onChange}
      />
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 8px;
`
const Content = styled.div``

export default FieldSupplier