import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { SRRResourceTypeId } from '@src/types/SRRResourceTypeId'

interface Options  {
  srrId: number | string,
  serialId: number,
  resouceTypeId: SRRResourceTypeId,
  partial: Partial<MaterialAsphaltProduct>,
}

export const apiSrrResouceCreate = (options: Options) => {
  const { 
    srrId, 
    serialId,
    resouceTypeId,
    partial,
  } = options

  const data = {
    srrId,
    type: {
      id: resouceTypeId,
    },
    ...partial,
  }
  const urlPartNormalize = (resouceTypeId: SRRResourceTypeId) => {
    const arr = [ 'paver', 'steelroller', 'multiroller', 'skidsteercombo', 'skidsteerbroom', 'suctionbroom', 'tractorbroom', 'watercart', 'linemarking', 'vmsboards' ]
    return arr[resouceTypeId - 1]
  }
  const axOptions = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/srr/${srrId}/machine/resources/${urlPartNormalize(resouceTypeId)}/${srrId}`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}