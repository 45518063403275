import React from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import TabSection from '../../comps/TabSectionWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import _get from 'lodash/get'
import TableCell from '../../comps/TableCell'
import TableHeadCell from '../../comps/TableHeadCell'
import { DRSMaterialAsphaltItemPAE } from '@src/types/drs/material/MaterialAsphaltItem'
import { DRSMaterialSpraySealItemPAE } from '@src/types/drs/material/MaterialSpraySealItem'

const renderMain = (type: string, data: any, isLast: boolean) => {
  if (type === 'ASPHALT') {
    return renderAs(data as DRSMaterialAsphaltItemPAE[], isLast)
  }
  return renderSs(data as DRSMaterialSpraySealItemPAE[], isLast)
}

const renderAs = (data: DRSMaterialAsphaltItemPAE[], isLast: boolean) => {
  if (!data) {
    return null
  }
  let finalData = data.filter((item: DRSMaterialAsphaltItemPAE) => {
    return (item.plan.product && item.plan.product.id) || item.plan.tonnes
  })
  if (!finalData.length) return null
  return  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell>Product</TableHeadCell>
        <TableHeadCell>Tonnes</TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        finalData.map(row => {
          return <TableRow key={row.id}>
            <TableCell>{row.plan.product.description}</TableCell>
            <TableCell>{row.plan.tonnes}</TableCell>
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}

const renderSs = (data: DRSMaterialSpraySealItemPAE[], isLast: boolean) => {
  if (!data) {
    return null
  }
  let finalData = data.filter((item: DRSMaterialSpraySealItemPAE) => {
    return item.plan.binderType || item.plan.area || item.plan.residualBinder || item.plan.aggregateSize
  })
  if (!finalData.length) return null
  return  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell>Binder</TableHeadCell>
        <TableHeadCell>Area</TableHeadCell>
        <TableHeadCell>Residual Binder</TableHeadCell>
        <TableHeadCell>Aggregate Size</TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        finalData.map(row => {
          return <TableRow key={row.id}>
            <TableCell width='20%'>{_get(row, 'plan.binderType.id')}</TableCell>
            <TableCell width='20%'>{row.plan.area}</TableCell>
            <TableCell width='20%'>{row.plan.residualBinder}</TableCell>
            <TableCell width='20%'>{row.plan.aggregateSize.description}</TableCell>
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}

const TabSectionMaterial = ({
  type, //oneOf(['ASPHALT', 'SPRAYSEAL']).isRequired,
  data, //products
  isLast,
}: {
  type: string, 
  data: DRSMaterialAsphaltItemPAE[] | DRSMaterialSpraySealItemPAE[],
  isLast: boolean,
}) => {
  return <TabSection
    name='Material Preview'
    isLast={isLast}
  >
    <InnerWrapper>
      { renderMain(type, data, isLast) }
    </InnerWrapper>
    
  </TabSection>
}

const InnerWrapper = styled.div`
  min-height: 110px;
`

export default observer(TabSectionMaterial)