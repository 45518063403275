import { useEffect, useState } from 'react'

import { useAppStore } from '@src/AppStore'

import { useParams } from 'react-router'

import { useStoreATP } from './Store.Approval'

import { apiATPGet } from '@src/apis/atp-get'
import { ATP } from '@src/types/ATP'
import { apiDRSGet } from '@src/apis/drs-get'
import { apiATPGetCommentHeaders } from '@src/apis/atp-get-comment-headers'
import { apiATPGetAspects } from '@src/apis/atp-get-aspects'

interface Options {
  // srrId: number,
}

export function useApproval(): [ any | null, boolean, typeof fetch, typeof fetchATP, any[] | null,typeof fetchCommentHeaders,  [], [], []] {
  const { id, drsId} = useParams<{id: string,drsId: string}>()
  const { serialId } = useAppStore()
  const { drs, setDRS,sections,setSections,allCommentsHeaders,setAllCommentsHeaders,commentsHeaders,setCommentsHeaders,atpAspetcts,setAtpAspects } = useStoreATP()
  const [ loading, setLoading ] = useState<boolean>(false)
  useEffect(() => {
    if (!drs && id) {
      fetch()
      fetchATP(id)
    }
  }, [id])
  const fetch = async () => {
    setLoading(true)
    try {
      const result = await apiDRSGet({
        drsId: drsId,
        roleId: 'RESOURCE_SCHEDULER',
      })
      // console.log(result)
      setDRS(result.data.drs)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const fetchATP = async (id: any) => {
    setLoading(true)
    try {
      const result = await apiATPGet({
        id: id,
        serialId,
      })
      // console.log(result)
      setSections(result.data)
      await fetchCommentHeaders()
      await fetchATPAspects();
      setLoading(false)
      
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchATPAspects = async () => {
    setLoading(true)
    try {
      const result = await apiATPGetAspects()
      // console.log(result)
      setAtpAspects(result.data.atpaspect)
      
      setLoading(false)
      
    } catch (e) {
      setLoading(false)
    }
  }
  const fetchCommentHeaders = async () => {
    setLoading(true)
    try {
      const result = await apiATPGetCommentHeaders()
      const uniqueIds:any = [];
      const uniqueRows: any = [];
      if(result) {
       // console.log(1);
        if(result.data) {
         // console.log(2);
          if(result.data.atpheader){
            setAllCommentsHeaders(result.data.atpheader);
            //console.log(3);
            let data = result.data.atpheader;
            data.forEach((item: any) => {
              //console.log(item);  
              if (!uniqueIds.includes(item.id)) {
                  uniqueIds.push(item.id);
                  uniqueRows.push(item);
              }
          });
            setCommentsHeaders(uniqueRows);
          }
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  return [ drs, loading, fetch,fetchATP,sections,fetchCommentHeaders,allCommentsHeaders, commentsHeaders,atpAspetcts ]
}
