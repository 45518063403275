import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options  {
  srrId: number | string,
  serialId: number,
}

export const apiSrrSubmit = (options: Options) => {
  const { 
    srrId, 
    serialId,
  } = options
  
  const axOptions = {
    method: 'PUT',
    url: `${BASE_API_URL}/v2/srr/${srrId}/status/complete`,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}