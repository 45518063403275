import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import TabSection from '../../comps/TabSectionWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { capWord } from '../../../../../../utils/capWord'
import TableCell from '../../comps/TableCell'
import TableHeadCell from '../../comps/TableHeadCell'
import _get from 'lodash/get'
import { DRS } from '@src/types/drs/DRS'

const reduceFn = (acc: number, curr: any) => {
  return acc + Number(curr.plan.orderedTonnesPerRound)
}
const getTonnes = (type: string, companyType: string, transportType: string, data: any) => {
  if (type === 'ASPHALT' && companyType === 'boral') {
    // console.log(`${TransportAsBoralPath}.${transportType}.plan.orderedTonnesPerRound`, _get(data, `${TransportAsBoralPath}.${transportType}.orderedTonnesPerRound`))
    return _get(data, `transport.boral.asphalt.${transportType}.plan.orderedTonnesPerRound`, 0)
  } else if (type === 'ASPHALT' && companyType === 'subcontractor') {
    return _get(data, `subcontractor.asphalt.${transportType}Transport`, []).reduce(reduceFn, 0)
  } else if (type === 'SPRAYSEAL' && companyType === 'subcontractor') {
    return _get(data, `subcontractor.spraySeal..${transportType}Transport`, []).reduce(reduceFn, 0)
  }
}
const renderTable = (type: string, companyType: string, data: any) => {
  if (!data) {
    return null
  }
  if (type === 'SPRAYSEAL' && companyType === 'boral') {
    return null // no such data
  }
  
  // when to show
  let deliveryTonnes = getTonnes(type, companyType, 'delivery', data)
  let profilingTonnes = getTonnes(type, companyType, 'profiling', data)

  if (deliveryTonnes === 0 && profilingTonnes === 0) {
    return null
  }
  return  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell colSpan={2}>{`${capWord(companyType)} Transport`}</TableHeadCell>
      </TableRow>
      <TableRow>
        <TableHeadCell>Type</TableHeadCell>
        <TableHeadCell>Tonnes / Round</TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>Delivery</TableCell>
        <TableCell>{deliveryTonnes}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Profiling</TableCell>
        <TableCell>{profilingTonnes}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
}

const TabSectionTransport = ({
  data,
  type, 
  isLast,
}: {
  data: DRS,
  type: string, //PropTypes.oneOf(['ASPHALT', 'SPRAYSEAL']).isRequired,
  isLast: boolean,
}) => {
  return <TabSection
    name='Transport Preview'
    isLast={isLast}
  >
    <InnerWrapper>
      { renderTable(type, 'boral', data) }
      {/* { renderTable(type, 'subcontractor', data) } */}
    </InnerWrapper>
    
  </TabSection>
}

const InnerWrapper = styled.div`
  min-height: 180px;
`

export default observer(TabSectionTransport)