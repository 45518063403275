import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  id: number,
  roleId: RoleId,
  serialId: number,
}

export const apiQASGetPdf = (options: Options) => {
  const {
    id,
    roleId,
    serialId,
  } = options
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/pdf/qas/${id}`,
    params: {
      reqSid: serialId,
      reqRole: roleId,
    },
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  }
  return ax(axOptions)
}
