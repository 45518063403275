import React from 'react'
import styled from 'styled-components'
import error404_lost from '../../assets/error404_lost.png'
import Typography from '@material-ui/core/Typography'

const FourOFour: React.FC = () => {
  return <Wrapper>
    <img src={error404_lost} width='115' />
    <PageNotFound align='center'>
      Page Not Found
    </PageNotFound>
  </Wrapper>
}

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`
const PageNotFound = styled(Typography)`
  color: #bbb;
  font-weight: 500;
  font-size: 22px;
  margin-top: 15px;
` as typeof Typography

export default FourOFour