import React, { useState, useEffect, ReactElement } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import { BoralSwitch } from '../../../components'
import CrewStatic from '../../../components/CrewStatic'
import { apiMobileAssetsUpdate } from '../../../apis/mobile-asset-update'
import { useSnackbar } from '../../../components/GlobalSnackbar'
import usePermission from '@src/utils/hooks/usePermission'
import MobileAssetFields, { MobileAsset } from '../fields'
import ErrorIcon from '@material-ui/icons/Error'
import { useHistory } from 'react-router'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { apiMobileAssetsUpdateError } from '@src/apis/mobile-asset-update-error'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

interface Column {
  id: string,
  label: string,
  type?: string,
  format?(value: any): ReactElement | null,
  element?: any,
  width?: number,
  minWidth?: number,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
}

const nextServiceDueField = MobileAssetFields.find((field) => field.key === 'nextServiceDue')
const assetReadingKM = MobileAssetFields.find((field) => field.key === 'assetReadingKM')
const assetReadingHour = MobileAssetFields.find((field) => field.key === 'assetReadingHour')

const ANWrapper = styled.div`
  cursor: pointer;
`
const ErrorItem = styled.div`
  color: #D32929;
  display: flex;
  text-decoration: underline;
  line-height: 24px;
  svg {
    margin-left: 10px;
  }
`
const NormalItem = styled.div`
  color: #0A9688;
  text-decoration: underline;
`
function AssetNumberElement(props: { row?: MobileAsset, onClick?(): void }) {
  const error = props.row!.error
  return <ANWrapper onClick={props.onClick}>
    {
      error ?
        <ErrorItem>
          { props.row!.id }
          <ErrorIcon />
        </ErrorItem>
        :
        <NormalItem>{ props.row!.id }</NormalItem>
    }
  </ANWrapper>
}
function OptionsElement(props: { row?: MobileAsset, onClick?(): void }) {
  const error = props.row!.error
  if (!error) {
    return null
  }
  return <ANWrapper onClick={props.onClick}>
    <IconButton size='small'>
      <MoreHorizIcon />
    </IconButton>
  </ANWrapper>
}
const columns: Column[] = [
  { 
    id: 'actions', 
    label: 'Edit',
    type: 'custom', 
    element: <IconButton size='small'><EditIcon /></IconButton>,
    width: 40,
  },
  { 
    id: 'id', 
    label: 'Asset Number', 
    minWidth: 170,
    type: 'custom',
    element: <AssetNumberElement />,
  },
  { id: 'description', label: 'Description', minWidth: 100 },
  {
    id: assetReadingKM!.key,
    label: assetReadingKM!.label || 'Current KMs',
    align: 'center'
  },
  {
    id: assetReadingHour!.key,
    label: assetReadingHour!.label || 'Current Hours',
    align: 'center'
  },
  {
    id: nextServiceDueField!.key,
    label: nextServiceDueField!.label || 'Next Service Due',
  },
  { 
    id: 'depot', label: 'Depot', minWidth: 100,
    format: (value: any) => {
      if (!value) return null
      const { id, name } = value
      return <React.Fragment>
        <Typography>
          { name }
        </Typography>
      </React.Fragment>
    }
  },
  { 
    id: 'crews', label: 'Crews', minWidth: 100,
    format: (value: any) => {
      if (!value) return null
      return <React.Fragment>
        { value.map((crew: any) => {
          return <div key={crew.id}>
            <CrewStatic crew={crew} />
          </div>
        })}
      </React.Fragment>
    }
  },
  { 
    id: 'active', 
    label: 'Status', 
    minWidth: 100,
    type: 'custom', 
    element: <BoralSwitch />,
  },
  { 
    id: 'options', 
    label: 'Options', 
    type: 'custom',
    element: <OptionsElement />,
  },
]

interface Props {
  assets: MobileAsset[],
  onEditAsset(asset: any): void,
  onSuccess(): void,
  searchTerm: any,
}

function MobileAssetTable (props: Props) {
  const {
    assets = [],
    searchTerm,
    onEditAsset,
    onSuccess,
  } = props
  const history = useHistory()
  const { alertDone, alertError } = useSnackbar()
  const { canEditMobileAssetsInMgmt, canChangeStatusForMobileAssetsInMgmt } = usePermission()
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { confirm } = useConfirm()
  const [ selectedAsset, setSelectedAsset ] = useState<null | MobileAsset>(null)
  const [ sortAssetNumber, setsortAssetNumber ] = useState<String>('asc');
  const [ currentAssets, setCurrentAssets] = useState<MobileAsset[]>([]);
  useEffect(() => {
 
    //setCurrentAssets(assets);
    setCurrentAssets(multiSort(assets,{'id':'asc'})); //sort by ascending by default
   
  }, [assets])
  useEffect(() => {
    if(searchTerm!=''){
      searchAssets(searchTerm);
    }else{
      setCurrentAssets(assets);
    }
   
  }, [searchTerm])
  const handleLinkClicked = (row: MobileAsset) => () => {
    history.push({
      pathname: `/mobile-asset/${row.id}`
    })
  }
  
  const handleEditClicked = (row: MobileAsset) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onEditAsset(row)
  }
  function searchAssets(searchTxt: any){
    const finlAssets: MobileAsset[] = [];
    for(var i = 0 ;i <assets.length;i++){
      var mainStr = assets[i].id.toLowerCase();
      var searchStr= searchTxt.toLowerCase();
      if(mainStr.indexOf(searchStr)!= -1) {
           finlAssets.push(assets[i]);
      }
    }
    setCurrentAssets(finlAssets);
  }
  const handleStatusChange = (row: MobileAsset) => async (e: React.ChangeEvent<HTMLInputElement>) => {
    const upcoming = e.target.checked
    try {
      await apiMobileAssetsUpdate({
        ...row,
        active: upcoming,
      })
      alertDone()
      onSuccess()
    }
    catch(e) {
      alertError()
    }
  }

  const handleMoreClicked = (row: MobileAsset) => (e: React.MouseEvent<any>) => {
    setAnchorEl(e.currentTarget)
    setSelectedAsset(row)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const beforeResolve = async () => {
    if (selectedAsset === null) {
      return 
    }
    try {
      setAnchorEl(null)
      await confirm({
        title: 'Confirm',
        message: `Are you sure all the issues indicated on ${selectedAsset.id} have been resolved?`,
        confirmButtonText: 'Yes, mark as resolved',
      })
      handleResolve()
    } catch (e) {}
  }
  const handleResolve = async () => {
    try {
      const result = await apiMobileAssetsUpdateError({
        id: selectedAsset!.id,
        error: false,
      })
      alertDone()
    } catch (e) {
      alertError()
    }
  } 
  const handleSort =  (event: any) => {
    if(event === 'id') {
      if(sortAssetNumber == 'asc') {
        setsortAssetNumber('desc');
       
        setCurrentAssets( multiSort(assets,{'id':'desc'}));
        return;
      }else{
        setsortAssetNumber('asc');
        setCurrentAssets( multiSort(assets,{'id':'asc'}));
        return;
      }
    }
  }
  /* function to sort array of assets */

  function multiSort(array: any, sortObject: any = {}) {
  const sortKeys = Object.keys(sortObject);
 // console.log(sortKeys);
    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
        return array;
    }
  
    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
        sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
    }
  
  
    const keySort = (a: any, b: any, direction: any) => {
        direction = direction !== null ? direction : 1;
  
        if (a === b) { // If the values are the same, do not switch positions.
            return 0;
        }
          //console.log(a,b);
        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
    };
  
    return array.sort((a:any, b: any) => {
        let sorted = 0;
        let index = 0;
  
        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
            const key = sortKeys[index];
            
            if (key) {
                const direction = sortObject[key];
                sorted = keySort(a[key], b[key], direction);
                index++;
            }
        }
  
        return sorted;
    });
  }
  /* function to sort array of assets ends */
  return <Wrapper>
    <Table stickyHeader aria-label='sticky table'>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <HeaderCell
              onClick={() => {
                handleSort(column.id)
              }}
              key={column.id}
              align={column.align}
              style={{ 
                minWidth: column.minWidth,
                width: column.width,
                cursor:'pointer'
              }}
              className={column.id}
            >
            
             {(column.id == 'id' && sortAssetNumber == 'asc') && <ArrowDownward  style={{position: 'absolute'}}  />}
             {(column.id == 'id' && sortAssetNumber == 'desc') && <ArrowUpward  style={{position: 'absolute'}}   />}
             <label style={{marginLeft:'25px',cursor: 'pointer'}}>
            {column.label} 
            </label>
            </HeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {currentAssets.map((row: MobileAsset) => {
          return (
            <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
              {columns.map(column => {
                const { id, format, type, element, align = 'left' } = column
                const value = row[id as keyof MobileAsset]
                let finalElement

                if (type === 'custom') {
                  let additionalProps: any = {
                    row: row
                  }
                  if (id === 'actions') {
                    additionalProps.disabled = !canEditMobileAssetsInMgmt
                    additionalProps.onClick = handleEditClicked(row)
                  } else if (id === 'active') {
                    additionalProps.disabled = !canChangeStatusForMobileAssetsInMgmt
                    additionalProps.checked = value
                    additionalProps.onChange = handleStatusChange(row)
                  } else if (id === 'id') {
                    additionalProps.onClick = handleLinkClicked(row)
                  } else if (id === 'options') {
                    additionalProps.onClick = handleMoreClicked(row)
                  } 
                  finalElement = React.cloneElement(element, { ...element.props, ...additionalProps })
                } else {
                  if (id === 'assetReadingKM') {
                    const rowValue = row.type.unit == 2 ? "N/A" : value
                    finalElement = format && typeof format === 'function' ? format(rowValue) : rowValue
                  } else if (id === 'assetReadingHour') {
                    const rowValue = row.type.unit == 1 ? "N/A" : value
                    finalElement = format && typeof format === 'function' ? format(rowValue) : rowValue
                  } else {
                    finalElement = format && typeof format === 'function' ? format(value) : value
                  }
                }
                
                return (
                  <TableCell key={id} align={align}>
                    { finalElement }
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
    <Menu
      id='table-options'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={beforeResolve}>Mark as resolved</MenuItem>
    </Menu>
  </Wrapper>
}

const Wrapper = styled.div``
const HeaderCell = styled(TableCell)`
  border-bottom: none;
  font-size: 16px;
  color: #645E5E;
  background-color: #F5F5F5;
`
export default observer(MobileAssetTable)