import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompFlatTable from '@src/modules/SiteResoureRequirements/comps/FlatTable'
import { SRRQSSsphalt } from '@src/types/SRR'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import useModal from '@src/components/FormModal/useModal'
import { JobType } from '@src/types/JobType'
import MarginTop from '@src/components/Layout/MarginTop'
import TextField from '@material-ui/core/TextField'
import { apiSrrQaUpdate } from '@src/apis/srr-qa-update'
import Modal from '@src/components/FormModal/Modal'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const CompSSQuality = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<any>({}) 
  const { serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data: SRRQSSsphalt | null = srr.qa.spraySeal

  if (data === null) {
    return null
  }

  const handleSave = async (form: any) => {
    console.log(form)
    try {
      const result = await apiSrrQaUpdate({
        srrId: srr.id, 
        serialId,
        jobType: JobType.SpraySeal,
        partial: {
          ...form,
          ...requirementFields
            .map((field) => {
              return {
                key: field.key,
                value: form.requirements.includes(field.key),
              }
            })
            .reduce((acc, curr) => {
              return {
                ...acc,
                [curr.key]: curr.value
              }
            }, {})
        }
      })
      alert({
        message: `Done.`
      })
      refresh()
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel title={`QA Requirements for Spray Seal`}>
    <CompFlatTable<any>
        data={data}
        fields={tableFields}
        editable={canEditSubmitSRR}
        onEdit={() => {
          openAndSetData({
            ...data,
            requirements: requirementFields
                .filter((field) => data[field.key] === true)
                .map((field) => field.key)
          })
        }}
      />
      <MarginTop>
        <TextField
          InputLabelProps={{ shrink: true }}
          multiline
          disabled
          variant='outlined'
          rows={3}
          label={`Comments`}
          value={data.comments || ''}
        />
      </MarginTop>
      { open && 
        <Modal<FieldsInModal>
          maxWidth='xs'
          open={open}
          title='Quality Requirements - Spray Seal'
          data={{
            rowData: modalData,
            fields: modalFields,
            columns: [
              {
                title: 'Information',
                fieldKeys: [
                  'jobSpec',
                  'requirements',
                  'comments',
                ],
              },
            ]
          }}
          onSuccess={handleSave}
          onClose={closeModal}
          onCancel={closeModal}
        /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`
const commentsField: FormTableField<SRRQSSsphalt | FieldsInModal> = {
  key: 'comments',
  type: FormTableFieldType.Textarea,
  label: 'Comments',
}
const jobSpecField: FormTableField<SRRQSSsphalt | FieldsInModal> = {
  key: 'jobSpec',
  type: FormTableFieldType.Text,
  label: 'Job Specification',
}
const requirementFields: FormTableField<SRRQSSsphalt>[] = [
  {
    key: 'holdPointReleaseForm',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Hold Point Release Form',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'ballPenTesting',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Ball Pen Testing',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'labTechOnSite',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Lab Tech Onsite',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'sealDesign',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Seal Design Testing',
    format: formatBooleanOrNullToYesNo,
  },
]
export const SRRQualitySpraySealFields: FormTableField<SRRQSSsphalt>[] = [
  jobSpecField,
  ...requirementFields,
  commentsField,
]
const tableFields = SRRQualitySpraySealFields.filter((field) => field.key !== 'comments')

type FieldsInModal = {
  jobSpec: string,
  requirements: any[],
  comments: string,
}
const modalFields: FormTableField<FieldsInModal>[] = [
  jobSpecField,
  {
    key: 'requirements',
    label: 'Select QA Requirements',
    type: FormTableFieldType.CheckboxGroup,
    options: requirementFields.map((requirement) => ({ value: requirement.key, label: requirement.label })),
  },
  commentsField,
]
export default CompSSQuality
