import { ax } from '../../utils/axios/axios'
import { BASE_API_URL } from '../../constants'

export interface Options {
  query?: string,
}

export const apiCrewSearch = ({
  query
} : Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/crews`,
    params: {
      search: query,
      active:true
    }
  }
  return ax(options)
}