import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { createMuiTheme, withStyles, useTheme } from '@material-ui/core/styles'

const muiTheme = createMuiTheme({
  typography: {
    // useNextVariants: true,
  },
  palette: {
    background: {
      default: '#fff'
    },
    primary: {
      main: '#009688'
    },
    secondary: {
      main: '#009688',
    },
  }
})

const appTheme = {
  colors: {
    'boral-light': '#72AD5C',
    'boral-dark': '#365D14',
  }
}

export const theme = {
  app: appTheme,
  mui: muiTheme,
}

const ThemeProvider = ({
  // theme, 
  children,
}) => {
  return (
    <React.Fragment>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <StylesProvider injectFirst>
          <StyledThemeProvider theme={{ app: appTheme, mui: muiTheme }}>
            {children}
          </StyledThemeProvider>
        </StylesProvider>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

ThemeProvider.propTypes = {
  // theme: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
}

export default ThemeProvider

