import { useEffect, useState } from 'react'
import { apiShiftGetOne } from '@src/apis/shift-get-one'
import { useAppStore } from '@src/AppStore'
import { Shift } from '@src/types/Shift'

export function useShift(shiftId: string): [ Shift | null ] {
  const [ shift, setShift ] = useState<Shift | null>(null)
  const { serialId } = useAppStore()
  let active = false
  useEffect(() => {
    async function fetchShift() {
      try {
        const result = await apiShiftGetOne({
          shiftId: shiftId,
          serialId,
        })
        if (active) {
          setShift(result.data.shift as Shift)
        }
      } catch(e) {
        
      }
    }
    if (!active) {
      active = true
      fetchShift()
    }
    return () => {
      active = false
    }
  }, [])
  return [ shift ]
}