import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { DndProvider, DropTargetMonitor, useDrop } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import InsertFileIcon from '@material-ui/icons/InsertDriveFile'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
// import FileInput from './FileInput'

const AcceptingFileMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
const HTMLInputAccept = AcceptingFileMimeTypes.join(',') // 'image/jpeg, image/png, application/pdf'

interface Props {
  onDropOrSelect(files: FileList): void,
}
const FileBoxMain = ({
  onDropOrSelect,
}: Props) => {
  const { alert } = useSnackbar()
  const fileInputRef = useRef<any>(null)
  const [{ isActive, canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFilesDropped(monitor)
    },
    collect: monitor => ({
      isOver: monitor.isOver,
      canDrop: monitor.canDrop,
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  })
  /**
   * @description filter out files with wrong type, warn user
   */
  const handleFilesDropped = (monitor: DropTargetMonitor) => {
    if (monitor) {
      const filesDropped = monitor.getItem().files
      checkFileTypesBeforeHandOver(filesDropped)
    }
  }
  const handleFilesSelected = () => {
    if (fileInputRef) {
      checkFileTypesBeforeHandOver(fileInputRef.current.files)
    }
  }
  const checkFileTypesBeforeHandOver = (files: any) => {
    files = Array.from(files)
    const validFiles = files.filter((file: File) => AcceptingFileMimeTypes.includes(file.type))
    const inValidFiles = files.filter((file: File) => !AcceptingFileMimeTypes.includes(file.type))
    if (inValidFiles.length) {
      const inValidFileNames = inValidFiles.map((file: File) => `'${file.name}'`).join(', ')
      alert({
        type: 'warning',
        message: `${inValidFileNames} in the wrong file format. Please upload only .JPEG, .PNG or .PDF file formats`,
      })
    }
    // console.log(inValidFiles)
    if (validFiles.length) {
      onDropOrSelect(validFiles)
    }
  }
  return (
    <TargetBoxWrapper ref={drop} isActive={isActive}>
      <Icons>
        <InsertPhotoIcon />
        <InsertFileIcon />
      </Icons>

      { isActive ? 'Release to drop' :
        <React.Fragment>
          <label htmlFor='input-rs-upload-file'>
            <DropMessage>
              Drop your images(.jpeg or .png) or PDFs here or  <HighlightSelectText>select</HighlightSelectText> from your computer
            </DropMessage>
          </label>
          <FileInput
            ref={fileInputRef}
            accept={HTMLInputAccept}
            id='input-rs-upload-file'
            multiple
            type='file'
            onChange={handleFilesSelected}
          />
        </React.Fragment>
      }


    </TargetBoxWrapper>
  )
}

// should wrap, ref https://github.com/react-dnd/react-dnd/issues/1494#issuecomment-534387135
const FileBox = (props: Props) => {
  return <DndProvider backend={HTML5Backend}>
    <FileBoxMain {...props} />
  </DndProvider>
}

const TargetBoxWrapper = styled.div<{ isActive: boolean }>`
  /* border: 1px solid gray; */
  background-color: #F5F5F5;
  height: 200px;
  max-width: 520px;
  /* padding: 2rem; */
  text-align: center;
  ${p => {
    if (p.isActive) {
      return css`border: 1px dotted #fae9ba;`
    }
  }}
`
const Icons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 77px;
  padding-bottom: 20px;
  color: #9A9898;
  svg {
    margin-right: 18px;
    font-size: 1.6rem;
  }
`
const DropMessage = styled.div`
  padding: 0 8px;
  color: #9E9E9E;
  cursor: pointer;
`
const HighlightSelectText = styled.span`
  color: #009688;
  text-decoration: underline;
`
const FileInput = styled.input`
  display: none;
`

export default FileBox