import React, { useRef, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Point, CircleHandle } from './Arrow'

interface Props {
  from: Point,
  to: Point,
  stroke?: string,
  text: string,
  hovered?: boolean,
  onMouseOver(): void,
  onMouseDown(e: React.MouseEvent<SVGSVGElement, MouseEvent>): void,
  onBottomRightMouseDown(e: React.MouseEvent<SVGCircleElement, MouseEvent>): void,
}

const CompTextBoxElement = (props: Props) => {
  const {
    from,
    to,
    stroke = '#707070',
    text,
    hovered = false,
    onMouseOver,
    onMouseDown,
    onBottomRightMouseDown,
  } = props
  const width = to.x - from.x
  const height = to.y - from.y

  const ref = useRef<any>()
  const [ rectW, setRectW ] = useState(0)
  const [ rectH, setRectH ] = useState(0)
  useLayoutEffect(() => {
    if (ref.current) {
      const bBox = (ref.current as SVGTextElement).getBBox()
      setRectW(bBox.width)
      setRectH(bBox.height)
    }
  }, [ref, text])

  function buildPath() {
    const dM = `M 0, 0`
    const dRect = `H ${width} V ${height} H 0 Z`
    return `${dM} ${dRect}`
  }

  return <Wrapper
    transform={`translate(${from.x}, ${from.y})`} 
    onMouseOver={onMouseOver}
    onMouseDown={onMouseDown}
    // onClick={onClick}
  >
    <path 
      d={buildPath()} 
      stroke={stroke} 
      strokeWidth={3}
      fill='white'
    />
    <text ref={ref} 
      x={(width - rectW) / 2}
      y={(height + rectH - 6) / 2}  
      fontSize='16' 
      fill='black' 
      textAnchor='start'
    >
      {text}
    </text> 
    { hovered && <CircleHandle r='5' cx={width} cy={height}  
      onMouseDown={
        (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
          e.stopPropagation()
          onBottomRightMouseDown(e)
        }
      } 
    />
    }
  </Wrapper>
  // const ref = useRef<any>()
  // const [ rectW, setRectW ] = useState(0)
  // const [ rectH, setRectH ] = useState(0)
  // const rectWidth = 200 * text.length/ 16
  // useLayoutEffect(() => {
  //   if (ref.current) {
  //     const bBox = (ref.current as SVGRectElement).getBBox()
  //     setRectW(bBox.width)
  //     setRectH(bBox.height)
  //   }
  // }, [ref])
  // const renderBgRect = () => {
  //   if (!ref.current) {
  //     return null
  //   }
  //   return <rect x={0} y={0} width={rectW} height={rectH} fill='#fbe599' stroke='#000' strokeWidth='2'/>
  // }
  // return <g transform={`translate(${x}, ${y})`}>
  //   { renderBgRect() }
  //   <text ref={ref} x={0} y={20} fontSize='20' fill='black'>{text}</text> 
  // </g>
}

const Wrapper = styled.g`
  &:hover {
    cursor: move;
  }
  text {
    user-select: none;
  }
`

export default CompTextBoxElement