import React, { useState } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Collapse from '@material-ui/core/Collapse'
import EditIcon from '@material-ui/icons/Edit'

export interface FormPanelProps {
  title: string,
  subtitle?: string,
  children: React.ReactNode,
  actionEdit?: boolean,
  onEdit?(): void,
}

const FormPanel = (props: FormPanelProps) => {
  const { 
    title,
    subtitle, 
    children,
    actionEdit = false,
    onEdit,
  } = props
  const [ collapsed, setCollapsed ] = useState<boolean>(true)
  const handleToggle = () => {
    setCollapsed(!collapsed)
  }
  const handleEdit = () => {
    if (onEdit && typeof onEdit === 'function') {
      onEdit()
    }
  }
  return <Wrapper>
    <Divider />
    <TitleRow>
      <Title>{title}</Title> <SubTitle>{subtitle}</SubTitle>
      <Actions>
        { actionEdit && <IconButton size='small' onClick={handleEdit}><EditIcon /></IconButton> }
        <IconButton size='small' onClick={handleToggle}><ArrowDropDownCircleIcon /></IconButton>
      </Actions>
    </TitleRow>
    <Collapse in={collapsed}>
      <ChildrenWrapper>
        { children }
      </ChildrenWrapper>
    </Collapse>
  </Wrapper>
}

const Wrapper = styled.div`
  margin-bottom: 40px;
`
const Divider = styled.div`
  height: 2px;
  background-color: #E3E0E0;
`
const TitleRow = styled.div`
  display: flex;
  height: 32px;
  line-height: 32px;
  margin: 16px 0 18px;
`
const Title = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 500;
  margin-right: 15px;
`
const SubTitle = styled.div`
  flex-grow: 1;
  color: #707070;
  font-size: 20px;
  font-weight: 500;
  font-style:italic;
`
const Actions = styled.div`

`
const ChildrenWrapper = styled.div`
  padding-bottom: 48px;
`
export default FormPanel