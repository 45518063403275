import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CompPdfDialog from '../9-Summary/PdfDialog'
import { apiDrsPdf } from '@src/apis/drs-pdf'
import _get from 'lodash/get'
import { useAppStore } from '@src/AppStore'
import PDFIcon from '@material-ui/icons/PictureAsPdf'
import { RoleId } from '@src/types/common/RoleId'
import { useDRS } from '../../useDRS'
import { getDRSPdfName } from '../../utils/getDRSPdfName'
import { DRSStatus } from '@src/types/drs/DRSStatus'
import { apiDRSUpdateStatus, apiDRSUpdateStatusAndEnterSiteDiaryNumber } from '@src/apis/drs-update-status'
import CompDRSSiteDiaryNumberDialog from './SiteDiaryNumberDialog'
import { useSnackbar } from '@src/components/GlobalSnackbar'
interface Props {

}

const CompAdminActions = (props: Props) => {
  const {

  } = props
  const { drs, drsId, refresh } = useDRS()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { stateId } = useAppStore()
  const [isSiteDiaryNumberDialogOpen, setIsSiteDiaryNumberDialogOpen] = useState<boolean>(false)
  const [pdfDialogOpen, setPdfDialogOpen] = useState<boolean>(false)
  const { alert, alertDone, alertError } = useSnackbar()
  if (drs === null) {
    return null
  }
  const status = drs.jobDetails.status.id
  const handlePdfDialogClose = () => {
    setPdfDialogOpen(false)
  }
  const onPdfClick = () => {
    setPdfDialogOpen(true)
  }

  const handlePdfDownloadConfirmed = async () => {
    if (!drs) {
      return
    }
    setIsLoading(true)
    try {
      const result = await apiDrsPdf({
        drsId: drsId + '',
        stateId,
        roleId: RoleId.Administrator,
      })

      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url
      const pdfName = getDRSPdfName(drs)
      link.setAttribute('download', pdfName)
      document.body.appendChild(link)
      link.click()
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const handleSiteDiaryNumberButtonClick = () => {
    setIsSiteDiaryNumberDialogOpen(true)
  }
  const handleSiteDiaryNumberConfirm = async (siteDiaryNumber: string) => {
    if (!siteDiaryNumber) {
      return
    }
    setIsSiteDiaryNumberDialogOpen(false)
    try {
      const result = await apiDRSUpdateStatusAndEnterSiteDiaryNumber({
        drsId,
        siteDiaryNumber,
      })
      if (result.data) {
        alert({
          message: 'DRS has been completed.',
        })
        refresh()
      }
    } catch (e) {
      alertError()
    }
  }

  return <Wrapper>
    <TitleRightWrapper>
      <React.Fragment>
        <PDFButton
          variant='contained'
          onClick={onPdfClick}
        >
          Create PDF
          </PDFButton>
        <CompPdfDialog
          open={pdfDialogOpen}
          onClose={handlePdfDialogClose}
        />
      </React.Fragment>
      {drs.jobDetails.siteDiaryNumber ?
        <React.Fragment>
          <SiteDiaryNumberLabel component='span'>Site Diary Number: </SiteDiaryNumberLabel>
          <SiteDiaryNumberValue component='span'>{drs.jobDetails.siteDiaryNumber}</SiteDiaryNumberValue>
        </React.Fragment>
        :
        (status === DRSStatus.APPROVED_PM ?
          <Button
            variant='contained'
            color='primary'
            onClick={handleSiteDiaryNumberButtonClick}
          >Enter Site Diary Number</Button> : null)
      }
    </TitleRightWrapper>
    <CompDRSSiteDiaryNumberDialog
      open={isSiteDiaryNumberDialogOpen}
      handleClose={() => setIsSiteDiaryNumberDialogOpen(false)}
      handleOk={handleSiteDiaryNumberConfirm}
    />
  </Wrapper>
}

const Wrapper = styled.div`

`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const TitleRightWrapper = styled.div`
  align-self: flex-start;
  margin-right: 10px;
`
const SiteDiaryButton = styled(Button)`
  
`
const SiteDiaryNumberLabel = styled(Typography)`
  color: #009688;
  font-size: 24px;
  font-weight: bold;
` as typeof Typography
const SiteDiaryNumberValue = styled(Typography)`
  color: #9A9898;
  font-size: 24px;
` as typeof Typography
const PDFButton = styled(Button)`
  margin-right: 10px;
  color: #009688;
  background: #F5F5F5;
`

export default CompAdminActions
