import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish'

interface Props {
  onClick(): void,
}

const CompUpdateButton = (props: Props) => {
  const {
    onClick
  } = props
  return <Wrapper 
    variant='contained' 
    startIcon={<PublishIcon />}
    onClick={onClick}
    size='small'
    >
    Update
  </Wrapper>
}

const Wrapper = styled(Button)`
  color: #fff;
  background-color: #D32929;
  font-size: 10px;
  padding: 3px 7px;
`

export default CompUpdateButton
