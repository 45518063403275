import React, { useState, useEffect} from 'react';
import '../Deviceinfo.css';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Container, Button, FormControlLabel, Checkbox, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, GridList, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import moment from 'moment';
import { apiDeviceFilter } from '../../../apis/device-filter';
import { useHistory } from 'react-router-dom'
import { checkToken } from '../../GenerateToken';
import { Autorenew } from '@material-ui/icons';
import { Pie } from 'react-chartjs-2';

const peiChartDefault = [0, 0, 0, 0, 0, 100];
let pieChartData: any = {
    labels: ['Isolated', 'Ignition Off', 'Ignition On', 'Engine Running', 'Paving', 'Offline'],
    datasets: [{
        label: '# of Votes',
        data: peiChartDefault,
        backgroundColor:[
            "rgb(49, 208, 217, 1)",
            "rgb(252, 192, 39, 1)",
            "rgb(91, 155, 213, 1)",
            "rgb(0, 229, 166, 1)",
            "rgb(220, 57, 18, 1)",
            "rgb(144, 144, 144, 1)"
        ],
        borderWidth: 0,
    }],
}
const pieChartoptions = {
    plugins: {
        legend: {
            display:false
        },
        tooltip: {
            callbacks: {
                title: (tooltipItems: any) => {
                    const label = tooltipItems[0].label;
                    const dataset = tooltipItems[0].dataset.data;
                    const currentValue = tooltipItems[0].parsed;
                    let total = 0;
                    for (let i = 0; i < dataset.length; i++) {
                      total += dataset[i];
                    }
                    const percentage = (currentValue / total * 100).toFixed(0);
                    return `${percentage}%`;
                },
            },
        },
    }
};
let progressDiv: any, pieChartRef: any;
const DeviceRawData = (props: any) => {
    const [infoArr, setInfoArr]: any = useState([])
    const [totalRecords, settotalRecords] = React.useState(0);
    const [startDate, setStartDate] = useState(moment().subtract(10, 'minutes'));
    const [endDate, setEndDate] = useState(moment());
    const [startTime, setStart] = useState(moment().subtract(30, 'minutes').unix().valueOf());
    const [endTime, setEnd] = useState(moment().unix().valueOf());
    const [checkFilter, setCheckFilter] = useState('');
    const [TableProgress, setTableProgress]: any = useState(true);
    const progressbarRef = React.createRef();
    let pieChart: any = React.createRef();
    const history = useHistory();
    let deviceid = props.match.params.id;

    useEffect(() => {
        pieChartRef = pieChart;
        progressDiv = progressbarRef.current;
        fetchDeviceFilter();
        return () => {
            const state = {...pieChartData};
            for(let i = 0; i < 6; i++) {
                state.datasets[0].data[i] = i < 5 ? 0 : 100;
            }
        };
    }, []);

    const loadDeviceinfo = async () => {
        progressDiv.classList.remove('per-3');
        settotalRecords(0);
        setTableProgress(true);
        setFilterData(startTime, endTime);
        setCheckFilter('');
    }

    const defaultTabledata = (data: React.ChangeEvent<HTMLInputElement>) => {
        progressDiv.classList.remove('per-3');
        settotalRecords(0);
        setTableProgress(true);
        setCheckFilter(data.target.value);
        const end = moment().unix().valueOf();
        if(parseInt(data.target.value) == 0) {
            const start = moment().subtract(30, 'minutes').unix().valueOf();
            setFilterData(start, end);
        }else if(parseInt(data.target.value) == 1) {
            const start = moment().subtract(1, 'hours').unix().valueOf();
            setFilterData(start, end);
        }else if(parseInt(data.target.value) == 2) {
            const start = moment().subtract(4, 'hours').unix().valueOf();
            setFilterData(start, end);
        }
    }

    const fetchDeviceFilter = async () => {
        const end = moment().unix().valueOf();
        const start = moment().subtract(24, 'hours').unix().valueOf();
        setFilterData(start, end);
    }

    const setFilterData = async (start: any, end: any) => {
        const status = await checkToken();
        if(status) {
            // const result: any = await apiDeviceFilter(getCookieAccessToken(), deviceid, start, end);
            const result: any = await apiDeviceFilter(localStorage.getItem('apiToken'), deviceid, start, end);
            if(!result){fetchDeviceFilter()}
            if(result && result.data.length > 0) {
                settotalRecords(result.data.length);
                var indexProgess = 0;
                var progress = setInterval(() => {
                    progressDiv.classList.remove(`per-${indexProgess}`);
                    indexProgess += 1;
                    progressDiv.classList.add(`per-${indexProgess}`);
                    if(indexProgess === 3){
                        clearInterval(progress);
                        setFilteredChartData(result.data);
                        result.data = result.data.reverse();
                        setInfoArr(result.data);
                        setTableProgress(false);
                    }
                }, 300);
            } else if(result && result.data.length == 0){
                const state = {...pieChartData};
                for(let i = 0; i < 6; i++) {
                    state.datasets[0].data[i] = i < 5 ? 0 : 100;
                }
                pieChartRef.update();
                settotalRecords(0);
                progressDiv.classList.add('per-3');
                setInfoArr([]);
                setTableProgress(false);
            }
        } else {
            history.push({
                pathname: '/dashboard/projects',
            })
        }
    }

    const setFilteredChartData = (data: any) => {
        const state = {...pieChartData};
        for(let i = 0, len = data.length; i < len; ++i){
            if(data[i].state && data[i].state > 0) {
                state.datasets[0].data[data[i].state] += 1;
            }
            if(!data[i].state || data[i].state == 0) {
                state.datasets[0].data[5] += 1;
            }
        }
        pieChartRef.update();
    }

    const setstartdate = (date:any) => {
        setStart(moment(date).unix().valueOf());
        setStartDate(date);
    }
    const setenddate = (date:any) => {
        setEnd(moment(date).unix().valueOf());
        setEndDate(date);
    }

    const status = (state: any = 0) => {
        if(state == 1){return "Isolated"}
        if(state == 2){return "Ignition Off"}
        if(state == 3){return "Ignition On"}
        if(state == 4){return "Engine Running"}
        if(state == 5){return "Paving"} else {return "Unknown"}
    }

    return <Container maxWidth='xl' style={{padding: '15px',  paddingBottom: '5px'}}>
            <div className="date-picker-area">
                <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="picker">
                            <span>From: </span>
                            <KeyboardDateTimePicker value={startDate} onChange={setstartdate} name="start" onError={console.log} maxDate={moment().subtract(10, 'minutes')}
                                format="MMM DD, YYYY HH:mm a" InputProps={{ readOnly: true }}/>
                        </div>
                        <div className="picker">
                            <span>To: &nbsp;&nbsp;&nbsp;</span>
                            <KeyboardDateTimePicker value={endDate} onChange={setenddate} name="end" onError={console.log} maxDate={moment()}
                                format="MMM DD, YYYY HH:mm a" InputProps={{ readOnly: true }}/>
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <div style={{width: 'calc(100% - 255px)'}}>
                    <ActionButton variant="outlined" onClick={loadDeviceinfo} disabled={TableProgress} > {TableProgress && <Autorenew className='progress-bar' />} {TableProgress ? `Loading...` : `Load Data`}  </ActionButton>
                    <FormControlLabel control={ <Checkbox checked={checkFilter == '0' ? true : false} onChange={defaultTabledata} name="default" value="0" /> } label="Last 30min" />
                    <FormControlLabel control={ <Checkbox checked={checkFilter == '1' ? true : false} onChange={defaultTabledata} name="default" value="1" /> } label="Last 1hours" />
                    <FormControlLabel control={ <Checkbox checked={checkFilter == '2' ? true : false} onChange={defaultTabledata} name="default" value="2" /> } label="Last 4hours" />
                    <div style={{backgroundColor: '#dbdbdb', width: '100%', margin: '30px 0', height: '20px'}}>
                        <span ref={progressbarRef as React.RefObject<HTMLDivElement>} className='progress-bar-slide'>{totalRecords} Records</span>
                    </div>
                </div>
            </div>
            <GridList cols={2} cellHeight="auto">
                <Grid style={{width: '75%'}}>
                <TableContainer style={{maxHeight: 'calc(100vh - 231px)', height: 'calc(100vh - 231px)'}}>
                        <Table id="deviceinfo-table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCellDeviceInfo>Date & Time</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Project #</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Location Lat/Lon</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Paver Speed mtrs/min</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Paver Status</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Paver Temp (deg c)</TableCellDeviceInfo>
                                    <TableCellDeviceInfo>Ambient Temp (deg c)</TableCellDeviceInfo>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {infoArr.map((row :any, index: any) => {
                                    return <TableRow key={index}>
                                        <TableCell>{moment.unix(row.ts).format("DD-MMM-YYYY HH:mm:ss")}</TableCell>
                                        <TableCell>{row.deviceid}</TableCell>
                                        <TableCell>{row.gps_lat} {row.gps_lon}</TableCell>
                                        <TableCell>{row.cp3}</TableCell>
                                        <TableCell>{status(row.state)}</TableCell>
                                        <TableCell>{row.current1}</TableCell>
                                        <TableCell>{row.ambient}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid style={{width: '25%', padding: "15px"}}>
                    <h3 style={{margin: "0"}}>Utilisation</h3>
                    <div style={{width: '265px', margin: '0 auto'}}>
                        <Pie data={pieChartData} options={pieChartoptions} ref={(reference: any) => {pieChart = reference}} redraw={true}/>
                        <div className='pie-info'>
                            <span className='prev-circle'>Isolated</span>
                            <span className='prev-circle cl1'>Ignition Off</span>
                            <span className='prev-circle cl2'>Ignition On</span>
                            <span className='prev-circle cl3'>Engine Running</span>
                            <span className='prev-circle cl4'>Paving</span>
                            <span className='prev-circle cl5'>Offline</span>
                        </div>
                    </div>
                </Grid>
            </GridList>
    </Container>;
};

const TableCellFirst = styled(TableCell)({
    borderBottom: '0 !important',
    padding: '4px 16px'
});
const TableCellCus = styled(TableCell)({
    borderBottom: '0 !important',
    padding: '4px 16px',
    textAlign: 'center'
});
const TableCellDeviceInfo = styled(TableCell)({
    color: '#ffffff !important',
    fontWeight: 700,
    border: '2px solid',
    background: '#002a42'
});
const ActionButton = styled(Button)({
    background: "#00999b",
    color: "#ffffff",
    fontSize: "13px",
    marginRight: '15px',
    textTransform: "capitalize",
    '&:hover' : {
      background: "#00999b !important",
      color: "#ffffff !important"
    },
    '&:disabled' : {
        background: "#00999b61 !important",
        color: "#ffffff !important"
    }
});

export default DeviceRawData