import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { useAppStore } from '@src/AppStore'
import { capWord } from '@src/utils/capWord'

const Welcome = () => {
  const { firstName } = useAppStore() 
  return (
    <WelcomeWrapper>
      <HiJack>
        { `Hi ${capWord(firstName)}!` }
      </HiJack>
      <WelcomeToYourWorkspace>
        Welcome to your Workspace
      </WelcomeToYourWorkspace>
    </WelcomeWrapper>
  )
}

const WelcomeWrapper = styled.div`
  margin-top: 55px;
  margin-bottom: 30px;
`
const HiJack = styled(Typography)`
  font-size: 40px;
  font-weight: bold;
  color: #0A9688;
`
const WelcomeToYourWorkspace = styled(Typography)`
  font-size: 24px;
`

export default Welcome