import _set from 'lodash/set'
import _get from 'lodash/get'
import shortid from 'shortid'
import { getJobSpecifications, getQualityOfWorkmanships, getDelayReasons, CHECKBOX_SELECT_OPTIONS, YES_NO_NULL_SELECT_OPTIONS, formatYesNoNullSelect } from '../Contants'
import { ADIDNameToValueLabel } from '../../../../../api-adaptors/id-name-to-value-label'
import moment from 'moment'

export default function getSummarySectionData(data: DRS.RootData) {
  const delayReasonPanels = getDelayReasonPanels(data)
  const summary = getSummaryPanel(data)

  const section = {
    label: 'SUMMARY',
    panels: [ 
      ...delayReasonPanels,
      summary,
    ]
  }
  return section
}

export function getDelayReasonPanels(data: DRS.RootData) {
  const label = `Delay Reason`
  const path = `summary.detail`
  let delayReasons = _get(data, path, [])
    

  const DelayOptions = delayReasons.map((item: any) => {
    return {
      value: item.delayReason.id,
      label: item.delayReason.description,
    }
  })
  const delayReasonsPanels = delayReasons
    
    .map((reason: any, index: number) => {
      return {
        // label: `${label} #${index + 1}`,
        repeatable: true,
        // removable: true,
        repeatItem: {
          duration: '',
        },
        repeatPath: path,
        path: `${path}.${index}`,
        fields: getDelayReasonFields({ 
          path: `${path}.${index}`,
          options: DelayOptions,
        }),
        raw: reason,
      }
    })
    .filter((item: any) => {
      const reason = item.raw
      return shouldReasonShown(reason)
    })
    .map((reason: any, index: number) => {
      return {
        ...reason,
        label: `${label} #${index + 1}`,
      }
    })
  return delayReasonsPanels
}

export function shouldReasonShown (reason: any) {
  return (
    Number(reason.actual.duration) !== 0 ||
    Number(reason.edit.duration) !== 0 ||
    // _show_flag_ to show only in the ui, because the duration is not changed yet, when refreshed, it will disappear
    reason._show_flag_
  )
}

function getDelayReasonFields({
  path,
  options,
}: {
  path: string,
  options: any[],
}) {
  return [
    {
      label: 'Delay Reason',
      type: 'select',
      path,
      relativePath: 'summary.delayReason',
      editable: false,
      options,
      format: (value: any) => value ? value.description : '',
    },
    {
      label: 'Delay Duration',
      type: 'number',
      path,
      relativePath: 'duration',
      editable: true,
    },
  ]
}



export function getSummaryPanel(data: DRS.RootData) {
  const label = `DRS Summary`
  const path = `summary.summary`
  const summaryPanel = {
    label,
    repeatable: false,
    path,
    fields: getFields(data, path),
  }
  return summaryPanel
}

function getFields(data: DRS.RootData, path: string) {
  return [
    {
      label: 'Quality of Workmanship',
      type: 'select',
      path,
      relativePath: 'qualityOfWorkmanship',
      editable: true,
      options: [
        {
          value: null,
          label: 'Null',
        },
        ...getQualityOfWorkmanships().map(item => ADIDNameToValueLabel(item)),
      ],
      format: (value: any) => {
        if (value) {
          return value.description
        } else {
          return ''
        }
      },
    },
    {
      label: 'Satisfied Customer Needs',
      type: 'select',
      path,
      relativePath: 'satisfiedCustomerNeeds',
      editable: true,
      options: YES_NO_NULL_SELECT_OPTIONS,
      format: (value: any) => formatYesNoNullSelect(value),
    },
    {
      label: 'Clean up at end of shift',
      type: 'select',
      path,
      relativePath: 'cleanUpAtEndOfShift',
      editable: true,
      options: YES_NO_NULL_SELECT_OPTIONS,
      format: (value: any) => formatYesNoNullSelect(value),
    },
    {
      label: 'Submitted By',
      type: 'text',
      path,
      relativePath: 'submittedBy',
      editable: false,
    },
    {
      label: 'Disclaimer',
      type: 'select',
      path,
      relativePath: 'submittedByDeclaration',
      editable: true,
      options: YES_NO_NULL_SELECT_OPTIONS,
      format: (value: any) => formatYesNoNullSelect(value),
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
    {
      label: 'View Photo & PDF Attachments',
      alwaysShow: true,
      editable: true,
      type: 'file',
      path,
    },
  ]
}

