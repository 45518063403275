import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSPageType, DRSSectionName } from '../../DRSPage'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import useModal from '@src/components/FormModal/useModal'
import { formatTransportTypeId, TransportTypeId } from '@src/types/TransportTypeId'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import _get from 'lodash/get'
import { useDRS } from '../../useDRS'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import Modal from '@src/components/FormModal/Modal'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { DRSTransportTruck, DRSTransportTruckOuter } from '@src/types/drs/transport/Transport'

const PartDRSTransport = () => {
  const { drs, drsPageType, refresh, updateSectionViaPath } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const { alertError } = useSnackbar()
  const [open, modalData, openAndSetData, closeModal] = useModal<any>({})
  if (drs === null) {
    return null
  }
  const trucksOuter = drs.transport.boral.asphalt.delivery.trucks
  const trucksInner: DRSTransportTruck[] = trucksOuter.map((truckOuter: DRSTransportTruckOuter) => {
    return truckOuter.truck
  })
  function getFinalOuterTrucks(truckIds: string[], updatingItem: 'delivery' | 'profiling') {
    if (drs === null) {
      return []
    }
    const trucksOuter = drs.transport.boral.asphalt[updatingItem].trucks
    return trucksOuter.map((truckOuter: DRSTransportTruckOuter) => {
      return {
        ...truckOuter,
        plan: truckIds.includes(`${truckOuter.truck.id}`) ? true : false,
      }
    })
  }
  const orderedTonnesPerRoundField: FormTableField<TransportInModal> = {
    key: 'orderedTonnesPerRound',
    type: FormTableFieldType.Number,
    label: 'Ordered tonnes per round',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  }
  const modalFields: FormTableField<TransportInModal>[] = [
    orderedTonnesPerRoundField,
    {
      key: 'machines',
      label: 'Select Trucks',
      type: FormTableFieldType.CheckboxGroup,
      options: trucksInner.map((machine) => ({ value: `${machine.id}`, label: machine.description })),
    }
  ]
  const tableFields: FormTableField<any>[] = [
    {
      key: 'transportType',
      type: FormTableFieldType.Text,
      label: 'Transport Type',
      format: (cellData: any) => {
        if (cellData) {
          return formatTransportTypeId(cellData)
        }
        return ''
      },
    },
    orderedTonnesPerRoundField,
    ...trucksInner.map((machine: DRSTransportTruck) => {
      return {
        key: machine.id,
        label: machine.description,
        type: FormTableFieldType.BooleanRadioGroup,
        format: (cellData: boolean | null) => {
          if (cellData === null) {
            return ''
          } else {
            return formatBooleanToYesNo(cellData)
          }
        }
      }
    }),
  ]
  const hanldeEditRow = (row: TransportRow) => {
    function getSelectedMachineIds(row: TransportRow) {
      let result: string[] = []
      trucksInner.forEach((truck: DRSTransportTruck) => {
        // @ts-ignore, matching all selected machines in row data, { 1008: true|false }
        if (row[`${truck.id}`]) {
          result.push(`${truck.id}`)
        }
      })
      return result
    }
    const machines = getSelectedMachineIds(row)
    openAndSetData({
      ...row,
      machines,
    })
  }
  const handleSave = async (form: TransportRow) => {
    console.log(form)
    const retainedTransport = drs.transport.boral.asphalt
    const updatingItem: 'delivery' | 'profiling' = form.transportType === TransportTypeId.Delivery ? 'delivery' : 'profiling'
    try {
      if (form._no_ === undefined || form._type_ === undefined) {
        return
      }
      const orderedTonnesPerRound = form.orderedTonnesPerRound
      const trucks = getFinalOuterTrucks(form.machines, updatingItem)
      const result = await updateSectionViaPath({
        sectionName: DRSSectionName.Transport,
        path: `boral.asphalt`,
        data: {
          ...retainedTransport,
          [updatingItem]: {
            ...retainedTransport[updatingItem],
            plan: {
              orderedTonnesPerRound,
            },
            trucks,
          },
          performed: true,
        },
      })
      refresh()
      closeModal()
    } catch (e) {
      alertError()
    }
  }

  function getTableData() {
    if (drs === null) {
      return []
    }
    const delivery = drs.transport.boral.asphalt.delivery
    let deliveryTrucksPlanned: any = {}
    delivery.trucks.forEach((truck) => {
      deliveryTrucksPlanned[truck.truck.id] = truck.plan ? true : false
    })
    const profiling = drs.transport.boral.asphalt.profiling
    let profilingTrucksPlanned: any = {}
    profiling.trucks.forEach((truck) => {
      profilingTrucksPlanned[truck.truck.id] = truck.plan ? true : false
    })
    let rows = []
    rows.push({
      transportType: TransportTypeId.Delivery,
      ...delivery.plan, // order per tonne
      ...deliveryTrucksPlanned, // all trucks with key of machine id
      _key_: `Delivery ${PAETableRowType.First}`,
      _type_: PAETableRowType.First,
      _no_: 0,
      _editable_: isPlan,
    })
    rows.push({
      transportType: TransportTypeId.Profiling,
      ...profiling.plan, // order per tonne
      ...profilingTrucksPlanned, // all trucks with key of machine id
      _key_: `Profiling ${PAETableRowType.First}`,
      _type_: PAETableRowType.First,
      _no_: 1,
      _editable_: isPlan,
    })
    return rows
  }

  return <Wrapper>
    <FormSectionTitle>Transport</FormSectionTitle>
    <FormPanel title={`Transport`}>
      <FormPAETable<any>
        fields={tableFields}
        data={getTableData()}
        editable={true}
        onEditRow={hanldeEditRow}
        showPAEType={false}
        showMoreActions={false}
      />
    </FormPanel>
    {open && <Modal<TransportInModal>
      maxWidth='xs'
      open={open}
      title='Transport'
      data={{
        rowData: modalData,
        fields: modalFields,
        columns: [
          {
            title: '',
            fieldKeys: [
              'orderedTonnesPerRound',
              'machines',
            ],
          },
        ]
      }}
      onSuccess={handleSave}
      onClose={closeModal}
      onCancel={closeModal}
    />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

interface TransportInModal {
  id: number,
  orderedTonnesPerRound: number,
  machines: string[],
  transportType: TransportTypeId,
}
interface TransportRow extends TransportInModal {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

export default PartDRSTransport
