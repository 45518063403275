import { ax } from '../utils/axios'
import { BASE_API_URL } from '../constants'
import axios, { Canceler } from 'axios'
let cancel: Canceler

export const apiProfileSearchAdvanced = ({
    search,
    depotId,
    regionId,
    stateId,
    role,
    roleId,
    roleIds,
    active,
    searchNames,
    handleProfileDepotType,
    fuzzyMatch,
    roleType,
    orderField,
    orderDirection,
    limit,
    offset,
}: {
    search: string,
    depotId?: string,
    regionId?: string,
    stateId?: string,
    role?: string,
    roleId?: string,
    roleIds?: string[],
    active?: boolean,
    searchNames?: boolean,
    handleProfileDepotType?: boolean,
    fuzzyMatch?: boolean,
    roleType?: string,
    orderField?: string,
    orderDirection?: string
    limit?: number,
    offset?: number,
}) => {
    // if (!query) return
    if (cancel) {
        cancel()
    }
    //console.log(` profile-search-advanced =${JSON.stringify({ search, depot: depotId, region: regionId, state: stateId, role: roleIds, active, searchNames, fuzzyMatch, orderField, orderDirection, limit, offset }, null, 2)}`)
    const options = {
        method: 'GET',
        url: `${BASE_API_URL}/ref/profiles`,
        params: {
            search,
            depot: depotId,
            region: regionId,
            state: stateId,
            role: roleIds,
            active,
            searchNames,
            handleProfileDepotType,
            fuzzyMatch,
            orderField,
            orderDirection,
            limit,
            offset,
        },
        cancelToken: new axios.CancelToken((c: Canceler) => {
            cancel = c
        }),
    }
    return ax(options)
}