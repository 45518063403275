import React, { useRef, SyntheticEvent, useEffect } from 'react'
import styled, { css } from 'styled-components'
import CompRectangleElememt from './comps/Rectangle'
import { DrawingElement, DrawingElementType, ArrowElement, RectangleElement, TextBoxElement } from './types/DrawingElement'
import CompTextBoxElement from './comps/Text'
import CompArrow, { Point } from './comps/Arrow'
import { DrawingMode } from './types/DrawingMode'

interface Props {
  mode: DrawingMode,
  elements?: DrawingElement[],
  exportTrigger: number,
  onExport(svgData: string): void,
  onMouseMove(e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point): void,
  onMouseUp(e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point): void,
  onMouseDown(e: React.MouseEvent<SVGSVGElement, MouseEvent>, p: Point): void,
  onElementMouseOver(elemet: DrawingElement): void,
  elementIdMouseOver: string,
  onElementClick(elemet: DrawingElement): void,
  selectedElement: DrawingElement | null,
  onElementMouseDown(elemet: DrawingElement, p: Point): void,
  onArrowStartMouseDown(elemet: DrawingElement): void,
  onArrowEndMouseDown(elemet: DrawingElement): void,
  onRectTopLeftMouseDown(elemet: DrawingElement): void,
  onRectTopRightMouseDown(elemet: DrawingElement): void,
  onRectBottomLeftMouseDown(elemet: DrawingElement): void,
  onRectBottomRightMouseDown(elemet: DrawingElement): void,
  onRectBottomRightRotateMouseDown(elemet: DrawingElement): void,
  onTextBoxBottomRightMouseDown(elemet: DrawingElement): void,
}

const CompDrawingBoard = (props: Props) => {
  const {
    mode,
    elements = [],
    exportTrigger,
    onExport,
    onMouseMove,
    onMouseUp,
    onMouseDown,
    onElementMouseOver,
    elementIdMouseOver,
    onElementClick,
    selectedElement,
    onElementMouseDown,
    onArrowStartMouseDown,
    onArrowEndMouseDown,
    onRectTopLeftMouseDown,
    onRectTopRightMouseDown,
    onRectBottomLeftMouseDown,
    onRectBottomRightMouseDown,
    onRectBottomRightRotateMouseDown,
    onTextBoxBottomRightMouseDown,
  } = props
  const ref = useRef<any>()
  const canvasRef = useRef<any>()
  useEffect(() => {
    if (exportTrigger) {
      const svgElement = ref.current as SVGElement
      let source = svgElement.outerHTML
      const svgData = 'data:image/svg+xml;base64,' + btoa(source)
      onExport(svgData)
    }
  }, [exportTrigger])
  const handleMouseOver = (element: DrawingElement) => () => {
    onElementMouseOver(element)
  }
  const handleElementClick = (element: DrawingElement) => () => {
    onElementClick(element)
  }
  const handleElementMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const s = ref.current as SVGElement
    const svgRect = s.getBoundingClientRect()
    const p = {
      x: e.clientX - svgRect.x,
      y: e.clientY - svgRect.y,
    }
    onElementMouseDown(element, p)
  }
  const handleArrowStartMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onArrowStartMouseDown(element)
  }
  const handleArrowEndMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onArrowEndMouseDown(element)
  }
  const handleRectTopLeftMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onRectTopLeftMouseDown(element)
  }
  const handleRectTopRightMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onRectTopRightMouseDown(element)
  }
  const handleRectBottomLeftMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onRectBottomLeftMouseDown(element)
  }
  const handleRectBottomRightMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onRectBottomRightMouseDown(element)
  }
  const handleRectBottomRightRotateMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onRectBottomRightRotateMouseDown(element)
  }
  const handleTextBoxBottomRightMouseDown = (element: DrawingElement) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    onTextBoxBottomRightMouseDown(element)
  }
  return <Wrapper mode={mode}>
    <svg
      ref={ref}
      version='1.1'
      baseProfile='full'
      width='100%' height='100%'
      xmlns='http://www.w3.org/2000/svg'
      onMouseMove={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const s = ref.current as SVGElement
        const svgRect = s.getBoundingClientRect()
        const p = {
          x: e.clientX - svgRect.x,
          y: e.clientY - svgRect.y,
        }
        onMouseMove(e, p)
      }}

      onMouseUp={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const s = ref.current as SVGElement
        const svgRect = s.getBoundingClientRect()
        const p = {
          x: e.clientX - svgRect.x,
          y: e.clientY - svgRect.y,
        }
        onMouseUp(e, p)
      }}

      onMouseDown={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const s = ref.current as SVGElement
        const svgRect = s.getBoundingClientRect()
        const p = {
          x: e.clientX - svgRect.x,
          y: e.clientY - svgRect.y,
        }
        onMouseDown(e, p)
      }}
      
      >
        { elements.map((element) => {
          const showHovered = elementIdMouseOver === element.id || (selectedElement !== null && selectedElement.id === element.id)
          switch (element.type) {
            case DrawingElementType.Arrow: {
              const ele = element as ArrowElement
              return <CompArrow
                key={ele.id}
                from={{
                  x: ele.properties.fromX,
                  y: ele.properties.fromY,
                }}
                to={{
                  x: ele.properties.toX,
                  y: ele.properties.toY,
                }}
                stroke={ele.properties.stroke}
                onMouseOver={handleMouseOver(ele)}
                hovered={showHovered}
                onMouseDown={handleElementMouseDown(ele)}
                onClick={handleElementClick(ele)}
                onStartMouseDown={handleArrowStartMouseDown(ele)}
                onEndMouseDown={handleArrowEndMouseDown(ele)}
              />
            }
            case DrawingElementType.Rectangle: {
              const ele = element as RectangleElement
              return <CompRectangleElememt 
                key={ele.id}
                from={{
                  x: ele.properties.fromX,
                  y: ele.properties.fromY,
                }}
                to={{
                  x: ele.properties.toX,
                  y: ele.properties.toY,
                }}
                stroke={ele.properties.stroke}
                rotation={ele.properties.rotation}
                onMouseOver={handleMouseOver(ele)}
                hovered={showHovered}
                onMouseDown={handleElementMouseDown(ele)}
                onTopLeftMouseDown={handleRectTopLeftMouseDown(ele)}
                onTopRightMouseDown={handleRectTopRightMouseDown(ele)}
                onBottomLeftMouseDown={handleRectBottomLeftMouseDown(ele)}
                onBottomRightMouseDown={handleRectBottomRightMouseDown(ele)}
                onBottomRightRotateMouseDown={handleRectBottomRightRotateMouseDown(ele)}
              />
            }
            case DrawingElementType.TextBox: {
              const ele = element as TextBoxElement
              return <CompTextBoxElement
                key={element.id}
                from={{
                  x: ele.properties.fromX,
                  y: ele.properties.fromY,
                }}
                to={{
                  x: ele.properties.toX,
                  y: ele.properties.toY,
                }}
                stroke={ele.properties.stroke}
                text={ele.properties.text}
                hovered={showHovered}
                onMouseOver={handleMouseOver(ele)}
                onMouseDown={handleElementMouseDown(ele)}
                onBottomRightMouseDown={handleTextBoxBottomRightMouseDown(ele)}
              />
            }
          }
          return null
          
        })}
    </svg>
    <CanvasPanel ref={canvasRef as any}>

    </CanvasPanel>
  </Wrapper>
}

const Wrapper = styled.div<{ mode: DrawingMode }>`
  width: 710px;
  /* background-color: pink; */
  height: 400px;
  ${(props) => {
    if (props.mode === DrawingMode.AddArrow) {
      return css`
        cursor: crosshair;
      `
    }
  }};
`
const CanvasPanel = styled.canvas`
  display: none;
`

export default CompDrawingBoard