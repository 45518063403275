import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSQaSpraySeal } from '@src/types/drs/qa/QaSpraySeal'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'
import { JobSpecification } from '@src/types/JobSpecification'
import { FormPAETableInteralData } from '@src/components/Form/PAETable'

const comments: FormTableField<DRSQaSpraySeal | DRSQaSpraySealModalForm> = {
  key: 'comments',
  type: FormTableFieldType.Textarea,
  label: 'Comment',
}
const jobSpecField: FormTableField<DRSQaSpraySeal | DRSQaSpraySealModalForm> = {
  id: 'jobSpecification',
  key: 'jobSpecification',
  type: FormTableFieldType.Select,
  label: 'Job Spec',
  format: (item: any) => item ? item.description : '',
}
export const DRSQaSpraySealRequirementFields: FormTableField<DRSQaSpraySeal>[] = [
  {
    key: 'holdPointReleaseForm',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Hold Point Release Form',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'ballPenTesting',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Ball Pen Testing',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'labTechOnsite',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Lab Tech Onsite',
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'sealDesign',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Seal Design Testing',
    format: formatBooleanOrNullToYesNo,
  },
]

export interface DRSQaSpraySealTableRow extends DRSQaSpraySeal, FormPAETableInteralData {}
export const DRSQaSpraySealTableFields: FormTableField<DRSQaSpraySeal>[] = [
  jobSpecField,
  ...DRSQaSpraySealRequirementFields,
  comments,
]

export interface DRSQaSpraySealModalForm {
  jobSpecification: JobSpecification,
  requirements: any[],
  comments: string,
}
export const DRSQaSpraySealModalFields: FormTableField<DRSQaSpraySealModalForm>[] = [
  jobSpecField,
  {
    key: 'requirements',
    label: 'Select QA Requirements',
    type: FormTableFieldType.CheckboxGroup,
    options: DRSQaSpraySealRequirementFields.map((requirement) => ({ value: requirement.key, label: requirement.label })),
  },
  comments,
]
