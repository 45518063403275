import { ax } from '../../utils/axios/axios'
import { BASE_API_URL } from '../../constants'

interface Options {
  name: string,
  colourCode: string,
  depot: AIMS.Depot,
  members: any,
}

export const apiCrewCreate = ({
  name,
  colourCode,
  depot,
  members,
}: Options) => {
  const data = {
    name,
    colourCode,
    depot,
    members,
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/crews`,
    data,
  }
  return ax(options)
}