import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import FormRowItem from './Item'

export interface FormRowGridProps {
  size?: 2 | 3 | 4 | 6 | 12,
  children?: React.ReactNodeArray | React.ReactElement,
}

const RowGrid = (props: FormRowGridProps) => {
  const { size = 2, children } = props
  if (!children) { 
    return null
  }
  return <Grid container>
    { React.Children.toArray(children).map((child: React.ReactNode, index: number) => {
      // using index as key here, because the order is not going to be changed
      return <Grid item xs={size} key={index}>
        <FormRowItem>
          { child }
        </FormRowItem>
      </Grid>
    })}
  </Grid>
}

export default RowGrid