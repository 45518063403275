import React, { useContext } from 'react'
import { observable, action } from "mobx";
import moment from 'moment'
import { Shift } from '@src/types/Shift'
import { formatDRSStatus, DRSStatus } from '@src/types/drs/DRSStatus'

const sortSelecetdShifts = () => {
  store.selectedShifts.replace(store.selectedShifts.slice().sort(function (a: Shift, b: Shift) {
    var c = moment(a.date);
    var d = moment(b.date);
    return c.diff(d, 'days')
  }));
}

const findShiftCrewsCantBeChanged = () => {
  let drsStatus: string
  store.drsStatusCrewsCantBeChanged = []
  store.selectedShifts.forEach((shift: Shift, index: number) => {
    if (shift.status.id === DRSStatus.SUBMITTED_PM || shift.status.id === DRSStatus.APPROVED_PM || shift.status.id === DRSStatus.COMPLETED) {
      //console.log(`status.id=${store.selectedShifts[index].status.id}`)
      drsStatus = `${formatDRSStatus(store.selectedShifts[index].status.id)}`
      if (!store.drsStatusCrewsCantBeChanged.find((o: string) => o === drsStatus))
        store.drsStatusCrewsCantBeChanged.push(`${drsStatus}`)
    }
  })
}

const store: any = observable({
  selectedShiftCount: 0,
  selectedShifts: [],
  isDragHappening: false,
  drsStatusCrewsCantBeChanged: [],
  sortSelectedShifts: action(() => {
  }),
  setIsDragHappening: action((isDragHappening: boolean) => {
    store.isDragHappening = isDragHappening
  }),
  removeSelectedShift: action((shift: Shift) => {
    store.selectedShifts.splice(store.selectedShifts.findIndex((o: any) => o.id === shift.id), 1)
    store.selectedShiftCount--
  }),
  addSelectedShift: action((shift: Shift) => {
    let found = store.selectedShifts.find((o: any) => o.id === shift.id);
    if (!found) {
      store.selectedShifts.push(shift)
      sortSelecetdShifts()
      findShiftCrewsCantBeChanged()
      store.selectedShiftCount++
    }
  }),
  clearSelectedShifts: action(() => {
    store.selectedShifts = []
    store.selectedShiftCount = 0
    store.drsStatusCrewsCantBeChanged = []
  }),
});

const context = React.createContext(store);

export const useSelectedShifts = () => {
  return useContext(context);
};