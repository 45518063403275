import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const BoralRadio = withStyles({
  checked: {
    colorSecondary: {
      color: '#009688',
    }
  },
  root: {
    '&$checked': {
      color: '#009688',
    }
  },
})(Radio)


export default BoralRadio