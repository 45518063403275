import React from 'react'
import styled from 'styled-components'
import FormSectionTitle from '@src/components/Form/FormSectionTitle'
import FormPanel from '@src/components/FormPanel/FormPanel'
import RowGrid from '@src/components/FormRow/RowGrid'
import FieldPair from '@src/components/Form/FieldPair'
import Modal from '../../../../components/FormModal/Modal'
import useModal from '../../../../components/FormModal/useModal'
import { FormTableFieldType } from '@src/components/Form/Table'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { SRR, SRRShiftDetails } from '@src/types/SRR'
import { formatDateStringHHmm } from '@src/types/format/dateStringHHmm'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'
import { apiSrrUpdateShiftDetails } from '@src/apis/srr-update-shift-details'
import { useAppStore } from '@src/AppStore'
import { formatDateStringDDMMYYYY } from '@src/types/format/dateStringDDMMYYYY'
import { useSRR } from '../../useSRR'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { formatProjectTypeId } from '@src/types/ProjectTypeId' 
import { formatShiftTypeId } from '@src/types/ShiftTypeId'
import { useSRRDetailStore } from '../../SRRDetailStore'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {
  
}

const SRRJob = (props: Props) => { 
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const { serialId } = useAppStore()
  const [ srr, srrLoading, refresh ] = useSRR()
  const { alert } = useSnackbar()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<SRRShiftDetails>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  
  const { shiftResourceRequirementInformation, projectDetails, shiftDetails } = srr.job
  const srrInfoData: { label: string, value: string }[] = [
    {
      label: 'Created By',
      value: formatProfileOrNullFirstLastName(shiftResourceRequirementInformation.createdBy),
    },
    {
      label: 'Creation date',
      value: formatDateStringOrNullDDMMYYYY(shiftResourceRequirementInformation.creationDate),
    },
    {
      label: 'Updated by',
      value: formatProfileOrNullFirstLastName(shiftResourceRequirementInformation.updatedBy),
    },
    {
      label: 'Last updated on',
      value: formatDateStringOrNullDDMMYYYY(shiftResourceRequirementInformation.updatedDate),
    },
  ]
  const projectData: { label: string, value: string }[] = [
    {
      label: 'Project Number',
      value: (`${projectDetails.projectId || projectDetails.projectInternalId}`) || '',
    },
    {
      label: 'Project Name',
      value: projectDetails.projectName || '',
    },
    {
      label: 'Customer',
      value: projectDetails.customer ? projectDetails.customer.name : '',
    },
    {
      label: 'Customer Representative',
      value: projectDetails.customerRepresentativeName || '',
    },
    {
      label: 'Contact Number',
      value: projectDetails.customerRepresentativeContactNumber || '',
    },
  ]
  const shiftDetailData: { label: string, value: string }[] = [
    {
      label: 'Shift Date',
      value: shiftDetails.shiftDate !== null ? formatDateStringDDMMYYYY(shiftDetails.shiftDate) : '',
    },
    {
      label: 'Shift Type',
      value: shiftDetails.shiftType ? formatProjectTypeId(shiftDetails.shiftType.id) : '',
    },
    {
      label: 'Shift Time',
      value: shiftDetails.shiftTime ? formatShiftTypeId(shiftDetails.shiftTime.id) : '',
    },
    {
      label: 'Weekend Work',
      value: shiftDetails.weekendWork === null ? '' : formatBooleanToYesNo(shiftDetails.weekendWork),
    },
    {
      label: 'Handwork',
      value: shiftDetails.handWork === null ? '' : formatBooleanToYesNo(shiftDetails.handWork),
    },
  ]
  const handleEditShiftDetail = () => {
    console.log(shiftDetailData)
    openAndSetData(shiftDetails)
  }
  const handleSave = async (form: any) => {
    console.log(form)
    try {
      const result = await apiSrrUpdateShiftDetails({
        srrId: form.srrId,
        shiftId: form.shiftId || 3318,
        serialId,
        weekendWork: form.weekendWork,
        handWork: form.handWork,
      })
      refresh()
      alert({
        message: 'Updated.'
      })
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: 'Error.'
      })
    }
  }
  return <Wrapper>
    <FormSectionTitle>JOB DETAILS</FormSectionTitle>
    <FormPanel title={`Shift Resource Requirement Information`}>
      <RowGrid>
        { srrInfoData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel title={`Project Details`}>
      <RowGrid>
        { projectData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
    </FormPanel>
    <FormPanel 
      title={`Shift Details`}
      actionEdit={canEditSubmitSRR}
      onEdit={handleEditShiftDetail}
    >
      <RowGrid>
        { shiftDetailData.map((item: { label: string, value: string } ) => {
          return <FieldPair key={item.label} label={item.label} value={item.value} />
        })}
      </RowGrid>
      { open && 
        <Modal<SRRShiftDetails>
          maxWidth={'xs'}
          open={open}
          title='Shift Details'
          data={{
            rowData: modalData,
            fields: [
              {
                key: 'weekendWork',
                type: FormTableFieldType.BooleanRadioGroup,
                label: 'Weekend Work',
                format: formatBooleanToYesNo,
              },
              {
                key: 'handWork',
                type: FormTableFieldType.BooleanRadioGroup,
                label: 'Handwork',
                format: formatBooleanToYesNo,
              },
            ],
            columns: [
              {
                title: '',
                fieldKeys: [
                  'weekendWork', 
                  'handWork', 
                ],
              },
            ]
          }}
          onSuccess={handleSave}
          onClose={closeModal}
          onCancel={closeModal}
        />
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default SRRJob