import { PAETableRowType, FormPAETableInteralData } from '@src/components/Form/PAETable'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { DRSPageType } from '../DRSPage'

export function mapMoreActions<T extends FormPAETableInteralData>(
  data: T, 
  drsPageType: DRSPageType, 
  pmActions?: PAETableMoreActionType[],
  rsActions?: PAETableMoreActionType[],
) {
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit

  let finalRSActions: PAETableMoreActionType[] = []
  if (isPlan) {
    if (rsActions) {
      finalRSActions = rsActions
    } else {
      finalRSActions = [PAETableMoreActionType.Delete]
    }
  }
  if (data._type_ === PAETableRowType.First) {
    return {
      ...data,
      _more_actions_: finalRSActions,
    }
  } else if (data._type_ === PAETableRowType.Second) {
    return {
      ...data,
      _more_actions_: pmActions ? pmActions : [
        PAETableMoreActionType.Revert, 
        PAETableMoreActionType.MarkZero,
         PAETableMoreActionType.Delete,
      ],
    }
  }
  return data
}
export function mapMoreActionsNormal<T extends FormPAETableInteralData>(
  data: T, 
 
  pmActions?: PAETableMoreActionType[],
  rsActions?: PAETableMoreActionType[],
) {
  
  let finalRSActions: PAETableMoreActionType[] = []
  
    if (rsActions) {
      finalRSActions = rsActions
    } else {
      finalRSActions = [PAETableMoreActionType.Delete]
    }
  
  
  return data
}