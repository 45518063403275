import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import _get from 'lodash/get'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { PAE } from '@src/types/drs/PAE'
import CrewSummaryFields, { CrewSummaryRowDisplay } from './CrewSummaryFields'
import { CrewExtraFields } from './CrewExtrasFields'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'

interface CrewSummaryRow extends CrewSummaryRowDisplay {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  accommodation: null,
  hoursOnSite: null,
  siteAllowance: null,
}
const ZERO_VALUES = {
  accommodation: '0',
  hoursOnSite: '0',
  siteAllowance: '',
}

const CompDRSCrewExtras = () => {
  const { id } = useParams<{ id: string }>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<CrewSummaryRow>({})
  if (drs === null) {
    return null
  }
  async function update(data: any) {
    try {
      await updateSectionViaPath({
        sectionName: DRSSectionName.Labour,
        path: `summary`,
        data,
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const handleSave = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        ...form,
      }
    }
    update(data)
  }
  const handleRevert = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        ...NULL_VALUES,
      }
    }
    update(data)
  }
  const handleMarkZero = async (form: CrewSummaryRow) => {
    const retainedSummary = drs.labour.summary
    const data = {
      ...retainedSummary,
      [updatingColumn]: {
        ...retainedSummary[updatingColumn],
        ...ZERO_VALUES,
      }
    }
    update(data)
  }
  const getTableData = () => {
    const rows = [drs.labour.summary]
    if (!rows || !rows.length) {
      return []
    }
    const doubled = doubleUp(rows, drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`Crew Extras`}>
      <FormPAETable<CrewSummaryRow>
        fields={CrewExtraFields}
        data={getTableData()}
        isSelectable={true}
        editable={true}
        onEditRow={(row: CrewSummaryRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
      />
    </FormPanel>

    {open &&
      <Modal<CrewSummaryRow>
        maxWidth={'xs'}
        open={open}
        title='Crew Extras'
        data={{
          rowData: modalData,
          fields: CrewExtraFields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'accommodation',
                'hoursOnSite',
                'siteAllowance',
              ],
            },
          ],
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSCrewExtras