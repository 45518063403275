import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import TabSection from '../../comps/TabSectionWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '../../comps/TableCell'
import TableHeadCell from '../../comps/TableHeadCell'
import _get from 'lodash/get'
import { DRS } from '@src/types/drs/DRS'
import { DRSReworkAssetPAE } from '@src/types/drs/rework/Rework'

const renderMain = (type: string, data: any, isLast: boolean) => {
  const path = type === 'ASPHALT' ? 'asphalt' : 'spraySeal'
  const typeText = type === 'ASPHALT' ? 'Asphalt' : 'Spray Seal'
  const boralAssets: DRSReworkAssetPAE[] = _get(data, `mobileAssets.boral.${path}.assets`)
  if (!boralAssets) {
    return null
  }
  let finalData = boralAssets.filter((item: DRSReworkAssetPAE) => {
    return (item.plan.mobileAsset && item.plan.mobileAsset.id)
  })
  if (!finalData.length) return null
  return  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell colSpan={2}>Boral Assets - {typeText}</TableHeadCell>
        <TableHeadCell></TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        finalData.map((row: DRSReworkAssetPAE) => {
          return <TableRow key={row.id}>
            <TableCell>{row.plan.mobileAsset.id}</TableCell>
            <TableCell>{row.plan.mobileAsset.description}</TableCell>
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}

// subcontractor
const renderSub = (type: string, data: any, isLast: boolean) => {
  const path = type === 'ASPHALT' ? 'asphalt' : 'spraySeal'
  const typeText = type === 'ASPHALT' ? 'Asphalt' : 'Spray Seal'
  let finalData = []

  if (type === 'ASPHALT') {
    const generalAssets: DRSReworkAssetPAE[] = _get(data, `mobileAssets.contractor.asphalt.generalAssets`, [])
    const profilingAssets: DRSReworkAssetPAE[] = _get(data, `mobileAssets.contractor.asphalt.profilingAssets`, [])
    finalData = [
      ...profilingAssets.map((profiling: DRSReworkAssetPAE) => {
        return {
          id: profiling.id,
          // supplierName: profiling.plan.supplierName,
          info: _get(profiling, `plan.millSize.id`),
        }
      }),
      ...generalAssets.map(general => {
        return {
          id: general.id,
          // supplierName: general.plan.supplierName,
          // info: general.plan.equipmentTypeDesc, // display equipment type for general one
        }
      }),
    ]
  } else {
    const assets: DRSReworkAssetPAE[] = _get(data, `mobileAssets.contractor.spraySeal.assets`, [])
    finalData = [
      ...assets.map(asset => {
        return {
          id: asset.id,
          // supplierName: asset.plan.supplierName,
          // info: asset.plan.equipmentTypeDesc,
        }
      })
    ]
  }
  return  <Table>
    <TableHead>
      <TableRow>
        <TableHeadCell colSpan={2}>Subcontractor Assets - {typeText}</TableHeadCell>
        <TableHeadCell></TableHeadCell>
      </TableRow>
      {/* <TableRow>
        <TableHeadCell>Type</TableHeadCell>
        <TableHeadCell>Tonnes / Round</TableHeadCell>
      </TableRow> */}
    </TableHead>
    <TableBody>
      {
        finalData.map(row => {
          return <TableRow key={row.id}>
            {/* <TableCell>{row.supplierName}</TableCell>
            <TableCell>{row.info}</TableCell> */}
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}

const TabSectionMobileAssets = ({
  type, //oneOf(['ASPHALT', 'SPRAYSEAL']).isRequired,
  data,
  isLast,
}: {
  type: string, 
  data: DRS,
  isLast: boolean,
}) => {
  return <TabSection
    name='Mobile Assets Preview'
    isLast={isLast}
  >
    <InnerWrapper>
      { renderMain(type, data, isLast) }
      {/* { renderSub(type, data, isLast) } */}
    </InnerWrapper>
    
  </TabSection>
}

const InnerWrapper = styled.div`
  min-height: 110px;
`

export default observer(TabSectionMobileAssets)