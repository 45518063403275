import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { Mime } from '@src/types/Mime'
import { RoleId } from '@src/types/common/RoleId'
interface Options {

  serialId: number,
  cpDocComment?: string,
  id: number,

  
  roleId: string,
  

}

export const apiUpdateFile = ({
  roleId,
  serialId,
  id,
  cpDocComment
}: Options) => {

  const data = {
   
    id,
     roleId,
   
    serialId, comment: cpDocComment
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/documents/crew_pack_photo/${id}`,
    data,
    params: {
      reqRole: roleId,
      reqSid: serialId,
      reqDev: 'web',
      isUpdateRecord: true,
      comment:cpDocComment,
      id

    }
  }
  return ax(options)
}