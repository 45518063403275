import React from 'react'
import styled from 'styled-components'

const FormSectionTitle = styled.div`
  height: 48px;
  line-height: 48px;
  font-size: 36px;
  font-weight: bold;
  color: #0A9688;
  margin-bottom: 48px;
`

export default FormSectionTitle