interface GeneralIDName {
  id: any,
  name?: string,
}

export const ADIDNameToValueLabel = (item: GeneralIDName) => {
  const { id, name } = item
  return {
    label: name,
    value: id,
  }
}