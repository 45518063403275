import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'

const TabSection = ({
  name,
  isLast,
  children,
}: {
  name: string,
  isLast: boolean,
  children: any,
}) => {
  return <Wrapper>
    <Name>{name}</Name>
    <ChildrenWrapper isLast={isLast}>

      { children }
      
    </ChildrenWrapper>
  </Wrapper>
}

const Wrapper = styled.div``

const Name = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  color: #009688;
` as typeof Typography

// const Divider = styled.div`
//   height: 1px;
//   background-color: #009688;
//   margin-bottom: 8px;
// `
const ChildrenWrapper = styled.div<{ isLast: boolean }>`
  ${props => {
    if (!props.isLast) {
      return css`
        border-bottom: 1px solid #009688;
      `
    }
  }}
  margin-bottom: 8px;
`

export default TabSection