import React from 'react'
import styled from 'styled-components'
import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link as ReactRouterLink, useLocation, useParams, useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@material-ui/icons/Home'

interface Props {
  home?: boolean,
  items: Item[],
}
interface Item {
  type: 'text' | 'link',
  url?: string,
  text: string,
  active?: boolean,
}

const Breadcrumbs = (props: Props) => {
  const history = useHistory()
  const { home = true, items = [] } = props
  return <Wrapper>
    <MuiBreadcrumbs aria-label='Breadcrumb' separator={<Separator fontSize='default' />}>
      { home && <ReactRouterLink to='/'><IconButton size='small'><SHomeIcon /></IconButton></ReactRouterLink> }
      { items.map((item: Item) => {
        if (item.type === 'text') {
          return <TextItem key={item.text} active={Boolean(item.active)}>{item.text}</TextItem>
        }
        return <LinkItem key={item.text}
          onClick={() => {
            history.push({
              pathname: item.url,
            })
          }}
        >{item.text}</LinkItem>
      })}
  </MuiBreadcrumbs>
  </Wrapper>
}

const Wrapper = styled.div`
  padding: 40px 0;
  display:inline-block;
`
const Separator = styled(NavigateNextIcon)`
  margin-left: -8px;
  margin-right: -8px;
  color: #129688;
`
const SHomeIcon = styled(HomeIcon)`
  color: #129688;
`
const TextItem = styled.div<{active?: boolean}>`
  font-size: 16px;
  line-height: 21px;
  color: #707070;
  ${props => props.active && `color: #129688;`};
`
const LinkItem = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #707070;
  cursor: pointer;
`

export default Breadcrumbs