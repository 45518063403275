import React from 'react'
import styled from 'styled-components'
import CompFlatTable from '@src/modules/SiteResoureRequirements/comps/FlatTable'
import Modal from '@src/components/FormModal/Modal'
import FormPanel from '@src/components/FormPanel/FormPanel'
import { MaterialSpraySealOtherProduct } from '@src/types/MaterialSpraySealOtherProduct'
import { useParams } from 'react-router'
import { useAppStore } from '@src/AppStore'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import useModal from '@src/components/FormModal/useModal'
import fields from './fields'
import { JobType } from '@src/types/JobType'
import { apiSrrMaterialOtherProductUpdate } from '@src/apis/srr-material-other-product-update'
import usePermission from '@src/utils/hooks/usePermission'

interface Props {

}

const CompSsOther = (props: Props) => {
  const {

  } = props
  const { id } = useParams<{id: string}>()
  const { serialId } = useAppStore()
  const [ srr, srrLoading, refresh ] = useSRR()
  const { alert } = useSnackbar()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<MaterialSpraySealOtherProduct>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const product = srr.material.spraySeal.otherProducts

  const handleSave = async (form: any) => {
    console.log(form)
    try {
      const result = await apiSrrMaterialOtherProductUpdate({
        srrId: id, 
        serialId,
        jobType: JobType.SpraySeal,
        partialProduct: {
          ...form,
        }
      })
      alert({
        message: `Done.`
      })
      refresh()
      closeModal()
    } catch (e) {
      alert({
        type: 'error',
        message: `Error.`
      })
    }
  }
  return <Wrapper>
    <FormPanel
      title={`Other Spray Seal Products`}
    >
      <CompFlatTable<MaterialSpraySealOtherProduct>
        data={product}
        fields={fields}
        editable={canEditSubmitSRR}
        onEdit={() => {
          openAndSetData(product)
        }}
      />
    </FormPanel>
    { open && <Modal<MaterialSpraySealOtherProduct>
        maxWidth='sm'
        open={open}
        title='Other Materials Spray Seal'
        data={{
          rowData: modalData,
          fields: fields,
          columns: [
            {
              title: '',
              fieldKeys: [
                'grit', 
                'dialBeforeYouDig',
                'catEyeCovers', 
                'trafficLoops',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'hateliteBitac',
                'sticksNStomps', 
                'geoFabric',
                'emulsion',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompSsOther