/**
 * [
  { "id": "DRAFT", "description": "Draft", "active": true },
  { "id": "CANCELLED", "description": "Cancelled", "active": true },
  {
    "id": "SUBMITTED-FORMAN",
    "description": "Submitted to Foreman",
    "active": true
  },
  { "id": "INPROGRESS", "description": "In Progress", "active": true },
  { "id": "SUBMITTED-PM", "description": "Submitted to PM", "active": true },
  { "id": "INREVIEW", "description": "In Review", "active": true },
  { "id": "APPROVED-PM", "description": "Approved by PM", "active": true },
  { "id": "COMPLETED", "description": "Completed", "active": true }
]
 */

const Mapping: { [key: string]: any } = {
  'DRAFT': 'Draft',
  'CANCELLED': 'Shift Cancelled',
  'SUBMITTED-FORMAN': 'Sent to Crew',
  'INPROGRESS': 'Shift in Progress',
  'SUBMITTED-PM': 'Shift Completed',
  'INREVIEW': 'Shift Review in Progress',
  'APPROVED-PM': 'Review Completed',
  'COMPLETED': 'DRS Completed',
}
export function formatStatus (statusId: string) {
  if (statusId) {
    return Mapping[statusId]
  }
}