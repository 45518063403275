import React from 'react'
import styled from 'styled-components'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'


interface Props {
  label: string,
  value: boolean,
  disabled?: boolean,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
}

const FormModalBooleanRadioGroup = (props: Props) => {
  const {
    label,
    value,
    disabled = false,
    onChange,
  } = props
  return <FormControl component='fieldset'>
    <FormLabel component='legend'>{label}</FormLabel>
    <RadioGroup value={value} onChange={onChange}>
      <FormControlLabel value={true} control={<Radio />} label='Yes' disabled={disabled}/>
      <FormControlLabel value={false} control={<Radio />} label='No' disabled={disabled}/>
    </RadioGroup>
  </FormControl>
}

const Wrapper = styled.div`

`

export default FormModalBooleanRadioGroup