import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  CrewCalendarNotesID:string,
  Comment: string,

  RGBColorCode: string,
  CreatedBy:string,
  Active: boolean
  Date:string,
  ModifiedBy:string

}

export const apiCrewtNoteUpdate = ({
  CrewCalendarNotesID,
  Comment,
  RGBColorCode,
  CreatedBy,
  Active,Date,
  ModifiedBy
}: Options) => {
  const data = {
    CrewCalendarNotesID,
    Comment,
    RGBColorCode,
    CreatedBy,
    Active,Date,ModifiedBy
  }
  const options = {
    method: 'PUT',
    url: `${BASE_API_URL}/ref/crewNotes`,
    data,
  }
  return ax(options)
}