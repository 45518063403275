import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSLabourSummary } from '@src/types/drs/labour/Labour'

export interface CrewSummaryRowDisplay extends DRSLabourSummary {

}

export const LostTimeFields: FormTableField<CrewSummaryRowDisplay>[] = [
  {
    key: 'noWorkAvailable',
    type: FormTableFieldType.Number,
    label: 'No Work Available',
  },
  {
    key: 'wetWeatherHours',
    type: FormTableFieldType.Number,
    label: 'Wet Weather Hours',
  },
  {
    key: 'breakdownOfPlantOrEquipment',
    type: FormTableFieldType.Number,
    label: 'Breakdown of plant or equipment',
  },
  {
    key: 'cancelledByCustomer',
    type: FormTableFieldType.Number,
    label: 'Cancelled by customer',
  },
  {
    key: 'cancelledByInternalIssue',
    type: FormTableFieldType.Number,
    label: 'Cancelled by Internal Issue',
  },
  {
    key: 'cancelledByCrewLeave',
    type: FormTableFieldType.Number,
    label: 'Cancelled by Crew Leave',
  },
  {
    key: 'trainingCrew',
    type: FormTableFieldType.Number,
    label: 'Training Crew',
  },
]

export default LostTimeFields