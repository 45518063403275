import React, { useEffect, useRef, useState } from "react";
import styled, { AnyStyledComponent, css } from "styled-components";
import { observer } from "mobx-react-lite";
import { useCrewCalender } from "../StoreCrewCalender";
import Typography from "@material-ui/core/Typography";
import { styledWithOut } from "../../../../utils/styledWithout";
import moment from "moment";
import NoteItem from "../comps/NoteItem";
import { apiDepotNoteSearch } from "@src/apis/depot-note-search";
import { useAppStore } from "@src/AppStore";
import { useCrewFilter } from "../FilterInHeader/CrewFilterStore";
import Collapse from "@material-ui/core/Collapse";
import { DndProvider, useDrop, DropTargetMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CustomDragLayer } from "../CustomDragLayer";
import { DragItemTypes } from "@src/types/DragItemTypes";
import { useHover } from "@src/utils/hooks";
import { useSocketClient } from "@src/utils/hooks/StoreSocketClient";
interface Props {
  currentBatch: any;
  hidden: boolean;
  shiftNoteStyle: string;
  shiftNewStyle: string;
}
interface DragItem {
  type: string;
}
const WeekDays = ({
  currentBatch,
  hidden,
  shiftNoteStyle,
  shiftNewStyle,
}: Props) => {
  const ref = useRef(null);
  const {
    viewType,
    isWeekView,
    today,
    gridNumber,
    batchStartDay,
    refreshHook,
    gridWidth,
    updateSelectedEndBox,
    crews,
    goRefresh,
    isCalendarControlsDisabled,
  } = useCrewCalender();
  const {
    socketRefreshHook,
    setShiftIds,
    setWatch,
    setListenBy,
    doConnectWebSocket,
  } = useSocketClient();
  const { selectedDepot } = useCrewFilter();
  const [start, setStart] = useState<string>("");
  const [end, setEnd] = useState<string>("");
  const [depotNotes, setDepotNotes] = useState([]);
  const [depotDates, setDepotDates] = useState<Array<string>>([]);
  const { depotId,regionId,stateId } = useAppStore();
  const [prevGridNumber, setPrevGridNumber] = useState<number>(gridNumber);
  const [prevBatchStartDay, setPrevBatchStartDay] =
    useState<any>(batchStartDay);
  const colorCode: string = "";

  const [boxRef, hovered] = useHover();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragItemTypes.DepotNote,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      //  console.log(`DRSBox useDrop() hover`)
    },
    collect: (monitor) => ({
      isOver: !isCalendarControlsDisabled && !!monitor.isOver(),
      canDrop: !isCalendarControlsDisabled,
    }),
    drop: (item: any, monitor) => {},
  });
  drop(boxRef);

  function prepareGroupData(resultData: any) {
    let group: any = [];
    for (let results of resultData) {
      let dateAdd: any = moment(results.Date).format("YYYY-MM-DD");
      if (group.indexOf(dateAdd) === -1) {
        group.push(dateAdd);
      }
    }
    group.sort();
    return group;
  }
  useEffect(() => {
    if (refreshHook) {
      console.log(`CrewCalendarView useEffect() refreshHook=${refreshHook}`);

      fetchNotes(selectedDepot.value);
    }
  }, [refreshHook]);

  const fetchNotes = async (depot: string) => {
    var startDate = batchStartDay.format("YYYY-MM-DD");
    const batchEndDayCloned = batchStartDay.clone();
    var endDate = batchEndDayCloned
      .add(gridNumber * 2, "day")
      .endOf("day")
      .toISOString();
    const result: any = await apiDepotNoteSearch({
      depot,
      state: stateId,
      region: regionId,
      start: startDate,
      end: endDate,
    });
    setDepotNotes(result.data.depotNotes);
    setDepotDates(prepareGroupData(result.data.depotNotes));
  };
  const handleClick = function () {};
  const onNext = function () {
    console.log("hello");
    fetchNotes(selectedDepot.value);
  };
  const onPrevious = function () {
    console.log("hello-onprev");
    fetchNotes(selectedDepot.value);
  };
  const onSave = function () {
    fetchNotes(selectedDepot.value);
  };
  function Note(props: any) {
    if (props.hidden) {
      return <BlankEle />;
    }
    const dateCurrent = props.dateCurrent;
    const propmodified = props.DateModified
      ? moment(props.DateModified).format("YYYY-MM-DD hh:mm:ss").toString()
      : "";
    const created = props.CreatedBy ? props.CreatedBy.toString() : "";
    const modifiedBy = props.modifiedBy ? props.modifiedBy.toString() : "";
    return (
      <NoteItem
        data={props.data}
        dateCreated={moment(props.DateCreated)
          .format("YYYY-MM-DD hh:mm:ss")
          .toString()}
        modifiedBy={modifiedBy}
        batchIndex={props.index}
        inDragLayer={false}
        shiftNoteStyle={shiftNoteStyle}
        shiftNewStyle={shiftNewStyle}
        dateModified={propmodified}
        createdBy={created}
        onSave={onSave}
        noteId={props.DepotCalendarNotesID}
        content={props.Comment}
        depotName={props.depotName}
        isEdit={true}
        color={props.RGBColorCode.toLowerCase()}
        currentDate={dateCurrent}
        hidden={hidden}
        onClick={handleClick}
        currentIndex={gridNumber}
        onNext={onNext}
        onPrevious={onPrevious}
        colorCode={""}
      />
    );
  }
  const nullData = {};
  const getNoteItem = function (
    dateCurrent: string,
    id: number,
    index: number
  ) {
    var depoArrDate: any = depotDates;

    const isHidden: boolean =
      depoArrDate.indexOf(dateCurrent) === -1 ? false : true;

    const listItems = depotNotes.map((arrayItem: any) =>
      moment(arrayItem.Date).format("YYYY-MM-DD").toString() === dateCurrent ? (
        <Note
          key={id + Math.random()}
          index={index}
          dateCurrent={dateCurrent}
          modifiedBy={arrayItem.ModifiedBy}
          data={arrayItem}
          Date={arrayItem.Date}
          DateModified={arrayItem.DateModified}
          CreatedBy={arrayItem.CreatedBy}
          DepotCalendarNotesID={arrayItem.DepotCalendarNotesID}
          depotName={selectedDepot.value == 'all' ?  arrayItem.DepotName+' - ' : ''}
          Comment={arrayItem.Comment}
          RGBColorCode={arrayItem.RGBColorCode}
          DateCreated={arrayItem.DateCreated}
          
        />
      ) : (
        <Note
          key={id + Math.random()}
          index={index}
          dateCurrent={""}
          data={nullData}
          Date={""}
          DateModified={""}
          CreatedBy={""}
          DepotCalendarNotesID={""}
          Comment={""}
          RGBColorCode={""}
          hidden={true}
        />
      )
    );

    return (
      <NoteParent>
        {isHidden == true ? (
          listItems
        ) : (
          <NoteItem
            data={nullData}
            dateCreated={""}
            batchIndex={index}
            inDragLayer={false}
            shiftNoteStyle={shiftNoteStyle}
            shiftNewStyle={shiftNewStyle}
            onSave={onSave}
            noteId={"0"}
            content={""}
            isEdit={false}
            modifiedBy={""}
            color={colorCode}
            currentDate={dateCurrent}
            hidden={false}
            onClick={handleClick}
            depotName={''}
            currentIndex={gridNumber}
            onNext={onNext}
            onPrevious={onPrevious}
            colorCode={""}
          />
        )}{" "}
      </NoteParent>
    );
  };
  useEffect(() => {
    if (selectedDepot && selectedDepot.value) {
      fetchNotes(selectedDepot.value);
    }
  }, [selectedDepot]);
  useEffect(() => {
    if (
      batchStartDay &&
      (!moment(prevBatchStartDay).isSame(batchStartDay, "day") ||
        gridNumber > prevGridNumber)
    ) {
      setPrevGridNumber(gridNumber);
      setPrevBatchStartDay(batchStartDay);
      fetchNotes(selectedDepot.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchStartDay, gridNumber]);
  return (
    <Wrapper isOver={isOver}>
      {currentBatch.map((date: moment.Moment, index: number) => {
        const isSameDay = date.isSame(today, "day");
        return (
          <FlexColumn key={date.dayOfYear()} gridNumber={gridNumber}>
            <FullWidth>
              <DayNumber isSameDay={isSameDay}>{date.date()}</DayNumber>
              <WeekDayName isSameDay={isSameDay}>
                {date.format("ddd")}
              </WeekDayName>
            </FullWidth>

            <Collapse in={!hidden}>
              <DndProvider backend={HTML5Backend}>
                <CustomDragLayer snapToGrid={true} />
                {getNoteItem(
                  date.format("YYYY-MM-DD").toString(),
                  date.unix(),
                  index
                )}
              </DndProvider>
            </Collapse>
          </FlexColumn>
        );
      })}
    </Wrapper>
  );
};
const Wrapper = styled.div<{ isOver: boolean }>`
  display: flex;
  ${(props) => {
    if (props.isOver) {
      return css`
        border: 1px solid #009688;
        background-color: #f5f5f5;
      `;
    }
  }}
`;
const FlexColumn = styled.div<{ gridNumber: number }>`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  width: ${(props) => `calc(100% / ${props.gridNumber - 1})`};
`;
const FullWidth = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const NoteParent = styled.div``;
const BlankEle = styled.span`
  width: 0;
  height: 0;
  display: none;
`;
const DayNumber = styled(styledWithOut("isSameDay")(Typography))`
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 6px;
  margin-right: 6px;
  ${(props) => {
    if (props.isSameDay) {
      return css`
        color: #fff;
        border-radius: 40px;
        height: 40px;
        width: 40px;
        background: #009688;
      `;
    }
  }}
`;

const WeekDayName = styled(styledWithOut("isSameDay")(Typography))`
  font-size: 14px;
  line-height: 45px;
  ${(props) => {
    if (props.isSameDay) {
      return css`
        color: #009688;
      `;
    }
  }}
`;
export default observer(WeekDays);
