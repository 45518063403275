import React from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import { ResourceFields, MachineResource } from './fields'
import FormTable, { FormTableFieldType, FormTableField } from '@src/components/Form/Table'
import { useSRR } from '@src/modules/SiteResoureRequirements/useSRR'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import { SRRResourceTypeId } from '@src/types/SRRResourceTypeId'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import { handleSaveSRRResource } from './handleSave'
import { handleRemoveSRRResource } from './handleRemove'
import usePermission from '@src/utils/hooks/usePermission'

const fields = ResourceFields
  .filter((field) => ['number'].includes(field.key))

const optionField: FormTableField<MachineResource> = {
  id: 'SRRResourceMultiRollerSelect',
  key: 'attribute',
  type: FormTableFieldType.Select,
  label: 'Multi Roller Size',
  format: (cellData: MachineResource['attribute']) => {
    if (cellData) {
      return cellData.description
    }
  },
}
export const SRRMultiRollerFields = [ optionField, ...fields ]

const ResouceTypeId = SRRResourceTypeId.MultiRoller

interface Props {

}

const ResourceMultiRoller = (props: Props) => {
  const {

  } = props
  const [ srr, srrLoading, refresh ] = useSRR()
  const { serialId } = useAppStore()
  const { alert, alertDone, alertError } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<any>({})
  const { canEditSubmitSRR } = usePermission()

  if (srr === null) {
    return null
  }
  const data = srr.machine.resources.multiRoller
  
  return <Wrapper>
    <FormPanel title={`Multi Roller`}>
      <FormTable<MachineResource>
        fields={[ optionField, ...fields ]}
        data={data}
        editable={canEditSubmitSRR}
        onEditRow={(row: any) => openAndSetData(row)}
        onRemoveRow={(row: any) => handleRemoveSRRResource({
          srrId: srr!.id,
          serialId,
          resouceTypeId: ResouceTypeId,
          row,
          confirm,
          alertDone,
          alertError,
          refresh,
        })}
      />
      { canEditSubmitSRR && 
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Multi Roller
          </CompCreateButton>
        </MarginTop>
      }
      { open && <Modal<MachineResource>
        maxWidth='xs'
        open={open}
        title='Resources'
        data={{
          rowData: modalData,
          fields: [ optionField, ...fields ],
          columns: [
            {
              title: 'Multi Roller',
              fieldKeys: [
                'attribute',
                'number',
              ],
            },
          ]
        }}
        onSuccess={(form: any) => handleSaveSRRResource({
          srrId: srr!.id,
          serialId,
          resouceTypeId: ResouceTypeId,
          modalData,
          form,
          alertDone,
          alertError,
          refresh,
          closeModal,
        })}
        onClose={closeModal}
        onCancel={closeModal}
      /> 
      }
    </FormPanel>
  </Wrapper>
}

const Wrapper = styled.div`

`

export default ResourceMultiRoller