import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _orderBy from 'lodash/orderBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SifBrief } from '@src/types/Sif'
import { formatDateStringOrNullDDMMYYYY } from '@src/types/format/dateStringOrNullDDMMYYYY'
import { useAppStore } from '@src/AppStore'
import { apiSifGetByProject } from '@src/apis/sif-get-by-project'
import { apiSrrImportSif } from '@src/apis/srr-import-sif'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import Alert from '@material-ui/lab/Alert'
import { reOrderSifs } from '@src/utils/reOrderSifs'

interface Props {
  data: {
    srrId: number,
    projectId: string | null,
    projectInternalId: number | null,
    projectName: string | null,
  } | null,
  open: boolean,
  onClose(): void,
  onCancel(): void,
  onSuccess(): void,
}

const CompImportSifToSrrModal = (props: Props) => {
  const {
    open,
    onClose,
    onCancel,
    data,
    onSuccess,
  } = props
  const { serialId } = useAppStore()
  const [sifs, setSifs] = useState<SifBrief[]>([])
  const [value, setValue] = useState<number>(0)
  const { alertError } = useSnackbar()
  const [sifOrder, setSifOrder] = useState<string>('street')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLinking, setIsLinking] = useState<boolean>(false)

  useEffect(() => {
    const fetchSifs = async () => {
      if (projectInternalId === null) {
        return
      }
      setIsLoading(true)
      try {
        const result = await apiSifGetByProject({
          serialId,
          projectInternalId: projectInternalId,
        })
        setSifs(reOrderSifs(result.data.sifs))
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }
    if (data !== null) {
      fetchSifs()
    }
  }, [data])

  if (data === null) {
    return null
  }
  const { srrId, projectId, projectInternalId, projectName } = data

  const handleOk = async () => {
    setIsLinking(true)
    try {
      const result = await apiSrrImportSif({
        srrId,
        sifId: value,
        serialId,
      })
      onSuccess()
      setIsLinking(false)
    } catch (e) {
      alertError()
      setIsLinking(false)
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue(Number((event.target as HTMLInputElement).value))
  }

  const handleSifOrderChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSifOrder(event.target.value)
    if (event.target.value === 'street') {
      setSifs(reOrderSifs(sifs))
    } else {
      setSifs(_orderBy(sifs, [event.target.value], ['desc']))
    }
  }

  const renderControls = () => {
    return <SortControlsWrapper>
      <FormControl component="fieldset">
        <RadioGroup row aria-label="userType" name="userType" value={sifOrder} onChange={handleSifOrderChange}>
          <FormControlLabel value={'street'} control={<Radio />} label="Sort by Street" />
          <FormControlLabel value={'date'} control={<Radio />} label="Sort by Date" />
        </RadioGroup>
      </FormControl>
    </SortControlsWrapper>
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle> {`Select Site Inspection`} </DialogTitle>
    <Alert severity='info'>Importing a SIF will populate sections in Shift Resource Requirement</Alert>
    {renderControls()}
    <DialogContent>
      <SectionWrapper>
        <DialogContentText>
          {!isLoading && sifs.length === 0 ? `No Site Inspection available for this project` :
            `Site Inspections in ${projectId || projectInternalId} | ${projectName}`}
        </DialogContentText>
        <Section>
          {isLoading && <SpinnerWrapper><Spinner size={24} /></SpinnerWrapper>}
          <FormControl component='fieldset'>
            <RadioGroup aria-label='drs' name='drs1' value={value} onChange={handleChange}>
              {sifs.map((sifBrief: SifBrief) => {
                return <FormControlLabel
                  key={sifBrief.id}
                  value={sifBrief.id} control={<Radio />}
                  label={`${sifBrief.street}, ${sifBrief.suburb}, ${formatDateStringOrNullDDMMYYYY(sifBrief.date)}`}
                />
              })}
            </RadioGroup>
          </FormControl>
        </Section>
      </SectionWrapper>
    </DialogContent>
    <DialogActions>
      {
        !isLoading && sifs.length !== 0 &&
        <React.Fragment>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={!value || isLinking}
            color='primary'
            variant='contained'
            onClick={handleOk}
          >
            {isLinking && <React.Fragment><ButtonSpinner size={16} /> {`  Importing  `} </React.Fragment>}
            {!isLinking && 'Import Site Inspection'}
          </Button>
        </React.Fragment>
      }

      {!isLoading && sifs.length === 0 &&
        <Button
          color='primary'
          variant='contained'
          onClick={onCancel}
        >
          {`OK, got it`}
        </Button>
      }

    </DialogActions>
  </Dialog>
}

const SectionWrapper = styled.div`
`

const Section = styled.div`
`

const SortControlsWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  width: 75%;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const ButtonSpinner = styled(CircularProgress)`
  margin-right: 10px;
`

/*
return <Dialog 
    fullWidth={true}
    maxWidth={'sm'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle> {`Select Site Inspection`} </DialogTitle>
    <Alert severity='info'>Importing a SIF will populate sections in Shift Resource Requirement</Alert>
    <DialogContent>
      <DialogContentText>
        { !loading && sifs.length === 0 ? `No Site Inspection available for this project` : 
        `Site Inspections in ${projectId || projectInternalId} | ${projectName}` }
      </DialogContentText>
      <FormControl component='fieldset'>
        <RadioGroup aria-label='drs' name='drs1' value={value} onChange={handleChange}>
          { sifs.map((sifBrief: SifBrief) => {
            return  <FormControlLabel 
              key={sifBrief.id}
              value={sifBrief.id} control={<Radio />} 
              label={`${sifBrief.street}, ${sifBrief.suburb}, ${formatDateStringOrNullDDMMYYYY(sifBrief.date)}`} 
            />
          })}
        </RadioGroup>
      </FormControl>
    </DialogContent>
    <DialogActions>
      {
        !loading && sifs.length !== 0 &&
        <React.Fragment>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button 
            disabled={!value || linking}
            color='primary'
            variant='contained'
            onClick={handleOk} 
            >
            { `Import Site Inspection` }
          </Button>
        </React.Fragment>
      }
      
      { !loading && sifs.length === 0 &&
        <Button 
          color='primary'
          variant='contained'
          onClick={onCancel} 
          >
          { `OK, got it` }
        </Button>
      }
      
    </DialogActions>
  </Dialog>
*/

export default CompImportSifToSrrModal
