export enum DrawingMode {
  Idle,

  AddArrow,
  MoveArrow,
  MoveArrowStart,
  MoveArrowEnd,
  
  AddRectangle,
  MoveRectangle,
  MoveRectangleTopLeft,
  MoveRectangleTopRight,
  MoveRectangleBottomLeft,
  MoveRectangleBottomRight,

  RotateRectangleBottomRight,
  
  AddTextBox,
  MoveTextBox,
  MoveTextBoxBottomRight,
  
  Edit,
}
export function formatDrawingMode (mode: DrawingMode) {
  return DrawingMode[mode]
}