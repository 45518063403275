import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { default as TableCellOrigin } from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from './base-TableCell'
import { useDRS } from '../StoreDRS'

const FieldLabourCrewExtrasTable = ({
    field,
    onChange,
  }: {
    field: DRS.Field,
    onChange(val: any, options: any): void,
  }) => {
    const { data: rootData } = useDRS()
    const { data, path } = field
    const { accommodation, hoursOnsite, siteAllowance} = data;

    return <Wrapper>
        <Content>
            <TableHead>
                <TableRow>
                    <LabourCrewExtrasTableHeaderCell>Edit</LabourCrewExtrasTableHeaderCell>
                    <LabourCrewExtrasTableHeaderCell>Accommodation</LabourCrewExtrasTableHeaderCell>
                    <LabourCrewExtrasTableHeaderCell>Hours on site</LabourCrewExtrasTableHeaderCell>
                    <LabourCrewExtrasTableHeaderCell>Site Allowance</LabourCrewExtrasTableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* TODO: fix the value once finalised by backend */}
                <TableRow>
                    <TableBodyCell></TableBodyCell>
                    <TableBodyCell>{accommodation ? accommodation : '0'}</TableBodyCell>
                    <TableBodyCell>{hoursOnsite ? hoursOnsite : '0'}</TableBodyCell>
                    <TableBodyCell>{siteAllowance ? siteAllowance : '0'}</TableBodyCell>
                </TableRow>
            </TableBody>
        </Content>
    </Wrapper>
  }

const LabourCrewExtrasTableHeaderCell = styled(TableCell)`
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  background-color: #DCDCDC;
  max-width: 120px;
` as any

const TableBodyCell = styled(TableCellOrigin)`
  border-bottom: 0;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
` as typeof TableCellOrigin
const Wrapper = styled.div``
const Content = styled.div`
  overflow-x: auto;
`

export default observer(FieldLabourCrewExtrasTable)