import React from 'react'
import styled from 'styled-components'
import Textfield from '@material-ui/core/TextField'


interface Props {
  label: string,
  value: string,
  onChange(e: React.ChangeEvent<HTMLInputElement>): void,
}

const FormModalTextarea = (props: Props) => {
  const {
    label,
    value,
    onChange,
  } = props
  // console.log(typeof value, value)
  return <Wrapper
    multiline
    variant='outlined'
    rows={3}
    label={label}
    value={value}
    onChange={onChange}
  >

  </Wrapper>
}

const Wrapper = styled(Textfield)`

`

export default FormModalTextarea