import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormTable from '@src/components/Form/Table'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { Rework } from '@src/types/Rework'
import useModal from '@src/components/FormModal/useModal'
import MarginTop from '@src/components/Layout/MarginTop'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import usePermission from '@src/utils/hooks/usePermission'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import DRSReworkSummaryFields from './ReworkSummaryFields'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE } from '@src/modules/DRS/DRSPage'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import _get from 'lodash/get'
import { DRSReworkSummary, DRSReworkDetailAndSummary } from '@src/types/drs/rework/Rework'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { genericUpdateDRSSection } from '@src/modules/DRS/utils/genericUpdateDRSSection'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'

interface DRSReworkSummaryRow extends DRSReworkSummary {
  _key_: string,
  _type_: PAETableRowType,
  _no_?: number,
  _editable_: boolean,
}

const NULL_VALUES = {
  reworkEmulsion: null,
  reworkLabourHours: null,
  reworkPercentage: null,
  reworkQuantity: null,
  depot: null,
}
const ZERO_VALUES = {
  reworkEmulsion: '0',
  reworkLabourHours: '0',
  reworkPercentage: '0',
  reworkQuantity: '0',
  depot: null,
}

interface Props {
  projectTypeId: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal,
}

const CompDRSReworkSummary = (props: Props) => {
  const {
    projectTypeId,
  } = props
  const normalizedType = normalizeProjectTypeId(projectTypeId)
  const { id } = useParams<{id: string}>()
  const { drs, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const { alertError, alertDone, alert } = useSnackbar()
  const { confirm } = useConfirm()
  const [ open, modalData, openAndSetData, closeModal ] = useModal<DRSReworkSummary>({})

  if (drs === null) {
    return null
  }
  
  const handleSave = async (form: DRSReworkSummaryRow) => {
    updateItemByValues(form, form)
  }
  function handleRevert(form: DRSReworkSummaryRow) {
    updateItemByValues(form, NULL_VALUES)
  }
  function handleMarkZero(form: DRSReworkSummaryRow) {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = (form: DRSReworkSummaryRow) => {
    const retainedDetails = drs.rework[normalizedType].detail
    if (retainedDetails.length) {
      alert({
        type: 'warning',
        message: `Please remove all assets first to make sure that there's no rework.`
      })
      return
    }
    const data = {
      summary: null,
      detail: retainedDetails,
      performed: false,
    }
    update(data)
  }
  const updateItemByValues = (form: DRSReworkSummaryRow, values: any) => {
    const retainedDetails = drs.rework[normalizedType].detail || []
    const retainedSummary = drs.rework[normalizedType].summary || null
    const data = {
      detail: retainedDetails,
      performed: true,
      summary: {
        ...retainedSummary,
        [mapDRSPageTypeToPAE(drsPageType)]: {
          ...values,
        }
      },
    }
    update(data)
  }
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.Rework,
      path: normalizedType,
      data,
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    })
  }
  const getTableData = () =>{
    const summary = (drs.rework[normalizedType] as DRSReworkDetailAndSummary).summary
    if (summary === null) {
      return []
    }
    const doubled = doubleUp([summary], drsPageType)
    const doubledWithActions = doubled.map((data: any) => mapMoreActions(data, drsPageType))
    return doubledWithActions
  }
  return <Wrapper>
    <FormPanel title={`Rework Details`}>
      <FormPAETable<DRSReworkSummaryRow>
        fields={DRSReworkSummaryFields}
        data={getTableData()}
        editable={true}
        onEditRow={(row: DRSReworkSummaryRow) => {
          openAndSetData(row)
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
        isSelectable={true}
      />
      { isPlan && !getTableData().length &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add Rework Details
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>


    { open && 
      <Modal<DRSReworkSummary>
        maxWidth={'xs'}
        open={open}
        title='Rework'
        data={{
          rowData: modalData,
          fields: DRSReworkSummaryFields,
          columns: [
            {
              title: 'Rework Information',
              fieldKeys: [
                'reworkPercentage', 
                'reworkQuantity', 
                'reworkLabourHours', 
                'reworkEmulsion',
                'depot',
              ],
            },
          ]
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSReworkSummary
