import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useLocation, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { LeftNav } from '@src/components/LeftNav'
import Container from '@material-ui/core/Container'
import { NavDeepLink } from '@src/types/NavDeepLink'
import { SifSectionName } from '@src/types/SifSectionName'
import LeftNavStickyWrapper from '@src/components/LeftNav/StickyWrapper'
import JobDetails from './parts/JobDetails'
import SiteInformation from './parts/SiteInformation'
import HSESiteAssessment from './parts/HSESiteAssessment'
import SiteDiagram from './parts/SiteDiagram'
import { apiSifGetOne } from '@src/apis/sif-get-one'
import { useAppStore } from '@src/AppStore'
import { Sif } from '@src/types/Sif'
import { formatSifStatus } from '@src/types/SifStatus'
import { apiGetFiles } from '@src/apis/files/get-files'
import { PackFormType } from '@src/types/PackFormType'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'
// import useDeepLink from '@src/utils/hooks/useDeepLink'

const ProjectSifPage = () => {
  const { id } = useParams<{ id: string }>()
  const { hash } = useLocation()
  const history = useHistory()
  const { serialId } = useAppStore()
  const emptySif = {} as Sif
  const [ sif, setSif ] = useState<Sif>(emptySif)
  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await apiSifGetOne({
          id: Number(id!),
          serialId,
        })
        setSif(result.data.sif)
      } catch (e) {
        
      }
    }
    fetch()
  }, [])
  const deepLinks: NavDeepLink<SifSectionName>[] = [
    {
      anchor: 'Job Details',
      urlHash: 'job-details',
      key: SifSectionName.JobDetails,
    },
    {
      anchor: 'Site Information',
      urlHash: 'site-information',
      key: SifSectionName.SiteInformation,
    },
    {
      anchor: 'HSE Site Assessment',
      urlHash: 'hse',
      key: SifSectionName.HSE,
    },
    {
      anchor: 'Site Diagram',
      urlHash: 'site-diagram',
      key: SifSectionName.SiteDiagram,
    },
  ]
  const [ activeSection, setActiveSection ] = useState<SifSectionName>(SifSectionName.JobDetails)
  const activeIndex = deepLinks.findIndex((link: NavDeepLink<SifSectionName>) => link.key === activeSection)
  // deep linking
  useEffect(() => {
    if (hash) {
      const trimmedHash = hash.substring(1)
      if (deepLinks.some((link: NavDeepLink<SifSectionName>) => link.urlHash === trimmedHash)) {
        setActiveSection(
          deepLinks.find((link: NavDeepLink<SifSectionName>) => link.urlHash === trimmedHash)!
            .key
        )
        return
      }
    }
    setActiveSection(SifSectionName.JobDetails)
  }, [id])
  // deep linking
  useEffect(() => {
    history.replace({
      hash: `#${deepLinks.find((link: NavDeepLink<SifSectionName>) => link.urlHash === activeSection)!
        .urlHash}`
    })
  }, [activeSection])

  const { status } = sif
  if (!sif || !sif.id) {
    return null
  }
  const { projectName, } = sif.jobDetails.projectDetails
  const { inspectedBy } = sif.jobDetails.inspectionDetails
  const { suburb, street } = sif.siteInformation.siteDetails

  const handleOnAnchorClick = (index: number) => {
    setActiveSection(deepLinks[index].key)
  }

  function renderPart () {
    if (!sif) {
      return null
    }
    switch(activeSection) {
      case SifSectionName.JobDetails: {
        return <JobDetails sif={sif}/>
      }
      case SifSectionName.SiteInformation: {
        return <SiteInformation  sif={sif}/>
      }
      case SifSectionName.HSE: {
        return <HSESiteAssessment sif={sif}/>
      }
      case SifSectionName.SiteDiagram: {
        return <SiteDiagram sif={sif}/>
      }
    }
  }
  
  return <Container maxWidth='xl' style={{ position: 'relative', paddingTop: '16px' }}>
    <Wrapper>
      <LeftNavStickyWrapper top={16}>
        <LeftNav 
          title={`Site Inspection Form`}
          statusName={formatSifStatus(status.id)}
          sections={[
            {
              label: 'Project',
              value: projectName || '',
            },
            {
              label: 'Site',
              value: `${suburb}, ${street}`,
            },
            {
              label: 'Supervisor',
              value: `${inspectedBy ? formatProfileFirstLastName(inspectedBy) : ''}`,
            },
          ]}
          anchors={deepLinks.map((link: NavDeepLink<SifSectionName>) => link.anchor)}
          activeIndex={activeIndex}
          onAnchorClick={handleOnAnchorClick}
        />
      </LeftNavStickyWrapper>
      <PartWrapper>
        { renderPart() }
      </PartWrapper>
    </Wrapper>

  </Container>
}

const Wrapper = styled.div`
  display: flex;
`
const PartWrapper = styled.div`
  padding: 0 40px;
  flex-grow: 1;
`

export default ProjectSifPage