import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'
interface Options {
  formType: PackFormType,
  formId: number | string,
  roleId: string,
  serialId: number,

  preupload?: string,
}

export const apiPresignedToken = ({
  formType,
  formId,
  roleId,
  serialId,
  preupload

}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/documents/${formType}/${formId}`,
    
   
    params: {
      reqRole: roleId,
      reqSid: serialId,
      preupload
    }
  }
  return ax(options)
}