import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FieldName } from './Field'
import BoralRadio from '../../../../../components/BoralRadio'

const FieldRadio = ({
  field,
  onChange,
}: DRS.Comp) => {
  const { options } = field.typeMeta!
  const handleChange = (e: SyntheticEvent) => {
    const selectResult = {
      id: (e.target as HTMLInputElement).value
    }
    onChange(selectResult)
  }
  let selectedId = ''
  if (field.data && field.data.id) {
    selectedId = field.data.id
  }
  
  return <Wrapper>
    <FormControl>
      {/* <FieldName>Asset Depot</FieldName> */}
      <RadioGroup
        aria-label={field.name}
        name={field.name}
        value={selectedId}
        onChange={handleChange}
      >
        { options!.map((option: AIMS.ValueLabel) => {
          const { value, label } = option
          return <FormControlLabel key={value} value={value} control={<BoralRadio />} label={label} />
        })}
      </RadioGroup>
    </FormControl>
  </Wrapper>
}

const Wrapper = styled.div``
const Content = styled.div``

export default FieldRadio