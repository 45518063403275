import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useBundle } from '@src/utils/hooks/useBundle'
import { IdDescription } from '@src/types/utils/IdDescription'
import { ValueLabel } from '@src/types/common/ValueLabel'
import { useObserver, observer } from 'mobx-react-lite'
import { SRRResourceTypeId } from '@src/types/SRRResourceTypeId'
import { DRSAssetDepotType } from '@src/modules/DRS/parts/2-Rework/parts/ReworkAssetsFields'
import { Crew } from '@src/types/Crew'

interface K {
  id: string | number,
  description?: string,
  label?: string,
  raw?: any,
}
interface Props<T extends K> {
  type: string,
  label?: string,
  value: null | K,
  onChange(item: T): void,
  options?: any[],
  disabled?: boolean,
}

interface K {
  id: string | number,
  description?: string,
  label?: string,
  raw?: any,
}

function FormModalSelect<T extends K>(props: Props<T>) {
  const {
    type,
    label,
    value,
    onChange,
    options = [],
    disabled = false,
  } = props
  const [ bundle ] = useBundle()
  // console.log(type)
  function renderContent() {
    switch (type) {
      case 'asphaltLayer': {
        //console.log('asphaltLayers=' + bundle.asphaltLayers)
        return <Select<string>
          id='asphaltLayer'
          label={ label || 'Asphalt Layer' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.asphaltLayers.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'asphaltProcess': {
        //console.log('asphaltLayers=' + bundle.asphaltLayers)
        return <Select<string>
          id='asphaltProcess'
          label={ label || 'Asphalt Process' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.asphaltLayers.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'wasteReturnReason': {
        //console.log('wasteReturnReasons=' + bundle.wasteReturnReasons)
        return <Select<string>
          id='wasteReturnReasons'
          label={ label || 'Waste Return Reasons' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.wasteReturnReasons.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'spraySealType': {
        return <Select<string>
          id='spraySealType'
          label={ label || 'Seal Type' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.spraySealTypes.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'MachineProvider': {
        return <Select<string>
          id='MachineProvider'
          label={ label || 'Machine Provider' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.providers.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'MachineProfilingWorkType': {
        return <Select<string>
          id='MachineProfilingWorkType'
          label={ label || 'Work Type' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.srrProfilingMillTypes.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'ProfilingMachineSize': {
        return <Select<string>
          id='ProfilingMachineSize'
          label={ label || 'Mill Size' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.srrProfilingMillSizes.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'Answers': {
        return <Select<string>
          id='Answers'
          label={ label || 'Answers' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={bundle.answers.map((item: IdDescription) => ({ value: `${item.id}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'SRRResourcePaverSelect': {
        return <Select<string>
          id='SRRResourcePaverSelect'
          label={ label || 'Size' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.srrMachineResourceSizes
              .filter((item) => item.type.id === SRRResourceTypeId.Paver)
              .map((item) => ({ value: `${item.id}`, label: item.description }))
          }
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'SRRResourceSteelRollerSelect': {
        return <Select<string>
          id='SRRResourceSteelRollerSelect'
          label={ label || 'Size' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.srrMachineResourceSizes
              .filter((item) => item.type.id === SRRResourceTypeId.SteelRoller)
              .map((item) => ({ value: `${item.id}`, label: item.description }))
          }
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'SRRResourceMultiRollerSelect': {
        return <Select<string>
          id='SRRResourceMultiRollerSelect'
          label={ label || 'Size' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.srrMachineResourceSizes
              .filter((item) => item.type.id === SRRResourceTypeId.MultiRoller)
              .map((item) => ({ value: `${item.id}`, label: item.description }))
          }
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'mobileAssetType': {
        return <Select<string>
          id='mobileAssetType'
          label={ label || 'Asset Type' }
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.mobileAssetTypes
              .map((item) => ({ value: `${item.id}`, label: item.description }))
          }
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'DRSAssetDepotType': {
        return <Select<string>
          id='DRSAssetDepotType'
          label={ label || 'Asset Depot' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            [
              {
                id: DRSAssetDepotType.MyDepot,
                description: 'My Depot'
              },
              {
                id: DRSAssetDepotType.MyState,
                description: 'My State'
              },
            ]
              .map((item) => ({ value: `${item.id}`, label: item.description }))
          }
          onChange={
            (item: { value: string, label: string }) => {
              onChange({
                id: item.value,
                description: item.label,
              } as T)
            }
          }
        />
      }
      case 'supplier-agreement': {
        return <Select<string>
          id='supplier-agreement'
          label={ label || 'Supplier Agreement' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            options // id, description
              .map((item) => ({ value: item.id, label: item.description, raw: item }))
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'supplier-agreement-item': {
        return <Select<string>
          id='supplier-agreement-item'
          label={ label || 'Activity' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            options // id, description
              .map((item) => ({ value: item.id, label: item.description, raw: item }))
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'qualityOfWorkmanship': {
        return <Select<string>
          id='qualityOfWorkmanship'
          label={ label || 'Quality Of Workmanship' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.qualityOfWorkmanships // id, description
              .map((item) => ({ value: item.id as string, label: item.description, raw: item }))
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'create-shift-crews': {
        return <Select<string>
          id='create-shift-crews'
          label={ label || 'Crew' }
          selected={value === null ? null : `${value.id}`}
          options={
            options // id, description
              .map((item: Crew) => ({ value: item.id + '', label: item.name, raw: item }))
          }
          onChange={
            (item: { value: string, label: string, raw: Crew }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'jobSpecification': {
        return <Select<string>
          id='jobSpecification'
          label={ label || 'Job Specification' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            bundle.jobSpecifications // id, description
              .map((item) => ({ value: item.id as string, label: item.description, raw: item }))
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }

      case 'mobile-asset-hours': {
        return <Select<string>
          id='mobile-asset-hours'
          label={ label || 'Hours' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            [
              {
                value: '0',
                label: '0',
              },
              {
                value: '2.5',
                label: '2.5',
              },
              {
                value: '5',
                label: '5',
              },
              {
                value: '10',
                label: '10',
              },
            ]
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }

      case 'asphProcess': {
        return <Select<string>
          id='asphProcess'
          label={ label || 'Process' }
          disabled={disabled}
          selected={value === null ? null : `${value.id}`}
          options={
            [
              {
                value: 'Supply & Lay',
                label: 'Supply & Lay',
              },
              {
                value: 'Profile & Fill',
                label: 'Profile & Fill',
              },
              {
                value: 'Profile',
                label: 'Profile',
              },
              {
                value: 'Key & Overlay',
                label: 'Key & Overlay',
              },
              {
                value: 'Profile & Patch',
                label: 'Profile & Patch',
              },
              {
                value: 'Repair',
                label: 'Repair',
              },
            ]
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'sprayProcess': {
        return <Select<string>
          id='sprayProcess'
          label={ label || 'Process' }
          disabled={disabled}
           selected={value === null ? null : `${value.description}`}
          options={bundle.spraySealTypes.map((item: IdDescription) => ({ value: `${item.description}`, label: item.description }))}
          onChange={
            (item: { value: string, label: string }) => {
              console.log(`item-sprayProcess`);
              console.log(item);
              console.log(`item-sprayProcess`);
              onChange({
                id: item.value,
                description: item.value
              } as T)
            }
          }
        /> 
      }
      case 'existingSurface': {
        return <Select<string>
          id='existingSurface'
          label={ label || 'Surface' }
          disabled={disabled}
          selected={value === null ? null : `${value.description}`}
          options={
            [
              {
                value: 'Gravel',
                label: 'Gravel',
              },
              {
                value: 'Spray Seal',
                label: 'Spray Seal',
              },
              {
                value: 'Asphalt',
                label: 'Asphalt',
              },
              {
                value: 'Concrete',
                label: 'Concrete',
              },
              {
                value: 'Bridge Deck',
                label: 'Bridge Deck',
              },
            ]
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.value,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      case 'leave-note-type': {
        return <Select<string>
          id='leave-note-type'
          label={ label || 'Type of Leave' }
          disabled={disabled}
          selected={value === null ? null : `${value}`}
          options={
            [
              {
                value: '0',
                label: 'Annual Leave',
              },
              {
                value: '1',
                label: 'Public Holiday',
              },
              {
                value: '2',
                label: 'RDO',
              },
              {
                value: '3',
                label: 'Weekend',
              },
			  {
                value: '4',
                label: 'Work Other Depot',
              },
			  {
                value: '5',
                label: 'Subcontractor Not Required',
              },
			  {
                value: '6',
                label: 'Split Crew',
              }
            ]
          }
          onChange={
            (item: { value: string, label: string, raw: any }) => {
              onChange({
                id: item.value,
                description: item.label,
                raw: item.raw,
              } as T)
            }
          }
        /> 
      }
      default: {
        return null
      }
    }
    return null
  }
  return (
    <Wrapper>
      { renderContent() }
    </Wrapper>
  )
}

const Wrapper = styled.div``

interface SelectOptionValueLabel<T> {
  value: T,
  label: string,
  raw?: any,
}

interface SelectProps<T extends string | number> {
  id: string | number,
  label: string,
  selected: T | null,
  options: SelectOptionValueLabel<T>[],
  onChange(item: { value: T, label: string, raw?: any }): void,
  disabled?: boolean,
} 
function Select<T extends string | number>(props: SelectProps<T>) {
  const {
    id,
    label,
    selected,
    options = [],
    onChange,
    disabled = false,
  } = props
  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const value = event.target.value as T
    onChange({
      value: value,
      label: label,
      raw: options.find((option: SelectOptionValueLabel<T>) => option.value === value)!.raw,
    })
  }
  return <FormControl fullWidth>
    <InputLabel htmlFor={`${id}`}>{label}</InputLabel>
    <MuiSelect
      placeholder={label}
      value={selected || ''}
      disabled={disabled}
      onChange={handleChange}
      inputProps={{
        name: id,
        id: `${id}`,
      }}
    >
      { options.map((option: SelectOptionValueLabel<T>) => {
        const { value, label } = option
        return <MenuItem key={value} value={value}>{label}</MenuItem>
      })}
    </MuiSelect>
  </FormControl>
}

export default observer(FormModalSelect) as typeof FormModalSelect