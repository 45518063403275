import React, { useContext } from 'react'
import { observable, action } from 'mobx'

interface Snackbar {
  isOpen: boolean,
  message: string,
  type: string,
  autoHide: boolean,
  close(): void,
  alert(options: AlertOptions): void,
  alertDone(): void,
  alertError(): void,
}
interface AlertOptions {
  message: string,
  type?: 'info' | 'warning' | 'error' | 'success',
  autoHide?: boolean,
}
const store: Snackbar = observable<Snackbar>({
  isOpen: false,
  message: '',
  type: 'info',
  autoHide: true,
  close: action(() => {
    store.isOpen = false
    store.message = ''
  }),
  alert: action(({
    message,
    type = 'info',
    autoHide = true,
  }: AlertOptions) => {
    store.isOpen = true
    store.message = message
    store.type = type
    store.autoHide = autoHide
  }),
  alertDone: action(() => {
    store.isOpen = true
    store.message = 'Done.'
    store.type = 'info'
    store.autoHide = true
  }),
  alertError: action(() => {
    store.isOpen = true
    store.message = 'Error'
    store.type = 'error'
    store.autoHide = true
  }),
})


const context = React.createContext(store)

export const useSnackbar = () => {
  return useContext<Snackbar>(context)
}
