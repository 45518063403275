import _set from 'lodash/set'
import _get from 'lodash/get'
import moment from 'moment'
import { getGeneralCrewHours, getSupervisorHours, getForemanHours, getTotalHours, mergeActualToEdit } from '../../Create/sections/6-LabourMembers'
import { getStartEndDiff, formatHoursToDecimal } from '../../Create/comps/f-LabourMemberTable'

export default function getLabourSectionData(data: DRS.RootData) {
  const members = getMemberPanels(data)
  const summary = getLabourSummaryPanel(data)

  const section = {
    label: 'LABOUR',
    panels: [ 
      ...members,
      summary,
    ]
  }
  return section
}

export function getMemberPanels(data: DRS.RootData) {
  const label = `Crew Member`
  const path = `labour.detail`
  const panelData: any = _get(data, path)
  const panels = panelData.map((member: any, index: number) => {
    return {
      label: `${label} #${index + 1}`,
      repeatable: true,
      removable: true,
      __drs__repeated_panel__: member.__drs__repeated_panel__,
      repeatItem: {
        available: true,
        startTime: '',
        endTime: '',
        hours: '',
        'comments': '',
      },
      repeatPath: path,
      path: `${path}.${index}`,
      fields: getMemberFields(`${path}.${index}`),
    }
  })
  return panels
}


function getMemberFields(path: string) {
  return [
    {
      showOnlyPlan: true,
      label: 'Name',
      type: 'text',
      path,
      relativePath: 'labour.email',
      editable: false,
      format: (profile: any) => {
        try {
          return `${profile.firstName} ${profile.lastName}`
        } catch (e) {
          return ''
        }
      }
    },
    {
      label: 'Start Time',
      type: 'time',
      path,
      relativePath: 'startTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
      meta: {
        panelPath: path,
      }
    },
    {
      label: 'End Time',
      type: 'time',
      path,
      relativePath: 'endTime',
      format: (value: any) => value ? moment(value).format('HH:mm') : '',
      editable: true,
      meta: {
        panelPath: path,
      }
    },
    {
      editReadonly: true,
      label: 'Hours',
      type: 'transport-table-hours',
      path,
      relativePath: 'hours',
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const timeTypeData: any = _get(rootData, `${panelPath}.${planActualOrEdit}`);
          const { startTime, endTime } = timeTypeData
          return formatHoursToDecimal(getStartEndDiff({
            start: startTime,
            end: endTime,
          }))
        } catch (e) {
          return 0
        }
      },
      editable: true,
      readonly: true,
      showTrending: true,
    },
    {
      label: 'Comments',
      type: 'text',
      path,
      relativePath: 'comments',
      editable: true,
    },
  ]
}

export function getLabourSummaryPanel(data: DRS.RootData) {
  const label = `Labour Summary`
  const path = `labour.summary`
  const summaryPanel = {
    label,
    repeatable: false,
    path,
    fields: getSummaryFields(path),
  }
  return summaryPanel
}

function getSummaryFields(path: string) {
  return [
    {
      alwaysShow: true,
      editReadonly: true,
      label: 'Crew Count',
      type: 'number',
      relativePath: 'crewCount',
      editable: false,
      showTrending: true,
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        try {
          const crewMembers: any = _get(rootData, `labour.detail`)
          return crewMembers.reduce((acc: number, curr: any) => {
            // count if has email
            if (curr.email) {
              return acc + 1
            }
            return acc
          }, 0)
        } catch (e) {
          return 0
        }
      },
    },
    {
      alwaysShow: true,
      editReadonly: true,
      label: 'Crew Hours',
      type: 'number',
      relativePath: 'crewHours',
      editable: false,
      showTrending: true,
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        let members: any = _get(rootData, `labour.detail`)
        if (planActualOrEdit === 'edit') {
          members = mergeActualToEdit({
            members,
          })
        }
        return formatHoursToDecimal(getGeneralCrewHours(members, planActualOrEdit)) || 0
      },
    },
    {
      label: 'Project Manager Hours',
      type: 'number',
      path,
      relativePath: 'pmHours',
      editable: true,
      showTrending: true,
    },
    {
      alwaysShow: true,
      editReadonly: true,
      label: 'Supervisor Hours',
      type: 'number',
      relativePath: 'supervisorHours',
      editable: false,
      showTrending: true,
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        let members: any = _get(rootData, `labour.detail`)
        if (planActualOrEdit === 'edit') {
          members = mergeActualToEdit({
            members,
          })
        }
        return formatHoursToDecimal(getSupervisorHours(members, planActualOrEdit)) || 0
      },
    },
    {
      alwaysShow: true,
      editReadonly: true,
      label: 'Foreman Hours',
      type: 'number',
      relativePath: 'foremanHours',
      editable: false,
      showTrending: true,
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        let members: any = _get(rootData, `labour.detail`)
        if (planActualOrEdit === 'edit') {
          members = mergeActualToEdit({
            members,
          })
        }
        return formatHoursToDecimal(getForemanHours(members, planActualOrEdit)) || 0
      },
    },
    {
      alwaysShow: true,
      editReadonly: true,
      label: 'Total Hours',
      type: 'number',
      relativePath: 'totalCrewCount',
      editable: false,
      readonly: true,
      showTrending: true,
      isDerived: true,
      derivedFunc: (rootData: DRS.RootData, panelPath: string, planActualOrEdit: DRS.PlanActualEdit) => {
        let editMembers: any = []
        if (planActualOrEdit === 'edit') {
          let memberRootData: any = _get(rootData, `labour.detail`);
          editMembers = mergeActualToEdit({
            members: memberRootData,
          })
        }
        return formatHoursToDecimal(getTotalHours(rootData, planActualOrEdit, editMembers)) || 0
      },
    },
  ]
}