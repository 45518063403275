import React, { ChangeEvent, useEffect } from 'react'
import styled, {css} from 'styled-components'
import { DrawingElement, DrawingElementProperties, isTextBoxElement, isArrowElement } from './types/DrawingElement'
import Textfield from '@material-ui/core/TextField'

interface Props {
  selectedElement: DrawingElement | null,
  onChange(p: keyof DrawingElementProperties, value: any): void,
  onRemoveSelected(): void,
}

const CompPropertySettings = (props: Props) => {
  const {
    selectedElement,
    onChange,
    onRemoveSelected,
  } = props
  const handleChange = (fieldName: keyof DrawingElementProperties) => (e: ChangeEvent<HTMLInputElement>) => {
    onChange(fieldName, e.target.value)
  }
  const handleColorChange = (color: string) => () => {
    onChange('stroke', color)
  }
  const handleUserKeyPress = (event: any) => {
    if ((event.target as HTMLElement).nodeName !== 'INPUT' && event.keyCode === 8 || event.keyCode === 46 /* backspace */) {
      event.preventDefault()
      onRemoveSelected()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress, false)
    return () => {
      document.removeEventListener('keydown', handleUserKeyPress, false)
    }
  })
  const renderMain = () => {
    if (selectedElement === null) {
      return null
    }
    return <React.Fragment>
      { renderFields()}
    </React.Fragment>
  }
  const renderFields = () => {
    if (selectedElement === null) {
      return null
    }
    if (isTextBoxElement(selectedElement)) {
      const {
        text = '',
        stroke = '',
      } = selectedElement.properties
      return <React.Fragment>
        <Textfield value={text} label='text' name='text' onChange={handleChange('text')} type='text' />
      </React.Fragment>
    } else if (isArrowElement(selectedElement)) {
      const {
        stroke,
      } = selectedElement.properties
      const COLORS = [ '#CF2929', '#3196D4', 'black']
      return <React.Fragment>
        {
          COLORS.map((color: string) => {
            const active = stroke === color ? 1 : 0
            return <ColorCircle 
              key={color}
              color={color} 
              active={active}
              onClick={handleColorChange(color)}  
            >
            </ColorCircle>
          })
        }
        </React.Fragment>
    }
    return null
  }
  
  return <Wrapper>
    {
      selectedElement === null ?
        'no element selected' :
        renderMain()
    }
  </Wrapper>
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
`
const ColorCircle = styled.div<{color: string, active: number}>`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${props => props.color};
  cursor: pointer;
  margin-right: 10px;
  ${props => {
    if (props.active) {
      return css`
        border: 2px solid #189688;
      `
    }
  }}
`

export default CompPropertySettings