import React, { useEffect } from 'react'
import queryString from 'query-string'
import { REDIRECT_AUTH_URL, IDENTITY_PROVIDER } from '../../constants'
import { apiProfileForOktaUsername } from '../../apis/profile-for-okta-username'
import { apiToken, apiRefreshToken } from '../../apis/token'
import { apiOktaUser } from '../../apis/okta-user'
import { setCookieAccessToken, setCookieRefreshToken, setCookieIdToken, getCookieRefreshToken, getCookieAccessToken, getCookieIdToken, getCookieEmail, setCookieEmail } from '../cookies'
import { useAppStore } from '../../AppStore'
import { RoleId } from '@src/types/common/RoleId'

const useLoginEffect = (context?: any) => {
  const { setLoggedIn, setProfile } = useAppStore()
  const email = getCookieEmail()
  let idToken = getCookieIdToken()
  let accessToken = getCookieAccessToken()
  let refreshToken = getCookieRefreshToken()
  useEffect(() => {
    
    const startLoginFlow = async () => {
      if (refreshToken) {
        if (idToken && accessToken) {
          if (email) {
            proceedToGetProfile()
          } else {
            // no username? get it with access
            proceedToGetEmail()
          }
        } else {
          // no id & access token, get it with refresh token
          proceedToRefreshToken()
        }
      } else {
        // no refresh token, try to get user to login
        const parsed: any = queryString.parse(window.location.search)
        if (parsed.code) {
          try {
            const tokenResponse = await apiToken(parsed.code)
            const { id_token, access_token, refresh_token } = tokenResponse.data
            console.log('idToken', id_token)
            console.log('accessToken', access_token)
            console.log('RefreshToken', refresh_token)
            setCookieIdToken(id_token)
            setCookieAccessToken(access_token)
            setCookieRefreshToken(refresh_token)
            proceedToGetEmail()
          } catch(error) {
            console.log('failed to get token', error)
            window.location.replace(REDIRECT_AUTH_URL)
          }
        } else {
          // no code in search, have to redirect
          window.location.replace(REDIRECT_AUTH_URL)
        }
      }
    }

    if (process.env.NODE_ENV === 'development') {
      // const currentUser = require('../../test-accounts').default
      // prepareToGetProfile(`${IDENTITY_PROVIDER}_${currentUser.email}`)
      proceedToRefreshToken()
    } else {
      startLoginFlow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const proceedToRefreshToken = async () => {
    const refreshResponse = await apiRefreshToken()
    try {
      if (refreshResponse.data 
          && refreshResponse.data.AuthenticationResult
          && refreshResponse.data.AuthenticationResult.IdToken
          ) {
        const { AuthenticationResult } = refreshResponse.data
        setCookieIdToken(AuthenticationResult.IdToken)
        setCookieAccessToken(AuthenticationResult.AccessToken)
        
        proceedToGetEmail()
      } else {
        // clearTokenAndRefresh(email)
        console.log('failed to refresh token')
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        setProfile({
          firstName: 'ju', 
          lastName: 'c', 
          active: true,
          email: '',
          serialId: 0,
          crews: [],
          landLine: '',
          mobileNumber: '',
          employeeType: '',
          labourHireCompany: '',
          state: { 
            id: '', 
            name: '' 
          }, 
          region: { 
            id: '', 
            name: '' 
          }, 
          depot: { 
            id: '', 
            name: '' 
          }, 
          depotPayroll: { 
            id: '', 
            name: '' 
          }, 
          roles: [{
            id: RoleId.ProjectManager,
          }]
        })
        proceedToLoggedIn()
      }
    }
  }

  const proceedToGetEmail = async () => {
    const userResponse = await apiOktaUser(getCookieAccessToken())
    if (userResponse.data && userResponse.data.username) {
      const username = userResponse.data.username
      const email = `${username.substring(IDENTITY_PROVIDER.length + 1).toLowerCase()}`
      setCookieEmail(email)

      proceedToGetProfile()
    }
  }

  const proceedToGetProfile = async () => {
    // if (process.env.NODE_ENV === 'development') {
    //   proceedToLoggedIn()
    // }
    try {
      const profileResult: any = await apiProfileForOktaUsername(getCookieEmail())
      if (profileResult && profileResult.data) {
        let profile = profileResult.data.profile
        
        setProfile(profile)

        proceedToLoggedIn()

      }
    } catch (error) {
      // procced to logged in anyway in local env
      if (process.env.NODE_ENV === 'development') {
        // mock up profile here
        setProfile({
          firstName: '', 
          lastName: '', 
          active: true,
          email: '',
          serialId: 0,
          crews: [],
          landLine: '',
          mobileNumber: '',
          employeeType: '',
          labourHireCompany: '',
          state: { 
            id: '', 
            name: '' 
          }, 
          region: { 
            id: '', 
            name: '' 
          }, 
          depot: { 
            id: '', 
            name: '' 
          }, 
          depotPayroll: { 
            id: '', 
            name: '' 
          },
          roles: [{
            id: RoleId.ProjectManager,
          }]
        })
        proceedToLoggedIn()
      }
    }

    
  }

  const proceedToLoggedIn = () => {
    // dispatch({ type: 'SET_LOGGED_IN', })
    setLoggedIn(true)
  }
  
}


export default useLoginEffect