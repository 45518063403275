import { ax } from '@src/utils/axios/axios'
import { BASE_API_URL } from '@src/constants'

interface Options {
  shiftId: string,
  serialId: number,
}

export const apiShiftGetQAS = ({
  shiftId,
  serialId,
}: Options) => {
  const data = {
  }
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/qas/byShift/${shiftId}`,
    data,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}