import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PanelName from './PanelName'

import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle'
// import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import CheckCircle from '@material-ui/icons/CheckCircle'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import { useConfirm } from '@src/components/GlobalConfirmDialog'

const PanelHeader = ({
  name,
  isCollapsed,
  setIsCollapsed,
  fieldsCompleted,
  repetitive,
  onAdd,
  onRemove,
  removable,
} : {
  name: string,
  isCollapsed: boolean,
  setIsCollapsed(isCollapsed: boolean): void,
  fieldsCompleted?: boolean,
  repetitive?: boolean,
  onAdd(): void,
  onRemove(): void,
  removable?: boolean,
}) => {
  const { confirm } = useConfirm()
  const beforeRemove = async () => {
    try {
      await confirm({
        title: `Confirm removal of ${name}`,
        message: 'This will erase any changes you have made to the fields and cannot be undone',
        confirmButtonText: 'Remove', 
      })
      onRemove()
    } catch (error) {

    }
  }
  return <Wrapper>
    <PanelName name={name}/>
    <Operation>
      {
        repetitive &&
        <React.Fragment>
          <IconButton size='small' onClick={onAdd}>
            <AddCircle /> 
          </IconButton>
          { removable &&
            <IconButton size='small' onClick={beforeRemove}>
              <RemoveCircle /> 
            </IconButton>
          }
        </React.Fragment>
      }
      {/* { fieldsCompleted && 
        <IconButton size='small'>
          <StyledCheckCircle /> 
        </IconButton>
      } */}
      <IconButton size='small' onClick={() => setIsCollapsed(!isCollapsed) }>
        <ArrowDropDownCircle />
      </IconButton>
    </Operation>
  </Wrapper>
}

// -- styled comps --
const Wrapper = styled.div`
  display: flex;
  height: 76px;
  align-items: center;
`
const Operation = styled.div`
  align-items: flex-end;
  flex: 1;
  text-align: right;
`
const Styled = styled(Typography)`
  color: #009688;
  font-size: 24px;
  font-weight: bold;
  /* padding: 0 20px;
  margin-bottom: 20px; */
`
const StyledCheckCircle = styled(CheckCircle)`
  color: #009688;
`

export default PanelHeader