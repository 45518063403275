import React from "react";
import styled from "styled-components";
import _reject from "lodash/reject";
import _merge from "lodash/merge";
import _isEmpty from "lodash/isEmpty";
import _unionBy from "lodash/unionBy";
import _union from "lodash/union";
import _find from "lodash/find";
import _some from "lodash/some";

import { useCrewCalender } from "@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";

import { DragItemTypes } from "@src/types/DragItemTypes";
import { DndProvider, DropTargetMonitor, useDrop } from "react-dnd";
import ShiftCardViewItem from "./ShiftCardViewItem";

import { useHover } from "@src/utils/hooks";

interface DragItem {
  type: string;
  index: number;
  typeOpt: string;
}
interface Props {
  title: string;
  shiftCards: any;
  type: string;
  onMove(type: string, items: any): void;
  moveCard(dragIndex: number, hoverIndex: number, type: string): void;
  id: string;
}

const ShiftCardViewSection = (props: Props) => {
  const { title, shiftCards, type, onMove, moveCard, id } = props;
  const {
    isCalendarControlsDisabled,
    setShiftCardTargetID,
    clearSelectedShiftCards,
  } = useCrewCalender();
  const [boxRef, hovered] = useHover();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragItemTypes.ShiftCardViewItem,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      const mn: any = monitor;
      // console.log(mn);
      setShiftCardTargetID(mn.targetId);
      //console setShiftCardTargetID(monitor.targetId);
      //  console.log(`DRSBox useDrop() hover`)
    },
    collect: (monitor) => ({
      isOver: !isCalendarControlsDisabled && !!monitor.isOver(),
      canDrop: !isCalendarControlsDisabled,
    }),
    drop: (item: any, monitor: any) => {
      if (item.typeOpt == type) {
        clearSelectedShiftCards();
      }
      //  console.log('drp');
      //  console.log(item.typeOpt,type)
    },
  });
  drop(boxRef);

  return (
    <SectionWrapper ref={boxRef} id={id}>
      <Section>
        <Title>{title}</Title>
        {shiftCards.map((card: any, index: number) => {
          return (
            <ShiftCardViewItem
              key={type+'_itmlst'+index}
              onMove={onMove}
              moveCard={moveCard}
              index={index}
              type={type}
              inDragLayer={false}
              data={card}
              id={"card_shift" + index}
            ></ShiftCardViewItem>
          );
        })}
      </Section>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  /* display: flex; */
`;
const Section = styled.div`
  padding: 0 30px;
  border: 1px solid #cecece;
`;

const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`;

export default ShiftCardViewSection;
