import React from 'react'
import styled from 'styled-components'
import { Shift } from '@src/types/Shift'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import ColorSquare from '@src/components/Crew/ColorSquare'
import CrewPackStatus from '@src/components/Status/CrewPackStatus'
import DayIcon from '@src/components/Shift/DayIcon'
import NightIcon from '@src/components/Shift/NightIcon'
import ASCircle from '@src/components/Shift/ASCircle'
import SSCircle from '@src/components/Shift/SSCircle'
import { ProjectTypeId } from '@src/types/ProjectTypeId'
import { ShiftTypeId } from '@src/types/ShiftTypeId'
import moment from 'moment'
const Format = 'DD/MM/YYYY'

interface Props {
  shift: Shift,
}

const CrewPackDetailInfo = (props: Props) => {
  const { 
    shift,
  } = props
  const { date, drsProjectType, projectId, projectName, projectInternalId, customerName } = shift
  const { crew, shiftType } = shift.plan
  function renderShiftType(shiftTypeId: ShiftTypeId) {
    return shiftTypeId === 'DAY' ? <DayIcon /> : <NightIcon />
  }
  function renderStreetSuburb() {
    if (shift!.plan.street) {
      return `${shift!.plan.street}, ${shift!.plan.suburb}`
    } else {
      return `[Street], [Suburb]`
    }
  }
  function renderProjectType(typeId: ProjectTypeId) {
    if (typeId === 'ASPHALT') {
      return <FlexBox>
        <ASCircle />
      </FlexBox>
    } else if (typeId === 'SPRAY-SEAL') {
      return <FlexBox>
        <SSCircle />
      </FlexBox>
    }
    return <FlexBox>
      <ASCircle />
      <SSCircleWrapper>
        <SSCircle />
      </SSCircleWrapper>
    </FlexBox>
  }
  return <React.Fragment>
    <StreetSuburb>
      <LocationOnIcon />
      { renderStreetSuburb() }
    </StreetSuburb>
    <ProjectInfo>
      <ProjectId>{`${projectId || projectInternalId} | `}</ProjectId>
      <ProjectName>{projectName}</ProjectName>
    </ProjectInfo>
    <DetailInfoWrapper>
      <InfoBlock>{customerName}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>{moment(date).format(Format)}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>{renderShiftType(shiftType.id)}</InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>
        { renderProjectType(drsProjectType.id)}
      </InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>
        <FlexBox>
          <ColorSquare color={`#${crew.colourCode}`} />
          <CrewName>{crew.name}</CrewName>
        </FlexBox>
      </InfoBlock>
      <SDivider orientation='vertical' />
      <InfoBlock>
        <CrewPackStatus status={shift.status.id}/>
      </InfoBlock>
    </DetailInfoWrapper>
  </React.Fragment>
}

const StreetSuburb = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  padding-bottom: 12px;
  svg {
    margin-right: 16px;
  }
`
const ProjectInfo = styled.div`
  margin-left: 38px;
  display: flex;
`
const ProjectId = styled.div`
  font-weight: bold;
  margin-right: 4px;
`
const ProjectName = styled.div``
const DetailInfoWrapper = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: 16px;
`
const InfoBlock = styled.div`
  padding: 0 48px;
`
const SDivider = styled(Divider)`
  background-color: #129688;
`
const CrewName = styled.div`
  margin-left: 6px;
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`
const SSCircleWrapper = styled.div`
  margin-left: 6px;
`
export default CrewPackDetailInfo