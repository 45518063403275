import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
interface Options {
  atpId: string,
  serialId:number,
}

export const apiGetFilesByATPId = ({
  atpId,
  serialId
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/documents/atp_pdf_files/${atpId}`,
    params: {
      reqSid: serialId,
    }
  }
  return ax(options)
}