import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AddIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import _get from 'lodash/get'
import FieldAssetDepotRadio from './f-AssetDepotRadio'
import FieldEditableNumber from './f-EditableNumber'
import FieldText from './f-Text'
import TableCell from './base-TableCell'
import TableHeaderCell from './base-TableHeaderCell'
import { getEmptyAsset } from '../StoreDRS'
import shortid from 'shortid'
import { AssetAutocomplete } from '../../../../../components'
import { useAppStore } from '../../../../../AppStore'
import { useConfirm } from '@src/components/GlobalConfirmDialog'

interface DRSField {
  data: any,
  path: string,
}

function AssetsTable ({
  field,
  onChange,
} : {
  field: DRSField,
  onChange: any,
}) {
  const { data, path } = field
  const { depotId, stateId } = useAppStore()
  const { confirm } = useConfirm()

  const handleCellChange = (row: DRS.DRSRowFn<DRS.MobileAssetRow>, rowIndex: number, cellPath: string) => (val: any) => {
    onChange(val, {
      isCell: true,
      tablePath: path,
      rowIndex,
      cellPath,
      path: [path, rowIndex, cellPath].join('.'),
    })
  }
  const onAssetNumberChange = (rowIndex: number) => ({ label, value, raw }: { label: string, value: any, raw: any }) => {
    onChange(raw, {
      fieldPath: `${field.path}.${rowIndex}.plan.mobileAsset`
    })
  }
  const handleAddRow = () => {
    const emptyRow = getEmptyAsset()
    onChange([
      ...data, {
        _react_key_: shortid.generate(),
        plan: emptyRow,
      }
    ], {
      isCell: false,
      isAdd: true,
      tablePath: path,
      path,
    })
  }
  const onRemove = (rowIndex: number) => {
    onChange([
      ...data.slice(0, rowIndex),
      ...data.slice(rowIndex + 1),
    ], {
      isCell: false,
      isAdd: false,
      isRemove: true,
      rowIndex,
      tablePath: path,
      path,
    })
  }
  const beforeRemove = (rowIndex: number) => async () => {
    try {
      const result = await confirm({
        title: 'Confirm removal of this item',
        message: 'This will erase any changes you have made to the fields and cannot be undone',
        confirmButtonText: 'Remove',
      })
      onRemove(rowIndex)
    } catch (error) {

    }
  }
  return <Wrapper>
    <Content>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Asset Depot</TableHeaderCell>
            <TableHeaderCell>Asset Number</TableHeaderCell>
            <TableHeaderCell>Asset Description</TableHeaderCell>
            <TableHeaderCell>Hours</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { data.map((row: DRS.DRSRowFn<DRS.MobileAssetRow>, rowIndex: number) => {
            const mobileAssetDepotIdData = _get(row, `plan.mobileAssetDepotId.id`)
            return <TableRow key={row._react_key_}>
              <TableCell>
                <FieldAssetDepotRadio
                  onChange={handleCellChange(row, rowIndex, 'plan.mobileAssetDepotId')}
                  field={
                    {
                      name: 'Mobile Asset assetDepot',
                      id: `${field.path}.${rowIndex}.plan.mobileAssetDepotId`,
                      path: `${field.path}.${rowIndex}.plan.mobileAssetDepotId`,
                      data: mobileAssetDepotIdData,
                    }
                  }
                  />
              </TableCell>
              <TableCell>
                <AssetAutocomplete 
                  active={true}
                  depotId={mobileAssetDepotIdData === 'My Depot' ? depotId : undefined}
                  stateId={mobileAssetDepotIdData !== 'My Depot' ? stateId : undefined}
                  selectedItem={{
                    value: row.plan.mobileAsset.id || '',
                    label: row.plan.mobileAsset.id || '',
                  }}
                  onChange={onAssetNumberChange(rowIndex)}
                />
              </TableCell>
              <TableCell>
                <BoxPt14>
                  <FieldText
                    field={
                      {
                        name: 'Asset Description',
                        id: `${field.path}.${rowIndex}.plan.mobileAsset.description`,
                        path: `${field.path}.${rowIndex}.plan.mobileAsset.description`,
                        data: row.plan.mobileAsset.description,
                        typeMeta: {
                          type: 'text',
                        }
                      }
                    }
                  />
                </BoxPt14>
              </TableCell>
              <TableCell>
                <FieldEditableNumber
                  onChange={handleCellChange(row, rowIndex, 'plan.hours')}
                  field={
                    {
                      name: 'Hours',
                      id: `${field.path}.${rowIndex}.plan.hours`,
                      path: `${field.path}.${rowIndex}.plan.hours`,
                      data: row.plan.hours,
                    }
                  }
                />
              </TableCell>
              <TableCell>
                {/* { rowIndex !== 0 &&  */}
                  <IconButton onClick={beforeRemove(rowIndex)}>
                    <DeleteIcon /> 
                  </IconButton>
                {/* } */}
              </TableCell>
            </TableRow>
          })}
          
        </TableBody>
      </Table>
      <AddButton 
        onClick={handleAddRow}
        >
          <StyledAddIcon />
          Add New
      </AddButton>
    </Content>
  </Wrapper>
}

const Wrapper = styled.div``

const Content = styled.div``

const AddButton = styled(Button)`
  color: #009688;
  text-transform: none;
  margin-left: 6px;
`
const StyledAddIcon = styled(AddIcon)`
  margin-right: 8px;
`
const BoxPt14 = styled.div`
  padding-top: 14px;
`

export default observer(AssetsTable)