export enum VMPStatusId {
  New = 1,
  Completed = 2,
  Updated = 3,
}

export function formatVMPStatusId(status: VMPStatusId) {
  switch (status) {
    case VMPStatusId.New:
      return 'New'
    case VMPStatusId.Completed:
      return 'Completed'
    case VMPStatusId.Updated:
      return 'Updated'
  }
}