import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import _get from 'lodash/get'
import Collaborators from '../ProjectManager/comps/ProjectDialog/Collaborators'
import usePermission from '@src/utils/hooks/usePermission'
import EditIcon from '@material-ui/icons/Edit'
import { Project } from '@src/types/project/Project'
import { formatProjectStatus } from '@src/types/project/ProjectStatus'
import { ProjectStatusComp } from '../ProjectManager/comps/ProjectCard'

function DetailInfo ({
  project,
  drsList,
  onEdit,
}: {
  project: Project,
  drsList: any[],
  onEdit(): void,
}) {
  const { canEditProject } = usePermission()
  return <Wrapper>
    <TitleWrapper>
      <Title>
        Project Information
      </Title>
      { canEditProject && 
          <EditButton
            variant='contained'
            color='primary'
            endIcon={<EditIcon />}
            onClick={onEdit} 
          >
            Edit Project
          </EditButton> 
        }
    </TitleWrapper>
    <GridContainer container>
      <Grid item xs={2}>
        <Label>Customer</Label>
        <Content>{_get(project, `customer.name`)}</Content>
      </Grid>
      <Grid item xs={2}>
        <Label>Customer Representative</Label>
        <Content>{project.customerRepresentativeName}</Content>
      </Grid>
      <Grid item xs={2}>
        <Label>Contact Number</Label>
        <Content>{project.customerRepresentativeContactNumber}</Content>
      </Grid>
      <Grid item xs={2}>
        <Label>Project Location</Label>
        <Content>{project.location || ''}</Content>
      </Grid>
      <Grid item xs={2}>
        <Label>Shifts Completed</Label>
        <Content>{`${project.shiftCompleted}/${project.shiftTotal}`}</Content>
      </Grid>
	  <Grid item xs={1}>
        <Label>Default Project Manager</Label>
        <Content>{`${project.defaultProjectManager || ''}`}</Content>
      </Grid>
      <Grid item xs={1}>
        <Label>Status</Label>
        <Content>
          <Status status={`${project.status!.id}`}>
            { formatProjectStatus(project.status!.id) }
          </Status>
        </Content>
      </Grid>
    </GridContainer>
  </Wrapper>
}

const Wrapper = styled.div`
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const Title = styled(Typography)`
  color: #645E5E;
  font-size: 20px;
  margin-bottom: 16px;
`
const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 8px;
`
const Content = styled.div`
  font-size: 16px;
  color: #645E5E;
`
const GridContainer = styled(Grid)`

`
const EditButton = styled(Button)`
  margin-right: 40px;
  align-self: center;
`

const Status = styled(ProjectStatusComp)`
  width: fit-content;
  padding: 0 10px;
`

export default DetailInfo