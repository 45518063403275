import React, { useState, useEffect } from 'react'
import Autocomplete from '../GeneralAutocomplete/Autocomplete'
import InputAdornment from '@material-ui/core/InputAdornment'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { apiCrewSearch } from '@src/apis/crew/crew-search'

const CrewAutocomplete = ({
  id = '',
  label = '',
  onChange,
  selectedItem,
}: AIMS.Autocomplete) => {
  const fetch = async (query: string) => {
    const result: any = await apiCrewSearch({ query })
    return result.data.crews
      .map((crew: AIMS.Crew) => {
        return {
          label: `${crew.name}`,
          value: crew.id,
          raw: crew,
        }
      })
  }
  
  return <Autocomplete
    id={id}
    label={label}
    fetch={fetch}
    selectedItem={selectedItem}
    onChange={onChange}
    dropdownAutoWidth={true}
    fullWidth={false}

    startAdornment={
      <InputAdornment position='start'>
        <AddCircleOutlineIcon />
      </InputAdornment>
    }
  />
}


export default CrewAutocomplete