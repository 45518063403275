import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import SingleDatePicker from './SingleDatePicker'
import { useCrewCalender } from '../StoreCrewCalender'

const BatchStartDateDialog = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose(): void,
}) => {
  const [date, setDate] = useState<Moment>(moment())
  const { goRefresh, setBatchStartDay } = useCrewCalender()
  if (!open) return null
  const handleSetDate = () => {
    setBatchStartDay(moment(date))
    onClose()
  }
  return <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
    <DialogTitle id='form-dialog-title'>{`Select Date`}</DialogTitle>
    <DialogContent>
      <SingleDatePicker
        variant='static'
        selectedItem={date}
        onChange={changedDate => {
          setDate(moment(changedDate))
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={handleSetDate} color='primary'>
        Go to Date
      </Button>
    </DialogActions>
  </Dialog>
}

export default BatchStartDateDialog