import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import FieldEditableCheckboxGroup from './f-EditableCheckboxGroup'
import _get from 'lodash/get'

const FieldEditableCheckboxGroupFlat = ({
  field,
  onChange,
}: {
  field: DRS.Field,
  onChange(val: any, options: { path: string }): void,
}) => {
  const { path, name, data } = field
  const { options } = field.typeMeta!
  const handleChange = (array: any[]) => {
    (options || []).forEach(option => {
      const { value } = option
      if (data[value || '']) {
        if (array.findIndex(item => item.id === value) === -1) {
          // console.log(`turning ${value} from true to false`)
          onChange(false, {
            path: `${path}.${value}`
          })
        }
      } else {
        if (array.findIndex(item => item.id === value) !== -1) {
          // console.log(`turning ${value} from false to true`)
          onChange(true, {
            path: `${path}.${value}`
          })
        }
      }
    })
  }
  const finalData = (options || [])
    .filter(option => data[option.value || ''])
    .map(item => {
      return {
        id: item.value
      }
    })
  const finalField = {
    name,
    path,
    data: finalData,  
    typeMeta: {
      type: '',
      options,
    }
  }
  return <FieldEditableCheckboxGroup
    onChange={handleChange}
    field={finalField}
  />
}


export default observer(FieldEditableCheckboxGroupFlat)