import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

export const apiShiftList = ({
  start,
  end,
  depot,
  region,
  state,
  limit,
  offset,
  projectInternalId,
}: {
  start?: string,
  end?: string,
  depot?: string,
  region?: string,
  state?: string,
  limit?: string,
  offset?: string,
  projectInternalId?: number,
}) => {
  const params = {
    start,
    end,
    depot,
    region,
    state,
    limit,
    offset,
    project: projectInternalId,
  }

  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/v3/shifts`,
    params,
  }
  return ax(options)
}