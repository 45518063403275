import React, { useState } from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import MuiTab from '@src/components/Tab'

interface Props {
  tabs: Tab[],
  activeIndex: number,
  onChange(tab: Tab, index: number): void,
}

export interface Tab {
  key: string | number,
  label: string,
}

const TabsComp = (props: Props) => {
  const {
    tabs,
    activeIndex,
    onChange,
  } = props
  if (activeIndex < 0 || activeIndex >= tabs.length) {
    return null
  }
  return <Tabs 
    value={activeIndex} 
    onChange={(e, index: number) => {
      onChange(tabs[index], index)
    }}>
      { tabs.map((tab: Tab) => {
        return <MuiTab key={tab.key} label={tab.label} />
      })}
  </Tabs>
}

const Wrapper = styled.div`

`

export default TabsComp