import React, { useState } from 'react'
import styled from 'styled-components'
import _merge from 'lodash/merge'
import moment, { Moment } from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { apiShiftDuplicate } from '../../../../apis/shift-duplicate'
import MultiDatePicker from './MultiDatePicker'
import { useCrewCalender } from '../StoreCrewCalender'
import { Shift } from '@src/types/Shift'
import { useAppStore } from '@src/AppStore'
import { useSnackbar } from '@src/components/GlobalSnackbar'

const DuplicateDialog = ({
  shift,
  open,
  onClose,
}: {
  shift: Shift,
  open: boolean,
  onClose(): void,
}) => {
  const { serialId } = useAppStore()
  const [date, setDate] = useState<Moment>(moment())
  const [dates, setDates] = useState<Moment[]>([])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { drss, setDrss, goRefresh } = useCrewCalender()
  const { alert } = useSnackbar()
  if (!open) return null
  const handleDuplicate = () => {
    setIsSaving(true)
    apiShiftDuplicate({
      shiftId: shift.id,
      dates: dates.map((date: Moment) => date.toISOString()),
      serialId,
    }).then((response: any) => {
      setIsSaving(false)
      if (response.data) {
        const formattedDates: string[] = dates.map((date: Moment) => date.format('DD-MM-YY'))
        const message: string = `Shift ${shift.id} was duplicated to ${formattedDates.join(', ')}`
        const newDrss: any[] = _merge([], drss, response.data.shifts)
        setDrss(newDrss)
        alert({ message })
        goRefresh()
        onClose()
      }
    })
  }
  return <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
    <DialogTitle id='form-dialog-title'>{`Duplicate Shift on ${moment(shift.date).format('DD/MM/YYYY')}`}</DialogTitle>
    <DialogContent>
      <MultiDatePicker
        variant='static'
        value={date}
        selectedItems={dates}
        onChange={date => {
          const index = dates.findIndex(item => {
            return moment(date).isSame(moment(item), 'date')
          })
          setDate(moment(date))
          if (index !== -1) {
            setDates([...dates.slice(0, index), ...dates.slice(index + 1)])
          } else {
            setDates([...dates, date])
          }
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={handleDuplicate} color='primary'>
        {isSaving && <React.Fragment><Spinner size={16} /> {`Duplicating`} </React.Fragment>}
        {!isSaving && 'Duplicate '}
      </Button>
    </DialogActions>
  </Dialog>
}

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`

export default DuplicateDialog