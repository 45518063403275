import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { Profile } from '@src/types/common/Profile'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  serialId: number,
  projectInternalId: number,
  street: string,
  suburb: string,
  dueDate: string,
  supervisor: Profile,
}

export const apiSifCreateOne = (options: Options) => {
  const { 
    serialId,
    projectInternalId,
    street,
    suburb,
    dueDate,
    supervisor,
  } = options
  const data = {
    projectInternalId,
    street,
    suburb,
    dueDate,
    supervisor: {
      serialId: supervisor.serialId
    },
  }
  const axOptions = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/sif`,
    data,
    params: {
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}