import Resizer from 'react-image-file-resizer';
import Q from 'q';

const resizeFile = (file: File, maxWidth: number, maxHeight: number, quality: number, compressFormat: string) =>
  new Promise((resolve) => {
    const rotation: number = 0
    const outputType: string = 'file'

    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType
    );
  });

const callResizeFile = (imageFile: File, maxWidth: number, maxHeight: number, quality: number, compressFormat: string) =>
  new Promise<File>((resolve) => {
    const file: unknown = <unknown>resizeFile(imageFile, maxWidth, maxHeight, quality, compressFormat)
    const fileFile: File = <File>file
    resolve(fileFile)
  });

interface Options {
  imageFile: File,
  maxWidth: number,
  maxHeight: number,
  maxFileSize: number,
}

export const compressImageFile = ({
  imageFile, maxWidth, maxHeight, maxFileSize
}: Options) => {
  const AcceptingImageFileMimeTypes = ['image/jpeg', 'image/png']
  const compressFormatTypes = ['JPEG', 'PNG']
  const compressFormat: string = compressFormatTypes[AcceptingImageFileMimeTypes.indexOf(imageFile.type)]
  const defered = Q.defer<File>();

  Q.when()
    .then(() => {
      //console.log(`START compressImageFile() ${imageFile.name} size=${imageFile.size} ${compressFormat}`)
      if (imageFile.size < maxFileSize)
        defered.resolve(imageFile)
      else
        return callResizeFile(imageFile, maxWidth, maxHeight, 80, compressFormat);
    })
    .then((file) => {
      if (file && file.size < maxFileSize) {
        defered.resolve(file)
      } else
        return callResizeFile(imageFile, maxWidth, maxHeight, 70, compressFormat);
    })
    .then((file) => {
      if (file && file.size < maxFileSize) {
        defered.resolve(file)
      } else
        return callResizeFile(imageFile, maxWidth, maxHeight, 60, compressFormat);
    })
    .then((file) => {
      if (file && file.size < maxFileSize) {
        defered.resolve(file)
      } else
        return callResizeFile(imageFile, maxWidth, maxHeight, 50, compressFormat);
    })
    .then((file) => {
      if (file && file.size < maxFileSize) {
        defered.resolve(file)
      } else
        return callResizeFile(imageFile, maxWidth, maxHeight, 40, compressFormat);
    })
    .then((file) => {
      if (file) {
        defered.resolve(file)
      }
    })
    .catch(function (error) {
      console.error('compressImageFile() Error: ' + error.stack);
    })
    .done(function () {
    });

  return defered.promise;
}