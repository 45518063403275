import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { formatBooleanToYesNo } from '@src/types/format/booleanToYesNo'
import { DRSMaterialAsphaltItem } from '@src/types/drs/material/MaterialAsphaltItem'

export interface DRSMaterialAsphaltItemRowDisplay extends DRSMaterialAsphaltItem {

}

const DRSMaterialAsphaltItemFields: FormTableField<DRSMaterialAsphaltItemRowDisplay>[] = [
  {
    key: 'asphaltLayer',
    type: FormTableFieldType.Select,
    label: 'Asphalt Layer',
    format: (cellData: MaterialAsphaltProduct['asphaltLayer']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    id: 'supplyPlant',
    key: 'supplyPlant',
    type: FormTableFieldType.Autocomplete,
    label: 'Supply Plant',
    format: (cellData: MaterialAsphaltProduct['plant']) => {
      if (cellData) {
        return cellData.name
      }
    },
  },
  {
    key: 'product',
    type: FormTableFieldType.Autocomplete,
    label: 'Product',
    format: (cellData: MaterialAsphaltProduct['product']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
  {
    key: 'customerSpecifiedMix',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Customer Specified Mix',
    format: (cellData: MaterialAsphaltProduct['customerSpecificMix']) => {
      if (cellData === null) {
        return ''
      }
      return formatBooleanToYesNo(cellData)
    },
  },
  {
    key: 'area',
    type: FormTableFieldType.Number,
    label: 'Area (m2)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'depth',
    type: FormTableFieldType.Number,
    label: 'Depth (mm)',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'maxRap',
    type: FormTableFieldType.Number,
    label: 'Max Rap',
    maxScale: 0,
    helperTextError: `only whole number allowed`,
  },
  {
    key: 'tonnes',
    type: FormTableFieldType.Number,
    label: 'Tonnes Planned',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'lotNumber',
    type: FormTableFieldType.Text,
    label: 'Lot Number',
  },
  {
    key: 'controlLine',
    type: FormTableFieldType.Text,
    label: 'Control Line',
  },
  {
    key: 'wasteReturn',
    type: FormTableFieldType.Number,
    label: 'Waste Tonnes',
    maxScale: 2,
    helperTextError: `only 2 decimal places allowed`,
  },
  {
    key: 'wasteReturnReason',
    type: FormTableFieldType.Select,
    label: 'Waste Return Reason',
    format: (cellData: MaterialAsphaltProduct['wasteReturnReason']) => {
      if (cellData) {
        return cellData.description
      }
    },
  },
]

export default DRSMaterialAsphaltItemFields