export enum ProjectStatus {
  Draft = 1,
  Active = 2,
  PracticallyCompleted = 3,
  FinanciallyCompleted = 4,
}

export function formatProjectStatus(status: ProjectStatus) {
  switch (status) {
    case ProjectStatus.Active:
      return 'Active'
    case ProjectStatus.Draft:
      return 'Draft'
    case ProjectStatus.PracticallyCompleted:
      return 'Practically Complete'
    case ProjectStatus.FinanciallyCompleted:
      return 'Financially Complete'
  }
}