import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

export const apiSupplyPlantSearch = ({ query }: { query: string }) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/plants`,
    params: {
      search: query,
    }
  }
  return ax(options)
}