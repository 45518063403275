import React from 'react'
import styled from 'styled-components'
import { DRSStatus, formatDRSStatus } from '@src/types/drs/DRSStatus'

interface Props {
  status: DRSStatus,
}
const CrewPackStatus = (props: Props) => {
  const { status } = props
  return <Status>
    { formatDRSStatus(status) }
  </Status>
}

const Status = styled.div`
  padding: 2px 47px;
  background-color: #129688;
  border-radius: 16px;
  color: #fff;
`

export default CrewPackStatus