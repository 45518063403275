import { FormTableField, FormTableFieldType } from '@src/components/Form/Table'
import { DRSSummaryInfo } from '@src/types/drs/summary/Summary'
import { formatProfileFirstLastName } from '@src/types/format/profileFirstLastName'
import { formatProfileOrNullFirstLastName } from '@src/types/format/profileOrNullFirstLastName'
import { ValueLabel } from '@src/types/common/ValueLabel'
import { IdDescription } from '@src/types/utils/IdDescription'
import { formatBooleanOrNullToYesNo } from '@src/types/format/booleanOrNullToYesNo'

export interface SummaryInfoRowDisplay extends DRSSummaryInfo {

}

export const SummaryInfoFields: FormTableField<SummaryInfoRowDisplay>[] = [
  {
    key: 'submittedBy',
    type: FormTableFieldType.Readonly,
    label: 'Submitted By',
    disabled: true,
    format: formatProfileOrNullFirstLastName,
  },
  {
    key: 'satisfiedCustomerNeeds',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Satisfied Customer Needs',
    disabled: true,
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'cleanUpAtEndOfShift',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Clean up At end of shift',
    disabled: true,
    format: formatBooleanOrNullToYesNo,
  },
  {
    key: 'submittedByDeclaration',
    type: FormTableFieldType.BooleanRadioGroup,
    label: 'Disclaimer',
    disabled: true,
    format: formatBooleanOrNullToYesNo,
  },
  {
    id: 'qualityOfWorkmanship',
    key: 'qualityOfWorkmanship',
    type: FormTableFieldType.Select,
    label: 'Quality Of Workmanship',
    disabled: true,
    format: (item: IdDescription) => item ? item.description : '',
  },
  {
    key: 'comments',
    type: FormTableFieldType.Textarea,
    label: 'Comments',
  },
]

export default SummaryInfoFields