import moment, { Moment } from 'moment'
import { adjustDateTimeToDate } from './adjustDateTimeToDate'

export function adjustEndTimeToStartTime(start: null | string, end: null | string | Moment, drsDate: string) {
  if (!end) {
    return null
  }
  if (!start) {
    return adjustDateTimeToDate(end, drsDate)
  }
  const adujstedEnd = adjustDateTimeToDate(end, drsDate)
  if (!adujstedEnd) {
    return null
  }
  const startTime = moment(start)
  let endTime = moment(adujstedEnd)
  const diff = moment.duration(endTime.diff(startTime)).asMinutes()
  if (diff < 0) {
    endTime.add(1, 'days')
  }
  return endTime.toISOString()
}