import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
  },
  label: {
    maxWidth: '100px',
  },
})

const Wrapper = styled.div`
  padding-top: 14px;
`

const Content = styled.div``
const StyledFormControl = styled(FormControl)`
  label {
    margin-left: 0;
  }
`
const FieldEditableCheckbox = ({
  field,
  onChange,
}: DRS.Comp) => {
  const classes = useStyles()
  let finalLabel = field.name
  // if (field.typeMeta && field.typeMeta.optional) {
  //   finalLabel = `${field.name} (Opt)`
  // }
  return <Wrapper>
    <StyledFormControl>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Checkbox
            checked={Boolean(field.data)}
            onChange={e => onChange(e.target.checked)}
            color='primary'
            inputProps={{
              // 'aria-label': 'secondary checkbox',
            }}
          />
        }
        label={field.name}
        labelPlacement='start'
      />
    </StyledFormControl>
    
  </Wrapper>
}

export default observer(FieldEditableCheckbox)