import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'
import { RoleId } from '@src/types/common/RoleId'

interface Options {
  drsId: number,
  serialId: number,
}

export const apiSrrGetOneByDRSId = (options: Options) => {
  const { drsId, serialId } = options
  const axOptions = {
    method: 'GET',
    url: `${BASE_API_URL}/v2/srr`,
    params: {
      drs: drsId,
      reqSid: serialId,
      reqRole: RoleId.ProjectManager,
      reqDev: 'web',
    }
  }
  return ax(axOptions)
}