import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'


interface Options {

  Comment: string,
  CrewID: number,
  Date: string,
  RGBColorCode: string,
  CreatedBy: string,
  Active: boolean,
  ModifiedBy: string,

}
export const apiCrewtNoteCreate = ({
  Comment,
  CrewID,
  Date,
  RGBColorCode,
  CreatedBy,
  Active,
  ModifiedBy,

}: Options) => {
  const data = {
    Comment,
    CrewID,
    Date,
    RGBColorCode,
    CreatedBy,
    Active,
    ModifiedBy,
  }
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/ref/crewNotes`,
    data
  }
  return ax(options)
}