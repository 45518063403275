import { ax } from "../utils/axios/axios";
import { BASE_API_URL } from "../constants";
import { Project } from "@src/types/project/Project";

interface Options {
  shiftId: number;
  project: Project;
  shiftTypeId: string;
  dates?: string[];
  date?: string;
  crewId: number;
  drsProjectTypeId: string;
  serialId: number;
  description?: string;
  street: string;
  nearestCrossStreet: string;
  suburb: string;
  planKMPlantToDepot: number;
  ShiftSealingRequirment: string;
  ShiftSalesOrder: string;
  isCostOnlyShift: boolean;
}

export const apiShiftUpdate = ({
  shiftId,
  project,
  shiftTypeId,
  date,
  crewId,
  drsProjectTypeId,
  serialId,
  street,
  nearestCrossStreet,
  suburb,
  planKMPlantToDepot,
  ShiftSealingRequirment,
  ShiftSalesOrder,
  isCostOnlyShift
}: Options) => {
  const data = {
    id: shiftId,
    description: " ",
    projectInternalId: project.projectInternalId,
    date,
    plan: {
      shiftType: {
        id: shiftTypeId,
      },
      crew: {
        id: crewId,
      },
      street,
      nearestCrossStreet,
      suburb,
      planKMPlantToDepot
    },
    drsProjectType: {
      id: drsProjectTypeId,
    },
    ShiftSealingRequirment,
    ShiftSalesOrder,
	isCostOnlyShift
  };

  const options = {
    method: "PUT",
    url: `${BASE_API_URL}/v2/shifts/${shiftId}`,
    data,
    params: {
      reqSid: serialId,
    },
  };
  return ax(options);
};
