import { ax } from '../utils/axios/axios'
import { BASE_API_URL } from '../constants'

interface Options {
  id: string
}

export const apiMobileAssetById = ({
  id
}: Options) => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/ref/mobileassets/${id}`,
  }
  return ax(options)
}
