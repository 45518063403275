import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import _reject from "lodash/reject";
import _merge from "lodash/merge";
import _isEmpty from "lodash/isEmpty";
import _unionBy from "lodash/unionBy";
import styled, { css } from "styled-components";
import _union from "lodash/union";
import _find from "lodash/find";
import _some from "lodash/some";
import { useCrewCalender } from "@src/modules/DashboardCalendar/CrewCalendarView/StoreCrewCalender";
import { DragItemTypes } from "@src/types/DragItemTypes";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";

interface Props {
  data: any;
  inDragLayer: boolean;
  type: string;
  id: string;
  index: number;
  onMove(type: string, items: any): void;
  moveCard(dragIndex: number, hoverIndex: number, type: string): void;
}

const ShiftCardViewItem = ({
  data,
  inDragLayer,
  type,
  id,
  onMove,
  index,
  moveCard,
}: Props) => {
  const ref: any = useRef();

  const {
    selectedShiftCardViewsCount,
    selectedShiftCardViews,
    setCalendarControlsDisabled,
    isShiftCardViewDragHappening,
    setIsShiftCardViewDragHappening,
    addSelectedShiftCards,
    shiftCardType,
    setShiftCardType,
    shiftCardTargetID,
  } = useCrewCalender();

  const [isMovingCard, setIsMovingCard] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [canDragShift, setCanDragShift] = useState<boolean>(true);

  useEffect(() => {
    let found = selectedShiftCardViews.find((o: any) => o.id === data.id);
    if (found) setIsSelected(true);
    else setIsSelected(false);
  }, [selectedShiftCardViewsCount]);

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.ShiftCardViewItem,
      shift: data,
      index: index,
      typeOpt: type,
    },

    canDrag: canDragShift,
    begin: (monitor) => {
      setIsShiftCardViewDragHappening(true);
      addSelectedShiftCards(data);
      setShiftCardType(type);
      if (type == "mine") {
        const allElement: any = document.getElementById("allCard");
        allElement.style = "opacity: 0.5";
      }
      if (type == "all") {
        const userCard: any = document.getElementById("userCard");
        userCard.style = "opacity: 0.5";
      }
    },

    end: async (item: any, monitor: any) => {
      console.log(selectedShiftCardViews);
      // console.log(`monitor`);
      const mn: any = monitor;
      //  console.log(item.typeOpt,shiftCardType);
      // console.log(monitor.receiveHandlerId());
      ///  console.log(monitor.getDropResult());
      // console.log(monitor.getTargetIds());
      // console.log(monitor.getHandlerId());
      console.log(`monitor`);
      const allElement: any = document.getElementById("allCard");
      allElement.style = "opacity: 1";
      const userCard: any = document.getElementById("userCard");
      userCard.style = "opacity: 1";
      if (shiftCardType == "all" && selectedShiftCardViews.length > 0) {
        onMove("move", item);
        setIsShiftCardViewDragHappening(false);
        setShiftCardType("");
        setCalendarControlsDisabled(false);
      } else if (shiftCardType != "all" && selectedShiftCardViews.length > 0) {
        onMove("back", item);
        setIsShiftCardViewDragHappening(false);
        setShiftCardType("");
        setCalendarControlsDisabled(false);
      } else {
        setIsShiftCardViewDragHappening(false);
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: DragItemTypes.ShiftCardViewItem,
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      //  console.log('--------'+dragIndex+'---'+hoverIndex)
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      setIsMovingCard(true);
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, type);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <Wrapper
      ref={ref}
      isSelected={isSelected}
      inDragLayer={inDragLayer}
      isDragHappening={isShiftCardViewDragHappening}
      id={id}
    >
      {data}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  isSelected: boolean;
  inDragLayer: boolean;
  isDragHappening: boolean;
}>`
  padding: 10px;
  background-color: #0a9688;
  color: #fff;
  border: 1px solid #ececec;
  margin: 5px 0px;
  cursor: pointer;
`;

export default observer(ShiftCardViewItem);
