import React, { useState } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { DatePicker } from '../../../../../components'
import CalendarToday from '@material-ui/icons/CalendarToday'
import { makeStyles } from '@material-ui/core/styles'
import { Moment } from 'moment'


const FieldEditableDate = ({
  field,
  onChange,
}: DRS.Comp) => {
  // const classes = useStyles();
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const handleChange = (date: Moment) => {
    setIsOpen(false)
    onChange(date)
  }
  return <Wrapper>
    <DatePicker
      open={isOpen}
      onClose={() => setIsOpen(false)}
      // classes={{
      //   root: classes.root, // class name, e.g. `classes-nesting-root-x`
      //   label: classes.label, // class name, e.g. `classes-nesting-label-x`
      // }}
      isKeyboardDatePicker={true}
      value={field.data}
      onChange={handleChange}
      InputProps={{
        placeholder: 'Date & Day',
        label: ' ',
      }}
      InputAdornmentProps={{ 
        position: 'start',
        component: () => <StyledIcon onClick={e => setIsOpen(true)}/>,
      }}
      variant='inline'
      format='YYYY-MM-DD'
      // style={{
      //   width: 'calc(100% - 48px)'
      // }}
      />
  </Wrapper>
}

const Wrapper = styled.div`
  margin-top: 30px;
`

const Content = styled.div``

const StyledIcon = styled(CalendarToday)`
  cursor: pointer;
  margin-right: 5px;
`

export default FieldEditableDate