import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useConfirm } from './useConfirm'
import { observer } from 'mobx-react-lite'

function Confirm () {
  const { isOpen, close, title, message, confirmButtonText, onConfirm } = useConfirm()
  const handleClose = () => {
    close()
  }
  const handleConfirm = () => {
    onConfirm()
  }
  return <Dialog
    open={isOpen}
    onClose={handleClose}
    // aria-labelledby='alert-dialog-slide-title'
    // aria-describedby='alert-dialog-slide-description'
  >
    <DialogTitle id='alert-dialog-slide-title'>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-slide-description'>
        { message }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} color='primary' variant='contained'>
        { confirmButtonText }
      </Button>
    </DialogActions>
  </Dialog>
}

export default observer(Confirm)