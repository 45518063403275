import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import DialogSectionTitle from '@src/components/Dialog/DialogSectionTitle'
import DepotAutocomplete from '@src/components/autocompletes/DepotAutocomplete'
import { Profile } from '@src/types/common/Profile'
import LabourAutocomplete from '@src/components/autocompletes/LabourAutocomplete'
import ProfileChip from '@src/components/Profile/Chip'
import AutocompleteCustomer from '@src/modules/ProjectManager/comps/ProjectDialog/AutocompleteCustomer'
import { Depot } from '@src/types/common/Depot'
import { AutocompleteOnChangeItem } from '@src/types/AutocompleteOnChangeItem'
import { Customer } from '@src/types/Customer'
import { apiProjectCreate, apiProjectUpdate } from '@src/apis/project-create'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { Project } from '@src/types/project/Project'
import CompThemeButton from '@src/components/Buttons/CompThemeButton'

interface Props {
  project?: Project,
  open: boolean,
  onClose(): void,
  onCancel(): void,
  onSuccess?(project?: Project): void,
}

const CreateProjectModal = (props: Props) => {
  const { project, open, onClose, onCancel, onSuccess = () => {} } = props
  const editing = project ? true : false
  const [ loading, setLoading ] = useState<boolean>(false)
  const emptyDepot = {} as Depot
  const [ depot, setDepot ] = useState<Depot>(emptyDepot)
  const depotSelected = 
    depot && depot.id ? { value: depot.id, label: depot.name } : { value: '', label: '' }
  
  const [ location, setLocation ] = useState<string>('')
  const [ projectColour, setProjectColour ] = useState<string>('#002A41')
  const [ defaultProjectManager, setDefaultProjectManager ] = useState<string>('')
  const [ collaborators, setCollaborators ] = useState<Profile[]>([])
  const emptyCustomer = {} as Customer
  const [ customer, setCustomer ] = useState<Customer>(emptyCustomer)
  const customerSelected = customer && customer.id ? { value: customer.id, label: customer.name } : { value: '', label: '' }
  const [ customerRepresentativeName, setCustomerRepresentativeName ] =  useState<string>('')
  const [ customerRepresentativeContactNumber, setCustomerRepresentativeContactNumber ] =  useState<string>('')
  const defaultProjectManagerSelected = 
    defaultProjectManager  ? { value: defaultProjectManager, label: defaultProjectManager } : { value: '', label: '' }
  const { alert } = useSnackbar()

  useEffect(() => {
    if (editing) {
      setDepot(project!.depot!)
      setLocation(project!.location!)
      setCollaborators(project!.projectTeam || [])
      setCustomer(project!.customer || emptyCustomer)
      setCustomerRepresentativeName(project!.customerRepresentativeName || '')
      setCustomerRepresentativeContactNumber(project!.customerRepresentativeContactNumber || '')
	    setDefaultProjectManager(project!.defaultProjectManager || '')
      setProjectColour(project!.projectColorCode || '#002A41')
    }
  }, [])
  const handleOk = async () => {
    if (!depot || !depot.id) {
      alert({
        message: 'Depot is required',
        type: 'error',
      })
      return
    }
    if (!location) {
      alert({
        message: 'Location is required',
        type: 'error',
      })
      return
    }
    if (!customer || !customer.id) {
      alert({
        message: 'Customer is required',
        type: 'error',
      })
      return
    }
    setLoading(true)
    try {
      if (editing) {
        const response: any = await apiProjectUpdate({
          projectInternalId: project!.projectInternalId,
          depot,
          location,
          collaborators,
          customer,
          customerRepresentativeName,
          customerRepresentativeContactNumber,
		  defaultProjectManager,
      projectColorCode: projectColour
        })
        setLoading(false)
        onSuccess(response.data.project)
      } else {
        const response: any = await apiProjectCreate({
          depot,
          location,
          collaborators,
          customer,
          customerRepresentativeName,
          customerRepresentativeContactNumber,
          defaultProjectManager,
          projectColorCode: projectColour
        })
		console.log(response)
        setLoading(false)
        onSuccess()
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const handleCollaboratorRemove = (index: number) => () => {
    setCollaborators([
      ...collaborators.slice(0, index),
      ...collaborators.slice(index + 1),
    ])
  }
  return <Dialog 
    fullWidth={true}
    maxWidth={'md'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle id='create-project-dialog-title'>
      { editing ? `Edit Project Information - ${project!.id || project!.projectInternalId} | ${project!.name}` : `Add Draft Project` }
    </DialogTitle>
    <DialogContent>
      <Section>
        <DialogSectionTitle>{`Project Information`}</DialogSectionTitle>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              <DepotAutocomplete
                disabled={editing}
                label={editing ? `Depot` : `Select Depot`}
                selectedItem={depotSelected}
                onChange={(item: AutocompleteOnChangeItem<Depot>) => {
                  setDepot(item.raw)
                }}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                disabled={editing}
                label={`Project Location`}
                value={location}
                onChange={(e: SyntheticEvent) => setLocation((e.target as HTMLInputElement).value)}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <LabourAutocomplete
                label={`Add Collaborator`}
                selectedItem={{ value: '', label: '' }}
                onChange={(item: AutocompleteOnChangeItem<Profile>) => {
                  setCollaborators([...collaborators, item.raw])
                }}
              />
              <CollaboratorsWrapper>
                {
                  collaborators.map((profile: Profile, index: number) => {
                    return <ProfileChip 
                      key={profile.serialId}
                      profile={profile}
                      removable
                      onRemove={handleCollaboratorRemove(index)}
                    />
                  })
                }
              </CollaboratorsWrapper>
            </GridInner>
          </Grid>
        </Grid>
		<Grid container>
          <Grid item xs={12}>
            <GridInner>

				<LabourAutocomplete 
                    label='Add Project Manager'
                    selectedItem={defaultProjectManagerSelected}
                    active={true}
                    roleType={`PROJECT_MANAGER`}
                    onChange={(item: AutocompleteOnChangeItem<Profile>) => {
                      console.log(item.raw.firstName + ' ' + item.raw.lastName);
						setDefaultProjectManager(item.raw.firstName + ' ' + item.raw.lastName)

                    }}
                />
			</GridInner>
		  </Grid>
		</Grid>
      </Section>
      <Section>
        <DialogSectionTitle>{`Customer Information`}</DialogSectionTitle>
        <Grid container>
          <Grid item xs={4}>
            <GridInner>
              <AutocompleteCustomer 
                disabled={editing}
                label={editing ? `Customer` : `Add Customer`}
                fullWidth={false}
                selectedItem={customerSelected}
                onChange={(item: AutocompleteOnChangeItem<Customer>) => {
                  setCustomer(item.raw)
                }}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                label={`Add Customer Rep`}
                value={customerRepresentativeName}
                onChange={(e: SyntheticEvent) => setCustomerRepresentativeName((e.target as HTMLInputElement).value)}
              />
            </GridInner>
          </Grid>
          <Grid item xs={4}>
            <GridInner>
              <TextField
                label={`Add Contact Number`}
                value={customerRepresentativeContactNumber}
                onChange={(e: SyntheticEvent) => setCustomerRepresentativeContactNumber((e.target as HTMLInputElement).value)}
              />
            </GridInner>
          </Grid>
        </Grid>
      </Section>
      <Section>
      <Grid container>
          <Grid item xs={4}>
            <GridInner>
            <DialogSectionTitle>{`Add Colour`}</DialogSectionTitle>
              <input type="color" id="projectColour" name="projectColour" value={projectColour} onChange={(e: any)=>{
                setProjectColour(e.target.value)
              }} /> {projectColour}
            </GridInner>
          </Grid>
          </Grid>
      </Section>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button 
        color='primary'
        variant='contained'
        disabled={loading} 
        onClick={handleOk} 
        >
        { editing ? `Save Changes` : `Create Draft Project` }
      </Button>
    </DialogActions>
  </Dialog>
}

const Section = styled.div`
  margin-bottom: 40px;
`
const GridInner =styled.div`
  padding-right: 16px;
`
const CollaboratorsWrapper = styled.div`
  display: flex;
  margin-top: 6px;
`

export default CreateProjectModal